import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/services';

@Component({
  selector: 'app-deliverables-draft',
  template: '<app-deliverable-list></app-deliverable-list>'
})
export class DraftDeliverablesComponent implements OnInit {
  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
  }
}
