<div fxFlex fxLayout="column" class="reporting-page">
  <div fxLayout="column" class="reporting-header-row">
    <div fxLayout="row" class="reporting-header-nav">
      <div fxLayout="row" class="reporting-header-nav-left">
        <ul class="nav">
          <li routerLinkActive="active">
            <a id="reporting" routerLink="/reporting/deliverables">Deliverables<span>&nbsp;</span></a>
          </li>
          <li routerLinkActive="active">
            <a id="stories" routerLink="/reporting/success-stories" style="white-space: nowrap;">SUCCESS
              STORIES<span>&nbsp;</span></a>
          </li>
          <li routerLinkActive="active">
            <a id="metrics" routerLink="/reporting/kpi">Metrics<span>&nbsp;</span></a>
          </li>
        </ul>

      </div>
      <div fxFlex></div>
      <div fxLayout="row" fxLayoutGap="10px" class="reporting-header-nav-right">
        <button id="btn_settingMenu" mat-button color="primary" class="setting-btn" [matMenuTriggerFor]="setingMenu"
          *ngIf="!isImageTab">
          <div class="btn-inner">
            <span>
              <span id="s_filtersetting" class="setting-value">Bookmark</span>
            </span>
            <span class="icon ic-dropdown"></span>
          </div>
        </button>
        <mat-menu #setingMenu="matMenu" class="live-menu sort-menu">
          <button id="btn_savesetting" mat-menu-item (click)="onSaveSetting()">
            Save Filter Settings
          </button>
          <button id="btn_loadsetting" mat-menu-item (click)="onLoadSetting()">
            Load Filter Settings
          </button>
        </mat-menu>
        <form fxLayout="row" class="search_wrap" (ngSubmit)="onSearchSubmit()" *ngIf="!isShowTab&&!isImageTab">
          <input id="searchDeliverables" placeholder="Search deliverables…" name="search" [(ngModel)]="search"
            aria-label="Search Deliverables" />
          <button id="btn_paginationSearch1" type="submit" *ngIf="!pagination.search || pagination.search != search"
            aria-label="submit Icon">
            <span class="icon ic-search"></span>
          </button>
          <button id="btn_paginationSearch2" *ngIf="pagination.search && pagination.search == search"
            (click)="search=null;onSearchSubmit()">
            <span class="icon ic-close"></span>
          </button>
        </form>
        <form fxLayout="row" class="search_wrap" (ngSubmit)="onSearchStorySubmit()" *ngIf="isImageTab">
          <input id="searchDeliverables" placeholder="Search stories…" name="searchStory" [(ngModel)]="searchStory"
            aria-label="Search stories" />
          <button id="btn_paginationSearch1" type="submit"
            *ngIf="!paginationStory.search || paginationStory.search != searchStory" aria-label="submit Icon">
            <span class="icon ic-search"></span>
          </button>
          <button id="btn_paginationSearch2" type="submit"
            *ngIf="paginationStory.search && paginationStory.search == searchStory"
            (click)="searchStory=null;onSearchStorySubmit()">
            <span class="icon ic-close"></span>
          </button>
        </form>
        <button id="btn_filter" mat-button class="filter-btn" color="primary" (click)="onOpenFilter()" [ngClass]="{'open': showFilter}">
          <span class="icon ic-filter"></span> Filter
        </button>
        <button id="btn_sortMenu" mat-button class="sort-btn" color="primary" [matMenuTriggerFor]="sortMenu"
          *ngIf="!isShowTab&&!isImageTab">
          <div class="btn-inner">
            <span>
              <span id="s_sort">Sort : </span>
              <span id="s_alphabetical" class="sort-value" *ngIf="pagination.sort == 'name'">Alphabetical</span>
              <span id="s_group" class="sort-value" *ngIf="pagination.sort == 'primary_group.name'">Group</span>
              <span id="s_workstream" class="sort-value"
                *ngIf="pagination.sort == 'primary_workstream.name'">Workstream</span>
              <span id="s_deliverableType" class="sort-value"
                *ngIf="pagination.sort == 'deliverable_type.name'">Deliverable Type</span>
              <span id="s_status" class="sort-value" *ngIf="pagination.sort == 'deliverable_status.name'">Status</span>
              <span id="s_mostRecent" class="sort-value" *ngIf="pagination.sort == 'last_updated_date'">Most
                Recent</span>
              <span id="s_sort_dir">({{pagination.sort_dir}})</span>
            </span>
            <span class="icon ic-dropdown"></span>
          </div>
        </button>
        <mat-menu #sortMenu="matMenu" class="live-menu sort-menu">
          <button id="btn_deliverableStatus" mat-menu-item (click)="onSort('deliverable_status.name')">
            Deliverable Status
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'deliverable_status.name'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="btn_mostRecent" mat-menu-item (click)="onSort('last_updated_date')">
            Most Recent
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'last_updated_date'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="btn_alphabetical" mat-menu-item (click)="onSort('name')">
            Alphabetical
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'name'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <mat-divider></mat-divider>
          <button id="btn_group" mat-menu-item (click)="onSort('primary_group.name')">
            Group
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'primary_group.name'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="btn_workstream" mat-menu-item (click)="onSort('primary_workstream.name')">
            Workstream
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'primary_workstream.name'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="btn_deliverableType" mat-menu-item (click)="onSort('deliverable_type.name')">
            Deliverable Type
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'deliverable_type.name'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
        </mat-menu>
        <button id="btn_sortstoryMenu" mat-button class="sort-btn" color="primary" [matMenuTriggerFor]="sortStoryMenu"
          *ngIf="!isShowTab&&isImageTab">
          <div class="btn-inner">
            <span>
              <span id="s_storysort">Sort : </span>
              <span id="s_storyalphabetical" class="sort-value"
                *ngIf="paginationStory.sort == 'title'">Alphabetical</span>
              <span id="s_storygroup" class="sort-value"
                *ngIf="paginationStory.sort == 'primary_group.name'">Group</span>
              <span id="s_storyworkstream" class="sort-value"
                *ngIf="paginationStory.sort == 'primary_workstream.name'">Workstream</span>
              <span id="s_status" class="sort-value" *ngIf="paginationStory.sort == 'workflow_status'">Workflow
                Status</span>
              <span id="s_mostRecent" class="sort-value" *ngIf="paginationStory.sort == 'last_updated_date'">Most
                Recent</span>
              <span id="s_sort_dir">({{paginationStory.sort_dir}})</span>
            </span>
            <span class="icon ic-dropdown"></span>
          </div>
        </button>
        <mat-menu #sortStoryMenu="matMenu" class="live-menu sort-menu">
          <button id="btn_storyStatus" mat-menu-item (click)="onStorySort('workflow_status')">
            Workflow Status
            <span class="icon ic-dropdown" *ngIf="paginationStory.sort == 'workflow_status'">
              <span
                [ngClass]="{'ic-sort_up' :paginationStory.sort_dir === 'asc', 'ic-sort_down' :paginationStory.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="btn_mostRecent" mat-menu-item (click)="onStorySort('last_updated_date')">
            Most Recent
            <span class="icon ic-dropdown" *ngIf="paginationStory.sort == 'last_updated_date'">
              <span
                [ngClass]="{'ic-sort_up' :paginationStory.sort_dir === 'asc', 'ic-sort_down' :paginationStory.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="btn_alphabetical" mat-menu-item (click)="onStorySort('title')">
            Alphabetical
            <span class="icon ic-dropdown" *ngIf="paginationStory.sort == 'title'">
              <span
                [ngClass]="{'ic-sort_up' :paginationStory.sort_dir === 'asc', 'ic-sort_down' :paginationStory.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <mat-divider></mat-divider>
          <button id="btn_group" mat-menu-item (click)="onStorySort('primary_group.name')">
            Group
            <span class="icon ic-dropdown" *ngIf="paginationStory.sort == 'primary_group.name'">
              <span
                [ngClass]="{'ic-sort_up' :paginationStory.sort_dir === 'asc', 'ic-sort_down' :paginationStory.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="btn_workstream" mat-menu-item (click)="onStorySort('primary_workstream.name')">
            Workstream
            <span class="icon ic-dropdown" *ngIf="paginationStory.sort == 'primary_workstream.name'">
              <span
                [ngClass]="{'ic-sort_up' :paginationStory.sort_dir === 'asc', 'ic-sort_down' :paginationStory.sort_dir !== 'asc' }"></span>
            </span>
          </button>

        </mat-menu>

      </div>
    </div>
    <div fxLayout="row" *ngIf="setting&&!isImageTab" class="show-save-setting">
      <label id="lblAppliedFilters"> Showing Bookmark:</label>
      <div fxLayout="column" class="show-name">
        {{setting.name}}
      </div>
    </div>
    <app-report-filter #commonFilter sectionKey="reporting-list-filter" [show]="showFilter"
      (onChange)="onFilter($event)" *ngIf="!isImageTab">
    </app-report-filter>
    <app-story-filter #commonFilter sectionKey="story-filter" filterOptionActive="All" [show]="showFilter"
      (onChange)="onStoryFilter($event)" *ngIf="isImageTab">
    </app-story-filter>
  </div>
  <div fxFlex fxLayout="column" *ngIf="!isShowTab&&!isImageTab" style="position: relative;">   
    <div fxLayout="row" class="reporting-table-header">   
      <div *ngIf="isLoading" style="display: flex; justify-content: center; align-items: center;">
        <mat-spinner [diameter]="30"></mat-spinner> <p style="margin-left: 20px;">Downloading...</p>
      </div>  
      <div fxFlex></div> 
      <div fxLayout="row" class="sec-btn-wrap" fxLayoutGap="15px" style="position: relative;">        
        <button id="btn_addNew" mat-stroked-button color="accent" (click)="onQuickEdit(null)" aria-label=" Add New">
          <div fxLayout="row" class="btn-inner">
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path fill="none" d="M0 0h24v24H0z" />
              <circle cx="9" cy="9" r="9" fill="none" stroke="#004dff" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2px" transform="translate(3 3)" />
              <path d="M0 0L6 0" stroke="#004dff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px"
                transform="translate(9 12)" />
              <path d="M0 0L0 6" stroke="#004dff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2px"
                transform="translate(12 9)" />
            </svg>
            Add New
          </div>
        </button>
        <button id="btn_exportMenu" (click)="exportReport()" *ngIf="services.commonService.hasAnyRole([Role.MANAGER_ADMINISTRATOR]) || services.commonService.hasAnyRole([Role.CONFIGURATION_ADMINISTRATOR])" mat-stroked-button color="accent" [matMenuTriggerFor]="exportMenu">
          <div fxLayout="row" class="btn-inner">
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24.6" viewBox="0 0 24 24.6">
              <path fill="none" d="M0 0h24v24.6H0z" />
              <path fill="#004dff"
                d="M8.71 7.71L11 5.41V15a1 1 0 0 0 2 0V5.41l2.29 2.3a1 1 0 1 0 1.42-1.42l-4-4a1.034 1.034 0 0 0-1.42 0l-4 4a1 1 0 0 0 1.42 1.42zM21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 1 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1z"
                transform="translate(0 -.005)" />
            </svg>
            <span>
              <span id="s_exportsetting" class="setting-value">Export Report</span>
            </span>
          </div>
        </button>
        <mat-menu #exportMenu="matMenu" class="live-menu sort-menu">
          <button id="btn_savesetting" mat-menu-item (click)="exportPptx()">
            Labs Publications Report (PPT)
          </button>
          <button id="btn_exportsetting" mat-menu-item (click)="onExport()">
            {{!hasFilter() && !pagination.search ?"Full Report (CSV)":"Filtered Report (CSV)"}}
          </button>
        </mat-menu>
      </div>
    </div>
    <div fxFlex fxLayout="column" class="reporting-list-row" id="reporting-list-row">
      <div fxFlex fxLayout="row" *ngIf="loading" class="loading-wrap">
        <span id="loading">Loading...</span>
      </div>      
      <div fxFlex fxLayout="row" role="alert" aria-live="assertive" *ngIf="!loading && !deliverables.length" class="loading-wrap">
        <span id="noResults">No results</span>
      </div>
      <div fxLayout="row" *ngIf="!loading && totalRecords!==0" class="reporting-list">
        <div fxLayout="column" fxFlex class="table-container" id="table-container" >
          <div>
            <table mat-table [dataSource]="deliverables" aria-label="reporting data table">
              <ng-container matColumnDef="name" sticky>
                <th id="th_deliverableName" mat-header-cell *matHeaderCellDef> Deliverable Name </th>
                <td name="td_deliverableName" mat-cell *matCellDef="let element" class="white-space-pre"><a
                    [routerLink]="['/reporting/view', element._id]">{{element.name}}</a></td>
              </ng-container>
              <ng-container matColumnDef="deliverable_status">
                <th id="th_deliverableStatus" mat-header-cell *matHeaderCellDef> Deliverable Status </th>
                <td name="td_deliverableStatus" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.deliverable_status?element.deliverable_status.name:'In Progress'}} </td>
              </ng-container>
              <ng-container matColumnDef="owner">
                <th id="th_owner" mat-header-cell *matHeaderCellDef> Owner </th>
                <td name="td_owner" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.owner?element.owner.display_name: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="organization_type">
                <th id="th_org" mat-header-cell *matHeaderCellDef> Organization </th>
                <td name="td_org" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.organization_type?element.organization_type.name: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="primary_group">
                <th id="th_group" mat-header-cell *matHeaderCellDef> Group </th>
                <td name="td_group" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.primary_group?element.primary_group.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="primary_workstream">
                <th id="th_workstream" mat-header-cell *matHeaderCellDef> Workstream </th>
                <td name="td_workstream" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.primary_workstream?element.primary_workstream.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="primary_location">
                <th id="th_location" mat-header-cell *matHeaderCellDef> Location </th>
                <td name="td_location" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.primary_location?element.primary_location.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="initiatives">
                <th id="th_initiative" mat-header-cell *matHeaderCellDef> Initiative </th>
                <td name="td_initiative" mat-cell *matCellDef="let element" class="no-white-space">
                  <span id="initiative{{i}}"
                    *ngFor="let option of element.initiatives; last as isLast;let i=index">{{option.name}}<span
                      *ngIf="!isLast">,<br /></span></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="deliverable_type">
                <th id="th_deliverableType" mat-header-cell *matHeaderCellDef> Deliverable Type </th>
                <td name="td_deliverableType" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.deliverable_type?element.deliverable_type.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="client">
                <th id="th_client" mat-header-cell *matHeaderCellDef> Client </th>
                <td name="td_client" mat-cell *matCellDef="let element" class="no-white-space"> {{element.client}} </td>
              </ng-container>
              <ng-container matColumnDef="metrics">
                <th id="th_metrics" mat-header-cell *matHeaderCellDef> Metrics </th>
                <td name="td_metrics" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.deliverable_type &&
                  element.deliverable_type.metric?element.deliverable_type.metric.name:''}}
                </td>
              </ng-container>
              <ng-container matColumnDef="idf_number">
                <th id="th_IDFNumber" mat-header-cell *matHeaderCellDef> IDF Number </th>
                <td name="td_IDFNumber" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.idf_number}}
                </td>
              </ng-container>

              <ng-container matColumnDef="eac_qrtr">
                <th id="th_endQTR" mat-header-cell *matHeaderCellDef> FiscalYear Quarter </th>
                <td name="td_endQTR" mat-cell *matCellDef="let element" class="no-white-space">
                  <span *ngIf="element.eac_qrtr">FY{{element.eac_qrtr.year-2000}} Q{{element.eac_qrtr.qtr}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="url">
                <th id="th_url" mat-header-cell *matHeaderCellDef> URL </th>
                <td name="td_url" mat-cell *matCellDef="let element"
                  style="min-width: 260px;max-width: 260px;word-break: break-all;">
                  <span id="url{{i}}" *ngFor="let option of element.url; last as isLast;let i=index">
                    <a [href]="services.commonService.addHttp(option)" target="_blank" class="link">{{option}}</a>
                    <span *ngIf="!isLast">,<br /></span>
                  </span>
                </td>
              </ng-container>
              <ng-container matColumnDef="project_name">
                <th id="th_projectName" mat-header-cell *matHeaderCellDef> Project Name </th>
                <td name="td_projectName" mat-cell *matCellDef="let element" style="min-width: 260px;max-width: 260px;">
                  <span *ngIf="element.project">{{element.project.name}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="project_description">
                <th id="th_projectDesc" mat-header-cell *matHeaderCellDef> Project Description </th>
                <td name="td_projectDesc" mat-cell *matCellDef="let element" style="min-width: 520px;max-width: 520px;">
                  <span *ngIf="element.project">{{element.project.description}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="project_lead">
                <th id="th_projectLead" mat-header-cell *matHeaderCellDef> Project Lead </th>
                <td name="td_projectLead" mat-cell *matCellDef="let element" class="no-white-space">
                  <span
                    *ngIf="element.project && element.project.project_lead">{{element.project.project_lead.display_name}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="industries">
                <th id="th_industry" mat-header-cell *matHeaderCellDef> Industry </th>
                <td name="td_industry" mat-cell *matCellDef="let element" class="no-white-space">
                  <span id="industry{{i}}"
                    *ngFor="let option of element.industries; last as isLast;let i=index">{{option.name}}<span
                      *ngIf="!isLast">,<br /></span></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="workflow_status">
                <th id="th_workflowStatus" mat-header-cell *matHeaderCellDef> Workflow Status </th>
                <td name="td_workflowStatus" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.workflow_status}}</td>
              </ng-container>
              <ng-container matColumnDef="action" stickyEnd>
                <th mat-header-cell *matHeaderCellDef style="width: 1%;">
                  <span style="display: none;">Wav</span>
                  <button id="btn_table_filter" mat-icon-button class="filter-btn" (click)="onOpenTableFilter()"
                    aria-label="filter">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                      height="20" viewBox="0 0 20 20">
                      <path
                        d="M18,20H16V6H14V4h2V0h2V4h2V6H18V20Zm-7,0H9V11H7V9H9V0h2V9h2v2H11v9ZM4,20H2V16H0V14H2V0H4V14H6v2H4v4Z" />
                    </svg>
                  </button>
                </th>
                <td mat-cell *matCellDef="let element">
                  <button id="deliverableMenuIcon" mat-icon-button matRipple [matRippleColor]="'#a100ff'" [matRippleCentered]="true" 
                  [matRippleUnbounded]="false" [matRippleRadius]="18" class="menu-btn" [matMenuTriggerFor]="deliverableMenu" aria-label="deliverable">
                    <span class="icon ic-menu"></span>
                  </button>
                  <mat-menu #deliverableMenu="matMenu" class="live-menu">
                    <button id="quickEditDeliverable" mat-menu-item (click)="onQuickEdit(element)">Quick Edit
                      Deliverable</button>
                    <button id="editDeliverable" mat-menu-item [routerLink]="['/deliverables', element._id]">Edit
                      Deliverable</button>
                    <button id="emailOwner" mat-menu-item *ngIf="element.owner && element.owner.email_id"
                      (click)="services.deliverableCommonService.sendOwnerEmail(element)">Email Owner</button>
                    <button id="emailGroupLead" mat-menu-item
                      *ngIf="element.primary_group && element.primary_group.group_lead && element.primary_group.group_lead.email_id"
                      (click)="services.deliverableCommonService.sendGroupLeadEmail(element)">Email Group Lead</button>
                  </mat-menu>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <div id="table_footer" fxLayout="row" class="table-footer">
            <mat-paginator [length]="totalRecords" [pageSize]="pagination.page_size"
              [pageIndex]="pagination.page_number" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)">
            </mat-paginator>
          </div>
        </div>
        <div fxLayout="column" class="filter-menu" *ngIf="filterMenu" [@slideMenu] id="filter-menu">
          <div fxLayout="column" class="filter-menu-button_wrap">
            <button id="btn_apply_top" mat-flat-button class="apply-btn" aria-label="Apply" color="primary" (click)="onApplyTableFilter()">
              Apply
            </button>
          </div>
          <div fxFlex fxLayout="column" class="filter-menu-options_wrap">
            <div fxLayout="column" class="filter-menu-group">
              <h2 id="h_tableOptions">Table Options</h2>
              <mat-checkbox [(ngModel)]="hideFilterFields" (change)="onHideFilterFieldsChange()" color="accent">Hide Filter Fields
              </mat-checkbox>
            </div>
            <div fxLayout="column" class="filter-menu-group">
              <h2 id="h_showHideColumns">Show/Hide Columns</h2>
              <mat-checkbox [(ngModel)]="showWorkflowStatus" color="accent">Workflow Status</mat-checkbox>
            </div>
            <div fxLayout="column" class="filter-menu-group">
              <h3 id="h_mandatoryFields">Mandatory Fields</h3>
              <mat-checkbox id="mandatoryFieldsShowAll" [checked]="checkAllSelect(mandatoryFields)"
                (change)="onShowAllChange($event, mandatoryFields)" color="accent">Show All</mat-checkbox>
              <mat-checkbox id="mandatoryFields{{i}}" *ngFor="let option of mandatoryFields;let i=index"
                [(ngModel)]="option.select" [disabled]="option.disabled" color="accent">{{option.label}}</mat-checkbox>
            </div>
            <div fxLayout="column" class="filter-menu-group">
              <h3 id="h_optionalFields">Optional Fields</h3>
              <mat-checkbox id="optionalFieldsShowAll" [checked]="checkAllSelect(optionalFields)"
                (change)="onShowAllChange($event, optionalFields)" color="accent">Show All</mat-checkbox>
              <mat-checkbox id="optionalFields{{i}}" *ngFor="let option of optionalFields;let i=index"
                [(ngModel)]="option.select" [disabled]="option.disabled" color="accent">{{option.label}}</mat-checkbox>
            </div>
          </div>
          <div fxLayout="column" class="filter-menu-button_wrap">
            <button id="btn_apply_bottom" mat-flat-button class="apply-btn" color="primary" (click)="onApplyTableFilter()">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-kpi #kpiView *ngIf="isShowTab" [filterData]="filter" [isInMetricsTab]="isShowTab&&!isImageTab" fxLayout="column"></app-kpi>
  <app-stories #storiesView *ngIf="isImageTab" [isInStoiesTab]="isImageTab" [storyFilter]="storyFilter"
    [storyPagination]="paginationStory"></app-stories>
</div>