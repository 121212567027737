/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { CommonService } from '../common.service';
import { ProjectsService } from '../projects.service';
import { WorkflowStatus } from '@app/models';

@Injectable()
export class ProjectsMockService implements ProjectsService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  getProjectStages(params = null): Observable<any> {
    this.commonService.log('GET:', '/projectstages', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/projects_stages.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjectStatus(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverablestatus', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/deliverables_status.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getProjectStatusForFilter(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverablestatus/project/filter', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/deliverables_status.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/list/project', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/quarters_project.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjectsKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/projects/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get('/assets/json/demo/projects_keywords.json', this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.filter((option) => {
            return option.toLowerCase().includes(searchText.toLowerCase()) 
          }) 
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjects(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/projects/list', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/projects.json', this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          const isDraft = parameters.workflow_status[0] == WorkflowStatus.DRAFT;
          const result = res.filter((x) => parameters.workflow_status.includes(x.workflow_status));
          return {
            projects: result.slice(parameters.page_number * parameters.page_size, parameters.page_number * parameters.page_size + parameters.page_size),
            total: result.length,
            live_total: !isDraft ? result.length : res.length - result.length,
            draft_total: isDraft ? result.length : res.length - result.length
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProject(projectId: any): Observable<any> {
    this.commonService.log('GET:', `/projects/${projectId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/projects.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.find((x) => x._id === parseInt(projectId, 10)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  saveDraft(project, file): Observable<any> {
    this.commonService.log('POST:', '/projects/draft', 'BODY:', project, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ project, file });
  }

  save(project, file): Observable<any> {
    this.commonService.log('POST:', '/projects', 'BODY:', project, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ project, file });
  }

  getTypeAheadData(queryParams): Observable<any> {
    return of(queryParams);
  }

  updateDraft(projectId, project, file): Observable<any> {
    this.commonService.log('PUT:', `/projects/draft/${projectId}`, 'BODY:', project, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ projectId, project, file });
  }

  update(projectId, project, file): Observable<any> {
    this.commonService.log('POST:', `/projects/${projectId}`, 'BODY:', project, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ projectId, project, file });
  }
  
  projectNameVerification(projectName): Observable<any> {
    this.commonService.log('GET:', '/projects/nameVerification/' + projectName, 'OPTIONS:', this.commonService.httpOptions(null));
    return this.http.get('/assets/json/demo/projects.json', this.commonService.httpOptions(null))
      .pipe(debounceTime(300),
        distinctUntilChanged(),
        map(this.commonService.extractData),
        map((res: any) => {
          const project = res.find((p) => {
            return p.name === projectName;
          });
          if (project) {
            return { result: true };
          }
          return { result: false };
        }),
      );
  }

  checkAdvanceStage(projectId: any): Observable<any> {
    const param = { projectId: projectId };
    this.commonService.log('GET:', `/projectStageWorkflow/checkAdvanceStage/${projectId}`, 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get('/assets/json/demo/projects.json', this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res:any) => {
          //const random = Math.random();
          // if (random < 0.5) {
          //   return {
          //     isMet: true,
          //     checkResult: []
          //   };
          // } else {
            // return {
            //   isMet: false,
            //   checkResult: [
            //     'Required [PoV/ Whitepaper] Deliverables',
            //     'Required [Complete] Status',
            //     'XXXXXXX',
            //   ]
            // };
         // }
         return{result:res}
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  advanceStage(projectId): Observable<any> {
    this.commonService.log('POST:', `/projectStageWorkflow/advanceStage/${projectId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ projectId });
  }

  getReviewers(projectId: any): Observable<any> {
    this.commonService.log('GET:', `/projects/reviewer/${projectId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/users.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return {
            result: res.filter((user) => {
              return this.commonService.hasAnyLeadRole(user);
            })
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  reviewRequest(projectId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/projects/review/request/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ projectId, formData });
  }

  approveReassign(projectId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/projects/review/reassign/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ projectId, formData });
  }
  
  approveRework(projectId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/projects/review/rework/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ projectId });
  }

  approve(projectId: any): Observable<any> {
    this.commonService.log('POST:', `/projects/review/approve/${projectId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId: projectId });
  }

  sendReminder(projectId: any): Observable<any> {
    this.commonService.log('GET:', `/projects/review/sendreminder/${projectId}`, 'OPTIONS:', this.commonService.httpOptions());
    return of({ projectId });
  }
}

