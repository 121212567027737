<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<table mat-table [dataSource] = "tableData" matSort [matSortStart]="tableOrder" (matSortChange)="sortData($event)" matSortDisableClear class="mat-elevation-z8" aria-label="admin-dashboard-data-table">
  <ng-container matColumnDef="{{header}}" *ngFor="let header of displayedColumns; let i=index">
    <ng-container *ngIf="header === 'checked'; else notSelect">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox id="selectAll" (change)="selectAll($event)" color="accent" [checked]="isHeaderChecked"><span style="display: none;">wav</span></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element;let j=index">
        <mat-checkbox id="selectOne{{j}}" [(ngModel)]="element.checked" (change)="selectOne(element)" color="accent"><span style="display: none;">wav</span></mat-checkbox>
        <i id="active{{j}}" *ngIf="element.active" class="fa fa-circle active-inactive-icon"></i>
        <i id="inactive{{j}}" *ngIf="!element.active" class="fa fa-circle-o active-inactive-icon"></i>
      </td>
    </ng-container>
    <ng-template #notSelect>
      <div *ngIf="i === 1; else noSort">       
        <th id="tableHeader{{i}}" mat-header-cell *matHeaderCellDef mat-sort-header > {{tableHeaders[i]}}</th>   
        <td id="tableDatas{{i}}{{j}}" (click)="clickElement(element)" class="highlightElement" mat-cell *matCellDef="let element;let j=index"  tabindex="0" (keydown.enter)="clickElement(element)"> {{element[header]}} </td>    
      </div>
        <ng-template #noSort>        
        <th id="tableHeader{{i}}" mat-header-cell *matHeaderCellDef> {{tableHeaders[i]}}</th>        
        <td id="tableDatas{{i}}{{j}}" mat-cell *matCellDef="let element;let j=index"> {{element[header]}} </td>     
        </ng-template>
      <th id="tableHeader{{i}}" mat-header-cell *matHeaderCellDef mat-sort-header> {{tableHeaders[i]}}</th>
      <td id="tableDatas{{i}}{{j}}" mat-cell *matCellDef="let element;let j=index"> {{element[header]}} </td>
    </ng-template>
    
  </ng-container>
  <ng-container matColumnDef="totalEntries">
    <td id="totalEntries" [attr.colspan]="displayedColumns.length" mat-footer-cell *matFooterCellDef>{{totalEntries[0]}}</td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef = "displayedColumns;"></tr>
  <tr mat-row *matRowDef = "let row; columns: displayedColumns;"></tr>
  <tr mat-footer-row *matFooterRowDef="['totalEntries']"></tr>
</table>