<form #validForm fxLayout="column" [formGroup]="iconForm" class="edit-story" aria-labelledby="lblDeliverableImage deliverableImage_text_hint">
    <div class="story-form-group">
        <label id="lblDeliverableImage" class="lable-Icon" [matTooltip]="Messages.PICTURES_FORM_MESSAGE">LOGO&nbsp;
            <div class="image"></div>&nbsp;
            <span id="deliverableImage_text_hint" *ngIf="maxFileSize"
                class="star">{{Messages.FILE_LIMIT_MESSAGE}}</span>
            <span class="star" *ngIf="isPop" >{{Messages.PICTURES_FORM_MESSAGE}}</span>
        </label>
        <div id="deliverableImage_text_hint" class="exceeds-size-show" [attr.aria-label]="Messages.FILE_TOTAL_MESSAGE" >{{Messages.FILE_TOTAL_MESSAGE}}</div>
        <div class="form-group">
            <input checked="checked" id="deliverable_image" type="file" #file class="form-control"
                (change)="getImagePreview(file.files)" />
            <div class="image_preview" [ngClass]="{'context-error': isFile}" >
                <div *ngIf="image_url" [style.background-image]="'url(' + image_url  + ')'" class="image_preview_inner"></div>
                <label id="lblReplacePhoto" for="deliverable_image" class="label-btn" tabindex="0" (keydown.enter)="replacePhoto()"><span
                        class="icon ic-file"></span>Upload</label>
            </div>

        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="story-edit-add-btn-wrap">
        <button id="cancel" type="button" mat-stroked-button color="primary" (click)="onCancel()">CANCEL</button>
        <button id="okBtn" type="button" mat-flat-button color="primary" (click)="onSave()" cdkFocusInitial>OK</button>
    </div>
</form>