import { Component, Input, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Services } from '@app/services/services';
import { WorkflowStatus, Messages, OrgType } from '@app/models';

@Component({
  selector: 'app-story-list-view',
  outputs: ['update', 'onClick'],
  templateUrl: './story-list-view.component.html',
  // styleUrls: ['./story-list-view.component.scss']
})
export class StoryListViewComponent implements OnInit {
  @Input('story') story: any;
  @Input('action') action: boolean;
  @Input('isFromSearch') isFromSearch: boolean;
  onClick: EventEmitter<any> = new EventEmitter<any>();
  update: EventEmitter<any> = new EventEmitter<any>();
  isLabs: any;
  isLiquidStudio: any;
  isIncubation: any;
  imgURL;
  constructor(
    public dialog: MatDialog,
    public services: Services
  ) {
    this.imgURL = this.services.storyCommonService.defaultStoryImage;
  }

  ngOnInit(): void {
    if (this.story && this.story.pictures?.length) {
      const firstSSImage = this.adjustPicturesOrder();
      this.services.storyEditCommonService.getStoryImage(this, firstSSImage.image_url);
    }
    this.checkOrgType();
  }

  adjustPicturesOrder() {
    if (this.story.pictures[0].order) {
      this.story.pictures.sort(this.compare('order'));
    }
    return this.story.pictures[0];
  }

  compare(property) {
    return function (a, b) {
      const value1 = a[property];
      const value2 = b[property];
      return value1 - value2;
    };
  }


  checkOrgType() {
    if (this.story?.organization_type) {
      this.isLabs = this.story.organization_type.name == OrgType.LABS ? true : false;
      this.isLiquidStudio = this.story.organization_type.name == OrgType.LIQUID ? true : false;
      this.isIncubation = this.story.organization_type.name == OrgType.INCUBATION ? true : false;
    }
  }

  onStoryClick() {
    this.onClick.emit(this.story);
  }
}
