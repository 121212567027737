/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';

export class GroupService {
  getGroups(params): Observable<any> {
    return of(params);
  }

  addGroup(reqbody): Observable<any> {
    return of(reqbody);
  }
  editGroup(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  updateGroupStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  groupFileDownload(deliverableIds): Observable<any>{
    return of(deliverableIds);
  }
}