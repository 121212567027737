<form fxLayout="column" class="deliverable-dialog">
  <div fxHide [fxShow]="inComplete.length" fxLayout="column">
    <h2 id="incomplete" class="dialog-title">Incomplete Page</h2>
    <div fxLayout="column" class="dialog-content">
      <p id="incompleteText">This page cannot be published as there are incomplete fields. Please fill out the
        following before trying again:</p>
      <ul>
        <li id="option{{i}}" *ngFor="let option of inComplete;let i=index">- {{option}}</li>
      </ul>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
      <button id="cancel1" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
      <button id="goToEdit" mat-flat-button type="button" color="primary" [routerLink]="['/stories', story._id]"
        [queryParams]="{incomplete:true}" mat-dialog-close cdkFocusInitial>Go to edit</button>
    </div>
  </div>
  <div fxHide [fxShow]="!inComplete.length" fxLayout="column">
    <h2 id="publish" class="dialog-title">PUBLISH SUCCESS STORY</h2>
    <div fxLayout="column" class="dialog-content">
      <p id="publishText">This will be sent to
        <b>{{publishApprovers}}</b> for review prior to publishing. Are you sure you want to publish?</p>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
      <button id="cancel2" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
      <button id="sendRequest" mat-flat-button type="button" color="primary" (click)="onSendRequest()" cdkFocusInitial>Send Request</button>
    </div>
  </div>
</form>