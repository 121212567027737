/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Messages } from '@app/models';
import { OrgType } from '@app/models/organizationType';
import { CommonService, UserService } from '@app/services';
import { PartnerService } from '@app/services/partner.service';
import { Services } from '@app/services/services';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-partner-popup',
  templateUrl: './partner-popup.component.html',
  styleUrls: ['./partner-popup.component.scss']
})
export class PartnerPopupComponent implements OnInit, AfterContentChecked {
  loadOwner = 0;
  loadSecondaryOwner = 0;
  showForm: UntypedFormGroup;
  submitted = false;
  parnterName = '';
  partnerLeadChanged: Subject<string> = new Subject<string>();
  secondaryPartnerLeadChanged: Subject<string> = new Subject<string>();
  public inputPartnerName: any = '';
  public selectedPartnerType = '';
  public parnterselectedOrg: Array<any> = [];
  public parnterselectedGroup: Array<any> = [];
  public partnerRelationshipOwner: Array<any> = [];
  public marketUnit = '';
  public description = '';
  public inputmarketUnit: any = '';
  public inputmarket: any = '';
  public inputCountry: any = '';
  public inputDescription: any = '';
  public loadVal = 0;
  inputLocation: any = '';
  selectedVariable: any = '';
  selectedId: string;
  selectedSecondaryId: string;
  inputUser: string;
  inputSecondaryUser: string;
  eacQrtr: any = '';
  orgTypes: Array<any> = [];
  PartnerTypes: Array<any> = [];
  secondaryGroups: Array<any> = [];
  isManualSelectedForOneOpt: any = false;
  isManualSelectedForOneOptOfMU: any = false;
  isManualSelectedForOneOptOfLocation: any = false;
  options: any = {
    users: [],
    secondaryUsers: [],
  }
  showPop = false;
  partnerRelatedDataList: Array<any> = [];
  inputPartnerLead: any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<PartnerPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private partnerService: PartnerService,
    private userService: UserService,
    public services: Services,
    private el: ElementRef,
    public render: Renderer2,
    public changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.orgTypes = this.data.orgTypes;
    this.secondaryGroups = this.data.secondaryGroups;
    this.eacQrtr = this.data.eac_qrtr;
    this.PartnerTypes = this.data.PartnerTypes;
    if (this.data.value) {
      if (!this.data.value.country) {
        // reset flag when country is blank
        this.isManualSelectedForOneOpt = true;
      }
      if (!this.data.value.marketUnit) {
        // reset flag when Market Unit is blank
        this.isManualSelectedForOneOptOfMU = true;
      }
      if (!this.data.value.location) {
        // reset flag when location is blank
        this.isManualSelectedForOneOptOfLocation = true;
      }
      if (this.data.value['partner_lead']) {
        this.selectedId = this.data.value['partner_lead']['eso_id'];
        this.inputUser = this.data.value['partner_lead']['display_name'];
        this.partnerRelationshipOwner = this.data.value['partner_lead'];
      }
      if (this.data.value['partner_secondary_lead']) {
        this.selectedSecondaryId = this.data.value['partner_secondary_lead']['eso_id'];
        this.inputSecondaryUser = this.data.value['partner_secondary_lead']['display_name'];
      }
      this.parnterName = this.data.value.name;
      this.inputPartnerName = this.parnterName;
      this.selectedPartnerType = this.data.value.partnerType;
      // this.data.value.parnter_multiple_org is a []
      Object.assign(this.parnterselectedOrg, this.data.value.parnter_multiple_org);
      Object.assign(this.parnterselectedGroup, this.data.value.parnter_secondary_group);
      this.inputLocation = this.data.value.location ? this.data.value.location : null;
      this.inputmarketUnit = this.data.value.marketUnit ? this.data.value.marketUnit : null;
      this.inputmarket = this.data.value.market ? this.data.value.market : null;
      this.inputCountry = this.data.value.country ? this.data.value.country : null;
      this.inputDescription = this.data.value.parnter_description;
      if (this.data.value.eac_qrtr_qtr && this.data.value.eac_qrtr_year)
        this.eacQrtr = { qtr: this.data.value.eac_qrtr_qtr, year: this.data.value.eac_qrtr_year };
    }
    this.showForm = this.formBuilder.group({
      parnterName: [{ value: this.parnterName, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
      partnerType: [{ value: this.selectedPartnerType, disabled: false }, Validators.required],
      // For history selected multiple org data we need set the selected value is empty.
      parnterMultipleOrg: [{ value: this.parnterselectedOrg.length == 1 ? this.parnterselectedOrg[0] : null, disabled: false }, Validators.required],
      parnterSecondaryGroup: [{ value: this.parnterselectedGroup, disabled: false }],
      partnerLead: [{ value: this.partnerRelationshipOwner, disabled: false }, Validators.required],
      partnerSecondaryLead: [{ value: this.inputSecondaryUser, disabled: false }],
      marketUnit: [{ value: this.inputmarketUnit, disabled: true }],
      description: [{ value: this.inputDescription, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
      eac_qrtr: [{ value: this.eacQrtr, disabled: true }, Validators.required],
      partnerLocation: [{ value: this.inputLocation, disabled: false }],
      market: [{ value: this.inputmarket, disabled: true }],
      country: [{ value: this.inputCountry, disabled: false }]
    });
    this.partnerLeadChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['users'] = [];
          this._getOwner(filterValue);
        }
      });
    this.secondaryPartnerLeadChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['secondaryUsers'] = [];
          this._getSecondaryOwner(filterValue);
        }
      });
    this.getPartnerRelatedData();
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.showPop = true;
    }, 300);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    const commonorgTypeName = this.data.orgTypeName;
    if (this._isValid()) {
      this.loadVal++;
      const body = this.getFormData(commonorgTypeName);
      if (this.data.value) {
        this.partnerService.editPartners(this.data.value._id, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1) => {
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      } else {
        this.partnerService.addPartner(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1) => {
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }

  
  getFormData(commonorgTypeName: any) {
    const body = {
      'name': this.showForm.get('parnterName').value?.trim(), 'partnerType': this.showForm.get('partnerType').value?.trim(),
      'parnter_multiple_org': [this.showForm.get('parnterMultipleOrg').value], 'partner_common_org': commonorgTypeName,
      'marketUnit': this.showForm.get('marketUnit').value, 'parnter_secondary_group': this.showForm.get('parnterSecondaryGroup').value,
      'parnter_description': this.showForm.get('description').value?.trim(), 'eac_qrtr': this.showForm.get('eac_qrtr').value,
      'location': this.showForm.get('partnerLocation').value, 'market': this.showForm.get('market').value,
      'country': this.showForm.get('country').value,
      'partner_lead': this.showForm.get('partnerLead').value,
    };
    // body['partner_lead'] = this.selectedId;
    body['partner_secondary_lead'] = this.selectedSecondaryId ? this.selectedSecondaryId : undefined;
    return body;
  }

  onChangeUsers(event): void {
    this.selectedId = '';
    this.inputUser = '';
    this.options['users'] = [];
    this.partnerLeadChanged.next(event.target.value);
  }

  onChangeSecondaryUsers(event): void {
    this.selectedSecondaryId = '';
    this.inputSecondaryUser = '';
    this.options['secondaryUsers'] = [];
    this.secondaryPartnerLeadChanged.next(event.target.value);
  }

  selectedValue(selected): void {
    this.selectedId = selected.option.value.eso_id;
    this.inputUser = selected.option.value.display_name;
  }
  getBusinessOperationsContactLead(option) {
    const display_name = option.display_name? option.display_name : option
    return display_name;
  }
  selectedSecondaryValue(selected): void {
    this.selectedSecondaryId = selected.option.value.eso_id;
    this.inputSecondaryUser = selected.option.value.display_name;
  }

  _isValid(): boolean {
    this.inputPartnerLead = document.getElementById('partnerLead');
    if (this.showForm.valid && (this.selectedId !== '' || this.inputPartnerLead.value == '')) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  _getOwner(searchText: any): void {
    this.loadOwner++;
    this.userService.getUsers(searchText)
      .subscribe((response) => {
        this.loadOwner--;
        if (response && response.result) {
          this.options.users = response.result;
        }
      });
  }

  _getSecondaryOwner(searchText: any): void {
    this.loadSecondaryOwner++;
    this.userService.getUsers(searchText)
      .subscribe((response) => {
        this.loadSecondaryOwner--;
        if (response && response.result) {
          this.options.secondaryUsers = response.result;
        }
      });
  }

  // _getSecondaryOwner(searchText: any): void {
  //   this.loadSecondaryOwner++;
  //   const secondaryOwner = [];
  //   const partnerLead = this.showForm.get('partnerLead').value;
  //   this.userService.getUsers(searchText)
  //     .subscribe((response) => {
  //       this.loadSecondaryOwner--;
  //       if (response && response.result) {
  //         response.result?.forEach((user) => {
  //           if (partnerLead?.eso_id !== user?.eso_id) {
  //             secondaryOwner.push(user);
  //           }
  //         });
  //         this.options.secondaryUsers = secondaryOwner;
  //       }
  //     });
  // }


  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  changePartnerNameValue(name) {
    this.inputPartnerName = name;
  }
  selectedPartnerTypeValue(value): void {
    this.selectedPartnerType = value;
  }
  changeMarketUnitValue(tooltip) {
    this.inputmarketUnit = tooltip;
  }
  changeDescriptionValue(tooltip) {
    this.inputDescription = tooltip;
  }
  get Messages(): any { return Messages; }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    let values = this.showForm.get(field).value;
    if (!values) {
      values = [];
    }
    const input = document.getElementById(field);
    const index = this.services.commonService.objectIndexOf(values, event.option.value, key);
    if (index < 0) {
      values.push(event.option.value);
    }
    // reset flag when secondary group changes
    if (field === 'parnterSecondaryGroup') {
      this.isManualSelectedForOneOptOfLocation = false;
    }
    // when TI relationship owner selected, set it's eso_id to selectedId, used for validate.
    if (field === 'partnerLead') {
      this.selectedId = event.option.value.eso_id;
    }
    input['value'] = '';
    input.blur();
    this.showForm.get(field).setValue(values);
    this.showForm.get(field).markAsDirty();
  }

  selectedPrimaryGroupValue(field, value): void {
    this.showForm.get(field).setValue(value); // single select.
    this.showForm.get(field).markAsDirty();
  }

  disableScrollBar() {
    this.render.setStyle(this.el.nativeElement.querySelector('.add-dialog'), 'pointer-events', 'none');
  }
  enableScrollBar() {
    this.render.setStyle(this.el.nativeElement.querySelector('.add-dialog'), 'pointer-events', 'auto');
  }

  filterSecondaryGroupByOrg() {
    const org_type = this.showForm.get('parnterMultipleOrg').value != null && [this.showForm.get('parnterMultipleOrg').value][0]._id != '' ? [this.showForm.get('parnterMultipleOrg').value] : [];
    const secondary_group = this.showForm.get('parnterSecondaryGroup').value ? this.showForm.get('parnterSecondaryGroup').value : [];
    const options = [];
    const showSecondaryGroup = [];
    if (org_type.length > 0) {
      org_type.forEach((item) => {
        this.secondaryGroups.forEach((group) => {
          if (item.name == group.organizationType.name) {
            if (this.services.commonService.objectIndexOf(options, group) < 0) {
              options.push(group);
            }
          }
        });
        if (secondary_group.length > 0) {
          secondary_group.forEach((group) => {
            if (item.name == group.organizationType.name) {
              if (this.services.commonService.objectIndexOf(showSecondaryGroup, group) < 0) {
                showSecondaryGroup.push(group);
              }
            }
          });
          this.showForm.get('parnterSecondaryGroup').setValue(showSecondaryGroup);
        }
      });
      if (org_type.length == 1 && org_type[0].name == OrgType.LIQUID) {
        this.showForm.get('parnterSecondaryGroup').disable({ emitEvent: false });
        this.showForm.get('parnterSecondaryGroup').setValue([]);
      } else {
        this.showForm.get('parnterSecondaryGroup').enable({ emitEvent: false });
      }
    } else {
      this.showForm.get('parnterSecondaryGroup').disable({ emitEvent: false });
      this.showForm.get('parnterSecondaryGroup').setValue([]);
    }
    return options;
  }
  filterLocationBySecondaryGroup() {
    let options = [];
    const org_type = this.showForm.get('parnterMultipleOrg').value != null && [this.showForm.get('parnterMultipleOrg').value][0]._id != '' ? [this.showForm.get('parnterMultipleOrg').value] : [];
    const secondary_group = this.showForm.get('parnterSecondaryGroup').value ? this.showForm.get('parnterSecondaryGroup').value : [];
    if (org_type && org_type.length > 0) {
      let isShow = false;
      isShow = org_type.filter((o) => o.name == OrgType.LIQUID).length > 0;
      if (isShow) {
        this.data.parnterLQLocation.forEach((element) => {
          options.push(element.name);
        });
      }
      if (secondary_group && secondary_group.length > 0) {
        secondary_group.forEach((item) => {
          item.locations?.forEach((location) => {
            options.push(location.name);
          });
        });
      }
      options = _.uniq(options);
      // autoselect the option when only 1 option and no manually select action
      if (options.length == 1 && !this.isManualSelectedForOneOptOfLocation) {
        this.showForm.get('partnerLocation').setValue(options[0]);
      }
      if (secondary_group && secondary_group.length > 0 || isShow) {
        this.showForm.get('partnerLocation').enable({ emitEvent: false });
      } else {
        this.showForm.get('partnerLocation').disable({ emitEvent: false });
        this.showForm.get('partnerLocation').setValue(null);
      }
    } else {
      this.showForm.get('partnerLocation').disable({ emitEvent: false });
      this.showForm.get('partnerLocation').setValue(null);
    }
    return options;
  }

  /**
   * get country option list from DB
   * @returns country option list
   */
  filterCountryOptionList() {
    let options = [];
    let tempResult = [];
    const country = this.showForm.get('country').value ? this.showForm.get('country').value : null;
    if (this.partnerRelatedDataList?.length > 0) {
      tempResult = this.partnerRelatedDataList;
      if (tempResult && tempResult.length > 0) {
        tempResult.forEach((item) => {
          options.push(item.country);
        });
        options = _.uniq(options);
        this.showForm.get('country').setValue(country);
      }
    } else {
      this.showForm.get('country').setValue(country);
    }
    return options;
  }

  /**
   * filter market/market unit option list by country
   */
  filterMarketAndMUByCountry() {
    let options = [];
    const country = this.showForm.get('country').value ? this.showForm.get('country').value : null;
    if (country && this.partnerRelatedDataList && this.partnerRelatedDataList.length > 0) {
      const temp = this.partnerRelatedDataList.filter((o) => o.country == country);
      const tempMU = [];
      let tempMarket = [];
      if (temp && temp.length > 0) {
        temp.forEach((item) => {
          tempMU.push(item.market_unit);
          tempMarket.push(item.market);
        });
        options = _.uniq(tempMU);
        tempMarket = _.uniq(tempMarket);
        this.showForm.get('market').setValue(tempMarket[0]);
        // autoselect the option when only 1 option and no manually select action
        if (options.length == 1 && !this.isManualSelectedForOneOptOfMU) {
          this.showForm.get('marketUnit').setValue(options[0]);
        }
        this.showForm.get('marketUnit').enable({ emitEvent: false });
      } else {
        this.showForm.get('market').setValue(null);
        this.showForm.get('marketUnit').setValue(null);
        this.showForm.get('marketUnit').disable({ emitEvent: false });
      }
    } else {
      this.showForm.get('marketUnit').disable({ emitEvent: false });
      this.showForm.get('market').setValue(null);
    }
    return options;
  }

  /**
   * Trigger Event when selection change
   * @param event
   */
  onSelectionChangeLocation(event) {
    this.showForm.get('partnerLocation').setValue(event.value);
    if (!event.value && event.source.options.length == 2) {
      this.isManualSelectedForOneOptOfLocation = true;
    }
    // reset flag and value for Country when location changes
    this.isManualSelectedForOneOpt = false;
    this.showForm.get('country').setValue(null);
  }
  onSelectionChangeCountry(event) {
    this.showForm.get('country').setValue(event.value);
    if (!event.value && event.source.options.length == 2) {
      this.isManualSelectedForOneOpt = true;
    }
    // reset flag and value for Market Unit when country changes
    this.isManualSelectedForOneOptOfMU = false;
    this.showForm.get('marketUnit').setValue(null);
  }
  onSelectionChangeMU(event) {
    this.showForm.get('marketUnit').setValue(event.value);
    if (!event.value && event.source.options.length == 2) {
      this.isManualSelectedForOneOptOfMU = true;
    }
  }
  getPartnerRelatedData() {
    this.partnerService.getParnterRelatedData().subscribe((response) => {
      if (response && response.result) {
        this.partnerRelatedDataList = response.result;
      }
    });
  }
  getOrganizationTypes() {
    return this.data.orgTypes;
  }


  getMultipleSelectValues(field: string): any {
    return this.showForm.get(field).value;
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    const values = this.showForm.get(field).value;
    const index = this.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    // reset flag when secondary group changes
    if (field === 'parnterSecondaryGroup') {
      this.isManualSelectedForOneOptOfLocation = false;
    }
    this.showForm.get(field).setValue(values);
    this.showForm.get(field).markAsDirty();
  }
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }
}
