
import { of, Observable } from 'rxjs';
export class StageService {
    getStages(params = null): Observable<any> {
    return of(params);
  }
  editStages(params, reqbody): Observable<any> {
    return of(reqbody);
  }
  getBydeliverableType(params=null): Observable<any> {
    return of(params);
  }


}
