/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {GroupService } from '@app/services/group.service';
import { saveAs } from 'file-saver';
import { CommonService } from '@app/services';
@Component({
  selector: 'app-disable-group-popup',
  templateUrl: './disable-group-popup.component.html',
  // styleUrls: ['./disable-group-popup.component.scss']
})
export class DisableGroupPopupComponent {
  text = '';
  loadVal = 0;
  public file:any;
  constructor(
    private commonService: CommonService,
    private groupService: GroupService,
    public dialogRef: MatDialogRef<DisableGroupPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  OnConfirmation():void{
    this.dialogRef.close(true);
  }
  onCancel():void{
    this.dialogRef.close(false);
  }
  onDownload():void{
    this.loadVal++;
    let groupIdArray = {'type': this.data.type, 'ids': [] };
    this.data.group_id.forEach((element) => {
      groupIdArray['ids'].push(element._id);
    });
    this.groupService.groupFileDownload(groupIdArray)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          const blob = new Blob([response.body], { type: response.headers.get('content-type') });
          const fileName = 'Disabling ' + this.data.type + '- Impacted Deliverable List';
          // // fileName = fileName || response.headers.get('content-disposition').split(';')[0];
          const file = new File([blob], fileName, { type: response.headers.get('content-type') });
          saveAs(file);
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
}
