/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class ReportingService {
  getQuarters(): Observable<any> {
    return of(null);
  }
  getStoryQuarters(): Observable<any> {
    return of(null);
  }

  getDeliverableTypeByMetrics(params = null): Observable<any> {
    return of(params);
  }

  getDeliverables(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }

  /**
   * get publication list data
   */
  getPublicationsList(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }
 
  export(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }

  getSettings(): Observable<any> {
    return of(null);
  }

  getSetting(_id): Observable<any> {
    return of(_id);
  }

  deleteSetting(_id): Observable<any> {
    return of(_id);
  }

  saveSetting(formData: any): Observable<any> {
    return of(formData);
  }

  updateSetting(_id, formData: any): Observable<any> {
    return of(formData);
  }

  downloadPdf(parameters: any, htmlText: any): Observable<any> {
    return of({ parameters, htmlText });
  }
}
