/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { Injectable } from '@angular/core';
import { AmTestService } from '../amtest.service';

@Injectable({
  providedIn: 'root'
})
export class AmTestAPIService implements AmTestService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  /**
   * get all test data
   * @param parameters query string parameters
   * @returns result
   */
  getAllTestData(parameters): Observable<any> {
    this.commonService.log('GET:', '/amtest', 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get(`${environment.API_URL}/amtest`, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  /**
   * get test data by id
   * @param parameters query string parameters
   * @returns result
   */
  getTestDataById(parameters: any): Observable<any> {
    this.commonService.log('GET:', '/amtest/${parameters._id}', 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get(`${environment.API_URL}/amtest/${parameters._id}`, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  /**
   * add test data
   * @param parameters parameters query string parameters
   * @param formData new test data
   * @returns result
   */
  addTestData(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/amtest', 'BODY:', { newData: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/amtest`, { newData: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  /**
   * update test data by id
   * @param parameters query string parameters
   * @param formData new test data
   * @returns result
   */
  updateTestData(parameters: any, formData: any): Observable<any> {
    this.commonService.log('PUT:', `/amtest/${parameters._id}`, 'BODY:', { newData: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.put(`${environment.API_URL}/amtest/${parameters._id}`, { newData: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  /**
   * delete test data logicly (available => false)
   * @param parameters query string parameters
   */
  deleteTestData(parameters: any): Observable<any> {
    this.commonService.log('DELETE:', `/amtest/${parameters._id}`, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.delete(`${environment.API_URL}/amtest/${parameters._id}`, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}