/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';
export class StatusService {
  getStatus(params): Observable<any> {
    return of(params);
  }

  addStatus(reqbody): Observable<any> {
    return of(reqbody);
  }
  changeStatus(params, reqbody): Observable<any> {
    return of(reqbody);
  }
  editStatus(params, reqbody): Observable<any> {
    return of(reqbody);
  }

  saveStatus(reqBody): Observable<any> {
    return of(reqBody);
  }
}
