/* eslint-disable complexity */
/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-self-assign */
/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { Animations } from '@app/app-animations';
import { ReportFilterComponent } from '@app/components/report-filter/report-filter.component';
import { DialogProgressComponent } from '@app/dialog-progress/dialog-progress.component';
import { Services } from '@app/services/services';
import { htmlToPptxText } from 'html-pptxgenjs';
import { Role } from '@app/models';
// for PPT
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import pptxgen from 'PptxGenJS';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-stories',
  templateUrl: './success-stories.component.html',
  styleUrls: ['../reporting.component.scss'],
  animations: [
    Animations.slideMenu
  ],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class SuccessStoriesComponent implements OnInit, AfterViewInit {
  @Input('isInStoiesTab') isInStoiesTab: any;
  @Input('storyFilter') filter: any;
  @Input('storyPagination') pagination: any;
  isShowStoryExportReport: any = true;
  loading: any = 0;
  search: any = null;
  stories: Array<any> = [];
  pptStories: Array<any> = [];
  ContactHashTag:any=[];
  totalRecords: any = 0;
  filterMenu = false;
  showMenu = false;
  pageSizeOptions = [10, 20, 30, 40, 50];
  showFilter: any = false;
  displayedColumns = ['Title', 'owner', 'organization_type', 'primary_group', 'primary_workstream', 'primary_location', 'eac_qrtr', 'markets', 'force', 'nested_themes', 'action'];
  hideFilterFields = false;
  showWorkflowStatus = false;
  setting = null;
  ssmandatoryFields: Array<any> = [
    { _id: 'owner', select: true, label: 'Owner', disabled: false },
    { _id: 'organization_type', select: true, label: 'Organization', disabled: false },
    { _id: 'primary_group', select: true, label: 'Group', disabled: false },
    { _id: 'primary_workstream', select: true, label: 'Workstream', disabled: false },
    { _id: 'primary_location', select: true, label: 'Location', disabled: false },
    { _id: 'eac_qrtr', select: true, label: 'FiscalYear Quarter', disabled: false },
    { _id: 'markets', select: true, label: 'Market', disabled: false },
    { _id: 'force', select: true, label: 'Force Name', disabled: false },
    { _id: 'nested_themes', select: true, label: 'Nested Themes', disabled: false },

  ];
  get Role(): any { return Role; }
  ssoptionalFields: Array<any> = [

    { _id: 'additional_team_members', select: false, label: 'Additional Team Members', disabled: false },
  ];
  exportReport() {
    alert('Beware that the downloaded file may contain client names and references Accenture data that could be highly confidential.');
    // Add your report exporting code here
  }
  // for PPT
  deliverableList = [];
  publicationList = [];
  publicationListOfPptx = [];
  DELIVERABLE_STATUS = {
    InProgress: 'In Progress',
    Complete: 'Complete',
    OnHold: 'On Hold'
  };
  dialogRef;
  isWaitingForPptGenrating = false;

  @ViewChild('commonFilter') reportFilter: ReportFilterComponent;
  // @ViewChild('kpiView') kpiComponent: KPIComponent;
  targetPropertiesList = [];
  specialCharactersList = [];
  targetCharactersDic = {};
  iconShapeInfo: any;
  loadIcon = 0;
  dataImages: any = {};
  dataIcons: any = {};
  isForList = true;
  hideForceNestedMarket = false;
  relatedDelThumbnailSize: any = {
    width: 80,
    height: 64
  }
  bulletIndentForPpt: any = '';
  denominatorForPpt: any = '';
  Context: any = [];
  Solution: any = [];
  Impact: any = [];
  constructor(
    public dialog: MatDialog,
    public services: Services
  ) {
  }
  ngAfterViewInit(): void {
    this.setTableSize();
  }

  ngOnInit(): void {
    this.isWaitingForPptGenrating = false;
    this.services.commonService.showHeader.emit(true);
    this._getStories();
    this._getIconShapeInfo();
    this.services.storyCommonService.isNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (this.isInStoiesTab && isNeedToRefresh) {
        this._getStories();
      }
    });
    // if (this.isInStoiesTab) {
    // 	this._getStories();
    // }
    // });
    // set table filter
    this.services.commonService.forceRelatedNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.hideForceNestedMarket = this.services.commonService.globalFlags['hideForceNestedMarket'];
      }
    });
    this.hideForceNestedMarket = this.services.commonService.globalFlags['hideForceNestedMarket'];
    if (this.services.commonService.getSessionData('sstable_filter_key')) {
      this.ssmandatoryFields = this.services.commonService.getSessionData('ssmandatoryFields');
      this.ssoptionalFields = this.services.commonService.getSessionData('ssoptionalFields');
      this.hideFilterFields = this.services.commonService.getSessionData('sshideFilterFields');
      this.showWorkflowStatus = this.services.commonService.getSessionData('ssshowWorkflowStatus');
      this.onApplyTableFilter();
      this.prePagination();
    }
    this.setting = this.services.commonService.getSessionData('LOAD_FILTER_SETTING');
    this.isShowStoryExportReport = environment.DISPLAY_STORY_EXPORT_REPORT;
  }

  prePagination(): void {
    const pagination = this.services.commonService.getSessionData('REPORTING_STORY_PAGINATION');
    if (pagination) {
      const previousUrl = this.services.routerExtService.getPreviousUrl();
      if (previousUrl.includes('/reporting/story/')) {
        this.pagination.page_number = pagination.page_number;
        this.pagination.page_size = pagination.page_size;
      }
      this.pagination.search = pagination.search;
      this.search = pagination.search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
    }
  }


  _getStories() {
    this.loading++;
    setTimeout(() => {
      this.services.commonService.setSessionData('REPORTING_STORY_PAGINATION', this.pagination);
      const filter = this.services.storyCommonService.getStoryFilterData(this.filter);
      this.services.kpiService.getStory(this.pagination, filter)
        .subscribe((response) => {
          this.loading--;
          if (response && response.stories) {
            this.stories = response.stories;
            this.stories = [...this.stories];
            this.totalRecords = response.total;
            this.onApplyTableFilter();
          }
        });
    }, 1);
  }

  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this.services.storyCommonService.isNeedToRefresh.next(true);
  }

  onSort(sort) {
    if (this.pagination.sort === sort) {
      this.pagination.sort_dir = this.pagination.sort_dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.pagination.sort_dir = 'asc';
    }
    this.pagination.sort = sort;
    this.pagination.page_number = 0;
    this.services.deliverableCommonService.isNeedToRefresh.next(true);
  }
  setTableSize() {
    const ele = document.getElementById('reporting-list-row');
    if (ele) {
      setTimeout(() => {
        const tableContainer = document.getElementById('table-container');
        if (tableContainer) {
          tableContainer.style.width = ele.offsetWidth - 120 + 'px';
        }
        const tableFooterContainer = document.getElementById('reporting-list-footer-inner');
        if (tableFooterContainer) {
          tableFooterContainer.style.width = ele.offsetWidth - 120 - 58 + 'px';
        }
      }, 100);
    }
  }

  onResize() {
    this.setTableSize();
  }

  _addColumn(field) {
    const index = this.services.commonService.objectIndexOf(this.displayedColumns, field, null);
    if (index < 0) {
      this.displayedColumns.push(field);
    }
  }

  _removeColumn(field) {
    const index = this.services.commonService.objectIndexOf(this.displayedColumns, field, null);
    if (index >= 0) {
      this.displayedColumns.splice(index, 1);
    }
  }

  _disableCheckBox(fields, key, disabled) {
    const index = this.services.commonService.objectIndexOf(fields, { _id: key });
    if (index >= 0) {
      fields[index].disabled = disabled;
    }
  }

  onOpenTableFilter() {
    this.filterMenu = !this.filterMenu;
  }

  onShowAllChange($event, fields) {
    if ($event.checked) {
      fields.forEach((field) => {
        field.select = true;
      });
    } else {
      fields.forEach((field) => {
        field.select = false;
      });
    }
  }

  checkAllSelect(fields) {
    let result = true;
    fields.forEach((field) => {
      if (!field.select) {
        result = false;
      }
    });
    return result;
  }

  onHideFilterFieldsChange() {
    if (this.hideFilterFields && this.filter) {
      if (this.filter.group && this.filter.group.length) {
        this._disableCheckBox(this.ssmandatoryFields, 'primary_group', true);
      } else {
        this._disableCheckBox(this.ssmandatoryFields, 'primary_group', false);
      }
      if (this.filter.workstream && this.filter.workstream.length) {
        this._disableCheckBox(this.ssmandatoryFields, 'primary_workstream', true);
      } else {
        this._disableCheckBox(this.ssmandatoryFields, 'primary_workstream', false);
      }
      if (this.filter.location && this.filter.location.length) {
        this._disableCheckBox(this.ssmandatoryFields, 'primary_location', true);
      } else {
        this._disableCheckBox(this.ssmandatoryFields, 'primary_location', false);
      }
      if (this.filter.eac_qrtr) {
        this._disableCheckBox(this.ssmandatoryFields, 'eac_qrtr', true);
      } else {
        this._disableCheckBox(this.ssmandatoryFields, 'eac_qrtr', false);
      }
      if (this.filter.organization && this.filter.organization.length) {
        this._disableCheckBox(this.ssmandatoryFields, 'organization_type', true);
      } else {
        this._disableCheckBox(this.ssmandatoryFields, 'organization_type', false);
      }
    } else {
      this._disableCheckBox(this.ssmandatoryFields, 'owner', false);
      this._disableCheckBox(this.ssmandatoryFields, 'primary_group', false);
      this._disableCheckBox(this.ssmandatoryFields, 'primary_workstream', false);
      this._disableCheckBox(this.ssmandatoryFields, 'primary_location', false);
      this._disableCheckBox(this.ssmandatoryFields, 'eac_qrtr', false);
      this._disableCheckBox(this.ssmandatoryFields, 'organization_type', false);
    }
  }

  onApplyTableFilter() {
    this.filterMenu = false;
    this.displayedColumns = [];
    this._addColumn('Title');

    this.ssmandatoryFields.forEach((field) => {
      if (field.select) {
        this._addColumn(field._id);
      }
    });
    this.services.commonService.setSessionData('ssmandatoryFields', this.ssmandatoryFields);

    this.ssoptionalFields.forEach((field) => {
      if (field.select) {
        this._addColumn(field._id);
      }
    });
    this.services.commonService.setSessionData('ssoptionalFields', this.ssoptionalFields);
    if (this.showWorkflowStatus) {
      this._addColumn('workflow_status');
    }
    this.services.commonService.setSessionData('ssshowWorkflowStatus', this.showWorkflowStatus);
    if (this.hideFilterFields && this.filter) {
      if (this.filter.group && this.filter.group.length) {
        this._removeColumn('primary_group');
      }
      if (this.filter.workstream && this.filter.workstream.length) {
        this._removeColumn('primary_workstream');
      }
      if (this.filter.location && this.filter.location.length) {
        this._removeColumn('primary_location');
      }
      // if (this.filter.start_qrtr) {
      //   this._removeColumn('start_qrtr');
      // }
      if (this.filter.eac_qrtr) {
        this._removeColumn('eac_qrtr');
      }
      if (this.filter.organization && this.filter.organization.length) {
        this._removeColumn('organization_type');
      }
    }
    this.onHideFilterFieldsChange();
    // action move to last column
    this._addColumn('action');
    this.services.commonService.setSessionData('sshideFilterFields', this.hideFilterFields);

    // save table filter
    this.services.commonService.setSessionData('sstable_filter_key', this.displayedColumns);
  }

  onExport() {
    const filter = this.services.storyCommonService.getStoryFilterData(this.filter);
    const parameters = { search: this.pagination.search };
    this.services.storiesService.export(parameters, filter)
      .subscribe((response) => {
        if (response) {
          const fileName = 'success stories.xlsx';
          const blob = new Blob([response.body], { type: response.headers.get('content-type') });
          // fileName = response.headers.get('content-disposition').split(';')[1] || fileName;
          const file = new File([blob], fileName, { type: response.headers.get('content-type') });
          saveAs(file);
        }
      });
  }

  showSubMenu() {
    if (this.showMenu) {
      this.showMenu = false;
      return;
    }
    this.showMenu = true;
  }

  hasFilter() {
    let res = false;
    for (const item in this.filter) {
      if (this.filter[item] && this.filter[item].length) {
        res = true;
      } else if (item == 'eac_qrtr' && this.filter[item]) {
        res = true;
      }
    }
    return res;
  }

  _getIconShapeInfo(): void {
    this.loadIcon++;
    this.services.storiesService.getIconShapeInfo()
      .subscribe((response) => {
        this.loadIcon--;
        if (response && response.result) {
          this.iconShapeInfo = response.result;
        }
      });
  }

  /**
 * download ppt slide
 */
  downloadPPT(isconfidential = false) {
    this.services.commonService.setSessionData('REPORTING_STORY_PAGINATION', this.pagination);
    const filter = this.services.storyCommonService.getStoryFilterData(this.filter);
    this.services.storiesService.exportPPT(this.pagination, filter)
      .subscribe((response) => {
        if (response && response.stories) {
          this.pptStories = response.stories;
          this.pptStories = [...this.pptStories];
          this.targetPropertiesList = response.targetPropertiesList ? response.targetPropertiesList : [];
          this.specialCharactersList = response.specialCharactersList ? response.specialCharactersList : [];
          this.targetCharactersDic = response.targetCharactersDic ? response.targetCharactersDic : {};
          this.denominatorForPpt = response.denominatorForPpt ? response.denominatorForPpt : 10;
          this.bulletIndentForPpt = response.bulletIndentForPpt ? response.bulletIndentForPpt : 10;
          this.isWaitingForPptGenrating = true;
          const pptx = new pptxgen();

          // popup process waiting page
          setTimeout(() => {
            this.dialogRef = this.dialog.open(DialogProgressComponent, {
              width: '550px',
              maxHeight: '95vh',
              disableClose: true,
            });
            this.dialogRef.afterClosed().subscribe(() => {
              this.isWaitingForPptGenrating = false;
            });
          }, 0);

          // download all images(icons and pictures)
          this.getAllImagesForPpt();

          // check if images are downloaded
          this.checkIfHasImageLoading(isconfidential, pptx).subscribe((isAllImagesDone) => {
            if (isAllImagesDone) {
              // build and setting pptx detail
              this.buildAndSettingPptxDetail(isconfidential, pptx);
            }
          });
        }

      });
  }

  /**
   * check if has loading image
   */
  checkIfHasImageLoading(isconfidential, pptx): Observable<boolean> {
    const imageIds = Object.keys(this.dataImages);
    const iconIds = Object.keys(this.dataIcons);
    const hasImageLoading = imageIds.some((imageId) => this.dataImages[imageId] === 'loading');
    const hasIconLoading = iconIds.some((iconId) => this.dataIcons[iconId] === 'loading');
    if (hasImageLoading || hasIconLoading) {
      setTimeout(() => {
        this.checkIfHasImageLoading(isconfidential, pptx).subscribe((isAllImagesDone) => {
          if (isAllImagesDone) {
            // build and setting pptx detail
            this.buildAndSettingPptxDetail(isconfidential, pptx);
          }
        });
      }, 300);
      return of(false);
    } else {
      return of(true);
    }
  }

  /**
   * get all images for ppt
   */
  getAllImagesForPpt() {
    this.pptStories?.forEach((item) => {
      this.adjustPicturesOrder(item);
      item.pictures?.forEach((picture) => {
        if (picture && picture.image_url) {
          const dataId = picture._id;
          if (this.dataImages[dataId] && this.dataImages[dataId] != 'loading') {
            return this.dataImages[dataId];
          } else if (this.dataImages[dataId] != 'loading') {
            this.dataImages[dataId] = 'loading';
            this.services.masterService.getImage(picture.image_url, 'story', this.isForList, this.relatedDelThumbnailSize)
              .subscribe((response) => {
                if (response && response.size > 0) {
                  const reader = new FileReader();
                  reader.readAsDataURL(response);
                  reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result.toString();
                    image.onload = () => {
                      this.dataImages[dataId] = {
                        src: image.src,
                        width: image.width,
                        height: image.height
                      };
                    };
                  };
                }
              });
          }
        }
      });
      item.icons?.forEach((icon) => {
        if (icon && icon.image_url) {
          const dataId = icon._id;
          if (this.dataIcons[dataId] && this.dataIcons[dataId] != 'loading') {
            return this.dataIcons[dataId];
          } else if (this.dataIcons[dataId] != 'loading') {
            this.dataIcons[dataId] = 'loading';
            this.services.masterService.getImage(icon.image_url, 'story', this.isForList, this.relatedDelThumbnailSize)
              .subscribe((response) => {
                if (response && response.size > 0) {
                  const reader = new FileReader();
                  reader.readAsDataURL(response);
                  reader.onload = () => {
                    const image = new Image();
                    image.src = reader.result.toString();
                    image.onload = () => {
                      this.dataIcons[dataId] = {
                        src: image.src,
                        width: image.width,
                        height: image.height
                      };
                    };
                  };
                }
              });
          }
        }
      });
    });
  }
  adjustPicturesOrder(story) {
    if (story.pictures?.length > 0 && story.pictures[0].order) {
      story.pictures = story.pictures.sort(this.compare('order'));
    } else {
      story.pictures = story.pictures;
    }
    if (story.icons?.length > 0 && story.icons[0].order) {
      story.icons = story.icons.sort(this.compare('order'));
    } else {
      story.icons = story.icons;
    }
  }

  compare(property) {
    return function (a, b) {
      const value1 = a[property];
      const value2 = b[property];
      return value1 - value2;
    };
  }
  /**
   *
   *
   * build and setting pptx detail
   * @param isconfidential is confidential
   * @param pptx pptx
   */
  buildAndSettingPptxDetail(isconfidential: boolean, pptx: pptxgen) {
    for (let i = 0; i < this.pptStories.length; i++) {
      if (isconfidential) {
        this.pptStories[i].confidentiality_level = null;
      }

      // set layout: 'LAYOUT_WIDE'  (13.33" x 7.5")
      pptx.layout = 'LAYOUT_WIDE';

      // define master
      this.setSlideMaster(pptx);

      // add slide
      const slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

      // handle special characters for ppt
      this.handleSpecialCharacters(this.pptStories[i]);

      this.setSlide(slide, this.pptStories[i]);
    }
    // output pptx file
    if (this.isWaitingForPptGenrating) {
      pptx.writeFile({ fileName: 'success stories' }).then(() => {
        this.dialogRef.close(true);
      });
    }
  }

  /**
* set slide master
* @param pptx pptx
*/
  private setSlideMaster(pptx: pptxgen) {
    pptx.defineSlideMaster({
      title: 'MASTER_SLIDE',
      objects: [
        {
          text: {
            options: {
              shape: pptx.ShapeType.line, x: 0.42, y: 5.97, w: 9.49, h: 0,
              line: { color: '222435', width: 0.5, dashType: 'solid' },
            },
          },
        },
        { image: { path: 'assets/images/footer.png', x: 0.42, y: 7.09, h: 0.22, w: 0.21 } },
        {
          text: {
            options: { x: 0.78, y: 7.09, fontSize: 8, fontFace: 'Graphik (Body)', color: '000000', h: 0.22, w: 1.26, margin: [0, 0, 0, 0] },
            text: 'Internal Use Only',
          },
        },
        {
          text: {
            options: { x: 9.1, y: 7.09, fontSize: 8, fontFace: 'Graphik', align: 'right', color: '000000', h: 0.22, w: 3.4, margin: [0, 0, 0, 0] },
            text: 'Copyright © 2023 Accenture. All rights reserved.',
          },
        }
      ]
    });
  }

  /**
 * handle special characters for ppt
 */
  handleSpecialCharacters(story) {
    this.targetPropertiesList.forEach((p) => {
      this.specialCharactersList.forEach((character) => {
        story[p] = story[p]?.replace(String.fromCharCode(character), this.targetCharactersDic[character]);
      });
    });
  }


  /**
   * set slide titles, content etc. properties
   * @param slide slide
   */
  private setSlide(slide: pptxgen.Slide, story) {
    // Confidentiality level
    if (this.hideForceNestedMarket) {
      this.setConfidentialityLevel(slide, story);
    }

    // Org Group
    if (!this.hideForceNestedMarket) {
      this.setOrgtype(slide, story);
    }

    // Top title
    this.setTopTitle(slide, story);

    // Main title
    this.setMainTitle(slide, story);

    // Sub title
    this.setSubTitle(slide, story);


    // Context/Solution/Impact
    this.setContents(slide, story);

    // Contacts
    this.setContacts(slide, story);

    // pictures
    this.setPictures(slide, story);

    // icons
    this.setIcons(slide, story);
  }

  /**
* set confidentiality levle for slide
* @param slide slide
*/
  private setConfidentialityLevel(slide: pptxgen.Slide, story) {
    slide.addText(
      [
        {
          text: story?.confidentiality_level,
          options: { fontSize: 18, fontFace: 'Calibri', color: 'ff0000', bold: false, breakLine: true }
        }
      ],
      // N / 71.42857143 =>inch; margin:[left,right, bottom, top]
      { x: 0.00, y: 0.10, h: 0.30, w: 12.8, valign: 'top', align: 'right', fit: 'none', charSpacing: 0, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.8 }
    );
  }

  /**
* set setOrgtype for slide
* @param slide slide
*/
  private setOrgtype(slide: pptxgen.Slide, story) {
    slide.addText(
      [
      ],
      { x: 0.00, y: 0.00, h: 0.30, w: 6.66673, valign: 'top', align: 'left', fit: 'none', fill: { color: '000000' } }

    );
    slide.addText(
      [
      ],
      { x: 6.67995589, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }

    );
    slide.addText(
      [
      ],
      { x: 8.01330189, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }

    );
    if (story?.organization_type.name == 'Labs') {
      slide.addText(
        [
          {
            options: { fontSize: 10, fontFace: 'Graphik (Body)', color: 'BFBEBF', breakLine: true }
          }
        ],
        { x: 9.34664789, y: 0.00, h: 0.30, w: 1.32012011, valign: 'middle', align: 'left', fit: 'none', charSpacing: 0, margin: [10, 0, 0, 0], lineSpacingMultiple: 0, fill: { color: 'BFBEBF' } }
      );
    } else
      slide.addText(
        [
        ],
        { x: 9.34664789, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }
      );
    if (story?.organization_type.name == 'Incubation') {
      slide.addText(
        [
          {
            options: { fontSize: 10, fontFace: 'Graphik (Body)', color: 'BFBEBF', breakLine: true }
          }
        ],
        { x: 10.67999389, y: 0.00, h: 0.30, w: 1.32012011, valign: 'middle', align: 'left', fit: 'none', charSpacing: 0, margin: [10, 0, 0, 0], lineSpacingMultiple: 0, fill: { color: 'BFBEBF' } }
      );
    } else
      slide.addText(
        [
        ],
        { x: 10.67999389, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }
      );
    if (story?.organization_type.name == 'Liquid Studio') {
      slide.addText(
        [
          {
            options: { fontSize: 10, fontFace: 'Graphik (Body)', color: 'FFFFFF', breakLine: true }
          }
        ],
        { x: 12.01333989, y: 0.00, h: 0.30, w: 1.32012011, valign: 'middle', align: 'left', fit: 'none', charSpacing: 0, margin: [10, 0, 0, 0], lineSpacingMultiple: 0, fill: { color: 'BFBEBF' } }
      );
    } else
      slide.addText(
        [
        ],
        { x: 12.01333989, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }
      );
  }

  /**
* set top title for slide
* @param slide slide
*/
  private setTopTitle(slide: pptxgen.Slide, story) {
    let topTitleContext = [];
    if (!this.hideForceNestedMarket) {
      if (story.force?.name != 'Not Applicable') {
        if(!story.force && !story.markets){
          topTitleContext = [
            {
              text: story.organization_type.name ? story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            },
          ];
        }
        else if(!story.force){
          topTitleContext = [
            {
              text: story.organization_type.name ? story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            },
            {
              text: story.markets ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: story.markets ? story.markets : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            }
          ];
        }
        else if(!story.markets){
          topTitleContext = [
            {
              text: story.force ? story.force.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
            },
            {
              text: story.organization_type.name ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: story.organization_type.name ? story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            }
          ];
        }
        else{
          topTitleContext = [
            {
              text: story.force ? story.force.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
            },
            {
              text: story.organization_type.name ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: story.organization_type.name ? story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            },
            {
              text: story.markets ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: story.markets ? story.markets : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            }
          ];
        }
      } else {
        topTitleContext = [
          {
            text: story.organization_type.name ? story.organization_type.name : '',
            options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
          },
          {
            text: story.markets ? ' | ' : '',
            options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
          },
          {
            text: story.markets ? story.markets : '',
            options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
          }
        ];
      }
    } else {
      topTitleContext = [
        {
          text: story?.organization_type?.name,
          options: { fontSize: 14, fontFace: 'Graphik (Body)', color: '000000', bold: true }
        },
        {
          text: story?.primary_group ? ' | ' : '',
          options: { fontSize: 14, fontFace: 'Graphik (Body)', color: '000000' }
        },
        {
          text: story?.primary_group ? story?.primary_group.name : '',
          options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
        },
        {
          text: story?.primary_location ? ' | ' : '',
          options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff' }
        },
        {
          text: story?.primary_location ? story?.primary_location.name : '',
          options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
        }
      ];
    }
    slide.addText(
      topTitleContext,
      // N / 71.42857143 =>inch; margin:[left, right, bottom, top]
      { x: 0.43, y: 0.31, h: 0.37, w: 8.903422, valign: 'top', align: 'left', charSpacing: 0, fit: 'shrink', margin: [0, 0, 3.571428571, 7.142857143], lineSpacingMultiple: 0.85 }
    );
  }

  /**
 * set main title for slide
 * @param slide slide
 */
  private setMainTitle(slide: pptxgen.Slide, story) {
    let dynamicFontSize = 36;
    if (story?.title?.length > 50) {
      dynamicFontSize = 30;
    }
    slide.addText(
      [
        {
          text: story?.title,
          options: { fontSize: dynamicFontSize, fontFace: 'Graphik', color: '000000', bold: true, breakLine: true }
        }
      ],
      // N / 71.42857143 =>inch; margin:[left, right, bottom, top]
      { x: 0.42, y: 0.63, h: 0.65, w: 8.903422, valign: 'top', align: 'left', fit: 'none', charSpacing: -3 / 2, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.8 }
    );
  }

  /**
* set sub title for slide
* @param slide slide
*/
  private setSubTitle(slide: pptxgen.Slide, story) {
    let dynamicFontSize = 16;
    if (story?.subtitle?.length > 200) {
      dynamicFontSize = 14;
    }
    slide.addText(
      [
        {
          text: story?.subtitle,
          options: { fontSize: dynamicFontSize, fontFace: 'Graphik', color: '4b0377', bold: true }
        }
      ],
      { x: 0.43, y: 1.96, h: 0.71, w: 9.38, valign: 'top', align: 'left', fit: 'none', charSpacing: -1, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.8 }
    );
  }
 
  /**
 * set context/solution/impact for slide
 * @param slide slide
 */
  private setContents(slide: pptxgen.Slide, story) {
    this.handlePptData(story?.context, story?.pure_context, story?.pure_solution, story?.pure_impact, 'Context');
    this.handlePptData(story?.solution, story?.pure_context, story?.pure_solution, story?.pure_impact, 'Solution');
    this.handlePptData(story?.impact, story?.pure_context, story?.pure_solution, story?.pure_impact, 'Impact');
    slide.addText(this.Context,
      { x: 0.43, y: 2.72, h: 3.01, w: 2.95, valign: 'top', align: 'left', fit: 'none', charSpacing: -1 / 3, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.9 });
    slide.addText(this.Solution,
      { x: 3.63, y: 2.72, h: 3.01, w: 2.95, valign: 'top', align: 'left', fit: 'none', charSpacing: -1 / 3, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.9 });
    slide.addText(this.Impact,
      { x: 6.87, y: 2.72, h: 3.01, w: 2.95, valign: 'top', align: 'left', fit: 'none', charSpacing: -1 / 3, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.9 });
  }

  /**
* set contacts for slide
* @param slide slide
*/
   private setContacts(slide: pptxgen.Slide, story) {
    this.ContactHashTag=[];
    this.ContactHashTag.push({ text: 'Contacts:', options: { fontSize: 10, fontFace: 'Graphik', color: '000000', bold: true, breakLine: true } })
    for(let i=0; i<story.contacts?.length;i++){
      this.ContactHashTag.push( { text: story.contacts[i]?.email_id, options: { fontSize: 10, fontFace: 'Graphik', color: 'a100ff', breakLine: true } })
    }
    this.ContactHashTag.push({ text: story.hashtag, options: { fontSize: 10, fontFace: 'Graphik', color: 'a100ff', breakLine: true } })
    slide.addText(this.ContactHashTag,
      { x: 5.95, y: 6.07, h: 0.74, w: 3.95, valign: 'top', align: 'right', fit: 'none', margin: [0, 0, 0, 0] }
    );
  }

  /**
 * set pictures for slide
 * @param slide slide
 */
  private setPictures(slide: pptxgen.Slide, story) {
    const imgMaxHeight = 1.33;
    const imgMaxWidth = 2.86;
    const imgVerticalSpacing = 0.16;
    let offsetY = 0;
    let imgTargetWidth = 0;
    let imgTargetHeight = 0;
    let previousCaptionAndImgHeight = 0;
    let imgCaptionHeight = 0;
    let maxMultiple = 0;
    const startTime = Date.now();
    for (let i = 0; i < story.pictures?.length; i++) {
      const imgSrc = this.dataImages[story.pictures[i]._id]?.src;
      const imgWidth = this.dataImages[story.pictures[i]._id]?.width;
      const imgHeight = this.dataImages[story.pictures[i]._id]?.height;
      offsetY += i > 0 ? imgVerticalSpacing + previousCaptionAndImgHeight : 0;
      maxMultiple = imgWidth / imgMaxWidth;
      maxMultiple = maxMultiple > imgHeight / imgMaxHeight ? maxMultiple : imgHeight / imgMaxHeight;
      imgTargetWidth = imgWidth / maxMultiple;
      imgTargetHeight = imgHeight / maxMultiple;

      // image caption
      let imgCaption = story.pictures[i].image_description;
      if (imgCaption) {
        imgCaption = imgCaption.replace(/\n/g, ' ');
        imgCaptionHeight = imgCaption.length > 30 ? 0.3 : 0.15;
        slide.addText(
          [
            { text: imgCaption, options: { fontSize: 12, fontFace: 'Graphik', color: '000000' } }
          ],
          { x: 10.05, y: 1.89 + offsetY, w: 3.2, h: imgCaptionHeight, valign: 'top', align: 'left', lineSpacingMultiple: 0.8, margin: [0, 0, 0, 0] }
        );
      } else {
        imgCaptionHeight = 0;
      }
      // image
      slide.addImage({ data: imgSrc, x: 10.05, y: 1.89 + imgCaptionHeight + offsetY, h: imgTargetHeight, w: imgTargetWidth });
      previousCaptionAndImgHeight = imgTargetHeight + imgCaptionHeight;
    }
    const delSearchTime = Date.now();
    console.log('syncToElasticSearch==>storys==Duration ==>' + (delSearchTime - startTime));
  }

  private setIcons(slide: pptxgen.Slide, story) {
    const iconAreaMaxWidth = 6.45;
    const iconHorizontalSpacing = Number(this.iconShapeInfo.iconHorizontalSpacing);
    const heightAssumedMaximum = Number(this.iconShapeInfo.iconHeightAssumedMaximum);
    const widthAssumedMinimum = Number(this.iconShapeInfo.iconWidthAssumedMinimum);
    let iconTargetWidth = 0;
    let iconTargetHeight = 0;
    let previousIconWidth = 0;
    let iconTotalWidth = 0;
    let assumedMaximumRatio = 0;
    let widthAssumedMaximum = 0;
    let lessThanNormalWidth = 0;
    let offsetX = 0;
    for (let i = 0; i < story.icons?.length; i++) {
      const iconWidth = this.dataIcons[story.icons[i]._id].width;
      const iconHeight = this.dataIcons[story.icons[i]._id].height;
      assumedMaximumRatio = iconHeight / heightAssumedMaximum;
      widthAssumedMaximum = iconWidth / assumedMaximumRatio;
      if (widthAssumedMaximum < widthAssumedMinimum) {
        lessThanNormalWidth += widthAssumedMaximum;
      }
      iconTotalWidth += widthAssumedMaximum;
    }
    for (let i = 0; i < story.icons?.length; i++) {
      offsetX += i > 0 ? iconHorizontalSpacing + previousIconWidth : 0;
      const iconSrc = this.dataIcons[story.icons[i]._id].src;
      const iconWidth = this.dataIcons[story.icons[i]._id].width;
      const iconHeight = this.dataIcons[story.icons[i]._id].height;
      const totalHorizontalSpacing = (story.icons?.length - 1) * iconHorizontalSpacing;
      const widthRatio = (iconTotalWidth - lessThanNormalWidth) / (iconAreaMaxWidth - totalHorizontalSpacing - lessThanNormalWidth);
      assumedMaximumRatio = iconHeight / heightAssumedMaximum;
      widthAssumedMaximum = iconWidth / assumedMaximumRatio;
      iconTargetHeight = heightAssumedMaximum / widthRatio;
      if (iconTargetHeight > heightAssumedMaximum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      } else {
        iconTargetWidth = widthAssumedMaximum / widthRatio;
      }
      if (widthAssumedMaximum < widthAssumedMinimum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      }
      slide.addImage({ data: iconSrc, x: 0.42 + offsetX, y: 6.2, h: iconTargetHeight, w: iconTargetWidth });
      previousIconWidth = iconTargetWidth;
    }
  }

  /**
* handle html text
* @param richText html
* @param key context/solution/impact
*/
  handlePptData(richText: string, pure_context, pure_solution, pure_impact, key: string) {
    // content title
    const textContentTitle: any = [
      { text: key + ':', options: { fontSize: 14, breakLine: true, fontFace: 'Graphik', charSpacing: -1 / 3, color: 'a100ff', bold: true } }
    ];

    // temp array for whole content included title
    let tmpTextArray = [...textContentTitle];

    // make sure html parser tool can identify <strong>
    richText = richText?.replace(/<strong[^>]*>/g, '<b>');
    richText = richText?.replace(/<\/strong>/g, '</b>');
    richText = richText?.replace(/<em[^>]*>/g, '<i>');
    richText = richText?.replace(/<\/em>/g, '</i>');
    richText = richText?.replace(/<h\d+[^>]*>/g, '<p><b>');
    richText = richText?.replace(/<\/h\d+>/g, '</b></p>');
    richText = richText?.replace(/&bull;/g, '&bull;@@uullii@@');
    tmpTextArray = [...tmpTextArray, ...this.ParseRichTextToPptText(richText, pure_context, pure_solution, pure_impact, -1 / 3)];

    // only retain the items which text is not empty or break line
    tmpTextArray = [...tmpTextArray].filter((o) => !!o.text || o.options.breakLine);
    // set consecutive empty lines to be small height
    reconcileParagraphs(tmpTextArray, this.denominatorForPpt);
    // remove multiple empty lines
    tmpTextArray = this.removeMultipleEmptyLines(tmpTextArray);
    // bullet list add breakline
    this.bulletAddBreakline(tmpTextArray);
    // set bullet indent property
    tmpTextArray.forEach((o, index) => {
      // bullet indent
      if (o.options.bullet) {
        o.options.indentLevel++;
        if (typeof o.options.bullet === 'boolean') {
          o.options.bullet = { indent: this.bulletIndentForPpt };
        } else {
          o.options.bullet.indent = this.bulletIndentForPpt;
        }
      }
      // replace @uullii@@ with 4 spaces
      if (o.text === '@@uullii@@') {
        o.text = '  ';
      }
      // small line height for the line before "•"
      if (o.text === '•' && !!tmpTextArray[index - 1] && index > 1) {
        tmpTextArray[index - 1].options.lineSpacingMultiple = 1 / 10;
      }
    });
    // add a empty line which has 0.45 line height
    tmpTextArray.splice(1, 0, {
      text: '', options: { fontSize: 14, breakLine: true, fontFace: 'Graphik', lineSpacingMultiple: 9 / 20 }
    });
    this[key] = tmpTextArray;
  }

  /**
 * parse rich text to ppt text
 * @param richText rich text
 * @returns text array
 */
  private ParseRichTextToPptText(richText: any, pure_context, pure_solution, pure_impact, charSpacing: any = null, isBold: any = false) {
    return _.each(htmlToPptxText(richText), (o) => {
      if (o.text == ' ' && o.options.breakLine) {
        o.text = '';
      }
      let dynamicFontSize = 12;
      if (pure_context?.length > 550 || pure_solution?.length > 550 ||
        pure_impact?.length > 550
      ) {
        dynamicFontSize = 10;
      }
      o.options.fontSize = dynamicFontSize;
      o.options.fontFace = 'Graphik';
      if (!o.options.bold) {
        o.options.bold = isBold;
      }
      if (charSpacing) {
        o.options.charSpacing = charSpacing;
      } else {
        delete o.options.charSpacing;
      }

      // remove undefined properties
      if (!o.options.align)
        delete o.options.align;
      if (!o.options.breakLine)
        delete o.options.breakLine;
      if (!o.options.underline)
        delete o.options.underline;

      // color
      if (!o.options.color) delete o.options.color;
      // italic
      if (!o.options.italic) delete o.options.italic;
      // // hyperlink
      if (!o.options.hyperlink) {
        delete o.options.hyperlink;
      } else {
        // tooltip
        if (!o.options.hyperlink.tooltip) {
          delete o.options.hyperlink.tooltip;
        }
        // url
        if (!o.options.hyperlink.url) {
          delete o.options.hyperlink.url;
        }
        // slide
        if (!o.options.hyperlink.slide) {
          delete o.options.hyperlink.slide;
        } else {
          o.options.hyperlink.url = o.options.hyperlink.slide;
          delete o.options.hyperlink.slide;
        }
      }
      delete o.options.strike;
      delete o.options.subscript;
      delete o.options.superscript;
    });
  }

  /**
 * remove multiple empty lines
 * @param contentKey content key: Context/Solution/Impact
 */
  private removeMultipleEmptyLines(tmpTextArray) {
    const toBeRemoveElements = [];
    for (let index = 0; index < tmpTextArray.length; index++) {
      const element = tmpTextArray[index];
      if (element.text.trim() == '') {
        if (index > 0 && !element.options.lineSpacingMultiple && (tmpTextArray[index - 1].options.breakLine || tmpTextArray[index - 1].text.trim() == '')) {
          toBeRemoveElements.push(index);
        }
      }
    }
    return tmpTextArray.filter((o, index) => !toBeRemoveElements.includes(index));
  }

  /**
   * bullet list add breakline
   * @param key key: Context/Solution/Impact
   */
  bulletAddBreakline(tmpTextArray) {
    const obj = { breakLine: true };
    for (let index = 0; index < tmpTextArray.length; index++) {
      const element = tmpTextArray[index];
      const previousElement = tmpTextArray[index - 1];
      if (element.options?.bullet && element.options?.bullet == true) {
        if (previousElement != null && previousElement != undefined) {
          previousElement.options = Object.assign(previousElement.options, obj);
        }
      }
    }
  }
}

/**
 * reconcile paragraphs
 * @param tmpTextArray array
 */
function reconcileParagraphs(tmpTextArray: any[], denominatorForPpt) {
  let countOfConsecutiveEmpyLine = 0;
  let previousText = null;
  const indexsOfsmallLineHeight = [];
  for (let index = 0; index < tmpTextArray.length; index++) {
    const item = tmpTextArray[index];
    if (item.text.trim() === '') {
      if (previousText === '') {
        countOfConsecutiveEmpyLine++;
        if (countOfConsecutiveEmpyLine > 1) {
          indexsOfsmallLineHeight.push(index);
        }
      } else {
        // do nothing
      }
    } else {
      countOfConsecutiveEmpyLine = 0;
    }
    previousText = item.text.trim();
  }
  indexsOfsmallLineHeight.forEach((smallIndex) => {
    tmpTextArray[smallIndex].options.lineSpacingMultiple = 1 / denominatorForPpt;
    if (tmpTextArray[smallIndex + 1]?.text.trim()) {
      tmpTextArray[smallIndex].options.breakLine = true;
    }
  });
}