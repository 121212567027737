<p id="headerStatus" class="table-title">Project Stages</p>
<hr>

<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<table mat-table #table [dataSource] = "stagesList" class = "mat-elevation-z8" cdkDropList aria-label="stage view data table"> 
    <ng-container matColumnDef="{{header}}" *ngFor="let header of columns; let i=index">
      <ng-container  *ngIf="i === 0; else notSelect">
        <th id="tableHeader{{i}}" style="width: 90px;" mat-header-cell *matHeaderCellDef  > {{headersData[i]}}</th>
        <td id="tableData{{i}}{{j}}" (click)="clickElement(element)" class="highlightElement"  mat-cell *matCellDef="let element;let j=index" tabindex="0" (keydown.enter)="clickElement(element)"> {{element[header]}} </td>

      </ng-container>
      <ng-template #notSelect>
        <div #noSort>       
          <th id="tableHeader{{i}}" style="width: 100px;" mat-header-cell *matHeaderCellDef  > {{headersData[i]}}</th>
          <td id="tableData{{i}}{{j}}"  style="word-break:break-word;" mat-cell *matCellDef="let element;let j=index"> {{element[header]}} </td>     
        </div>    
          <ng-template #noSort>        
          <th id="tableHeader{{i}}" mat-header-cell *matHeaderCellDef> {{headersData[i]}}</th>        
          <td id="tableData{{i}}{{j}}" mat-cell *matCellDef="let element;let j=index"> {{element[header]}} </td>     
          </ng-template>
      </ng-template>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef = "columns"></tr>
    <tr mat-row *matRowDef = "let row; columns: columns;"></tr>
  </table>