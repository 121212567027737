/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, Input, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Services } from '@app/services/services';
import { WorkflowStatus, Messages, OrgType } from '@app/models';

import {
  ReviewRequestDialog,
  PublishRequestDialog,
  ReassignDialog,
  ApproveDialog,
  ReworkDialog,
  PublishDialog
} from '@app/deliverables/deliverable-dialog.components';

@Component({
  selector: 'app-deliverable-list-view',
  outputs: ['update', 'onClick'],
  templateUrl: 'deliverable-list-view.component.html',
  // styleUrls: ['./deliverable-list-view.component.scss'],
})
export class DeliverableListViewComponent implements OnInit {
  @Input('deliverable') deliverable: any;
  @Input('action') action: boolean;
  @Input('isFromSearch') isFromSearch: boolean;
  update: EventEmitter<any> = new EventEmitter<any>();
  onClick: EventEmitter<any> = new EventEmitter<any>();
  imgURL;
  isLiquidStudio: any;
  isIncubation: any;
  clientValue: any = null;
  constructor(
    public dialog: MatDialog,
    public services: Services
  ) {
    this.imgURL = this.services.deliverableCommonService.defaultDeliverableImage;
  }

  ngOnInit(): void {
    if (this.deliverable && this.deliverable.image_link) {
      this.services.deliverableEditCommonService.getDeliverableImage(this, this.deliverable._id);
    }
    if (this.deliverable.customer_name && this.deliverable.country_key) {
      this.clientValue = this.deliverable.customer_name + ' - ' + this.deliverable.country_key;
    }
    this.checkOrgType();
  }

  checkOrgType() {
    if (this.deliverable.organization_type) {
      this.isLiquidStudio = this.deliverable.organization_type.name == OrgType.LIQUID ? true : false;
      this.isIncubation = this.deliverable.organization_type.name == OrgType.INCUBATION || this.deliverable.organization_type.name == OrgType.OPENINNOVATION || this.deliverable.organization_type.name == OrgType.METAVERSE ? true : false;
    }
  }

  get WorkflowStatus(): any { return WorkflowStatus; }

  onDeliverableClick() {
    this.onClick.emit(this.deliverable);
  }


  onReviewRequest(deliverable): void {
    this.services.deliverableCommonService.openDialog(this, ReviewRequestDialog, deliverable, '538px', Messages.REVIEW_REQUESTED_SUCCESS, 'review_request');
  }

  onPublishRequest(deliverable): void {
    this.services.deliverableCommonService.openDialog(this, PublishRequestDialog, deliverable, '478px', Messages.PUBLISH_REQUESTED_SUCCESS, 'publish_request');
  }

  onReassign(deliverable): void {
    this.services.deliverableCommonService.openDialog(this, ReassignDialog, deliverable, '538px', Messages.REASSIGN_SUCCESS, 'reassign');
  }

  onApprove(deliverable): void {
    this.services.deliverableCommonService.openDialog(this, ApproveDialog, deliverable, '478px', Messages.APPROVE_SUCCESS, 'approve');
  }

  onRework(deliverable): void {
    this.services.deliverableCommonService.openDialog(this, ReworkDialog, deliverable, '538px', Messages.REASSIGN_SUCCESS, 'rework');
  }

  onApprovePublish(deliverable): void {
    this.services.deliverableCommonService.openDialog(this, PublishDialog, deliverable, '478px', Messages.PUBLISH_SUCCESS, 'approve_publish');
  }


  onSendReminder(deliverableId): void {
    this.services.deliverableService.sendReminder(deliverableId)
      .subscribe((res) => {
        if (res) {
          this.services.commonService.openSnackBar('success', Messages.REMINDER_SUCCESS);
        }
      });
  }

  canRequestReview(deliverable: any) {
    if (this.services.deliverableCommonService.canEditDeliverable(deliverable)) {
      if (deliverable.workflow_status == WorkflowStatus.SAVED) {
        return true;
      } else if (deliverable.workflow_status == WorkflowStatus.APPROVED || deliverable.workflow_status == WorkflowStatus.PUBLISH_APPROVED) {
        if (deliverable.workflow && new Date(deliverable.last_updated_date).getTime() > new Date(deliverable.workflow.updated).getTime()) {
          return true;
        } else return false;
      } else return false;
    } else return false;
  }

  canRequestPublish(deliverable: any) {
    if (this.services.deliverableCommonService.canEditDeliverable(deliverable)) {
      if (this.services.deliverableCommonService.isDeliverablePublishable(deliverable) && (deliverable.workflow_status == WorkflowStatus.SAVED ||
        deliverable.workflow_status == WorkflowStatus.APPROVED)) {
        return true;
      } else if (this.services.deliverableCommonService.isDeliverablePublishable(deliverable) && deliverable.workflow_status == WorkflowStatus.PUBLISH_APPROVED) {
        if (deliverable.workflow && new Date(deliverable.last_updated_date).getTime() > new Date(deliverable.workflow.updated).getTime()) {
          return true;
        } else return false;
      } else return false;
    } else return false;
  }

  canApprove(deliverable: any) {
    if (deliverable.workflow_status == WorkflowStatus.REVIEW_REQUESTED && this.services.commonService.hasAnyLeadRole() &&
      this.services.deliverableCommonService.isAssigned(deliverable)) {
      return true;
    } else return false;
  }

  canPublish(deliverable: any) {
    if (deliverable.workflow_status == WorkflowStatus.PUBLISH_REQUESTED && this.services.commonService.hasAnyLeadRole() &&
      this.services.deliverableCommonService.isPrimaryLocationLead(deliverable)) {
      return true;
    } else return false;
  }

  canSendReminder(deliverable) {
    if (deliverable.workflow_status == WorkflowStatus.REVIEW_REQUESTED &&
      !this.services.deliverableCommonService.isAssigned(deliverable))
      return true;
    else return false;
  }
}
