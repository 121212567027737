/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Input, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import Cropper from 'cropperjs';
import * as $ from 'jquery';

@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
})
export class CropperComponent implements OnInit {
  @Input()
  public currentFile: any;
  @Input()
  public projectImgURL: any;
  imgURL: any = '../../../../assets/images/TI-Banner.png';
  cropper: Cropper;
  sliderValuecurrent: any = 0;
  val1 = 0;
  croppedCanvas: HTMLCanvasElement;
  originalType: any;


  ngOnInit(): void {
    this.initCropper();
  }

  initCropper() {
    $('#image').attr('src', this.imgURL); // update image URL
    const $image = $('#image')[0] as HTMLImageElement;
    this.cropper = new Cropper($image, {
      viewMode: 3,
      dragMode: 'move',
      autoCropArea: 1,
      restore: false,
      modal: false,
      guides: false,
      highlight: false,
      cropBoxMovable: false,
      cropBoxResizable: false,
      toggleDragModeOnDblclick: false,
      center: false,
      zoomOnWheel: false,
    });
    this.val1 = 0;
  }

  cropperHandleImage():Cropper {
    return this.cropper;
  }


  ngOnChanges() {
    if(this.projectImgURL !== null){
      this.imgURL = this.projectImgURL;
      this.originalType = this.projectImgURL.match(/data:(\S*);base64/)[1];
      this.cropper.destroy(); // destroy cropper area
      this.initCropper();// reset cropper
      this.projectImgURL = null;
      this.currentFile = 'originalImage';
    }
    if (this.currentFile !== '' && this.currentFile !== 'originalImage') {
      this.imgURL = URL.createObjectURL(this.currentFile[0]); // get image URL
      this.cropper.destroy(); // destroy cropper area
      this.initCropper();// reset cropper
    }
  }

  handleChange(e) {
    const count = (e.value - this.sliderValuecurrent) * .02;
    this.cropper.zoom(count);
    this.sliderValuecurrent = e.value;
    if(e.value == 0) {
      this.cropper.reset();
    }
  }
}