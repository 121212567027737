/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, debounceTime, distinctUntilChanged, retry, delay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { ProjectsService } from '../projects.service';
import { resetFakeAsyncZone } from '@angular/core/testing';

@Injectable()
export class ProjectsAPIService implements ProjectsService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getProjectStages(params = null): Observable<any> {
    this.commonService.log('GET:', '/projectstages', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/projectstages`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjectStatus(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverablestatus', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/deliverablestatus`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/list/project', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/quarter/list/project`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjectsKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/projects/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/projects/search/keywords`, this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjects(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/projects/list', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/projects/list`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProject(projectId: any): Observable<any> {
    this.commonService.log('GET:', `/projects/${projectId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/projects/${projectId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  setFormData(project, file): FormData {
    const formData = new FormData();
    if (file) { formData.append('projectImage', file); }
    formData.append('project', JSON.stringify(project));
    return formData;
  }

  saveDraft(project, file): Observable<any> {
    const formData = this.setFormData(project, file);
    this.commonService.log('POST:', '/projects/draft', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/projects/draft`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  save(project, file): Observable<any> {
    const formData = this.setFormData(project, file);
    this.commonService.log('POST:', '/projects', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/projects`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getTypeAheadData(queryParams): Observable<any> {
    return this.http.get(`${environment.API_URL}/search/suggest`, this.commonService.httpOptions(queryParams));
  }

  updateDraft(projectId, project, file): Observable<any> {
    const formData = this.setFormData(project, file);
    this.commonService.log('PUT:', `/projects/draft/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.put(`${environment.API_URL}/projects/draft/${projectId}`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  update(projectId, project, file): Observable<any> {
    const formData = this.setFormData(project, file);
    this.commonService.log('POST:', `/projects/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/projects/${projectId}`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  projectNameVerification(projectName): Observable<any> {
    this.commonService.log('GET:', '/projects/nameVerification/', 'OPTIONS:', this.commonService.httpOptions(null));
    return this.http.get(`${environment.API_URL}/projects/nameVerification/${projectName}`, this.commonService.httpOptions(null))
      .pipe(debounceTime(300),
        distinctUntilChanged(),
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  checkAdvanceStage(projectId: any): Observable<any> {
    const param = { projectId: projectId };
    this.commonService.log('GET:', '/projectStageWorkflow/checkAdvanceStage', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/projectStageWorkflow/checkAdvanceStage/${projectId}`, this.commonService.httpOptions(null))
      .pipe(debounceTime(300),
        distinctUntilChanged(),
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  advanceStage(projectId): Observable<any> {
    this.commonService.log('POST:', `/projectStageWorkflow/advanceStage/${projectId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/projectStageWorkflow/advanceStage/${projectId}`, {}, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getReviewers(projectId: any): Observable<any> {
    this.commonService.log('GET:', `/projects/reviewer/${projectId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/projects/reviewer/${projectId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  reviewRequest(projectId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/projects/review/request/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/projects/review/request/${projectId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  approveReassign(projectId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/projects/review/reassign/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/projects/review/reassign/${projectId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  approveRework(projectId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/projects/review/rework/${projectId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/projects/review/rework/${projectId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  approve(projectId: any): Observable<any> {
    this.commonService.log('POST:', `/projects/review/approve/${projectId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/projects/review/approve/${projectId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  sendReminder(projectId: any): Observable<any> {
    this.commonService.log('POST:', `/projects/review/sendreminder/${projectId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/projects/review/sendreminder/${projectId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  //   advanceStage(project): Observable<any> {
  //     const formData = this.setFormData(project, null);
  //   //this.commonService.log('POST:', `/projectStageWorkflow/advanceStage/${projectId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions(null, null));
  //   return this.http.post(`${environment.API_URL}/projectStageWorkflow/advanceStage`,  formData, this.commonService.httpOptions(null, null))
  //     .pipe(
  //       map(this.commonService.extractData),
  //       map((res: any) => {
  //         return res;
  //       }),
  //       catchError((error: any) => {
  //         return this.commonService.handleError(error);
  //       })
  //     );
  // }
}

