/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class StoriesService {
  getStory(storyId: any): Observable<any> {
    return of(storyId);
  }

  getStories(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }

  save(story, file, orgTypeName = null): Observable<any> {
    return of({ story, file, orgTypeName });
  }

  update(storyId, story, file, orgTypeName = null, deleteStoryList = null): Observable<any> {
    return of({ storyId, story, file, orgTypeName, deleteStoryList });
  }


  saveDraft(story, file): Observable<any> {
    return of({ story, file });
  }

  updateDraft(storyId, story, file, deleteStoryList): Observable<any> {
    return of({ storyId, story, file, deleteStoryList });
  }

  export(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }

  exportPPT(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }

  publishRequest(storyId: any): Observable<any> {
    return of(storyId);
  }
  publishApprove(storyId: any): Observable<any> {
    return of(storyId);
  }
  publishRework(storyId: any, formData): Observable<any> {
    return of({ storyId, formData });
  }
  getStoryKeywords(searchText: string): Observable<any> {
    return of(searchText);
  }
  getIconShapeInfo(): Observable<any> {
    return of(null);
  }

  getMarketsInfo(): Observable<any> {
    return of(null);
  }
}

