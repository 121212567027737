/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { saveAs } from 'file-saver';

import { Animations } from '@app/app-animations';
import { ReportFilterComponent } from '@app/components/report-filter/report-filter.component';
import { OrganizationTypePopupComponent } from '@app/dialog/organization-type-popup/organization-type-popup.component';
import { Messages, Role } from '@app/models';
import { Services } from '@app/services/services';
import { QuickEditComponent } from './quick-edit.component';
import { LoadSettingComponent, SaveSettingComponent } from './reporting-setting.component';

// for PPT
import { SuccessStoriesComponent } from '@app/reporting/success-stories/success-stories.component';
import { environment } from '@environments/environment';
import pptxgen from 'PptxGenJS';
import { KPIComponent } from './kpi/kpi.component';
@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
  styleUrls: ['./reporting.component.scss'],
  animations: [
    Animations.slideMenu
  ],
  host: {
    '(window:resize)': 'onResize()'
  }
})
export class ReportingComponent implements OnInit, AfterViewInit {
  isShowTab: any = false;
  isLoading:any = false
  isImageTab: any = false;
  loading: any = 0;
  search: any = null;
  searchStory: any = null;
  deliverables: Array<any> = [];
  totalRecords: any = 0;
  filterMenu = false;
  pagination: any = {
    page_number: 0,
    page_size: 10,
    sort: 'last_updated_date',
    search: null,
    workflow_status: null,
    sort_dir: 'desc'
  }

  paginationStory: any = {
    page_number: 0,
    page_size: 10,
    sort: 'last_updated_date',
    search: null,
    workflow_status: null,
    sort_dir: 'desc'
  }
  get Role(): any { return Role; }
  pageSizeOptions = [10, 20, 30, 40, 50];
  showFilter: any = false;
  filter;
  storyFilter;
  displayedColumns = ['name', 'deliverable_status', 'owner', 'organization_type', 'primary_group', 'primary_workstream', 'deliverable_type', 'client', 'metrics', 'url', 'action'];
  hideFilterFields = false;
  showWorkflowStatus = false;
  setting = null;
  mandatoryFields: Array<any> = [
    { _id: 'deliverable_status', select: true, label: 'Deliverable Status', disabled: false },
    { _id: 'owner', select: true, label: 'Owner', disabled: false },
    { _id: 'organization_type', select: true, label: 'Organization', disabled: false },
    { _id: 'primary_group', select: true, label: 'Group', disabled: false },
    { _id: 'primary_workstream', select: true, label: 'Workstream', disabled: false },
    { _id: 'primary_location', select: false, label: 'Location', disabled: false },
    { _id: 'initiatives', select: false, label: 'Initiative', disabled: false },
    { _id: 'deliverable_type', select: true, label: 'Deliverable Type', disabled: false },
    { _id: 'client', select: true, label: 'Client', disabled: false },
    { _id: 'metrics', select: true, label: 'Metrics', disabled: false },
    { _id: 'idf_number', select: false, label: 'IDF Number', disabled: false },
    { _id: 'url', select: false, label: 'URL', disabled: false },
    { _id: 'eac_qrtr', select: false, label: 'FiscalYear Quarter', disabled: false }
  ];
  optionalFields: Array<any> = [

    { _id: 'project_name', select: false, label: 'Project Name', disabled: false },
    { _id: 'project_description', select: false, label: 'Project Description', disabled: false },
    { _id: 'project_lead', select: false, label: 'Project Lead', disabled: false },
    { _id: 'industries', select: false, label: 'Industry', disabled: false }
  ];

  // for PPT
  deliverableList = [];
  publicationList = [];
  publicationListOfPptx = [];
  DELIVERABLE_STATUS = {
    InProgress: 'In Progress',
    Complete: 'Complete',
    OnHold: 'On Hold'
  };

  @ViewChild('commonFilter') reportFilter: ReportFilterComponent;
  @ViewChild('kpiView') kpiComponent: KPIComponent;
  @ViewChild('storiesView') SuccessStoriesEditComponent: SuccessStoriesComponent;
  constructor(
    public dialog: MatDialog,
    public services: Services
  ) {
  }

  ngOnInit(): void {
    const hrefValue = this.getCurrentUrl();
    if (hrefValue == 'kpi') {
      this.isShowTab = true;
      this.isImageTab = false;
    }

    if (hrefValue == 'success-stories') {
      this.isImageTab = true;
      this.isShowTab = false;
    }
    if (hrefValue == 'deliverables') {
      this.isImageTab = false;
    }
    this.services.commonService.showHeader.emit(true);
    this.services.commonService.reportDeleted.subscribe((reportId) => {
      setTimeout(() => {
        if (this.setting && this.setting._id == reportId) {
          this.setting = null;
          this.services.commonService.removeSessionData('LOAD_FILTER_SETTING');
        }
      });
    });
    this.services.commonService.reportCreated.subscribe((res) => {
      setTimeout(() => {
        // this.setting = res;
      });
    });
    this.services.deliverableCommonService.isNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (!this.isShowTab && isNeedToRefresh) {
        this._getDeliverables();
      }
    });
    // set table filter
    if (this.services.commonService.getSessionData('table_filter_key')) {
      this.mandatoryFields = this.services.commonService.getSessionData('mandatoryFields');
      this.optionalFields = this.services.commonService.getSessionData('optionalFields');
      this.showWorkflowStatus = this.services.commonService.getSessionData('showWorkflowStatus');
      this.hideFilterFields = this.services.commonService.getSessionData('hideFilterFields');
      this.onApplyTableFilter();
      this.prePagination();
    }
    this.setting = this.services.commonService.getSessionData('LOAD_FILTER_SETTING');
  }
  getCurrentUrl() {
    return window.location.href.toString().split('/')[4];
  }

  prePagination(): void {
    const pagination = this.services.commonService.getSessionData('REPORTING_PAGINATION');
    const storyPagination = this.services.commonService.getSessionData('REPORTING_STORY_PAGINATION');
    if (pagination) {
      const previousUrl = this.services.routerExtService.getPreviousUrl();
      if (previousUrl.includes('/reporting/view/')) {
        this.pagination.page_number = pagination.page_number;
        this.pagination.page_size = pagination.page_size;
      }
      this.pagination.search = pagination.search;
      this.search = pagination.search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
      if (storyPagination) {
        this.paginationStory.search = storyPagination.search;
        this.searchStory = storyPagination.search;
      }
    }
  }

  ngAfterViewInit() {
    this.setTableSize();
  }

  _getDeliverables(): void {
    this.loading++;
    setTimeout(() => {
      const filter = this.services.deliverableCommonService.getFilterData(this.filter);
      this.services.commonService.setSessionData('REPORTING_PAGINATION', this.pagination);
      this.services.reportingService.getDeliverables(this.pagination, filter)
        .subscribe((response) => {
          this.loading--;
          if (response && response.deliverables) {
            this.deliverables = response.deliverables;
            this.deliverables = [...this.deliverables];
            this.totalRecords = response.total;
            this.setTableSize();
            this.onApplyTableFilter();
          }
        });
    }, 500);
  }

  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this.services.deliverableCommonService.isNeedToRefresh.next(true);
  }

  onSort(sort) {
    if (this.pagination.sort === sort) {
      this.pagination.sort_dir = this.pagination.sort_dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.pagination.sort_dir = 'asc';
    }
    this.pagination.sort = sort;
    this.pagination.page_number = 0;
    this.services.deliverableCommonService.isNeedToRefresh.next(true);
  }
  onStorySort(sort) {
    if (this.paginationStory.sort === sort) {
      this.paginationStory.sort_dir = this.paginationStory.sort_dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.paginationStory.sort_dir = 'asc';
    }
    this.paginationStory.sort = sort;
    this.paginationStory.page_number = 0;
    this.services.storyCommonService.isNeedToRefresh.next(true);
  }
  onFilter($event) {
    if ($event.type == 'clearAll') {
      if (!this.isShowTab) {
        this.pagination.search = null;
        this.search = null;
      }
      this.setting = null;
      this.services.commonService.removeSessionData('LOAD_FILTER_SETTING');
    }
    if ($event.type == 'applyFilters') {
      this.showFilter = false;
    }
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this.services.deliverableCommonService.isNeedToRefresh.next(true);
  }
  onStoryFilter($event) {
    if ($event.type == 'clearAll') {
      if (!this.isShowTab) {
        this.paginationStory.search = null;
        this.search = null;
      }
    }
    if ($event.type == 'applyFilters') {
      this.showFilter = false;
    }
    this.storyFilter = $event.data;
    if ($event.type != 'init') {
      this.paginationStory.page_number = 0;
    }
    this.services.storyCommonService.isNeedToRefresh.next(true);
  }

  onSearchSubmit() {
    this.pagination.search = this.search;
    this.pagination.page_number = 0;
    this.services.deliverableCommonService.isNeedToRefresh.next(true);
  }

  onSearchStorySubmit() {
    this.paginationStory.search = this.searchStory;
    this.pagination.page_number = 0;
    this.services.storyCommonService.isNeedToRefresh.next(true);
  }

  onOpenFilter() {
    this.showFilter = !this.showFilter;
  }

  onUpdate() {
    this.services.deliverableCommonService.isNeedToRefresh.next(true);
  }

  setTableSize() {
    const ele = document.getElementById('reporting-list-row');
    if (ele) {
      setTimeout(() => {
        const tableContainer = document.getElementById('table-container');
        if (tableContainer) {
          tableContainer.style.width = ele.offsetWidth - 120 + 'px';
        }
        const tableFooterContainer = document.getElementById('reporting-list-footer-inner');
        if (tableFooterContainer) {
          tableFooterContainer.style.width = ele.offsetWidth - 120 - 58 + 'px';
        }
      }, 100);
    }
  }

  onResize() {
    this.setTableSize();
  }

  onQuickEdit(deliverable) {
    if (deliverable === null) {
      this.services.deliverableCommonService.openDialog(this, OrganizationTypePopupComponent, 'reporting', '538px', '', 'organization_type');
    } else {
      const dialogRef = this.dialog.open(QuickEditComponent, {
        width: '1090px',
        height: '800px',
        maxHeight: '95vh',
        data: deliverable
      });
    }
  }

  _addColumn(field) {
    const index = this.services.commonService.objectIndexOf(this.displayedColumns, field, null);
    if (index < 0) {
      this.displayedColumns.push(field);
    }
  }

  _removeColumn(field) {
    const index = this.services.commonService.objectIndexOf(this.displayedColumns, field, null);
    if (index >= 0) {
      this.displayedColumns.splice(index, 1);
    }
  }

  _disableCheckBox(fields, key, disabled) {
    const index = this.services.commonService.objectIndexOf(fields, { _id: key });
    if (index >= 0) {
      fields[index].disabled = disabled;
    }
  }

  onOpenTableFilter() {
    this.filterMenu = !this.filterMenu;
  }

  onShowAllChange($event, fields) {
    if ($event.checked) {
      fields.forEach((field) => {
        field.select = true;
      });
    } else {
      fields.forEach((field) => {
        field.select = false;
      });
    }
  }

  checkAllSelect(fields) {
    let result = true;
    fields.forEach((field) => {
      if (!field.select) {
        result = false;
      }
    });
    return result;
  }

  onHideFilterFieldsChange() {
    if (this.hideFilterFields && this.filter) {
      if (this.filter.group && this.filter.group.length) {
        this._disableCheckBox(this.mandatoryFields, 'primary_group', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'primary_group', false);
      }
      if (this.filter.workstream && this.filter.workstream.length) {
        this._disableCheckBox(this.mandatoryFields, 'primary_workstream', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'primary_workstream', false);
      }
      if (this.filter.location && this.filter.location.length) {
        this._disableCheckBox(this.mandatoryFields, 'primary_location', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'primary_location', false);
      }
      if (this.filter.status && this.filter.status.length) {
        this._disableCheckBox(this.mandatoryFields, 'deliverable_status', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'deliverable_status', false);
      }
      if (this.filter.initiative && this.filter.initiative.length) {
        this._disableCheckBox(this.mandatoryFields, 'initiatives', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'initiatives', false);
      }
      if (this.filter.deliverable_type && this.filter.deliverable_type.length) {
        this._disableCheckBox(this.mandatoryFields, 'deliverable_type', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'deliverable_type', false);
      }
      if (this.filter.eac_qrtr) {
        this._disableCheckBox(this.mandatoryFields, 'eac_qrtr', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'eac_qrtr', false);
      }
      if (this.filter.organization && this.filter.organization.length) {
        this._disableCheckBox(this.mandatoryFields, 'organization_type', true);
      } else {
        this._disableCheckBox(this.mandatoryFields, 'organization_type', false);
      }
    } else {
      this._disableCheckBox(this.mandatoryFields, 'primary_group', false);
      this._disableCheckBox(this.mandatoryFields, 'primary_workstream', false);
      this._disableCheckBox(this.mandatoryFields, 'primary_location', false);
      this._disableCheckBox(this.mandatoryFields, 'deliverable_status', false);
      this._disableCheckBox(this.mandatoryFields, 'initiatives', false);
      this._disableCheckBox(this.mandatoryFields, 'deliverable_type', false);
      this._disableCheckBox(this.mandatoryFields, 'eac_qrtr', false);
      this._disableCheckBox(this.mandatoryFields, 'organization_type', false);
    }
  }

  onApplyTableFilter() {
    this.filterMenu = false;
    this.displayedColumns = [];
    this._addColumn('name');

    this.mandatoryFields.forEach((field) => {
      if (field.select) {
        this._addColumn(field._id);
      }
    });
    this.services.commonService.setSessionData('mandatoryFields', this.mandatoryFields);

    this.optionalFields.forEach((field) => {
      if (field.select) {
        this._addColumn(field._id);
      }
    });
    this.services.commonService.setSessionData('optionalFields', this.optionalFields);

    if (this.showWorkflowStatus) {
      this._addColumn('workflow_status');
    }
    this.services.commonService.setSessionData('showWorkflowStatus', this.showWorkflowStatus);

    if (this.hideFilterFields && this.filter) {
      if (this.filter.group && this.filter.group.length) {
        this._removeColumn('primary_group');
      }
      if (this.filter.workstream && this.filter.workstream.length) {
        this._removeColumn('primary_workstream');
      }
      if (this.filter.location && this.filter.location.length) {
        this._removeColumn('primary_location');
      }
      if (this.filter.status && this.filter.status.length) {
        this._removeColumn('deliverable_status');
      }
      if (this.filter.initiative && this.filter.initiative.length) {
        this._removeColumn('initiatives');
      }
      if (this.filter.deliverable_type && this.filter.deliverable_type.length) {
        this._removeColumn('deliverable_type');
      }
      // if (this.filter.start_qrtr) {
      //   this._removeColumn('start_qrtr');
      // }
      if (this.filter.eac_qrtr) {
        this._removeColumn('eac_qrtr');
      }
      if (this.filter.organization && this.filter.organization.length) {
        this._removeColumn('organization_type');
      }
    }
    this.onHideFilterFieldsChange();
    // action move to last column
    this._addColumn('action');
    this.services.commonService.setSessionData('hideFilterFields', this.hideFilterFields);

    // save table filter
    this.services.commonService.setSessionData('table_filter_key', this.displayedColumns);
  }

  onExport() {
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);
    const parameters = { search: this.pagination.search };
    this.services.reportingService.export(parameters, filter)
      .subscribe((response) => {
        if (response) {
          const fileName = 'deliverables.xlsx';
          const blob = new Blob([response.body], { type: response.headers.get('content-type') });
          // fileName = response.headers.get('content-disposition').split(';')[1] || fileName;
          const file = new File([blob], fileName, { type: response.headers.get('content-type') });
          saveAs(file);
        }
      });
  }

  hasFilter() {
    let res = false;
    for (const item in this.filter) {
      if (this.filter[item] && this.filter[item].length) {
        res = true;
      } else if (item == 'eac_qrtr' && this.filter[item]) {
        res = true;
      }
    }
    return res;
  }

  onSaveSetting() {
    const columns = [];
    this.mandatoryFields.forEach((field) => {
      if (field.select) {
        columns.push(field._id);
      }
    });

    this.optionalFields.forEach((field) => {
      if (field.select) {
        columns.push(field._id);
      }
    });

    if (this.showWorkflowStatus) {
      columns.push('workflow_status');
    }
    const filter = this.reportFilter.getFilter('loadSetting');
    if (!filter) {
      return false;
    }
    const dialogRef = this.dialog.open(SaveSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: {
        _id: this.setting ? this.setting._id : null,
        name: this.setting ? this.setting.name : null,
        setting: {
          filter: filter,
          displayedColumns: columns,
          hideFilterFields: this.hideFilterFields
        }
      }
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.setting = response.result;
        this.services.commonService.openSnackBar('success', Messages.REPORTING_SETTING_SAVE_SUCCESS);
      }
    });
  }

  onLoadSetting() {
    const dialogRef = this.dialog.open(LoadSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loading++;
        this.services.reportingService.getSetting(res._id)
          .subscribe((response) => {
            this.loading--;

            this.setting = response.result;
            this.services.commonService.setSessionData('LOAD_FILTER_SETTING', this.setting);
            const setting = this.setting.setting;
            this.filter = setting.filter;
            this.hideFilterFields = setting.hideFilterFields;
            if (setting.displayedColumns.includes('workflow_status')) {
              this.showWorkflowStatus = true;
            } else {
              this.showWorkflowStatus = false;
            }
            this.mandatoryFields.forEach((field) => {
              if (setting.displayedColumns.includes(field._id)) {
                field.select = true;
              } else {
                field.select = false;
              }
            });

            this.optionalFields.forEach((field) => {
              if (setting.displayedColumns.includes(field._id)) {
                field.select = true;
              } else {
                field.select = false;
              }
            });
            this.reportFilter.loadFilter(this.filter);
          });
      }
    });
  }

  /**
  * retrive publications data //not using this function we are retrive data from backend
  */
  getPublicationsList(): void {
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);    
      this.services.reportingService.getPublicationsList({}, filter)
      .subscribe((response) => {
        if (response) {
          const fileName = 'publications.pptx';        
          const blob = new Blob([response.body], { type: response.headers.get('content-type') });
          // fileName = response.headers.get('content-disposition').split(';')[1] || fileName;
          const file = new File([blob], fileName, { type: response.headers.get('content-type') });
          saveAs(file);
        }
      });
    }
  

  /**
  * handle data for publications list
  */
  handleData(): void {
    const tmpGroupList = [];
    let distinctGroupList = [];
    let completeDeliverables = [];
    let inprogressDeliverables = [];
    let onholdDeliverables = [];
    for (const deliverableItem of this.deliverableList) {
      tmpGroupList.push(deliverableItem.primary_group.name);
    }
    distinctGroupList = Array.from(new Set(tmpGroupList)).sort();
    this.publicationList = [];
    for (const groupItem of distinctGroupList) {
      inprogressDeliverables = this.deliverableList.filter((o) => o.primary_group.name === groupItem && o.deliverable_status.name === this.DELIVERABLE_STATUS.InProgress);
      completeDeliverables = this.deliverableList.filter((o) => o.primary_group.name === groupItem && o.deliverable_status.name === this.DELIVERABLE_STATUS.Complete);
      onholdDeliverables = this.deliverableList.filter((o) => o.primary_group.name === groupItem && o.deliverable_status.name === this.DELIVERABLE_STATUS.OnHold);
      if (completeDeliverables.length > 0) {
        this.publicationList.push(completeDeliverables);
      }
      if (inprogressDeliverables.length > 0) {
        this.publicationList.push(inprogressDeliverables);
      }
      if (onholdDeliverables.length > 0) {
        this.publicationList.push(onholdDeliverables);
      }
    }
    for (const arr of this.publicationList) {
      arr.sort(this.ArraySort);
    }
  }

  /**
   * sort data for publications list
   */
  ArraySort(a, b) {
    if (a['primary_workstream'] && b['primary_workstream']) {
      if (a['primary_workstream']['name'] == b['primary_workstream']['name']) {
        if (a['deliverable_type']['name'] > b['deliverable_type']['name']) {
          return 1;
        } else if (a['deliverable_type']['name'] < b['deliverable_type']['name']) {
          return - 1;
        } else {
          return 0;
        }
      } else if (a['primary_workstream']['name'] > b['primary_workstream']['name']) {
        return 1;
      }
    } else {
      return - 1;
    }
  }
  exportReport() {
    alert('Beware that the downloaded file may contain client names and references Accenture data that could be highly confidential.');
    // Add your report exporting code here
  }
  /**
   * export pptx
   */
  exportPptx()  {    
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);    
      this.services.reportingService.getPublicationsList({}, filter)
      .subscribe((response) => {
        if (response) {
          const fileName = 'publications.pptx';        
          const blob = new Blob([response.body], { type: response.headers.get('content-type') });     
          const file = new File([blob], fileName, { type: response.headers.get('content-type') });
          saveAs(file);
        }
      });
  }

  /**
   * generate pptx and provide download 
   * //we are generate pptx file from backend not using this function
   */
  private async generateAndDownload() {
    const pptx = new pptxgen();
    let tmpSlide;

    // prepare data for pptx download
    this.prepareDataForPptx();

    // generate pptx
    this.publicationListOfPptx.forEach((groupList) => {
      let heightOfExistTables = 0;
      groupList.detail.forEach((workstreamList) => {
        let slide = null;
        // 0.78==>0.5(default Y position) + blank row height(0.28)
        let positionY = heightOfExistTables + 0.78;
        // rows * 0.28 + 1.02(Title1 + Title2 + Header)
        const additionalRows = this.calCountOfAdditionalRows(workstreamList.detail);
        const heightOfNewTable = (workstreamList.detail.reduce((a, b) => a + Math.ceil(b.name.length / 55), 0) + additionalRows) * 0.28 + 1.02;
        this.services.commonService.log('public services: Services', heightOfExistTables, '===>heightOfNewTable===>', heightOfNewTable);
        // control page size
        if (tmpSlide && heightOfExistTables > 0 && heightOfExistTables + heightOfNewTable < 4.9 && positionY <= 4.3) {
          slide = tmpSlide;
        } else {
          positionY = 0.5;
          heightOfExistTables = 0;
          slide = pptx.addSlide();
        }
        const arrTableRows: pptxgen.TableRow[] = this.prepareTableOfSlide(groupList, workstreamList, heightOfExistTables);
        const tableOptionProp = {
          autoPage: false,
          autoPageHeaderRows: 3,
          // autoPageLineWeight: -0.3,
          autoPageRepeatHeader: true,
          x: 0.5,
          y: positionY,
          w: '90%'
        };
        // if (heightOfExistTables + heightOfNewTable < 4.9) {
        //   tableOptionProp['autoPageLineWeight'] = -0.3;
        // }
        slide.addTable(arrTableRows, tableOptionProp);
        heightOfExistTables += heightOfNewTable;
        tmpSlide = slide;
      });
    });
  
    const filename = `publications.pptx`; 
    console.log('pptx', pptx);
    await pptx.writeFile({ fileName: filename });   
  }
  /**
   * calculate addtional rows count
   */
  calCountOfAdditionalRows(detail) {
    const allItems = Array.from(new Set(detail.map((o) => o['deliverable_type']['name'])));
    const summaryResult = [];
    allItems.forEach((name) => {
      summaryResult.push(detail.filter((o) => o['deliverable_type']['name'] === name).length);
    });
    let result = summaryResult.filter((v) => v === 1).length * 2;
    if (summaryResult[0] === 1) {
      result -= 1;
    }
    if (summaryResult[summaryResult.length - 1] === 1) {
      result -= 1;
    }
    this.services.commonService.log('allItems==>', allItems, '|==>summaryResult==>', summaryResult, '|==>result==>', result);

    return result;
  }

  /**
   * prepare data for pptx download
   */
  prepareDataForPptx() {
    this.publicationListOfPptx = [];
    let tmpGroupsList = [];
    let finalGroupsList = [];
    tmpGroupsList = this.deliverableList.map((o) => o.primary_group.name);
    finalGroupsList = Array.from(new Set(tmpGroupsList)).sort();

    // group
    for (const groupItem of finalGroupsList) { // iterate all the groups
      // all the complete workstreams in current group
      this.setItemsBySpecifiedStatus(groupItem, this.DELIVERABLE_STATUS.Complete);
      // all the in progress workstreams in current group
      this.setItemsBySpecifiedStatus(groupItem, this.DELIVERABLE_STATUS.InProgress);
      // all the on hold workstreams in current group
      this.setItemsBySpecifiedStatus(groupItem, this.DELIVERABLE_STATUS.OnHold);
    }
    this.services.commonService.log('publicationListOfPptx====>', this.publicationListOfPptx);
  }

  /**
   * set items into publicationListOfPptx
   * @param groupItem group item
   * @param specifiedDeliverableStatus specified deliverable status
   */
  private setItemsBySpecifiedStatus(groupItem, specifiedDeliverableStatus: string) {
    const targetWorkStreamsList = Array.from(new Set(this.deliverableList
      .filter((o) => o.primary_group.name === groupItem && o.deliverable_status.name === specifiedDeliverableStatus)
      .map((p) => p.primary_workstream?.name).filter((o) => !!o))).sort();
    // workstream
    const tmpWorkStreamList = [];
    for (const workstreamItem of targetWorkStreamsList) {
      const targetDeliverables = this.deliverableList.filter((o) => o.primary_group.name === groupItem &&
        o.primary_workstream?.name === workstreamItem && o.deliverable_status.name === specifiedDeliverableStatus).sort(this.ArraySortByDeliverableType);
      tmpWorkStreamList.push({
        workstream: workstreamItem,
        detail: targetDeliverables
      });
    }
    // group + deliverable_status + detail
    if (tmpWorkStreamList.length > 0) {
      let markForMaxLines = 0;
      let currentWorkstreamName;
      let tmpWorkStreamListLimited = [];
      let tmpDetail = [];
      for (let workstreamIndex = 0; workstreamIndex < tmpWorkStreamList.length; workstreamIndex++) {
        currentWorkstreamName = tmpWorkStreamList[workstreamIndex].workstream;
        if (workstreamIndex > 0) {
          markForMaxLines += 3;
        }
        for (let detailIndex = 0; detailIndex < tmpWorkStreamList[workstreamIndex].detail.length; detailIndex++) {
          markForMaxLines += Math.ceil(tmpWorkStreamList[workstreamIndex].detail[detailIndex].name.length / 54);
          tmpDetail.push(tmpWorkStreamList[workstreamIndex].detail[detailIndex]);
          if (markForMaxLines % 9 === 0) {
            tmpWorkStreamListLimited.push({
              workstream: currentWorkstreamName,
              detail: Array.from(new Set(tmpDetail))
            });
            tmpDetail = [];
          }
        }
        if (tmpDetail.length > 0) {
          tmpWorkStreamListLimited.push({
            workstream: currentWorkstreamName,
            detail: Array.from(new Set(tmpDetail))
          });
          tmpDetail = [];
        }
      }
      this.publicationListOfPptx.push({
        group: groupItem,
        deliverable_status: specifiedDeliverableStatus,
        detail: Array.from(new Set(tmpWorkStreamListLimited))
      });
      tmpWorkStreamListLimited = [];
    }
  }


  /**
   * prepare slide table
   */
  prepareTableOfSlide(groupList: any, workstreamList: any, totalExistRows: number) {
    const arrTableRows: pptxgen.TableRow[] = [];
    // Title1 - Group and Deliverable status
    const groupProps: pptxgen.TableCellProps = {
      align: 'left',
      bold: true,
      fontFace: 'Arial',
      fontSize: 20,
    };
    const deliverableStatusProps: pptxgen.TableCellProps = {
      align: 'right',
      bold: true,
      fontFace: 'Arial',
      fontSize: 20,
      color: '7030a0',
    };
    const groupAndDeliverableStausRow: pptxgen.TableRow = [
      // { text: 'Group: ' + groupList.group, options: objTitle1Props }
      { text: groupList.group, options: groupProps },
      { text: groupList.deliverable_status, options: deliverableStatusProps },
    ];
    // Title2 - workstream
    const workstreamProps: pptxgen.TableCellProps = {
      colspan: 2,
      align: 'left',
      bold: true,
      fontFace: 'Arial',
      fontSize: 14,
      color: '7030a0',
      border: [{ pt: 0 }, { pt: 0 }, { pt: 1 }, { pt: 0 }],
    };
    const objTitle2Row: pptxgen.TableRow = [
      { text: workstreamList.workstream, options: workstreamProps },
    ];
    if (totalExistRows <= 0) {
      arrTableRows.push(groupAndDeliverableStausRow);
    }
    arrTableRows.push(objTitle2Row);

    // header
    const objHeaderCellProps: pptxgen.TableCellProps = {
      align: 'left',
      bold: true,
      fontFace: 'Arial',
      border: {
        pt: 1
      },
      fill: {
        color: 'F0F0F0'
      }
    };
    const objHeaderRow: pptxgen.TableRow = [
      { text: 'Deliverable Type', options: objHeaderCellProps },
      { text: 'Deliverable Name', options: objHeaderCellProps },
    ];
    arrTableRows.push(objHeaderRow);
    // detail - deliverable type and name
    const deliverableTypeProps: pptxgen.TableCellProps = {
      align: 'left',
      fontFace: 'Arial',
      border: {
        pt: 1
      }
    };
    // deliverable type and name
    const allOfDeliverableTypeNames = Array.from(new Set(workstreamList.detail.map((m) => m.deliverable_type.name)));
    let previousDeliverableTypeName, currentDeliverableTypeName;
    workstreamList.detail.forEach((o, index) => {
      // If deliverable type is only there once, insert a row between this and the next deliverable
      // check if it needs to add space row
      let isNeedBefore, isNeedBoth, isNeedAfter;
      const countOfCurrentDeliverableType = workstreamList.detail.filter((d) => d.deliverable_type.name === o.deliverable_type.name).length;
      // get previous index
      let previousIndex = -1;
      if (index > 0) {
        allOfDeliverableTypeNames.forEach((c, i) => {
          if (c === o.deliverable_type.name) {
            previousIndex = i - 1;
          }
        });
      }
      if (index === 0 && workstreamList.detail.length > 1) {
        isNeedAfter = countOfCurrentDeliverableType === 1;
      }
      if (index > 0 && index < workstreamList.detail.length - 1) {
        const countOfPreviousDeliverableType = workstreamList.detail.filter((d) => d.deliverable_type.name === allOfDeliverableTypeNames[previousIndex]).length;
        this.services.commonService.log('countOfPreviousDeliverableType==>', countOfPreviousDeliverableType, '==>workstream name==>', o.workstream);

        isNeedBoth = countOfPreviousDeliverableType > 1 && countOfCurrentDeliverableType === 1;
        isNeedAfter = !isNeedBoth && countOfCurrentDeliverableType === 1;
      }
      if (index === workstreamList.detail.length - 1) {
        const countOfPreviousDeliverableType = workstreamList.detail.filter((d) => d.deliverable_type.name === allOfDeliverableTypeNames[previousIndex]).length;
        isNeedBefore = countOfPreviousDeliverableType > 1 && countOfCurrentDeliverableType === 1;
      }

      // before
      if (isNeedBefore || isNeedBoth) {
        const objTableRow: pptxgen.TableRow = [
          { text: '', options: deliverableTypeProps }, { text: '', options: deliverableTypeProps },
        ];
        arrTableRows.push(objTableRow);
      }
      // current
      const deliverableNameWithHyperlinkProps: pptxgen.TableCellProps = {
        align: 'left',
        fontFace: 'Arial',
        border: {
          pt: 1
        },
        color: '0043ff'
      };
      deliverableNameWithHyperlinkProps['hyperlink'] = { url: environment.DELIVERABLE_URL + o._id };
      currentDeliverableTypeName = o.deliverable_type.name === previousDeliverableTypeName ? '' : o.deliverable_type.name;
      previousDeliverableTypeName = o.deliverable_type.name;
      const objTableRow: pptxgen.TableRow = [
        { text: currentDeliverableTypeName, options: deliverableTypeProps },
        { text: o.name, options: deliverableNameWithHyperlinkProps },
      ];
      arrTableRows.push(objTableRow);
      // after
      if (isNeedAfter || isNeedBoth) {
        const objTableRow: pptxgen.TableRow = [
          { text: '', options: deliverableTypeProps }, { text: '', options: deliverableTypeProps },
        ];
        arrTableRows.push(objTableRow);
      }
    });
    return arrTableRows;
  }

  /**
   * sort by deliverable name
   */
  ArraySortByDeliverableType(a, b) {
    if (a['deliverable_type']['name'] > b['deliverable_type']['name']) {
      return 1;
    } else if (a['deliverable_type']['name'] < b['deliverable_type']['name']) {
      return -1;
    } else {
      return 0;
    }
  }
}