/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { GroupLocationLeadPopupComponent } from '@app/dialog/group-location-lead-popup/group-location-lead-popup.component';
import { Messages } from '@app/models';
import { CommonService } from '@app/services';
import { GroupLocationLeadService } from '@app/services/group-location-lead.service';
import { MasterService } from '@app/services/master.service';
@Component({
  selector: 'app-group-location-view',
  templateUrl: './group-location-view.component.html',
  // styleUrls: ['./group-location-view.component.scss']
  })
export class GroupLocationViewComponent implements OnInit {
  public groupLocationList;
  public columns: string[] = ['checked', 'groupName', 'locationName', 'group_location_lead_name'];
  public headersData = ['', 'Group', 'Location', 'Group Location Lead'];
  public tableOrder = 'asc';
  public selectedOption = 'All';
  public checkedCount = 0;
  public search = '';
  public selectedGroupLocations: string[];
  public groupLocationsCount = [];
  public totalgroupLocation = [];
  public placeHolder;
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public editSelectedLocation = false;
  public loadVal = 0;
  groupLocationsIdList: any[];
  public groups = [];
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    private groupLocationLeadService: GroupLocationLeadService,
    public dialog: MatDialog,
    private masterService: MasterService) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this._getGroups();
    this.getGroupLocationLeads();
  }
  getGroupLocationLeads(): void {
    this.loadVal++;
    this.groupLocationList = [];
    let params;
    if (this.selectedOption === 'All') {
      params = { active: 'All', orgTypeName: this.orgTypeName };
    } else if (this.selectedOption === 'Enabled') {
      params = { active: true, orgTypeName: this.orgTypeName };
    } else if (this.selectedOption === 'Disabled') {
      params = { active: false, orgTypeName: this.orgTypeName };
    }
    this.groupLocationLeadService.getGroupLocationLeads(params).subscribe((response) => {
      if (response && response.result) {
        response.order == 1 ? this.tableOrder = 'asc' : this.tableOrder = 'desc';
        this.groupLocationList = response.result;
        this.groupLocationList.forEach((element) => {
          element['checked'] = false;
          element['active'] = !element.archive_ind;
          if (element.group_location_lead) {
            element['group_location_lead_name'] = element.group_location_lead.display_name;
          }
          if (element.group) {
            element['groupName'] = element.group.name;
          }
          if (element.location) {
            element['locationName'] = element.location.name;
          }
        });
        Object.assign(this.totalgroupLocation, this.groupLocationList);
        this.enabledValue = this.groupLocationList;
        // this.groupLocationList = this.groupLocationList.map(({description, country, ...rest}) => ({...rest}));
        this.groupLocationList = new MatTableDataSource(this.groupLocationList);
        this.groupLocationList._filter.next(this.search);
        if (this.search.trim() == '') {
          this.groupLocationsCount = [`${this.groupLocationList.filteredData.length} Entries`, ''];
        }
        else {
          this.groupLocationsCount = [`${this.groupLocationList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
        }
        this.loadVal--;
      }
    }, (error1) => {
      this.loadVal--;
      this.commonService.handleError(error1);
    });
  }
  getSelectedOption(selected): void {
    this.selectedGroupLocations = [];
    this.checkedCount = 0;
    this.editSelectedLocation = false;
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.groupLocationsIdList = [];
    this.groupLocationList.filteredData.forEach((element) => {
      if (element.checked === true) {
        this.checkedCount++;
        this.selectedGroupLocations.push(element.groupName);
        this.totalgroupLocation.forEach((item) => {
          if (item.groupName === element.groupName && item.location.name == element.location.name) {
            this.groupLocationsIdList.push({ '_id': item._id });
          }
        });
      }
    });
    if (this.checkedCount > 0) {
      this.editSelectedLocation = true;
    }
  }
  getEnableDisableStatus(value): void {
    this.selectedOption = value;
    this.getGroupLocationLeads();
  }
  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.groupLocationList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.groupLocationsCount = [`${this.groupLocationList.filteredData.length} Entries`, ''];
    }
    else {
      this.groupLocationsCount = [`${this.groupLocationList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }
  setStatus(value): void {
    this.loadVal++;
    this.selectedOption = value;
    const params = { disable: !(value === 'Enabled') };
    this.groupLocationLeadService.updateGroupLocationLeadStatus(params, this.groupLocationsIdList)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.getGroupLocationLeads();
          this.editSelectedLocation = false;
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  addNewGroupLocation(add): void {
    if (add) {
      const editData = { 'groups': this.groups, 'orgTypeName': this.orgTypeName };
      const dialogRef = this.dialog.open(GroupLocationLeadPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.GROUP_LOCATION_ADDED_SUCCESS);
          this.getGroupLocationLeads();
        }
      });
    }
  }
  getClickedItem(value): void {
    if (value) {
      const editData = { 'groups': this.groups, 'value': value };
      const dialogRef = this.dialog.open(GroupLocationLeadPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.GROUP_LOCATION_EDITED_SUCCESS);
          this.getGroupLocationLeads();
        }
      });
    }
  }
  _getGroups(): void {
    this.loadVal++;
    this.masterService.getGroups({ 'orgTypeName': this.orgTypeName })
      .subscribe((response) => {
        this.loadVal--;
        if (response && response.result) {
          this.groups = response.result;
        }
      });
  }
  getSelectedOrder(value): void {
    this.loadVal++;
    const params = { collection_name: 'group_location_leads', order: value, field_name: 'group' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.GROUP_LOCATION_LEAD_ORDER_SAVE_SUCCESS);
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
}
