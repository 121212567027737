/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SearchFilterComponent } from '@app/components/search-filter/search-filter.component';
import { LoadBookSettingComponent, SaveBookMarkSettingComponent } from '@app/dialog/book-mark-setting/book-mark-setting.component';
import { Messages } from '@app/models';
import { ContentType } from '@app/models/contentType';
import { CommonService, DeliverableCommonService, DeliverableService, RouterExtService } from '@app/services';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  // styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit {
  loading: any = 0;
  search: any = null;
  results: Array<any> = [];
  totalRecords: any = 0;
  pageSizeOptions = [10, 20, 30, 40, 50];
  loadTypeAhead: any = 0;
  pagination: any = {
    page_number: 0,
    page_size: 10,
    sort: 'last_updated_date',
    search: null,
    sort_dir: 'desc'
  }

  showFilter: any = false;
  filter;
  setting = null;
  sort_temp: any = {
    sort: '',
    sort_dir: ''
  };
  @ViewChild('searchFilter') searchFilter: SearchFilterComponent;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  searchText;
  review: any;
  count: any;
  constructor(
    private cd: ChangeDetectorRef,
    private commonService: CommonService,
    private deliverableService: DeliverableService,
    public deliverableCommonService: DeliverableCommonService,
    private routerExtService: RouterExtService,
    private router: Router,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
    const sort = this.commonService.getSessionData('SEARCH_SORT');
    const pagination = this.commonService.getSessionData('SEARCH_PAGINATION');
    if (sort) {
      this.sort_temp = sort;
    }
    this.commonService.bookMarkDeleted.subscribe((reportId) => {
      setTimeout(() => {
        if (this.setting && this.setting._id == reportId) {
          this.setting = null;
          this.commonService.removeSessionData('LOAD_SEARCH_FILTER_SETTING');
        }
      });
    });
    if (pagination) {
      const previousUrl = this.routerExtService.getPreviousUrl();
      if (previousUrl.includes('/search/view/')) {
        this.pagination.page_number = pagination.page_number;
        this.pagination.page_size = pagination.page_size;
      }
      this.pagination.search = pagination.search;
      this.searchText = pagination.search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
    }
    this.setting = this.commonService.getSessionData('LOAD_SEARCH_FILTER_SETTING');
  }

  _getSearchResult(): void {
    setTimeout(() => {
      this.loadTypeAhead++;
      this.cd.detectChanges();
    });
    this.loading++;
    this.commonService.setSessionData('SEARCH_SORT', this.sort_temp);
    if (this.filter && this.filter.type && this.filter.type.length > 0) {
      this.filter.type.forEach((o) => {
        if (o.name === ContentType.SUCCESSSTORY) {
          o.name = ContentType.STORY;
        }
      });
    }
    const filter = this.deliverableCommonService.getFilterData(this.filter);
    this.commonService.setSessionData('SEARCH_PAGINATION', this.pagination);
    this.deliverableService.getFilter(this.pagination, filter)
      .subscribe((response) => {
        this.count = response.total;
        this.loading--;
        if (response && response.result) {
          this.results = response.result;
          this.totalRecords = response.total;
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      }, (error1) => {
        this.loading--;
        this.commonService.handleError(error1);
      });
  }

  onViewAll() {
    this.pagination.search = null;
    this.search = null;
    this.ngSelectComponent.handleClearClick();
    this.searchText = null;
    this._getSearchResult();
  }

  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this._getSearchResult();
  }

  onSort(sort) {
    if (sort != '') {
      if (this.pagination.sort === sort) {
        this.pagination.sort_dir = this.pagination.sort_dir === 'asc' ? 'desc' : 'asc';
      } else {
        this.pagination.sort_dir = 'asc';
      }
    }
    else {
      this.sort_temp.sort_dir = this.pagination.sort_dir;
      this.sort_temp.sort = this.pagination.sort;
      this.pagination.sort_dir = '';
    }
    this.pagination.sort = sort;
    this.pagination.page_number = 0;
    this._getSearchResult();
  }

  onFilter($event) {
    if ($event.type == 'clearAll') {
      this.pagination.search = null;
      this.search = null;
      this.setting = null;
      this.commonService.removeSessionData('LOAD_SEARCH_FILTER_SETTING');
    }
    if ($event.type == 'applyFilters') {
      this.showFilter = false;
    }
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this._getSearchResult();
  }

  onTypeAheadSearchSubmit($event = null) {
    if ($event != undefined) {
      this.searchText = $event.target.value;
    }
    this.pagination.search = this.searchText;
    this.pagination.page_number = 0;
    if (this.pagination.sort) {
      this.sort_temp.sort = this.pagination.sort;
      this.sort_temp.sort_dir = this.pagination.sort_dir;
    }
    if (this.pagination.search) {
      this.pagination.sort = '';
      this.pagination.sort_dir = '';
    } else {
      this.pagination.sort = 'last_updated_date';
      this.pagination.sort_dir = 'desc';
    }
    this._getSearchResult();
  }

  onOpenFilter() {
    this.showFilter = !this.showFilter;
  }

  onDeliverableClick(deliverable) {
    this.router.navigate(['/search', 'view', deliverable._id]);
  }

  onProjectClick(project) {
    this.router.navigate(['/search', 'view', 'project', project._id]);
  }

  onStoryClick(story) {
    this.router.navigate(['/search', 'view', 'story', story._id]);
  }

  getTypeAhead(event) {
    const typeAheadValue = event.target.value;
    this.searchText = typeAheadValue;
    if (typeAheadValue.length > 2) {
      const reqArguments = {
        searchText: typeAheadValue
      };
      setTimeout(() => {
        this.loadTypeAhead++;
        this.cd.detectChanges();
      });
      this.deliverableService.getTypeAheadData(reqArguments).subscribe((typedata) => {
        this.review = typedata.suggestions;
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      });
    }
    else {
      this.review = null;
    }
    if (typeAheadValue.length == 0) {
      this.ngSelectComponent.handleClearClick();
    }
  }
  onSaveSetting() {
    const filter = this.deliverableCommonService.getFilterData(this.filter);
    if (!filter) {
      return false;
    }
    const dialogRef = this.dialog.open(SaveBookMarkSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: {
        _id: this.setting ? this.setting._id : null,
        name: this.setting ? this.setting.name : null,
        bookType: 'search',
        setting: {
          filter: this.filter,
        }
      }
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.setting = response.result;
        this.commonService.openSnackBar('success', Messages.SETTINGS_SAVE_SUCCESS);
      }
    });
  }

  onLoadSetting() {
    const dialogRef = this.dialog.open(LoadBookSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: {
        bookType: 'search'
      }
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loading++;
        this.deliverableService.getSetting(res._id)
          .subscribe((response) => {
            this.loading--;
            this.setting = response.result;
            this.commonService.setSessionData('LOAD_SEARCH_FILTER_SETTING', this.setting);
            const setting = this.setting.setting;
            this.filter = setting.filter;
            this.searchFilter.loadFilter(this.filter);
          });
      }
    });
  }
}
