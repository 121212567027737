/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { of, Observable } from 'rxjs';
export class MetricService {
  getMetrics(params): Observable<any> {
    return of(params);
  }

  addMetrics(reqbody): Observable<any> {
    return of(reqbody);
  }

  updateMetricsStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  editMetrics(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}
