/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class KPIService {
  getDeliverables(formData: any): Observable<any> {
    return of(formData);
  }

  getStory(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }
}
