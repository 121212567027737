<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form fxLayout="column" (ngSubmit)="OnDelete()" class="delete-dialog">
    <div fxLayout="column" class="dialog-content">
        <p id="deleteText"><span class="delete-confirm">Are you sure you want to permanently delete these {{deleteItems}}?</span></p>
        <ul class="delete-items">
            <li id="item{{i}}" *ngFor="let item of data.checkedData;let i=index">{{item}}</li>
        </ul>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnDelete" mat-flat-button type="submit" color="primary" cdkFocusInitial>Delete {{deleteItems}}</button>
    </div>
</form>