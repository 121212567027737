import * as ld from 'launchdarkly-js-client-sdk';
import { EventType } from '@azure/msal-browser';
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { CommonService, UserService, AuthenticationService } from '@app/services';
import { Role } from '@app/models';
import { environment } from '@environments/environment';
import { EventMessage } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showHeader = false;
  mobileQuery: MediaQueryList;
  mobileQueryListener: () => void;
  opened = true;
  @ViewChild('sideNav', { static: false }) sideNav: MatSidenav;
  route: string;
  redirectURL: any = '/home';
  deliverableListURL: any = '/home';
  isIframe = false;
  loggedIn = false;
  defaultName = 'jian.a.zheng';
  userId = this.authService.instance.getAllAccounts()[0] ? this.authService.instance.getAllAccounts()[0].username.split('@')[0] : this.defaultName;
  user = { key: this.userId };
  client: ld.LDClient = ld.initialize(environment.launchDarklyID, this.user);

  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private media: MediaMatcher,
    private router: Router,
    private location: Location,
    public commonService: CommonService,
    private msalBroadcastService: MsalBroadcastService,
    public authService: MsalService,
    private userService: UserService,
    private authenticationService: AuthenticationService
  ) {
    this.mobileQuery = this.media.matchMedia('(max-width: 599px)');
  }

  ngOnInit(): void {
    this.ldInitFlag();
    this.getGlobalFlags();
    if (!environment.demo) {
      this.isIframe = window !== window.parent && !window.opener;

      this.msalBroadcastService.msalSubject$
        .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          takeUntil(this._destroying$)
        )
        .subscribe((result) => {
          this.checkAccount();
          localStorage.setItem('msal.idtoken', result.payload['idToken']);
          sessionStorage.setItem('ddrumsdk.idtoken', result.payload['idToken']);
          this.checkLogin();
        });
    }

    this.router.events.subscribe((route) => {
      if (route && route['url'] && route['url'] != '/' && route['url'] != '/#' && route['url'] != '/home' && route['url'] != '/error' && !route['url'].includes('#code=')) {
        this.redirectURL = route['url'];
      }
      if (this.location.path().includes('demo')) {
        this.route = 'demo';
      }
      else if (this.location.path() !== '') {
        this.route = this.location.path();
      } else {
        this.route = '';
      }

      if (this.mobileQuery.matches) {
        this.opened = false;
      } else {
        this.opened = true;
      }
    });

    if (this.mobileQuery.matches) {
      this.opened = false;
    } else {
      this.opened = true;
    }

    this.commonService.showHeader.subscribe((showHeader) => {
      setTimeout(() => this.showHeader = showHeader);
    });


    window.addEventListener('resize', () => {
      if (this.mobileQuery.matches) {
        this.opened = false;
      } else {
        this.opened = true;
      }
    });
  }

  ldInitFlag() {
    this.client.waitForInitialization().then(() => {
      sessionStorage.setItem(environment.MY_SUCCESS_STORIES, this.client.variation(environment.MY_SUCCESS_STORIES, false));
    });
  }

  get role(): any { return Role; }

  isChildOf(path: string): boolean {
    const pattern = new RegExp(`^/(${path})`);
    return pattern.test(this.route);
  }

  onActivate(scrollContainer: any): void {
    scrollContainer.elementRef.nativeElement.scrollTop = 0;
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  async checkLogin(value = false) {
    if (this.loggedIn) {
      this.signUp(value);
    }
  }

  signUp(value) {
    this.userService.signUp().subscribe((response) => {
      if (response && response.result) {
        this.authenticationService.setCurrentUserValue(response.result);
        this.authenticationService.currentUserSubject.next(response.result);
        this.commonService.removeSessionData();
        this.router.navigate([this.redirectURL]);
        if (value) {
          this.router.navigate([this.deliverableListURL]);
        }
      }
    });
  }

  getGlobalFlags() {
    this.userService.getGlobalFlags().subscribe((response) => {
      if (response && response.result) {
        this.commonService.globalFlags['hidePartners'] = JSON.parse(response.result.hidePartners);
        this.commonService.globalFlags['hideForceNestedMarket'] = JSON.parse(response.result.hideForceNestedMarket);
        this.commonService.parnterNeedToRefresh.next(true);
        this.commonService.forceRelatedNeedToRefresh.next(true);
      }
    });
  }
}
