<div fxLayout="column" class="select-deliverables-page">
  <div fxLayout="row">
    <div fxFlex="33" class="filter-field">
      <div class="search_wrap">
        <mat-progress-bar *ngIf="loadTypeAhead" color="accent" mode="indeterminate"></mat-progress-bar>
        <ng-select id="addSearchKey" class="WavSelect WavOption" bindLabel="name" bindValue="name" groupBy="type"
          [(ngModel)]="searchText" placeholder="Search deliverables… " [markFirst]="false" [editableSearchTerm]="true"
          [inputAttrs]='{"aria-label":"Search"}' [closeOnSelect]="true" (input)="getTypeAhead($event)"
          (keydown.enter)="onTypeAheadSearchSubmit($event)" (change)="onTypeAheadSearchSubmit()" [items]="review">
        </ng-select>
      </div>
      <app-related-deliverables-filter class="container" sectionKey="select-deliverables-filter" class="container"
        (onChange)="onFilter($event)"></app-related-deliverables-filter>
    </div>
    <div fxFlex="67" class="form-group">
      <div class="table_field" id="deliverablesTableId" (scroll)="scrollToBottom($event)">
        <div id="loading" fxLayout="row" *ngIf="loading" class="loading-wrap">
          <span>Loading...</span>
        </div>
        <div id="noResults" fxLayout="row" *ngIf="!loading && !results.length" class="loading-wrap">
          <span>No results</span>
        </div>
        <app-related-deliverables-table *ngIf="!loading" [tableData]="deliverablesList" [displayedColumns]="columns"
          (selectedItem)="getSelectedOption($event)"></app-related-deliverables-table>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div fxLayout="row" fxLayoutGap="60px" class="selected-deliverables-btn-wrap">
    <button id="cancelBtn" type="button" mat-stroked-button class="cancel-btn" color="primary" (click)="onCancel()">Cancel</button>
    <button id="okBtn" type="button" mat-flat-button class="apply-filter" color="primary" (click)="onSave()">Ok</button>
  </div>
</div>