/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, Observer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Services } from '@app/services/services';
import { User, Messages } from '@app/models';
import { WorkflowStatus } from '@app/models';
import { CropperComponent } from '@app/shared/components/cropper/cropper.component';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {
replacePhoto() {
throw new Error('Method not implemented.');
}
  @ViewChild('cropper')
  imgCropper: CropperComponent;
  currentFile: any = '';
  loading: any = 0;
  loadKeywords: any = 0;
  loadProjectLead: any = 0;
  loadTeam: any = 0;
  routerWatcher: any;
  isNew: any = true;
  isSaved: any = false;
  file: any = null;
  projectForm: UntypedFormGroup;
  submitted: any = false;
  draftSubmitted: any = false;
  imgURL: any = null;
  maxFileSize: any = false;
  projectId: any = null;
  project: any = null;
  hasValidProjectName: any = false;
  projectLeadChanged: Subject<string> = new Subject<string>();
  teamMembersChanged: Subject<string> = new Subject<string>();
  keywordChanged: Subject<string> = new Subject<string>();
  groupOrder: any = null;
  options: any = {
    initiatives: [],
    project_status: [],
    eac_qrtr: [],
    project_lead: [],
    groups: [],
    team_members: [],
    keywords: [],
    industries: []
  }
  loginUser: User = null;
  formDisabled: any = false;
  projectName: any = '';
  shortDesc: any = '';
  long_description: any = '';
  createTitle: any = '';
  editeTitle: any = '';
  orgTypeName: any = '';
  orgType: any = {};
  currentUrl = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public services: Services
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.services.commonService.removeSessionData('PROJECT_LIVE_PAGINATION');
    this.services.commonService.removeSessionData('PROJECT_DRAFT_PAGINATION');
    this.services.commonService.removeSessionData('SEARCH_PAGINATION');
    this.services.projectsEditCommonService.getInitiatives(this);
    this.services.projectsEditCommonService.getQuarters(this);
    this.services.projectsEditCommonService.getProjectStatus(this);
    this.services.projectsEditCommonService.getIndustries(this);
  

    this.services.commonService.showHeader.emit(true);
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.isNew = true;
      this.projectId = null;
      this.submitted = false;
      this.project = null;
      this.projectForm = null;
      this.isSaved = false;
      this.file = null;
      this.draftSubmitted = false;
      this.imgURL = null;
      this.projectName = null;
      this.shortDesc = null;
      this.long_description = null;

      setTimeout(() => {
        if (params.project_id !== 'new') {
          this.isNew = false;
          this.projectId = params.project_id;
          this.loading++;
          this.services.projectsService.getProject(this.projectId)
            .subscribe((response) => {
              this.loading--;
              if (response && response.result) {
                this.project = response.result;
                this.project = { ...response.result, ...response.metadata };
                this.projectName = this.project.name;
                this.orgTypeName = this.project.organization_type.name;
                this.editeTitle = 'Edit ' + this.orgTypeName + ' Project';
                this.shortDesc = this.project.short_description;
                this.long_description = this.project.long_description;
                if (!this.project.canEdit) {
                  this.location.back();
                  this.services.commonService.openSnackBar('warning', Messages.EDIT_PROJECT_ERROR_MESSAGE);
                  return;
                }
                if (this.project.workflow_status != WorkflowStatus.DRAFT) {
                  this.isSaved = true;
                }
                this.services.projectsEditCommonService.createForm(this, this.project);
                if (this.project.image_link) {
                  this.services.projectsEditCommonService.getProjectImage(this, this.projectId);
                }
              }
              this.services.projectsEditCommonService.getGroups(this);
            });
        } else {
          this.orgType = this.services.commonService.getSessionData('GET_PROJECT_ORG');
          this.orgTypeName = this.orgType.name;
          this.createTitle = 'New ' + this.orgTypeName + ' Project';
          this.services.commonService.removeSessionData('project-list-filter');
          const unsavedProject = this.services.commonService.getSessionData('UNSAVED_PROJECT');
          if (unsavedProject) {
            setTimeout(() => {
              const dialogRef = this.services.commonService.unSavedDialog(Messages.UNSAVED_PROJECT_DRAFT_MESSAGE);
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.services.projectsEditCommonService.createForm(this, unsavedProject);
                  this.projectForm.markAsDirty();
                } else {
                  this.services.projectsEditCommonService.createForm(this);
                  this.services.commonService.removeSessionData('UNSAVED_PROJECT');
                }
              });
            }, 3000);
          } else {
            this.services.projectsEditCommonService.createForm(this);
          }
          this.services.projectsEditCommonService.getGroups(this);
        }
      }, 0);
    });


    this.projectLeadChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        this.services.projectsEditCommonService.projectLeadChanged(this, filterValue);
      });

    this.teamMembersChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['team_members'] = [];
          this.services.projectsEditCommonService.getTeamMembers(this, filterValue);
        }
      });

    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['keywords'] = [];
          this.services.projectsEditCommonService.getKeywords(this, filterValue);
        }
      });
  }

  canDeactivate() {
    if (this.projectForm && this.projectForm.dirty) {
      return Observable.create((observer: Observer<boolean>) => {
        const dialogRef = this.services.commonService.openDirtyDialog();
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.services.commonService.removeSessionData('UNSAVED_PROJECT');
          } else {
            this.location.go(this.currentUrl);
          }
          observer.next(result);
          observer.complete();
        });
      });
    }
    return true;
  }

  get Messages(): any { return Messages; }

  _afterCreateForm() {
    this.services.projectsEditCommonService.afterCreateForm(this);
    if (this.isNew && this.options.end_quarter_default_value) {
      this.projectForm.get('eac_qrtr').setValue(this.options.end_quarter_default_value.qtr, { emitEvent: false });
      this.projectForm.get('eac_qrtr_year').setValue(this.options.end_quarter_default_value.year, { emitEvent: false });
    }
    this.projectForm.get('other_groups').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this._enableControls();
      });

    this.projectForm.get('name').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.projectName = this.projectForm.get('name').value;
      });

    this.projectForm.get('short_description').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.shortDesc = this.projectForm.get('short_description').value;
      });

    this.projectForm.get('long_description').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.long_description = this.projectForm.get('long_description').value;
      });

    this.projectForm.get('other_workstreams').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this._enableControls();
      });

    this.projectForm.valueChanges.subscribe(() => {
      if (this.isNew) {
        this.services.commonService.setSessionData('UNSAVED_PROJECT', this._getFromData());
      }
    });
  }

  onBlurProjectName() {
    this.services.projectsEditCommonService.onBlurProjectName(this);
  }

  _enableControls() {
    this.services.projectsEditCommonService.primaryOptionCheck(this);
    this.services.projectsEditCommonService.otherOptionCheck(this);
    this.services.projectsEditCommonService.enableControls(this);
  }

  getImagePreview(files: any): void {
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const limit = 5 * 1024 * 1024;
    if (files[0].size > limit) {
      this.maxFileSize = true;
      this.file = null;
      return;
    } else {
      this.maxFileSize = false;
    }
    this.currentFile = files;
  }
  

  onChangeProjectLead(event): void {
    this.services.projectsEditCommonService.onChangeProjectLead(this, event);
  }

  onChangeTeamMembers(event): void {
    this.options['team_members'] = [];
    this.teamMembersChanged.next(event.target.value);
  }

  onChangeKeyWord(event): void {
    this.options['keywords'] = [];
    this.keywordChanged.next(event.target.value);
  }

  getGroupMaster(group) {
    return this.services.projectsEditCommonService.getGroupMaster(this, group);
  }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    return this.services.projectsEditCommonService.getMultipleSelectOptions(this, field, key, onlyTypeHead, withoutFilter);
  }

  getMultipleSelectValues(field: string): any {
    return this.projectForm.get(field).value;
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    this.services.projectsEditCommonService.onAddMultipleSelectValue(this, field, event, allowEnter, key);
  }

  onAddSelectValue(field: string, event: MatAutocompleteSelectedEvent): void {
    this.services.projectsEditCommonService.onAddSelectValue(this, field, event);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.projectsEditCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }

  getQuartsOptions(field: string): any[] {
    return this.services.projectsEditCommonService.getQuartsOptions(this, field);
  }

  isValueNotFoundInOptions(field: string, key: string): boolean {
    return this.services.projectsEditCommonService.isValueNotFoundInOptions(this, field, key);
  }

  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }

  onCancel(): void {
    this.currentUrl = this.location.path();
    this.location.back();
  }

  _isValid(): boolean {
    if (this.projectForm.valid && !this.maxFileSize) {
      return true;
    }
    this.invalidFocus();
    return false;
  }

  _isValidDraft(): boolean {
    const isValidQuarter = this.projectForm.get('eac_qrtr').invalid && this.projectForm.get('eac_qrtr').errors && this.projectForm.get('eac_qrtr').errors.mustLessThenMatch;
    if (this.projectForm.get('name').valid && this.projectForm.get('project_lead').valid && !this.maxFileSize && !isValidQuarter) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    this.services.projectsEditCommonService.invalidFocus(this);
  }

  _formReset() {
    this.services.projectsEditCommonService.formReset(this);
    setTimeout(() => {
      this.services.commonService.removeSessionData('UNSAVED_PROJECT');
    });
  }

  _getFromData() {
    return this.services.projectsEditCommonService.getFromData(this);
  }

  checkProjectNameAgain(): void {
    if (!this.hasValidProjectName) {
      // check project name again and set error style
      this.onBlurProjectName();
      setTimeout(() => {
        const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
        if (invalidControl) {
          invalidControl.focus();
        }
      }, 300);
    }
  }
  

  onSaveDraft(): void {
    let fileType = 'image/png';
    if (this.currentFile && this.currentFile.length > 0) {
      fileType = this.currentFile[0].type;
    }
    this.imgCropper.cropperHandleImage().getCroppedCanvas().toBlob(this.handleSaveDraft.bind(this), fileType);
  }

  onSave(): void {
    let fileType = 'image/png';
    if (this.currentFile && this.currentFile.length > 0) {
      fileType = this.currentFile[0].type;
    }
    this.imgCropper.cropperHandleImage().getCroppedCanvas().toBlob(this.handleSave.bind(this), fileType);
  }
   replace_Photo(event) {
    // Your code to handle the photo replacement functionality
    // For example, triggering a click on the file input to open the file picker
    const fileInput = document.getElementById('deliverable_image');
    if (fileInput) {
        fileInput.click();
        
    }
}
  handleSaveDraft(blob): void {
    const extension = blob.type.split('/')[1];
    this.file = new window.File([blob], 'sxm.' + extension, { type: blob.type });
    this.submitted = false;
    this.draftSubmitted = true;
    this.checkProjectNameAgain();
    if (this._isValidDraft() && !this.loading) {
      if (this.isNew) {
        this.loading++;
        this.services.projectsService.saveDraft(this.services.commonService.removeNullObjects(this._getFromData()), this.file)
          .subscribe((response) => {
            this.services.projectsEditCommonService.afterSave(this, response, true, true);
          });
      } else {
        this.loading++;
        this.services.projectsService.updateDraft(this.projectId, this.services.commonService.removeNullObjects(this._getFromData()), this.file)
          .subscribe((response) => {
            this.services.projectsEditCommonService.afterSave(this, response, false, true);
          });
      }
    }
  }

  /**
   * handle save
   * @param blob blob
   */
  handleSave(blob) {
    const extension = blob.type.split('/')[1];
    this.file = new window.File([blob], 'sxm.' + extension, { type: blob.type });
    this.submitted = true;
    this.checkProjectNameAgain();
    if (this._isValid() && !this.loading) {
      this.services.projectsEditCommonService.setDefaultStatus(this);
      this.loading++;
      if (this.isNew) {
        this.services.projectsService.save(this.services.commonService.removeNullObjects(this._getFromData()), this.file)
          .subscribe((response) => {
            this.services.projectsEditCommonService.afterSave(this, response, true, false);
          });
      } else {
        this.services.projectsService.update(this.projectId, this.services.commonService.removeNullObjects(this._getFromData()), this.file)
          .subscribe((response) => {
            this.services.projectsEditCommonService.afterSave(this, response, false, false);
          });
      }
    }
  }
}
