<div fxLayout="column">
  <div fxLayout="column" #filterValidForm class="filter-wrap" [formGroup]="filterForm" *ngIf="show" [@slideInOut]>
    <label id="lblFilterDeliverables">Filter Deliverables By:</label>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="organization" id="organization" placeholder="Organization" class="form-control"
            [matAutocomplete]="organization" />
          <mat-autocomplete id="group_auto" #organization="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('organization', $event)">
            <mat-option id="organization_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('organization');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="organization_list">
            <mat-chip id="organization_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('organization');let i=index"
              (removed)="onRemoveMultipleSelectValue('organization', option)">
              {{option.name}}
              <span id="organization_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33.33%" class="form-group"></div>
        <div fxFlex="33.33%" class="form-group"></div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="Group" id="group" placeholder="Group" class="form-control"
            [matAutocomplete]="group" />
          <mat-autocomplete id="group_auto" #group="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('group', $event)">
            <mat-option id="group_option{{i}}" *ngFor="let option of getMultipleSelectOptions('group');let i=index"
              [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="group_list">
            <mat-chip id="group_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('group');let i=index"
              (removed)="onRemoveMultipleSelectValue('group', option)">
              {{option.name}}
              <span id="group_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="Workstream" id="workstream" placeholder="Workstream" class="form-control"
            [matAutocomplete]="workstream" />
          <mat-autocomplete id="workstream_auto" #workstream="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('workstream', $event)">
            <mat-option id="workstream_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('workstream');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="workstream_list">
            <mat-chip id="workstream_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('workstream');let i=index"
              (removed)="onRemoveMultipleSelectValue('workstream', option)">
              {{option.name}}
              <span id="workstream_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="Location" id="location" placeholder="Location" class="form-control"
            [matAutocomplete]="location" />
          <mat-autocomplete id="location_auto" #location="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('location', $event)">
            <mat-option id="location_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('location');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="location_list">
            <mat-chip id="location_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('location');let i=index"
              (removed)="onRemoveMultipleSelectValue('location', option)">
              {{option.name}}
              <span id="location_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="Initiative" id="initiative" placeholder="Initiative" class="form-control"
            [matAutocomplete]="initiative" />
          <mat-autocomplete id="initiative_auto" #initiative="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('initiative', $event)">
            <mat-option id="initiative_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('initiative');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="initiative_list">
            <mat-chip id="initiative_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('initiative');let i=index"
              (removed)="onRemoveMultipleSelectValue('initiative', option)">
              {{option.name}}
              <span id="initiative_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="Deliverable Status" id="status" placeholder="Deliverable Status" class="form-control"
            [matAutocomplete]="status" />
          <mat-autocomplete id="status_auto" #status="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('status', $event)">
            <mat-option id="status_option{{i}}" *ngFor="let option of getMultipleSelectOptions('status');let i=index"
              [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="status_list">
            <mat-chip id="status_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('status');let i=index"
              (removed)="onRemoveMultipleSelectValue('status', option)">
              {{option.name}}
              <span id="status_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="workflow_status" id="workflow_status" placeholder="Workflow Status"
            class="form-control" [matAutocomplete]="workflow_status" />
          <mat-autocomplete id="workflow_auto" #workflow_status="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('workflow_status', $event)">
            <mat-option id="workflow_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('workflow_status');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="workflow_list">
            <mat-chip id="workflow_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('workflow_status');let i=index"
              (removed)="onRemoveMultipleSelectValue('workflow_status', option)">
              {{option.name}}
              <span id="workflow_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="Quarter" id="eac_qrtr_qtr" placeholder="Quarter" class="form-control"
            [matAutocomplete]="quarter" />
          <mat-autocomplete id="quarter_auto" #quarter="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('eac_qrtr_qtr', $event, null, null)">
            <mat-option id="quarter_option{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr_qtr');let i=index"
              [value]="option">
              Q{{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="quarter_list">
            <mat-chip id="quarter_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('eac_qrtr_qtr');let i=index"
              (removed)="onRemoveMultipleSelectValue('eac_qrtr_qtr', option, null)">
              Q{{option}}
              <span id="quarter_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33.33%" class="form-group">
          <input type="text" aria-label="Financial year" id="eac_qrtr_year" placeholder="Fiscal Year"
            class="form-control" [matAutocomplete]="fiscalYear" />
          <mat-autocomplete id="fiscalYear_auto" #fiscalYear="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('eac_qrtr_year', $event, null, null)">
            <mat-option id="fiscalYear_option{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr_year');let i=index"
              [value]="option">
              FY{{option-2000}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="fiscalYear_list">
            <mat-chip id="fiscalYear_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('eac_qrtr_year');let i=index"
              (removed)="onRemoveMultipleSelectValue('eac_qrtr_year', option, null)">
              FY{{option-2000}}
              <span id="fiscalYear_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33.33%" class="form-group"></div>
      </div>
      <div fxLayout="row">
        <div fxFlex><label id="lblMetrics">Metrics</label></div>
      </div>
      <div fxLayout="row wrap" class="grid">
        <div fxFlex="33.33%" class="form-group" *ngFor="let metric of options.metrics;let i=index">
          <ng-container *ngIf="metric && metric.deliverable_types && metric.deliverable_types.length">
            <input type="text" aria-label="metrics" [id]="'deliverable_type_'+metric._id" [placeholder]="metric.name"
              class="form-control" [matAutocomplete]="deliverable_type" />
            <mat-autocomplete id="metrics_auto{{i}}" #deliverable_type="matAutocomplete"
              (optionSelected)="onAddMultipleSelectValue('deliverable_type', $event, metric)">
              <mat-option id="metric_option{{i}}{{j}}"
                *ngFor="let option of getMultipleSelectOptions('deliverable_type', metric);let j=index"
                [value]="option">{{option.name}} ({{option.total_deliverable}})</mat-option>
            </mat-autocomplete>
            <mat-chip-set id="metrics_list{{i}}">
              <mat-chip id="metric_chip{{i}}{{j}}" disableRipple="true" removable="true" selectable="false"
                *ngFor="let option of getMultipleSelectValues('deliverable_type', metric);let j=index"
                (removed)="onRemoveMultipleSelectValue('deliverable_type', option)">
                {{option.name}}
                <span id="metric_delete{{i}}{{j}}" matChipRemove><i class="ic-close"></i></span>
              </mat-chip>
            </mat-chip-set>
          </ng-container>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <button id="btnApplyFilters" mat-flat-button color="primary" (click)="onFilter('applyFilters')">Apply
          Filters </button>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="13px" class="filter-values" *ngIf="hasFilter()">
    <label id="lblAppliedFilters">Applied Filters:</label>
    <ng-container *ngFor="let item of filter | keyvalue;let i=index">
      <ng-container *ngIf="item.value && item.value.length">
        <div class="tag" *ngFor="let option of item.value;let j=index">
          <span id="optionTag{{i}}{{j}}"
            *ngIf="item.key=='eac_qrtr_qtr'">{{filterLabels[item.key]}}:&nbsp;Q{{option}}</span>
          <span id="optionTag{{i}}{{j}}"
            *ngIf="item.key=='eac_qrtr_year'">{{filterLabels[item.key]}}:&nbsp;FY{{option-2000}}</span>
          <span id="optionTag{{i}}{{j}}"
            *ngIf="item.key!='eac_qrtr_qtr'&& item.key!='eac_qrtr_year'">{{filterLabels[item.key]}}:&nbsp;{{option&&
            option.name ?option.name :
            option}}</span>
          <a id="filterRemoveTag{{i}}{{j}}" class="remove_tag" (click)="onRemoveFilter(item.key, option)"><span
             ><i class="ic-close"></i></span></a>
        </div>
      </ng-container>
    </ng-container>

    <button id="clearAllFilters" mat-button class="clear-btn" matRipple [matRippleUnbounded]="false" [matRippleColor]="'rgba(255, 255, 255, .3)'" 
        [matRippleCentered]="false" (click)="onClearAllFilter()">Clear All Filters</button>
  </div>
</div>