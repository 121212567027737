<main fxFlex fxLayout="column">
    <app-header *ngIf="showHeader" fxShow="showHeader"></app-header>
    <mat-sidenav-container fxFlex autosize="true">
        <mat-sidenav *ngIf="showHeader" fxShow="showHeader" #sideNav [(opened)]="opened" [mode]="mobileQuery.matches ? 'over' : 'side'" class="side-bar">
            <app-side-bar></app-side-bar>
        </mat-sidenav>
        <mat-sidenav-content #scrollContainer>
            <router-outlet (activate)="onActivate(scrollContainer)" *ngIf="!isIframe"></router-outlet>
        </mat-sidenav-content>
    </mat-sidenav-container>
</main>