/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class UserService {
  signUp(user = null): Observable<any> {
    return of(user);
  }

  getUsers(searchText: string): Observable<any> {
    return of(searchText);
  }

  getLoginUsers(): Observable<any> {
    return of(null);
  }

  getGlobalFlags(): Observable<any> {
    return of(null);
  }
}