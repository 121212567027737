/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime } from 'rxjs/operators';
import { Messages, OrgType } from '@app/models';
import { environment } from '@environments/environment';

@Injectable()

export class DeliverableEditCommonService {
  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {

  }

  getDeliverableImage(self, deliverableId): void {
    self.services.masterService.getImage(deliverableId)
      .subscribe((response) => {
        if (response && response.size > 0) {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onload = () => {
            self.imgURL = reader.result;
          };
        }
      });
  }

  getRelatedDeliverables(self, param): void {
    self.loadRelatedDeliverables++;
    self.services.deliverableService.getRelatedDeliverables(param)
      .subscribe((response) => {
        self.loadRelatedDeliverables--;
        if (response && response.result) {
          self.options.related_deliverables = response.result;
        }
      });
  }

  getProjects(self): void {
    self.loading++;
    const params = { orgTypeName: self.orgTypeName };
    self.services.masterService.getProjects(params)
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.project = response.result.filter((o) => { return o.workflow_status !== 'Draft' && o.workflow_status !== null; });
        }
      });
  }

  getDeliverableTypes(self): void {
    self.loading++;
    const params = { orgTypeName: self.orgTypeName };
    self.services.masterService.getDeliverableTypes(params)
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.deliverable_type = response.result;
        }
        if (self.deliverableForm) {
          self._enableControls();
        }
      });
  }

  getInitiatives(self): void {
    self.loading++;
    self.services.masterService.getInitiatives()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.initiatives = response.result;
        }
      });
  }

  getQuarters(self): void {
    self.loading++;
    self.services.masterService.getQuarters()
      .subscribe((response) => {
        self.loading--;
        if (response) {
          self.options.eac_qrtr = response.end_quarter;
        }
      });
  }

  getDeliverableStatus(self): void {
    self.loading++;
    self.services.deliverableService.getDeliverableStatus()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.deliverable_status = response.result;
        }
      });
  }

  getOwner(self, searchText: any): void {
    self.loadOwner++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadOwner--;
        if (response && response.result) {
          self.options.owner = response.result;
        }
      });
  }

  getClient(self, searchText: any): void {
    self.loadClient++;
    self.services.deliverableService.getClientUser(searchText)
      .subscribe((response) => {
        self.loadClient--;
        if (response && response.result) {
          self.options.client = response.result;
        }
      });
  }

  getGroups(self): void {
    self.loading++;
    const params = { orgTypeName: self.orgTypeName };
    self.services.masterService.getGroups(params)
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.groups = response.result;
          self.groupOrder = response.order;
        }
        if (self.deliverableForm) {
          self._enableControls();
        }
      });
  }


  getTeamMembers(self, searchText: any): void {
    self.loadTeam++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadTeam--;
        if (response && response.result) {
          self.options.team_members = response.result;
        }
      });
  }

  getKeywords(self, searchText: any): void {
    self.loadKeywords++;
    self.services.deliverableService.getDeliverablesKeywords(searchText)
      .subscribe((response) => {
        self.loadKeywords--;
        if (response && response.result) {
          self.options.keywords = response.result;
        }
      });
  }

  getIndustries(self): void {
    self.loading++;
    self.services.masterService.getIndustries()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.industries = response.result;
        }
      });
  }

  // getPartner(self, searchText: any): void {
  //   self.loadPartners++;
  //   self.services.masterService.getPartner(searchText)
  //     .subscribe((response) => {
  //       self.loadPartners--;
  //       if (response && response.result) {
  //         self.options.partners = response.result;
  //       }
  //     });
  // }

  ownerChanged(self, filterValue) {
    if (filterValue.trim().length >= 3) {
      self.options['owner'] = [];
      this.getOwner(self, filterValue);
    }
  }

  clientChanged(self, filterValue) {
    if (filterValue.trim().length >= 3) {
      self.options['client'] = [];
      this.getClient(self, filterValue);
    }
  }

  createForm(self, deliverable = null): void {
    self.deliverableForm = this.formBuilder.group({
      _id: [deliverable ? deliverable._id : null],
      name: [deliverable ? deliverable.name : null, [Validators.required, this.noWhitespaceValidator]],
      short_description: [deliverable ? deliverable.short_description : null, [Validators.required, this.noWhitespaceValidator]],
      deliverable_type: [deliverable ? deliverable.deliverable_type : null, Validators.required],
      customer_number: [deliverable ? deliverable.customer_number : null],
      customer_name: [deliverable ? deliverable.customer_name : null],
      country_key: [deliverable ? deliverable.country_key : null],
      long_description: [deliverable ? deliverable.long_description : null],
      related_deliverables: [{ value: deliverable ? deliverable.related_deliverables : [], disabled: true }],

      project: [deliverable ? deliverable.project : null],
      initiatives: [deliverable ? deliverable.initiatives : []],
      deliverable_status: [deliverable ? deliverable.deliverable_status : null],
      idf_number: [{ value: deliverable ? deliverable.idf_number : null, disabled: true }],
      eac_qrtr: [deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.qtr : null, Validators.required],
      eac_qrtr_year: [{ value: deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.year : null, disabled: true }, Validators.required],
      url: [deliverable ? deliverable.url : []],

      owner: [deliverable ? deliverable.owner : self.loginUser, Validators.required],
      team_members: [{ value: deliverable ? deliverable.team_members : [], disabled: false }],
      primary_group: [{ value: deliverable ? deliverable.primary_group : null, disabled: false }, Validators.required],
      other_groups: [{ value: deliverable ? deliverable.other_groups : [], disabled: true }],
      primary_workstream: [{ value: deliverable ? deliverable.primary_workstream : null, disabled: false }, Validators.required],
      other_workstreams: [{ value: deliverable ? deliverable.other_workstreams : [], disabled: true }],
      primary_location: [{ value: deliverable ? deliverable.primary_location : null, disabled: true }, Validators.required],
      other_locations: [{ value: deliverable ? deliverable.other_locations : [], disabled: true }],
      keywords: [deliverable ? deliverable.keywords : []],
      industries: [deliverable ? deliverable.industries : []],
      partners: [{ value: deliverable && deliverable.partners ? deliverable.partners : [], disabled: false }],
      image: null
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }

  createLiquidForm(self, deliverable = null): void {
    self.deliverableForm = this.formBuilder.group({
      _id: [deliverable ? deliverable._id : null],
      name: [deliverable ? deliverable.name : null, [Validators.required, this.noWhitespaceValidator]],
      short_description: [deliverable ? deliverable.short_description : null, [Validators.required, this.noWhitespaceValidator]],
      deliverable_type: [deliverable ? deliverable.deliverable_type : null, Validators.required],
      customer_number: [deliverable ? deliverable.customer_number : null],
      customer_name: [deliverable ? deliverable.customer_name : null],
      country_key: [deliverable ? deliverable.country_key : null],
      long_description: [deliverable ? deliverable.long_description : null],
      related_deliverables: [{ value: deliverable ? deliverable.related_deliverables : [], disabled: true }],

      project: [deliverable ? deliverable.project : null],
      initiatives: [deliverable ? deliverable.initiatives : []],
      deliverable_status: [deliverable ? deliverable.deliverable_status : null],
      idf_number: [{ value: deliverable ? deliverable.idf_number : null, disabled: true }],
      eac_qrtr: [deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.qtr : null, Validators.required],
      eac_qrtr_year: [{ value: deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.year : null, disabled: true }, Validators.required],
      url: [deliverable ? deliverable.url : []],

      owner: [deliverable ? deliverable.owner : self.loginUser, Validators.required],
      team_members: [{ value: deliverable ? deliverable.team_members : [], disabled: false }],
      primary_location: [{ value: deliverable ? deliverable.primary_location : null, disabled: false }, Validators.required],
      other_locations: [{ value: deliverable ? deliverable.other_locations : [], disabled: true }],
      primary_group: [{ value: deliverable ? deliverable.primary_group : null, disabled: false }],
      other_groups: [{ value: deliverable ? deliverable.other_groups : [], disabled: true }],
      primary_workstream: [{ value: deliverable ? deliverable.primary_workstream : null, disabled: false }],
      other_workstreams: [{ value: deliverable ? deliverable.other_workstreams : [], disabled: true }],
      keywords: [deliverable ? deliverable.keywords : []],
      industries: [deliverable ? deliverable.industries : []],
      partners: [{ value: deliverable && deliverable.partners ? deliverable.partners : [], disabled: false }],
      image: null
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }

  createIncubationForm(self, deliverable = null): void {
    self.deliverableForm = this.formBuilder.group({
      _id: [deliverable ? deliverable._id : null],
      name: [deliverable ? deliverable.name : null, [Validators.required, this.noWhitespaceValidator]],
      short_description: [deliverable ? deliverable.short_description : null, [Validators.required, this.noWhitespaceValidator]],
      deliverable_type: [deliverable ? deliverable.deliverable_type : null, Validators.required],
      customer_number: [deliverable ? deliverable.customer_number : null],
      customer_name: [deliverable ? deliverable.customer_name : null],
      country_key: [deliverable ? deliverable.country_key : null],
      long_description: [deliverable ? deliverable.long_description : null],
      related_deliverables: [{ value: deliverable ? deliverable.related_deliverables : [], disabled: true }],

      project: [deliverable ? deliverable.project : null],
      initiatives: [deliverable ? deliverable.initiatives : []],
      deliverable_status: [deliverable ? deliverable.deliverable_status : null],
      idf_number: [{ value: deliverable ? deliverable.idf_number : null, disabled: true }],
      eac_qrtr: [deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.qtr : null, Validators.required],
      eac_qrtr_year: [{ value: deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.year : null, disabled: true }, Validators.required],
      url: [deliverable ? deliverable.url : []],

      owner: [deliverable ? deliverable.owner : self.loginUser, Validators.required],
      team_members: [{ value: deliverable ? deliverable.team_members : [], disabled: false }],
      primary_group: [{ value: deliverable ? deliverable.primary_group : null, disabled: false }, Validators.required],
      other_groups: [{ value: deliverable ? deliverable.other_groups : [], disabled: true }],
      primary_location: [{ value: deliverable ? deliverable.primary_location : null, disabled: true }, Validators.required],
      other_locations: [{ value: deliverable ? deliverable.other_locations : [], disabled: true }],
      primary_workstream: [{ value: deliverable ? deliverable.primary_workstream : null, disabled: false }],
      other_workstreams: [{ value: deliverable ? deliverable.other_workstreams : [], disabled: true }],
      keywords: [deliverable ? deliverable.keywords : []],
      industries: [deliverable ? deliverable.industries : []],
      partners: [{ value: deliverable && deliverable.partners ? deliverable.partners : [], disabled: false }],
      image: null
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }

  createMetaverseForm(self, deliverable = null): void {
    self.deliverableForm = this.formBuilder.group({
      _id: [deliverable ? deliverable._id : null],
      name: [deliverable ? deliverable.name : null, [Validators.required, this.noWhitespaceValidator]],
      short_description: [deliverable ? deliverable.short_description : null, [Validators.required, this.noWhitespaceValidator]],
      deliverable_type: [deliverable ? deliverable.deliverable_type : null, Validators.required],
      customer_number: [deliverable ? deliverable.customer_number : null],
      customer_name: [deliverable ? deliverable.customer_name : null],
      country_key: [deliverable ? deliverable.country_key : null],
      long_description: [deliverable ? deliverable.long_description : null],
      related_deliverables: [{ value: deliverable ? deliverable.related_deliverables : [], disabled: true }],

      project: [deliverable ? deliverable.project : null],
      initiatives: [deliverable ? deliverable.initiatives : []],
      deliverable_status: [deliverable ? deliverable.deliverable_status : null],
      idf_number: [{ value: deliverable ? deliverable.idf_number : null, disabled: true }],
      eac_qrtr: [deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.qtr : null, Validators.required],
      eac_qrtr_year: [{ value: deliverable && deliverable.eac_qrtr ? deliverable.eac_qrtr.year : null, disabled: true }, Validators.required],
      url: [deliverable ? deliverable.url : []],

      owner: [deliverable ? deliverable.owner : self.loginUser, Validators.required],
      team_members: [{ value: deliverable ? deliverable.team_members : [], disabled: false }],
      primary_group: [{ value: deliverable ? deliverable.primary_group : null, disabled: false }, Validators.required],
      other_groups: [{ value: deliverable ? deliverable.other_groups : [], disabled: true }],
      primary_location: [{ value: deliverable ? deliverable.primary_location : null, disabled: true }, Validators.required],
      other_locations: [{ value: deliverable ? deliverable.other_locations : [], disabled: true }],
      primary_workstream: [{ value: deliverable ? deliverable.primary_workstream : null, disabled: false }],
      other_workstreams: [{ value: deliverable ? deliverable.other_workstreams : [], disabled: true }],
      keywords: [deliverable ? deliverable.keywords : []],
      industries: [deliverable ? deliverable.industries : []],
      partners: [{ value: deliverable && deliverable.partners ? deliverable.partners : [], disabled: false }],
      image: null
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }  

  extraValidator() {
    return (formGroup: UntypedFormGroup) => {
      const endQrtrCtrl = formGroup.controls['eac_qrtr'];
      const endQrtrYearCtrl = formGroup.controls['eac_qrtr_year'];
      const deliverableStatusCtrl = formGroup.controls['deliverable_status'];

      if (!endQrtrCtrl.value || !endQrtrYearCtrl.value) {
        endQrtrCtrl.setErrors({ required: true });
        endQrtrYearCtrl.setErrors({ required: true });
      } else {
        endQrtrCtrl.setErrors(null);
        endQrtrYearCtrl.setErrors(null);
      }

      const deliverableTypeCtrl = formGroup.controls['deliverable_type'];
      const idfNumberCtrl = formGroup.controls['idf_number'];
      const urlCtrl = formGroup.controls['url'];
      // IDF Number Required
      if (deliverableTypeCtrl.value && deliverableTypeCtrl.value.idf_number_required) {
        if (!idfNumberCtrl.value || idfNumberCtrl.value.trim() === '') {
          idfNumberCtrl.setErrors({ required: true });
        } else {
          idfNumberCtrl.setErrors(null);
        }
      } else {
        idfNumberCtrl.setErrors(null);
      }
      // URL
      if (deliverableStatusCtrl.value && deliverableStatusCtrl.value.isComplete && deliverableTypeCtrl.value && !deliverableTypeCtrl.value.isPublish && deliverableTypeCtrl.value.link_required) {
        if (urlCtrl.value && !urlCtrl.value.length) {
          urlCtrl.setErrors({ required: true });
        } else {
          urlCtrl.setErrors(null);
        }
      } else {
        urlCtrl.setErrors(null);
      }
    };
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  afterCreateForm(self) {
    if (self.options.groups && self.options.groups.length) {
      self._enableControls();
    }
    self.deliverableForm.get('deliverable_type').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe((deliverableType) => {
        if (!deliverableType || !deliverableType.idf_number_required) {
          self.deliverableForm.get('idf_number').setValue(null);
        }
        if (deliverableType) {
          self.currentDeliverableType = self.deliverableForm.get('deliverable_type').value;
          if (self.previousDeliverableType && self.previousDeliverableType.name == 'IDFs Filed' && self.previousDeliverableStatus) {
            self.deliverableForm.get('deliverable_status').setValue(self.previousDeliverableStatus, { emitEvent: false });
          }
          if (deliverableType.name !== 'IDFs Filed') {
            self.currentDeliverableStatus = self.deliverableForm.get('deliverable_status').value;
          }
          self.previousDeliverableType = self.currentDeliverableType;
          self.previousDeliverableStatus = self.currentDeliverableStatus;
        }
        self._enableControls();
      });

    self.deliverableForm.get('owner').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe((owner) => {
        if (!owner || owner.eso_id !== self.loginUser.eso_id) {
          const teamMembers = self.getMultipleSelectValues('team_members');
          const index = self.services.commonService.objectIndexOf(teamMembers, self.loginUser, 'eso_id');
          if (index < 0) {
            teamMembers.push(self.loginUser);
          }
          self.deliverableForm.get('team_members').setValue(teamMembers);
        }
      });

    self.deliverableForm.get('primary_group').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.deliverableForm.get('primary_workstream').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.deliverableForm.get('primary_location').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.deliverableForm.get('eac_qrtr').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        const eacQrtrYears = self.getQuartsOptions('eac_qrtr_year');
        const endYear = self.deliverableForm.get('eac_qrtr_year').value;
        if (eacQrtrYears && eacQrtrYears.length && !endYear) {
          self.deliverableForm.get('eac_qrtr_year').setValue(eacQrtrYears[1].year);
        } else if (eacQrtrYears && eacQrtrYears.length && endYear) {
          self.deliverableForm.get('eac_qrtr_year').setValue(endYear);
        } else {
          self.deliverableForm.get('eac_qrtr_year').setValue(null);
        }
        self._enableControls();
      });

    self.deliverableForm.get('deliverable_status').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self.currentDeliverableStatus = self.deliverableForm.get('deliverable_status').value;
        self._enableControls();
      });
  }

  primaryOptionCheck(self) {
    const deliverable = self.deliverableForm.getRawValue();

    const primaryWorkstreamOptions = self.getMultipleSelectOptions('primary_workstream', 'name', false, true);
    const workstreamIndex = self.services.commonService.objectIndexOf(primaryWorkstreamOptions, deliverable.primary_workstream);
    if (workstreamIndex < 0) {
      self.deliverableForm.get('primary_workstream').setValue(null, { emitEvent: false });
    }
    if (primaryWorkstreamOptions.length == 1) {
      self.deliverableForm.get('primary_workstream').setValue(primaryWorkstreamOptions[0], { emitEvent: false });
    }

    const primaryLocationOptions = self.getMultipleSelectOptions('primary_location', 'name', false, true);
    let locationIndex;
    if (primaryLocationOptions !== undefined && primaryLocationOptions.length !== 0) {
      locationIndex = self.services.commonService.objectIndexOf(primaryLocationOptions, deliverable.primary_location);
    }
    if (locationIndex < 0) {
      self.deliverableForm.get('primary_location').setValue(null, { emitEvent: false });
    }
    if (primaryLocationOptions !== undefined && primaryLocationOptions.length == 1) {
      self.deliverableForm.get('primary_location').setValue(primaryLocationOptions[0], { emitEvent: false });
    }
    if (self.orgTypeName == OrgType.LIQUID) {
      if (locationIndex >= 0) {
        self.deliverableForm.get('related_deliverables').enable({ emitEvent: false });
        const removedPrimaryLocation = primaryLocationOptions[locationIndex];
        let otherLocations = self.deliverableForm.get('other_locations').value;
        if (otherLocations.length > 0) {
          otherLocations = otherLocations.filter((o) => { return o._id != removedPrimaryLocation._id; });
        }
        self.deliverableForm.get('other_locations').setValue(otherLocations, { emitEvent: false });
      } else {
        self.deliverableForm.get('other_locations').setValue([], { emitEvent: false });
      }
    }
  }

  otherOptionCheck(self) {
    let deliverable = self.deliverableForm.getRawValue();
    const otherGroupsOptions = self.getMultipleSelectOptions('other_groups', 'name', false, true);
    const removeGroups = [];
    deliverable.other_groups.forEach((group) => {
      const groupIndex = self.services.commonService.objectIndexOf(otherGroupsOptions, group);
      if (groupIndex < 0) {
        removeGroups.push(group);
      }
    });
    removeGroups.forEach((group) => {
      const groupIndex = self.services.commonService.objectIndexOf(deliverable.other_groups, group);
      if (groupIndex >= 0) {
        deliverable.other_groups.splice(groupIndex, 1);
      }
    });
    self.deliverableForm.get('other_groups').setValue(deliverable.other_groups, { emitEvent: false });

    const otherWorkstreamsOptions = self.getMultipleSelectOptions('other_workstreams', 'name', false, true);
    const removeWorkstreams = [];
    deliverable.other_workstreams.forEach((workstream) => {
      const workstreamIndex = self.services.commonService.objectIndexOf(otherWorkstreamsOptions, workstream);
      if (workstreamIndex < 0) {
        removeWorkstreams.push(workstream);
      }
    });
    removeWorkstreams.forEach((workstream) => {
      const workstreamIndex = self.services.commonService.objectIndexOf(deliverable.other_workstreams, workstream);
      if (workstreamIndex >= 0) {
        deliverable.other_workstreams.splice(workstreamIndex, 1);
      }
    });
    self.deliverableForm.get('other_workstreams').setValue(deliverable.other_workstreams, { emitEvent: false });


    const otherLocationsOptions = self.getMultipleSelectOptions('other_locations', 'name', false, true);
    const removeLocations = [];
    deliverable.other_locations.forEach((location) => {
      const locationIndex = self.services.commonService.objectIndexOf(otherLocationsOptions, location);
      if (locationIndex < 0) {
        removeLocations.push(location);
      }
    });
    removeLocations.forEach((location) => {
      const locationIndex = self.services.commonService.objectIndexOf(deliverable.other_locations, location);
      if (locationIndex >= 0) {
        deliverable.other_locations.splice(locationIndex, 1);
      }
    });
    self.deliverableForm.get('other_locations').setValue(deliverable.other_locations, { emitEvent: false });

    // Enable and disable
    deliverable = self.deliverableForm.getRawValue();
    if ((deliverable.other_groups.length || deliverable.primary_workstream) && otherWorkstreamsOptions.length) {
      self.deliverableForm.get('other_workstreams').enable({ emitEvent: false });
    } else {
      self.deliverableForm.get('other_workstreams').disable({ emitEvent: false });
    }

    if ((deliverable.other_groups.length || deliverable.primary_location) && otherLocationsOptions.length) {
      self.deliverableForm.get('other_locations').enable({ emitEvent: false });
    } else {
      self.deliverableForm.get('other_locations').disable({ emitEvent: false });
    }
  }

  enableControls(self) {
    // Enable and disable
    const deliverable = self.deliverableForm.getRawValue();
    if (!self.isNew && !self.deliverable.canEdit && !self.deliverable.canEditInitiative) {
      self.showSaveBtn = false;
      self.deliverableForm.disable({ emitEvent: false });
    } else if (!self.isNew && !self.deliverable.canEdit && self.deliverable.canEditInitiative) {
      self.showSaveBtn = true;
      self.deliverableForm.disable({ emitEvent: false });
      self.deliverableForm.get('initiatives').enable({ emitEvent: false });
    } else {
      self.showSaveBtn = true;
      self.deliverableForm.enable({ emitEvent: false });
      if (deliverable.deliverable_type && deliverable.deliverable_type.idf_number_required) {
        self.deliverableForm.get('idf_number').enable({ emitEvent: false });
      } else {
        self.deliverableForm.get('idf_number').disable({ emitEvent: false });
      }

      if (deliverable.deliverable_type && deliverable.deliverable_type.organizationType.name == OrgType.LABS && deliverable.deliverable_type.name == 'IDFs Filed') {
        const completeStatus = self.options.deliverable_status.filter((o) => o.name == 'Complete');
        self.deliverableForm.get('deliverable_status').setValue(completeStatus[0], { emitEvent: false });
        self.deliverableForm.get('deliverable_status').disable({ emitEvent: false });
      } else {
        self.deliverableForm.get('deliverable_status').enable({ emitEvent: false });
      }

      if (deliverable.primary_group) {
        self.deliverableForm.get('primary_workstream').enable({ emitEvent: false });
        self.deliverableForm.get('primary_location').enable({ emitEvent: false });
        self.deliverableForm.get('other_groups').enable({ emitEvent: false });
      } else {
        self.deliverableForm.get('primary_workstream').disable({ emitEvent: false });
        self.deliverableForm.get('primary_location').disable({ emitEvent: false });
        self.deliverableForm.get('other_groups').disable({ emitEvent: false });
      }

      if (deliverable.primary_group && deliverable.primary_workstream) {
        self.deliverableForm.get('related_deliverables').enable({ emitEvent: false });
      } else {
        self.deliverableForm.get('related_deliverables').disable({ emitEvent: false });
      }

      if (self.orgTypeName == OrgType.LIQUID) {
        self.deliverableForm.get('primary_location').enable({ emitEvent: false });
        if (self.deliverableForm.get('primary_location').value) {
          self.deliverableForm.get('related_deliverables').enable({ emitEvent: false });
        }
      }

      if (self.orgTypeName == OrgType.INCUBATION || self.orgTypeName == OrgType.OPENINNOVATION) {
        if (self.deliverableForm.get('primary_group').value) {
          self.deliverableForm.get('related_deliverables').enable({ emitEvent: false });
        }
      }

      if (deliverable.eac_qrtr) {
        self.deliverableForm.get('eac_qrtr_year').enable({ emitEvent: false });
      } else {
        self.deliverableForm.get('eac_qrtr_year').disable({ emitEvent: false });
      }
    }
  }


  onChangeOwner(self, event): void {
    self.options['owner'] = [];
    if (event.target.value == '') {
      self.deliverableForm.get('owner').setValue(null);
    }
    self.ownerChanged.next(event.target.value);
  }

  onChangeClient(self, event): void {
    self.options['client'] = [];
    if (self.showAllClientInfo) {
      self.showAllClientInfo = '';
      const input = document.getElementById('client');
      input['value'] = '';
    }
    if (event.target.value == '') {
      self.deliverableForm.get('customer_number').setValue('');
      self.deliverableForm.get('customer_name').setValue('');
      self.deliverableForm.get('country_key').setValue('');
    }
    self.clientChanged.next(event.target.value);
  }

  getGroupMaster(self, group) {
    const index = self.services.commonService.objectIndexOf(self.options['groups'], group);
    if (index >= 0) {
      return self.options['groups'][index];
    }
    return { workstreams: [], locations: [] };
  }

  getMultipleSelectOptions(self, field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    const input = document.getElementById(field);
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    if (onlyTypeHead && input && input['value'].trim() == '') {
      filterValue = null;
    }
    let options = [];
    let filterResult = [];
    let keyArray: any;
    if (field == 'owner') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      const teamMembers = self.deliverableForm.get('team_members').value;
      self.options['owner'].forEach((user) => {
        const index = self.services.commonService.objectIndexOf(teamMembers, user, 'eso_id');
        if (index < 0) {
          options.push(user);
        }
      });
    } else if (field == 'team_members') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      const owner = self.deliverableForm.get('owner').value;
      if (owner) {
        self.options['team_members'].forEach((item) => {
          if (item.eso_id !== owner.eso_id) {
            options.push(item);
          }
        });
      } else {
        options = self.options['team_members'];
      }
    } else if (field == 'primary_group') {
      options = self.options['groups'];
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.order));
      }
    } else if (field == 'other_groups') {
      const group = self.deliverableForm.get('primary_group').value;
      if (group) {
        self.options['groups'].forEach((item) => {
          if (item._id !== group._id) {
            options.push(item);
          }
        });
      }
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.order));
      }
    } else if (field == 'primary_workstream') {
      const group = self.deliverableForm.get('primary_group').value;
      if (group) {
        const groupMaster = self.getGroupMaster(group);
        options = groupMaster.workstreams;
      }
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.workstreamOrder));
      }
    } else if (field == 'other_workstreams') {
      const group = self.deliverableForm.get('primary_group').value;
      const primary_workstream = self.deliverableForm.get('primary_workstream').value;
      if (group && primary_workstream) {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.workstreams.forEach((workstream) => {
          const index = self.services.commonService.objectIndexOf(options, workstream);
          if (index < 0 && primary_workstream._id != workstream._id) {
            options.push(workstream);
          }
        });
      }
      const otherGroups = self.deliverableForm.get('other_groups').value;
      otherGroups.forEach((group) => {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.workstreams.forEach((workstream) => {
          if (!primary_workstream || primary_workstream && primary_workstream._id != workstream._id) {
            const index = self.services.commonService.objectIndexOf(options, workstream);
            if (index < 0) {
              options.push(workstream);
            }
          }
        });
      });
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.workstreamOrder));
      }
    } else if (field == 'primary_location') {
      const group = self.deliverableForm.get('primary_group').value;
      if (group) {
        const groupMaster = self.getGroupMaster(group);
        options = groupMaster.locations;
      } else if (!group && self.orgTypeName == OrgType.LIQUID) {
        options = self.locationList;
      }
      if (self.groupOrder && options) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.locationOrder));
      }
    } else if (field == 'other_locations') {
      const group = self.deliverableForm.get('primary_group').value;
      const primary_location = self.deliverableForm.get('primary_location').value;
      if (group && primary_location) {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.locations.forEach((location) => {
          const index = self.services.commonService.objectIndexOf(options, location);
          if (index < 0 && primary_location._id != location._id) {
            options.push(location);
          }
        });
      }
      const otherGroups = self.deliverableForm.get('other_groups').value;
      otherGroups.forEach((group) => {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.locations.forEach((location) => {
          if (!primary_location || primary_location && primary_location._id != location._id) {
            const index = self.services.commonService.objectIndexOf(options, location);
            if (index < 0) {
              options.push(location);
            }
          }
        });
      });
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.locationOrder));
      }
    } else if (field == 'keywords') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      options = self.options[field];
    }
    // need to enhancement 'group' and if 条件合并
    else if (field == 'partners') {
      const deliverable_type = self.deliverableForm.get('deliverable_type').value;
      const partnersList = [];
      self.options['partners'].forEach((item) => {
        if (deliverable_type?.name == 'University Relationships' && item.partnerType == 'University' ||
          deliverable_type?.name == 'Vendor Relationships' && item.partnerType == 'Vendor'
        ) {
          partnersList.push(item);
          options = partnersList;
        }
        else if (deliverable_type?.name !== 'University Relationships' &&
          deliverable_type?.name !== 'Vendor Relationships'
        ) {
          partnersList.push(item);
          options = partnersList;
        }
      });
    }
    else if (field == 'initiatives') {
      if (!self.isNew && !self.deliverable.canEdit && self.deliverable.canEditInitiative) {
        options = self.options[field].filter((i) => i.initiative_lead && i.initiative_lead.eso_id == self.loginUser.eso_id);
      } else {
        options = self.options[field];
      }
    } else if (field == 'client') {
      if (filterValue) {
        filterValue = filterValue.trim();
        options = self.options[field];
        keyArray = key.split(',');
        for (const keyword of keyArray) {
          const result = options.filter((option) => {
            return option[keyword] && option[keyword].toLowerCase().includes(filterValue);
          });
          filterResult = filterResult.concat(result);
        }
        return filterResult;
      } else {
        return [];
      }
    } else {
      options = self.options[field];
    }
    if (withoutFilter) {
      return options;
    }
    if (filterValue) {
      if (key != null) {
        if (field == 'partners') {
          return options.filter((option) => {
            return option[key] && option[key].toLowerCase().includes(filterValue);
          }
          );
        } else {
          return options.filter((option) => {
            return option[key] && (option[key].toLowerCase().includes(filterValue) || Object.keys(option).some((k) => option[k] && option[k].toString().toLowerCase().includes(filterValue)));
          }
          );
        }
      }
      return options.filter((option) => {
        return option && option.toLowerCase().includes(filterValue);
      });
    } else if (onlyTypeHead) {
      return [];
    }
    return options;
  }

  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    const values = self.deliverableForm.get(field).value;
    const input = document.getElementById(field);
    if (allowEnter) {
      const value = input['value'];
      if (value.trim() != '') {
        const index = self.services.commonService.objectIndexOf(values, value, null);
        if (index < 0) {
          values.push(value);
        }
      }
    } else {
      const index = self.services.commonService.objectIndexOf(values, event.option.value, key);
      if (index < 0) {
        values.push(event.option.value);
      }
    }
    input['value'] = '';
    input.blur();
    self.deliverableForm.get(field).setValue(values);
    self.deliverableForm.get(field).markAsDirty();
  }

  onAddSelectValue(self, field: string, event: MatAutocompleteSelectedEvent): void {
    const input = document.getElementById(field);
    input['value'] = event.option.viewValue;
    input.blur();
    self.deliverableForm.get(field).setValue(event.option.value);
    self.deliverableForm.get(field).markAsDirty();
  }

  onAddClientValue(self, field: string, event: MatAutocompleteSelectedEvent): void {
    const input = document.getElementById(field);
    input.blur();
    self.showAllClientInfo = event.option.viewValue;
    self.deliverableForm.get('customer_number').setValue(event.option.value.customer_number);
    self.deliverableForm.get('customer_name').setValue(event.option.value.customer_name);
    self.deliverableForm.get('country_key').setValue(event.option.value.country_key);
    self.deliverableForm.get('customer_number').markAsDirty();
    self.deliverableForm.get('customer_name').markAsDirty();
    self.deliverableForm.get('country_key').markAsDirty();
  }

  canInitiativeAccess(self, initiative) {
    if (!self.isNew && !self.deliverable.canEdit && self.deliverable.canEditInitiative) {
      if (initiative && initiative.initiative_lead && initiative.initiative_lead.eso_id == self.loginUser.eso_id) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    const values = self.deliverableForm.get(field).value;
    const index = self.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.deliverableForm.get(field).setValue(values);
    self.deliverableForm.get(field).markAsDirty();
  }

  getQuartsOptions(self, field: string): any[] {
    const options = [];
    if (field == 'eac_qrtr') {
      self.options['eac_qrtr'].forEach((qrtr) => {
        const index = self.services.commonService.objectIndexOf(options, qrtr, 'qtr');
        if (index < 0) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a.qtr - b.qtr; });
    } else if (field == 'eac_qrtr_year') {
      const eacQrtr = self.deliverableForm.get('eac_qrtr').value;
      self.options['eac_qrtr'].forEach((qrtr) => {
        const index = self.services.commonService.objectIndexOf(options, qrtr, 'year');
        if (index < 0 && qrtr.qtr == eacQrtr) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a.year - b.year; });
    }
    return options;
  }

  isValueNotFoundInOptions(self, field: string, key: string): boolean {
    let result = true;
    const value = self.deliverableForm.get(field).value;
    if (!value) {
      return false;
    }
    const options = self.getQuartsOptions(field);
    options.forEach((opt) => {
      if (opt[key] == value) {
        result = false;
      }
    });
    return result;
  }

  onAddUrl(self): any {
    const input = document.getElementById('url_text');
    const value = input['value'].trim();
    if (value !== '') {
      const values = self.deliverableForm.get('url').value;
      const expression = /^(http|https|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|.|#|-|%)*$/gi;
      const regex = new RegExp(expression);
      if (!value.match(regex)) {
        input.classList.add('error');
        document.getElementById('url_text_hint').classList.add('show');
        return;
      } else {
        input.classList.remove('error');
        document.getElementById('url_text_hint').classList.remove('show');
      }
      const index = self.services.commonService.objectIndexOf(values, value, null);
      if (index < 0) {
        values.push(value);
      }
      input['value'] = '';
      self.deliverableForm.get('url').setValue(values, { emitEvent: true });
      self.deliverableForm.get('url').markAsDirty();
    } else {
      input.classList.remove('error');
      document.getElementById('url_text_hint').classList.remove('show');
    }
  }

  onRemoveUrl(self, value: any): void {
    const values = self.deliverableForm.get('url').value;
    const index = self.services.commonService.objectIndexOf(values, value, null);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.deliverableForm.get('url').setValue(values);
    self.deliverableForm.get('url').markAsDirty();
  }

  invalidFocus(self) {
    setTimeout(() => {
      const invalidMatControl = self.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = self.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }

  formReset(self) {
    const defaultDeliverable = {
      owner: self.loginUser,
      related_deliverables: [],
      initiatives: [],
      url: [],
      team_members: [],
      other_groups: [],
      other_workstreams: [],
      other_locations: [],
      partners: [],
      keywords: [],
      industries: [],
    };
    self.deliverableForm.reset(defaultDeliverable, { emitEvent: false });
  }

  getFromData(self) {
    const formData = self.deliverableForm.getRawValue();
    if (formData.name) {
      formData.name = formData.name.trim();
    }
    if (formData.short_description) {
      formData.short_description = formData.short_description.trim();
    }
    if (formData.long_description) {
      formData.long_description = formData.long_description.trim();
    }
    if (formData.idf_number) {
      formData.idf_number = formData.idf_number.trim();
    }
    if (formData.eac_qrtr) {
      formData.eac_qrtr = {
        qtr: formData.eac_qrtr,
        year: formData.eac_qrtr_year
      };
    }
    if (!formData.primary_group && self.orgTypeName !== OrgType.LIQUID) {
      formData.primary_group = null;
      formData.primary_location = null;
      formData.primary_workstream = null;
    }
    formData.organization_type = self.orgType;
    return formData;
  }

  setDefaultStatus(self) {
    if (!self.deliverableForm.get('deliverable_status').value) {
      for (const option of self.options.deliverable_status) {
        if (option.name == 'In Progress') {
          self.deliverableForm.get('deliverable_status').setValue(option);
          break;
        }
      }
    }
  }

  afterSave(self, response, isNew, isDraft, isQuick = null) {
    self.loading--;
    if (response) {
      self.services.commonService.openSnackBar('success', isNew ? Messages.SAVE_SUCCESS : Messages.UPDATE_SUCCESS);
      self._formReset();
      if (!isQuick)
        if (isDraft)
          self.router.navigate(['deliverables/list/drafts']);
        else
          self.router.navigate(['/deliverables/list/live']);
      else
        self.dialogRef.close(true);
    }
  }
}
