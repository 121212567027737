/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { StageService } from '../stage.service';

@Injectable({
  providedIn: 'root'
})
export class StageApiService implements StageService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getStages(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/projectstages/stages`, this.commonService.httpOptions(params));
  }
  editStages(params, reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/projectstages/update/${params}`, reqbody, this.commonService.httpOptions());
  }
  getBydeliverableType(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/projectstages/groupbyDelivarableType`, this.commonService.httpOptions(params));
  }
}

