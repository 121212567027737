/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, delay, retry } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { DeliverableService } from '../deliverable.service';

@Injectable()
export class DeliverableAPIService implements DeliverableService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getDeliverableStatus(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverablestatus', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/deliverablestatus`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverablesKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/deliverables/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/deliverables/search/keywords`, this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverables(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/deliverables/list', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/deliverables/list`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverable(deliverableId: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/deliverables/${deliverableId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getRelatedDeliverables(parameters: any): Observable<any> {
    this.commonService.log('GET:', '/deliverables/search/related', 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get(`${environment.API_URL}/deliverables/search/related`, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  setFormData(deliverable, file, orgTypeName = null): FormData {
    const formData = new FormData();
    if (file) { formData.append('deliverableImage', file); }
    if (orgTypeName) { formData.append('orgTypeName', orgTypeName); }
    formData.append('deliverable', JSON.stringify(deliverable));
    return formData;
  }

  saveDraft(deliverable, file): Observable<any> {
    const formData = this.setFormData(deliverable, file);
    this.commonService.log('POST:', '/deliverables/draft', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/deliverables/draft`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  updateDraft(deliverableId, deliverable, file, orgTypeName = null): Observable<any> {
    const formData = this.setFormData(deliverable, file);
    this.commonService.log('PUT:', `/deliverables/draft/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.put(`${environment.API_URL}/deliverables/draft/${deliverableId}`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  save(deliverable, file, orgTypeName = null): Observable<any> {
    const formData = this.setFormData(deliverable, file, orgTypeName);
    this.commonService.log('POST:', '/deliverables', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/deliverables`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  update(deliverableId, deliverable, file, orgTypeName = null): Observable<any> {
    const formData = this.setFormData(deliverable, file, orgTypeName);
    this.commonService.log('POST:', `/deliverables/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/deliverables/${deliverableId}`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getReviewers(deliverableId: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/reviewer/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/deliverables/reviewer/${deliverableId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  reviewRequest(deliverableId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/request/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/review/request/${deliverableId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  publishRequest(deliverableId: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/publish/request/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/publish/request/${deliverableId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  approve(deliverableId: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/approve/${deliverableId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/review/approve/${deliverableId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  approveRework(deliverableId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/rework/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/review/rework/${deliverableId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  approveReassign(deliverableId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/reassign/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/review/reassign/${deliverableId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  publishRework(deliverableId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/deliverables/publish/rework/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/publish/rework/${deliverableId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  publishApprove(deliverableId: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/publish/approve/${deliverableId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/publish/approve/${deliverableId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getTypeAheadData(queryParams): Observable<any> {
    return this.http.get(`${environment.API_URL}/search/suggest`, this.commonService.httpOptions(queryParams));
  }
  getTypeAheadDeliverableData(queryParams): Observable<any> {
    return this.http.get(`${environment.API_URL}/search/deliverable/suggest`, this.commonService.httpOptions(queryParams));
  }
  getFilter(parameters: any, formData: any): Observable<any> {
    return this.http.post(`${environment.API_URL}/search/list`, { filter: formData }, this.commonService.httpOptions(parameters));
  }

  sendReminder(deliverableId: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/sendreminder/${deliverableId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/deliverables/review/sendreminder/${deliverableId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverableAndProjectKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/search/keywords`, this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  triggerError(errorCode: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/triggerError/${errorCode}`, this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/deliverables/triggerError/${errorCode}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getClientUser(searchText: any): Observable<any> {
    searchText = searchText.toLowerCase().trim();
    this.commonService.log('GET:', `/deliverables/client/${searchText}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/deliverables/client/${searchText}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          res.result.forEach((element) => {
            element.customer_number = this.fromCode(element.customer_number);
            element.customer_name = this.fromCode(element.customer_name);
            element.country_key = this.fromCode(element.country_key);
          });
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  fromCode(str) {
    const key = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const l = key.length;
    let b, b1, b2, b3, d = 0, s;
    s = new Array(Math.floor(str.length / 3));
    b = s.length;
    for (let i = 0; i < b; i++) {
      b1 = key.indexOf(str.charAt(d));
      d++;
      b2 = key.indexOf(str.charAt(d));
      d++;
      b3 = key.indexOf(str.charAt(d));
      d++;
      s[i] = b1 * l * l + b2 * l + b3;
    }
    b = eval('String.fromCharCode(' + s.join(',') + ')');
    return b;
  }

  getSettings(bookType: any): Observable<any> {
    this.commonService.log('GET:', '/bookMarkSetting/settings/type', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/bookMarkSetting/settings/type/${bookType}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getSetting(settingId: any): Observable<any> {
    this.commonService.log('GET:', `/bookMarkSetting/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/bookMarkSetting/settings/${settingId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  deleteSetting(settingId: any): Observable<any> {
    this.commonService.log('DELETE:', `/bookMarkSetting/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.delete(`${environment.API_URL}/bookMarkSetting/settings/${settingId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  saveSetting(formData: any): Observable<any> {
    this.commonService.log('POST:', '/bookMarkSetting/settings', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/bookMarkSetting/settings`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  updateSetting(settingId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/bookMarkSetting/settings/${settingId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/bookMarkSetting/settings/${settingId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getClient(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/search/client', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/search/client`, this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}

