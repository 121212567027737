<div fxLayout="column" class="list-item-card" tabindex="0" (keydown.enter)="onProjectClick()">
    <div fxLayout="row" fxLayoutGap="20px" class="name-row">
        <a fxFlex fxLayout="row" fxLayoutGap="20px" (click)="onProjectClick()">
            <img [src]="imgURL" alt="" class="list-item-image">
            <div fxFlex fxLayout="column">
                <h2 name="projectNameItem" class="white-space-pre">{{project.name}}</h2>
                <p name="projectShortDesc">{{project.short_description}}</p>
            </div>
        </a>
        <div fxLayout="row" class="action-row" *ngIf="isFromSearch">
            <div fxLayout="row" fxLayoutGap="20px" class="action-row-inner">
            <div name="searchProjectStageName" class="tag project-tag" *ngIf="project.project_stage">{{project.project_stage.name}}</div>
            </div>
        </div>
        <div fxLayout="row" class="action-row" *ngIf="action">
            <div fxLayout="row" class="action-row-inner">
                <div name="actionProjectStageName" class="tag project-tag" *ngIf="project.project_stage">{{project.project_stage.name}}</div>
                <button name="projectMenuIcon" mat-icon-button matRipple [matRippleColor]="'#a100ff'" [matRippleCentered]="true" 
                [matRippleUnbounded]="false" [matRippleRadius]="18" class="menu-btn" [matMenuTriggerFor]="projectMenu" aria-label="workflow status">
                    <span class="icon ic-menu"></span>
                </button>
                <mat-menu #projectMenu="matMenu" class="live-menu">
                    <button name="editProject" mat-menu-item [routerLink]="['/projects', project._id]">Edit Project</button>
                    <button name="requestReview" mat-menu-item *ngIf="canRequestReview(project)" (click)="onReviewRequest(project)">Request Review</button>
                    <button name="reassign" mat-menu-item *ngIf="canApprove(project)" (click)="onReassign(project)">Reassign</button>
                    <button name="approve" mat-menu-item *ngIf="canApprove(project)" (click)="onApprove(project)">Approve</button>
                    <button name="sendReminder" mat-menu-item *ngIf="canSendReminder(project)" (click)="onSendReminder(project._id)">Send Reminder</button>
                    <button name="advanceStage" *ngIf="project.stage_number < 5" mat-menu-item (click)="onAdvanceStage(project)">Advance Stage</button>
                    <button name="recommend" mat-menu-item (click)="services.projectsCommonService.sendRecommend(project)">Recommend</button>
                </mat-menu>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <a fxLayout="row" fxLayoutGap="10px" class="data-row" (click)="onProjectClick()">
        <div fxLayout="column" class="data-group">
            <label name="lbl_projectLead">Project Lead</label>
            <span name="projectLead_value">{{project.project_lead?project.project_lead.display_name:'n/a'}}</span>
        </div>
        <div fxLayout="column" class="data-group">
            <label name="lbl_primaryGroup">Primary Group</label>
            <span name="primaryGroup_value">{{project.primary_group?project.primary_group.name:'n/a'}}</span>
        </div>
        <div fxLayout="column" class="data-group">
            <label name="lbl_primaryWorkstream">Primary Workstream</label>
            <span name="primaryWorkstream_value">{{project.primary_workstream?project.primary_workstream.name:'n/a'}}</span>
        </div>
        <div fxLayout="column" class="data-group">
            <label name="lbl_primaryLocation">Primary Location</label>
            <span name="primaryLocation_value">{{project.primary_location?project.primary_location.name:'n/a'}}</span>
        </div>
        <div fxLayout="column" class="data-group">
            <label name="lbl_lastEdited">Last Edited</label>
            <span name="lastEdited_hasValue" *ngIf="project.last_updated_date">{{project.last_updated_date | date }}</span>
            <span name="lastEdited_nullValue" *ngIf="!project.last_updated_date">n/a</span>
        </div>
    </a>
</div>