/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  // styleUrls: ['./data-table.component.scss']
})
export class DataTableComponent implements OnChanges {
  @Input()
  public tableData;
  @Input()
  public tableOrder?='asc';
  @Input()
  public displayedColumns: string[];
  @Input()
  public tableHeaders: string[];
  @Input()
  public searchInput? = '';
  @Input()
  public totalEntries: string;
  @Input()
  public loadVal?=0;
  @Output() private selectedItem: EventEmitter<any> = new EventEmitter();
  @Output() private selectedOrder: EventEmitter<any> = new EventEmitter();
  @Output() private clickedItem: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatSort) sort: MatSort;
  isHeaderChecked = false;

  ngOnChanges(): void {
    this.isHeaderChecked = false;
    if(this.tableData) {
      this.tableData.sort = this.sort;
      this.tableData.filter = this.searchInput.trim().toLowerCase();
    }
  }

  selectAll(event): void {
    this.isHeaderChecked = (event.checked) ? true :  false;
    this.tableData.filteredData.forEach((element) => {
      if (event.checked) {
        element.checked = true;
      } else {
        element.checked = false;
      }
    });
    this.selectedItem.emit(this.tableData.filteredData);
  }

  selectOne(item): void {
    this.selectedItem.emit(item);
  }
  sortData(value):void{
    this.selectedOrder.emit(value.direction);
  }
  clickElement(element): void {
    this.clickedItem.emit(element);
  }
}
