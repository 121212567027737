/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { AdminService } from '../admin.service';

@Injectable()
export class AdminAPIService implements AdminService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getUsersByRole(role: string): Observable<any> {
    return this.http.get(`${environment.API_URL}/users/fetchbyrole`, this.commonService.httpOptions(role));
  }

  addAdmin(parameters): Observable<any> {
    return this.http.patch(`${environment.API_URL}/users/admin/save`, {}, this.commonService.httpOptions(parameters));
  }

  editAdmin(reqBody, parameters): Observable<any> {
    return this.http.patch(`${environment.API_URL}/users/admin/edit`, reqBody, this.commonService.httpOptions(parameters));
  }
}

