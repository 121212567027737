<div fxLayout="column">
  <div fxLayout="column" class="filter-wrap" [formGroup]="filterForm" *ngIf="show" [@slideInOut]>
    <label id="lblFilterResultsBy">Filter Results By:</label>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33" class="form-group">
          <input type="text" aria-label="organization" id="organization" placeholder="Organization" class="form-control"
            [matAutocomplete]="organization" />
          <mat-autocomplete id="group_auto" #organization="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('organization', $event)">
            <mat-option id="organization_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('organization');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="organization_list">
            <mat-chip id="organization_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('organization');let i=index"
              (removed)="onRemoveMultipleSelectValue('organization', option)">
              {{option.name}}
              <span id="organization_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group">
          <input type="text" id="type" placeholder="Content Type" class="form-control" [matAutocomplete]="type"
            aria-label="type" />
          <mat-autocomplete id="type_auto" #type="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('type', $event)">
            <mat-option id="type_option{{i}}" *ngFor="let option of getMultipleSelectOptions('type');let i=index"
              [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="type_list">
            <mat-chip id="type_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('type');let i=index"
              (removed)="onRemoveMultipleSelectValue('type', option)">
              {{option.name}}
              <span id="type_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group">
          <input type="text" id="deliverable_type" placeholder="Deliverable Type" class="form-control"
            [matAutocomplete]="deliverableType" aria-label="deliverable_type" [disabled]="isShowDeliverableType" />
          <mat-autocomplete id="deliverable_type_auto" #deliverableType="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('deliverable_type', $event)">
            <mat-option id="deliverable_type_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('deliverable_type');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="deliverable_type_list">
            <mat-chip id="deliverable_type_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('deliverable_type');let i=index"
              (removed)="onRemoveMultipleSelectValue('deliverable_type', option)">
              {{option.name}}
              <span id="deliverable_type_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33" class="form-group">
          <input type="text" id="group" placeholder="Group" class="form-control" [matAutocomplete]="group"
            aria-label="group" />
          <mat-autocomplete id="group_auto" #group="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('group', $event)">
            <mat-option id="group_option{{i}}" *ngFor="let option of getMultipleSelectOptions('group');let i=index"
              [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="group_list">
            <mat-chip id="group_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('group');let i=index"
              (removed)="onRemoveMultipleSelectValue('group', option)">
              {{option.name}}
              <span id="group_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>

        <div fxFlex="33" class="form-group">
          <input type="text" id="workstream" placeholder="Workstream" class="form-control"
            [matAutocomplete]="workstream" aria-label="workstream" />
          <mat-autocomplete id="workstream_auto" #workstream="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('workstream', $event)">
            <mat-option id="workstream_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('workstream');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="workstream_list">
            <mat-chip id="workstream_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('workstream');let i=index"
              (removed)="onRemoveMultipleSelectValue('workstream', option)">
              {{option.name}}
              <span id="workstream_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group">
          <input type="text" id="location" placeholder="Location" class="form-control" [matAutocomplete]="location"
            aria-label="location" />
          <mat-autocomplete id="location_auto" #location="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('location', $event)">
            <mat-option id="location_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('location');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="location_list">
            <mat-chip id="location_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('location');let i=index"
              (removed)="onRemoveMultipleSelectValue('location', option)">
              {{option.name}}
              <span id="location_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33" class="form-group">

          <input type="text" id="industries" placeholder="Industry" class="form-control" [matAutocomplete]="industries"
            aria-label="delindustries" />
          <mat-autocomplete id="industries_auto" #industries="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('industries', $event)">
            <mat-option id="industries_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('industries');let i=index" [value]="option">
              {{option.name}}</mat-option>

          </mat-autocomplete>

          <mat-chip-set id="industries_list">
            <mat-chip id="industries_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('industries');let i=index"
              (removed)="onRemoveMultipleSelectValue('industries', option)">

              {{option.name}}
              <span id="industries_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group">
          <input type="text" id="initiative" placeholder="Initiative" class="form-control"
            [matAutocomplete]="initiative" aria-label="initiative" />
          <mat-autocomplete id="initiative_auto" #initiative="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('initiative', $event)">
            <mat-option id="initiative_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('initiative');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="initiative_list">
            <mat-chip id="initiative_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('initiative');let i=index"
              (removed)="onRemoveMultipleSelectValue('initiative', option)">
              {{option.name}}
              <span id="initiative_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group">
          <input type="text" id="keyword" placeholder="Keyword" class="form-control" [matAutocomplete]="keyword"
            (keyup)="onChangeKeyWord($event)" aria-label="keyword" />
          <mat-progress-bar *ngIf="loadKeywords" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="keyword_auto" #keyword="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('keyword', $event, null)">
            <mat-option id="keyword_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('keyword', null, true);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="keyword_list">
            <mat-chip id="keyword_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('keyword');let i=index"
              (removed)="onRemoveMultipleSelectValue('keyword', option, null)">
              {{option}}
              <span id="keyword_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33" class="form-group">
          <input type="text" id="client" placeholder="Client" class="form-control" [matAutocomplete]="client"
            (keyup)="onChangeClient($event)" aria-label="client" />
          <mat-progress-bar *ngIf="loadClient" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="client_auto" #client="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('client', $event, null)">
            <mat-option id="client_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('client', null, true);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="client_list">
            <mat-chip id="client_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('client');let i=index"
              (removed)="onRemoveMultipleSelectValue('client', option, null)">
              {{option}}
              <span id="client_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group">
          <input type="text" id="force" placeholder="Force Name" class="form-control" [matAutocomplete]="force"
            aria-label="force" />
          <mat-autocomplete id="force_auto" #force="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('force', $event)">
            <mat-option id="force_option{{i}}" *ngFor="let option of getMultipleSelectOptions('force');let i=index"
              [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="force_list">
            <mat-chip id="force_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('force');let i=index"
              (removed)="onRemoveMultipleSelectValue('force', option)">
              {{option.name}}
              <span id="force_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group">
          <input type="text" id="nested_themes" placeholder="Nested Themes" class="form-control"
            [matAutocomplete]="nested_themes" aria-label="nested_themes" />
          <mat-autocomplete id="nested_themes_auto" #nested_themes="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('nested_themes', $event)">
            <mat-option id="nested_themes_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('nested_themes');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="nested_themes_list">
            <mat-chip id="nested_themes_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('nested_themes');let i=index"
              (removed)="onRemoveMultipleSelectValue('nested_themes', option)">
              {{option.name}}
              <span id="nested_themes_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxFlex="33" class="form-group">
          <input type="text" id="market" placeholder="Market" class="form-control" [matAutocomplete]="market"
            aria-label="market" />
          <mat-autocomplete id="market_auto" #market="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('market', $event,null)">
            <mat-option id="market_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('market',null);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="market_list">
            <mat-chip id="market_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('market');let i=index"
              (removed)="onRemoveMultipleSelectValue('market', option, null)">
              {{option}}
              <span id="market_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="33" class="form-group"></div>
        <div fxFlex="33" class="form-group"></div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="btn-position">
        <button id="applyFilters" mat-flat-button color="primary" (click)="onFilter('applyFilters')">Apply Filters
        </button>
      </div>
    </div>
  </div>
  <div fxLayout="row wrap" fxLayoutGap="13px" class="filter-values" *ngIf="hasFilter()">
    <label id="lblAppliedFilters">Applied Filters:</label>
    <ng-container *ngFor="let item of filter | keyvalue;let i=index">
      <ng-container *ngIf="item.value && item.value.length">
        <div id="option{{i}}{{j}}" class="tag" *ngFor="let option of item.value;let j=index">
          <span>{{filterLabels[item.key]}}:</span>&nbsp; {{option && option.name ? option.name : option}}
          <a id="remove{{i}}{{j}}" class="remove_tag" (click)="onRemoveFilter(item.key, option)"><span><i
                class="ic-close"></i></span></a>
        </div>
      </ng-container>
    </ng-container>
    <button id="clearAllFilters" mat-button class="clear-btn" matRipple [matRippleUnbounded]="false"
      [matRippleColor]="'rgba(255, 255, 255, .3)'" [matRippleCentered]="false" (click)="onClearAllFilter()">Clear All
      Filters</button>
  </div>
</div>