import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/services';

@Component({
  selector: 'app-deliverables-live',
  template: '<app-deliverable-list [isLive]="true" [action]="true"></app-deliverable-list>'
})
export class LiveDeliverablesComponent implements OnInit {
  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
  }
}
