/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { InitiativesService } from '@app/services/initiatives.service';
@Injectable({
  providedIn: 'root'
})
export class InitiativesApiService implements InitiativesService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getInitiatives(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/initiatives`, this.commonService.httpOptions(params));
  }
  addInitiative(reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/initiatives`, reqbody, this.commonService.httpOptions());
  }

  editInitiative(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/initiatives/update/${params}`, reqBody, this.commonService.httpOptions());
  }
  changeInitiativeStatus(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/initiatives/archiveind`, reqBody, this.commonService.httpOptions(params));
  }
}
