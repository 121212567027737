<div fxFlex fxLayout="column" class="quick-edit-dialog" aria-labelledby="h_quickDeliverable">
  <div fxFlex fxLayout="column" *ngIf="deliverableForm">
    <div fxLayout="column" class="dialog-header">
      <h2 id="h_quickDeliverable" class="title">Quick {{isNew ? addNewTitle : editTitle}} Deliverable</h2>
    </div>
    <form fxFlex #validForm fxLayout="column" [formGroup]="deliverableForm" class="dialog-content">
      <div fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="50" fxLayout="column">
          <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('name').invalid }">
            <label id="lblDeliverableName">Deliverable Name<span>*</span></label>
            <input type="text" class="form-control" formControlName="name" aria-label="Deliverable Name" maxlength="300"
              id="deliverableName" />
            <p id="deliverableNameLength" class="hint">{{deliverableName ? deliverableName.length :0}}/300
            </p>
          </div>
          <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('short_description').invalid }">
            <label id="lblShortDesc">Short Description<span>*</span></label>
            <textarea id="shortDescription" rows="3" class="form-control short" aria-label="Short description"
              formControlName="short_description" maxlength="150"></textarea>
            <p id="shortDescLength" class="hint">{{shortDesc ? shortDesc.length :0}}/150</p>
          </div>
          <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('owner').invalid }">
            <label id="lblOwner">Owner<span>*</span></label>
            <input type="text" id="owner" aria-label="owner" class="form-control" [matAutocomplete]="owner"
              [disabled]="deliverableForm.get('owner').disabled"
              [value]="deliverableForm.get('owner').value?deliverableForm.get('owner').value.display_name:''"
              (keyup)="onChangeOwner($event)" />
            <mat-progress-bar *ngIf="loadOwner" color="accent" mode="indeterminate"></mat-progress-bar>
            <mat-autocomplete id="owner_auto" #owner="matAutocomplete"
              (optionSelected)="onAddSelectValue('owner', $event)">
              <mat-option id="owner_option_{{i}}"
                *ngFor="let option of getMultipleSelectOptions('owner','email_id', true);let i=index" [value]="option">
                {{option.display_name}}</mat-option>
            </mat-autocomplete>
            <span id="owner_text_hint" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
          </div>
          <div class="form-group" *ngIf="orgTypeName !== 'Liquid Studio'"
            [ngClass]="{'error': submitted && deliverableForm.get('primary_group').invalid }">
            <label id="lblPrimaryGroup">Primary Group<span>*</span></label>
            <mat-select id="drpPrimaryGroup" formControlName="primary_group" [compareWith]="onCompareObjects" aria-label="primary group">
              <mat-option [value]="null"></mat-option>
              <mat-option id="optPrimaryGroup_{{i}}"
                *ngFor="let option of getMultipleSelectOptions('primary_group');let i=index" [value]="option">
                {{option.name}}</mat-option>
            </mat-select>
          </div>
          <div class="form-group" *ngIf="orgTypeName == 'Labs'"
            [ngClass]="{'error': submitted && deliverableForm.get('primary_workstream').invalid }">
            <label id="lblPrimaryWorkstream">Primary Workstream<span>*</span></label>
            <mat-select id="drpPrimaryWorkstream" formControlName="primary_workstream" [compareWith]="onCompareObjects" aria-label="Primary workstream">
              <mat-option [value]="null"></mat-option>
              <mat-option id="optPrimaryWorkstream_{{i}}"
                *ngFor="let option of getMultipleSelectOptions('primary_workstream');let i=index" [value]="option">
                {{option.name}}</mat-option>
            </mat-select>
          </div>
          <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('primary_location').invalid }"
            *ngIf="orgTypeName !== 'Liquid Studio'">
            <label id="lblPrimaryLocation">Primary Location<span>*</span></label>
            <mat-select id="drpPrimaryLocation" formControlName="primary_location" [compareWith]="onCompareObjects" aria-label="Primary Location">
              <mat-option [value]="null"></mat-option>
              <mat-option id="optPrimaryLocation_{{i}}"
                *ngFor="let option of getMultipleSelectOptions('primary_location');let i=index" [value]="option">
                {{option.name}}</mat-option>
            </mat-select>
          </div>
          <!-- Liquid Studio -->
          <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('primary_location').invalid }"
            *ngIf="orgTypeName == 'Liquid Studio'">
            <label id="lblPrimaryLocation">Primary Location<span>*</span></label>
            <mat-select id="drpPrimaryLocation" formControlName="primary_location" [compareWith]="onCompareObjects" aria-label="Primary location">
              <mat-option [value]="null"></mat-option>
              <mat-option id="optPrimaryLocation_{{i}}" *ngFor="let option of locationList;let i=index"
                [value]="option">{{option.name}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div fxFlex="50" fxLayout="column">
          <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex class="form-group"
              [ngClass]="{'error': submitted && deliverableForm.get('deliverable_type').invalid }">
              <label id="lblDeliverableType">Type Of Deliverable<span>*</span></label>
              <mat-select formControlName="deliverable_type" [compareWith]="onCompareObjects" id="drpDeliverableType" aria-label="Type of Deliverable">
                <mat-option [value]="null"></mat-option>
                <mat-option id="optDeliverableType_{{i}}" *ngFor="let option of options.deliverable_type;let i=index"
                  [value]="option" (click)="filterSelectPartners()">{{option.name}}</mat-option>
              </mat-select>
              <span id="type_text_hint"
                *ngIf="deliverableForm.get('deliverable_type').value && deliverableForm.get('deliverable_type').value.description"
                class="hint"
                [matTooltip]="deliverableForm.get('deliverable_type').value.description">{{deliverableForm.get('deliverable_type').value.description}}</span>
            </div>
            <div fxFlex class="form-group" style="width: 155px;"
              [ngClass]="{'error': submitted && deliverableForm.get('idf_number').invalid }">
              <label id="lblIDFNumber">IDF Number</label>
              <input id="IDFNumber" type="text" class="form-control" aria-label="IDFNumber" formControlName="idf_number"
                maxlength="20" />
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex *ngIf="!hidePartners" class="form-group">
              <label id="lblIndustry">Partners</label>
              <input id="partners" type="text" class="form-control" [matAutocomplete]="partners"
                [disabled]="deliverableForm.get('partners').disabled" aria-label="partners" />
              <mat-autocomplete id="partner_auto" (optionSelected)="onAddMultipleSelectValue('partners', $event)"
                #partners="matAutocomplete">
                <mat-option id="partners_option{{i}}"
                  *ngFor="let option of getMultipleSelectOptions('partners');let i=index"
                  [value]="option">{{option.name}}
                </mat-option>
              </mat-autocomplete>
              <!-- <span id="keywords_text_hint" *ngIf="!getMultipleSelectValues('partners')?.length" class="hint "
                [matTooltip]="Messages.PARTNER_HINT">{{Messages.PARTNER_HINT}}</span> -->
              <mat-chip-set id="partners_list">
                <mat-chip id="partners_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                  *ngFor="let option of getMultipleSelectValues('partners');let i=index"
                  (removed)="onRemoveMultipleSelectValue('partners', option)">
                  {{option.name}}
                  <span id="partners_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('partners').disabled"><i
                      class="ic-close"></i></span>
                </mat-chip>
              </mat-chip-set>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('eac_qrtr').invalid }">
              <label id="lblEndQTR">Quarter<span>*</span></label>
              <mat-select formControlName="eac_qrtr" id="drpEndQrtr" aria-label="Quarter">
                <mat-option [value]="null"></mat-option>
                <mat-option id="endQTRHasValue" [value]="deliverableForm.get('eac_qrtr').value" disabled
                  *ngIf="isValueNotFoundInOptions('eac_qrtr', 'qtr')">
                  Q{{deliverableForm.get('eac_qrtr').value}}</mat-option>
                <mat-option id="optEndqtr_{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr');let i=index"
                  [value]="option.qtr">Q{{option.qtr}}</mat-option>
              </mat-select>
            </div>
            <div fxFlex class="form-group" style="width: 155px;"
              [ngClass]="{'error': submitted && deliverableForm.get('eac_qrtr_year').invalid }">
              <label id="lblEndYear">Fiscal Year<span>*</span></label>
              <mat-select formControlName="eac_qrtr_year" id="drpEndQrtrYear" aria-label="Financial year">
                <mat-option id="endYearHasValue" [value]="deliverableForm.get('eac_qrtr_year').value" disabled
                  *ngIf="isValueNotFoundInOptions('eac_qrtr_year', 'year')">
                  FY{{deliverableForm.get('eac_qrtr_year').value - 2000}}</mat-option>
                <mat-option id="optEndYear_{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr_year');let i=index"
                  [value]="option.year">FY{{option.year-2000}}</mat-option>
              </mat-select>
            </div>
          </div>
          <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('deliverable_status').invalid}">
            <label id="lblStatus">Deliverable Status</label>
            <mat-select formControlName="deliverable_status" [compareWith]="onCompareObjects" id="deliverableStatus" aria-label="Deliverable status">
              <mat-option [value]="null"></mat-option>
              <mat-option id="optStatus_{{i}}" *ngFor="let option of options.deliverable_status;let i=index"
                [value]="option">
                {{option.name}}</mat-option>
            </mat-select>
          </div>
          <div class="form-group" *ngIf="!isLiquidStudio">
            <label id="lblInitiatives">Initiatives For Affiliation</label>
            <input type="text" id="initiatives" aria-label="Initiatives for Affiliation" class="form-control"
              [matAutocomplete]="initiatives"
              [disabled]="deliverableForm.get('initiatives').disabled && !showSaveBtn" />
            <mat-autocomplete id="initiatives_auto" #initiatives="matAutocomplete"
              (optionSelected)="onAddMultipleSelectValue('initiatives', $event)">
              <mat-option id="initiatives_option{{i}}"
                *ngFor="let option of getMultipleSelectOptions('initiatives');let i=index" [value]="option">
                {{option.name}}</mat-option>
            </mat-autocomplete>
            <mat-chip-set id="initiatives_list">
              <mat-chip id="initiatives_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                *ngFor="let option of getMultipleSelectValues('initiatives');let i=index"
                (removed)="onRemoveMultipleSelectValue('initiatives', option)">
                {{option.name}}
                <span id="initiatives_delete{{i}}" matChipRemove
                  *ngIf="canInitiativeAccess(option) && (!deliverableForm.get('initiatives').disabled || showSaveBtn)">
                  <i class="ic-close"></i></span>
              </mat-chip>
            </mat-chip-set>
          </div>
          <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('url').invalid}">
            <label id="lblURL">URL</label>
            <div fxLayout="row" fxLayoutGap="10px" style="width: 100%;">
              <input fxFlex type="text" aria-label="URL" id="url_text" class="form-control" (keyup.enter)="onAddUrl()"
                (blur)="onAddUrl()" [disabled]="deliverableForm.get('url').disabled" maxlength="1000" />
              <button id="btnAdd" type="button" mat-stroked-button class="add-btn" color="accent" (click)="onAddUrl()"
                [disabled]="deliverableForm.get('url').disabled">ADD</button>
            </div>
            <span id="url_text_hint" class="hint error" role="alert" [attr.aria-invalid]="Messages.URL_ERROR  ? 'true' : 'false'" [matTooltip]="Messages.URL_ERROR">{{Messages.URL_ERROR}}</span>
            <mat-chip-set id="url_list">
              <mat-chip id="url_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                *ngFor="let option of getMultipleSelectValues('url');let i=index" (removed)="onRemoveUrl(option)">
                {{option}}
                <span id="url_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('url').disabled">
                  <i class="ic-close"></i></span>
              </mat-chip>
            </mat-chip-set>
          </div>
        </div>
      </div>
    </form>
    <div fxLayout="row" fxLayoutGap="10px" class="dialog-actions">
      <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
      <button id="saveChanges" mat-flat-button type="button" color="primary" (click)="onSave()" *ngIf="showSaveBtn">Save
        Changes</button>
    </div>
  </div>
</div>