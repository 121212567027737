/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';
export class PartnerService {
  getPartners(params): Observable<any> {
    return of(params);
  }
  addPartner(reqbody): Observable<any> {
    return of(reqbody);
  }
  editPartners(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  getParnterTypes(): Observable<any> {
    return of(null);
  }
  getParnterRelatedData(): Observable<any> {
    return of(null);
  }
  changePartenrsStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}
