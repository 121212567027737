<header fxLayout="row">
  <div fxLayout="row" class="logo_wrap">
    <img id="logoPicture" src="assets/images/logo.png" alt="logoPicture" />
  </div>
  <div fxFlex></div>
  <div fxLayout="row" fxLayoutGap="20px" class="right_wrap">
    <button
      id="btn_createDeliverable"
      mat-stroked-button
      matRipple
      [matRippleUnbounded]="false"
      matRippleColor="rgba(255,255,255,.1)"
      [matRippleCentered]="false"
      *ngIf="isChildOf('deliverables')"
      type="submit"
      class="create-btn"
      (click)="createDeliverable()"
    >
      CREATE NEW DELIVERABLE
    </button>
    <button
      id="btn_createProject"
      mat-stroked-button
      matRipple
      [matRippleUnbounded]="false"
      matRippleColor="rgba(255,255,255,.1)"
      [matRippleCentered]="false"
      *ngIf="
        isChildOf('projects') &&
        commonService.hasAnyRole([
          Role.GROUP_LEAD,
          Role.WORKSTREAM_LEAD,
          Role.GROUP_LOCATION_LEAD,
          Role.INITIATIVE_LEAD,
          Role.SUPER_ADMIN
        ])
      "
      type="submit"
      class="create-btn"
      (click)="createProject()"
    >
      CREATE NEW PROJECT
    </button>
    <button
      id="btn_createStory"
      mat-stroked-button
      matRipple
      [matRippleUnbounded]="false"
      matRippleColor="rgba(255,255,255,.1)"
      [matRippleCentered]="false"
      *ngIf="isChildOf('stories')"
      type="submit"
      class="create-btn"
      (click)="createStory()"
    >
      CREATE NEW SUCCESS STORY
    </button>
    <img
      id="peoplePicture"
      [src]="getCurrentUserProfileImage()"
      class="user-avatar"
      alt="peoplePicture"
    />
  </div>
</header>
