import { Menu } from '@app/models/menu';
import { orgLinkNames, orgTypes } from './constants';

export const accordionMenu: Menu[] = [
  {
    type: 'General',
    subMenu: [
      {
        linkName: 'Metrics',
        href: '/Admin/General/admin-metrics-view'
      },
      {
        linkName: 'Deliverable Status',
        href: '/Admin/General/admin-status-view'
      },
      {
        linkName: 'Industries',
        href: '/Admin/General/admin-industries-view'
      },
      {
        linkName: 'Initiatives',
        href: '/Admin/General/admin-initiatives-view'
      },
      {
        linkName: 'Partners',
        href: '/Admin/General/admin-partner-view'
      }
    ]
  },
  {
    type: 'Labs',
    subMenu: [
      {
        linkName: 'Locations',
        href: '/Admin/Labs/admin-location-view'
      },
      {
        linkName: 'Groups',
        href: '/Admin/Labs/admin-groups-view'
      },
      {
        linkName: 'Group Location Leads',
        href: '/Admin/Labs/admin-groupLocation-view'
      },
      {
        linkName: 'Workstreams',
        href: '/Admin/Labs/admin-workstream-view'
      },
      {
        linkName: 'Deliverable Types',
        href: '/Admin/Labs/admin-deliverable-type-view'
      },
      {
        linkName: 'Project Stages',
        href: '/Admin/Labs/admin-stage-view'
      }
    ]
  },
  {
    type: 'Liquid Studio',
    subMenu: [
      {
        linkName: 'Locations',
        href: '/Admin/LiquidStudio/admin-location-view'
      },
      {
        linkName: 'Deliverable Types',
        href: '/Admin/LiquidStudio/admin-deliverable-type-view'
      },
    ]
  },
  {
    type: 'Incubation',
    subMenu: [
      {
        linkName: 'Locations',
        href: '/Admin/Incubation/admin-location-view'
      },
      {
        linkName: 'Groups',
        href: '/Admin/Incubation/admin-groups-view'
      },
      {
        linkName: 'Deliverable Types',
        href: '/Admin/Incubation/admin-deliverable-type-view'
      }
    ]
  },
  {
    type: 'Open Innovation / Ventures',
    subMenu: [
      {
        linkName: 'Locations',
        href: '/Admin/Innovation/admin-location-view'
      },
      {
        linkName: 'Groups',
        href: '/Admin/Innovation/admin-groups-view'
      },
      {
        linkName: 'Deliverable Types',
        href: '/Admin/Innovation/admin-deliverable-type-view'
      }
    ]
  },
  {
    type: orgTypes.Metaverse,
    subMenu: [
      {
        linkName: orgLinkNames.LOCATIONS,
        href: '/Admin/Metaverse/admin-location-view'
      },
      {
        linkName: orgLinkNames.GROUPS,
        href: '/Admin/Metaverse/admin-groups-view'
      },
      {
        linkName: orgLinkNames.DELIVERABLE_TYPE,
        href: '/Admin/Metaverse/admin-deliverable-type-view'
      }
    ]
  }
];
