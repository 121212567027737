/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';
export class IndustryService{
  getIndustries(params): Observable<any> {
    return of(params);
  }
  addIndustry(reqbody): Observable<any> {
    return of(reqbody);
  }
  editIndustries(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  changeIndustriesStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}
