/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MetricPopupComponent } from '@app/dialog/metric-popup/metric-popup.component';
import { Messages } from '@app/models';
import { CommonService } from '@app/services';
import { MasterService } from '@app/services/master.service';
import { MetricService } from '@app/services/metric.service';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-metrics-view',
  templateUrl: './metrics-view.component.html',
  // styleUrls: ['./metrics-view.component.scss']
})
export class MetricsViewComponent implements OnInit {
  public metricList;
  public columns: string[] = ['checked', 'name'];
  public headersData = ['', 'Metric'];
  public selectedOption = 'All';
  public checkedCount = 0;
  public editSelectedAdmin = false;
  public selectedMetrics: string[];
  public search = '';
  public checkedAdmin: string;
  public placeHolder;
  public metricIdList = [];
  public metricCount = [];
  public totalMetrics = [];
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public tableOrder = 'asc';
  public loadVal = 0;
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private metricService: MetricService,
    private masterService: MasterService
  ) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.getMetrics();
  }

  getMetrics(): void {
    this.loadVal++;
    this.metricList = [];
    let params;
    if(this.selectedOption === 'All') {
      params = {active: 'All', orgTypeName:  this.orgTypeName};
    } else if (this.selectedOption === 'Enabled'){
      params = {active: true, orgTypeName:  this.orgTypeName};
    } else if (this.selectedOption === 'Disabled') {
      params = {active: false, orgTypeName:  this.orgTypeName};
    }
    this.metricService.getMetrics(params).subscribe((response) => {
      if (response && response.result) {
        response.order==1?this.tableOrder ='asc':this.tableOrder ='desc';
        this.metricList = response.result;
        this.metricList.forEach((element) => {
          element['checked'] = false;
          element['active'] = !element.archive_ind;
        });
        Object.assign(this.totalMetrics, this.metricList);
        this.enabledValue = this.metricList;
        this.metricList = new MatTableDataSource(this.metricList);
        this.metricList._filter.next(this.search);
        if (this.search.trim() == '') {
          this.metricCount = [`${this.metricList.filteredData.length} Entries`, ''];
        }
        else {
          this.metricCount = [`${this.metricList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
        }
        this.loadVal--;
      }
    }, (error1)=>{
      this.loadVal--;
      this.commonService.handleError(error1);
    });
  }

  getSelectedOption(selected):void {
    this.selectedMetrics = [];
    this.checkedCount = 0;
    this.editSelectedAdmin = false;
    this.checkedAdmin = selected.name;
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.metricIdList = [];
    this.metricList.filteredData.forEach((element)=> {
      if(element.checked === true) {
        this.checkedCount++;
        this.selectedMetrics.push(element.name);
        this.totalMetrics.forEach((item) => {
          if(item.name === element.name) {
            this.metricIdList.push({'_id': item._id});
          }
        });
      }
    });
    if(this.checkedCount > 0) {
      this.editSelectedAdmin = true;
    }
  }

  getEnableDisableStatus(value) :void{
    this.selectedOption = value;
    this.getMetrics();
  }

  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.metricList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.metricCount = [`${this.metricList.filteredData.length} Entries`, ''];
    }
    else {
      this.metricCount = [`${this.metricList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }

  setStatus(value): void {
    this.loadVal++;
    this.selectedOption = value;
    const params = {disable: !(value ==='Enabled')};
    this.metricService.updateMetricsStatus(params, this.metricIdList)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.getMetrics();
          this.editSelectedAdmin = false;
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  addNewMetric(add): void {
    if(add) {
      const editData = {'orgTypeName': this.orgTypeName};
      const dialogRef = this.dialog.open(MetricPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.METRIC_ADDED_SUCCESS);
          this.getMetrics();
        }
      });
    }
  }
  getSelectedOrder(value):void{
    this.loadVal++;
    const params = { collection_name: 'metrics', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.METRIC_ORDER_SAVE_SUCCESS);
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getClickedItem(value):void{
    if(value) {
      const editData = {'value': value,'orgTypeName': this.orgTypeName};
      const dialogRef = this.dialog.open(MetricPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.METRIC_EDITED_SUCCESS);
          this.getMetrics();
        }
      });
    }
  }
}
