/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { CommonService } from '../common.service';
import { DeliverableService } from '../deliverable.service';

@Injectable()
export class DeliverableMockService implements DeliverableService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  getDeliverableStatus(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverablestatus', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/deliverables_status.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getStatusForFilter(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverablestatus/filter', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/deliverables_status.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getDeliverableStatusForFilter(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverablestatus/deliverable/filter', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/deliverables_status.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverablesKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/deliverables/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get('/assets/json/demo/deliverables_keywords.json', this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.filter((option) => option.toLowerCase().includes(searchText)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverableAndProjectKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/search/keywords', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get('/assets/json/demo/deliverables_keywords.json', this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.filter((option) => option.toLowerCase().includes(searchText)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getClient(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    this.commonService.log('GET:', '/search/client', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get('/assets/json/demo/deliverables_keywords.json', this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.filter((option) => option.toLowerCase().includes(searchText)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverables(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/deliverables/list', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/deliverables.json', this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          const result = res.filter((x) => parameters.workflow_status.includes(x.workflow_status));
          return {
            deliverables: result.slice(parameters.page_number * parameters.page_size, parameters.page_number * parameters.page_size + parameters.page_size),
            total: result.length
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverable(deliverableId: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/deliverables.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.find((x) => x._id === parseInt(deliverableId, 10)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getRelatedDeliverables(parameters: any): Observable<any> {
    this.commonService.log('GET:', '/deliverables/search/related', 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/deliverables.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  saveDraft(deliverable, file): Observable<any> {
    this.commonService.log('POST:', '/deliverables/draft', 'BODY:', deliverable, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ deliverable, file });
  }

  updateDraft(deliverableId, deliverable, file): Observable<any> {
    this.commonService.log('PUT:', `/deliverables/draft/${deliverableId}`, 'BODY:', deliverable, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ deliverableId, deliverable, file });
  }

  save(deliverable, file, orgTypeName = null): Observable<any> {
    this.commonService.log('POST:', '/deliverables', 'BODY:', deliverable, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ deliverable, file, orgTypeName });
  }

  update(deliverableId, deliverable, file): Observable<any> {
    this.commonService.log('POST:', `/deliverables/${deliverableId}`, 'BODY:', deliverable, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ deliverableId, deliverable, file });
  }


  getReviewers(deliverableId: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/reviewer/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/users.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return {
            result: res.filter((user) => {
              return this.commonService.hasAnyLeadRole(user);
            })
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  reviewRequest(deliverableId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/request/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId, formData });
  }

  publishRequest(deliverableId: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/publish/request/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId });
  }

  approve(deliverableId: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/approve/${deliverableId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId });
  }

  approveRework(deliverableId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/rework/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId });
  }

  approveReassign(deliverableId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/deliverables/review/reassign/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId, formData });
  }

  publishRework(deliverableId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/deliverables/publish/rework/${deliverableId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId, formData });
  }

  publishApprove(deliverableId: any): Observable<any> {
    this.commonService.log('POST:', `/deliverables/publish/approve/${deliverableId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId });
  }

  getTypeAheadData(queryParams): Observable<any> {
    this.commonService.log('GET:', '/search/suggest', 'OPTIONS:', this.commonService.httpOptions(queryParams));
    return this.http.get('/assets/json/demo/search_suggestions.json', this.commonService.httpOptions(queryParams))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getTypeAheadDeliverableData(queryParams): Observable<any> {
    this.commonService.log('GET:', '/search/deliverable/suggest', 'OPTIONS:', this.commonService.httpOptions(queryParams));
    return this.http.get('/assets/json/demo/search_suggestions.json', this.commonService.httpOptions(queryParams))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getFilter(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/search/list', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/search_result.json', this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return {
            result: res.slice(parameters.page_number * parameters.page_size, parameters.page_number * parameters.page_size + parameters.page_size),
            total: res.length
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  sendReminder(deliverableId: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/review/sendreminder/${deliverableId}`, 'OPTIONS:', this.commonService.httpOptions());
    return of({ deliverableId });
  }

  triggerError(errorCode: any): Observable<any> {
    this.commonService.log('GET:', `/deliverables/${errorCode}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/deliverables.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getClientUser(searchText: any): Observable<any> {
    searchText = searchText.toLowerCase().trim();
    this.commonService.log('GET:', `/deliverables/client/${searchText}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/deliverables.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getSettings(): Observable<any> {
    this.commonService.log('GET:', '/reports/settings', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/reporting_settings.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  deleteSetting(settingId: any): Observable<any> {
    this.commonService.log('DELETE:', `/reports/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return of(null);
  }

  getSetting(settingId: any): Observable<any> {
    this.commonService.log('GET:', `/reports/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/reporting_settings.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.find((x) => x._id === parseInt(settingId, 10)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  saveSetting(formData: any): Observable<any> {
    this.commonService.log('POST:', '/reports/settings', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ formData });
  }

  updateSetting(settingId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/reports/settings/${settingId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ formData });
  }
}

