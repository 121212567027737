<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm" class="add-dialog" role="dialog" aria-labelledby="titleStatus">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('status').invalid }">
            <p id="titleStatus">Deliverable Status</p>
            <input id="status" type="text" formControlName="status" class="form-control" placeholder="Deliverable Status" aria-label="Deliverable Status"  maxlength="128" required (keyup)="changeStatusValue(showForm.get('status').value)"/>
            <span id="statusLength" class="hint point">{{inputStatusName ? inputStatusName.length : 0}}/128</span>
        </div>
        <mat-checkbox id="indicatesCompletion" [(ngModel)]="isComplete"  [ngModelOptions]="{standalone: true}" color="accent">Indicates Completion</mat-checkbox>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
    </div>
</form>