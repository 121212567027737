/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { MasterService } from '../master.service';

@Injectable()
export class MasterAPIService implements MasterService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getDeliverableTypes(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverabletypes', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/deliverabletypes`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getProjects(params = null): Observable<any> {
    this.commonService.log('GET:', '/projects', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/projects`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getInitiatives(params = null): Observable<any> {
    this.commonService.log('GET:', '/initiatives', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/initiatives`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/list', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/quarter/list`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getParnterQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/parnter', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/quarter/parnter`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getGroups(params = null): Observable<any> {
    this.commonService.log('GET:', '/groups/common/list', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/groups/common/list`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }


  getIndustries(): Observable<any> {
    this.commonService.log('GET:', '/industries', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/industries`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getPartners(): Observable<any> {
    this.commonService.log('GET:', '/partners', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/partners`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getImage(id, type = 'deliverable', isForList = false, thumbnailSize = {}): Observable<any> {
    const param = { id: id, type: type, isForList: isForList, thumbnailSize: JSON.stringify(thumbnailSize) };
    this.commonService.log('GET:', '/images', 'OPTIONS:', this.commonService.httpOptions(param));
    return this.http.get(`${environment.API_URL}/images`, { ...this.commonService.httpOptions(param, null), responseType: 'blob', observe: 'response' })
      .pipe(
        map(this.commonService.extractData),
        map((res) => {
          return res['body'];
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error, false);
        })
      );
  }

  getProfileImage(id: string): Observable<any> {
    this.commonService.log('GET:', `https://graph.microsoft.com/v1.0/users/${id}/photo/$value`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`https://graph.microsoft.com/v1.0/users/${id}/photo/$value`, { ...this.commonService.httpOptions(null, null), responseType: 'blob', observe: 'response' })
      .pipe(
        map(this.commonService.extractData),
        map((res) => {
          return res['body'];
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error, false);
        })
      );
  }
  getLocations(params = null): Observable<any> {
    this.commonService.log('GET:', '/locations', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/locations`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  saveOrder(params): Observable<any> {
    return this.http.patch(`${environment.API_URL}/orders/save`, {}, this.commonService.httpOptions(params));
  }
  getWorkstreams(): Observable<any> {
    return this.http.get(`${environment.API_URL}/workstreams`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getForceList(params = null): Observable<any> {
    this.commonService.log('GET:', '/forces/list', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/forces/list`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getNestedThemesList(params = null): Observable<any> {
    this.commonService.log('GET:', '/nestedThemes/list', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/nestedThemes/list`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}

