/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { PartnerService } from '@app/services/partner.service';
@Injectable({
  providedIn: 'root'
})
export class PartnerApiService implements PartnerService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getPartners(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/partners`, this.commonService.httpOptions(params));
  }
  addPartner(reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/partners`, reqbody, this.commonService.httpOptions());
  }

  editPartners(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/partners/update/${params}`, reqBody, this.commonService.httpOptions());
  }
  changePartenrsStatus(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/partners/archiveind`, reqBody, this.commonService.httpOptions(params));
  }
  getParnterTypes(): Observable<any> {
    this.commonService.log('GET:', '/partners/parnterTypes', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/partners/parnterTypes`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getParnterRelatedData(): Observable<any> {
    this.commonService.log('GET:', '/partners/partnerRelatedData', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/partners/partnerRelatedData`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}
