<div fxLayout="column" class="list-item-card" tabindex="0" (keydown.enter)="onDeliverableClick()">
  <div fxLayout="row" fxLayoutGap="20px" class="name-row">
    <a fxFlex fxLayout="row" fxLayoutGap="20px" (click)="onDeliverableClick()">
      <img [src]="imgURL" alt="" class="list-item-image">
      <div fxFlex fxLayout="column">
        <h2 name="deliverableName" class="white-space-pre">{{deliverable.name}}</h2>
        <p name="deliverableDesc">{{deliverable.short_description}}</p>
        <h3 name="deliverableType">{{deliverable.deliverable_type?deliverable.deliverable_type.name:''}}</h3>
      </div>
    </a>
    <div fxLayout="row" class="action-row" *ngIf="isFromSearch">
      <div fxLayout="row" fxLayoutGap="20px" class="action-row-inner">
        <div name="searchWorkflowStatus" class="tag">{{deliverable.workflow_status}}</div>
      </div>
    </div>
    <div fxLayout="row" class="action-row" *ngIf="action">
      <div fxLayout="row" class="action-row-inner">
        <div name="actionWorkflowStatus" class="tag">{{deliverable.workflow_status}}</div>
        <button name="deliverableMenuIcon" mat-icon-button matRipple [matRippleColor]="'#a100ff'" [matRippleCentered]="true" 
        [matRippleUnbounded]="false" [matRippleRadius]="18" class="menu-btn" [matMenuTriggerFor]="deliverableMenu"
          aria-label="workflow status">
          <span class="icon ic-menu"></span>
        </button>
        <mat-menu #deliverableMenu="matMenu" class="live-menu">
          <button name="editDeliverable" mat-menu-item [routerLink]="['/deliverables', deliverable._id]">Edit
            Deliverable</button>

          <button name="requestReview" mat-menu-item
            *ngIf="canRequestReview(deliverable)  && !isLiquidStudio && !isIncubation"
            (click)="onReviewRequest(deliverable)">Request Review</button>
          <button name="requestPublish" mat-menu-item *ngIf="canRequestPublish(deliverable)"
            (click)="onPublishRequest(deliverable)">Request Publish</button>

          <button name="reassign" mat-menu-item *ngIf="canApprove(deliverable) && !isLiquidStudio && !isIncubation"
            (click)="onReassign(deliverable)">Reassign</button>
          <button name="approve" mat-menu-item *ngIf="canApprove(deliverable) && !isLiquidStudio && !isIncubation"
            (click)="onApprove(deliverable)">Approve</button>
          <button name="sendReminder" mat-menu-item
            *ngIf="canSendReminder(deliverable) && !isLiquidStudio && !isIncubation"
            (click)="onSendReminder(deliverable._id)">Send Reminder</button>
          <button name="rework" mat-menu-item *ngIf="canPublish(deliverable)"
            (click)="onRework(deliverable)">Reassign</button>
          <button name="approvePublish" mat-menu-item *ngIf="canPublish(deliverable)"
            (click)="onApprovePublish(deliverable)">Approve Publish</button>
          <button name="recommend" mat-menu-item
            (click)="services.deliverableCommonService.sendRecommend(deliverable)">Recommend</button>
        </mat-menu>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <a fxLayout="row" fxLayoutGap="10px" class="data-row" (click)="onDeliverableClick()">
    <div fxLayout="column" fxFlex="15" class="data-group">
      <label name="lblOwner">Owner</label>
      <span name="owner_value">{{deliverable.owner?deliverable.owner.display_name:'n/a'}}</span>
    </div>
    <div fxLayout="column" fxFlex="15" class="data-group">
      <label name="lblOrganization">organization</label>
      <span
        name="organization_value">{{deliverable.organization_type.name?deliverable.organization_type.name:'n/a'}}</span>
    </div>
    <div fxLayout="column" fxFlex="15" class="data-group">
      <label name="lblclient">client</label>
      <span name="client_value">{{clientValue?clientValue:'n/a'}}</span>
    </div>
    <div fxLayout="column" fxFlex="20" class="data-group" *ngIf="!isLiquidStudio">
      <label name="lblPrimaryGroup">Primary Group</label>
      <span name="primaryGroup_value">{{deliverable.primary_group?deliverable.primary_group.name:'n/a'}}</span>
    </div>
    <div fxLayout="column" fxFlex="20" class="data-group" *ngIf="!isLiquidStudio && !isIncubation">
      <label name="lblPrimaryWorkstream">Primary Workstream</label>
      <span
        name="primaryWorkstream_value">{{deliverable.primary_workstream?deliverable.primary_workstream.name:'n/a'}}</span>
    </div>
    <div fxLayout="column" fxFlex="20" class="data-group">
      <label name="lblPrimaryLocation">Primary Location</label>
      <span name="primaryLocation_value">{{deliverable.primary_location?deliverable.primary_location.name:'n/a'}}</span>
    </div>
    <div fxLayout="column" fxFlex="8" class="data-group">
      <label name="lblLastEdited">Last Edited</label>
      <span name="lastEdited_hasValue" *ngIf="deliverable.last_updated_date">{{deliverable.last_updated_date | date
        }}</span>
      <span name="lastEdited_nullvalue" *ngIf="!deliverable.last_updated_date">n/a</span>
    </div>
  </a>
</div>