/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, Input, EventEmitter, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Services } from '@app/services/services';
import { WorkflowStatus, Messages } from '@app/models';
import { AdvanceStageDialog, ProjectReviewRequestDialog } from '@app/projects/project-dialog.components';
import { ProjectApproveDialog, ProjectReassignDialog } from '@app/projects/project-reassign-dialog.components';

@Component({
  selector: 'app-project-list-view',
  outputs: ['update', 'onClick'],
  templateUrl: 'project-list-view.component.html',
  // styleUrls: ['./project-list-view.component.scss'],
})
export class ProjectListViewComponent implements OnInit {
  @Input('project') project: any;
  @Input('action') action: boolean;
  @Input('isFromSearch') isFromSearch: boolean;
  update: EventEmitter<any> = new EventEmitter<any>();
  onClick: EventEmitter<any> = new EventEmitter<any>();
  data: any = {
    advanceStage: null,
    project: null
  };
  imgURL;
  constructor(
    public dialog: MatDialog,
    public services: Services
  ) {
    this.imgURL = this.services.projectsCommonService.defaultProjectImage;
  }

  ngOnInit(): void {
    if (this.project && this.project.image_link) {
      this.services.projectsEditCommonService.getProjectImage(this, this.project._id);
    }
  }

  get WorkflowStatus(): any { return WorkflowStatus; }

  onProjectClick() {
    this.onClick.emit(this.project);
  }


  onAdvanceStage(project): void {
    this.services.projectsService.checkAdvanceStage(project._id)
      .subscribe((response) => {
        if (response) {
          this.data.advanceStage = response.result;
          this.data.project = project;
          this.services.projectsCommonService.openDialog(this, AdvanceStageDialog, this.data, '624px', Messages.ADVANCE_STAGE_SUCCESS, 'update_stage');
        }
      });
  }

  canRequestReview(project: any) {
    if (this.services.projectsCommonService.canEditProject(project)) {
      if (project.workflow_status == WorkflowStatus.SAVED) {
        return true;
      } else if (project.workflow_status == WorkflowStatus.APPROVED) {
        if (project.workflow && new Date(project.last_updated_date).getTime() > new Date(project.workflow.updated).getTime()) {
          return true;
        } else return false;
      } else return false;
    } else return false;
  }

  onReviewRequest(project): void {
    this.services.projectsCommonService.openDialog(this, ProjectReviewRequestDialog, project, '538px', Messages.PROJECT_REVIEW_REQUESTED_SUCCESS, 'review_request');
  }
  onReassign(project): void {
    this.services.projectsCommonService.openDialog(this, ProjectReassignDialog, project, '538px', Messages.PROJECT_REASSIGN_SUCCESS, 'reassign');
  }
  onApprove(project): void {
    this.services.projectsCommonService.openDialog(this, ProjectApproveDialog, project, '478px', Messages.PROJECT_APPROVE_SUCCESS, 'approve');
  }
  canApprove(project: any) {
    if (project.workflow_status == WorkflowStatus.REVIEW_REQUESTED && this.services.commonService.hasAnyLeadRole() &&
      this.services.projectsCommonService.isAssigned(project)) {
      return true;
    } else return false;
  }

  onSendReminder(projectId): void {
    this.services.projectsService.sendReminder(projectId)
      .subscribe((res) => {
        if (res) {
          this.services.commonService.openSnackBar('success', Messages.REMINDER_SUCCESS);
        }
      });
  }
  canSendReminder(project) {
    if (project.workflow_status == WorkflowStatus.REVIEW_REQUESTED &&
      !this.services.projectsCommonService.isAssigned(project))
      return true;
    else return false;
  }
}
