/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { CommonService } from '../common.service';
import { KPIService } from '../kpi.service';

@Injectable()
export class KPIMockService implements KPIService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getDeliverables(formData: any): Observable<any> {
    this.commonService.log('POST:', '/search/kpi', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/kpi.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          const result = res;
          return {
            deliverables: result.deliverables,
            total: result.total
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getStory(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/search/stories', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/deliverables.json', this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          const result = res;
          return {
            deliverables: result.deliverables,
            total: result.total
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}
