/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';

export class WorkstreamService {
  getWorkStreams(params): Observable<any> {
    return of(params);
  }

  addWorkstream(reqbody): Observable<any> {
    return of(reqbody);
  }

  editWorkstream(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  changeWorkstreamStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}