/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Services } from '@app/services/services';
import { CommonEchartsComponent } from '../common-echarts/common-echarts.component';
@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss']
})
export class KPIComponent implements OnInit {
  @Input('filterData') filterData: any;
  @Input('isInMetricsTab') isInMetricsTab: any;
  loading = 0;
  publicationList = [];
  isShowPPT = true;
  showFilter: any = false;
  deliverables: any;
  metrics: any;
  result: any = {
    'grand_total': 0,
    'metric': []
  }

  @ViewChild('echart') echartsComponent: CommonEchartsComponent;
  constructor(public services: Services) { }

  ngOnInit(): void {
    this.services.commonService.showHeader.emit(true);
    this._getDeliverables();
    this.services.deliverableCommonService.isNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (this.isInMetricsTab && isNeedToRefresh) {
        this._getDeliverables();
      }
    });
  }

  _getDeliverables() {
    this.loading++;
    setTimeout(() => {
      const filter = this.services.deliverableCommonService.getFilterData(this.filterData);
      this.services.kpiService.getDeliverables({ filter: filter })
        .subscribe((response) => {
          this.loading--;
          if (response && response.deliverables) {
            this.deliverables = response;
            this._getMetricTable(this.deliverables);
          }
        });
    }, 1);
  }

  _getMetricTable(deliverables) {
    this.result.metric = [];
    const metrics = [];
    let detail = [];
    let qtrs = [];
    // grand_total
    this.result.grand_total = deliverables.total;
    if (deliverables.deliverables && deliverables.deliverables.length > 0) {
      const startTime = Date.now();
      // metric types
      const metric_types = new Set(deliverables.deliverables.map((o) => o.deliverable_type.metric.name));
      for (const item of metric_types) {
        // eslint-disable-next-line no-irregular-whitespace
        const metric_type_del = deliverables.deliverables.filter((o) => { return o.deliverable_type.metric.name == item; });
        // deliverable_types
        const deliverable_types = new Set(metric_type_del.map((o) => o.deliverable_type.name));
        for (const del of deliverable_types) {
          // eslint-disable-next-line no-irregular-whitespace
          const deliverable_type_name_del = metric_type_del.filter((o) => { return o.deliverable_type.name == del; });
          const eac_qrtr_year = new Set(deliverable_type_name_del.map((o) => Number(o.eac_qrtr_year)));
          for (const eac_year of eac_qrtr_year) {
            const del_same_year = deliverable_type_name_del.filter((o) => { return o.eac_qrtr_year == eac_year; });
            const eac_qrtr_qtr = new Set(del_same_year.map((o) => Number(o.eac_qrtr_qtr)));
            for (const eac_qtr of eac_qrtr_qtr) {
              const eac_qrtr = { 'year': eac_year, 'qtr': eac_qtr };
              const del_same_qtr = del_same_year.filter((o) => { return o.eac_qrtr_qtr == eac_qtr; });
              qtrs.push({ 'eac_qrtr': eac_qrtr, 'total': del_same_qtr.length });
            }
          }
          detail.push({ 'deliverable_type_name': del, 'count': deliverable_type_name_del.length, 'qtrs': qtrs });
          qtrs = [];
        }
        metrics.push({ 'name': item, 'count': metric_type_del.length, 'detail': detail });
        detail = [];
      }
      this.result.metric = metrics;
      const duration = Date.now() - startTime;
      this.services.commonService.log('Monitor for Metrics data processing ===>', duration);
    }
    this.echartsComponent.showBarForDeliverableType(this.result);
    // result
    return this.result;
  }
}
