/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserService, CommonService } from '@app/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { LocationsService } from '@app/services/location.service';
import { Messages } from '@app/models';
@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})


export class AddLocationComponent implements OnInit {
  loading= 0;
  showForm: UntypedFormGroup;
  submitted = false;
   loadTeam = 0;
  input: string;
  selectedId: string;
  locationName = '';
  teamMembersChanged: Subject<string> = new Subject<string>();
  options: any = {
    users: []
  }
  public loadVal =0;
  public inputLocationName: any = '';
  locationArray:any = [];
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AddLocationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private commonService: CommonService,
    private locationsService: LocationsService
  ) { }

  ngOnInit(): void {
    if(this.data.value){
      this.locationName = this.data.value.name;
      this.inputLocationName = this.locationName;
      if(this.data.value.location_lead.length > 0) {
        for(const i of this.data.value.location_lead) {
          const item = {'display_name': i.display_name, 'email_id': i.email_id, 'eso_id': i.eso_id, 'job_title': i.job_title};
          this.locationArray.push(item);
        }
      }
      this.locationArray.sort(this.ArraySort);
    }
    this.showForm = this.formBuilder.group({
      locationName: [{ value: this.locationName, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
      locationLead: [{ value: this.input, disabled: false}, Validators.required]
    });
    this.teamMembersChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['users'] = [];
          this._getTeamMembers(filterValue);
        }
      });
  }

  ArraySort(a, b) {
    if (a['display_name'] == b['display_name']) {
      return 0;
    } else if (a['display_name'] > b['display_name']) {
      return 1;
    } else {
      return - 1;
    }
  }

  _getTeamMembers(searchText: any): void {
    this.loadTeam++;
    this.userService.getUsers(searchText)
      .subscribe((response) => {
        this.loadTeam--;
        if (response && response.result) {
          this.options.users = response.result;
        }
      });
  }
  onChangeUsers(event): void {
    this.selectedId = '';
    this.input = '';
    this.options['users'] = [];
    this.teamMembersChanged.next(event.target.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  addNewData(): void {
    this.submitted = true;
    if (this._isValid()){
      this.loadVal++;
      const locationLeadArray = [];
      if(this.data.value){
        if (this.locationArray.length > 0){
          for(const i of this.locationArray){
            const item = {'_id': i.eso_id};
            locationLeadArray.push(item);
          }
        }
        const body ={'name': this.showForm.get('locationName').value.trim(), 'location_lead': locationLeadArray, 'orgTypeName': this.data.orgTypeName};
        this.locationsService.editLocation(this.data.value._id, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{
        if (this.locationArray.length > 0){
          for(const i of this.locationArray){
            const item = {'_id': i.eso_id};
            locationLeadArray.push(item);
          }
        }
        const body ={'name': this.showForm.get('locationName').value.trim(), 'location_lead': locationLeadArray, 'orgTypeName': this.data.orgTypeName};
        this.locationsService.addLocation(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }
  selectedMultipleValue(field: string, event): void {
    const input = document.getElementById(field);
    if (input['value'].trim() != '') {
      const index = this.commonService.objectIndexOf(this.locationArray, event.option.value, 'eso_id');
      if (index < 0) {
        this.locationArray.push(event.option.value);
      }
    }
    setTimeout(() => {
      input['value'] = '';
      input.blur();
      this.options['users'] = [];
    }, 0);
    this.showForm.get('locationLead').setValue(this.locationArray);
    this.showForm.get('locationLead').markAsDirty();
  }

  onRemoveMultipleSelectValue(field, value: any): void {
    const input = document.getElementById(field);
    const index = this.commonService.objectIndexOf(this.locationArray, value, 'eso_id');
    if (index >= 0) {
      this.locationArray.splice(index, 1);
    }
    setTimeout(() => {
      input['value'] = '';
      input.blur();
      this.options['users'] = [];
    }, 0);
    this.showForm.get('locationLead').setValue(this.locationArray);
    this.showForm.get('locationLead').markAsDirty();
  }
  _isValid(): boolean {
    if(this.data.value){
      if (this.showForm.get('locationName').value.trim() && this.locationArray.length > 0) {
        return true;
      }
    } else {
      if (this.showForm.valid && this.locationArray.length > 0) {
        return true;
      }
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  changeLocationValue(value){
    this.inputLocationName = value;
  }
  get Messages(): any { return Messages; }
}
