<div fxFlex fxLayout="column" class="load-setting-dialog">
    <div fxLayout="column" class="dialog-header">
        <h2 id="h_saveSettings" class="dialog-title">Save Report Settings</h2>
    </div>
    <div fxLayout="column" class="dialog-content">
        <p id="saveSettingsText">Save your settings for quick access to the filters and table columns you use most often. Please name your report for your own reference later. Your saved reports are only visible to you.</p>
        <div class="form-group">
            <label id="lblReportName">Report Name</label>
            <input id="reportName" type="text" class="form-control" placeholder="e.g. My Favorite Report" [(ngModel)]="reportName" aria-label="Report Name"/>
        </div>
    </div>
    
    <div fxLayout="row" fxLayoutGap="10px" class="dialog-actions">
        <div fxFlex></div>
        <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
        <button id="saveReport" mat-flat-button type="button" color="primary" (click)="onSave()" [disabled]="!reportName">Save Report</button>
    </div>
</div>