/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DeliverableTypePopupComponent } from '@app/dialog/deliverable-type-popup/deliverable-type-popup.component';
import { Messages } from '@app/models';
import { CommonService } from '@app/services';
import { DeliverableTypeService } from '@app/services/deliverable-type.service';
import { MasterService } from '@app/services/master.service';
import { MetricService } from '@app/services/metric.service';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-deliverable-type-view',
  templateUrl: './deliverable-type-view.component.html',
  // styleUrls: ['./deliverable-type-view.component.scss']
})
export class DeliverableTypeViewComponent implements OnInit {
  public deliverableTypeList;
  public columns: string[] = ['checked', 'name', 'isPublishable', 'isIDFReq', 'isLinkReq'];
  public headersData = ['', 'Deliverable Type', 'Publishable', 'IDF Number Req.', 'Links Req.'];
  public selectedOption = 'All';
  public checkedCount = 0;
  public editSelectedAdmin = false;
  public selectedDeliverableTypes: string[];
  public search = '';
  public checkedAdmin: string;
  public placeHolder;
  public delTypeIdList = [];
  public delTypeIdCount = [];
  public totalDelType = [];
  public metricList =[];
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public tableOrder = 'asc';
  public loadVal =0;
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private deliverableTypeService: DeliverableTypeService,
    private masterService: MasterService,
    private metricService: MetricService
  ) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.getDelTypes();
    this.getMetrics();
  }

  getDelTypes(): void {
    this.loadVal++;
    this.deliverableTypeList = [];
    let params = {};
    if(this.selectedOption === 'All') {
      params = {active: 'All'};
    } else if (this.selectedOption === 'Enabled'){
      params = {active: true};
    } else if (this.selectedOption === 'Disabled') {
      params = {active: false};
    }
    params['orgTypeName'] = this.orgTypeName;
    this.deliverableTypeService.getDeliverableType(params).subscribe((response) => {
      if (response && response.result) {
        response.order==1?this.tableOrder ='asc':this.tableOrder ='desc';
        this.deliverableTypeList = response.result;
        this.deliverableTypeList.forEach((element) => {
          element['checked'] = false;
          element['active'] = !element.archive_ind;
          element['isPublishable'] = element.isPublish?'Yes':'No';
          element['isLinkReq'] = element.link_required?'Yes':'No';
          element['isIDFReq'] = element.idf_number_required?'Yes':'No';
        });
        Object.assign(this.totalDelType, this.deliverableTypeList);
        this.enabledValue = this.deliverableTypeList;
        this.deliverableTypeList = new MatTableDataSource(this.deliverableTypeList);
        this.deliverableTypeList._filter.next(this.search);
        if (this.search.trim() == '') {
          this.delTypeIdCount = [`${this.deliverableTypeList.filteredData.length} Entries`, ''];
        }
        else {
          this.delTypeIdCount = [`${this.deliverableTypeList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
        }
        this.loadVal--;
      }
    }, (error1)=>{
      this.loadVal--;
      this.commonService.handleError(error1);
    });
  }

  getSelectedOption(selected):void {
    this.selectedDeliverableTypes = [];
    this.checkedCount = 0;
    this.editSelectedAdmin = false;
    this.checkedAdmin = selected.name;
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.delTypeIdList = [];
    this.deliverableTypeList.filteredData.forEach((element)=> {
      if(element.checked === true) {
        this.checkedCount++;
        this.selectedDeliverableTypes.push(element.name);
        this.totalDelType.forEach((item) => {
          if(item.name === element.name) {
            this.delTypeIdList.push({'_id': item._id});
          }
        });
      }
    });
    if(this.checkedCount > 0) {
      this.editSelectedAdmin = true;
    }
  }

  getEnableDisableStatus(value) :void{
    this.selectedOption = value;
    this.getDelTypes();
  }

  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.deliverableTypeList._filter.next(this.search);
    if(searchInput.trim() == ''){
      this.delTypeIdCount = [`${this.deliverableTypeList.filteredData.length} Entries`, ''];
    }
    else{
      this.delTypeIdCount = [`${this.deliverableTypeList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }

  setStatus(value): void {
    this.loadVal++;
    this.selectedOption = value;
    const params = {disable: !(value ==='Enabled')};
    this.deliverableTypeService.updateDeliverabletypeStatus(params, this.delTypeIdList)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.getDelTypes();
          this.editSelectedAdmin = false;
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  addNewDelType(add): void {
    if(add) {
      const editData = {'metric': this.metricList, 'orgTypeName': this.orgTypeName};
      const dialogRef = this.dialog.open(DeliverableTypePopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.DELIVERABLE_TYPE_ADDED_SUCCESS);
          this.getDelTypes();
        }
      });
    }
  }
  getSelectedOrder(value):void{
    this.loadVal++;
    const params = { collection_name: 'deliverable_types', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.DELIVERABLE_TYPE_ORDER_SAVE_SUCCESS);
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getClickedItem(value):void{
    if(value) {
      const editData = {'metric': this.metricList, 'value': value, 'orgTypeName': this.orgTypeName};
      const dialogRef = this.dialog.open(DeliverableTypePopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.DELIVERABLE_TYPE_EDITED_SUCCCESS);
          this.getDelTypes();
        }
      });
    }
  }
  getMetrics():void{
    const params = {active: true};
    this.metricService.getMetrics(params).subscribe((response) => {
      if (response && response.result) {
        this.metricList = response.result;
      }
    });
  }
}

