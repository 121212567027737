<div fxFlex fxLayout="column" class="load-setting-dialog">
    <div fxLayout="column" class="dialog-header">
        <h2 id="h_saveSettings" class="dialog-title">Overwrite Saved Settings</h2>
    </div>
    <div fxLayout="column" class="dialog-content">
        <p id="deleteReportText">Are you sure you want to overwrite bookmark <b id="deleteName">{{data.name}}</b>?</p>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="dialog-actions">
        <div fxFlex></div>
        <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
        <button id="loadReport" mat-flat-button type="button" color="primary" (click)="onSave()">Overwrite Settings</button>
    </div>
</div>