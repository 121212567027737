/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UserService } from '@app/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { WorkstreamService } from '@app/services/workstream.service';
import { CommonService } from '@app/services';
import { Messages } from '@app/models';
@Component({
  selector: 'app-add-new-workstream',
  templateUrl: './add-new-workstream.component.html',
  // styleUrls: ['./add-new-workstream.component.scss']
})
export class AddNewWorkstreamComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  submitted = false;
  workstreamName = '';
  teamMembersChanged: Subject<string> = new Subject<string>();
  options: any = {
    users: [],
    selectedGroups: ''
  }
  loadTeam = 0;
  input: string;
  selectedId: string;
  groupLocation: string;
  public loadVal = 0;
  public inputWorkstreamName: any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AddNewWorkstreamComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private workstreamService: WorkstreamService,
  ) { }

  ngOnInit(): void {
    if (this.data.value) {
      if (this.data.value['workstream_lead']) {
        this.selectedId = this.data.value['workstream_lead']['eso_id'];
        this.input = this.data.value['workstream_lead']['display_name'];
      }
      this.workstreamName = this.data.value.name;
      this.inputWorkstreamName = this.workstreamName;
      this.options.selectedGroups = this.commonService.getSelectedValue(this.data.value.groups[0]._id, this.data.groups);
    }
    this.showForm = this.formBuilder.group({
      workstreamName: [{ value: this.workstreamName, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
      workstreamsGroup: [{ value: this.options.selectedGroups, disabled: false }, Validators.required],
      workstreamLead: [{ value: this.input, disabled: false }, Validators.required]
    });
    this.teamMembersChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['users'] = [];
          this._getTeamMembers(filterValue);
        }
      });
  }

  _getTeamMembers(searchText: any): void {
    this.loadTeam++;
    this.userService.getUsers(searchText)
      .subscribe((response) => {
        this.loadTeam--;
        if (response && response.result) {
          this.options.users = response.result;
        }
      });
  }

  onChangeUsers(event): void {
    this.selectedId = '';
    this.input = '';
    this.options['users'] = [];
    this.teamMembersChanged.next(event.target.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    if (this._isValid()) {
      this.loadVal++;
      if (this.data.value) {
        const params = this.data.value._id;
        const groupArray = [{ _id: this.options.selectedGroups }];
        const body ={'name': this.showForm.get('workstreamName').value.trim(), 'groups': groupArray,'orgTypeName': this.data.orgTypeName};
        body['workstream_lead'] = this.selectedId;

        this.workstreamService.editWorkstream(params, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1) => {
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else {
        const groupArray = [{ _id: this.options.selectedGroups }];
        const body = { 'name': this.showForm.get('workstreamName').value.trim(), 'groups': groupArray, 'orgTypeName': this.data.orgTypeName };
        body['workstream_lead'] = this.selectedId;

        this.workstreamService.addWorkstream(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1) => {
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }
  selectedValue(selected): void {
    this.selectedId = selected.option.value.eso_id;
    this.input = selected.option.value.display_name;
  }
  getWorkstreamLeadName(option) {
    const display_name = option.display_name? option.display_name : option
    return display_name;
  }
  selectedGroupValue(value): void {
    this.options.selectedGroups = value;
  }

  _isValid(): boolean {
    if (this.showForm.valid && this.selectedId !== '') {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  changeWorkstreamValue(value) {
    this.inputWorkstreamName = value;
  }
  get Messages(): any { return Messages; }
}

