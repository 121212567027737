/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { CommonService } from './common.service';

import { UserService } from './user.service';
import { UserAPIService } from './api/user-api.service';
import { UserMockService } from './mock/user-mock.service';

import { MasterService } from './master.service';
import { MasterAPIService } from './api/master-api.service';
import { MasterMockService } from './mock/master-mock.service';

import { DeliverableService } from './deliverable.service';
import { DeliverableAPIService } from './api/deliverable-api.service';
import { DeliverableMockService } from './mock/deliverable-mock.service';

import { ProjectsService } from './projects.service';
import { ProjectsAPIService } from './api/projects-api.service';
import { ProjectsMockService } from './mock/projects-mock.service';

import { StoriesService } from './stories.service';
import { StoryAPIService } from './api/story-api.service';
import { StoryMockService } from './mock/story-mock.service';

import { ReportingService } from './reporting.service';
import { ReportingAPIService } from './api/reporting-api.service';
import { ReportingMockService } from './mock/reporting-mock.service';

import { KPIService } from './kpi.service';
import { KPIAPIService } from './api/kpi-api.service';
import { KPIMockService } from './mock/kpi-mock.service';

import { AdminMockService } from './mock/admin-mock.service';
import { AdminAPIService } from './api/admin-api.service';
import { AdminService } from './admin.service';
import { GroupService } from './group.service';
import { GroupApiService } from './api/group-api.service';
import { WorkstreamService } from './workstream.service';
import { WorkstreamApiService } from './api/workstream-api.service';

import { InitiativesApiService } from './api/initiatives-api.service';
import { InitiativesService } from './initiatives.service';
import { PartnerApiService } from './api/partner-api.service';
import { PartnerService } from './partner.service';
import { MetricApiService } from './api/metric-api.service';
import { MetricService } from './metric.service';
import { StageApiService } from './api/stage-api-service';
import { StageService } from './stage.service';
import { IndustryService } from './industry.service';
import { IndustryApiService } from './api/industry-api.service';
import { StatusService } from './status.service';
import { StatusApiService } from './api/status-api.service';
import { LocationsService } from './location.service';
import { LocationApiService } from './api/location-api.service';
import { DeliverableTypeService } from './deliverable-type.service';
import { DeliverableTypeApiService } from './api/deliverable-type-api.service';

import { GroupLocationLeadApiService } from './api/group-location-lead-api.service';
import { GroupLocationLeadMockService } from './mock/group-location-lead-mock.service';
import { GroupLocationLeadService } from './group-location-lead.service';

import { AmTestMockService } from './mock/amtest-mock.service';
import { AmTestAPIService } from './api/amtest-api.service';
import { AmTestService } from './amtest.service';
import { OrganizationTypeAPIService } from './api/organization-type-api.service';
import { OrganizationTypeService } from './organization-type.service';
import { OrganizationTypeMockService } from './mock/organization-type-mock.service';

export function UserFactory(http: HttpClient, commonService: CommonService): UserService {
  if (environment.demo) {
    return new UserMockService(http, commonService);
  } else {
    return new UserAPIService(http, commonService);
  }
}

export function MasterFactory(http: HttpClient, commonService: CommonService): MasterService {
  if (environment.demo) {
    return new MasterMockService(http, commonService);
  } else {
    return new MasterAPIService(http, commonService);
  }
}

export function DeliverableFactory(http: HttpClient, commonService: CommonService): DeliverableService {
  if (environment.demo) {
    return new DeliverableMockService(http, commonService);
  } else {
    return new DeliverableAPIService(http, commonService);
  }
}

export function ProjectsFactory(http: HttpClient, commonService: CommonService): ProjectsService {
  if (environment.demo) {
    return new ProjectsMockService(http, commonService);
  } else {
    return new ProjectsAPIService(http, commonService);
  }
}

export function StoriesFactory(http: HttpClient, commonService: CommonService): StoriesService {
  if (environment.demo) {
    return new StoryMockService(http, commonService);
  } else {
    return new StoryAPIService(http, commonService);
  }
}

export function ReportingFactory(http: HttpClient, commonService: CommonService): ReportingService {
  if (environment.demo) {
    return new ReportingMockService(http, commonService);
  } else {
    return new ReportingAPIService(http, commonService);
  }
}

export function KPIFactory(http: HttpClient, commonService: CommonService): KPIService {
  if (environment.demo) {
    return new KPIMockService(http, commonService);
  } else {
    return new KPIAPIService(http, commonService);
  }
}
export function OrganizationTypeFactory(http: HttpClient, commonService: CommonService): OrganizationTypeService {
  if (environment.demo) {
    return new OrganizationTypeMockService(http, commonService);
  } else {
    return new OrganizationTypeAPIService(http, commonService);
  }
}

export function AdminFactory(http: HttpClient, commonService: CommonService): AdminService {
  if (environment.demo) {
    return new AdminMockService(http, commonService);
  } else {
    return new AdminAPIService(http, commonService);
  }
}
export function GroupFactory(http: HttpClient, commonService: CommonService): GroupService {
  // if (environment.demo) {
  //   return new AdminMockService(http, commonService);
  // } else {
  return new GroupApiService(http, commonService);
  // }
}
export function WorkstreamFactory(http: HttpClient, commonService: CommonService): WorkstreamService {
  // if (environment.demo) {
  //   return new AdminMockService(http, commonService);
  // } else {
  return new WorkstreamApiService(http, commonService);
  // }
}

export function InitiativeFactory(http: HttpClient, commonService: CommonService): InitiativesService {
  // if (environment.demo) {
  //   return new AdminMockService(http, commonService);
  // } else {
  return new InitiativesApiService(http, commonService);
  // }
}
export function PartnerFactory(http: HttpClient, commonService: CommonService): PartnerService {
  return new PartnerApiService(http, commonService);
}
export function MetricFactory(http: HttpClient, commonService: CommonService): MetricService {
  return new MetricApiService(http, commonService);
}

export function StageFactory(http: HttpClient, commonService: CommonService): StageService {
  return new StageApiService(http, commonService);
}
export function IndustryFactory(http: HttpClient, commonService: CommonService): IndustryService {
  return new IndustryApiService(http, commonService);
}
export function LocationFactory(http: HttpClient, commonService: CommonService): LocationsService {
  return new LocationApiService(http, commonService);
}
export function DeliverableTypeFactory(http: HttpClient, commonService: CommonService): DeliverableTypeService {
  return new DeliverableTypeApiService(http, commonService);
}
export function StatusFactory(http: HttpClient, commonService: CommonService): StatusService {
  return new StatusApiService(http, commonService);
}
export function GroupLocationLeadFactory(http: HttpClient, commonService: CommonService): GroupLocationLeadService {
  if (environment.demo) {
    return new GroupLocationLeadMockService(http, commonService);
  } else {
    return new GroupLocationLeadApiService(http, commonService);
  }
}
export function AmTestFactory(http: HttpClient, commonService: CommonService): AmTestService {
  if (environment.demo) {
    return new AmTestMockService(http, commonService);
  } else {
    return new AmTestAPIService(http, commonService);
  }
}
