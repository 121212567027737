/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdminService } from '@app/services/admin.service';
import { StatusService } from '@app/services/status.service';
import { Role } from '@app/models';
import { CommonService } from '@app/services';
@Component({
  selector: 'app-delete-functionality-dialog',
  templateUrl: './delete-functionality-dialog.component.html',
  // styleUrls: ['./delete-functionality-dialog.component.scss']
})
export class DeleteFunctionalityDialogComponent implements OnInit {
  loading = 0;
  submitted = false;
  deleteItems: string;
  public loadVal =0;
  constructor(
    public dialogRef: MatDialogRef<DeleteFunctionalityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private adminService: AdminService,
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    if(this.data.count < 2) {
      this.deleteItems = `${this.data.count} Item`;
    } else {
      this.deleteItems = `${this.data.count} Items`;
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  OnDelete(): void {
    this.loadVal++;
    if(this.data.status){
      const params = {disable: true};
      this.statusService.changeStatus(params, this.data.statusIdList)
        .subscribe((response) => {
          if (response) {
            this.loadVal--;
            this.dialogRef.close(true);
          }
        }, (error1)=>{
          this.loadVal--;
          this.commonService.handleError(error1);
        });
    }else{
      const body = { eso_id: this.data.esoId };
      const params = {role: Role.ADMIN, delete: true, active: false };
      this.adminService.editAdmin(body, params)
        .subscribe((response) => {
          if (response) {
            this.loadVal--;
            this.dialogRef.close(true);
          }
        }, (error1)=>{
          this.loadVal--;
          this.commonService.handleError(error1);
        });
    }
  }
}
