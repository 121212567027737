/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { CommonService } from '../common.service';
import { OrganizationTypeService } from '../organization-type.service';

@Injectable()
export class OrganizationTypeMockService implements OrganizationTypeService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getOrganizationTypes(params = null): Observable<any> {
    this.commonService.log('GET:', '/organizationType', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/organization_type.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}
