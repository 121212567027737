/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { AdminService } from '../admin.service';
import { CommonService } from '../common.service';

@Injectable()
export class AdminMockService implements AdminService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getUsersByRole(role): Observable<any> {
    return of(role);
  }

  addAdmin(params): Observable<any> {
    return of(params);
  }

  editAdmin(reqBody, params): Observable<any> {
    return of(reqBody, params);
  }
}

