<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form fxLayout="column" [formGroup]="showForm" class="add-dialog" role="dialog" aria-labelledby="titleIndustryName">
  <div fxLayout="column" class="dialog-content">
      <div class="form-group"  [ngClass]="{'error': submitted && showForm.get('industryName').invalid }">
          <p id="titleIndustryName">Industry Name</p>
          <input id="industryName" type="text" formControlName="industryName" class="form-control" placeholder="Industry Name" required aria-label="Industry Name" maxlength="128" (keyup)="changeIndustryValue(showForm.get('industryName').value)"/>
          <span id="industryNameLength" class="hint point">{{inputIndustryName ? inputIndustryName.length : 0}}/128</span> 
        </div>
  </div>
  <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
      <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
      <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
  </div>
</form>

