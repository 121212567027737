<form fxLayout="column" [formGroup]="reassignForm" (ngSubmit)="onReassign()" class="deliverable-dialog">
    <div fxHide [fxShow]="step == 1" fxLayout="column" role="dialog" aria-labelledby="reassignStep1">
        <label id="step_1" class="step-title">STEP 1 of {{reassignForm.get('reason').value == 'CHANGE'? 2 :3}}</label>
        <h2 id="reassignStep1" class="dialog-title">Select Reason for Reassignment</h2>
        <div fxLayout="column" class="dialog-content">
            <div class="form-group">
                <mat-radio-group color="primary" fxLayout="column" formControlName="reason">
                    <mat-radio-button id="change" value="CHANGE">Changes required for approval</mat-radio-button>
                    <mat-radio-button id="request" value="REQUEST">Request additional review</mat-radio-button>
                </mat-radio-group>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button id="cancel1" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
            <button id="next1" mat-flat-button type="button" color="primary" (click)="onNext()" cdkFocusInitial>Next</button>
        </div>
    </div>
    <div fxHide [fxShow]="step == 2" fxLayout="column" aria-labelledby="reassignStep2">
        <label id="step_2" class="step-title">STEP 2 OF {{reassignForm.get('reason').value == 'CHANGE'? 2 :3}}</label>
        <h2 id="reassignStep2" class="dialog-title">SELECT RECIPIENT</h2>
        <div fxLayout="column" class="dialog-content">
            <div class="form-group" [ngClass]="{'error': submitted && !reassignForm.get('recipient').value }">
                <label id="lblRecipient">Recipient</label>
                <mat-select id="drpRecipient" formControlName="recipient" aria-label="Recipient">
                    <mat-option id="optRecipient{{i}}" *ngFor="let option of reviewers;let i=index" [value]="option">{{option.display_name}}</mat-option>
                </mat-select>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button id="cancel2" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
            <button id="next2" mat-flat-button type="button" color="primary" (click)="onNext()" cdkFocusInitial>Next</button>
        </div>
    </div>
    <div fxHide [fxShow]="step == 3" fxLayout="column" role="dailog" aria-labelledby="reassignStep3">
        <label id="step_3" class="step-title">STEP {{reassignForm.get('reason').value == 'CHANGE'? 2 :3}} OF {{reassignForm.get('reason').value == 'CHANGE'? 2 :3}}</label>
        <h2 id="reassignStep3" class="dialog-title">add notes or comments</h2>
        <div fxLayout="column" class="dialog-content">
            <div class="form-group" [ngClass]="{'error': submitted && reassignForm.get('comments').invalid }" style="position: relative;">
                <label id="lblNotesOrComments">Notes or Comments</label>
                <textarea id="notesOrComments" formControlName="comments" class="form-control" aria-label="notes or comments" (keyup)="changeCommentValue(reassignForm.get('comments').value)" maxlength="3000"></textarea>
                <span id="CommentsLength" class="hint">{{inputComments ? inputComments.length : 0}}/3000</span>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button id="cancel3" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
            <button id="sendReassign" mat-flat-button type="submit" color="primary" cdkFocusInitial>Send Reassignment</button>
        </div>
    </div>

</form>