/* eslint-disable complexity */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Messages } from '@app/models';
import { AuthenticationService } from './api/authentication.service';
@Injectable()

export class ProjectsCommonService {
  defaultProjectImage: any = 'assets/images/TI-Banner.png';
  defaultProfileImage: any = 'assets/images/avatar.png';

  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog,
  ) {

  }

  getProfileImage(self, userId: any): string {
    if (userId) {
      if (self.profileImages[userId] && self.profileImages[userId] != 'loading') {
        return self.profileImages[userId];
      } else if (self.profileImages[userId] != 'loading') {
        self.profileImages[userId] = 'loading';
        self.services.masterService.getImage(userId, 'profile')
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                self.profileImages[userId] = reader.result;
              };
            }
          });
      }
    }
    return self.services.projectsCommonService.defaultProfileImage;
  }

  getFilterData(filterObj) {
    const filter = {};
    for (const item in filterObj) {
      if (filterObj && filterObj[item] && filterObj[item].length) {
        filterObj[item].forEach((value) => {
          if (item == 'group') {
            if (!filter['primary_group']) {
              filter['primary_group'] = [];
            }
            filter['primary_group'].push(value.name);
          } else if (item == 'workstream') {
            if (!filter['primary_workstream']) {
              filter['primary_workstream'] = [];
            }
            filter['primary_workstream'].push(value.name);
          } else if (item == 'location') {
            if (!filter['primary_location']) {
              filter['primary_location'] = [];
            }
            filter['primary_location'].push(value.name);
          } else if (item == 'status') {
            if (!filter['status']) {
              filter['status'] = [];
            }
            filter['status'].push(value.name);
          } else if (item == 'stage') {
            if (!filter['stage']) {
              filter['stage'] = [];
            }
            filter['stage'].push(value.name);
          } else if (item == 'initiative') {
            if (!filter['initiatives']) {
              filter['initiatives'] = [];
            }
            filter['initiatives'].push(value.name);
          } else if (item == 'keyword') {
            if (!filter['keywords']) {
              filter['keywords'] = [];
            }
            filter['keywords'].push(value);
          } 
          // else if (item == 'workflow_status') {
          //   if (!filter['workflow_status']) {
          //     filter['workflow_status'] = [];
          //   }
          //   filter['workflow_status'].push(value.name);
          // }
        });
      }
    }
    return filter;
  }


  // getWorkflowStatus(self): void {
  //   self.options.workflow_status = [{
  //     _id: WorkflowStatus.SAVED,
  //     name: WorkflowStatus.SAVED
  //   }, {
  //     _id: WorkflowStatus.REVIEW_REQUESTED,
  //     name: WorkflowStatus.REVIEW_REQUESTED
  //   }, {
  //     _id: WorkflowStatus.APPROVED,
  //     name: WorkflowStatus.APPROVED
  //   }];
  // }
  sendRecommend(project) {
    const projectLink = window.location.origin + '/projects/search/view/' + project._id;
    const body = `${Messages.RECOMMEND_MAIL_BODY_1} ${projectLink} ${Messages.RECOMMEND_MAIL_BODY_2}`;
    const uri = `mailto:?subject=${encodeURIComponent(Messages.RECOMMEND_PROJECT_MAIL_SUBJECT)}&body=${encodeURIComponent(body)}`;
    window.open(uri);
  }

  /**
   * isReturn: return projects/list/view page
   */
  openDialog(self, component, data, width, message, emit = null, isReturn = false) {
    const dialogRef = this.dialog.open(component, {
      width: width,
      maxHeight: '95vh',
      data: data
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        self.services.commonService.openSnackBar('success', message);
        if (emit) {
          self.update.emit({ type: emit });
        } else {
          if (isReturn) {
            self.router.navigate(['/projects/list/live']);
          }
          self.refresh();
        }
      }
    });
  }

  canEditProject(project: any) {
    const loginUser = this.authService.currentUserValue;
    if (loginUser && project && project.project_lead && project.project_lead.eso_id) {
      if (project.project_lead.eso_id == loginUser.eso_id) {
        return true;
      }
    }
    if (loginUser && project && project.team_members) {
      for (const user of project.team_members) {
        if (user.eso_id === loginUser.eso_id) {
          return true;
        }
      }
    }
    if (loginUser && project && project.primary_group && project.primary_group.group_lead) {
      if (project.primary_group.group_lead.eso_id == loginUser.eso_id) {
        return true;
      }
    }
    if (loginUser && project && project.other_groups) {
      for (const group of project.other_groups) {
        if (group.group_lead && group.group_lead.eso_id == loginUser.eso_id) {
          return true;
        }
      }
    }
    if (loginUser && project && project.primary_workstream && project.primary_workstream.workstream_lead) {
      if (project.primary_workstream.workstream_lead.eso_id == loginUser.eso_id) {
        return true;
      }
    }
    if (loginUser && project && project.other_workstreams) {
      for (const workstream of project.other_workstreams) {
        if (workstream.workstream_lead && workstream.workstream_lead.eso_id == loginUser.eso_id) {
          return true;
        }
      }
    }
    if (loginUser && project && project.primary_location && project.primary_location.location_lead) {
      for (const location_lead of project.primary_location.location_lead) {
        if (location_lead.eso_id === loginUser.eso_id) {
          return true;
        }
      }
    }
    if (loginUser && project && project.initiatives) {
      for (const initiative of project.initiatives) {
        if (initiative.initiative_lead && initiative.initiative_lead.eso_id == loginUser.eso_id) {
          return true;
        }
      }
    }
    if (loginUser && project && project.group_location_leads) {
      for (const group_location_leads of project.group_location_leads) {
        if (group_location_leads && group_location_leads == loginUser._id) {
          return true;
        }
      }
    }
    if (loginUser && project && project.other_locations) {
      let result = false;
      project.other_locations.forEach((element) => {
        if (element && element.location_lead) {
          element.location_lead.forEach((ele) => {
            if (ele && ele.eso_id) {
              if (ele.eso_id == loginUser.eso_id) {
                result = true;
              }
            }
          });
        }
      });
      return result;
    }
    return false;
  }


  isAssigned(project: any) {
    const loginUser = this.authService.currentUserValue;
    if (loginUser && project && project.workflow && project.workflow.assigned_user) {
      if (project.workflow.assigned_user == loginUser._id) {
        return true;
      } else if (project.workflow.assigned_user.eso_id) {
        return project.workflow.assigned_user.eso_id == loginUser.eso_id;
      }
    }
    return false;
  }
}