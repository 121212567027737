<div fxFlex fxLayout="column" *ngIf="deliverable" class="view-deliverable">
  <div fxLayout="row wrap" fxLayoutGap="30px" class="pri-btn-view-wrap">
    <button id="back" mat-button color="primary" (click)="onBack()">BACK</button>
    <div fxFlex></div>
    <button id="edit" *ngIf="(deliverable.canEdit || deliverable.canEditInitiative && !isLiquidStudio)"
      [routerLink]="['/deliverables', deliverable._id]" mat-button color="primary">Edit</button>
    <button id="viewComments" *ngIf="comments.length" mat-stroked-button color="primary" (click)="onViewComments()">View Comments</button>
    <button id="requestReview" *ngIf="deliverable.canRequestReview && isLabs" mat-stroked-button color="primary" (click)="onReviewRequest()">Request Review</button>
    <button id="requestPublish" *ngIf="deliverable.canRequestPublish" mat-stroked-button color="primary" (click)="onRequestPublish()">Request Publish</button>
    <button id="reassign" *ngIf="services.commonService.hasAnyLeadRole() && deliverable.canApprove && isLabs" color="primary" (click)="onReassign()" mat-stroked-button>Reassign</button>
    <button id="approve" *ngIf="services.commonService.hasAnyLeadRole() && deliverable.canApprove && isLabs" color="primary" (click)="onApprove()" mat-stroked-button>Approve</button>
    <button id="sendReminder" *ngIf="canSendReminder(deliverable) && isLabs" color="primary" (click)="onSendReminder()" mat-stroked-button>Send Reminder</button>
    <button id="rework" *ngIf="services.commonService.hasAnyLeadRole() && deliverable.canPublish" mat-stroked-button color="primary" (click)="onRework()">Reassign</button>
    <button id="approvePublish" *ngIf="services.commonService.hasAnyLeadRole() && deliverable.canPublish" mat-stroked-button color="primary" (click)="onApprovePublish()">Approve Publish</button>
    <button id="recommend" mat-stroked-button color="primary" (click)="services.deliverableCommonService.sendRecommend(deliverable)">Recommend</button>
  </div>
  <div fxLayout="column" class="workflow_status_wrap">
    <div fxLayout="row" class="workflow_status" *ngIf="deliverable.workflow_status != WorkflowStatus.PUBLISH_REQUESTED">
      <span id="workflowStatus">{{deliverable.workflow_status}}</span>
      <span id="workflowFromUser" *ngIf="deliverable.workflow && deliverable.workflow.from_user">by
        {{deliverable.workflow.from_user.display_name}} on {{deliverable.workflow.updated | date}}</span>
      <span id="workflowLastUpdatedBy" *ngIf="!deliverable.workflow && deliverable.last_updated_by">by
        {{deliverable.last_updated_by.display_name}} on {{deliverable.last_updated_date | date}}</span>
    </div>
    <div fxLayout="row" class="workflow_status"
      *ngIf="(deliverable.workflow_status == WorkflowStatus.APPROVED || deliverable.workflow_status == WorkflowStatus.PUBLISH_APPROVED) && isEdited()">
      <span id="edited">Edited</span>
      <span id="editedBy">by {{deliverable.last_updated_by.display_name}} on {{deliverable.last_updated_date |
        date}}</span>
    </div>
    <div fxLayout="row" class="workflow_status"
      *ngIf="(deliverable.workflow_status == WorkflowStatus.REVIEW_REQUESTED && deliverable.workflow && deliverable.workflow.assigned_user)">
      <span id="reviewRequested">Review requested</span>
      <span id="reviewRequestedBy">from {{deliverable.workflow.assigned_user.display_name}} on
        {{deliverable.workflow.updated | date}}</span>
    </div>
    <div fxLayout="row" class="publish_requested_status"
      *ngIf="deliverable.workflow_status == WorkflowStatus.PUBLISH_REQUESTED">
      <span id="publishApprover">Publish Approver</span>
      <span id="publishApproverUser" *ngIf="deliverable.primary_location">
        {{publishApprovers}} on {{deliverable.workflow.updated | date}}</span>
    </div>
    <div fxLayout="row" class="publish_requested_status"
      *ngIf="deliverable.workflow_status == WorkflowStatus.PUBLISH_REQUESTED">
      <span id="publishRequester">Publish Requester</span>
      <span id="publishRequestedUser" *ngIf="deliverable.workflow && deliverable.workflow.from_user">
        {{deliverable.workflow.from_user.display_name}} on {{deliverable.workflow.updated | date}}</span>
    </div>
  </div>
  <h2 id="deliverableName" class="white-space-pre">{{deliverable.name}}</h2>
  <h3 id="deliverableShortDesc">{{deliverable.short_description}}</h3>
  <div fxLayout="row" class="value-group">
    <div id="deliverableType" class="label deliverable_type">
      {{deliverable.deliverable_type?deliverable.deliverable_type.name:''}} {{deliverable.idf_number? " - " +
      deliverable.idf_number:''}}
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="30px" class="image_wrap">
    <div id="imagePreview" class="image_preview">
      <div [style.background-image]="'url(' + getDeliverableImage(deliverable)  + ')'" class="image_preview_inner"></div>
    </div>
    <div fxFlex fxLayout="column">
      <p id="deliverableLongDesc" fxFlex [innerHTML]="deliverable.long_description"></p>
      <a id="url{{i}}" [href]="services.commonService.addHttp(option)" target="_blank" class="link"
        *ngFor="let option of deliverable.url;let i=index">{{option}}</a>
    </div>
  </div>
  <div fxLayout="column" class="owner_wrap">
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="34" fxLayout="column">
        <h4 id="deliverableAttributes">DELIVERABLE ATTRIBUTES</h4>
        <div fxLayout="row" class="value-group">
          <div id="t_org" class="label">Organization</div>
          <div id="org_value" fxFlex class="value">
            {{deliverable.organization_type?deliverable.organization_type.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_client" class="label">Client</div>
          <div id="client_value" fxFlex class="value">{{clientInfo}}</div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_owner" class="label">Owner</div>
          <div id="owner_value" fxFlex class="value">{{deliverable.owner?deliverable.owner.display_name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="!isLiquidStudio">
          <div id="t_primaryGroup" class="label">Primary Group</div>
          <div id="primaryGroup_value" fxFlex class="value">
            {{deliverable.primary_group?deliverable.primary_group.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="!isLiquidStudio">
          <div id="t_otherGroups" class="label">Other Groups</div>
          <div id="otherGroups_value" fxFlex class="value"><span
              *ngFor="let option of deliverable.other_groups; last as isLast;let i=index">{{option.name}}<span
                *ngIf="!isLast">, </span></span></div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="isLabs">
          <div id="t_workstream" class="label">Primary Workstream</div>
          <div id="workstream_value" fxFlex class="value">
            {{deliverable.primary_workstream?deliverable.primary_workstream.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="isLabs">
          <div id="t_otherWorkstreams" class="label">Other Workstreams</div>
          <div id="otherWorkstreams_value" fxFlex class="value"><span
              *ngFor="let option of deliverable.other_workstreams; last as isLast;let i=index">{{option.name}}<span
                *ngIf="!isLast">, </span></span></div>
        </div>

        <div fxLayout="row" class="value-group">
          <div id="t_primaryLocation" class="label">Primary Location</div>
          <div id="primaryLocation_value" fxFlex class="value">
            {{deliverable.primary_location?deliverable.primary_location.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_otherLocations" class="label">Other Locations</div>
          <div id="otherLocations_value" fxFlex class="value"><span
              *ngFor="let option of deliverable.other_locations; last as isLast;let i=index">{{option.name}}<span
                *ngIf="!isLast">, </span></span></div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_lastPublished" class="label">Last Published</div>
          <div fxFlex class="value">
            <span id="lastPublished_hasValue"
              *ngIf="deliverable.last_showcase_published_date">{{deliverable.last_showcase_published_date | date
              }}</span>
            <span id="lastPublished_nullValue" *ngIf="!deliverable.last_showcase_published_date">n/a</span>
          </div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_lastEdited" class="label">Last Edited</div>
          <div fxFlex class="value">
            <span id="lastEdited_hasValue" *ngIf="deliverable.last_updated_date">{{deliverable.last_updated_date | date
              }}</span>
            <span id="lastEdited_nullValue" *ngIf="!deliverable.last_updated_date">n/a</span>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxLayout="column">
        <h4 id="teamMembers">ADDITIONAL TEAM MEMBERS</h4>
        <div fxLayout="row" *ngFor="let user of deliverable.team_members;let i=index" class="user-wrap">
          <img alt="user profile" id="userImage{{i}}" [src]="getProfileImage(user.eso_id)" class="user-profile" />
          <div fxFlex fxLayout="column" class="user-name-wrap">
            <div id="userDisplayName{{i}}">{{user.display_name}}</div>
            <div id="userJobTitle{{i}}">{{user.job_title }}</div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxLayout="column">
        <h4 id="keywords">KEYWORDS</h4>
        <mat-chip-set id="keywords_list">
          <mat-chip id="keywords_chip{{i}}" disableRipple="true" *ngFor="let option of deliverable.keywords;let i=index">
            {{option}}
          </mat-chip>
        </mat-chip-set>
        <h4 id="industry">INDUSTRY</h4>
        <mat-chip-set id="industry_list">
          <mat-chip id="industry_chip{{i}}" disableRipple="true" *ngFor="let option of deliverable.industries;let i=index">
            {{option.name}}
          </mat-chip>
        </mat-chip-set>
        <h4 id="partner" *ngIf="!hidePartners">PARTNERS</h4>
        <mat-chip-set id="partner_list" *ngIf="!hidePartners"> 
          <mat-chip id="partner_chip{{i}}" disableRipple="true" *ngFor="let option of deliverable.partners;let i=index">
            {{option.name}}
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="67" fxLayout="column">
        <div fxFlex fxLayout="row" class="value-group" *ngIf="deliverable.asset_link">
          <div id="publishedLink" class="label published-link">Published Link</div>
          <div id="deliverableAssetLink" fxFlex class="value">
            <a [href]="services.commonService.addHttp(deliverable.asset_link)" target="_blank"
              class="link">{{linkName}}</a>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxLayout="column">
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="30px" class="project_wrap">
    <div fxFlex="33" fxLayout="column">
      <div fxLayout="row" class="value-group">
        <div id="deliverableStatus" class="label">Deliverable Status</div>
        <div id="deliverableStatus_value" fxFlex class="value">
          {{deliverable.deliverable_status?deliverable.deliverable_status.name:'In Progress'}}</div>
      </div>
      <div fxLayout="row" class="value-group">
        <div id="endQTR" class="label">FiscalYear Quarter</div>
        <div id="endQTR_value" fxFlex class="value">
          <span *ngIf="deliverable.eac_qrtr">FY{{deliverable.eac_qrtr.year-2000}} Q{{deliverable.eac_qrtr.qtr}}</span>
        </div>
      </div>
    </div>
    <div fxFlex="33" fxLayout="column" *ngIf="!isIncubation && !isLiquidStudio">
      <h4 id="relevantProject">Relevant Project</h4>
      <div *ngIf="deliverable.project">
        <div id="projectName" class="project_name">{{deliverable.project.name}}</div>
        <div id="projectDesc" class="project_desc">{{deliverable.project.description}}</div>
      </div>
    </div>
    <div fxFlex="33" fxLayout="column" *ngIf="!isLiquidStudio">
      <h4 id="initiatives">INITIATIVES FOR AFFILIATION</h4>
      <mat-chip-set id="initiatives_list">
        <mat-chip id="initiatives_chip{{i}}" disableRipple="true" *ngFor="let option of deliverable.initiatives;let i=index">
          {{option.name}}
        </mat-chip>
      </mat-chip-set>
    </div>
  </div>
  <div class="related-deliverables-wrap"
    *ngIf="deliverable.related_deliverables && deliverable.related_deliverables.length">
    <table aria-label="RELATED DELIVERABLES">
      <thead>
        <tr>
          <th id="th_relatedDeliverables">Related Deliverables</th>
          <th id="th_owner">Owner</th>
          <th id="th_primaryGroup" *ngIf="!isLiquidStudio">Primary Group</th>
          <th id="th_primaryLocation">Primary Location</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let relatedDeliverable of deliverable.related_deliverables;let i=index">
          <td id="td_relatedDeliverables{{i}}">
            <div fxLayout="row" fxLayoutGap="15px">
              <img id="deliverableImage{{i}}" [src]="getDeliverableImage(relatedDeliverable)" alt=""
                class="deliverable-image">
              <div fxLayout="column">
                <h6 id="deliverableName{{i}}">{{relatedDeliverable.name}}</h6>
                <p id="deliverableShortDesc{{i}}">{{relatedDeliverable.short_description}}</p>
              </div>
            </div>
          </td>
          <td id="td_displayName{{i}}">{{relatedDeliverable.owner?relatedDeliverable.owner.display_name:'n/a'}}</td>
          <td id="td_primaryGroup{{i}}" *ngIf="!isLiquidStudio">
            {{relatedDeliverable.primary_group?relatedDeliverable.primary_group.name:'n/a'}}</td>
          <td id="td_primaryLocation{{i}}">
            {{relatedDeliverable.primary_location?relatedDeliverable.primary_location.name:'n/a'}}</td>
        </tr>
      </tbody>
    </table>
  </div>

</div>