/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Messages } from '@app/models/messages';
import { Services } from '@app/services/services';

@Component({
  selector: 'app-load-setting',
  templateUrl: './load-setting.component.html',
  // styleUrls: ['../dialog/book-mark-setting/book-mark-setting.component.scss']
})
export class LoadSettingComponent implements OnInit {
  loading: any = 0;
  submitted: any = false;
  settings: Array<any> = [];
  setting: any = null;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LoadSettingComponent>,
    public services: Services
  ) {
  }

  ngOnInit(): void {
    this.services.reportingService.getSettings()
      .subscribe((response) => {
        this.loading--;
        this.settings = response.result;
      });
  }

  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    if (this.setting) {
      this.dialogRef.close();
      this.dialog.open(DeleteSettingComponent, {
        width: '516px',
        height: 'auto',
        maxHeight: '95vh',
        data: this.setting
      });
    }
  }

  onLoad(): void {
    if (this.setting) {
      this.dialogRef.close(this.setting);
    }
  }
}

@Component({
  selector: 'app-delete-setting',
  templateUrl: './delete-setting.component.html',
  // styleUrls: ['../dialog/book-mark-setting/book-mark-setting.component.scss']
})
export class DeleteSettingComponent {
  loading: any = 0;
  constructor(
    public dialogRef: MatDialogRef<LoadSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public services: Services
  ) {
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onDelete(): void {
    this.loading++;
    this.services.reportingService.deleteSetting(this.data._id)
      .subscribe((res) => {
        this.loading--;
        this.dialogRef.close(res);
        this.services.commonService.reportDeleted.emit(this.data._id);
      });
  }
}

@Component({
  selector: 'app-save-setting',
  templateUrl: './save-setting.component.html',
  // styleUrls: ['../dialog/book-mark-setting/book-mark-setting.component.scss']
})
export class SaveSettingComponent {
  loading: any = 0;
  isClick = false;
  reportName: any = null;
  settings: Array<any> = [];
  constructor(
    public dialogRef: MatDialogRef<SaveSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public services: Services,
    public dialog: MatDialog
  ) {
    if (data._id) {
      this.reportName = data.name;
    }
  }
  ngOnInit(): void {
    this.services.reportingService.getSettings()
      .subscribe((response) => {
        this.settings = response.result;
        this.isClick = true;
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.isClick) {
      this.isClick = false;
      setTimeout(() => {
        this.loading++;
        const formData = {
          name: this.reportName,
          setting: this.data.setting
        };
        let hasValue = false;
        if (this.settings.length > 0) {
          for (let i = 0; i < this.settings.length; i++) {
            if (this.settings[i].name == this.reportName) {
              hasValue = true;
              const formData = {
                _id: this.settings[i]._id,
                name: this.reportName,
                setting: this.data.setting
              };
              this.dialog.open(UpdateReportBookSettingComponent, {
                width: '516px',
                height: 'auto',
                maxHeight: '95vh',
                data: formData
              });
              this.dialogRef.close();
            }
          }
        }
        if (!hasValue) {
          if (this.data.name != this.reportName) {
            this.data.id = null;
            this.data.name = this.reportName;
            this.services.reportingService.saveSetting(formData)
              .subscribe((res) => {
                this.loading--;
                this.dialogRef.close(res);
                this.services.commonService.reportCreated.emit(res);
              });
          } else {
            this.data.name = this.reportName;
            this.services.reportingService.updateSetting(this.data._id, formData)
              .subscribe((res) => {
                this.loading--;
                this.dialogRef.close(res);
                this.services.commonService.reportCreated.emit(res);
              });
          }
        }
      }, 2500);
    }
  }
}

@Component({
  selector: 'app-save-setting',
  templateUrl: './update-setting.component.html',
  // styleUrls: ['../dialog/book-mark-setting/book-mark-setting.component.scss']
})
export class UpdateReportBookSettingComponent {
  isClick = true;
  loading: any = 0;
  setting: any = null;
  settings: Array<any> = [];
  bookMarkName: any = null;
  bookMarkType: any = null;
  constructor(
    public dialogRef: MatDialogRef<SaveSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public services: Services,
    public dialog: MatDialog
  ) {
  }

  onCancel(): void {
    this.dialog.open(SaveSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: this.data
    });
    this.dialogRef.close();
  }

  onSave(): void {
    if (this.isClick) {
      this.isClick = false;
      this.loading++;
      this.services.reportingService.updateSetting(this.data._id, this.data)
        .subscribe((res) => {
          this.loading--;
          this.dialogRef.close(res);
          this.services.commonService.reportCreated.emit(res);
          this.services.commonService.openSnackBar('success', Messages.REPORTING_SETTING_SAVE_SUCCESS);
        });
    }
  }
}
