/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { Services } from '@app/services/services';

@Component({
  selector: 'app-related-deliverables-list',
  templateUrl: './related-deliverables-list.component.html',
  styleUrls: ['./related-deliverables-list.component.scss']
})
export class RelatedDeliverablesListComponent implements OnInit {
  @Input('deliverable') deliverable: any;
  @Input('isChecked') isChecked: any;
  @Input('isShowLink') isShowLink: any;
  dataImages: any = {};
  isForList = true;
  thumbnailSize: any = {
    width: 193,
    height: 155
  }
  constructor(
    public services: Services
  ) { }

  ngOnInit(): void {
    if (this.isShowLink === undefined) {
      this.isShowLink = true;
    }
  }
  getDataImage(data) {
    if (data && data.image_link) {
      const deliverable = data._id;
      if (this.dataImages[deliverable] && this.dataImages[deliverable] != 'loading') {
        return this.dataImages[deliverable];
      } else if (this.dataImages[deliverable] != 'loading') {
        this.dataImages[deliverable] = 'loading';
        this.services.masterService.getImage(deliverable, 'deliverable', this.isForList, this.thumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[deliverable] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }
}
