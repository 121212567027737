/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from '@angular/common';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Messages, OrgType, WorkflowStatus } from '@app/models';
import { Services } from '@app/services/services';
import { htmlToPptxText } from 'html-pptxgenjs';
import * as _ from 'lodash';
import pptxgen from 'PptxGenJS';
import { ReworkStoryDialog, StoryCommentsDialog, StoryPublishDialog, StoryPublishRequestDialog } from './dialog/story-dialog.components';

@Component({
  selector: 'app-story-view',
  templateUrl: './story-view.component.html',
  styleUrls: ['./story-view.component.scss']
})
export class StoryViewComponent implements OnInit, AfterViewChecked {
  deliverableImages: any = {};
  loading: any = 0;
  loadIcon = 0;
  routerWatcher: any;
  storyId: any;
  dataImages: any = {};
  story: any = null;
  storyImages: any = {};
  profileImages: any = {};
  loginUser: any = null;
  relatedstorys: Array<any> = [];
  comments: Array<any> = [];
  isLiquidStudio: any = false;
  isLabs: any = false;
  isIncubation: any = false;
  clientInfo: any = '';
  linkName: any = '';
  bulletIndentForPpt: any = '';
  denominatorForPpt: any = '';
  publishApprovers: any = '';
  isExceedsTitle: any = true;
  isExceedsSubTitle: any = true;
  isExceedsHashtag: any= true;
  isExceedsContext: any = true;
  isExceedsSolution: any = true;
  isExceedsImpact: any = true;
  isForList = true;
  isForce: any = false;
  isNested: any = false;
  isContextHtml: any = false;
  isSolutionHtml: any = false;
  isImpactHtml: any = false;
  hidePartners = false;
  hideForceNestedMarket = false;

  relatedDelThumbnailSize: any = {
    //commented out the below code as image was getting blurred
    // width: 80,
    // height: 64
  }
  copyimage: any;
  Context: any = [];
  ContactHashTag:any=[];
  Solution: any = [];
  Impact: any = [];
  options: any = {
    pictures: [],
    icons: []
  };
  iconsArr: any = [];
  iconShapeInfo: any;
  isLoadingIcon: any = false;
  isRefreshIcon: any = false;
  targetPropertiesList = [];
  specialCharactersList = [];
  targetCharactersDic = {};
  constructor(
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    public services: Services
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngAfterViewChecked(): void {
    if (this.options.icons && this.options.icons.length > 0) {
      const icons = this.iconsArr.filter((o) => o.height.toString() == 'NaN' || o.height == 0 || o.width.toString() == 'NaN' || o.width == 0);
      if (icons && icons.length > 0) {
        this.isLoadingIcon = false;
      }
      this.iconsArr = this.calculateIcons(this.options.icons);
    }
    this.cd.detectChanges();
  }
  ngOnInit(): void {
    this.services.commonService.showHeader.emit(true);
    this.services.commonService.parnterNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
      }
    });
    this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
    this.services.commonService.forceRelatedNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.hideForceNestedMarket = this.services.commonService.globalFlags['hideForceNestedMarket'];
      }
    });
    this.hideForceNestedMarket = this.services.commonService.globalFlags['hideForceNestedMarket'];
    window.addEventListener('resize', () => {
      if (this.options.icons && this.options.icons.length > 0) {
        this.isRefreshIcon = true;
        this.isLoadingIcon = false;
        this.iconsArr = this.calculateIcons(this.options.icons);
      }
    });
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.loading++;
      this.storyId = params.story_id;
      this.services.storiesService.getStory(this.storyId)
        .subscribe((response) => {
          this.loading--;
          if (response && response.story) {
            this.story = response.story;
            this.targetPropertiesList = response.targetPropertiesList ? response.targetPropertiesList : [];
            this.specialCharactersList = response.specialCharactersList ? response.specialCharactersList : [];
            this.targetCharactersDic = response.targetCharactersDic ? response.targetCharactersDic : {};
            this.story = { ...response.story, ...response.metadata };
            this.linkName = response.linkName;
            this.denominatorForPpt = response.denominatorForPpt ? response.denominatorForPpt : 10;
            this.bulletIndentForPpt = response.bulletIndentForPpt ? response.bulletIndentForPpt : 10;
            this.adjustPicturesOrder();
            this.getPulishApprovers();
            this._getComments();
            this.duplicateRemoval();
            this.checkOrgType();
            this.checkForce();
            this.checkNested();
            this._getIconShapeInfo();
            this.checkStrSize();
          }
        });
    });
  }

  checkStrSize() {
    if (this.story.title?.length > 50) {
      this.isExceedsTitle = false;
    }
    if (this.story.subtitle?.length > 150) {
      this.isExceedsSubTitle = false;
    }
    if (this.story.hashtag?.length > 101) {
      this.isExceedsHashtag = false;
    }
    if (this.story.pure_impact?.length > 550 || this.story.pure_context?.length > 550 ||
      this.story.pure_solution?.length > 550
    ) {
      this.isExceedsContext = false;
      this.isExceedsSolution = false;
      this.isExceedsImpact = false;
    }
    this.isContextHtml = this.checkHtml(this.story.context);
    this.isSolutionHtml = this.checkHtml(this.story.solution);
    this.isImpactHtml = this.checkHtml(this.story.impact);
  }

  checkHtml(htmlStr) {
    htmlStr = htmlStr.replace(/<p[^>]*>/g, '');
    htmlStr = htmlStr.replace(/<\/p>/g, '');
    const reg = /<[^>]+=>/g;
    return reg.test(htmlStr);
  }
  adjustPicturesOrder() {
    if (this.story.pictures?.length > 0 && this.story.pictures[0].order) {
      this.options.pictures = this.story.pictures.sort(this.compare('order'));
    } else {
      this.options.pictures = this.story.pictures;
    }
    if (this.story.icons?.length > 0 && this.story.icons[0].order) {
      this.options.icons = this.story.icons.sort(this.compare('order'));
    } else {
      this.options.icons = this.story.icons;
    }
  }

  compare(property) {
    return function (a, b) {
      const value1 = a[property];
      const value2 = b[property];
      return value1 - value2;
    };
  }

  getPulishApprovers() {
    if (this.story.primary_location) {
      const locationLeads = this.story.primary_location.location_lead.map((o) => o.display_name);
      for (const i in locationLeads) {
        if (Number(i) === 0 || locationLeads.length === 1) {
          this.publishApprovers = locationLeads[i];
        } else {
          this.publishApprovers = this.publishApprovers + '; ' + locationLeads[i];
        }
      }
    }
  }
  _getComments() {
    if (this.story.workflow) {
      let workflow = this.story.workflow;
      do {
        if (workflow.comments) {
          this.comments.push({
            comments: workflow.comments,
            from_user: workflow.from_user,
            updated: workflow.updated
          });
        }
        if (workflow.old_workflow) {
          workflow = workflow.old_workflow;
        } else {
          workflow = null;
        }
      } while (workflow);
    }
  }

  duplicateRemoval() {
    this.story.other_groups = this.story.other_groups.filter((o) => o._id != this.story.primary_group._id);
    this.story.other_workstreams = this.story.other_workstreams.filter((o) => o._id != this.story.primary_workstream._id);
    this.story.other_locations = this.story.other_locations.filter((o) => o._id != this.story.primary_location._id);
  }

  /**
   * handle html text
   * @param richText html
   * @param key context/solution/impact
   */
  handlePptData(richText: string, pure_context, pure_solution, pure_impact, key: string) {
    // content title
    const textContentTitle: any = [
      { text: key + ':', options: { fontSize: 14, breakLine: true, fontFace: 'Graphik', charSpacing: -1 / 3, color: 'a100ff', bold: true } }
    ];

    // temp array for whole content included title
    let tmpTextArray = [...textContentTitle];

    // make sure html parser tool can identify <strong>
    richText = richText.replace(/<strong[^>]*>/g, '<b>');
    richText = richText.replace(/<\/strong>/g, '</b>');
    richText = richText.replace(/<em[^>]*>/g, '<i>');
    richText = richText.replace(/<\/em>/g, '</i>');
    richText = richText.replace(/<h\d+[^>]*>/g, '<p><b>');
    richText = richText.replace(/<\/h\d+>/g, '</b></p>');
    richText = richText.replace(/&bull;/g, '&bull;@@uullii@@');
    tmpTextArray = [...tmpTextArray, ...this.ParseRichTextToPptText(richText, pure_context, pure_solution, pure_impact, -1 / 3)];

    // only retain the items which text is not empty or break line
    tmpTextArray = [...tmpTextArray].filter((o) => !!o.text || o.options.breakLine);
    // set consecutive empty lines to be small height
    reconcileParagraphs(tmpTextArray, this.denominatorForPpt);
    // remove multiple empty lines
    tmpTextArray = this.removeMultipleEmptyLines(tmpTextArray);
    // bullet list add breakline
    this.bulletAddBreakline(tmpTextArray);
    // set bullet indent property
    tmpTextArray.forEach((o, index) => {
      // bullet indent
      if (o.options.bullet) {
        o.options.indentLevel++;
        if (typeof o.options.bullet === 'boolean') {
          o.options.bullet = { indent: this.bulletIndentForPpt };
        } else {
          o.options.bullet.indent = this.bulletIndentForPpt;
        }
      }
      // replace @uullii@@ with 4 spaces
      if (o.text === '@@uullii@@') {
        o.text = '  ';
      }
      // small line height for the line before "•"
      if (o.text === '•' && !!tmpTextArray[index - 1] && index > 1) {
        tmpTextArray[index - 1].options.lineSpacingMultiple = 1 / 10;
      }
    });
    this[key] = tmpTextArray;
  }

  /**
   * remove multiple empty lines
   * @param contentKey content key: Context/Solution/Impact
   */
  private removeMultipleEmptyLines(tmpTextArray) {
    const toBeRemoveElements = [];
    for (let index = 0; index < tmpTextArray.length; index++) {
      const element = tmpTextArray[index];
      if (element.text.trim() == '') {
        if (index > 0 && !element.options.lineSpacingMultiple && (tmpTextArray[index - 1].options.breakLine || tmpTextArray[index - 1].text.trim() == '')) {
          toBeRemoveElements.push(index);
        }
      }
    }
    return tmpTextArray.filter((o, index) => !toBeRemoveElements.includes(index));
  }

  /**
   * bullet list add breakline
   * @param key key: Context/Solution/Impact
   */
  bulletAddBreakline(tmpTextArray) {
    const obj = { breakLine: true };
    for (let index = 0; index < tmpTextArray.length; index++) {
      const element = tmpTextArray[index];
      const previousElement = tmpTextArray[index - 1];
      if (element.options?.bullet && element.options?.bullet == true) {
        if (previousElement != null && previousElement != undefined) {
          previousElement.options = Object.assign(previousElement.options, obj);
        }
      }
    }
  }

  /**
   * parse rich text to ppt text
   * @param richText rich text
   * @returns text array
   */
  private ParseRichTextToPptText(richText: any, pure_context, pure_solution, pure_impact, charSpacing: any = null, isBold: any = false) {
    return _.each(htmlToPptxText(richText), (o) => {
      if (o.text == ' ' && o.options.breakLine) {
        o.text = '';
      }
      let dynamicFontSize = 12;
      if (pure_context?.length > 550 || pure_solution?.length > 550 ||
        pure_impact?.length > 550
      ) {
        dynamicFontSize = 10;
      }
      o.options.fontSize = dynamicFontSize;
      o.options.fontFace = 'Graphik';
      if (!o.options.bold) {
        o.options.bold = isBold;
      }
      if (charSpacing) {
        o.options.charSpacing = charSpacing;
      } else {
        delete o.options.charSpacing;
      }

      // remove undefined properties
      if (!o.options.align)
        delete o.options.align;
      if (!o.options.breakLine)
        delete o.options.breakLine;
      if (!o.options.underline)
        delete o.options.underline;

      // color
      if (!o.options.color) delete o.options.color;
      // italic
      if (!o.options.italic) delete o.options.italic;
      // // strike
      // if (!o.options.strike) delete o.options.strike;
      // // subscript
      // if (!o.options.subscript) delete o.options.subscript;
      // // superscript
      // if (!o.options.superscript) delete o.options.superscript;
      // // hyperlink
      if (!o.options.hyperlink) {
        delete o.options.hyperlink;
      } else {
        // tooltip
        if (!o.options.hyperlink.tooltip) {
          delete o.options.hyperlink.tooltip;
        }
        // url
        if (!o.options.hyperlink.url) {
          delete o.options.hyperlink.url;
        }
        // slide
        if (!o.options.hyperlink.slide) {
          delete o.options.hyperlink.slide;
        } else {
          o.options.hyperlink.url = o.options.hyperlink.slide;
          delete o.options.hyperlink.slide;
        }
      }
      delete o.options.strike;
      delete o.options.subscript;
      delete o.options.superscript;
    });
  }

  /**
   * set bold and breakline styles
   * @param strongEndingStr string ending string
   */
  private setBoldBreaklineStyle(tmpTextArray: any, strongEndingStr: any, isBold: boolean) {
    if (strongEndingStr.indexOf('<breakline>') > -1) {
      const needBreaklineItems = strongEndingStr.split('<breakline>');
      // add bold and breakline style
      for (let needBreaklineItemIndex = 0; needBreaklineItemIndex < needBreaklineItems.length - 1; needBreaklineItemIndex++) {
        tmpTextArray.push({ text: needBreaklineItems[needBreaklineItemIndex], options: { fontSize: 12, fontFace: 'Graphik', color: '000000', charSpacing: -1 / 3, bold: isBold, breakLine: true } });
      }
      // add bold style
      tmpTextArray.push({ text: needBreaklineItems[needBreaklineItems.length - 1], options: { fontSize: 12, fontFace: 'Graphik', color: '000000', charSpacing: -1 / 3, bold: isBold } });
    } else {
      // add bold style
      tmpTextArray.push({ text: strongEndingStr, options: { fontSize: 12, fontFace: 'Graphik', color: '000000', charSpacing: -1 / 3, bold: isBold } });
    }
  }

  /**
   * download ppt slide
   */
  downloadPPT(isconfidential = false) {
    if (isconfidential) {
      this.story.confidentiality_level = null;
    }
    const pptx = new pptxgen();

    // set layout: 'LAYOUT_WIDE'  (13.33" x 7.5")
    pptx.layout = 'LAYOUT_WIDE';

    // define master
    this.setSlideMaster(pptx);

    // add slide
    const slide = pptx.addSlide({ masterName: 'MASTER_SLIDE' });

    // handle special characters for ppt
    this.handleSpecialCharacters();

    this.setSlide(slide);

    // output pptx file
    pptx.writeFile({ fileName: this.story.title });
  }

  /**
   * handle special characters for ppt
   */
  handleSpecialCharacters() {
    this.targetPropertiesList.forEach((p) => {
      this.specialCharactersList.forEach((character) => {
        this.story[p] = this.story[p]?.replace(String.fromCharCode(character), this.targetCharactersDic[character]);
      });
    });
  }

  /**
   * set slide titles, content etc. properties
   * @param slide slide
   */
  private setSlide(slide: pptxgen.Slide) {
    // Confidentiality level
    if (this.hideForceNestedMarket) {
      this.setConfidentialityLevel(slide);
    }

    // Org Group
    if (!this.hideForceNestedMarket) {
      this.setOrgtype(slide);
    }
    // Top title
    this.setTopTitle(slide);

    // Main title
    this.setMainTitle(slide);

    // Sub title
    this.setSubTitle(slide);

    // Context/Solution/Impact
    this.setContents(slide);

    // Contacts
    this.setContacts(slide);

    // pictures
    this.setPictures(slide);

    // icons
    this.setIcons(slide);
  }

  /**
  * set confidentiality levle for slide
  * @param slide slide
  */
  private setConfidentialityLevel(slide: pptxgen.Slide) {
    slide.addText(
      [
        {
          text: this.story.confidentiality_level,
          options: { fontSize: 18, fontFace: 'Calibri', color: 'ff0000', bold: false, breakLine: true }
        }
      ],
      // N / 71.42857143 =>inch; margin:[left,right, bottom, top]
      { x: 0.00, y: 0.10, h: 0.30, w: 12.8, valign: 'top', align: 'right', fit: 'none', charSpacing: 0, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.8 }
    );
  }

  /**
   * set top title for slide
   * @param slide slide
   */
  public setTopTitle(slide: pptxgen.Slide) {
    let titleContext = [];
    if (!this.hideForceNestedMarket) {
      if (this.story.force?.name != 'Not Applicable') {
        if (!this.story.force && !this.story.markets) {
          titleContext = [
            {
              text: this.story.organization_type.name ? this.story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            },
          ];
        }
        else if (!this.story.force) {
          titleContext = [
            {
              text: this.story.organization_type.name ? this.story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            },
            {
              text: this.story.markets ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: this.story.markets ? this.story.markets : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            }
          ];
        }
        else if (!this.story.markets) {
          titleContext = [
            {
              text: this.story.force ? this.story.force.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
            },
            {
              text: this.story.organization_type.name ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: this.story.organization_type.name ? this.story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            }
          ];
        }
        else {
          titleContext = [
            {
              text: this.story.force ? this.story.force.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
            },
            {
              text: this.story.organization_type.name ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: this.story.organization_type.name ? this.story.organization_type.name : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            },
            {
              text: this.story.markets ? ' | ' : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
            },
            {
              text: this.story.markets ? this.story.markets : '',
              options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
            }
          ];
        }
      }
      else {
        titleContext = [
          {
            text: this.story.organization_type.name ? this.story.organization_type.name : '',
            options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
          },
          {
            text: this.story.markets ? ' | ' : '',
            options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000' }
          },
          {
            text: this.story.markets ? this.story.markets : '',
            options: { fontSize: 14, fontFace: 'Graphik Black', color: '000000', bold: true }
          }
        ];
      }
    } else {
      titleContext = [
        {
          text: this.story.organization_type.name,
          options: { fontSize: 14, fontFace: 'Graphik (Body)', color: '000000', bold: true }
        },
        {
          text: this.story.primary_group ? ' | ' : '',
          options: { fontSize: 14, fontFace: 'Graphik (Body)', color: '000000' }
        },
        {
          text: this.story.primary_group ? this.story.primary_group.name : '',
          options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
        },
        {
          text: this.story.primary_location ? ' | ' : '',
          options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff' }
        },
        {
          text: this.story.primary_location ? this.story.primary_location.name : '',
          options: { fontSize: 14, fontFace: 'Graphik Black', color: 'a100ff', bold: true }
        }
      ];
    }
    slide.addText(
      titleContext,
      // N / 71.42857143 =>inch; margin:[left, right, bottom, top]
      { x: 0.43, y: 0.31, h: 0.37, w: 8.903422, valign: 'top', align: 'left', charSpacing: 0, fit: 'shrink', margin: [0, 0, 3.571428571, 7.142857143], lineSpacingMultiple: 0.85 }
    );
  }

  /**
* set setOrgtype for slide
* @param slide slide
*/
  private setOrgtype(slide: pptxgen.Slide) {
    slide.addText(
      [
      ],
      { x: 0.00, y: 0.00, h: 0.30, w: 6.66673, valign: 'top', align: 'left', fit: 'none', fill: { color: '000000' } }

    );
    slide.addText(
      [
      ],
      { x: 6.67995589, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }

    );
    slide.addText(
      [
      ],
      { x: 8.01330189, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }

    );
    if (this.isLabs) {
      slide.addText(
        [
          {
            options: { fontSize: 10, fontFace: 'Graphik (Body)', color: 'BFBEBF', breakLine: true }
          }
        ],
        { x: 9.34664789, y: 0.00, h: 0.30, w: 1.32012011, valign: 'middle', align: 'left', fit: 'none', charSpacing: 0, margin: [10, 0, 0, 0], lineSpacingMultiple: 0, fill: { color: 'BFBEBF' } }
      );
    } else
      slide.addText(
        [
        ],
        { x: 9.34664789, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }
      );
    if (this.isIncubation) {
      slide.addText(
        [
          {
            options: { fontSize: 10, fontFace: 'Graphik (Body)', color: 'BFBEBF', breakLine: true }
          }
        ],
        { x: 10.67999389, y: 0.00, h: 0.30, w: 1.32012011, valign: 'middle', align: 'left', fit: 'none', charSpacing: 0, margin: [10, 0, 0, 0], lineSpacingMultiple: 0, fill: { color: 'BFBEBF' } }
      );
    } else
      slide.addText(
        [
        ],
        { x: 10.67999389, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }
      );
    if (this.isLiquidStudio) {
      slide.addText(
        [
          {
            options: { fontSize: 10, fontFace: 'Graphik (Body)', color: 'FFFFFF', breakLine: true }
          }
        ],
        { x: 12.01333989, y: 0.00, h: 0.30, w: 1.32012011, valign: 'middle', align: 'left', fit: 'none', charSpacing: 0, margin: [10, 0, 0, 0], lineSpacingMultiple: 0, fill: { color: 'BFBEBF' } }
      );
    } else
      slide.addText(
        [
        ],
        { x: 12.01333989, y: 0.00, h: 0.30, w: 1.32012011, valign: 'top', align: 'left', fit: 'none', fill: { color: 'BFBEBF' } }
      );
  }

  /**
   * set main title for slide
   * @param slide slide
   */
  private setMainTitle(slide: pptxgen.Slide) {
    let dynamicFontSize = 36;
    if (this.story.title?.length > 50) {
      dynamicFontSize = 30;
    }
    slide.addText(
      [
        {
          text: this.story.title,
          options: { fontSize: dynamicFontSize, fontFace: 'Graphik', color: '000000', bold: true, breakLine: true }
        }
      ],
      // N / 71.42857143 =>inch; margin:[left, right, bottom, top]
      { x: 0.42, y: 0.63, h: 0.65, w: 8.903422, valign: 'top', align: 'left', fit: 'none', charSpacing: -3 / 2, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.8 }
    );
  }

  /**
   * set sub title for slide
   * @param slide slide
   */
  private setSubTitle(slide: pptxgen.Slide) {
    let dynamicFontSize = 16;
    if (this.story.subtitle?.length > 200) {
      dynamicFontSize = 14;
    }
    slide.addText(
      [
        {
          text: this.story.subtitle,
          options: { fontSize: dynamicFontSize, fontFace: 'Graphik', color: '4b0377', bold: true }
        }
      ],
      { x: 0.43, y: 1.96, h: 0.71, w: 9.38, valign: 'top', align: 'left', fit: 'none', charSpacing: -1, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.8 }
    );
  }

  /**
   * set context/solution/impact for slide
   * @param slide slide
   */
  private setContents(slide: pptxgen.Slide) {
    this.handlePptData(this.story?.context, this.story?.pure_context, this.story?.pure_solution, this.story?.pure_impact, 'Context');
    this.handlePptData(this.story?.solution, this.story?.pure_context, this.story?.pure_solution, this.story?.pure_impact, 'Solution');
    this.handlePptData(this.story?.impact, this.story?.pure_context, this.story?.pure_solution, this.story?.pure_impact, 'Impact');
    slide.addText(this.Context,
      { x: 0.43, y: 2.72, h: 3.01, w: 2.95, valign: 'top', align: 'left', fit: 'none', charSpacing: -1 / 3, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.9 });
    slide.addText(this.Solution,
      { x: 3.63, y: 2.72, h: 3.01, w: 2.95, valign: 'top', align: 'left', fit: 'none', charSpacing: -1 / 3, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.9 });
    slide.addText(this.Impact,
      { x: 6.87, y: 2.72, h: 3.01, w: 2.95, valign: 'top', align: 'left', fit: 'none', charSpacing: -1 / 3, margin: [0, 0, 0, 0], lineSpacingMultiple: 0.9 });
  }

  /**
   * set contacts and hashtag for slide 
   * changes done to fix spacing issue below contacts
   * @param slide slide
   */
  private setContacts(slide: pptxgen.Slide) {
    this.ContactHashTag=[];
    this.ContactHashTag.push({ text: 'Contacts:', options: { fontSize: 10, fontFace: 'Graphik', color: '000000', bold: true, breakLine: true } })
    for(let i=0; i<this.story.contacts?.length;i++){
      this.ContactHashTag.push( { text: this.story.contacts[i]?.email_id, options: { fontSize: 10, fontFace: 'Graphik', color: 'a100ff', breakLine: true } })
    }
    this.ContactHashTag.push({ text: this.story.hashtag, options: { fontSize: 10, fontFace: 'Graphik', color: 'a100ff', breakLine: true } })
    slide.addText(this.ContactHashTag,
      { x: 5.95, y: 6.07, h: 0.74, w: 3.95, valign: 'top', align: 'right', fit: 'none', margin: [0, 0, 0, 0] }
    );
  }

  /**
   * set pictures for slide
   * @param slide slide
   */
  private setPictures(slide: pptxgen.Slide) {
    const imgMaxHeight = 1.33;
    const imgMaxWidth = 2.86;
    const imgVerticalSpacing = 0.16;
    let offsetY = 0;
    let imgTargetWidth = 0;
    let imgTargetHeight = 0;
    let previousCaptionAndImgHeight = 0;
    let imgCaptionHeight = 0;
    let maxMultiple = 0;
    for (let i = 0; i < this.story.pictures?.length; i++) {
      offsetY += i > 0 ? imgVerticalSpacing + previousCaptionAndImgHeight : 0;
      const image = new Image();
      image.src = this.getDataStoryImage(this.story.pictures[i], 'story');
      maxMultiple = image.width / imgMaxWidth;
      maxMultiple = maxMultiple > image.height / imgMaxHeight ? maxMultiple : image.height / imgMaxHeight;
      imgTargetWidth = image.width / maxMultiple;
      imgTargetHeight = image.height / maxMultiple;

      // image caption
      let imgCaption = this.story.pictures[i].image_description;
      if (imgCaption) {
        imgCaption = imgCaption.replace(/\n/g, ' ');
        imgCaptionHeight = imgCaption.length > 30 ? 0.3 : 0.15;
        slide.addText(
          [
            { text: imgCaption, options: { fontSize: 12, fontFace: 'Graphik', color: '000000' } }
          ],
          { x: 10.05, y: 1.89 + offsetY, w: 3.2, h: imgCaptionHeight, valign: 'top', align: 'left', lineSpacingMultiple: 0.8, margin: [0, 0, 0, 0] }
        );
      } else {
        imgCaptionHeight = 0;
      }
      // image
      slide.addImage({ data: image.src, x: 10.05, y: 1.89 + imgCaptionHeight + offsetY, h: imgTargetHeight, w: imgTargetWidth });
      previousCaptionAndImgHeight = imgTargetHeight + imgCaptionHeight;
    }
  }

  _getIconShapeInfo(): void {
    this.loadIcon++;
    this.services.storiesService.getIconShapeInfo()
      .subscribe((response) => {
        this.loadIcon--;
        if (response && response.result) {
          this.iconShapeInfo = response.result;
        }
      });
  }

  private setIcons(slide: pptxgen.Slide) {
    const iconAreaMaxWidth = 6.45;
    const iconHorizontalSpacing = Number(this.iconShapeInfo.iconHorizontalSpacing);
    const heightAssumedMaximum = Number(this.iconShapeInfo.iconHeightAssumedMaximum);
    const widthAssumedMinimum = Number(this.iconShapeInfo.iconWidthAssumedMinimum);
    let iconTargetWidth = 0;
    let iconTargetHeight = 0;
    let previousIconWidth = 0;
    let iconTotalWidth = 0;
    let assumedMaximumRatio = 0;
    let widthAssumedMaximum = 0;
    let lessThanNormalWidth = 0;
    let offsetX = 0;
    for (let i = 0; i < this.story.icons?.length; i++) {
      const icon = new Image();
      icon.src = this.getDataStoryImage(this.story.icons[i], 'story');
      assumedMaximumRatio = icon.height / heightAssumedMaximum;
      widthAssumedMaximum = icon.width / assumedMaximumRatio;
      if (widthAssumedMaximum < widthAssumedMinimum) {
        lessThanNormalWidth += widthAssumedMaximum;
      }
      iconTotalWidth += widthAssumedMaximum;
    }
    for (let i = 0; i < this.story.icons?.length; i++) {
      offsetX += i > 0 ? iconHorizontalSpacing + previousIconWidth : 0;
      const icon = new Image();
      icon.src = this.getDataStoryImage(this.story.icons[i], 'story');
      const totalHorizontalSpacing = (this.story.icons?.length - 1) * iconHorizontalSpacing;
      const widthRatio = (iconTotalWidth - lessThanNormalWidth) / (iconAreaMaxWidth - totalHorizontalSpacing - lessThanNormalWidth);
      assumedMaximumRatio = icon.height / heightAssumedMaximum;
      widthAssumedMaximum = icon.width / assumedMaximumRatio;
      iconTargetHeight = heightAssumedMaximum / widthRatio;
      if (iconTargetHeight > heightAssumedMaximum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      } else {
        iconTargetWidth = widthAssumedMaximum / widthRatio;
      }
      if (widthAssumedMaximum < widthAssumedMinimum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      }
      slide.addImage({ data: icon.src, x: 0.42 + offsetX, y: 6.2, h: iconTargetHeight, w: iconTargetWidth });
      previousIconWidth = iconTargetWidth;
    }
  }

  /**
   * set slide master
   * @param pptx pptx
   */
  private setSlideMaster(pptx: pptxgen) {
    pptx.defineSlideMaster({
      title: 'MASTER_SLIDE',
      objects: [
        {
          text: {
            options: {
              shape: pptx.ShapeType.line, x: 0.42, y: 5.97, w: 9.49, h: 0,
              line: { color: '222435', width: 0.5, dashType: 'solid' },
            },
          },
        },
        { image: { path: 'assets/images/footer.png', x: 0.42, y: 7.09, h: 0.22, w: 0.21 } },
        {
          text: {
            options: { x: 0.78, y: 7.09, fontSize: 8, fontFace: 'Graphik (Body)', color: '000000', h: 0.22, w: 1.26, margin: [0, 0, 0, 0] },
            text: 'Internal Use Only',
          },
        },
        {
          text: {
            options: { x: 9.1, y: 7.09, fontSize: 8, fontFace: 'Graphik', align: 'right', color: '000000', h: 0.22, w: 3.4, margin: [0, 0, 0, 0] },
            text: 'Copyright © 2023 Accenture. All rights reserved.',
          },
        }
      ]
    });
  }

  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  }

  checkForce() {
    if (this.story.force?.name) {
      if (this.story.force.name == 'Not Applicable') {
        this.isForce = true;
      }
    }
  }

  checkNested() {
    if (this.story.nested_themes?.name) {
      if (this.story.nested_themes.name == 'Not Applicable') {
        this.isNested = true;
      }
    }
  }

  checkOrgType() {
    if (this.story.organization_type) {
      this.isLabs = this.story.organization_type.name == OrgType.LABS ? true : false;
      this.isLiquidStudio = this.story.organization_type.name == OrgType.LIQUID ? true : false;
      this.isIncubation = this.story.organization_type.name == OrgType.INCUBATION ? true : false;
    }
  }

  get WorkflowStatus(): any { return WorkflowStatus; }

  onBack() {
    const previousUrl = this.services.routerExtService.getPreviousUrl();
    if (previousUrl.includes('/search/view/story/')) {
      this.router.navigate(['/home']);
    } else if (previousUrl.includes('/stories/view/')) {
      this.router.navigate(['/stories/list/live']);
    } else {
      this.location.back();
    }
  }

  isEdited() {
    if (this.story && this.story.publish_approve_workflow) {
      const last_published = new Date(this.story.publish_approve_workflow.updated).getTime();
      const last_updated = new Date(this.story.last_updated_date).getTime();
      if (last_published < last_updated) {
        return true;
      }
    }
    return false;
  }

  getDataStoryImage(data: any, type): string {
    if (data && data.image_url) {
      const dataId = data._id;
      if (this.dataImages[dataId] && this.dataImages[dataId] != 'loading') {
        return this.dataImages[dataId];
      } else if (this.dataImages[dataId] != 'loading') {
        this.dataImages[dataId] = 'loading';
        this.services.masterService.getImage(data.image_url, type, this.isForList, this.relatedDelThumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[dataId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }

  /**
   * check if there is any image is loading
   * @returns true: has image loding
   */
  hasImageLoading() {
    const imageIds = Object.keys(this.dataImages);
    return imageIds.some((imageId) => this.dataImages[imageId] === 'loading');
  }

  getDeliverableImage(data: any): string {
    if (data && data.image_link) {
      const dataId = data._id;
      if (this.deliverableImages[dataId] && this.deliverableImages[dataId] != 'loading') {
        return this.deliverableImages[dataId];
      } else if (this.deliverableImages[dataId] != 'loading') {
        this.deliverableImages[dataId] = 'loading';
        this.services.masterService.getImage(dataId, 'deliverable', this.isForList, this.relatedDelThumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.deliverableImages[dataId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }
  getProfileImage(userId: any): string {
    return this.services.storyCommonService.getProfileImage(this, userId);
  }

  canSendReminder(story) {
    if (story.workflow_status == WorkflowStatus.REVIEW_REQUESTED && story.canEdit && !story.canApprove)
      return true;
    else return false;
  }
  onRequestPublish(): void {
    this.services.storyCommonService.openDialog(this, StoryPublishRequestDialog, this.story, '478px', Messages.STORY_PUBLISH_REQUESTED_SUCCESS);
  }
  onApprovePublish(): void {
    this.services.storyCommonService.openDialog(this, StoryPublishDialog, this.story, '478px', Messages.STORY_PUBLISH_SUCCESS);
  }
  onRework(): void {
    this.services.storyCommonService.openDialog(this, ReworkStoryDialog, this.story, '538px', Messages.STORY_REASSIGN_SUCCESS);
  }

  onViewComments(): void {
    this.dialog.open(StoryCommentsDialog, {
      width: '538px',
      height: 'auto',
      maxHeight: '95vh',
      data: this.comments
    });
  }
  onSendReminder(): void {
    this.loading++;
    this.services.deliverableService.sendReminder(this.storyId)
      .subscribe((res) => {
        this.loading--;
        if (res) {
          this.services.commonService.openSnackBar('success', Messages.REMINDER_SUCCESS);
        }
      });
  }
  calculateIcons(data) {
    if (this.isLoadingIcon) {
      return this.iconsArr;
    }
    const loadImg = [];
    let iconAreaMaxWidth = 0;
    const iconAreaMax = document.getElementById('iconArea');
    iconAreaMaxWidth = iconAreaMax?.clientWidth;
    const iconsArr = [];
    const tempImages = [];
    let iconTargetWidth = 0;
    let iconTargetHeight = 0;
    const iconHorizontalSpacing = 5;
    let iconTotalWidth = 0;
    let assumedMaximumRatio = 0;
    let widthAssumedMaximum = 0;
    const heightAssumedMaximum = 39;
    const widthAssumedMinimum = 45;
    let lessThanNormalWidth = 0;
    for (let i = 0; i < data.length; i++) {
      const icon = new Image();
      icon.src = this.getDataStoryImage(data[i], 'story');
      const id = data[i]._id;
      if (this.dataImages[id] === 'loading' || icon.height == 0 && icon.width == 0) {
        loadImg.push(data[i]);
      }
      assumedMaximumRatio = icon.height / heightAssumedMaximum;
      widthAssumedMaximum = icon.width / assumedMaximumRatio;
      if (widthAssumedMaximum < widthAssumedMinimum) {
        lessThanNormalWidth += widthAssumedMaximum;
      }
      iconTotalWidth += widthAssumedMaximum;
      tempImages.push(icon);
    }
    for (let i = 0; i < tempImages.length; i++) {
      const item = tempImages[i];
      const totalHorizontalSpacing = data.length * iconHorizontalSpacing;
      const widthRatio = (iconTotalWidth - lessThanNormalWidth) / (iconAreaMaxWidth - totalHorizontalSpacing - lessThanNormalWidth);
      assumedMaximumRatio = item.height / heightAssumedMaximum;
      widthAssumedMaximum = item.width / assumedMaximumRatio;
      iconTargetHeight = heightAssumedMaximum / widthRatio;
      if (iconTargetHeight > heightAssumedMaximum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      } else {
        iconTargetWidth = widthAssumedMaximum / widthRatio;
      }
      if (widthAssumedMaximum < widthAssumedMinimum) {
        iconTargetHeight = heightAssumedMaximum;
        iconTargetWidth = widthAssumedMaximum;
      }
      iconsArr.push({ data: item.src, height: iconTargetHeight, width: iconTargetWidth });
    }
    if (!this.isRefreshIcon && loadImg.length === 0) {
      this.isLoadingIcon = true;
      this.isRefreshIcon = false;
    } else {
      this.isLoadingIcon = false;
      this.isRefreshIcon = true;
    }
    return iconsArr;
  }
}

/**
 * reconcile paragraphs
 * @param tmpTextArray array
 */
function reconcileParagraphs(tmpTextArray: any[], denominatorForPpt) {
  let countOfConsecutiveEmpyLine = 0;
  let previousText = null;
  const indexsOfsmallLineHeight = [];
  for (let index = 0; index < tmpTextArray.length; index++) {
    const item = tmpTextArray[index];
    if (item.text.trim() === '') {
      if (previousText === '') {
        countOfConsecutiveEmpyLine++;
        if (countOfConsecutiveEmpyLine > 1) {
          indexsOfsmallLineHeight.push(index);
        }
      } else {
        // do nothing
      }
    } else {
      countOfConsecutiveEmpyLine = 0;
    }
    previousText = item.text.trim();
  }
  indexsOfsmallLineHeight.forEach((smallIndex) => {
    tmpTextArray[smallIndex].options.lineSpacingMultiple = 1 / denominatorForPpt;
    if (tmpTextArray[smallIndex + 1]?.text.trim()) {
      tmpTextArray[smallIndex].options.breakLine = true;
    }
  });
}

