/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { WorkflowStatus, Messages } from '@app/models';
import { Services } from '@app/services/services';
import { ProjectApproveDialog, ProjectReassignDialog } from './project-reassign-dialog.components';
import { AdvanceStageDialog, ProjectReviewRequestDialog } from '@app/projects/project-dialog.components';
@Component({
  selector: 'app-projects-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectsViewComponent implements OnInit {
  loading: any = 0;
  routerWatcher: any;
  projectId: any;
  project: any = null;
  deliverableImages: any = {};
  projectImages: any = {};
  profileImages: any = {};
  loginUser: any = null;
  data: any = {
    advanceStage: null,
    project: null
  };
  stageInfoName: any = [];
  advanceStage: any = null
  constructor(
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public services: Services
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.services.commonService.showHeader.emit(true);
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.projectId = params.project_id;
      this.getProject();
    });
  }

  get WorkflowStatus(): any { return WorkflowStatus; }

  getProject() {
    this.loading++;
    this.services.projectsService.getProject(this.projectId)
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.project = response.result;
          this.project = { ...response.result, ...response.metadata };
          this.project.stage_number = 1;
          if (this.project.project_stage && this.project.project_stage.phase) {
            this.project.stage_number = parseInt(this.project.project_stage.phase);
          }
          this.stageInfo(this.project.stageInfo);
          this.duplicateRemoval();
        }
      });
  }

  stageInfo(stageInfo) {
    this.stageInfoName = stageInfo.map((o) => o.name);
  }

  onReviewRequest(): void {
    this.services.projectsCommonService.openDialog(this, ProjectReviewRequestDialog, this.project, '538px', Messages.PROJECT_REVIEW_REQUESTED_SUCCESS, null, true);
  }

  duplicateRemoval() {
    this.project.other_groups = this.project.other_groups.filter((o) => o._id != this.project.primary_group._id);
    this.project.other_workstreams = this.project.other_workstreams.filter((o) => o._id != this.project.primary_workstream._id);
    this.project.other_locations = this.project.other_locations.filter((o) => o._id != this.project.primary_location._id);
  }

  onBack() {
    const previousUrl = this.services.routerExtService.getPreviousUrl();
    if (previousUrl.includes('/search/view/project/')) {
      this.router.navigate(['/home']);
    } else if (previousUrl.includes('/projects/view/')) {
      this.router.navigate(['/projects/list/live']);
    } else {
      this.location.back();
    }
  }

  getProjectImage(project: any): string {
    if (project && project.image_link) {
      const projectId = project._id;
      if (this.projectImages[projectId] && this.projectImages[projectId] != 'loading') {
        return this.projectImages[projectId];
      } else if (this.projectImages[projectId] != 'loading') {
        this.projectImages[projectId] = 'loading';
        this.services.masterService.getImage(projectId, 'project')
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.projectImages[projectId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.projectsCommonService.defaultProjectImage;
  }

  getDeliverableImage(deliverable: any): string {
    if (deliverable && deliverable.image_link) {
      const deliverableId = deliverable._id;
      if (this.deliverableImages[deliverableId] && this.deliverableImages[deliverableId] != 'loading') {
        return this.deliverableImages[deliverableId];
      } else if (this.deliverableImages[deliverableId] != 'loading') {
        this.deliverableImages[deliverableId] = 'loading';
        this.services.masterService.getImage(deliverableId)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.deliverableImages[deliverableId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }

  getProfileImage(userId: any): string {
    return this.services.projectsCommonService.getProfileImage(this, userId);
  }


  onAdvanceStage(): void {
    this.loading++;
    this.services.projectsService.checkAdvanceStage(this.project._id)
      .subscribe((response) => {
        this.loading--;
        if (response) {
          this.data.advanceStage = response.result;
          this.data.project = this.project;
          this.services.projectsCommonService.openDialog(this, AdvanceStageDialog, this.data, '624px', Messages.ADVANCE_STAGE_SUCCESS);
        }
      });
  }

  refresh() {
    this.getProject();
  }

  onReassign(): void {
    this.services.projectsCommonService.openDialog(this, ProjectReassignDialog, this.project, '538px', Messages.PROJECT_REASSIGN_SUCCESS, null, true);
  }
  onApprove(): void {
    this.services.projectsCommonService.openDialog(this, ProjectApproveDialog, this.project, '478px', Messages.PROJECT_APPROVE_SUCCESS, null, true);
  }


  isEdited() {
    if (this.project && this.project.workflow) {
      const workflow_updated = new Date(this.project.workflow.updated).getTime();
      const last_updated = new Date(this.project.last_updated_date).getTime();
      if (workflow_updated < last_updated) {
        return true;
      }
    }
    return false;
  }

  canSendReminder(project) {
    if (project.workflow_status == WorkflowStatus.REVIEW_REQUESTED && project.canEdit && !project.canApprove)
      return true;
    else return false;
  }

  onSendReminder(): void {
    this.loading++;
    this.services.projectsService.sendReminder(this.projectId)
      .subscribe((res) => {
        this.loading--;
        if (res) {
          this.services.commonService.openSnackBar('success', Messages.REMINDER_SUCCESS);
        }
      });
  }
}
