/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, delay, retry } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { GroupService } from '../group.service';
@Injectable({
  providedIn: 'root'
})
export class GroupApiService implements GroupService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getGroups(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/groups`, this.commonService.httpOptions(params));
  }
  addGroup(reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/groups`, reqbody, this.commonService.httpOptions());
  }
  editGroup(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/groups/update/${params}`, reqBody, this.commonService.httpOptions())
    .pipe(
      map(this.commonService.extractData),
      map((res: any) => {
        return res;
      }),
      retry(environment.MAX_RETRY_COUNT),
      delay(environment.RETRY_INTERVAL),
      catchError((error: any) => {
        return this.commonService.handleError(error);
      })
    );
  }
  updateGroupStatus(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/groups/archiveind`, reqBody, this.commonService.httpOptions(params));
  }
  groupFileDownload(deliverableIds: any): Observable<any> {
    // this.commonService.log('POST:', `/deliverables/review/sendreminder/${deliverableId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/exports/relateddata/download`, deliverableIds, {...this.commonService.httpOptions(), responseType: 'blob', observe: 'response' });
  }
}
