/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { CommonService } from '../common.service';
import { ReportingService } from '../reporting.service';

@Injectable()
export class ReportingMockService implements ReportingService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/list/reporting', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/quarters.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getStoryQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/report/story', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/quarters.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverableTypeByMetrics(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverabletypes/groupbymetrices', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get('/assets/json/demo/deliverabletypebymetrics.json', this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverables(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/search', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/deliverables.json', this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          const result = res;
          return {
            deliverables: result.slice(parameters.page_number * parameters.page_size, parameters.page_number * parameters.page_size + parameters.page_size),
            total: result.length
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  /**
   * get publications data
   * @param parameters parameters
   * @param formData form data
   */
  getPublicationsList(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/search', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/publications_origin_data.json', this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  export(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/deliverables/reporting/export', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return of({});
  }

  getSettings(): Observable<any> {
    this.commonService.log('GET:', '/reports/settings', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/reporting_settings.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  deleteSetting(settingId: any): Observable<any> {
    this.commonService.log('DELETE:', `/reports/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return of(null);
  }

  getSetting(settingId: any): Observable<any> {
    this.commonService.log('GET:', `/reports/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/reporting_settings.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.find((x) => x._id === parseInt(settingId, 10)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  saveSetting(formData: any): Observable<any> {
    this.commonService.log('POST:', '/reports/settings', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ formData });
  }

  updateSetting(settingId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/reports/settings/${settingId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ formData });
  }

  downloadPdf(parameters: any, htmlText: any): Observable<any> {
    return of({ parameters, htmlText });
  }
  
}
