/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input } from '@angular/core';
import { Services } from '@app/services/services';
import { Animations } from '@app/app-animations';
@Component({
  selector: 'app-common-echarts',
  outputs: ['onChange'],
  templateUrl: './common-echarts.component.html',
  styleUrls: ['./common-echarts.component.scss'],
  animations: [
    Animations.slideInOut
  ]
})
export class CommonEchartsComponent {
  optionsArr: any = [];
  @Input('showLoading') showLoading: any;
  constructor(public services: Services) { }

  showBarForDeliverableType(dataSource): void {
    const dataList = dataSource.metric;
    this.optionsArr = [];
    if (dataList && dataList.length) {
      dataList.forEach((metricItem) => {
        let dimensionsArr = [];
        let sourceArr = [];
        const seriesArr = [];

        // dimensionsArr
        const delTypeNamesArr = [];
        let fyQtrsSet = [];
        fyQtrsSet = this.calculateDimensions(metricItem, delTypeNamesArr, fyQtrsSet);
        dimensionsArr.push('FYQTR');
        dimensionsArr = dimensionsArr.concat(delTypeNamesArr);
        // sourceArr
        sourceArr = this.getSourceOfDataset(fyQtrsSet, delTypeNamesArr, metricItem);

        // seriesArr
        for (let i = 0; i < delTypeNamesArr.length; i++) {
          seriesArr.push({
            type: 'bar',
            barMaxWidth: '15',
            barGap: '10%',
          });
        }

        // set option
        const option = {
          tooltip: {},
          title: {
            text: metricItem.name,
            top: '10%',
            left: '30%',
            textStyle: {
              fontWeight: 'normal',
              fontSize: 14
            },
          },
          dataset: {
            dimensions: dimensionsArr,
            source: sourceArr
          },
          legend: {
            show: true,
            type: 'plain',
            left: '70%',
            orient: 'vertical',
            top: '10%',
            selectedMode: false,
            textStyle: {
              width: 120,
              overflow: 'break'
            },
            tooltip: {
              show: true
            }
          },
          grid: {
            left: 0,
            // top: 70,
            right: '30%',
            bottom: 0,
            containLabel: true
          },
          xAxis: {
            show: true,
            type: 'category',
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              // rotate:-30,
            }
          },
          yAxis: {
            show: true,
            splitLine: {
              show: true,
              color: '#fff',
              type: 'dotted',
            },
            type: 'value',
            minInterval: 5
          },
          series: seriesArr
        };
        this.optionsArr.push(option);
      });
    }
  }

  /**
   * get source of dataset
   * @param fyQtrsSet FY Quarter set
   * @param delTypeNamesArr deliverable type name array
   * @param metricItem metric item
   * @returns source of dataset
   */
  getSourceOfDataset(fyQtrsSet: any[], delTypeNamesArr: any[], metricItem: any) {
    const sourceArr = [];
    fyQtrsSet.forEach((fyqtrItem) => {
      const sourceItem = { FYQTR: fyqtrItem };
      delTypeNamesArr.forEach((val) => {
        const results = metricItem.detail.filter((o) => o.deliverable_type_name === val);
        results.forEach((result) => {
          const res = result.qtrs.filter((o) => 'FY' + (o.eac_qrtr.year - 2000) + 'Q' + o.eac_qrtr.qtr === fyqtrItem);
          if (res.length > 0) {
            sourceItem[val] = res[0].total;
          }
        });
      });
      sourceArr.push(sourceItem);
    });
    return sourceArr;
  }

  /**
   * calculate dimensions
   * @param metricItem metric item
   * @param delTypeNamesArr deliverable type names
   * @param fyQtrsSet FY and Quarter
   */
  calculateDimensions(metricItem: any, delTypeNamesArr: any[], fyQtrsSet: any[]) {
    metricItem.detail.forEach((detailItem) => {
      delTypeNamesArr.push(detailItem.deliverable_type_name);
      detailItem.qtrs.forEach((o) => {
        fyQtrsSet.push('FY' + (o.eac_qrtr.year - 2000) + 'Q' + o.eac_qrtr.qtr);
      });
    });
    fyQtrsSet = Array.from(new Set(fyQtrsSet)).sort();
    return fyQtrsSet;
  }
}
