<div fxFlex fxLayout="column" class="edit-deliverable" *ngIf="deliverableForm && dataProcessing">
  <div role="form" aria-labelledby="formHeading" aria-describedby="requiredFieldNote">
  <h2 id="isNew">{{isNew ? createOrgTitle : editOrgTitle}}</h2>
  <p id="indicatesRequiredField"><span>*</span> indicates required field</p>
  </div>
  <form #validForm fxLayout="column" [formGroup]="deliverableForm">
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50">
        <div class="form-group"
          [ngClass]="{'error': (draftSubmitted || submitted) && deliverableForm.get('name').invalid }">
          <label id="lblDeliverableName">Deliverable Name<span>*</span></label>
          <input id="deliverableName" type="text" class="form-control" formControlName="name" aria-label="Deliverable Name"
            maxlength="300" />
          <p id="deliverableNameLength" class="hintMessage">{{deliverableName ? deliverableName.length :0}}/300</p>
        </div>
      </div>
      <div fxFlex="50">
        <div fxLayout="row" fxLayoutGap="22px">
          <div fxFlex class="form-group"
            [ngClass]="{'error': submitted && deliverableForm.get('deliverable_type').invalid }">
            <label  for="drpDeliverableType" id="lblDeliverableType">Type Of Deliverable<span>*</span></label>
            <mat-select id="drpDeliverableType" formControlName="deliverable_type" [compareWith]="onCompareObjects" aria-labelledby="lblDeliverableType">
              <mat-option [value]="null"></mat-option>
              <mat-option id="optDeliverableType{{i}}" *ngFor="let option of options.deliverable_type;let i=index"
                [value]="option" (click)="filterSelectPartners()">{{option.name}}</mat-option>
            </mat-select>
            <span id="deliverableTypeDesc"
              *ngIf="deliverableForm.get('deliverable_type').value && deliverableForm.get('deliverable_type').value.description"
              class="hint"
              [matTooltip]="deliverableForm.get('deliverable_type').value.description">{{deliverableForm.get('deliverable_type').value.description}}</span>
          </div>
          <div fxFlex class="form-group" style="width: 155px;"
            [ngClass]="{'error': submitted && deliverableForm.get('idf_number').invalid }">
            <label id="lblIdfNumber">IDF Number</label>
            <input id="idfNum" type="text" class="form-control" formControlName="idf_number" aria-label="idfNum"
              maxlength="20" />
          </div>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50" fxLayout="column">
        <div class="form-group" [ngClass]="{'error': submitted && deliverableForm.get('short_description').invalid }">
          <label id="lblShortDescription">Short Description<span>*</span></label>
          <textarea id="shortDescription" rows="3" class="form-control short" formControlName="short_description"
            maxlength="150" aria-label="shortDescription"></textarea>
          <p id="shortDescriptionLength" class="hintMessage">{{shortDesc ? shortDesc.length :0}}/150</p>
        </div>
        <div class="form-group" *ngIf="!isLiquidStudio">
          <label for="drpRelevantProjects" id="lblRelevantProjects">Relevant Projects</label>
          <mat-select id="project" formControlName="project" [compareWith]="onCompareObjects" aria-labelledby="lblRelevantProjects">
            <mat-option [value]="null"></mat-option>
            <mat-option id="optProject{{i}}" *ngFor="let option of options.project;let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-select>
        </div>
        <div class="form-group"
          [ngClass]="{'error': (incomplete && imgURL == services.deliverableCommonService.defaultDeliverableImage) || (submitted && maxFileSize) }">
          <label id="lblDeliverableImage">Deliverable Image (Required to Publish)</label>
          <input id="deliverable_image" type="file" #file class="form-control" (change)="getImagePreview(file.files)"
            [ngClass]="{'incomplete': incomplete && imgURL == services.deliverableCommonService.defaultDeliverableImage, 'ng-invalid': submitted && maxFileSize}" />
          <div class="image_preview">
            <div *ngIf="imgURL" [style.background-image]="'url(' + imgURL  + ')'" class="image_preview_inner">
            </div>
            <label id="lblReplacePhoto" for="deliverable_image" class="label-btn" tabindex="0" (keydown.enter)="replacePhoto()"
              *ngIf="!deliverableForm.get('image').disabled"><span class="icon ic-file"></span>Replace Photo</label>
          </div>
          <span id="deliverableImage_text_hint" *ngIf="maxFileSize" class="hint error"
            [matTooltip]="Messages.FILE_LIMIT_MESSAGE" style="display: block;">{{Messages.FILE_LIMIT_MESSAGE}}</span>
        </div>
      </div>
      <div fxFlex="50" fxLayout="column">
        <div fxFlex="50" *ngIf="!hidePartners" class="form-group">
          <label id="lblIndustry">Partners</label>
          <input id="partners" type="text" class="form-control" [matAutocomplete]="partners"
            [disabled]="deliverableForm.get('partners').disabled" aria-label="partners" aria-describedby="keywords_text_hint"/>
          <mat-autocomplete id="partner_auto" (optionSelected)="onAddMultipleSelectValue('partners', $event)" role="combobox"
            #partners="matAutocomplete">
            <mat-option id="partners_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('partners');let i=index" [value]="option">{{option.name}}
            </mat-option>
          </mat-autocomplete>
          <span id="keywords_text_hint" *ngIf="!getMultipleSelectValues('partners')?.length" class="hint"
            [matTooltip]="Messages.PARTNER_HINT">{{Messages.PARTNER_HINT}}</span>
          <mat-chip-set id="partners_list">
            <mat-chip id="partners_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('partners');let i=index"
              (removed)="onRemoveMultipleSelectValue('partners', option)">
              {{option.name}}
              <span id="partners_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('partners').disabled"><i
                  class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div class="form-group client">
          <label id="lblClient">Client</label>
          <input id="client" type="text" class="form-control" [matAutocomplete]="client" value="{{showAllClientInfo}}"
            (keyup)="onChangeClient($event)" aria-label="client" aria-describedby="client_dropdown_hint" />
          <mat-progress-bar *ngIf="loadClient" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="client_auto" #client="matAutocomplete" role="combobox"
            (optionSelected)="onAddClientValue('client', $event)">
            <mat-option id="client_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('client','customer_number,customer_name',true);let i=index"
              [value]="option">{{option.customer_number}} - {{option.customer_name}} - {{option.country_key}}
            </mat-option>
          </mat-autocomplete>
          <span id="client_dropdown_hint" class="hint"
            [matTooltip]="Messages.CLIENT_HINT">{{Messages.CLIENT_HINT}}</span>
        </div>
        <div class="form-group" [ngClass]="{'error': incomplete && !deliverableForm.get('long_description').value }">
          <label id="lblLongDescription">Long Description (Required to Publish)</label>
          <textarea id="longDescription" class="form-control" formControlName="long_description"
            [ngClass]="{'incomplete': incomplete && !deliverableForm.get('long_description').value }"
            aria-label="longDescription" maxlength="5000"></textarea>
          <p id="longDescriptionLength" class="hintMessage">{{longDesc ? longDesc.length :0}}/5000</p>
        </div>
        <div class="form-group"
          [ngClass]="{'error': (incomplete && !deliverableForm.get('url').value.length) || (submitted && deliverableForm.get('url').invalid) }">
          <label id="lblURL">URL (Required to Publish)</label>
          <div fxLayout="row" fxLayoutGap="10px" style="width: 100%;">
            <input id="url_text" fxFlex type="text" class="form-control" (keyup.enter)="onAddUrl()" (blur)="onAddUrl()"
              [disabled]="deliverableForm.get('url').disabled"
              [ngClass]="{'incomplete': incomplete && !deliverableForm.get('url').value.length }" aria-label="url_text"
              maxlength="1000" />
            <button id="addUrl" type="button" mat-stroked-button class="add-btn" color="accent" (click)="onAddUrl()"
              [disabled]="deliverableForm.get('url').disabled">ADD</button>
          </div>
          <span id="url_text_hint" class="hint error" role="alert"  [attr.aria-invalid]="Messages.URL_ERROR  ? 'true' : 'false'" [matTooltip]="Messages.URL_ERROR">{{Messages.URL_ERROR}}</span> 
          <mat-chip-set id="url_list">
            <mat-chip id="url_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('url');let i=index" (removed)="onRemoveUrl(option)">
              {{option}}
              <span id="url_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('url').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="owner_wrap">
      <div fxLayout="row" fxLayoutGap="30px">
        <div fxFlex="50" class="form-group"
          [ngClass]="{'error': (draftSubmitted || submitted) && deliverableForm.get('owner').invalid }">
          <label id="lblOwner">Owner<span>*</span></label>
          <input id="owner" type="text" class="form-control" [matAutocomplete]="owner"
            [disabled]="deliverableForm.get('owner').disabled"
            [value]="deliverableForm.get('owner').value?deliverableForm.get('owner').value.display_name:''"
            (keyup)="onChangeOwner($event)" aria-label="owner" aria-describedby="owner_text_hint"/>
          <mat-progress-bar *ngIf="loadOwner" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="owner_auto" #owner="matAutocomplete" role="combobox"
            (optionSelected)="onAddSelectValue('owner', $event)">
            <mat-option id="owner_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('owner','email_id', true);let i=index" [value]="option">
              {{option.display_name}}</mat-option>
          </mat-autocomplete>
          <span id="owner_text_hint" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
        </div>
        <div fxFlex="50" class="form-group">
          <label id="lblTeamMembers">ADDITIONAL TEAM MEMBERS</label>
          <input id="team_members" type="text" class="form-control" [matAutocomplete]="team_members"
            [disabled]="deliverableForm.get('team_members').disabled" (keyup)="onChangeTeamMembers($event)"
            aria-label=" Additional Teammembers" aria-describedby="team_members_hint"/>
          <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="team_members_auto" #team_members="matAutocomplete" role="combobox"
            (optionSelected)="onAddMultipleSelectValue('team_members', $event, false, 'eso_id')">
            <mat-option id="team_members_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('team_members','email_id', true);let i=index"
              [value]="option">{{option.display_name}}</mat-option>
          </mat-autocomplete>
          <span id="team_members_hint" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
          <mat-chip-set id="team_members_list">
            <mat-chip id="team_members_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('team_members');let i=index"
              (removed)="onRemoveMultipleSelectValue('team_members', option, 'eso_id')">
              {{option.display_name}}
              <span id="team_members_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('team_members').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName !== 'Liquid Studio'">
        <div fxFlex="50" class="form-group"
          [ngClass]="{'error': submitted && deliverableForm.get('primary_group').invalid }">
          <label id="lblPrimaryGroup">Primary Group<span>*</span></label>
          <mat-select id="drpPrimaryGroup" formControlName="primary_group" [compareWith]="onCompareObjects" aria-labelledby="lblPrimaryGroup" >
            <mat-option [value]="null"></mat-option>
            <mat-option id="optPrimaryGroup{{i}}"
              *ngFor="let option of getMultipleSelectOptions('primary_group');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-select>
        </div>
        <div fxFlex="50" class="form-group">
          <label id="lblOtherGroups">Other Groups</label>
          <input id="other_groups" type="text" class="form-control" [matAutocomplete]="other_groups"
            [disabled]="deliverableForm.get('other_groups').disabled" aria-label="otherGroups" />
          <mat-autocomplete id="other_groups_auto" #other_groups="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('other_groups', $event)">
            <mat-option id="other_groups_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('other_groups');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="other_groups_list">
            <mat-chip id="other_groups_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('other_groups');let i=index"
              (removed)="onRemoveMultipleSelectValue('other_groups', option)">
              {{option.name}}
              <span id="other_groups_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('other_groups').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <mat-divider *ngIf="orgTypeName !== 'Liquid Studio'"></mat-divider>
      <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName == 'Labs'">
        <div fxFlex="50" class="form-group"
          [ngClass]="{'error': submitted && deliverableForm.get('primary_workstream').invalid }">
          <label id="lblPrimaryWorkstream">Primary Workstream<span>*</span></label>
          <mat-select id="drpPrimaryWorkstream" formControlName="primary_workstream" [compareWith]="onCompareObjects" aria-labelledby="lblPrimaryWorkstream">
            <mat-option [value]="null"></mat-option>
            <mat-option id="optPrimaryWorkstream{{i}}"
              *ngFor="let option of getMultipleSelectOptions('primary_workstream'); let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-select>
        </div>
        <div fxFlex="50" class="form-group">
          <label id="lblOtherWorkstream">Other Workstreams</label>
          <input id="other_workstreams" type="text" class="form-control" [matAutocomplete]="other_workstreams"
            [disabled]="deliverableForm.get('other_workstreams').disabled" aria-label="otherWorkstreams" />
          <mat-autocomplete id="other_workstreams_auto" #other_workstreams="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('other_workstreams', $event)">
            <mat-option id="other_workstreams_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('other_workstreams');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="other_workstreams_list">
            <mat-chip id="other_workstreams_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('other_workstreams');let i=index"
              (removed)="onRemoveMultipleSelectValue('other_workstreams', option)">
              {{option.name}}
              <span id="other_workstreams_delete{{i}}" matChipRemove
                *ngIf="!deliverableForm.get('other_workstreams').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <mat-divider *ngIf="orgTypeName == 'Labs'"></mat-divider>
      <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName !== 'Liquid Studio'">
        <div fxFlex="50" class="form-group"
          [ngClass]="{'error': submitted && deliverableForm.get('primary_location').invalid }">
          <label id="lblPrimaryLocation">Primary Location<span>*</span></label>
          <mat-select id="drpPrimaryLocation" formControlName="primary_location" [compareWith]="onCompareObjects" aria-label="Primary Location">
            <mat-option [value]="null"></mat-option>
            <mat-option id="optPrimaryLocation{{i}}"
              *ngFor="let option of getMultipleSelectOptions('primary_location');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-select>
        </div>
        <div fxFlex="50" class="form-group">
          <label id="lblOtherLocations">Other Locations</label>
          <input id="other_locations" type="text" class="form-control" [matAutocomplete]="other_locations"
            [disabled]="deliverableForm.get('other_locations').disabled" aria-label="otherLocations" />
          <mat-autocomplete id="other_locations_auto" #other_locations="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('other_locations', $event)">
            <mat-option id="other_locations_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('other_locations');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="other_locations_list">
            <mat-chip id="other_locations_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('other_locations');let i=index"
              (removed)="onRemoveMultipleSelectValue('other_locations', option)">
              {{option.name}}
              <span id="other_locations_delete{{i}}" matChipRemove
                *ngIf="!deliverableForm.get('other_locations').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <!-- Liquid Studio -->
      <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName == 'Liquid Studio'">
        <div fxFlex="50" class="form-group"
          [ngClass]="{'error': submitted && deliverableForm.get('primary_location').invalid }">
          <label id="lblPrimaryLocation">Primary Location<span>*</span></label>
          <mat-select id="drpPrimaryLocation" formControlName="primary_location" [compareWith]="onCompareObjects" aria-label="lblPrimaryLocation">
            <mat-option [value]="null"></mat-option>
            <mat-option id="optPrimaryLocation{{i}}" *ngFor="let option of locationList;let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-select>
        </div>
        <div fxFlex="50" class="form-group">
          <label id="lblOtherLocations">Other Locations</label>
          <input id="other_locations" type="text" class="form-control" [matAutocomplete]="other_locations"
            [disabled]="deliverableForm.get('primary_location').invalid" aria-label="otherLocations" />
          <mat-autocomplete id="other_locations_auto" #other_locations="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('other_locations', $event)">
            <mat-option id="other_locations_option{{i}}" *ngFor="let option of getOtherLocations();let i=index"
              [value]="option">{{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="other_locations_list">
            <mat-chip id="other_locations_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('other_locations');let i=index"
              (removed)="onRemoveMultipleSelectValue('other_locations', option)">
              {{option.name}}
              <span id="other_locations_delete{{i}}" matChipRemove
                *ngIf="!deliverableForm.get('other_locations').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div fxLayout="row" fxLayoutGap="30px">
        <div fxFlex="50" class="form-group">
          <label for="lblIndustry" id="lblIndustry">INDUSTRY</label>
          <input id="industries" type="text" class="form-control" [matAutocomplete]="industries"
            [disabled]="deliverableForm.get('industries').disabled" aria-label="indusries" />
          <mat-autocomplete id="industries_auto" #industries="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('industries', $event)">
            <mat-option id="industries_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('industries');let i=index" [value]="option">{{option.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-chip-set id="industries_list">
            <mat-chip id="industries_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('industries');let i=index"
              (removed)="onRemoveMultipleSelectValue('industries', option)">
              {{option.name}}
              <span id="industries_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('industries').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="50" class="form-group"
          [ngClass]="{'error': incomplete && !deliverableForm.get('keywords').value.length }">
          <label id="lblKeywords">Keywords (Required to Publish)</label>
          <input id="keywords" type="text" class="form-control" [matAutocomplete]="keywords"
            [disabled]="deliverableForm.get('keywords').disabled" (keyup)="onChangeKeyWord($event)"
            (keyup.enter)="onAddMultipleSelectValue('keywords', $event, true)"
            [ngClass]="{'incomplete': incomplete && !deliverableForm.get('keywords').value.length}"
            aria-label="keywords" maxlength="300" aria-describedby="keywords_text_hint1" />
          <mat-progress-bar *ngIf="loadKeywords" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="keywords_auto" #keywords="matAutocomplete" role="combobox"
            (optionSelected)="onAddMultipleSelectValue('keywords', $event, true)">
            <mat-option id="keywords_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('keywords', null, true);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <span id="keywords_text_hint1" *ngIf="!getMultipleSelectValues('keywords').length" class="hint"
            [matTooltip]="Messages.KEYWORD_HINT">{{Messages.KEYWORD_HINT}}</span>
          <mat-chip-set id="keywords_list">
            <mat-chip id="keywords_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('keywords');let i=index"
              (removed)="onRemoveMultipleSelectValue('keywords', option, null)">
              {{option}}
              <span id="keywords_delete{{i}}" matChipRemove *ngIf="!deliverableForm.get('keywords').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
    </div>
    <div fxLayout="column" class="project_wrap">
      <div fxLayout="row" fxLayoutGap="30px">
        <div fxFlex="50" class="form-group"
          [ngClass]="{'error': incomplete && deliverableForm.get('deliverable_status').value && !deliverableForm.get('deliverable_status').value.isComplete}">
          <label id="lblDeliverableStatus">Deliverable Status</label>
          <mat-select id="drpDeliverableStatus" formControlName="deliverable_status" [compareWith]="onCompareObjects" aria-label="DeliverableStatus"
            [ngClass]="{'disable_font': deliverableForm.get('deliverable_status').disabled }">
            <mat-option [value]="null"></mat-option>
            <mat-option id="optDeliverableStatus{{i}}" *ngFor="let option of options.deliverable_status;let i=index"
              [value]="option">{{option.name}}</mat-option>
          </mat-select>
        </div>
        <div fxFlex="50" class="form-group" *ngIf="!isLiquidStudio">
          <label id="lblInitiatives">Initiatives For Affiliation</label>
          <input id="initiatives" type="text" class="form-control" [matAutocomplete]="initiatives"
            [disabled]="deliverableForm.get('initiatives').disabled" aria-label="initiatives" />
          <mat-autocomplete id="initiatives_auto" #initiatives="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('initiatives', $event)">
            <mat-option id="initiatives_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('initiatives');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="initiatives_list">
            <mat-chip id="initiatives_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('initiatives');let i=index"
              (removed)="onRemoveMultipleSelectValue('initiatives', option)">
              {{option.name}}
              <span id="initiatives_delete{{i}}" matChipRemove
                *ngIf="canInitiativeAccess(option) && !deliverableForm.get('initiatives').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
        <div fxFlex="50" class="form-group" *ngIf="isLiquidStudio">
          <label id="lblRelatedDeliverables">Related Deliverables</label>
          <input id="related_deliverables" type="text" class="form-control" (focus)="getRelatedDeliverablesParam()"
            [matAutocomplete]="related_deliverables" [disabled]="deliverableForm.get('related_deliverables').disabled"
            aria-label="relatedDeliverables" />
          <mat-progress-bar *ngIf="loadRelatedDeliverables" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="related_deliverables_auto" #related_deliverables="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('related_deliverables', $event)">
            <mat-option id="related_deliverables_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('related_deliverables');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="related_deliverables_list">
            <mat-chip id="related_deliverables_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('related_deliverables');let i=index"
              (removed)="onRemoveMultipleSelectValue('related_deliverables', option)">
              {{option.name}}
              <span id="related_deliverables_delete{{i}}" matChipRemove
                *ngIf="!deliverableForm.get('related_deliverables').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="30px" *ngIf="!isLiquidStudio">
        <div fxFlex="50" fxLayout="row" fxLayoutGap="30px">
          <div fxFlex="50" class="form-group"
            [ngClass]="{'error': submitted && deliverableForm.get('eac_qrtr').invalid }">
            <label id="lblEndQTR">Quarter<span>*</span></label>
            <mat-select id="drpEndQrtr" formControlName="eac_qrtr" aria-label="Quarter">
              <mat-option [value]="null"></mat-option>
              <mat-option [value]="deliverableForm.get('eac_qrtr').value" disabled
                *ngIf="isValueNotFoundInOptions('eac_qrtr', 'qtr')">Q{{deliverableForm.get('eac_qrtr').value}}
              </mat-option>
              <mat-option id="optEndqtr{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr');let i=index"
                [value]="option.qtr">Q{{option.qtr}}</mat-option>
            </mat-select>
          </div>
          <div fxFlex="50" class="form-group"
            [ngClass]="{'error': submitted && deliverableForm.get('eac_qrtr_year').invalid }">
            <label id="lblEndYear">Fiscal Year<span>*</span></label>
            <mat-select id="drpEndQrtrYear" formControlName="eac_qrtr_year" aria-label="FiscalYear">
              <mat-option [value]="deliverableForm.get('eac_qrtr_year').value" disabled
                *ngIf="isValueNotFoundInOptions('eac_qrtr_year', 'year')">FY{{deliverableForm.get('eac_qrtr_year').value
                - 2000}}</mat-option>
              <mat-option id="optEndYear{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr_year'); let i=index"
                [value]="option.year">FY{{option.year-2000}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div fxFlex="50" class="form-group">
          <label FOR="lblRelatedDeliverables" id="lblRelatedDeliverables">Related Deliverables</label>
          <input id="related_deliverables" type="text" class="form-control" (focus)="getRelatedDeliverablesParam()"
            [matAutocomplete]="related_deliverables" [disabled]="deliverableForm.get('related_deliverables').disabled"
            aria-label="relatedDeliverables" />
          <mat-progress-bar *ngIf="loadRelatedDeliverables" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="related_deliverables_auto" #related_deliverables="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('related_deliverables', $event)">
            <mat-option id="related_deliverables_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('related_deliverables');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="related_deliverables_list">
            <mat-chip id="related_deliverables_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('related_deliverables');let i=index"
              (removed)="onRemoveMultipleSelectValue('related_deliverables', option)">
              {{option.name}}
              <span id="related_deliverables_delete{{i}}" matChipRemove
                *ngIf="!deliverableForm.get('related_deliverables').disabled">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="30px" *ngIf="isLiquidStudio">
        <div fxFlex="50" fxLayout="row" fxLayoutGap="30px">
          <div fxFlex="50" class="form-group"
            [ngClass]="{'error': submitted && deliverableForm.get('eac_qrtr').invalid }">
            <label for="lblEndQTR" id="lblEndQTR">Quarter<span>*</span></label>
            <mat-select id="drpEndQrtr" formControlName="eac_qrtr">
              <mat-option [value]="null"></mat-option>
              <mat-option [value]="deliverableForm.get('eac_qrtr').value" disabled
                *ngIf="isValueNotFoundInOptions('eac_qrtr', 'qtr')">Q{{deliverableForm.get('eac_qrtr').value}}
              </mat-option>
              <mat-option id="optEndqtr{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr');let i=index"
                [value]="option.qtr">Q{{option.qtr}}</mat-option>
            </mat-select>
          </div>
          <div fxFlex="50" class="form-group"
            [ngClass]="{'error': submitted && deliverableForm.get('eac_qrtr_year').invalid }">
            <label for="lblEndYear" id="lblEndYear">Fiscal Year<span>*</span></label>
            <mat-select id="drpEndQrtrYear" formControlName="eac_qrtr_year">
              <mat-option [value]="deliverableForm.get('eac_qrtr_year').value" disabled
                *ngIf="isValueNotFoundInOptions('eac_qrtr_year', 'year')">FY{{deliverableForm.get('eac_qrtr_year').value
                - 2000}}</mat-option>
              <mat-option id="optEndYear{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr_year'); let i=index"
                [value]="option.year">FY{{option.year-2000}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div fxFlex="50" fxLayout="row"></div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="pri-btn-edit-wrap">
      <button id="cancel" type="button" mat-button color="primary" (click)="onCancel()">Cancel</button>
      <button id="saveDraft" *ngIf="!isSaved" type="button" mat-stroked-button color="primary"
        (click)="onSaveDraft()">Save Draft</button>
      <button id="goLive" *ngIf="!isSaved" type="button" mat-flat-button color="primary" (click)="onSave()">Go
        Live</button>
      <button id="update" *ngIf="isSaved" type="button" mat-flat-button color="primary"
        (click)="onSave()">Update</button>
    </div>
  </form>
</div>