/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { OrganizationTypeService } from '@app/services/organization-type.service';
import { Router } from '@angular/router';
import { Services } from '@app/services/services';
import { QuickEditComponent } from '@app/reporting/quick-edit.component';
import { OrgType } from '@app/models';
@Component({
  selector: 'app-organization-type-popup',
  templateUrl: './organization-type-popup.component.html',
  // styleUrls: ['./organization-type-popup.component.scss']
})
export class OrganizationTypePopupComponent implements OnInit {
  route: string;
  loading: any = 0;
  orgTypes: Array<any> = [];
  orgTypeFrom: UntypedFormGroup;
  submitted: any = false;
  btnName: any = '';
  constructor(
    public dialogRef: MatDialogRef<OrganizationTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationTypeService: OrganizationTypeService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    public services: Services
  ) { }

  ngOnInit(): void {
    this._getOrganizationTypes();
    this.orgTypeFrom = this.formBuilder.group({
      OrgType: [null, Validators.required],
    });
  }

  _getOrganizationTypes(): void {
    this.loading++;
    this.organizationTypeService.getOrganizationTypes()
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          if (this.data === 'project') {
            this.orgTypes = response.result.filter((o) => o.name == OrgType.LABS);
          } else if (this.data === 'reporting') {
            this.orgTypes = response.result.filter((o) => o.name != OrgType.GENERAL 
            //Commenting this part to implement openInnovation org in reporting section. 
            // && o.name != OrgType.OPENINNOVATION
            );
          } else {
            this.orgTypes = response.result.filter((o) => o.name != OrgType.GENERAL);
          }
        }
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  onSaveOrgType(): void {
    const selectedOrgType = this.orgTypeFrom.get('OrgType').value;
    if (this.data === 'deliverable') {
      this.services.commonService.setSessionData('GET_DELIVERABLE_ORG', selectedOrgType);
      if (this.route && this.route !== '/deliverables/new') {
        this.router.navigate(['/deliverables/new']);
      } else {
        this.redirectTo('/deliverables/new');
      }
    } else if (this.data === 'project') {
      this.services.commonService.setSessionData('GET_PROJECT_ORG', selectedOrgType);
      if (this.route && this.route !== '/projects/new') {
        this.router.navigate(['/projects/new']);
      } else {
        this.redirectTo('/projects/new');
      }
    } else if (this.data === 'story') {
      this.services.commonService.setSessionData('GET_STORY_ORG', selectedOrgType);
      if (this.route && this.route !== '/stories/new') {
        this.router.navigate(['/stories/new']);
      } else {
        this.redirectTo('/stories/new');
      }
    } else if (this.data === 'reporting') {
      this.services.commonService.setSessionData('GET_QUICK_ORG', selectedOrgType);
      this.dialog.open(QuickEditComponent, {
        width: '1090px',
        height: '800px',
        maxHeight: '95vh',
        data: null
      });
    }
    this.dialogRef.close();
  }
  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }
}
