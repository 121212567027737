<mat-nav-list *ngIf="!isChildOf('Admin')">
    <a id="searchDeliverables" routerLink="/home" mat-list-item routerLinkActive="active">
        <span>Search</span>
    </a>
    <a id="myDeliverables" routerLink="/deliverables" mat-list-item routerLinkActive="active">
        <span>My Deliverables</span>
    </a>
    <a id="myProjects" routerLink="/projects" mat-list-item routerLinkActive="active">
        <span>My Projects</span>
    </a>
    <a id="myStories" *ngIf="isShowStory" routerLink="/stories" mat-list-item routerLinkActive="active">
        <span>My Success Stories</span>
    </a>
    <a id="reporting" *ngIf="services.commonService.isReportingOn()" routerLink="/reporting" mat-list-item
        routerLinkActive="active">
        <span>Reporting</span>
    </a>
    <mat-divider></mat-divider>
    <a id="help" mat-list-item target="_blank" [href]="Messages.HELP_REF">
        <span>Help</span>
    </a>
    <a id="contactUs" mat-list-item [href]="contactUsLink">
        <span>Contact Us</span>
    </a>
    <a id="signOut" mat-list-item routerLinkActive="active" (click)="onSignOut()" tabindex="0" (keydown.enter)="onSignOut()">
        <span>Sign Out</span>
    </a>
    
    
    <mat-divider></mat-divider>
    <a id="adminDashboardOne" mat-list-item
        *ngIf="services.commonService.hasAnyRole([Role.MANAGER_ADMINISTRATOR]) || services.commonService.hasAnyRole([Role.CONFIGURATION_ADMINISTRATOR])"
        routerLink="/Admin/General/admin-metrics-view" routerLinkActive="active">
        <span>Admin Dashboard</span>
    </a>
</mat-nav-list>

<mat-nav-list
    *ngIf="isChildOf('Admin') && services.commonService.hasAnyRole([Role.MANAGER_ADMINISTRATOR, Role.CONFIGURATION_ADMINISTRATOR]) ">
    <a id="back" mat-list-item routerLink="/deliverables">
        <span class="navigate-back">
            < Back to Main Site </span>
    </a>
    <div>
        <div class="total-menu" *ngFor="let accordion of accordionMenuList; let i = index">
            <a id="menuList{{i}}" mat-list-item routerLink="{{accordion.subMenu[0].href}}">
                <span class="orgtype">{{accordion.type}}</span>
            </a>
            <div *ngFor="let subMenu of accordion.subMenu; let j = index"
                style="margin-left: 15px;">
                <a id="subMenuList{{i}}{{j}}" mat-list-item routerLink="{{subMenu.href}}" routerLinkActive="active">
                    <span class="orgtype-submenu">{{subMenu.linkName}}</span>
                </a>
            </div>
        </div>
    </div>
</mat-nav-list>