export const richTextConfig = {
  plugins: [
    'autolink', 'lists', 'link',
    'code', 'fullscreen'
  ],
  menubar: false,
  branding: false,
  toolbar: 'undo redo | blocks | formatselect | link | ' +
    'bold italic forecolor | alignleft aligncenter ' +
    'alignright alignjustify | bullist numlist outdent indent | ' +
    'removeformat',
  statusbar: false,
  toolbar_mode: 'wrap',
  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px; } ' +
    '::-webkit-scrollbar { width: 5px; height: 5px; } ' +
    '::-webkit-scrollbar-track { background-color: transparent; } ' +
    '::-webkit-scrollbar-corner { background-color: transparent } ' +
    '::-webkit-scrollbar-thumb { background-color: #DCDCDC;border-radius: 12px; } ' +
    '::-webkit-scrollbar-thumb:hover { background-color: #C1C1C1; }'
};