<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm" class="add-dialog" role="dialog" aria-labelledby="titleLocationName">
  <div fxLayout="column" class="dialog-content">
      <div class="form-group" [ngClass]="{'error': submitted && showForm.get('locationName').invalid }">
          <p id="titleLocationName">Location Name</p>
          <input type="text" id="name" formControlName="locationName" class="form-control" placeholder="Location Name"  aria-label="Location name" required  maxlength="128" (keyup)="changeLocationValue(showForm.get('locationName').value)"/>
          <span id="locationNameLength" class="hint point">{{inputLocationName ? inputLocationName.length : 0}}/128</span>
        </div>
       <div class="form-group" [ngClass]="{'error': submitted && !(this.locationArray.length > 0)}">
          <p id="titleKM">Knowledge Managers</p>
          <input type="text" id="users" formControlName="locationLead" placeholder="Search People" class="form-control" [matAutocomplete]="users" value="{{input}}" (keyup)="onChangeUsers($event)"  aria-label="Knowledge Managers" aria-describedby="usersMessage"/>
          <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="users_auto" #users="matAutocomplete" (optionSelected)="selectedMultipleValue('users', $event)" role="combobox">
              <mat-option id="users_option{{i}}" *ngFor="let option of options.users;let i=index" [value]="option">{{option.display_name}}</mat-option>
          </mat-autocomplete>
          <span id="usersMessage" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
          <mat-chip-set id="users_list">
                <mat-chip id="users_chip{{i}}" disableRipple="true" removable="true" selectable="false" *ngFor="let option of locationArray;let i=index" (removed)="onRemoveMultipleSelectValue('users', option)">
                    {{option.display_name}}
                    <span id="users_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                </mat-chip>
          </mat-chip-set>
      </div>
  </div>
  <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
    <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
    <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
  </div>
</form>
