/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError, delay, retry } from 'rxjs/operators';
import { Observable } from 'rxjs'; 
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { StoriesService } from '../stories.service';

@Injectable()
export class StoryAPIService implements StoriesService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  setFormData(story, file, orgTypeName = null): FormData {
    const formData = new FormData();
    if (file) { formData.append('storyImage', file); }
    if (orgTypeName) { formData.append('orgTypeName', orgTypeName); }
    formData.append('story', JSON.stringify(story));
    return formData;
  }

  setStoryFormData(story, fileList, orgTypeName = null, deleteStoryList = null): FormData {
    const formData = new FormData();
    if (fileList.length > 0) {
      fileList.map((element) => {
        const name = element._id + element.imgFile.name;
        formData.append('files', element.imgFile, name);
      });
    }

    if (!!deleteStoryList && deleteStoryList.length > 0) {
      deleteStoryList = deleteStoryList.filter((o) => o.image_type !== 'new');
      formData.append('deleteStoryList', JSON.stringify(deleteStoryList));
    }
    if (orgTypeName) { formData.append('orgTypeName', orgTypeName); }
    const storyNwImage = Object.assign({}, story);
    const iconList = [];
    const picutureList = [];
    story.icons?.forEach((element) => {
      const storyImage: any = {};
      storyImage._id = element._id;
      if (element.image_type == 'new') {
        storyImage.image_url = '';
      }
      else { storyImage.image_url = element.image_url; }
      storyImage.image_description = element.image_description;
      iconList.push(storyImage);
    });
    story.pictures?.forEach((element) => {
      const storyImage: any = {};
      storyImage._id = element._id;
      if (element.image_type == 'new') {
        storyImage.image_url = '';
      }
      else { storyImage.image_url = element.image_url; }
      storyImage.image_description = element.image_description;
      picutureList.push(storyImage);
    });
    storyNwImage.icons = iconList;
    storyNwImage.pictures = picutureList;
    formData.append('story', JSON.stringify(storyNwImage));
    return formData;
  }


  getStory(storyId: any): Observable<any> {
    this.commonService.log('GET:', `/stories/searchMySuccessStory/${storyId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/stories/searchMySuccessStory/${storyId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getStories(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/stories/searchMySuccessStoryList', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/stories/searchMySuccessStoryList`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }


  save(story, file, orgTypeName = null): Observable<any> {
    const formData = this.setStoryFormData(story, file, orgTypeName);
    this.commonService.log('POST:', '/stories', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/stories`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  update(storyId, story, file, orgTypeName = null, deleteStoryList = null): Observable<any> {
    const formData = this.setStoryFormData(story, file, orgTypeName, deleteStoryList);
    this.commonService.log('POST:', `/stories/${storyId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/stories/${storyId}`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  saveDraft(story, file): Observable<any> {
    const formData = this.setStoryFormData(story, file);
    this.commonService.log('POST:', '/stories/draft', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.post(`${environment.API_URL}/stories/draft`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  updateDraft(storyId, story, file, deleteStoryList): Observable<any> {
    const orgTypeName = null;
    const formData = this.setStoryFormData(story, file, orgTypeName, deleteStoryList);
    this.commonService.log('PUT:', `/stories/draft/${storyId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return this.http.put(`${environment.API_URL}/stories/draft/${storyId}`, formData, this.commonService.httpOptions(null, null))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  export(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/stories/reporting/export', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/stories/reporting/export`, { filter: formData }, { ...this.commonService.httpOptions(parameters, null), responseType: 'blob' as 'json', observe: 'response' })
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  
  exportPPT(parameters: any, formData: any): Observable<any> {
    return this.http.post(`${environment.API_URL}/stories/reporting/exportPPT`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  publishRequest(storyId: any): Observable<any> {
    this.commonService.log('POST:', `/stories/publish/request/${storyId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/stories/publish/request/${storyId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  publishApprove(storyId: any): Observable<any> {
    this.commonService.log('POST:', `/stories/publish/approve/${storyId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/stories/publish/approve/${storyId}`, {}, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  publishRework(storyId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/stories/publish/rework/${storyId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/stories/publish/rework/${storyId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getStoryKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    return this.http.get(`${environment.API_URL}/stories/search/keywords`, this.commonService.httpOptions(param))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getIconShapeInfo(): Observable<any> {
    this.commonService.log('GET:', '/stories/getIconShapeInfo', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/stories/getIconShapeInfo`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getMarketsInfo(): Observable<any> {
    this.commonService.log('GET:', '/stories/getMarketsInfo', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/stories/getMarketsInfo`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}

