/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OrgType } from '@app/models/organizationType';
import * as _ from 'lodash';
@Injectable()

export class StoryFilterCommonService {
  public allOfLocations: any[];
  public allOfGroups: any[];
  getMarketsInfo(self): void {
    self.loading++;
    self.services.storiesService.getMarketsInfo()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['market'] = response.result.markets.split(',');
        }
      });
  }
  getForceList(self, active): void {
    self.loading++;
    self.services.masterService.getForceList({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['force'] = response.result;
        }
      });
  }
  getNestedThemesList(self, active): void {
    self.loading++;
    self.services.masterService.getNestedThemesList({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['nested_themes'] = response.result;
        }
      });
  }
  getLocationsOfLq(self, active): void {
    self.loading++;
    self.services.masterService.getLocations({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['location'] = response.result.filter((o) => o.organizationType.name === OrgType.LIQUID);
        }
      });
  }
  getGroups(self, active): void {
    self.loading++;
    self.services.masterService.getGroups({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['group'] = response.result;
          self.groupOrder = response.order;
        }
      });
  }
  /**
   * get quarter and year dropdown list for filter
   * @param self
   */
  getStoryQuarters(self): void {
    self.loading++;
    self.services.reportingService.getStoryQuarters()
      .subscribe((response) => {
        self.loading--;
        if (response) {
          self.options.eac_qrtr_year = response.fiscal_year;
          self.options.eac_qrtr_qtr = response.quarter;
        }
      });
  }
  getMultipleSelectValues(self, field: string): any {
    return self.filterForm.get(field).value;
  }
  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, metric = null, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    const input = document.getElementById(field);
    const index = self.services.commonService.objectIndexOf(values, event.option.value, key);
    if (index < 0) {
      values.push(event.option.value);
    }
    input['value'] = '';
    input.blur();
    self.filterForm.get(field).setValue(values);
  }
  /**
   * Get options values for dropdown list
   * @param self
   * @param field
   * @param key
   * @param onlyTypeHead
   * @returns options values
   */
  getMultipleSelectOptions(self, field: string, key = 'name', onlyTypeHead = false): any[] {
    const input = document.getElementById(field);
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    if (onlyTypeHead && input && input['value'].trim() == '') {
      filterValue = null;
    }
    let options = [];
    if (field == 'group') {
      const groupList = this.filterOptionsByGroup(options, self, field);
      options = groupList;
    } else if (field == 'workstream') {
      const wrkList = this.filterOptionsByWorkstream(self, options);
      options = wrkList;
    } else if (field == 'location') {
      const locationList = this.filterOptionsByLocation(self, options);
      options = locationList;
    } else if (field == 'organization') {
      const orgList = this.filterOptionsByOrg(self, field);
      options = orgList;
    } else if (field == 'nested_themes') {
      const nestedList = this.filterOptionsByNestedThemes(self, field);
      options = nestedList;
    }
    else {
      options = self.options[field];
    }

    if (options && options.length > 0 && field !='market') {
      options = _.uniqBy(options, 'name');
    }
    else{
      options = _.uniq(options);
    }

    if (filterValue) {
      if (key != null) {
        return options.filter((option) => option[key].toLowerCase().includes(filterValue));
      }
      return options.filter((option) => option.toLowerCase().includes(filterValue));
    } else if (onlyTypeHead) {
      return [];
    }
    return options;
  }

  filterOptionsByNestedThemes(self, field): any[] {
    let options = [];
    let forces = [];
    if (self.filterForm.get('force')) {
      forces = self.filterForm.get('force').value;
    }
    options = [];
    if (forces && forces.length > 0) {
      forces.forEach((item) => {
        self.options['nested_themes'].forEach((ele) => {
          if (item.name == ele.force.name) {
            if (self.services.commonService.objectIndexOf(options, ele) < 0) {
              options.push(ele);
            }
          }
        });
      });
    } else {
      options = self.options[field];
    }
    return options;
  }

  filterOptionsByOrg(self, field): any[] {
    let options = [];
    let locations = [];
    let workstreams = [];
    let groups = [];
    if (self.filterForm.get('location')) {
      locations = self.filterForm.get('location').value;
    }
    if (self.filterForm.get('workstream')) {
      workstreams = self.filterForm.get('workstream').value;
    }
    if (self.filterForm.get('group')) {
      groups = self.filterForm.get('group').value;
    }
    options = [];
    if (groups && groups.length) {
      this.filterOrgByGroups(groups, self, options);
    } else if (workstreams && workstreams.length) {
      this.filterOrgByWorkstreams(workstreams, self, options);
    } else if (locations && locations.length > 0) {
      this.filterOrgByLocations(locations, self, options);
    } else {
      options = self.options[field];
    }
    if (self.groupOrder) {
      options.sort(this.compareValues(self.groupOrder.internalOrder.locationOrder));
    }
    return options;
  }
  filterOrgByLocations(locations: any[], self: any, options: any[]) {
    locations.forEach((location) => {
      self.options['organization'].forEach((org) => {
        const selectedLocations = this.allOfLocations.filter((o) => o.name == location.name);
        selectedLocations.forEach((s) => {
          if (s.organizationType.name == org.name) {
            if (self.services.commonService.objectIndexOf(options, org) < 0) {
              options.push(org);
            }
          }
        });
      });
    });
  }
  filterOrgByWorkstreams(workstreams: any[], self: any, options: any[]) {
    workstreams.forEach((item) => {
      self.options['organization'].forEach((org) => {
        if (item.organizationType.name == org.name) {
          if (self.services.commonService.objectIndexOf(options, org) < 0) {
            options.push(org);
          }
        }
      });
    });
  }
  filterOrgByGroups(groups: any, self: any, options: any[]) {
    groups.forEach((group) => {
      self.options['organization'].forEach((org) => {
        const selectedGroups = this.allOfGroups.filter((o) => o.name == group.name);
        selectedGroups.forEach((s) => {
          if (s.organizationType.name == org.name) {
            if (self.services.commonService.objectIndexOf(options, org) < 0) {
              options.push(org);
            }
          }
        });
      });
    });
  }
  filterOptionsByLocation(self: any, options: any[]) {
    let org_type = [];
    if (self.filterForm.get('organization')) {
      org_type = self.filterForm.get('organization').value;
    }
    let groups = [];
    if (self.filterForm.get('group')) {
      groups = self.filterForm.get('group').value;
    }
    let workstreams = [];
    if (self.filterForm.get('workstream')) {
      workstreams = self.filterForm.get('workstream').value;
    }
    if (groups && groups.length) {
      this.filterLocationByGroup(groups, options, self);
    } else if (workstreams && workstreams.length) {
      this.filterLocationByWorkstream(workstreams, self, options);
    } else if (org_type && org_type.length) {
      this.filterLocationByOrg(org_type, self, options);
    } else {
      this.filterAllLocation(self, options);
    }
    if (self.groupOrder) {
      options.sort(this.compareValues(self.groupOrder.internalOrder.locationOrder));
    }
    this.allOfLocations = options;
    return options;
  }
  filterLocationByWorkstream(workstreams: any, self: any, options: any[]) {
    workstreams.forEach((item) => {
      self.options['group'].forEach((group) => {
        group['workstreams'].forEach((workstream) => {
          if (item.name == workstream.name) {
            group['locations'].forEach((location) => {
              if (self.services.commonService.objectIndexOf(options, location) < 0) {
                options.push(location);
              }
            });
          }
        });
      });
    });
  }
  filterLocationByGroup(groups: any, options: any[], self) {
    groups.forEach((group) => {
      const selectedGroups = this.allOfGroups.filter((o) => o.name == group.name);
      selectedGroups.forEach((item) => {
        item['locations'].forEach((location) => {
          if (self.services.commonService.objectIndexOf(options, location) < 0) {
            options.push(location);
          }
        });
      });
    });
  }
  filterLocationByOrg(org_type: any[], self: any, options: any[]) {
    org_type.forEach((item) => {
      if (item.name === OrgType.LIQUID) {
        self.options['location'].forEach((location) => {
          if (self.services.commonService.objectIndexOf(options, location) < 0) {
            options.push(location);
          }
        });
      } else {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            if (item.name == location.organizationType.name) {
              if (self.services.commonService.objectIndexOf(options, location) < 0) {
                options.push(location);
              }
            }
          });
        });
      }
    });
  }

  filterOptionsByWorkstream(self: any, options: any[]) {
    let org_type = [];
    let locations = [];
    let groups = [];
    if (self.filterForm.get('group')) {
      groups = self.filterForm.get('group').value;
    }
    if (self.filterForm.get('location')) {
      locations = self.filterForm.get('location').value;
    }
    if (self.filterForm.get('organization')) {
      org_type = self.filterForm.get('organization').value;
    }
    if (groups && groups.length) {
      this.filterWorkstreamByGroup(groups, options, self);
    } else if (locations && locations.length) {
      this.filterWorkstreamByLocation(locations, self, options, org_type);
    } else if (org_type && org_type.length) {
      this.filterWorkstreamByOrg(org_type, self, options);
    } else {
      this.filterAllWorkstreamByFromGroup(self, options);
    }
    if (self.groupOrder) {
      options.sort(this.compareValues(self.groupOrder.internalOrder.workstreamOrder));
    }
    return options;
  }
  filterWorkstreamByLocation(locations: any, self: any, options: any[], org_type: any[]) {
    if (org_type && org_type.length) {
      org_type.forEach((org) => {
        locations.forEach((item) => {
          self.options['group'].forEach((group) => {
            group['locations'].forEach((location) => {
              if (org.name == group.organizationType.name && item.name == location.name) {
                group['workstreams'].forEach((workstream) => {
                  if (self.services.commonService.objectIndexOf(options, workstream) < 0) {
                    options.push(workstream);
                  }
                });
              }
            });
          });
        });
      });
    } else {
      locations.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            if (item.name == location.name) {
              group['workstreams'].forEach((workstream) => {
                if (self.services.commonService.objectIndexOf(options, workstream) < 0) {
                  options.push(workstream);
                }
              });
            }
          });
        });
      });
    }
  }
  filterWorkstreamByGroup(groups: any, options: any[], self) {
    groups.forEach((group) => {
      const selectedGroups = this.allOfGroups.filter((o) => o.name == group.name);
      selectedGroups.forEach((item) => {
        item['workstreams'].forEach((workstream) => {
          if (self.services.commonService.objectIndexOf(options, workstream) < 0) {
            options.push(workstream);
          }
        });
      });
    });
  }
  filterWorkstreamByOrg(org_type: any[], self: any, options: any[]) {
    org_type.forEach((item) => {
      self.options['group'].forEach((group) => {
        group['workstreams'].forEach((workstream) => {
          if (item.name == workstream.organizationType.name) {
            if (self.services.commonService.objectIndexOf(options, workstream) < 0) {
              options.push(workstream);
            }
          }
        });
      });
    });
  }
  filterOptionsByGroup(options: any[], self: any, field: string) {
    let org_type = [];
    let locations = [];
    let workstreams = [];
    if (self.filterForm.get('location')) {
      locations = self.filterForm.get('location').value;
    }
    if (self.filterForm.get('workstream')) {
      workstreams = self.filterForm.get('workstream').value;
    }
    if (self.filterForm.get('organization')) {
      org_type = self.filterForm.get('organization').value;
    }
    if (locations && locations.length) {
      this.filterGroupByLocation(workstreams, self, options, org_type, locations);
    } else if (workstreams && workstreams.length) {
      this.filterGroupByWorkstream(workstreams, self, options);
    } else if (org_type && org_type.length) {
      this.filterGroupByOrg(org_type, self, options);
    } else {
      options = self.options[field];
    }
    if (self.groupOrder) {
      options.sort(this.compareValues(self.groupOrder.order));
    }
    this.allOfGroups = options;
    return options;
  }
  filterGroupByWorkstream(workstreams: any, self: any, options: any[]) {
    workstreams.forEach((item) => {
      self.options['group'].forEach((group) => {
        group['workstreams'].forEach((workstream) => {
          if (item.name == workstream.name) {
            if (self.services.commonService.objectIndexOf(options, group) < 0) {
              options.push(group);
            }
          }
        });
      });
    });
  }
  filterGroupByLocation(workstreams: any, self: any, options: any[], org_type: any[], locations: any) {
    if (workstreams && workstreams.length) {
      workstreams.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['workstreams'].forEach((workstream) => {
            if (item.name == workstream.name) {
              if (self.services.commonService.objectIndexOf(options, group) < 0) {
                options.push(group);
              }
            }
          });
        });
      });
    } else if (org_type && org_type.length) {
      org_type.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            locations.forEach((loc) => {
              if (item.name == group.organizationType.name && location.name === loc.name) {
                if (self.services.commonService.objectIndexOf(options, group) < 0) {
                  options.push(group);
                }
              }
            });
          });
        });
      });
    } else {
      locations.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            if (item.name == location.name) {
              if (self.services.commonService.objectIndexOf(options, group) < 0) {
                options.push(group);
              }
            }
          });
        });
      });
    }
  }
  filterGroupByOrg(org_type: any[], self: any, options: any[]) {
    org_type.forEach((item) => {
      self.options['group'].forEach((group) => {
        if (item.name == group.organizationType.name) {
          if (self.services.commonService.objectIndexOf(options, group) < 0) {
            options.push(group);
          }
        }
      });
    });
  }
  filterAllLocation(self: any, options: any[]) {
    self.options['group'].forEach((group) => {
      group['locations'].forEach((location) => {
        if (self.services.commonService.objectIndexOf(options, location) < 0) {
          options.push(location);
        }
      });
    });
    self.options['location'].forEach((location) => {
      if (self.services.commonService.objectIndexOf(options, location) < 0) {
        options.push(location);
      }
    });
  }
  /**
 * get all workstream by group
 * @param self
 * @param options
 */
  filterAllWorkstreamByFromGroup(self: any, options: any[]) {
    self.options['group'].forEach((group) => {
      group['workstreams'].forEach((workstream) => {
        if (self.services.commonService.objectIndexOf(options, workstream) < 0) {
          options.push(workstream);
        }
      });
    });
  }
  /**
   * remove multiple select values from filterFrom
   * @param self
   * @param field: Fields to be removed
   * @param value: Values to be removed
   * @param key
   */
  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    const index = self.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.filterForm.get(field).setValue(values);
  }

  compareValues(order = 'asc', key = 'name') {
    return function innerSort(a, b) {
      if (!a[key] || !b[key]) {
        return 0;
      }
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
}