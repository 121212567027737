/* eslint-disable id-blacklist */
import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadBookSettingComponent, SaveBookMarkSettingComponent } from '@app/dialog/book-mark-setting/book-mark-setting.component';
import { Messages, WorkflowStatus } from '@app/models';
import { Services } from '@app/services/services';
import { StoryFilterComponent } from '../story-filter/story-filter.component';

@Component({
  selector: 'app-story-list',
  templateUrl: './story-list.component.html',
  // styleUrls: ['./story-list.component.scss']
})
export class StoryListComponent implements OnInit {
  @Input('isLive') isLive: boolean;
  @Input('action') action: boolean;
  draftWorkflowStatus: Array<any> = [WorkflowStatus.DRAFT];
  liveWorkflowStatus: Array<any> = [WorkflowStatus.SAVED];
  draftCount: any = 0;
  liveCount: any = 0;
  loading: any = 0;
  stories: Array<any> = [];
  pageSizeOptions = [10, 20, 30, 40, 50];
  pagination: any = {
    page_number: 0,
    page_size: 10,
    sort: 'last_updated_date',
    search: null,
    sort_dir: 'desc'
  }
  search: any = null;
  showFilter: any = false;
  filter;
  setting = null;
  totalRecords: any = 0;

  @ViewChild('storyFilter') storyFilter: StoryFilterComponent;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private location: Location,
    public services: Services
  ) {
  }

  ngOnInit(): void {
    this.draftCount = this.services.commonService.getSessionData('STORY_DRAFT_COUNT');
    this.liveCount = this.services.commonService.getSessionData('STORY_LIVE_COUNT');
    const pagination = this.services.commonService.getSessionData(this.isLive ? 'STORY_LIVE_PAGINATION' : 'STORY_DRAFT_PAGINATION');
    const search = this.services.commonService.getSessionData('SEARCH_STORY');
    if (pagination) {
      const previousUrl = this.services.routerExtService.getPreviousUrl();
      if (previousUrl.includes('/stories/view/')) {
        this.pagination.page_number = pagination.page_number;
        this.pagination.page_size = pagination.page_size;
      }
      this.pagination.search = search;
      this.search = search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
    }
    this.services.commonService.bookMarkDeleted.subscribe((reportId) => {
      setTimeout(() => {
        if (this.setting && this.setting._id == reportId) {
          this.setting = null;
          this.services.commonService.removeSessionData('LOAD_BOOK_STORY_FILTER_SETTING');
        }
      });
    });
    this.setting = this.services.commonService.getSessionData('LOAD_BOOK_STORY_FILTER_SETTING');
    this.pagination.search = search;
    this.search = search;
  }

  _getStories(): void {
    this.loading++;
    this.pagination.workflow_status = this.isLive ? this.liveWorkflowStatus : this.draftWorkflowStatus;
    const filter = this.services.storyCommonService.getStoryFilterData(this.filter);
    setTimeout(() => {
      this.services.commonService.setSessionData(this.isLive ? 'STORY_LIVE_PAGINATION' : 'STORY_DRAFT_PAGINATION', this.pagination);
      this.services.commonService.setSessionData('SEARCH_STORY', this.pagination.search);
      this.services.storiesService.getStories(this.pagination, filter)
        .subscribe((response) => {
          this.loading--;
          if (response && response.stories) {
            response.stories.forEach((item) => {
              if (item.location?.length == 0) {
                delete item.location;
              }
              if (item.group?.length == 0) {
                delete item.group;
              }
              if (item.organization_type?.length == 0) {
                delete item.organization_type;
              }
            });
            this.stories = response.stories;
            this.totalRecords = this.isLive ? response.live_total : response.draft_total;
            this.liveCount = response.live_total;
            this.services.commonService.setSessionData('STORY_LIVE_COUNT', response.live_total);
            this.draftCount = response.draft_total;
            this.services.commonService.setSessionData('STORY_DRAFT_COUNT', response.draft_total);
          }
        }, (error1) => {
          this.loading--;
          this.services.commonService.handleError(error1);
        });
    }, 500);
  }
  onFilter($event) {
    if ($event.type == 'clearAll') {
      this.pagination.search = null;
      this.search = null;
      this.setting = null;
      this.services.commonService.removeSessionData('LOAD_BOOK_STORY_FILTER_SETTING');
    }
    if ($event.type == 'applyFilters') {
      this.showFilter = false;
    }
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this._getStories();
  }
  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this._getStories();
  }
  onOpenFilter() {
    this.showFilter = !this.showFilter;
  }
  onUpdate() {
    this._getStories();
  }

  onStoryClick(story) {
    if (this.isLive) {
      this.router.navigate(['/stories', 'view', story._id]);
    } else {
      this.router.navigate(['/stories', story._id]);
    }
  }
  onSort(sort) {
    if (this.pagination.sort === sort) {
      this.pagination.sort_dir = this.pagination.sort_dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.pagination.sort_dir = 'asc';
    }
    this.pagination.sort = sort;
    this.pagination.page_number = 0;
    this._getStories();
  }
  onSearchSubmit() {
    this.pagination.search = this.search;
    this.pagination.page_number = 0;
    this._getStories();
  }
  onSaveSetting() {
    const filter = this.services.storyCommonService.getStoryFilterData(this.filter);
    if (!filter) {
      return false;
    }
    const dialogRef = this.dialog.open(SaveBookMarkSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: {
        _id: this.setting ? this.setting._id : null,
        name: this.setting ? this.setting.name : null,
        bookType: 'success story',
        setting: {
          filter: this.filter,
        }
      }
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.setting = response.result;
        this.services.commonService.openSnackBar('success', Messages.SETTINGS_SAVE_SUCCESS);
      }
    });
  }

  onLoadSetting() {
    const dialogRef = this.dialog.open(LoadBookSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: {
        bookType: 'success story'
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loading++;
        this.services.deliverableService.getSetting(res._id)
          .subscribe((response) => {
            this.loading--;
            this.setting = response.result;
            this.services.commonService.setSessionData('LOAD_BOOK_STORY_FILTER_SETTING', this.setting);
            const setting = this.setting.setting;
            this.filter = setting.filter;
            this.storyFilter.loadFilter(this.filter);
          });
      }
    });
  }
}
