<div fxLayout="row" fxLayoutGap="22px" class="dropdown-menu" *ngIf="!setActions">
    <div fxFlex="15" class="form-group">
        <mat-select id="selectedOptions" placeholder="{{selectedOption}}" panelClass="switch-enable-dropdown" (selectionChange)="showStatus($event.value)">
            <mat-option id="all" [ngClass]="{'shift-icon':selectedOption !== 'All'}" [value]="'All'"><i *ngIf="selectedOption === 'All'" class="fa fa-check checked-icon"></i><i class="fa fa-circle-o active-inactive-icon"></i>All</mat-option>
            <mat-option id="enabled" [ngClass]="{'shift-icon':selectedOption !== 'Enabled'}" [value]="'Enabled'"><i *ngIf="selectedOption === 'Enabled'" class="fa fa-check checked-icon"></i><i class="fa fa-circle active-inactive-icon"></i>Enabled</mat-option>
            <mat-option id="disabled" [ngClass]="{'shift-icon':selectedOption !== 'Disabled'}" [value]="'Disabled'"><i *ngIf="selectedOption === 'Disabled'" class="fa fa-check checked-icon"></i><i class="fa fa-circle-o active-inactive-icon"></i>Disabled</mat-option>
        </mat-select>
    </div>
    <form fxFlex="68" fxLayout="row" class="search_wrap">
        <input id="search" placeholder="Search" name="search" [(ngModel)]="search" (input)="setSearchInput()" aria-label="search"/>
        <span id="searchIcon" class="icon ic-search"></span>
    </form>
    <div fxFlex="17" class="button-display">
        <button id="bthAddNew" mat-stroked-button color="accent" (click)="addNew()"><i class="fa fa-plus-circle add-icon"></i>Add New</button>
    </div>
</div>
<div fxLayout="row" fxLayoutGap="22px" class="dropdown-menu" *ngIf="setActions">
    <div fxFlex="15" class="form-group">
        <mat-select id="setStatus" placeholder="Set Status" panelClass="switch-enable-dropdown" (selectionChange)="setStatus($event.value)" [(value)]="statusPlaceholder">
            <mat-option id="setEnabled" [ngClass]="{'shift-icon':selectedOption !== 'Enabled'}" [value]="'Enabled'"><i *ngIf="selectedOption === 'Enabled'" class="fa fa-check checked-icon"></i><i class="fa fa-circle active-inactive-icon"></i>Enabled</mat-option>
            <mat-option id="setDisabled" [ngClass]="{'shift-icon':selectedOption !== 'Disabled'}" [value]="'Disabled'"><i *ngIf="selectedOption === 'Disabled'" class="fa fa-check checked-icon"></i><i class="fa fa-circle-o active-inactive-icon"></i>Disabled</mat-option>
        </mat-select>
    </div>
    <!-- <div fxFlex="15" class="form-group">
        <mat-select placeholder="Actions" (selectionChange)="performAction($event.value)" [(value)]="actionPlaceholder">
            <mat-option [disabled] = "adminEnable || selectedCount > 1" [value]="'Edit'">Edit</mat-option>
            <mat-option [value]="'Delete'">Delete ({{selectedCount}})</mat-option>
        </mat-select>
    </div> -->
</div>