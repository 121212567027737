/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { DeliverableTypeService } from '@app/services/deliverable-type.service';
import { CommonService } from '@app/services';
@Component({
  selector: 'app-deliverable-type-popup',
  templateUrl: './deliverable-type-popup.component.html',
  // styleUrls: ['./deliverable-type-popup.component.scss']
})
export class DeliverableTypePopupComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  submitted = false;
  public isPublished = false;
  public isIDFRequired = false;
  public isLinksReq = false;
  public selectedMetric = '';
  public delTypeName = '';
  public delTypeDescription = '';
  public loadVal =0;
  public inputDelType:any = '';
  public inputTooltip:any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<DeliverableTypePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private delTypeService: DeliverableTypeService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (this.data.value){
      this.delTypeName = this.data.value.name;
      this.selectedMetric = this.data.value.metric._id;
      this.delTypeDescription = this.data.value.description;
      this.isLinksReq = this.data.value.link_required;
      this.isPublished = this.data.value.isPublish;
      this.isIDFRequired = this.data.value.idf_number_required;
      this.inputDelType = this.delTypeName;
      this.inputTooltip = this.delTypeDescription;
    }
    this.showForm = this.formBuilder.group({
      delType: [{ value: this.delTypeName, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
      metric: [{value: this.selectedMetric, disabled: false}, Validators.required],
      description: [{ value: this.delTypeDescription, disabled: false}, [Validators.required, this.noWhitespaceValidator]]
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    if (this._isValid()){
      this.loadVal++;
      if (this.data.value){
        const metricObj = {'_id': this.selectedMetric};
        const body = {'name': this.showForm.get('delType').value.trim(), 'metric': metricObj, 'description': this.showForm.get('description').value.trim(), 'isPublish': this.isPublished, 'link_required': this.isLinksReq, 'idf_number_required': this.isIDFRequired, 'orgTypeName': this.data.orgTypeName};
        this.delTypeService.editDeliverableType(this.data.value._id, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{
        const metricObj = {'_id': this.selectedMetric};
        const body = {'name': this.showForm.get('delType').value.trim(), 'metric': metricObj, 'description': this.showForm.get('description').value.trim(), 'isPublish': this.isPublished, 'link_required': this.isLinksReq, 'idf_number_required': this.isIDFRequired, 'orgTypeName': this.data.orgTypeName};
        this.delTypeService.addDeliverableType(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }
  _isValid(): boolean {
    if (this.showForm.valid) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  selectedGroupValue(value): void {
    this.selectedMetric = value;
  }
  changeLinkReqChecked():void{
    this.isLinksReq = !this.isPublished;
  }
  changeDelTypeValue(delType){
    this.inputDelType = delType;
  }
  changeTooltipValue(tooltip){
    this.inputTooltip = tooltip;
  }
}
