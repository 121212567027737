/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { StatusService } from '../status.service';

@Injectable({
  providedIn: 'root'
})
export class StatusApiService implements StatusService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getStatus(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/deliverablestatus`, this.commonService.httpOptions(params));
  }
  addStatus(reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/deliverablestatus`, reqbody, this.commonService.httpOptions());
  }
  changeStatus(params, reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/deliverablestatus/archiveind`, reqbody, this.commonService.httpOptions(params));
  }
  editStatus(params, reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/deliverablestatus/update/${params}`, reqbody, this.commonService.httpOptions());
  }

  saveStatus(reqBody): Observable<any> {
    return this.http.patch(`${environment.API_URL}/orders/status/save`, reqBody, this.commonService.httpOptions());
  }
}