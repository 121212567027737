/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ApproveDialog, DeliverableCommentsDialog, PublishDialog, PublishRequestDialog,
  ReassignDialog, ReviewRequestDialog, ReworkDialog
} from '@app/deliverables/deliverable-dialog.components';
import { Messages, OrgType, WorkflowStatus } from '@app/models';
import { Services } from '@app/services/services';

@Component({
  selector: 'app-deliverables-view',
  templateUrl: './deliverable-view.component.html',
  styleUrls: ['./deliverable-view.component.scss']
})
export class DeliverableViewComponent implements OnInit {
  loading: any = 0;
  routerWatcher: any;
  deliverableId: any;
  deliverable: any = null;
  deliverableImages: any = {};
  profileImages: any = {};
  loginUser: any = null;
  relatedDeliverables: Array<any> = [];
  comments: Array<any> = [];
  isLiquidStudio: any = false;
  isLabs: any = false;
  isIncubation: any = false;
  clientInfo: any = '';
  linkName: any = '';
  publishApprovers: any = '';
  hidePartners = false;

  constructor(
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public services: Services
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.services.commonService.showHeader.emit(true);
    this.services.commonService.parnterNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
      }
    });
    this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.loading++;
      this.deliverableId = params.deliverable_id;
      this.services.deliverableService.getDeliverable(this.deliverableId)
        .subscribe((response) => {
          this.loading--;
          if (response && response.result) {
            this.deliverable = response.result;
            this.deliverable = { ...response.result, ...response.metadata };
            if (this.deliverable.long_description) {
              let longDescription = this.deliverable.long_description;
              longDescription = longDescription.replace(/[\n\r]/g, '<br>');
              longDescription = longDescription.replace(/( )/g, '&zwnj; ');
              this.deliverable.long_description = longDescription;
            }
            this.linkName = response.linkName;
            this.getPulishApprovers();
            this._getComments();
            this.duplicateRemoval();
            this.checkOrgType();
            this.getClient();
          }
        });
    });
  }
  getPulishApprovers() {
    if (this.deliverable.primary_location) {
      const locationLeads = this.deliverable.primary_location.location_lead.map((o) => o.display_name);
      for (const i in locationLeads) {
        if (Number(i) === 0 || locationLeads.length === 1) {
          this.publishApprovers = locationLeads[i];
        } else {
          this.publishApprovers = this.publishApprovers + '; ' + locationLeads[i];
        }
      }
    }
  }
  duplicateRemoval() {
    this.deliverable.other_groups = this.deliverable.other_groups.filter((o) => o._id != this.deliverable.primary_group._id);
    this.deliverable.other_workstreams = this.deliverable.other_workstreams.filter((o) => o._id != this.deliverable.primary_workstream._id);
    this.deliverable.other_locations = this.deliverable.other_locations.filter((o) => o._id != this.deliverable.primary_location._id);
  }

  checkOrgType() {
    if (this.deliverable.organization_type) {
      this.isLabs = this.deliverable.organization_type.name == OrgType.LABS ? true : false;
      this.isLiquidStudio = this.deliverable.organization_type.name == OrgType.LIQUID ? true : false;
      this.isIncubation = this.deliverable.organization_type.name == OrgType.INCUBATION || this.deliverable.organization_type.name == OrgType.OPENINNOVATION || this.deliverable.organization_type.name == OrgType.METAVERSE ? true : false;
    }
  }

  getClient() {
    if (this.deliverable.customer_number && this.deliverable.customer_name && this.deliverable.country_key) {
      this.clientInfo = this.deliverable.customer_number + ' - ' + this.deliverable.customer_name + ' - ' + this.deliverable.country_key;
    }
  }

  get WorkflowStatus(): any { return WorkflowStatus; }

  onBack() {
    const previousUrl = this.services.routerExtService.getPreviousUrl();
    if (previousUrl.includes('/search/view/')) {
      this.router.navigate(['/home']);
    } else if (previousUrl.includes('/deliverables/view/')) {
      this.router.navigate(['/deliverables/list/live']);
    } else {
      this.location.back();
    }
  }

  isEdited() {
    if (this.deliverable && this.deliverable.workflow) {
      const workflow_updated = new Date(this.deliverable.workflow.updated).getTime();
      const last_updated = new Date(this.deliverable.last_updated_date).getTime();
      if (workflow_updated < last_updated) {
        return true;
      }
    }
    return false;
  }

  getDeliverableImage(deliverable: any): string {
    if (deliverable && deliverable.image_link) {
      const deliverableId = deliverable._id;
      if (this.deliverableImages[deliverableId] && this.deliverableImages[deliverableId] != 'loading') {
        return this.deliverableImages[deliverableId];
      } else if (this.deliverableImages[deliverableId] != 'loading') {
        this.deliverableImages[deliverableId] = 'loading';
        this.services.masterService.getImage(deliverableId)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.deliverableImages[deliverableId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }

  getProfileImage(userId: any): string {
    return this.services.deliverableCommonService.getProfileImage(this, userId);
  }

  _getComments() {
    if (this.deliverable.workflow) {
      let workflow = this.deliverable.workflow;
      do {
        if (workflow.comments) {
          this.comments.push({
            comments: workflow.comments,
            from_user: workflow.from_user,
            updated: workflow.updated
          });
        }
        if (workflow.old_workflow) {
          workflow = workflow.old_workflow;
        } else {
          workflow = null;
        }
      } while (workflow);
    }
  }

  onReviewRequest(): void {
    this.services.deliverableCommonService.openDialog(this, ReviewRequestDialog, this.deliverable, '538px', Messages.REVIEW_REQUESTED_SUCCESS);
  }

  onRequestPublish(): void {
    this.services.deliverableCommonService.openDialog(this, PublishRequestDialog, this.deliverable, '478px', Messages.PUBLISH_REQUESTED_SUCCESS);
  }

  onReassign(): void {
    this.services.deliverableCommonService.openDialog(this, ReassignDialog, this.deliverable, '538px', Messages.REASSIGN_SUCCESS);
  }

  onApprove(): void {
    this.services.deliverableCommonService.openDialog(this, ApproveDialog, this.deliverable, '478px', Messages.APPROVE_SUCCESS);
  }

  onRework(): void {
    this.services.deliverableCommonService.openDialog(this, ReworkDialog, this.deliverable, '538px', Messages.REASSIGN_SUCCESS);
  }

  onApprovePublish(): void {
    this.services.deliverableCommonService.openDialog(this, PublishDialog, this.deliverable, '478px', Messages.PUBLISH_SUCCESS);
  }

  onViewComments(): void {
    this.dialog.open(DeliverableCommentsDialog, {
      width: '538px',
      height: 'auto',
      maxHeight: '95vh',
      data: this.comments
    });
  }

  canSendReminder(deliverable) {
    if (deliverable.workflow_status == WorkflowStatus.REVIEW_REQUESTED && deliverable.canEdit && !deliverable.canApprove)
      return true;
    else return false;
  }

  onSendReminder(): void {
    this.loading++;
    this.services.deliverableService.sendReminder(this.deliverableId)
      .subscribe((res) => {
        this.loading--;
        if (res) {
          this.services.commonService.openSnackBar('success', Messages.REMINDER_SUCCESS);
        }
      });
  }
}
