/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';

export class AmTestService {
  getAllTestData(parameters: any): Observable<any> {
    return of(parameters);
  }

  getTestDataById(parameters): Observable<any> {
    return of({ parameters });
  }

  addTestData(parameters: any, formData: any): Observable<any> {
    return of(formData);
  }

  updateTestData(parameters: any, formData: any): Observable<any> {
    return of(formData);
  }

  deleteTestData(parameters: any): Observable<any> {
    return of(parameters);
  }
}
