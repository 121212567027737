/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-related-deliverables-table',
  templateUrl: './related-deliverables-table.component.html',
  styleUrls: ['./related-deliverables-table.component.scss']
})
export class RelatedDeliverablesTableComponent implements OnInit {
  isShowLink: any = false;
  @Input()
  public tableData;
  @Input()
  public tableOrder?= 'asc';
  @Input()
  public displayedColumns: string[];
  @Input()
  public tableHeaders: string[];
  @Input()
  public searchInput?= '';
  @Input()
  public totalEntries: string;
  @Input()
  public loadVal?= 0;
  @Output() private selectedItem: EventEmitter<any> = new EventEmitter();
  constructor() { }

  ngOnInit(): void { }
  selectOne(item): void {
    this.selectedItem.emit(item);
  }
}
