/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Location } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Messages, WorkflowStatus } from '@app/models';
import { Services } from '@app/services/services';
import { LoadBookSettingComponent, SaveBookMarkSettingComponent } from '../../dialog/book-mark-setting/book-mark-setting.component';
import { DeliverableFilterComponent } from '../deliverable-filter/deliverable-filter.component';
@Component({
  selector: 'app-deliverable-list',
  templateUrl: 'deliverable-list.component.html',
  // styleUrls: ['./deliverable-list.component.scss'],
})
export class DeliverableListComponent implements OnInit {
  @Input('isLive') isLive: boolean;
  @Input('action') action: boolean;
  draftWorkflowStatus: Array<any> = [WorkflowStatus.DRAFT];
  liveWorkflowStatus: Array<any> = [WorkflowStatus.SAVED, WorkflowStatus.REVIEW_REQUESTED, WorkflowStatus.APPROVED, WorkflowStatus.PUBLISH_REQUESTED, WorkflowStatus.PUBLISH_APPROVED];
  loading: any = 0;
  search: any = null;
  deliverables: Array<any> = [];
  draftCount: any = 0;
  liveCount: any = 0;
  totalRecords: any = 0;
  pageSizeOptions = [10, 20, 30, 40, 50];
  pagination: any = {
    page_number: 0,
    page_size: 10,
    sort: 'last_updated_date',
    search: null,
    sort_dir: 'desc'
  }
  showFilter: any = false;
  filter;
  setting = null;

  @ViewChild('delFilter') deliverableFilter: DeliverableFilterComponent;
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private location: Location,
    public services: Services
  ) {
  }

  ngOnInit(): void {
    this.draftCount = this.services.commonService.getSessionData('DRAFT_COUNT');
    this.liveCount = this.services.commonService.getSessionData('LIVE_COUNT');
    const pagination = this.services.commonService.getSessionData(this.isLive ? 'LIVE_PAGINATION' : 'DRAFT_PAGINATION');
    const search = this.services.commonService.getSessionData('SEARCH_DEL');
    if (pagination) {
      const previousUrl = this.services.routerExtService.getPreviousUrl();
      if (previousUrl.includes('/deliverables/view/')) {
        this.pagination.page_number = pagination.page_number;
        this.pagination.page_size = pagination.page_size;
      }
      this.pagination.search = search;
      this.search = search;
      this.pagination.sort = pagination.sort;
      this.pagination.sort_dir = pagination.sort_dir;
    }
    this.checkErrorRoute(search);
    this.services.commonService.bookMarkDeleted.subscribe((reportId) => {
      setTimeout(() => {
        if (this.setting && this.setting._id == reportId) {
          this.setting = null;
          this.services.commonService.removeSessionData('LOAD_BOOK_FILTER_SETTING');
        }
      });
    });
    this.setting = this.services.commonService.getSessionData('LOAD_BOOK_FILTER_SETTING');
  }

  checkErrorRoute(search) {
    const route = this.location.path().split('/');
    this.pagination.search = search;
    this.search = search;
    if (route[2] == 'triggerError') {
      const errorCode = route[3];
      this.services.deliverableService.triggerError(errorCode).subscribe();
    }
  }

  _getDeliverables(): void {
    this.loading++;
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);
    this.pagination.workflow_status = this.isLive ? this.liveWorkflowStatus : this.draftWorkflowStatus;
    setTimeout(() => {
      this.services.commonService.setSessionData(this.isLive ? 'LIVE_PAGINATION' : 'DRAFT_PAGINATION', this.pagination);
      this.services.commonService.setSessionData('SEARCH_DEL', this.search);
      this.services.deliverableService.getDeliverables(this.pagination, filter)
        .subscribe((response) => {
          this.loading--;

          if (response && response.deliverables) {
            this.deliverables = response.deliverables;
            this.totalRecords = this.isLive ? response.live_total : response.draft_total;
            this.liveCount = response.live_total;
            this.services.commonService.setSessionData('LIVE_COUNT', response.live_total);
            this.draftCount = response.draft_total;
            this.services.commonService.setSessionData('DRAFT_COUNT', response.draft_total);
          }
        }, (error1) => {
          this.loading--;
          this.services.commonService.handleError(error1);
        });
    }, 500);
  }

  onPageChange($event): void {
    this.pagination.page_number = $event.pageIndex;
    this.pagination.page_size = $event.pageSize;
    this._getDeliverables();
  }

  onSort(sort) {
    if (this.pagination.sort === sort) {
      this.pagination.sort_dir = this.pagination.sort_dir === 'asc' ? 'desc' : 'asc';
    } else {
      this.pagination.sort_dir = 'asc';
    }
    this.pagination.sort = sort;
    this.pagination.page_number = 0;
    this._getDeliverables();
  }

  onDeliverableClick(deliverable) {
    if (this.isLive) {
      this.router.navigate(['/deliverables', 'view', deliverable._id]);
    } else {
      this.router.navigate(['/deliverables', deliverable._id]);
    }
  }

  onFilter($event) {
    if ($event.type == 'clearAll') {
      this.pagination.search = null;
      this.search = null;
      this.setting = null;
      this.services.commonService.removeSessionData('LOAD_BOOK_FILTER_SETTING');
    }
    if ($event.type == 'applyFilters') {
      this.showFilter = false;
    }
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this._getDeliverables();
  }

  onSearchSubmit() {
    this.pagination.search = this.search;
    this.pagination.page_number = 0;
    this._getDeliverables();
  }

  onOpenFilter() {
    this.showFilter = !this.showFilter;
  }

  onUpdate() {
    this._getDeliverables();
  }
  onSaveSetting() {
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);
    if (!filter) {
      return false;
    }
    const dialogRef = this.dialog.open(SaveBookMarkSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: {
        _id: this.setting ? this.setting._id : null,
        name: this.setting ? this.setting.name : null,
        bookType: 'deliverable',
        setting: {
          filter: this.filter,
        }
      }
    });

    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.setting = response.result;
        this.services.commonService.openSnackBar('success', Messages.SETTINGS_SAVE_SUCCESS);
      }
    });
  }

  onLoadSetting() {
    const dialogRef = this.dialog.open(LoadBookSettingComponent, {
      width: '516px',
      height: 'auto',
      maxHeight: '95vh',
      data: {
        bookType: 'deliverable'
      }
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loading++;
        this.services.deliverableService.getSetting(res._id)
          .subscribe((response) => {
            this.loading--;
            this.setting = response.result;
            this.services.commonService.setSessionData('LOAD_BOOK_FILTER_SETTING', this.setting);
            const setting = this.setting.setting;
            this.filter = setting.filter;
            this.deliverableFilter.loadFilter(this.filter);
          });
      }
    });
  }
}
