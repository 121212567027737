/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddNewInitiativeComponent } from '@app/dialog/add-new-initiative/add-new-initiative/add-new-initiative.component';
import { DisableGroupPopupComponent } from '@app/dialog/disable-group-popup/disable-group-popup.component';
import { Messages } from '@app/models';
import { CommonService, MasterService } from '@app/services';
import { InitiativesService } from '@app/services/initiatives.service';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-initiatives-view',
  templateUrl: './initiatives-view.component.html',
  // styleUrls: ['./initiatives-view.component.scss']
})
export class InitiativesViewComponent implements OnInit {
  public initiativesList;
  public columns: string[] = ['checked', 'name', 'initiative_type', 'initiative_lead_name'];
  public headersData = ['', 'Initiatives', 'Type', 'Initiative Lead'];
  public selectedOption = 'All';
  public checkedCount = 0;
  public search = '';
  public selectedInitiatives: string[];
  public placeHolder;
  public initiativeIdList = [];
  public initiativesCount = [];
  public totalInitiatives = [];
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public editSelectedInitiative = false;
  public tableOrder = 'asc';
  public initiative_type = [];
  public loadVal = 0;
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private initiativeService: InitiativesService,
    private masterService: MasterService) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.getInitiatives();
    this._getInitiativesTypes();
  }

  getInitiatives(): void {
    this.loadVal++;
    this.initiativesList = [];
    this.totalInitiatives = [];
    let params;
    if(this.selectedOption === 'All') {
      params = {active: 'All', orgTypeName: this.orgTypeName};
    } else if (this.selectedOption === 'Enabled'){
      params = {active: true, orgTypeName: this.orgTypeName};
    } else if (this.selectedOption === 'Disabled') {
      params = {active: false, orgTypeName: this.orgTypeName};
    }
    this.initiativeService.getInitiatives(params).subscribe((response) => {
      if (response && response.result) {
        response.order ==1?this.tableOrder='asc':this.tableOrder='desc';
        this.initiativesList = response.result;
        this.initiativesList.forEach((element) => {
          element['checked'] = false;
          element['active'] = !element.archive_ind;
          if(element.initiative_lead){
            element['initiative_lead_name'] = element.initiative_lead.display_name;
          }
        });
        Object.assign(this.totalInitiatives, this.initiativesList);
        this.enabledValue = this.initiativesList;
        this.initiativesList = new MatTableDataSource(this.initiativesList);
        this.initiativesList._filter.next(this.search);
        if (this.search.trim() == '') {
          this.initiativesCount = [`${this.initiativesList.filteredData.length} Entries`, ''];
        }
        else {
          this.initiativesCount = [`${this.initiativesList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
        }
        this.loadVal--;
      }
    }, (error1)=>{
      this.loadVal--;
      this.commonService.handleError(error1);
    });
  }

  getSelectedOption(selected):void {
    this.selectedInitiatives = [];
    this.checkedCount = 0;
    this.editSelectedInitiative = false;
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.initiativeIdList = [];
    this.initiativesList.filteredData.forEach((element)=> {
      if(element.checked === true) {
        this.checkedCount++;
        this.selectedInitiatives.push(element.name);
        this.totalInitiatives.forEach((item) => {
          if(item.name === element.name) {
            this.initiativeIdList.push({'_id': item._id});
          }
        });
      }
    });
    if(this.checkedCount > 0) {
      this.editSelectedInitiative = true;
    }
  }
  resetSelected():void {
    this.selectedInitiatives = [];
    this.checkedCount = 0;
    this.editSelectedInitiative = false;
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.initiativeIdList = [];
    this.initiativesList.filteredData.forEach((element)=> {
      element['checked'] = false;
    });
  }
  getEnableDisableStatus(value) :void{
    this.selectedOption = value;
    this.getInitiatives();
  }

  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.initiativesList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.initiativesCount = [`${this.initiativesList.filteredData.length} Entries`, ''];
    }
    else {
      this.initiativesCount = [`${this.initiativesList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }
  setStatus(value): void {
    this.selectedOption = value;
    if (value ==='Enabled'){
      this.loadVal++;
      const params = {disable: !(value ==='Enabled')};
      this.initiativeService.changeInitiativeStatus(params, this.initiativeIdList)
        .subscribe((response) => {
          if (response) {
            this.loadVal--;
            this.getInitiatives();
            this.editSelectedInitiative = false;
          }
        }, (error1)=>{
          this.loadVal--;
          this.commonService.handleError(error1);
        });
    }
    else{
      const editData = {'group_id': this.initiativeIdList, 'type': 'initiative', 'popup_content': Messages.INITIATIVES_POPUP_CONTENT};
      const dialogRef = this.dialog.open(DisableGroupPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.loadVal++;
          const params = {disable: !(value ==='Enabled')};
          this.initiativeService.changeInitiativeStatus(params, this.initiativeIdList)
            .subscribe((response) => {
              if (response) {
                this.loadVal--;
                this.getInitiatives();
                this.editSelectedInitiative = false;
              }
            }, (error1)=>{
              this.loadVal--;
              this.commonService.handleError(error1);
            });
        }
        else{
          this.resetSelected();
        }
      });
    }
  }
  addNewInitiative(add): void {
    if(add) {
      const editData = {type: this.initiative_type, orgTypeName: this.orgTypeName};
      const dialogRef = this.dialog.open(AddNewInitiativeComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.INITIATIVE_ADDED_SUCCESS);
          this.getInitiatives();
        }
      });
    }
  }
  getSelectedOrder(value):void{
    this.loadVal++;
    const params = { collection_name: 'initiatives', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.INITIATIVE_ORDER_SAVE_SUCCESS);
        }
      }, (error1)=> {
        this.loadVal--;
      });
  }
  getClickedItem(value):void{
    if(value) {
      const editData = {'type': this.initiative_type, 'value': value, 'orgTypeName': this.orgTypeName};
      const dialogRef = this.dialog.open(AddNewInitiativeComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.INITIATIVE_EDITED_SUCCCESS);
          this.getInitiatives();
        }
      });
    }
  }
  _getInitiativesTypes(): void {
    const params = {orgTypeName: this.orgTypeName};
    this.masterService.getInitiatives(params)
      .subscribe((response) => {
        if (response && response.result) {
          this.initiative_type = response.result.map((item) => item['initiative_type']).filter((value, index, self) => self.indexOf(value) === index);
        }
      });
  }
}
