<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm"  class="add-dialog" role="dialog" aria-labelledby="titleDeliverableType">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('delType').invalid }">
            <p id="titleDeliverableType">Deliverable Type</p>
            <input id="deliverableType" type="text" formControlName="delType" class="form-control" placeholder="Deliverable Type" aria-label="Deliverable Type"  maxlength="128" (keyup)="changeDelTypeValue(showForm.get('delType').value)"/>
            <span id="deliverableTypeLength" class="hint point">{{inputDelType ? inputDelType.length : 0}}/128</span>
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('metric').invalid }">
            <p id="titleMetric">Metric</p>
            <mat-select id="drpMetric" formControlName="metric" class="form-control" placeholder="Select" aria-label="Metric" (selectionChange)="selectedGroupValue($event.value)" >
                <mat-option id="metric_option{{i}}" *ngFor="let option of data.metric;let i=index" [value]="option._id" >{{option.name}}</mat-option>
            </mat-select>
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('description').invalid }">
            <p id="titleTooltip">Tooltip</p>
            <textarea id="tooltip" rows="3" class="form-control short" formControlName="description" placeholder="Tooltip" aria-label="tooltip"  maxlength="3000" (keyup)="changeTooltipValue(showForm.get('description').value)"></textarea>
            <span id="tooltipLength" class="hint point">{{inputTooltip ? inputTooltip.length : 0}}/3000</span>
        </div>
        <ul>
           <li> <mat-checkbox id="publishable" [(ngModel)]="isPublished" (click)="changeLinkReqChecked()" [ngModelOptions]="{standalone: true}" color="accent">Publishable</mat-checkbox></li>
           <li> <mat-checkbox id="IDFNumberRequired" [(ngModel)]="isIDFRequired" [ngModelOptions]="{standalone: true}"  color="accent">IDF Number Required</mat-checkbox></li>
           <li> <mat-checkbox id="linksAreRequired" [(ngModel)]="isLinksReq" [disabled]="isPublished" [ngModelOptions]="{standalone: true}" color="accent">Links are Required</mat-checkbox></li>
        </ul>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
    </div>
</form>
