/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Services } from '@app/services/services';
import { Animations } from '@app/app-animations';

@Component({
  selector: 'app-deliverable-filter',
  outputs: ['onChange'],
  templateUrl: 'deliverable-filter.component.html',
  // styleUrls: ['./deliverable-filter.component.scss'],
  animations: [
    Animations.slideInOut
  ]
})
export class DeliverableFilterComponent implements OnInit {
  loading: any = 0;
  loadKeywords: any = 0;
  keywordChanged: Subject<string> = new Subject<string>();
  filterForm: UntypedFormGroup;
  groupOrder: any = null;
  filterLabels: any = {
    group: 'Group',
    workstream: 'Workstream',
    location: 'Location',
    status: 'Deliverable Status',
    initiative: 'Initiative',
    project: 'Project',
    keyword: 'Keyword',
    deliverable_type: 'Deliverable Type',
    workflow_status: 'Workflow Status'
  }
  options: any = {
    group: [],
    status: [],
    initiative: [],
    keyword: [],
    project: [],
    deliverable_type: [],
    workflow_status: []
  };
  filter: any = {
    group: [],
    workstream: [],
    location: [],
    status: [],
    initiative: [],
    keyword: [],
    project: [],
    deliverable_type: [],
    workflow_status: []
  };
  addAllOfDeliverableTypes: any = [];
  @Input('show') show: boolean;
  @Input('isDraft') isDraft: boolean;
  @Input('sectionKey') filterSectionKey: string;
  @Input('filterOptionActive') filterOptionActive: any = null;

  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public services: Services
  ) {

  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      group: [[]],
      workstream: [[]],
      location: [[]],
      status: [[]],
      initiative: [[]],
      project: [[]],
      keyword: [[]],
      deliverable_type: [[]],
      workflow_status: [[]]
    });
    this.services.deliverableFilterCommonService.getGroups(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getLocationsOfLq(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getDeliverableStatus(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getInitiatives(this, this.filterOptionActive);
    this.services.deliverableEditCommonService.getDeliverableTypes(this);
    this.services.deliverableCommonService.getWorkflowStatus(this);
    this._getProjects();
    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.length >= 3) {
          this.options['keyword'] = [];
          this._getKeywords(filterValue);
        }
      });
    if (this.services.commonService.getSessionData(this.filterSectionKey)) {
      this.filter = this.services.commonService.getSessionData(this.filterSectionKey);
      if (this.isDraft) {
        this.filter.workflow_status = [];
      }
    }
    this.onFilter('init');
  }

  _getProjects(): void {
    this.loading++;
    this.services.masterService.getProjects({ active: this.filterOptionActive })
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['project'] = response.result;
        }
      });
  }

  _getKeywords(searchText: any): void {
    this.loadKeywords++;
    this.services.deliverableService.getDeliverablesKeywords(searchText)
      .subscribe((response) => {
        this.loadKeywords--;
        if (response && response.result) {
          this.options['keyword'] = response.result;
        }
      });
  }

  onChangeKeyWord(event): void {
    this.options['keyword'] = [];
    this.keywordChanged.next(event.target.value);
  }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false): any[] {
    return this.services.commonService.getMultipleSelectOptions(this, field, null, key, onlyTypeHead, false);
  }

  getMultipleSelectValues(field: string): any {
    return this.services.deliverableFilterCommonService.getMultipleSelectValues(this, field, null);
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    this.services.deliverableFilterCommonService.onAddMultipleSelectValue(this, field, event, null, key);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.deliverableFilterCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }

  onFilter(type) {
    let values = [];
    if(type != 'loadSetting'){
      values = this.filterForm.getRawValue();
    } else { values= this.filter; }
    for (const item in values) {
      if (values[item] && values[item].length) {
        values[item].forEach((value) => {
          let index = -1;
          if (item == 'keyword') {
            index = this.services.commonService.objectIndexOf(this.filter[item], value, null);
          } else {
            index = this.services.commonService.objectIndexOf(this.filter[item], value);
          }
          if (index < 0) {
            this.filter[item].push(value);
          }
        });
      }
    }
    this.filterForm.reset();
    this.services.commonService.setSessionData(this.filterSectionKey, this.filter);
    this.onChange.emit({ type: type, data: this.filter });
  }
  loadFilter(filter) {
    this.filter = filter;
    this.onFilter('loadSetting');
  }
  onRemoveFilter(field, option) {
    let index = -1;
    if (field === 'keyword') {
      index = this.services.commonService.objectIndexOf(this.filter[field], option, null);
    } else {
      index = this.services.commonService.objectIndexOf(this.filter[field], option);
    }
    if (index >= 0) {
      this.filter[field].splice(index, 1);
    }
    this.onFilter('removeFilter');
  }

  onClearAllFilter() {
    this.filterForm.reset();
    this.filter = {
      group: [],
      workstream: [],
      location: [],
      status: [],
      initiative: [],
      keyword: [],
      project: [],
      deliverable_type: [],
      workflow_status: []
    };
    this.onFilter('clearAll');
  }

  hasFilter() {
    return this.services.deliverableFilterCommonService.hasFilter(this);
  }
}
