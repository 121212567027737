
<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form fxLayout="column" class="disable-group-dialog">
    <div fxLayout="column" class="dialog-content">
        <div class="popup-content" id="popupContent" [innerHTML]="data.popup_content"></div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnDownload" mat-button type="file"  color="primary" (click)="onDownload()">Download CSV</button>
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnConfirm" mat-flat-button type="submit" color="primary" (click)="OnConfirmation()" cdkFocusInitial>Confirm</button>
    </div>
</form>
