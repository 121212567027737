/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddNewGroupsComponent } from '@app/dialog/add-new-groups/add-new-groups.component';
import { DisableGroupPopupComponent } from '@app/dialog/disable-group-popup/disable-group-popup.component';
import { Messages, OrgType } from '@app/models';
import { CommonService } from '@app/services';
import { GroupService } from '@app/services/group.service';
import { MasterService } from '@app/services/master.service';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-groups-view',
  templateUrl: './groups-view.component.html',
  // styleUrls: ['./groups-view.component.scss']
})
export class GroupsViewComponent implements OnInit {
  public groupList;
  public columns: string[] = ['checked', 'name', 'group_locations', 'group_lead_name'];
  public headersData = ['', 'Groups', 'Location(s)', 'Group Lead'];
  public selectedOption = 'All';
  public checkedCount = 0;
  public editSelectedAdmin = false;
  public selectedGroups: string[];
  public search = '';
  public checkedAdmin: string;
  public placeHolder;
  public esoIdList = [];
  public groupCount = [];
  public totalGroups = [];
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public tableOrder = 'asc';
  public locations = [];
  public loadVal = 0;
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private groupService: GroupService,
    private masterService: MasterService
  ) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.getGroups();
    this._getLocations();
  }

  getGroups(): void {
    this.loadVal++;
    this.groupList = [];
    let params = {};
    if (this.selectedOption === 'All') {
      params = { active: 'All' };
    } else if (this.selectedOption === 'Enabled') {
      params = { active: true };
    } else if (this.selectedOption === 'Disabled') {
      params = { active: false };
    }
    params['orgTypeName'] = this.orgTypeName;
    this.groupService.getGroups(params).subscribe((response) => {
      if (response && response.result) {
        this.tableOrder = response.order.order;
        this.groupList = response.result;
        this.groupList.forEach((element) => {
          element['checked'] = false;
          element['active'] = !element.archive_ind;
          let temp_location = '';
          if(element['active']){
            element.locations.forEach((location) => {
              temp_location += location.name + ',';
            });
          }
          if (element.group_lead) {
            element['group_lead_name'] = element.group_lead.display_name;
          }
          element['group_locations'] = temp_location.substring(0, temp_location.length - 1);
        });
        Object.assign(this.totalGroups, this.groupList);
        this.enabledValue = this.groupList;
        this.groupList = new MatTableDataSource(this.groupList);
        this.groupList._filter.next(this.search);
        if (this.search.trim() == '') {
          this.groupCount = [`${this.groupList.filteredData.length} Entries`, ''];
        }
        else {
          this.groupCount = [`${this.groupList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
        }
        this.loadVal--;
      }
    }, (error1) => {
      this.loadVal--;
      this.commonService.handleError(error1);
    });
  }

  getSelectedOption(selected): void {
    this.selectedGroups = [];
    this.checkedCount = 0;
    this.editSelectedAdmin = false;
    this.checkedAdmin = selected.group_name;
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.esoIdList = [];
    this.groupList.filteredData.forEach((element) => {
      if (element.checked === true) {
        this.checkedCount++;
        this.selectedGroups.push(element.group_name);
        this.totalGroups.forEach((item) => {
          if (item.name === element.name) {
            this.esoIdList.push({ '_id': item._id });
          }
        });
      }
    });
    if (this.checkedCount > 0) {
      this.editSelectedAdmin = true;
    }
  }
  resetSelected(): void {
    this.selectedGroups = [];
    this.checkedCount = 0;
    this.editSelectedAdmin = false;
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.esoIdList = [];
    this.groupList.filteredData.forEach((element) => {
      element['checked'] = false;
    });
  }

  getEnableDisableStatus(value): void {
    this.selectedOption = value;
    this.getGroups();
  }

  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.groupList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.groupCount = [`${this.groupList.filteredData.length} Entries`, ''];
    }
    else {
      this.groupCount = [`${this.groupList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }

  setStatus(value): void {
    this.selectedOption = value;
    if (value === 'Enabled' || this.orgTypeName !== OrgType.LABS) {
      this.updateSelectedGroupStatus(value);
    }
    else {
      const editData = { 'group_id': this.esoIdList, 'type': 'group', 'popup_content': Messages.GROUP_POPUP_CONTENT };
      const dialogRef = this.dialog.open(DisableGroupPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.updateSelectedGroupStatus(value);
        }
        else {
          this.resetSelected();
        }
      });
    }
  }

  updateSelectedGroupStatus(value: any) {
    this.loadVal++;
    const params = { disable: !(value === 'Enabled') };
    this.groupService.updateGroupStatus(params, this.esoIdList)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.getGroups();
          this.editSelectedAdmin = false;
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  addNewAdmin(add): void {
    if (add) {
      const editData = { 'locations': this.locations, 'orgTypeName': this.orgTypeName };
      const dialogRef = this.dialog.open(AddNewGroupsComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.GROUP_ADDED_SUCCESS);
          this.getGroups();
        }
      });
    }
  }
  getSelectedOrder(value): void {
    this.loadVal++;
    const params = { collection_name: 'groups', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.GROUP_ORDER_SAVE_SUCCESS);
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getClickedItem(value): void {
    if (value) {
      const editData = { 'locations': this.locations, 'value': value, 'orgTypeName': this.orgTypeName  };
      const dialogRef = this.dialog.open(AddNewGroupsComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.GROUP_EDITED_SUCCESS);
          this.getGroups();
        }
      });
    }
  }
  _getLocations(): void {
    this.masterService.getLocations({ 'orgTypeName': this.orgTypeName })
      .subscribe((response) => {
        if (response && response.result) {
          this.locations = response.result;
        }
      }, (error1) => {
        this.commonService.handleError(error1);
      });
  }
}
