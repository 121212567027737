/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Input, EventEmitter, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Services } from '@app/services/services';
import { Animations } from '@app/app-animations';
import { OrganizationTypeService } from '@app/services/organization-type.service';
import { OrgType } from '@app/models/organizationType';
import { ContentType } from '@app/models/contentType';

@Component({
  selector: 'app-search-filter',
  outputs: ['onChange'],
  templateUrl: 'search-filter.component.html',
  // styleUrls: ['./search-filter.component.scss'],
  animations: [
    Animations.slideInOut
  ]
})
export class SearchFilterComponent implements OnInit, AfterViewChecked {
  loading: any = 0;
  loadKeywords: any = 0;
  loadClient: any = 0;
  keywordChanged: Subject<string> = new Subject<string>();
  clientChanged: Subject<string> = new Subject<string>();
  filterForm: UntypedFormGroup;
  groupOrder: any = null;
  isShowDeliverableType = false;
  filterLabels: any = {
    type: 'Content Type',
    group: 'Group',
    workstream: 'Workstream',
    location: 'Location',
    industries: 'Industries',
    initiative: 'Initiative',
    keyword: 'Keyword',
    organization: 'Organization',
    deliverable_type: 'Deliverable Type',
    client: 'Client',
    force: 'Force Name',
    nested_themes: 'Nested Themes',
    market: 'Market'
  }
  options: any = {
    type: [
      {
        _id: 1,
        name: 'Deliverable'
      },
      {
        _id: 2,
        name: 'Project'
      },
      {
        _id: 3,
        name: 'Success Story'
      },
    ],
    group: [],
    industries: [],
    initiative: [],
    keyword: [],
    deliverable_type: [],
    organization: [],
    client: [],
    force: [],
    nested_themes: [],
    market: []
  };
  filter: any = {
    type: [],
    group: [],
    workstream: [],
    location: [],
    industries: [],
    initiative: [],
    keyword: [],
    organization: [],
    deliverable_type: [],
    client: [],
    force: [],
    nested_themes: [],
    market: []
  };
  addAllOfDeliverableTypes: any = [];
  @Input('show') show: boolean;
  @Input('isDraft') isDraft: boolean;
  @Input('sectionKey') filterSectionKey: string;
  @Input('filterOptionActive') filterOptionActive: any = null;
  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private organizationTypeService: OrganizationTypeService,
    public services: Services,
    private cd: ChangeDetectorRef
  ) {

  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      type: [[]],
      group: [[]],
      workstream: [[]],
      location: [[]],
      industries: [[]],
      initiative: [[]],
      project: [[]],
      keyword: [[]],
      deliverable_type: [[]],
      workflow_status: [[]],
      organization: [[]],
      client: [[]],
      force: [[]],
      nested_themes: [[]],
      market: [[]]
    });
    this._getOrganization();
    this.services.deliverableFilterCommonService.getGroups(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getLocationsOfLq(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getIndustries(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getInitiatives(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getDeliverableTypes(this, this.filterOptionActive);
    this.services.storyFilterCommonService.getForceList(this, this.filterOptionActive);
    this.services.storyFilterCommonService.getNestedThemesList(this, this.filterOptionActive);
    this.services.storyFilterCommonService.getMarketsInfo(this);
    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.length >= 3) {
          this.options['keyword'] = [];
          this._getKeywords(filterValue);
        }
      });
    this.clientChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.length >= 3) {
          this.options['client'] = [];
          this._getClient(filterValue);
        }
      });
    if (this.services.commonService.getSessionData(this.filterSectionKey)) {
      this.filter = this.services.commonService.getSessionData(this.filterSectionKey);
      if (this.isDraft) {
        this.filter.workflow_status = [];
      }
    }
    this.onFilter('init');
  }
  _getOrganization(): void {
    this.loading++;
    this.organizationTypeService.getOrganizationTypes()
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['organization'] = response.result.filter((o) => o.name != OrgType.GENERAL);
        }
      });
  }
  _getKeywords(searchText: any): void {
    this.loadKeywords++;
    this.services.deliverableService.getDeliverableAndProjectKeywords(searchText)
      .subscribe((response) => {
        this.loadKeywords--;
        if (response && response.result) {
          this.options['keyword'] = response.result;
        }
      });
  }
  _getClient(searchText: any): void {
    this.loadClient++;
    this.services.deliverableService.getClient(searchText)
      .subscribe((response) => {
        this.loadClient--;
        if (response && response.result) {
          this.options['client'] = response.result;
        }
      });
  }

  onChangeKeyWord(event): void {
    this.options['keyword'] = [];
    this.keywordChanged.next(event.target.value);
  }
  onChangeClient(event): void {
    this.options['client'] = [];
    this.clientChanged.next(event.target.value);
  }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false): any[] {
    return this.services.commonService.getMultipleSelectOptions(this, field, null, key, onlyTypeHead, false, false, true);
  }

  getMultipleSelectValues(field: string): any {
    return this.services.deliverableFilterCommonService.getMultipleSelectValues(this, field, null);
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    this.services.deliverableFilterCommonService.onAddMultipleSelectValue(this, field, event, null, key);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.deliverableFilterCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }

  onFilter(type) {
    let values = [];
    if (type != 'loadSetting') {
      values = this.filterForm.getRawValue();
    } else {
      values = this.filter;
    }
    for (const item in values) {
      if (values[item] && values[item].length) {
        values[item].forEach((value) => {
          let index = -1;
          if (item == 'keyword' || item == 'client' || item == 'market') {
            index = this.services.commonService.objectIndexOf(this.filter[item], value, null);
          } else {
            index = this.services.commonService.objectIndexOf(this.filter[item], value);
          }
          if (index < 0) {
            this.filter[item].push(value);
          }
        });
      }
    }
    this.filterForm.reset();
    this.services.commonService.setSessionData(this.filterSectionKey, this.filter);
    this.onChange.emit({ type: type, data: this.filter });
  }
  onRemoveFilter(field, option) {
    let index = -1;
    if (field === 'keyword' || field === 'client' || field == 'market') {
      index = this.services.commonService.objectIndexOf(this.filter[field], option, null);
    } else {
      index = this.services.commonService.objectIndexOf(this.filter[field], option);
    }
    if (index >= 0) {
      this.filter[field].splice(index, 1);
    }
    this.onFilter('removeFilter');
  }

  onClearAllFilter() {
    this.filterForm.reset();
    this.filter = {
      type: [],
      group: [],
      workstream: [],
      location: [],
      industries: [],
      initiative: [],
      keyword: [],
      organization: [],
      deliverable_type: [],
      client: [],
      force: [],
      nested_themes: [],
      market: []
    };
    this.onFilter('clearAll');
  }

  hasFilter() {
    if (this.filter && this.filter.type && this.filter.type.length > 0) {
      this.filter.type.forEach((o) => {
        if (o.name === ContentType.STORY) {
          o.name = ContentType.SUCCESSSTORY;
        }
      });
    }
    return this.services.deliverableFilterCommonService.hasFilter(this);
  }
  loadFilter(filter) {
    this.filter = filter;
    this.onFilter('loadSetting');
  }
}
