import { Injectable } from '@angular/core';
import { CommonService, AuthenticationService, DeliverableService, ReportingService, DeliverableCommonService, DeliverableEditCommonService, DeliverableFilterCommonService, MasterService, UserService, RouterExtService, ProjectsService, ProjectsCommonService, ProjectsEditCommonService, ProjectFilterCommonService, KPIService, StoryCommonService, StoryEditCommonService, StoriesService } from '@app/services';
import { AmTestService } from './amtest.service';
import { StoryFilterCommonService } from './story-filter-common.service';

@Injectable()
export class Services {
  constructor(public commonService: CommonService,
    public authenticationService: AuthenticationService,
    public deliverableCommonService: DeliverableCommonService,
    public masterService: MasterService,
    public deliverableService: DeliverableService,
    public deliverableEditCommonService: DeliverableEditCommonService,
    public reportingService: ReportingService,
    public deliverableFilterCommonService: DeliverableFilterCommonService,
    public userService: UserService,
    public routerExtService: RouterExtService,
    public amtestService: AmTestService,
    public projectsService: ProjectsService,
    public projectsCommonService: ProjectsCommonService,
    public projectsEditCommonService: ProjectsEditCommonService,
    public projectFilterCommonService: ProjectFilterCommonService,
    public storyCommonService: StoryCommonService,
    public storyEditCommonService: StoryEditCommonService,
    public storiesService: StoriesService,
    public storyFilterCommonService: StoryFilterCommonService,
    public kpiService: KPIService) {
  }
}
