<div fxFlex fxLayout="column" class="reporting-page">

  <div fxFlex fxLayout="column">
    <div fxLayout="row" class="reporting-table-header">
      <div fxFlex></div>
      <div fxLayout="row" class="sec-btn-wrap" fxLayoutGap="15px" *ngIf="isShowStoryExportReport">
        <button id="btn_exportMenu" (click)="exportReport()" *ngIf="services.commonService.hasAnyRole([Role.MANAGER_ADMINISTRATOR]) || services.commonService.hasAnyRole([Role.CONFIGURATION_ADMINISTRATOR])" mat-stroked-button color="accent" [matMenuTriggerFor]="exportMenu">
          <div fxLayout="row" class="btn-inner">
            <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24.6" viewBox="0 0 24 24.6">
              <path fill="none" d="M0 0h24v24.6H0z" />
              <path fill="#004dff"
                d="M8.71 7.71L11 5.41V15a1 1 0 0 0 2 0V5.41l2.29 2.3a1 1 0 1 0 1.42-1.42l-4-4a1.034 1.034 0 0 0-1.42 0l-4 4a1 1 0 0 0 1.42 1.42zM21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 1 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1z"
                transform="translate(0 -.005)" />
            </svg>
            <span>
              <span id="s_exportsetting" class="setting-value">Export Report</span>
            </span>
          </div>
        </button>
        <mat-menu #exportMenu="matMenu" class="live-menu export-menu" xPosition="before">
          <button id="ppt" mat-menu-item (click)="showSubMenu()" [matMenuTriggerFor]="hiddenMenu"
            [ngClass]="{'open-menu': showMenu}">
            {{ !hasFilter() && !pagination.search ? "Full Report (PPT)" : "Filtered Report (PPT)" }}
            <span class="ic-arrow-down"></span>
          </button>
          <div *ngIf="showMenu" class="confidential-menu">
            <button id="btn_exportMenu" mat-menu-item (click)="downloadPPT()">Download with confidential level</button>
            <button id="btn_exportMenu" mat-menu-item (click)="downloadPPT(true)">Download without confidential
              level</button>
          </div>
          <button id="csv" mat-menu-item (click)="onExport()">
            {{ !hasFilter() && !pagination.search ? "Full Report (CSV)" : "Filtered Report (CSV)" }}
          </button>
        </mat-menu>
        <!-- to control the mat-menu is always foused -->
        <mat-menu class="hidden-menu" #hiddenMenu="matMenu"></mat-menu>
      </div>
    </div>
    <div fxFlex fxLayout="column" class="reporting-list-row" id="reporting-list-row">
      <div fxFlex fxLayout="row" *ngIf="loading" class="loading-wrap">
        <span id="loading">Loading...</span>
      </div>
      <div fxFlex fxLayout="row" role="alert" aria-live="assertive" *ngIf="!loading && !stories.length" class="loading-wrap">
        <span id="noResults">No results</span>
      </div>
      <div fxLayout="row" *ngIf="!loading && totalRecords !==0" class="reporting-list">
        <div fxLayout="column" fxFlex class="table-container" id="table-container">
          <div>
            <table mat-table [dataSource]="stories" aria-label="reporting data table">
              <ng-container matColumnDef="Title" sticky>
                <th id="th_storyTitle" mat-header-cell *matHeaderCellDef>Title </th>
                <td name="td_storyTitle" mat-cell *matCellDef="let element" class="white-space-pre"><a
                    [routerLink]="['/reporting/story', element._id]">{{element.title}}</a></td>
              </ng-container>

              <ng-container matColumnDef="owner">
                <th id="th_owner" mat-header-cell *matHeaderCellDef> Owner </th>
                <td name="td_owner" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.owner?element.owner.display_name: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="organization_type">
                <th id="th_org" mat-header-cell *matHeaderCellDef> Organization </th>
                <td name="td_org" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.organization_type?element.organization_type.name: ''}} </td>
              </ng-container>
              <ng-container matColumnDef="primary_group">
                <th id="th_group" mat-header-cell *matHeaderCellDef> Group </th>
                <td name="td_group" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.primary_group?element.primary_group.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="workflow_status">
                <th id="th_workflowStatus" mat-header-cell *matHeaderCellDef> Workflow Status </th>
                <td name="td_workflowStatus" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.workflow_status}}</td>
              </ng-container>
              <ng-container matColumnDef="primary_workstream">
                <th id="th_workstream" mat-header-cell *matHeaderCellDef> Workstream </th>
                <td name="td_workstream" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.primary_workstream?element.primary_workstream.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="primary_location">
                <th id="th_location" mat-header-cell *matHeaderCellDef> Location </th>
                <td name="td_location" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.primary_location?element.primary_location.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="eac_qrtr">
                <th id="th_endQTR" mat-header-cell *matHeaderCellDef class="no-white-space-last">
                  FiscalYear Quarter
                </th>
                <td name="td_endQTR" mat-cell *matCellDef="let element" class="no-white-space">
                  <span *ngIf="element.eac_qrtr">FY{{element.eac_qrtr.year-2000}}
                    Q{{element.eac_qrtr.qtr}}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="markets">
                <th id="th_markets" mat-header-cell *matHeaderCellDef> Market </th>
                <td name="td_markets" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.markets?element.markets:''}} </td>
              </ng-container>
              <ng-container matColumnDef="force">
                <th id="th_force" mat-header-cell *matHeaderCellDef> Force Name </th>
                <td name="td_force" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.force?element.force.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="nested_themes">
                <th id="th_nested_themes" mat-header-cell *matHeaderCellDef> Nested Themes </th>
                <td name="td_nested_themes" mat-cell *matCellDef="let element" class="no-white-space">
                  {{element.nested_themes?element.nested_themes.name:''}} </td>
              </ng-container>
              <ng-container matColumnDef="additional_team_members">
                <th id="th_additional_team_member" mat-header-cell *matHeaderCellDef> Additional Team Members </th>
                <td name="td_additional_team_member" mat-cell *matCellDef="let element" class="no-white-space">
                  <span id="additional_team_member{{i}}"
                    *ngFor="let option of element.team_members; last as isLast;let i=index">{{option.display_name}}<span
                      *ngIf="!isLast">;<br /></span></span>
                </td>
              </ng-container>
              <ng-container matColumnDef="action" stickyEnd>
                <th mat-header-cell *matHeaderCellDef style="width: 1%;">
                  <span style="display: none;">Wav</span>
                  <button id="btn_table_filter" mat-icon-button class="filter-btn" aria-label="filter"
                    (click)="onOpenTableFilter()">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                      height="20" viewBox="0 0 20 20">
                      <path
                        d="M18,20H16V6H14V4h2V0h2V4h2V6H18V20Zm-7,0H9V11H7V9H9V0h2V9h2v2H11v9ZM4,20H2V16H0V14H2V0H4V14H6v2H4v4Z" />
                    </svg>
                  </button>
                </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <button id="deliverableMenuIcon" mat-icon-button class="menu-btn"
                    [matMenuTriggerFor]="deliverableMenu" aria-label="deliverable">
                    <span class="icon ic-menu"></span>
                  </button> -->
                  <!-- <mat-menu #deliverableMenu="matMenu" class="live-menu">
                    <button id="quickEditDeliverable" mat-menu-item>Quick Edit
                      Deliverable</button>
                    <button id="editDeliverable" mat-menu-item [routerLink]="['/stories', element._id]">Edit
                      Deliverable</button>
                    <button id="emailOwner" mat-menu-item *ngIf="element.owner && element.owner.email_id"
                      (click)="services.deliverableCommonService.sendOwnerEmail(element)">Email
                      Owner</button>
                    <button id="emailGroupLead" mat-menu-item
                      *ngIf="element.primary_group && element.primary_group.group_lead && element.primary_group.group_lead.email_id"
                      (click)="services.deliverableCommonService.sendGroupLeadEmail(element)">Email
                      Group Lead</button>
                  </mat-menu> -->
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
          <div id="table_footer" fxLayout="row" class="table-footer">
            <mat-paginator [length]="totalRecords" [pageSize]="pagination.page_size"
              [pageIndex]="pagination.page_number" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)">
            </mat-paginator>
          </div>
        </div>
        <div fxLayout="column" class="filter-menu" *ngIf="filterMenu" [@slideMenu] id="filter-menu">
          <div fxLayout="column" class="filter-menu-button_wrap">
            <button id="btn_apply_top" mat-flat-button class="apply-btn" aria-label="Apply" color="primary"
              (click)="onApplyTableFilter()">
              Apply
            </button>
          </div>
          <div fxFlex fxLayout="column" class="filter-menu-options_wrap">
            <div fxLayout="column" class="filter-menu-group">
              <h2 id="h_tableOptions">Table Options</h2>
              <mat-checkbox [(ngModel)]="hideFilterFields" (change)="onHideFilterFieldsChange()" color="accent">Hide
                Filter Fields
              </mat-checkbox>
            </div>
            <div fxLayout="column" class="filter-menu-group">
              <h2 id="h_showHideColumns">Show/Hide Columns</h2>
              <mat-checkbox [(ngModel)]="showWorkflowStatus" color="accent">Workflow Status</mat-checkbox>
            </div>
            <div fxLayout="column" class="filter-menu-group">
              <h3 id="h_mandatoryFields">Mandatory Fields</h3>
              <mat-checkbox id="mandatoryFieldsShowAll" [checked]="checkAllSelect(ssmandatoryFields)"
                (change)="onShowAllChange($event, ssmandatoryFields)" color="accent">Show All</mat-checkbox>
              <mat-checkbox id="mandatoryFields{{i}}" *ngFor="let option of ssmandatoryFields;let i=index"
                [(ngModel)]="option.select" [disabled]="option.disabled" color="accent">{{option.label}}</mat-checkbox>
            </div>
            <div fxLayout="column" class="filter-menu-group optional-fields">
              <h3 id="h_optionalFields">Optional Fields</h3>
              <mat-checkbox id="optionalFieldsShowAll" [checked]="checkAllSelect(ssoptionalFields)"
                (change)="onShowAllChange($event, ssoptionalFields)" color="accent">Show All</mat-checkbox>
              <mat-checkbox id="ssoptionalFields{{i}}" *ngFor="let option of ssoptionalFields;let i=index"
                [(ngModel)]="option.select" [disabled]="option.disabled" color="accent">{{option.label}}</mat-checkbox>
            </div>
          </div>
          <div fxLayout="column" class="filter-menu-button_wrap">
            <button id="btn_apply_bottom" mat-flat-button class="apply-btn" color="primary"
              (click)="onApplyTableFilter()">
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>