/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { CommonService, DeliverableService, DeliverableCommonService, MasterService, ReportingService } from '@app/services';
import { debounceTime } from 'rxjs/operators';
import { Messages } from '@app/models';
import { Animations } from '@app/app-animations';
import { Services } from '@app/services/services';
import { OrganizationTypeService } from '@app/services/organization-type.service';
import { OrgType } from '@app/models/organizationType';

@Component({
  selector: 'app-report-filter',
  outputs: ['onChange'],
  templateUrl: 'report-filter.component.html',
  // styleUrls: ['./report-filter.component.scss'],
  animations: [
    Animations.slideInOut
  ]
})
export class ReportFilterComponent implements OnInit {
  loading: any = 0;
  filterForm: UntypedFormGroup;
  groupOrder: any = null;
  submitted: any = false;
  filterLabels: any = {
    group: 'Group',
    workstream: 'Workstream',
    location: 'Location',
    status: 'Deliverable Status',
    initiative: 'Initiative',
    deliverable_type: 'Deliverable Type',
    workflow_status: 'Workflow Status',
    organization: 'Organization',
    eac_qrtr_qtr: 'Quarter',
    eac_qrtr_year: 'Fiscal Year'
  }
  options: any = {
    group: [],
    status: [],
    initiative: [],
    eac_qrtr: [],
    eac_qrtr_year: [],
    eac_qrtr_qtr: [],
    start_qrtr: [],
    metrics: [],
    workflow_status: [],
    organization: []
  };
  filter: any = {
    group: [],
    workstream: [],
    location: [],
    status: [],
    initiative: [],
    eac_qrtr_year: [],
    eac_qrtr_qtr: [],
    deliverable_type: [],
    workflow_status: [],
    organization: []
  };
  addAllOfDeliverableTypes: any = [];
  @Input('show') show: boolean;
  @Input('sectionKey') filterSectionKey: string;
  @ViewChild('filterValidForm') filterValidForm: ElementRef;
  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private deliverableService: DeliverableService,
    public deliverableCommonService: DeliverableCommonService,
    private masterService: MasterService,
    private reportingService: ReportingService,
    private organizationTypeService: OrganizationTypeService,
    public services: Services
  ) {

  }

  get Messages(): any { return Messages; }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      group: [[]],
      workstream: [[]],
      location: [[]],
      status: [[]],
      initiative: [[]],
      eac_qrtr_year: [],
      eac_qrtr_qtr: [],
      deliverable_type: [[]],
      workflow_status: [],
      organization: []
    }

    );
    this._getGroups();
    this._getDeliverableStatus();
    this._getInitiatives();
    this._getQuarters();
    this._getMetrics();
    this._getOrganizationTypes();
    this.getLocationsOfLq();
    this.deliverableCommonService.getWorkflowStatus(this);
    if (this.commonService.getSessionData(this.filterSectionKey)) {
      this.filter = this.commonService.getSessionData(this.filterSectionKey);
    }
    this.onFilter('init');
  }


  _getOrganizationTypes(): void {
    this.loading++;
    this.organizationTypeService.getOrganizationTypes()
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['organization'] = response.result.filter((o) => o.name != OrgType.GENERAL);
        }
      });
  }

  getLocationsOfLq(): void {
    this.loading++;
    this.masterService.getLocations({ active: 'All' })
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['location'] = response.result.filter((o) => o.organizationType.name === OrgType.LIQUID);
        }
      });
  }
  _getGroups(): void {
    this.loading++;
    this.masterService.getGroups({ active: 'All' })
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['group'] = response.result.filter((o) => o.organizationType.name != OrgType.LIQUID);
          this.groupOrder = response.order;
        }
      });
  }

  _getDeliverableStatus(): void {
    this.loading++;
    this.deliverableService.getDeliverableStatus({ active: 'All' })
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['status'] = response.result;
        }
      });
  }

  _getInitiatives(): void {
    this.loading++;
    this.masterService.getInitiatives({ active: 'All' })
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['initiative'] = response.result;
        }
      });
  }

  _getQuarters(): void {
    this.loading++;
    this.reportingService.getQuarters()
      .subscribe((response) => {
        this.loading--;
        if (response) {
          this.options.eac_qrtr_year = response.fiscal_year;
          this.options.eac_qrtr_qtr = response.quarter;
        }
      });
  }

  _getMetrics(): void {
    this.loading++;
    this.reportingService.getDeliverableTypeByMetrics({ active: 'All' })
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options.metrics = response.result;
        }
      });
  }

  getMultipleSelectOptions(field: string, metric = null, key = 'name', onlyTypeHead = false): any[] {
    return this.services.commonService.getMultipleSelectOptions(this, field, metric, key, onlyTypeHead, false, true);
  }

  getMultipleSelectValues(field: string, metric = null): any {
    if (field == 'deliverable_type') {
      const values = this.filterForm.get(field).value;
      const result = [];
      metric.deliverable_types.forEach((deliverableType) => {
        const index = this.commonService.objectIndexOf(values ? values : [], deliverableType);
        if (index >= 0) {
          result.push(deliverableType);
        }
      });
      return result;
    }
    return this.filterForm.get(field).value;
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, metric = null, key = '_id'): void {
    let values = this.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    let input = document.getElementById(field);
    if (metric) {
      input = document.getElementById(field + '_' + metric._id);
      metric.deliverable_types.forEach((deliverableType) => {
        if (event.option.value.name === deliverableType.name) {
          this.addAllOfDeliverableTypes.push(deliverableType);
        }
      });
    }
    const index = this.commonService.objectIndexOf(values, event.option.value, key);
    if (index < 0) {
      values.push(event.option.value);
    }
    input['value'] = '';
    input.blur();
    this.filterForm.get(field).setValue(values);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    let values = this.filterForm.get(field).value;
    if (!values) {
      values = [];
      this.addAllOfDeliverableTypes = [];
    }
    const index = this.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    if (values.length > 0 && field === 'deliverable_type') {
      const allList = values.map((o) => this.addAllOfDeliverableTypes.filter((e) => e.name === o.name && e._id != value._id));
      this.addAllOfDeliverableTypes = allList.reduce((a, b) => { return a.concat(b); });
    } else if (field === 'deliverable_type') {
      this.addAllOfDeliverableTypes = [];
    }
    this.filterForm.get(field).setValue(values);
  }

  getQuartsOptions(field: string): any[] {
    const options = [];
    if (field == 'eac_qrtr_qtr') {
      this.options['eac_qrtr_qtr'].forEach((qrtr) => {
        const index = this.commonService.objectIndexOf(options, qrtr, null);
        if (index < 0) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a - b; });
    } else if (field == 'eac_qrtr_year') {
      this.options['eac_qrtr_year'].forEach((qrtr) => {
        const index = this.commonService.objectIndexOf(options, qrtr, null);
        if (index < 0) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a - b; });
    }
    return options;
  }

  _isValid(): boolean {
    if (this.filterForm.valid) {
      return true;
    }
    this.invalidFocus();
    return false;
  }

  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.filterValidForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.filterValidForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }

  onFilter(type) {
    this.submitted = true;
    if (this._isValid()) {
      this.filter = this.getFilter(type);
      this.filterForm.reset();
      this.commonService.setSessionData(this.filterSectionKey, this.filter);
      this.onChange.emit({ type: type, data: this.filter });
      this.addAllOfDeliverableTypes = [];
    }
  }

  getFilter(type) {
    if (this._isValid()) {
      let values = [];
      if (type != 'loadSetting') {
        values = this.filterForm.getRawValue();
      } else { values = this.filter; }
      for (const item in values) {
        if (values[item] && values[item].length) {
          values[item].forEach((value) => {
            let index = -1;
            if (item === 'eac_qrtr_year' || item === 'eac_qrtr_qtr') {
              index = this.commonService.objectIndexOf(this.filter[item], value, null);
            } else {
              index = this.commonService.objectIndexOf(this.filter[item], value);
            }
            if (index < 0) {
              this.filter[item].push(value);
            }
          });
        }
      }
      this.filter.deliverable_type = this.filter.deliverable_type.map(({ _id, name }) => ({ _id, name }));
      this.filter.group = this.filter.group.map(({ _id, name }) => ({ _id, name }));
      this.filter.initiative = this.filter.initiative.map(({ _id, name }) => ({ _id, name }));
      this.filter.location = this.filter.location.map(({ _id, name }) => ({ _id, name }));
      this.filter.status = this.filter.status.map(({ _id, name }) => ({ _id, name }));
      this.filter.workstream = this.filter.workstream.map(({ _id, name }) => ({ _id, name }));
      return this.filter;
    } else {
      return false;
    }
  }

  onRemoveFilter(field, option) {
    if (option) {
      let index = -1;
      if (field === 'eac_qrtr_year' || field === 'eac_qrtr_qtr') {
        index = this.commonService.objectIndexOf(this.filter[field], option, null);
      } else {
        index = this.commonService.objectIndexOf(this.filter[field], option);
      }
      if (index >= 0) {
        this.filter[field].splice(index, 1);
      }
    } else {
      this.filter[field] = null;
    }
    this.onFilter('removeFilter');
  }

  onRemoveFilterObjects(fields) {
    fields.forEach((field) => {
      this.filter[field] = null;
    });
    this.onFilter('removeFilter');
  }

  onClearAllFilter() {
    this.filterForm.reset();
    this.filter = {
      group: [],
      workstream: [],
      location: [],
      status: [],
      initiative: [],
      eac_qrtr_year: [],
      eac_qrtr_qtr: [],
      deliverable_type: [],
      workflow_status: [],
      organization: []
    };
    this.onFilter('clearAll');
  }

  hasFilter() {
    let res = false;
    for (const item in this.filter) {
      if (this.filter[item] && this.filter[item].length) {
        res = true;
      }
    }
    return res;
  }

  loadFilter(filter) {
    this.filter = filter;
    this.onFilter('loadSetting');
  }
}
