<div fxFlex fxLayout="column" class="load-setting-dialog" role="dialog" aria-labelledby="h_saveSettings">
  <div fxLayout="column" class="dialog-header">
    <h2 id="h_saveSettings" class="dialog-title">Save Settings</h2>
  </div>
  <div fxLayout="column" class="dialog-content" aria-labelledby="saveSettingsText">
    <p id="saveSettingsText">Save your settings for quick access to the filters you use most
      often. Please name your settings for your own reference later. Your saved settings are only visible to you.</p>
    <div class="form-group">
      <label id="lblbookMarkName">Settings Name</label>
      <input id="bookMarkName" type="text" class="form-control" placeholder="e.g. My Favorite Settings"
        [(ngModel)]="bookMarkName" aria-label="Settings Name" />
    </div>
  </div>

  <div fxLayout="row" fxLayoutGap="10px" class="dialog-actions">
    <div fxFlex></div>
    <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
    <button id="saveReport" mat-flat-button type="button" color="primary" (click)="onSave()" [disabled]="!bookMarkName">Save Settings</button>
  </div>
</div>