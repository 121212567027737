/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class DeliverableService {
  getDeliverableStatus(params = null): Observable<any> {
    return of(params);
  }

  getDeliverablesKeywords(searchText: string): Observable<any> {
    return of(searchText);
  }

  getDeliverables(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }

  getDeliverable(deliverableId: any): Observable<any> {
    return of(deliverableId);
  }

  getRelatedDeliverables(parameters: any): Observable<any> {
    return of(parameters);
  }

  saveDraft(deliverable, file): Observable<any> {
    return of({ deliverable, file });
  }

  updateDraft(deliverableId, deliverable, file): Observable<any> {
    return of({ deliverableId, deliverable, file });
  }

  save(deliverable, file, orgTypeName = null): Observable<any> {
    return of({ deliverable, file, orgTypeName });
  }

  update(deliverableId, deliverable, file, orgTypeName = null): Observable<any> {
    return of({ deliverableId, deliverable, file, orgTypeName });
  }


  getReviewers(deliverableId: any): Observable<any> {
    return of(deliverableId);
  }

  reviewRequest(deliverableId: any, formData: any): Observable<any> {
    return of({ deliverableId, formData });
  }

  publishRequest(deliverableId: any): Observable<any> {
    return of(deliverableId);
  }

  approve(deliverableId: any): Observable<any> {
    return of(deliverableId);
  }

  approveRework(deliverableId: any, formData): Observable<any> {
    return of({ deliverableId, formData });
  }

  approveReassign(deliverableId: any, formData): Observable<any> {
    return of({ deliverableId, formData });
  }

  publishRework(deliverableId: any, formData): Observable<any> {
    return of({ deliverableId, formData });
  }

  publishApprove(deliverableId: any): Observable<any> {
    return of(deliverableId);
  }

  getTypeAheadData(queryParams): Observable<any> {
    return of(queryParams);
  }
  getTypeAheadDeliverableData(queryParams): Observable<any> {
    return of(queryParams);
  }

  getFilter(parameters: any, formData: any): Observable<any> {
    return of(formData, parameters);
  }

  sendReminder(deliverableId: any): Observable<any> {
    return of(deliverableId);
  }

  getDeliverableAndProjectKeywords(searchText: string): Observable<any> {
    return of(searchText);
  }

  triggerError(errorCode: any): Observable<any> {
    return of(errorCode);
  }

  getClientUser(searchText: string): Observable<any> {
    return of(searchText);
  }
  getSettings(bookType: string): Observable<any> {
    return of(bookType);
  }

  getSetting(_id): Observable<any> {
    return of(_id);
  }

  deleteSetting(_id): Observable<any> {
    return of(_id);
  }

  saveSetting(formData: any): Observable<any> {
    return of(formData);
  }

  updateSetting(_id, formData: any): Observable<any> {
    return of(formData);
  }

  getClient(searchText: string): Observable<any> {
    return of(searchText);
  }
}

