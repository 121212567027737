/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from '@app/services';
import { StageService } from '@app/services/stage.service';
import * as uuid from 'uuid';
@Component({
  selector: 'app-add-stage',
  templateUrl: './add-stage.component.html',
  styleUrls: ['./add-stage.component.scss']
  })
export class AddStageComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  stageName = '';
  stage = '';
  typeRule = '';
  deliverableStatuRule = '';
  workStatuRule = '';
  public loadVal = 0;
  publicationList = ['Stage', 'Stage Name', 'Deliverables', 'Deliverables'];
  DelType = [];
  DelStatus = [' ', 'Complete'];
  WorkFStatus = [' ', 'Published'];
  isDelte = false;
  stageDetail: any = [];
  projectstage: any = null;
  public projectStageList: any =[];
  projectStageListShow: any = null;
  public inputStageName: any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AddStageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private stageService: StageService
  ) { }

  ngOnInit(): void {
    if (this.data.value) {
      this.stageName = this.data.value.name;
      this.stage = this.data.value.stage;
      this.projectstage = this.data.value;
      this.projectStageList = JSON.parse(JSON.stringify(this.data.value.projectStage));
      this.projectStageListShow = this.data.value.projectStage.concat();
      this.projectStageListShow.sort(this.arraySort);
      this.inputStageName = this.data.value.name;
      this.DelType = this.data.deliverbletype;
    }
    this.showForm = this.formBuilder.group({
      stage: [{ value: this.stage, disabled: false }],
      stageName: [{ value: this.stageName, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
    });
  }
  arraySort(a, b) {
    return b.updated_date < a.updated_date ? 1 : -1;
  }
  onCancel(): void {
    this.projectStageList = [];
    this.projectStageListShow = [];
    this.dialogRef.close();
  }
  selectedLocationsValue(event, name, stageDtail): void {
    this.projectStageList.forEach((element) => {
      if (element._id === stageDtail._id) {
        if (name === 'typeRule') {
          element.typeRule = event.value;
        } else if (name === 'deliverableStatuRule') {
          element.deliverableStatuRule = event.value;
        } else if (name === 'workStatuRule') {
          element.workStatuRule = event.value;
        }
      }
    });
  }
  deleteStage(stageItem): void {
    this.projectStageList.forEach(function (o, index, arr) {
      if (o._id === stageItem._id) {
        if (o.archive_ind == false) {
          o.archive_ind = true;
        }
        if (o.archive_ind === undefined) {
          arr.splice(index, 1);
        }
      }
    });
    this.projectStageListShow = this.projectStageList.concat();
    this.projectStageListShow = this.projectStageListShow.filter((o) => {
      return o.archive_ind !== true;
    });
    // this.projectStageListShow.sort(this.arraySort);
  }

  scrollDown()
  {
    const div = document.getElementById('scrolldIV');
    div.scrollTop = div.scrollHeight;
  }

  addNewData(): void {
    const newStageDetail = { '_id': uuid.v4(), 'typeRule': '', 'deliverableStatuRule': '', 'workStatuRule': '', 'project_stage': this.projectstage._id, 'updated_date': new Date()};
    this.projectStageList.push(newStageDetail);
    this.projectStageListShow.push(newStageDetail);
    this.projectStageListShow.sort();
    setTimeout(() => {
      this.scrollDown();
    }, 100);
  }
  save(): void {
    if (this._isValid()) {
      this.loadVal++;
      if (this.data.value) {
        const params = this.data.value._id;
        this.projectstage.name = this.inputStageName;
        this.projectstage.projectStage = this.projectStageList.concat();
        this.stageService.editStages(params, this.projectstage)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1) => {
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }

  _isValid(): boolean {
    const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
    if (!invalidControl) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  changeStatusValue(stageName) {
    this.inputStageName = stageName;
  }
}

