import { Component } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  // styleUrls: ['./progress-spinner.component.scss']
})
export class ProgressSpinnerComponent {
  mode: ProgressSpinnerMode = 'indeterminate';
}
