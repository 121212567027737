<div fxLayout="column" class="comments-dialog">
    <div fxLayout="column">
      <button id="cancel" mat-icon-button class="btn-close" (click)="onCancel()" aria-label="Comments">
        <span class="icon ic-close-button"></span>
      </button>
      <h2 id="comments" class="dialog-title">Comments</h2>
        <div fxLayout="column" class="dialog-content">
          <div fxLayout="column" class="comments" *ngFor="let comment of comments;let i=index">
            <span id="fromUser{{i}}">Comment from {{comment.from_user.display_name}} on {{comment.updated | date}}</span>
            <div class="comment-box">
                <p id="commentsText{{i}}">{{comment.comments}}</p>
            </div>
        </div>
        </div>
    </div>
  </div>