/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { StatusService } from '@app/services/status.service';
import { CommonService } from '@app/services';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-status-popup',
  templateUrl: './status-popup.component.html',
  // styleUrls: ['./status-popup.component.scss']
})
export class StatusPopupComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  submitted = false;
  statusName= '';
  isComplete = false;
  public loadVal = 0;
  inputStatusName: any ='';
  statusPopName: any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<StatusPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    if (this.data.value){
      this.statusName = this.data.value.name;
      this.isComplete = this.data.value.isComplete;
      this.inputStatusName = this.statusName;
    }
    this.showForm = this.formBuilder.group({
      status: [{ value: this.statusName, disabled: false }, [Validators.required, this.noWhitespaceValidator]]
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    if (this._isValid()){
      this.loadVal++;
      if (this.data.value){
        const params = this.data.value._id;
        const body ={ 'name': this.showForm.get('status').value.trim(), 'isComplete': this.isComplete};
        this.statusService.editStatus(params, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{ 
        const orgTypeName= this.data.orgTypeName;
        const body ={'name': this.showForm.get('status').value.trim(), 'isComplete': this.isComplete,'orgTypeName':orgTypeName};
        this.statusService.addStatus(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }

  _isValid(): boolean {
    if (this.showForm.valid) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  changeStatusValue(status){
    this.inputStatusName = status;
  }
}

