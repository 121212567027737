export const environment = {
  production: true,
  demo: false,
  log: false,
  reporting: true,
  API_URL: '/api',
  clientId: 'cc9ddd16-733e-4b8a-ac23-771d4d051a93',
  authority: 'https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22',
  redirectUri: 'https://si-test.ciodev.accenture.com/home',
  DELIVERABLE_URL: 'http://si-test.ciodev.accenture.com/reporting/view/',
  unprotectedResources: [],
  IDF20: true,
  isShowPPT: true,
  CUSTOMIZE_FY: [],
  MAX_RETRY_COUNT: 4,
  RETRY_INTERVAL: 1000,
  DISPLAY_STORY: true,
  DISPLAY_STORY_EXPORT_REPORT: true,
  launchDarklyID: '62f5e7549523e011a2df2419',
  MY_SUCCESS_STORIES: 'SuccessStoriesMenu_AIR94601',
  DRAFT_STORY_LINK: 'stories/list/drafts',
  STORY_LIVE_LINK: 'stories/list/live',
  STORY_ID_LINK: 'stories/:story_id',
  STORY_VIEW_LINK: 'stories/view/:story_id'
};