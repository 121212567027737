<div fxFlex fxLayout="column" class="main-page">
  <div fxLayout="column" class="toolbar-header-row">
    <mat-progress-bar *ngIf="loadTypeAhead" color="accent" mode="indeterminate"></mat-progress-bar>
    <ng-select id="addSearchKey" class="WavSelect WavOption" bindLabel="name" bindValue="name" groupBy="type"
      [(ngModel)]="searchText" placeholder="Search for Deliverables, Projects and Success Stories " [markFirst]="false"
      [editableSearchTerm]="true" [inputAttrs]='{"aria-label":"Search"}' [inputAttrs]='{"autocomplete":"off"}' [closeOnSelect]="true"
      (input)="getTypeAhead($event)" (keydown.enter)="onTypeAheadSearchSubmit($event)"
      (change)="onTypeAheadSearchSubmit()" [items]="review"></ng-select>
    <div fxLayout="row" class="toolbar-header-nav filter-icon-btn">
      <div fxFlex></div>
      <div fxLayout="row" fxLayoutGap="10px">
        <button id="btn_settingMenu" mat-button color="primary" class="setting-btn" [matMenuTriggerFor]="setingMenu">
          <div class="btn-inner">
            <span>
              <span id="s_filtersetting" class="setting-value">Bookmark</span>
            </span>
            <span class="icon ic-dropdown"></span>
          </div>
        </button>
        <mat-menu #setingMenu="matMenu" class="live-menu sort-menu">
          <button id="btn_savesetting" mat-menu-item (click)="onSaveSetting()">
            Save Filter Settings
          </button>
          <button id="btn_loadsetting" mat-menu-item (click)="onLoadSetting()">
            Load Filter Settings
          </button>
        </mat-menu>
        <button id="sortMenuIcon" mat-button color="primary" class="sort-btn" [matMenuTriggerFor]="sortMenu">
          <div class="btn-inner">
            <span>
              <span id="s_sort">Sort : </span>
              <span id="s_select" class="sort-value" *ngIf="pagination.sort == ''">Most Relevant </span>
              <span id="s_mostRecent" class="sort-value" *ngIf="pagination.sort == 'last_updated_date'">Most
                Recent</span>
              <span id="s_alphabetical" class="sort-value" *ngIf="pagination.sort == 'name'">Alphabetical</span>
              <span id="s_sort_dir" *ngIf="pagination.sort_dir">({{pagination.sort_dir}})</span>
            </span>
            <span class="icon ic-dropdown"></span>
          </div>
        </button>
        <mat-menu #sortMenu="matMenu" class="live-menu sort-menu">
          <button id="Select" mat-menu-item (click)="onSort('')" *ngIf="pagination.search ">
            Most Relevant
          </button>
          <button id="mostRecent" mat-menu-item (click)="onSort('last_updated_date')">
            Most Recent
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'last_updated_date'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="alphabetical" mat-menu-item (click)="onSort('name')">
            Alphabetical
            <span class="icon ic-dropdown" *ngIf="pagination.sort == 'name'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
        </mat-menu>

        <button id="filter" mat-button class="filter-btn" color="primary" (click)="onOpenFilter()" [ngClass]="{'open': showFilter}">
          <span class="icon ic-filter"></span> Filter
        </button>

      </div>
    </div>
    <div fxLayout="row" *ngIf="setting" class="show-save-setting">
      <label id="searchAppliedFilters"> Showing Bookmark:</label>
      <div fxLayout="column" class="show-name">
        {{setting.name}}
      </div>
    </div>
    <app-search-filter #searchFilter [show]="showFilter" filterOptionActive="All" sectionKey="search-filter"
      (onChange)="onFilter($event)">
    </app-search-filter>
    <button id="viewAll" mat-stroked-button class="view-all" color="primary" (click)="onViewAll()">VIEW ALL</button>
  </div>
  <div fxFlex fxLayout="column" class="list-row" id="list-row">
    <div fxFlex fxLayout="row" *ngIf="loading" class="loading-wrap">
      <span id="loading">Loading...</span>
    </div>
    <div fxFlex fxLayout="row" role="alert" aria-live="assertive" *ngIf="!loading && !results.length " class="loading-wrap">
      <span id="noResults">No results</span>
    </div>
    <div fxLayout="column" fxLayoutGap="20px" *ngIf="!loading " class="list-items">
      <ng-container *ngFor="let result of results;let i=index">
        <app-deliverable-list-view id="deliverableComp{{i}}" *ngIf="result.type == 'Deliverable'" [deliverable]="result"
          [isFromSearch]=true (onClick)="onDeliverableClick($event)">
        </app-deliverable-list-view>
        <app-project-list-view id="projectComp{{i}}" *ngIf="result.type == 'Project'" [project]="result"
          [isFromSearch]=true (onClick)="onProjectClick($event)">
        </app-project-list-view>
        <app-story-list-view id="storyComp{{i}}" *ngIf="result.type == 'Story'" [story]="result" [isFromSearch]=true
          (onClick)="onStoryClick($event)">
        </app-story-list-view>
      </ng-container>
    </div>
    <mat-paginator [length]="totalRecords" [pageSize]="pagination.page_size" [pageIndex]="pagination.page_number"
      [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
  </div>
</div>