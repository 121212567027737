/* eslint-disable @typescript-eslint/no-unused-vars */
import { MiddlewareService } from './middleware.service';
/* eslint-disable complexity */
/* eslint-disable max-lines */
/* eslint-disable id-blacklist */
/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { accordionMenu } from '@app/config/accordionMenu';
import { ConfirmDialog } from '@app/dialog/confirm-dialog';
import { Messages, OrgType, Role, SnackBarText } from '@app/models';
import { Menu } from '@app/models/menu';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { RedirectRequest } from '@azure/msal-browser';
import { environment } from '@environments/environment';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable, Observer, of, Subject } from 'rxjs';
import { AuthenticationService } from './api/authentication.service';

@Injectable()
export class CommonService {
  showHeader: EventEmitter<any> = new EventEmitter();
  reportDeleted: EventEmitter<any> = new EventEmitter();
  reportCreated: EventEmitter<any> = new EventEmitter();
  bookMarkDeleted: EventEmitter<any> = new EventEmitter();
  parnterNeedToRefresh: Subject<boolean> = new Subject<boolean>();
  forceRelatedNeedToRefresh: Subject<boolean> = new Subject<boolean>();
  unexpected_error = [400, 403, 405, 406, 408, 415, 429, 500];
  bad_network_error = [502, 503, 504];
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private router: Router,
    private middlewareService: MiddlewareService,
    private authenticationService: AuthenticationService,
    public authService: MsalService) {
  }

  public allOfLocations: any[];
  public allOfGroups: any[];
  globalFlags = {};


  hasAnyRole(roles: any, user = null): boolean {
    if (!user) {
      user = JSON.parse(localStorage.getItem('currentUser'));
    }
    if (user) {
      if (roles) {
        if (roles.indexOf('*') !== -1) {
          return true;
        }
        return roles.filter((o) => user.labs_role.includes(o) || user.roles.includes(o)).length > 0;
      }
      return true;
    }
    return false;
  }

  hasAnyLeadRole(user = null): boolean {
    const roles = [Role.GROUP_LEAD, Role.INITIATIVE_LEAD, Role.LOCATION_LEAD, Role.PROJECT_LEAD, Role.WORKSTREAM_LEAD, Role.GROUP_LOCATION_LEAD];
    return this.hasAnyRole(roles, user);
  }

  getIdToken() {
    return localStorage.getItem('msal.idtoken');
  }

  httpOptions(params = null, contentType = 'application/json'): any {
    let headers = new HttpHeaders();
    if (!environment.demo) {
      if (contentType) {
        headers = new HttpHeaders({
          'Content-Type': contentType,
          'Authorization': 'Bearer ' + this.getIdToken()
        });
      } else {
        headers = new HttpHeaders({
          'Authorization': 'Bearer ' + this.getIdToken()
        });
      }
    } else if (contentType) {
      headers = new HttpHeaders({
        'Content-Type': contentType
      });
    }

    const httpOptions = {
      headers: headers,
      params: new HttpParams(),
      withCredentials: true,
    };

    if (params) {
      for (const key in params) {
        if (params[key] != null && params[key] !== '') {
          if (params[key] instanceof Array) {
            params[key].forEach((item) => {
              httpOptions.params = httpOptions.params.append(`${key.toString()}`, item);
            });
          } else {
            httpOptions.params = httpOptions.params.append(key.toString(), params[key]);
          }
        }
      }
    }
    return httpOptions;
  }

  extractData(res: any = null): any {
    const body = res;
    return body || {};
  }

  handleError<T>(error: any, emit = true) {
    this.log(error);
    if (emit && error.status !== Messages.HTTP_STATUS_CODE_401 && error.status !== Messages.HTTP_STATUS_CODE_200) {
      let message = '';
      if (this.unexpected_error.includes(error.status) || error.status == 0) {
        message = Messages.UNEXPECTED_ERROR_DATA_MESSAGE;
      } else if (this.bad_network_error.includes(error.status)) {
        message = Messages.BAD_NETWORK_ERROR_MESSAGE;
      } else if (error && error.error) {
        if (error.error.error) {
          if (error.error.error.message) {
            message = error.error.error.message;
          } else {
            message = error.error.error;
          }
        }
      }
      if (message !== null && message !== undefined && message !== '') {
        this.openSnackBar(SnackBarText.ERROR, message, 5000);
      }
    }
    else if (error.status == Messages.HTTP_STATUS_CODE_401) {
      this.openSnackBar(SnackBarText.WARNING, Messages.SESSION_EXPIRED_DATA_MESSAGE, 5000);
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
    if (error.status === Messages.HTTP_STATUS_CODE_403) {
      this.router.navigate(['/home']);
    } else if (error.status === Messages.HTTP_STATUS_CODE_404) {
      this.router.navigate(['/error']);
    }
    return of(null as T);
  }

  log(...args): void {
    if (environment.log) {
      console.log(moment().format() + ':', ...args);
    }
  }

  removeSessionData(key = null): void {
    if (key) {
      sessionStorage.removeItem(key);
    } else {
      const tmpDict = {};
      tmpDict['GET_DELIVERABLE_ORG'] = sessionStorage.getItem('GET_DELIVERABLE_ORG');
      tmpDict['GET_PROJECT_ORG'] = sessionStorage.getItem('GET_PROJECT_ORG');
      tmpDict['GET_STORY_ORG'] = sessionStorage.getItem('GET_STORY_ORG');
      tmpDict['GET_QUICK_ORG'] = sessionStorage.getItem('GET_QUICK_ORG');
      tmpDict['GET_SELECTED_DELIVERABLES'] = sessionStorage.getItem('GET_SELECTED_DELIVERABLES');
      tmpDict['GET_SELECTED_STORY_IMAGE'] = sessionStorage.getItem('GET_SELECTED_STORY_IMAGE');
      sessionStorage.clear();
      sessionStorage.setItem('GET_DELIVERABLE_ORG', tmpDict['GET_DELIVERABLE_ORG']);
      sessionStorage.setItem('GET_PROJECT_ORG', tmpDict['GET_PROJECT_ORG']);
      sessionStorage.setItem('GET_STORY_ORG', tmpDict['GET_STORY_ORG']);
      sessionStorage.setItem('GET_QUICK_ORG', tmpDict['GET_QUICK_ORG']);
      sessionStorage.setItem('GET_SELECTED_DELIVERABLES', tmpDict['GET_SELECTED_DELIVERABLES']);
      sessionStorage.setItem('GET_SELECTED_STORY_IMAGE', tmpDict['GET_SELECTED_STORY_IMAGE']);
    }
  }

  getSessionData(key: string): any {
    return JSON.parse(sessionStorage.getItem(key));
  }

  setSessionData(key: string, JsonData: any): void {
    sessionStorage.setItem(key, JSON.stringify(JsonData));
  }

  // Local data

  getLocalData(key: string): any {
    return JSON.parse(localStorage.getItem(key));
  }

  setLocalData(key: string, JsonData: any): void {
    localStorage.setItem(key, JSON.stringify(JsonData));
  }

  removeLocalData(key = null): void {
    if (key) {
      localStorage.removeItem(key);
    } else {
      localStorage.clear();
    }
  }

  objectIndexOf(array: any[], value: any, key = '_id'): number {
    if (value) {
      if (key != null) {
        for (let i = 0, len = array.length; i < len; i++) {
          if (array[i][key] === value[key]) {
            return i;
          }
        }
      } else {
        for (let i = 0, len = array.length; i < len; i++) {
          if (array[i] === value) {
            return i;
          }
        }
      }
    }
    return -1;
  }

  getOnlyIds(array: any, key = '_id') {
    const res = [];
    array.forEach((item) => {
      res.push(item[key]);
    });
    return res;
  }

  removeNullObjects(object) {
    for (const key in object) {
      if (object[key] === null || object[key] === undefined) {
        delete object[key];
      } else if (object[key] instanceof Array && !object[key].length) {
        delete object[key];
      }
    }
    return object;
  }


  addHttp(url) {
    if (!/^(?:f|ht)tps?\:\/\//.test(url)) {
      url = 'http://' + url;
    }
    return url;
  }

  openSnackBar(type, message, duration = 1500) {
    this.snackBar.open(message, 'X', {
      duration: duration,
      panelClass: type,
      horizontalPosition: 'end',
      verticalPosition: 'top'
    });
  }

  openDirtyDialog() {
    return this.dialog.open(ConfirmDialog, {
      width: '478px',
      maxHeight: '95vh',
      data: {
        title: 'Confirm',
        message: Messages.DISCARD_MESSAGE,
        toShowCancel: true,
        buttonYesCaption: 'Yes',
        buttonNoCaption: 'No'
      }
    });
  }

  unSavedDialog(message) {
    return this.dialog.open(ConfirmDialog, {
      width: '478px',
      maxHeight: '95vh',
      disableClose: true,
      data: {
        title: 'Confirm',
        message: message,
        toShowCancel: true,
        buttonYesCaption: 'Yes',
        buttonNoCaption: 'No'
      }
    });
  }

  openErrorDialog(message, errorList) {
    return Observable.create((observer: Observer<boolean>) => {
      const dialogRef = this.dialog.open(ConfirmDialog, {
        width: '478px',
        maxHeight: '95vh',
        data: {
          title: 'Error',
          message: message,
          errorList: errorList,
          toShowCancel: false,
          buttonYesCaption: 'Okay',
        }
      });

      dialogRef.afterClosed().subscribe((result) => {
        observer.next(result);
        observer.complete();
      });
    });
  }

  getMenuAccordionList(): Observable<Menu[]> {
    return of(accordionMenu);
  }

  compareValues(order = 'asc', key = 'name') {
    return function innerSort(a, b) {
      if (!a[key] || !b[key]) {
        return 0;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  isReportingOn() {
    if (environment.reporting)
      return true;
    else return false;
  }
  getSelectedValue(idValue, idsList) {
    const isPresent = idsList.some(function (el) { return el._id === idValue; });
    if (isPresent) {
      return idValue;
    }
    return null;
  }
  // eslint-disable-next-line complexity
  getMultipleSelectOptions(self, field: string, metric = null, key = 'name', onlyTypeHead = false, isDp = true, isReportFilter = false, isSearchFilter = false): any[] {
    let input = document.getElementById(field);
    if (metric) {
      input = document.getElementById(field + '_' + metric._id);
    }
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    if (onlyTypeHead && input && input['value'].trim() == '') {
      filterValue = null;
    }
    let options = [];
    const optionsList = this.filterOptions(self, field, isDp);
    if (field == 'group' || field == 'workstream' || field == 'location') {
      options = optionsList;
    } else if (field == 'organization') {
      const orgList = this.filterOptionsByOrg(self, field, isReportFilter, isSearchFilter);
      options = orgList;
    } else if (field == 'nested_themes') {
      const nestedList = this.filterOptionsByNestedThemes(self, field);
      options = nestedList;
    } else if (field == 'deliverable_type' && metric) {
      let org_type = [];
      if (self.filterForm.get('organization')) {
        org_type = self.filterForm.get('organization').value;
      }
      if (org_type && org_type.length) {
        org_type.forEach((org) => {
          metric.deliverable_types.forEach((delType) => {
            if (org.name == delType.organizationType.name) {
              if (this.objectIndexOf(options, delType) < 0) {
                options.push(delType);
              }
            }
          });
        });
        options.forEach((o) => {
          o.total_deliverable = 0;
          const selectedOrgs = org_type.map((o) => o.name);
          if (selectedOrgs.includes(OrgType.LABS)) {
            o.total_deliverable += o.total_labs;
          }
          if (selectedOrgs.includes(OrgType.LIQUID)) {
            o.total_deliverable += o.total_ls;
          }
          if (selectedOrgs.includes(OrgType.INCUBATION)) {
            o.total_deliverable += o.total_inc;
          }
        });
      } else {
        options = metric.deliverable_types;
        options.forEach((o) => o.total_deliverable = o.total_labs + o.total_ls + o.total_inc);
      }
    } else if (field == 'deliverable_type') {
      options = this.filterOptionsByDeliverableTypes(self, options, field);
    } else {
      options = self.options[field];
      if (field === 'project') {
        options = options.filter((o) => { return o.workflow_status !== 'Draft' && o.workflow_status !== null; });
      }
    }

    if (options && options.length > 0 && field != 'keyword' && field != 'client' && field != 'market') {
      options = _.uniqBy(options, 'name');
    } else {
      options = _.uniq(options);
    }

    if (filterValue) {
      if (key != null) {
        return options.filter((option) => option[key].toLowerCase().includes(filterValue));
      }
      return options.filter((option) => option.toLowerCase().includes(filterValue));
    } else if (onlyTypeHead) {
      return [];
    }

    return options;
  }
  filterOptionsByNestedThemes(self, field): any[] {
    let options = [];
    let forces = [];
    if (self.filterForm.get('force')) {
      forces = self.filterForm.get('force').value;
    }
    options = [];
    if (forces && forces.length > 0) {
      forces.forEach((item) => {
        self.options['nested_themes'].forEach((ele) => {
          if (item.name == ele.force.name) {
            if (this.objectIndexOf(options, ele) < 0) {
              options.push(ele);
            }
          }
        });
      });
    } else {
      options = self.options[field];
    }
    return options;
  }
  filterOptionsByDeliverableTypes(self: any, options: any[], field: string) {
    let org_type = [];
    let type = [];
    if (self.filterForm.get('organization')) {
      org_type = self.filterForm.get('organization').value;
    }
    if (self.filterForm.get('type')) {
      type = self.filterForm.get('type').value;
    }
    if (org_type && org_type.length) {
      org_type.forEach((org) => {
        self.options['deliverable_type'].forEach((delType) => {
          if (org.name == delType.organizationType.name) {
            if (this.objectIndexOf(options, delType) < 0) {
              options.push(delType);
            }
          }
        });
      });
    } else if (type && type.length > 0) {
      type.forEach((o) => {
        if (type.length == 1 && (o.name === 'Project' || o.name === 'Success Story')) {
          self.isShowDeliverableType = true;
          options = [];
        } else if (type.length == 2) {
          const isStory = type.filter((o) => o.name === 'Deliverable');
          if (isStory.length > 0) {
            self.isShowDeliverableType = false;
            options = self.options[field];
          } else {
            self.isShowDeliverableType = true;
            options = [];
          }
        }
        else {
          self.isShowDeliverableType = false;
          options = self.options[field];
        }
      });
    } else {
      self.isShowDeliverableType = false;
      options = self.options[field];
    }
    return options;
  }

  filterOptionsByOrg(self, field, isReportFilter, isSearchFilter): any[] {
    let options = [];
    let locations = [];
    let workstreams = [];
    if (self.filterForm.get('location')) {
      locations = self.filterForm.get('location').value;
    }
    if (self.filterForm.get('workstream')) {
      workstreams = self.filterForm.get('workstream').value;
    }
    const groups = self.filterForm.get('group').value;
    let deliverable_types = [];
    if (self.filterForm.get('deliverable_type')) {
      deliverable_types = self.filterForm.get('deliverable_type').value;
    }
    options = [];
    if (groups && groups.length > 0 || workstreams && workstreams.length > 0 || locations && locations.length > 0) {
      if (groups && groups.length) {
        this.filterOrgByGroups(groups, self, options);
      } else if (workstreams && workstreams.length) {
        this.filterOrgByWorkstreams(workstreams, self, options);
      } else {
        this.filterOrgByLocations(locations, self, options);
      }
      if (isReportFilter && deliverable_types && deliverable_types.length > 0) {
        this.filterOrgByDeliverableTypes(deliverable_types, self, options);
      }
    } else if (isReportFilter && deliverable_types && deliverable_types.length) {
      this.filterOrgByDeliverableTypes(deliverable_types, self, options);
    } else if (isSearchFilter && deliverable_types && deliverable_types.length) {
      this.searchPagefilterOrgByDeliverableTypes(deliverable_types, self, options);
    } else {
      options = self.options[field];
    }
    if (self.groupOrder) {
      options.sort(this.compareValues(self.groupOrder.internalOrder.locationOrder));
    }
    return options;
  }
  /**
   * filter organization by deliverable_types
   * @param deliverable_types
   * @param self
   * @param options
   */
  filterOrgByDeliverableTypes(deliverable_types: any[], self: any, options: any[]) {
    deliverable_types.forEach((delType) => {
      self.options['organization'].forEach((org) => {
        const selectedDeliverableType = self.addAllOfDeliverableTypes.filter((o) => o.name == delType.name);
        selectedDeliverableType.forEach((element) => {
          if (org.name == element.organizationType.name) {
            if (this.objectIndexOf(options, org) < 0) {
              options.push(org);
            }
          }
        });
      });
    });
  }

  searchPagefilterOrgByDeliverableTypes(deliverable_types: any[], self: any, options: any[]) {
    const allDeliverableTypes = self.options['deliverable_type'];
    const sameDeliverableTypeName = [];
    self.addAllOfDeliverableTypes.forEach((element) => {
      allDeliverableTypes.forEach((item) => {
        if (element.name === item.name) {
          sameDeliverableTypeName.push(item);
        }
      });
    });
    deliverable_types.forEach((delType) => {
      self.options['organization'].forEach((org) => {
        const selectedDeliverableType = sameDeliverableTypeName.filter((o) => o.name == delType.name);
        selectedDeliverableType.forEach((element) => {
          if (org.name == element.organizationType.name) {
            if (this.objectIndexOf(options, org) < 0) {
              options.push(org);
            }
          }
        });
      });
    });
  }

  /**
   * filter organization by locations
   * @param locations
   * @param self
   * @param options
   */
  filterOrgByLocations(locations: any[], self: any, options: any[]) {
    locations.forEach((location) => {
      self.options['organization'].forEach((org) => {
        const selectedLocations = this.allOfLocations.filter((o) => o.name == location.name);
        selectedLocations.forEach((s) => {
          if (s.organizationType.name == org.name) {
            if (this.objectIndexOf(options, org) < 0) {
              options.push(org);
            }
          }
        });
      });
    });
  }
  /**
   *  filter organization by workstreams
   * @param workstreams
   * @param self
   * @param options
   */
  filterOrgByWorkstreams(workstreams: any[], self: any, options: any[]) {
    workstreams.forEach((item) => {
      self.options['organization'].forEach((org) => {
        if (item.organizationType.name == org.name) {
          if (this.objectIndexOf(options, org) < 0) {
            options.push(org);
          }
        }
      });
    });
  }
  /**
   * filter organization by groups
   */
  filterOrgByGroups(groups: any, self: any, options: any[]) {
    groups.forEach((group) => {
      self.options['organization'].forEach((org) => {
        const selectedGroups = this.allOfGroups.filter((o) => o.name == group.name);
        selectedGroups.forEach((s) => {
          if (s.organizationType.name == org.name) {
            if (this.objectIndexOf(options, org) < 0) {
              options.push(org);
            }
          }
        });
      });
    });
  }

  // Group, Workstream, Location keep 3 ways smart associated filter
  // eslint-disable-next-line complexity
  filterOptions(self, field, isDp = true): any[] {
    let options = [];
    let org_type = [];
    let locations = [];
    let workstreams = [];
    if (self.filterForm.get('location')) {
      locations = self.filterForm.get('location').value;
    }
    if (self.filterForm.get('workstream')) {
      workstreams = self.filterForm.get('workstream').value;
    }
    const groups = self.filterForm.get('group').value;
    if (self.filterForm.get('organization')) {
      org_type = self.filterForm.get('organization').value;
    }
    if (field == 'group') {
      options = [];
      if (locations && locations.length) {
        this.filterGroupByLocation(workstreams, self, options, org_type, locations);
      } else if (workstreams && workstreams.length) {
        this.filterGroupByWorkstream(workstreams, self, options);
      } else if (org_type && org_type.length) {
        this.filterGroupByOrg(org_type, self, options);
      } else {
        options = self.options[field];
      }
      if (self.groupOrder) {
        options.sort(this.compareValues(self.groupOrder.order));
      }
      this.allOfGroups = options;
    } else if (field == 'workstream') {
      options = [];
      if (groups && groups.length) {
        this.filterWorkstreamByGroup(groups, options);
      } else if (locations && locations.length) {
        this.filterWorkstreamByLocation(locations, self, options, org_type);
      } else if (org_type && org_type.length) {
        this.filterWorkstreamByOrg(org_type, self, options);
      } else {
        this.filterAllWorkstreamByFromGroup(self, options);
      }
      if (self.groupOrder) {
        options.sort(this.compareValues(self.groupOrder.internalOrder.workstreamOrder));
      }
    } else if (field == 'location') {
      options = [];
      if (groups && groups.length) {
        this.filterLocationByGroup(groups, options);
      } else if (workstreams && workstreams.length) {
        this.filterLocationByWorkstream(workstreams, self, options);
      } else if (org_type && org_type.length) {
        this.filterLocationByOrg(org_type, self, options);
      } else {
        this.filterAllLocation(self, options, isDp);
      }
      if (self.groupOrder) {
        options.sort(this.compareValues(self.groupOrder.internalOrder.locationOrder));
      }
      this.allOfLocations = options;
    }
    return options;
  }

  filterAllLocation(self: any, options: any[], isDp: boolean) {
    self.options['group'].forEach((group) => {
      group['locations'].forEach((location) => {
        if (this.objectIndexOf(options, location) < 0) {
          options.push(location);
        }
      });
    });
    if (!isDp) {
      self.options['location'].forEach((location) => {
        if (this.objectIndexOf(options, location) < 0) {
          options.push(location);
        }
      });
    }
  }

  filterLocationByOrg(org_type: any[], self: any, options: any[]) {
    org_type.forEach((item) => {
      if (item.name === OrgType.LIQUID) {
        self.options['location'].forEach((location) => {
          if (this.objectIndexOf(options, location) < 0) {
            options.push(location);
          }
        });
      } else {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            if (item.name == location.organizationType.name) {
              if (this.objectIndexOf(options, location) < 0) {
                options.push(location);
              }
            }
          });
        });
      }
    });
  }

  filterLocationByWorkstream(workstreams: any, self: any, options: any[]) {
    workstreams.forEach((item) => {
      self.options['group'].forEach((group) => {
        group['workstreams'].forEach((workstream) => {
          if (item.name == workstream.name) {
            group['locations'].forEach((location) => {
              if (this.objectIndexOf(options, location) < 0) {
                options.push(location);
              }
            });
          }
        });
      });
    });
  }

  filterLocationByGroup(groups: any, options: any[]) {
    groups.forEach((group) => {
      const selectedGroups = this.allOfGroups.filter((o) => o.name == group.name);
      selectedGroups.forEach((item) => {
        item['locations'].forEach((location) => {
          if (this.objectIndexOf(options, location) < 0) {
            options.push(location);
          }
        });
      });
    });
  }

  filterAllWorkstreamByFromGroup(self: any, options: any[]) {
    self.options['group'].forEach((group) => {
      group['workstreams'].forEach((workstream) => {
        if (this.objectIndexOf(options, workstream) < 0) {
          options.push(workstream);
        }
      });
    });
  }

  filterWorkstreamByOrg(org_type: any[], self: any, options: any[]) {
    org_type.forEach((item) => {
      self.options['group'].forEach((group) => {
        group['workstreams'].forEach((workstream) => {
          if (item.name == workstream.organizationType.name) {
            if (this.objectIndexOf(options, workstream) < 0) {
              options.push(workstream);
            }
          }
        });
      });
    });
  }

  filterWorkstreamByLocation(locations: any, self: any, options: any[], org_type: any[]) {
    if (org_type && org_type.length) {
      org_type.forEach((org) => {
        locations.forEach((item) => {
          self.options['group'].forEach((group) => {
            group['locations'].forEach((location) => {
              if (org.name == group.organizationType.name && item.name == location.name) {
                group['workstreams'].forEach((workstream) => {
                  if (this.objectIndexOf(options, workstream) < 0) {
                    options.push(workstream);
                  }
                });
              }
            });
          });
        });
      });
    } else {
      locations.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            if (item.name == location.name) {
              group['workstreams'].forEach((workstream) => {
                if (this.objectIndexOf(options, workstream) < 0) {
                  options.push(workstream);
                }
              });
            }
          });
        });
      });
    }
  }

  filterWorkstreamByGroup(groups: any, options: any[]) {
    groups.forEach((group) => {
      const selectedGroups = this.allOfGroups.filter((o) => o.name == group.name);
      selectedGroups.forEach((item) => {
        item['workstreams'].forEach((workstream) => {
          if (this.objectIndexOf(options, workstream) < 0) {
            options.push(workstream);
          }
        });
      });
    });
  }

  filterGroupByOrg(org_type: any[], self: any, options: any[]) {
    org_type.forEach((item) => {
      self.options['group'].forEach((group) => {
        if (item.name == group.organizationType.name) {
          if (this.objectIndexOf(options, group) < 0) {
            options.push(group);
          }
        }
      });
    });
  }

  filterGroupByWorkstream(workstreams: any, self: any, options: any[]) {
    workstreams.forEach((item) => {
      self.options['group'].forEach((group) => {
        group['workstreams'].forEach((workstream) => {
          if (item.name == workstream.name) {
            if (this.objectIndexOf(options, group) < 0) {
              options.push(group);
            }
          }
        });
      });
    });
  }

  filterGroupByLocation(workstreams: any, self: any, options: any[], org_type: any[], locations: any) {
    if (workstreams && workstreams.length) {
      workstreams.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['workstreams'].forEach((workstream) => {
            if (item.name == workstream.name) {
              if (this.objectIndexOf(options, group) < 0) {
                options.push(group);
              }
            }
          });
        });
      });
    } else if (org_type && org_type.length) {
      org_type.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            locations.forEach((loc) => {
              if (item.name == group.organizationType.name && location.name === loc.name) {
                if (this.objectIndexOf(options, group) < 0) {
                  options.push(group);
                }
              }
            });
          });
        });
      });
    } else {
      locations.forEach((item) => {
        self.options['group'].forEach((group) => {
          group['locations'].forEach((location) => {
            if (item.name == location.name) {
              if (this.objectIndexOf(options, group) < 0) {
                options.push(group);
              }
            }
          });
        });
      });
    }
  }
  /**
   *
   * @param self:component
   * @param pageForm: page form
   */
  checkPartnersOption(self, pageForm) {
    const deliverable = pageForm.getRawValue();
    const partners = self.getMultipleSelectOptions('partners', 'name', false, true);
    const removePartners = [];
    deliverable.partners.forEach((item) => {
      const groupIndex = this.objectIndexOf(partners, item);
      if (groupIndex < 0) {
        removePartners.push(item);
      }
    });
    removePartners.forEach((ele) => {
      const partnerIndex = this.objectIndexOf(deliverable.partners, ele);
      if (partnerIndex >= 0) {
        deliverable.partners.splice(partnerIndex, 1);
      }
    });
    pageForm.get('partners').setValue(deliverable.partners, { emitEvent: false });
    self.dataProcessing = true;
  }
}
