<p id="headerStatus" class="table-title">Deliverable Status</p>
<hr>
<div fxLayout="row" fxLayoutGap="22px" class="dropdown-menu" *ngIf="!editSelectedStatus">
    <div fxFlex="15" class="form-group">
        <mat-select id="drpAll" placeholder="{{selectedOption}}">
            <mat-option id="allOption" [ngClass]="{'shift-icon':selectedOption !== 'All'}" [value]="'All'"><i *ngIf="selectedOption === 'All'" class="fa fa-check checked-icon"></i><i class="fa fa-circle-o active-inactive-icon"></i>All</mat-option>
        </mat-select>
    </div>
    <div fxFlex="17" class="button-display">
        <button id="btnAddNew" mat-stroked-button color="accent" (click)="addNewAdmin()"><i class="fa fa-plus-circle add-icon"></i>Add New</button>
    </div>
</div>
<div fxLayout="row" fxLayoutGap="22px" class="dropdown-menu" *ngIf="editSelectedStatus">
    <div fxFlex="15" class="form-group">
        <mat-select id="drpDelete" placeholder="Actions"  (selectionChange)="performAction($event.value)" [(value)]="actionPlaceholder">
            <mat-option id="optionDelete" [value]="'Delete'">Delete ({{checkedCount}})</mat-option>
        </mat-select>
    </div>
</div>
<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<table mat-table #table [dataSource] = "statusList" class = "mat-elevation-z8" cdkDropList (cdkDropListDropped)="dropTable($event)" aria-label="deliverable-status-data-table"> 
    <ng-container matColumnDef="{{header}}" *ngFor="let header of columns; let i=index">
      <ng-container *ngIf="header === 'checked'; else notSelect">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox id="selectAll" (change)="selectAll($event)" color="accent"><span style="display: none;">wav</span></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element;let j=index">
          <mat-checkbox id="selectOne{{j}}" [(ngModel)]="element.checked" [disabled]="element.disabled" (change)="selectOne(element)" color="accent"><span style="display: none;">wav</span></mat-checkbox>
          <i id="shiftIcon{{j}}" class="shift-icon fa fa-reorder"></i>
        </td>
  
      </ng-container>
      <ng-template #notSelect>
        <div *ngIf="i === 1; else noSort">       
          <th id="tableHeader{{i}}" mat-header-cell *matHeaderCellDef  > {{headersData[i]}}</th>
          <td id="tableData{{i}}{{j}}" (click)="clickElement(element)" class="highlightElement"  mat-cell *matCellDef="let element;let j=index" tabindex="0" (keydown.enter)="clickElement(element)"> {{element[header]}} </td>
        </div>    
          <ng-template #noSort>        
          <th id="tableHeader{{i}}" mat-header-cell *matHeaderCellDef> {{headersData[i]}}</th>        
          <td id="tableData{{i}}{{j}}" mat-cell *matCellDef="let element;let j=index"> {{element[header]}} </td>     
          </ng-template>
      </ng-template>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef = "columns"></tr>
    <tr mat-row *matRowDef = "let row; columns: columns;" cdkDrag [cdkDragData]="row"></tr>
  </table>