<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm" class="add-dialog" role="dialog" aria-labelledby="titleWorkstreamName">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('workstreamName').invalid }">
            <p id="titleWorkstreamName">Workstream Name</p>
            <input id="workstreamName" type="text" formControlName="workstreamName" class="form-control" placeholder="Workstream"  aria-label="Workstream Name" required  maxlength="128" (keyup)="changeWorkstreamValue(showForm.get('workstreamName').value)"/>
            <span id="workstreamNameLength" class="hint point">{{inputWorkstreamName ? inputWorkstreamName.length : 0}}/128</span>  
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('workstreamsGroup').invalid }">
            <p id="titleGroup">Group</p>
            <mat-select id="drpGroup" formControlName="workstreamsGroup" placeholder="Select" aria-label="Group" (selectionChange)="selectedGroupValue($event.value)">
                <mat-option id="group_option{{i}}" *ngFor="let option of data.groups;let i=index" [value]="option._id">{{option.name}}</mat-option>
            </mat-select>
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && (showForm.get('workstreamLead').invalid || selectedId =='') }">
            <p id="titleWorkstreamLead">Workstream Lead</p>
            <input id="workstreamLead" type="text" formControlName="workstreamLead" placeholder="Search People" class="form-control" [matAutocomplete]="users" value="{{input}}" (keyup)="onChangeUsers($event)"  aria-label="Workstream Lead" aria-describedby="workstreamLeadMessage"/>
            <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
            <mat-autocomplete id="workstreamLead_auto" #users="matAutocomplete" (optionSelected)="selectedValue($event)" [displayWith]="getWorkstreamLeadName" role="combobox">
                <mat-option id="workstreamLead_option{{i}}" *ngFor="let option of options.users;let i=index" [value]="option">{{option.display_name}}</mat-option>
            </mat-autocomplete>
            <span id="workstreamLeadMessage" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
    </div>
</form>
