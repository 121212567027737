/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';

export class AdminService {
  getUsersByRole(role): Observable<any> {
    return of(role);
  }

  addAdmin(params): Observable<any> {
    return of(params);
  }

  editAdmin(reqBody, params): Observable<any> {
    return of(reqBody, params);
  }
}