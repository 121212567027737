/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { MsalService } from '@azure/msal-angular';

import { Services } from '@app/services/services';
import { environment } from '@environments/environment';
import { Messages, Role } from '@app/models';
import { Menu } from '@app/models/menu';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  accordionMenuList: Menu[];
  route: string;
  contactUsLink: string;
  isShowStory: any = true;
  featureFlagForMySuccessStories: any = true;
  globalFlags = {};
  constructor(
    private router: Router,
    private location: Location,
    private authService: MsalService,
    public services: Services
  ) {
    this.contactUsLink = `mailto:${Messages.CONTACT_US_MAIL_TO}?subject=${Messages.CONTACT_US_MAIL_SUBJECT}&body=${Messages.CONTACT_US_MAIL_BODY}`;
  }

  ngOnInit(): void {
    this.route = this.location.path();
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.route = this.location.path();
      } else {
        this.route = '';
      }
    });
    this.services.commonService.getMenuAccordionList()
      .subscribe((accordionList: Menu[]) => {
        this.accordionMenuList = accordionList;
      });
    this.services.commonService.parnterNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.services.commonService.getMenuAccordionList()
          .subscribe((accordionList: Menu[]) => {
            if (this.services.commonService.globalFlags['hidePartners']) {
              accordionList.forEach((menu) => {
                menu.subMenu = menu.subMenu.filter((subMenu) => {
                  return subMenu.linkName != 'Partners';
                });
              });
              this.accordionMenuList = accordionList;
            }
          });
      }
    });
    this.isShowStory = environment.DISPLAY_STORY;
    this.featureFlagForMySuccessStories = JSON.parse(sessionStorage.getItem(environment.MY_SUCCESS_STORIES));
  }

  get Role(): any { return Role; }
  get Messages(): any { return Messages; }
  onSignOut(): void {
    if (!environment.demo) {
      this.authService.logout();
      this.services.commonService.removeSessionData();
    } else {
      this.services.authenticationService.logout();
      this.services.commonService.removeSessionData();
      this.router.navigate(['/login']);
    }
  }

  getAdminMenu(): boolean {
    let result = false;
    if (this.services.commonService.hasAnyRole([Role.MANAGER_ADMINISTRATOR])) {
      result = true;
    }
    return result;
  }

  isChildOf(path: string): boolean {
    if (!this.route) {
      if (this.location.path() !== '') {
        this.route = this.location.path();
      } else {
        this.route = '';
      }
    }
    const pattern = new RegExp(`^\/(${path})`);
    return pattern.test(this.route);
  }
}
