/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import { IndustryService } from '@app/services/industry.service';
import { CommonService } from '@app/services';

@Component({
  selector: 'app-add-industry',
  templateUrl: './add-industry.component.html',
  // styleUrls: ['./add-industry.component.scss'],
})
export class AddIndustryComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  submitted= false;
  selectedType: '';
  loadTeam: 0;
  input: string;
  selectedId: string;
  industryName = '';
  public loadVal =0;
  public inputIndustryName:any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonService,
    private industryService: IndustryService,
    public dialogRef: MatDialogRef<AddIndustryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if(this.data.value){
      this.industryName = this.data.value.name;
      this.inputIndustryName = this.industryName;
    }
    this.showForm = this.formBuilder.group({
      industryName: [{ value: this.industryName, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
    });
  }
  addNewData(): void {
    this.submitted = true;
    if (this._isValid()){
      this.loadVal++;
      const orgTypeName= this.data.orgTypeName;
      if(this.data.value){
        const body = { 'name': this.showForm.get('industryName').value.trim(),'orgTypeName':orgTypeName};
        this.industryService.editIndustries(this.data.value._id, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{
       
        const body = { 'name': this.showForm.get('industryName').value.trim(),'orgTypeName':orgTypeName};
        this.industryService.addIndustry(body).subscribe((response) => {
          if (response) {
            this.loadVal--;
            this.dialogRef.close(true);
          }
        }, (error1)=>{
          this.loadVal--;
          this.commonService.handleError(error1);
        });
      }
    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  _isValid(): boolean {
    if (this.showForm.valid) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  changeIndustryValue(value){
    this.inputIndustryName = value;
  }
}
