/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectsService } from '@app/services';

@Component({
  selector: 'app-project-reassign-dialog',
  templateUrl: 'project-reassign-dialog.html',
  // styleUrls: ['./project-dialog.components.scss']
})
export class ProjectReassignDialog implements OnInit {
  loading: any = 0;
  step: any = 1;
  reviewers: Array<any> = [];
  reassignForm: UntypedFormGroup;
  submitted: any = false;
  public inputComments: any = '';
  constructor(
    public dialogRef: MatDialogRef<ProjectReassignDialog>,
    @Inject(MAT_DIALOG_DATA) public project: any,
    private projectsService: ProjectsService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this._getReviewers();
    this.reassignForm = this.formBuilder.group({
      reason: ['CHANGE', Validators.required],
      recipient: [null],
      comments: [null, Validators.required],
    });
  }

  _getReviewers(): void {
    this.loading++;
    this.projectsService.getReviewers(this.project._id)
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.reviewers = response.result;
        }
      });
  }

  onNext(): void {
    this.submitted = true;
    if (this.step === 1) {
      if (this.reassignForm.get('reason').valid) {
        this.submitted = false;
        if (this.reassignForm.get('reason').value == 'CHANGE') {
          this.step = 3;
        } else {
          this.step++;
        }
      }
    } else if (this.step === 2) {
      if (this.reassignForm.get('recipient').value) {
        this.submitted = false;
        this.step++;
      }
    }
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onReassign(): void {
    this.submitted = true;
    if (this.reassignForm.valid && !this.loading) {
      this.loading++;
      if (this.reassignForm.get('reason').value == 'CHANGE') {
        this.projectsService.approveRework(this.project._id, this.reassignForm.getRawValue())
          .subscribe((response) => {
            this.loading--;
            if (response) {
              this.dialogRef.close(true);
            }
          });
      } else {
        this.projectsService.approveReassign(this.project._id, this.reassignForm.getRawValue())
          .subscribe((response) => {
            this.loading--;
            if (response) {
              this.dialogRef.close(true);
            }
          });
      }
    }
  }

  changeCommentValue(value) {
    this.inputComments = value;
  }
}

@Component({
  selector: 'app-project-approve-dialog',
  templateUrl: 'project-approve-dialog.html',
  // styleUrls: ['./project-dialog.components.scss']
})
export class ProjectApproveDialog {
  loading: any = 0;
  constructor(
    public dialogRef: MatDialogRef<ProjectApproveDialog>,
    private projectsService: ProjectsService,
    @Inject(MAT_DIALOG_DATA) public project: any,
  ) { }

  onCancel(): void {
    this.dialogRef.close();
  }

  onApprove(): void {
    if (!this.loading) {
      this.loading++;
      this.projectsService.approve(this.project._id)
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.dialogRef.close(true);
          }
        });
    }
  }
}

