import { trigger, transition, animate, style, state, group } from '@angular/animations';
export const Animations = {
  slideInOut: trigger('slideInOut', [
    state('in', style({ height: '*', opacity: 0 })),
    transition(':leave', [
      style({ height: '*', opacity: 1 }),

      group([
        animate(300, style({ height: 0 })),
        animate('200ms ease-in-out', style({ 'opacity': '0' }))
      ])

    ]),
    transition(':enter', [
      style({ height: '0', opacity: 0 }),

      group([
        animate(300, style({ height: '*' })),
        animate('400ms ease-in-out', style({ 'opacity': '1' }))
      ])

    ])
  ]),
  slideLeft: trigger('slideLeft', [
    state('in', style({ width: '*', opacity: 0 })),
    transition(':leave', [
      style({ width: '*', opacity: 1 }),

      group([
        animate(300, style({ width: 0 })),
        animate('200ms ease-in-out', style({ 'opacity': '0' }))
      ])

    ]),
    transition(':enter', [
      style({ width: '0', opacity: 0 }),

      group([
        animate(300, style({ width: '*' })),
        animate('400ms ease-in-out', style({ 'opacity': '1' }))
      ])

    ])
  ]),
  slideMenu: trigger('slideMenu', [
    state('in', style({ height: '*', opacity: 0 })),
    transition(':leave', [
      style({ height: '*', opacity: 1 }),

      group([
        animate(1000, style({ height: 0 })),
        animate('1200ms ease-in-out', style({ 'opacity': '0' }))
      ])

    ]),
    transition(':enter', [
      style({ height: '0', opacity: 0 }),

      group([
        animate(1000, style({ height: '*' })),
        animate('500ms ease-in-out', style({ 'opacity': '1' }))
      ])

    ])
  ])
};