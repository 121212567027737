/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';

export class LocationsService {
  getLocations(params): Observable<any> {
    return of(params);
  }

  addLocation(reqbody): Observable<any> {
    return of(reqbody);
  }

  editLocation(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  updateLocationStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}
