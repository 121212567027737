<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm"  class="add-dialog" role="dialog" aria-labelledby="titleGroup">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('group').invalid }">
            <p id="titleGroup" >Group</p>
            <mat-select id="drpGroup" formControlName="group" class="form-control" placeholder="Select" aria-label="Group" [compareWith]="onCompareObjects" (selectionChange)="selectedGroupValue($event.value)">
                <mat-option id="group_option{{i}}" *ngFor="let option of groups;let i=index" [value]="option" >{{option.name}}</mat-option>
            </mat-select>
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('groupLocation').invalid }">
            <p id="titleLocation">Location</p>
            <mat-select id="drpLocation" formControlName="groupLocation" class="form-control" aria-label="Location" placeholder="Select" (selectionChange)="selectedLocationsValue($event.value)">
                <mat-option id="location_option{{i}}" *ngFor="let option of options.locations;let i=index" [value]="option._id" >{{option.name}}</mat-option>
            </mat-select>
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && (showForm.get('groupUsers').invalid || selectedId == '' ) }">
            <p id="titleGroupLocationLead">Group Location Lead</p>
            <input type="text" id="users" formControlName="groupUsers" placeholder="Search People" class="form-control" [matAutocomplete]="users" value="{{groupLocationLeadName}}" (keyup)="onChangeUsers($event)" aria-label="Group Location Lead" aria-describedby="usersMessage"/>
            <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
            <mat-autocomplete id="users_auto" #users="matAutocomplete" (optionSelected)="selectedValue($event)" [displayWith]="getGroupLocationLeadName" role="combobox">
                <mat-option id="users_option{{i}}" *ngFor="let option of options.users;let i=index" [value]="option">{{option.display_name}}</mat-option>
            </mat-autocomplete>
            <span id="usersMessage" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
    </div>
</form>
