/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Observable, Observer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

import { Services } from '@app/services/services';
import { User, Messages } from '@app/models';
import { WorkflowStatus } from '@app/models';
import { LocationsService } from '@app/services/location.service';
import { CommonService } from '@app/services';
import { OrgType } from '@app/models/organizationType';
import { PartnerService } from '@app/services/partner.service';

@Component({
  selector: 'app-deliverable-edit',
  templateUrl: './deliverable-edit.component.html',
  styleUrls: ['./deliverable-edit.component.scss']
})
export class DeliverableEditComponent implements OnInit {
  loading: any = 0;
  loadKeywords: any = 0;
  loadPartners: any = 0;
  loadOwner: any = 0;
  loadClient: any = 0;
  loadTeam: any = 0;
  isLiquidStudio: any = false;
  loadRelatedDeliverables: any = 0;
  incomplete: any = false;
  routerWatcher: any;
  isNew: any = true;
  isSaved: any = false;
  file: any = null;
  deliverableForm: UntypedFormGroup;
  submitted: any = false;
  draftSubmitted: any = false;
  imgURL: any = null;
  maxFileSize: any = false;
  deliverableId: any = null;
  deliverable: any = null;
  ownerChanged: Subject<string> = new Subject<string>();
  clientChanged: Subject<string> = new Subject<string>();
  teamMembersChanged: Subject<string> = new Subject<string>();
  keywordChanged: Subject<string> = new Subject<string>();
  partnerChanged: Subject<string> = new Subject<string>();
  groupOrder: any = null;
  options: any = {
    deliverable_type: [],
    initiatives: [],
    deliverable_status: [],
    client: [],
    eac_qrtr: [],
    owner: [],
    groups: [],
    project: [],
    related_deliverables: [],
    team_members: [],
    keywords: [],
    industries: [],
    partners: []
  }
  loginUser: User = null;
  formDisabled: any = false;
  deliverableName: any = '';
  shortDesc: any = '';
  longDesc: any = '';
  createOrgTitle: any = '';
  editOrgTitle: any = '';
  orgTypeName: any = '';
  orgType: any = {};
  locationList: any;
  otherLocationsOptions: any = [];
  currentDeliverableStatus: any = '';
  previousDeliverableStatus: any = '';
  currentDeliverableType: any = '';
  previousDeliverableType: any = '';
  showAllClientInfo: any = '';
  currentUrl = '';
  hidePartners;
  dataProcessing: any = false;
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public services: Services,
    private locationsService: LocationsService,
    private commonService: CommonService,
    private partnerService: PartnerService
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.services.commonService.removeSessionData('LIVE_PAGINATION');
    this.services.commonService.removeSessionData('DRAFT_PAGINATION');
    this.services.commonService.removeSessionData('SEARCH_PAGINATION');
    this.services.commonService.removeSessionData('REPORTING_PAGINATION');

    this.services.deliverableEditCommonService.getInitiatives(this);
    this.services.deliverableEditCommonService.getDeliverableStatus(this);
    this.services.deliverableEditCommonService.getIndustries(this);
    this.getLocations();

    this.services.commonService.showHeader.emit(true);
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.isNew = true;
      this.deliverableId = null;
      this.submitted = false;
      this.deliverable = null;
      this.deliverableForm = null;
      this.isSaved = false;
      this.file = null;
      this.draftSubmitted = false;
      this.imgURL = null;
      this.deliverableName = null;
      this.shortDesc = null;
      this.longDesc = null;
      this.loading++;
      this.services.masterService.getQuarters()
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.options.eac_qrtr = response.end_quarter;
            this.options.fiscal_year_default_value = response.fiscal_year_default_value;
            if (params.deliverable_id !== 'new') {
              this.isNew = false;
              this.deliverableId = params.deliverable_id;
              this.loading++;
              this.services.deliverableService.getDeliverable(this.deliverableId)
                .subscribe((response) => {
                  this.loading--;
                  if (response && response.result) {
                    this.deliverable = response.result;
                    this.deliverable = { ...response.result, ...response.metadata };
                    this.deliverableName = this.deliverable.name;
                    this.currentDeliverableType = this.deliverable.deliverable_type?.name;
                    this.currentDeliverableStatus = this.deliverable.deliverable_status;
                    this.orgType = this.deliverable.organization_type;
                    this.orgTypeName = this.deliverable.organization_type.name;
                    this.editOrgTitle = 'Edit ' + this.orgTypeName + ' Deliverable';
                    this.getRelatedOrgData();
                    this.getPartners();
                    this.shortDesc = this.deliverable.short_description;
                    this.longDesc = this.deliverable.long_description;
                    if (this.deliverable.customer_number && this.deliverable.customer_name && this.deliverable.country_key) {
                      this.showAllClientInfo = this.deliverable.customer_number + ' - ' + this.deliverable.customer_name + ' - ' + this.deliverable.country_key;
                    }
                    if (!this.deliverable.canEdit && (!this.deliverable.canEditInitiative || this.orgTypeName == OrgType.LIQUID)) {
                      this.location.back();
                      this.services.commonService.openSnackBar('warning', Messages.EDIT_ERROR_MESSAGE, 5000);
                      return;
                    }
                    if (this.deliverable.workflow_status != WorkflowStatus.DRAFT) {
                      this.isSaved = true;
                    }
                    if (this.deliverable.project != undefined && this.deliverable.project.workflow_status == WorkflowStatus.DRAFT) {
                      this.deliverable.project = null;
                    }
                    if (this.orgTypeName == 'Labs') {
                      this.services.deliverableEditCommonService.createForm(this, this.deliverable);
                    } else if (this.orgTypeName == 'Liquid Studio') {
                      this.services.deliverableEditCommonService.createLiquidForm(this, this.deliverable);
                      this.isLiquidStudio = true;
                    } else if (this.orgTypeName == 'Incubation' || this.orgTypeName == 'Open Innovation / Ventures') {
                      this.services.deliverableEditCommonService.createIncubationForm(this, this.deliverable);
                    } else if (this.orgTypeName == OrgType.METAVERSE) {
                      this.services.deliverableEditCommonService.createMetaverseForm(this, this.deliverable);
                    }
                    if (this.deliverable.image_link) {
                      this.services.deliverableEditCommonService.getDeliverableImage(this, this.deliverableId);
                    }
                    this.route.queryParams.subscribe((params) => {
                      if (params.incomplete) {
                        this.incomplete = true;
                        this.submitted = true;
                        this.gotoIncompleteField();
                      }
                    });
                  }
                });
            } else {
              this.orgType = this.services.commonService.getSessionData('GET_DELIVERABLE_ORG');
              if (this.orgType) {
                this.orgTypeName = this.orgType.name;
                this.createOrgTitle = 'New ' + this.orgTypeName + ' Deliverable';
              }
              if (this.orgTypeName == 'Liquid Studio') {
                this.isLiquidStudio = true;
              }
              this.getRelatedOrgData();
              this.getPartners();
              this.services.commonService.removeSessionData('deliverable-list-filter');
              const unsavedDeliverable = this.services.commonService.getSessionData('UNSAVED_DELIVERABLE');
              if (unsavedDeliverable) {
                setTimeout(() => {
                  const dialogRef = this.services.commonService.unSavedDialog(Messages.UNSAVED_DRAFT_MESSAGE);

                  dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                      if (this.orgTypeName == 'Labs') {
                        this.services.deliverableEditCommonService.createForm(this, unsavedDeliverable);
                      } else if (this.orgTypeName == 'Liquid Studio') {
                        this.services.deliverableEditCommonService.createLiquidForm(this, unsavedDeliverable);
                      } else if (this.orgTypeName == 'Incubation' || this.orgTypeName == 'Open Innovation / Ventures') {
                        this.services.deliverableEditCommonService.createIncubationForm(this, unsavedDeliverable);
                      } else if (this.orgTypeName == OrgType.METAVERSE) {
                        this.services.deliverableEditCommonService.createMetaverseForm(this, this.deliverable);
                      }
                      if (this.deliverableForm) {
                        this.deliverableForm.markAsDirty();
                      }
                    } else {
                      if (this.orgTypeName == 'Labs') {
                        this.services.deliverableEditCommonService.createForm(this);
                      } else if (this.orgTypeName == 'Liquid Studio') {
                        this.services.deliverableEditCommonService.createLiquidForm(this);
                      } else if (this.orgTypeName == 'Incubation' || this.orgTypeName == 'Open Innovation / Ventures') {
                        this.services.deliverableEditCommonService.createIncubationForm(this);
                      } else if (this.orgTypeName == OrgType.METAVERSE) {
                        this.services.deliverableEditCommonService.createMetaverseForm(this, this.deliverable);
                      }
                      this.services.commonService.removeSessionData('UNSAVED_DELIVERABLE');
                    }
                  });
                }, 3000);
              } else {
                // this.services.deliverableEditCommonService.createForm(this);
                // eslint-disable-next-line no-lonely-if
                if (this.orgTypeName == 'Labs') {
                  this.services.deliverableEditCommonService.createForm(this);
                } else if (this.orgTypeName == 'Liquid Studio') {
                  this.services.deliverableEditCommonService.createLiquidForm(this);
                } else if (this.orgTypeName == 'Incubation' || this.orgTypeName == 'Open Innovation / Ventures') {
                  this.services.deliverableEditCommonService.createIncubationForm(this);
                } else if (this.orgTypeName == OrgType.METAVERSE) {
                  this.services.deliverableEditCommonService.createMetaverseForm(this, this.deliverable);
                }
              }
            }
          }
        });
    });
    this.services.commonService.parnterNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
      }
    });
    this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
    this.ownerChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        this.services.deliverableEditCommonService.ownerChanged(this, filterValue);
      });

    this.clientChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        this.services.deliverableEditCommonService.clientChanged(this, filterValue);
      });

    this.teamMembersChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['team_members'] = [];
          this.services.deliverableEditCommonService.getTeamMembers(this, filterValue);
        }
      });

    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['keywords'] = [];
          this.services.deliverableEditCommonService.getKeywords(this, filterValue);
        }
      });
  }

  getRelatedOrgData() {
    this.services.deliverableEditCommonService.getGroups(this);
    this.services.deliverableEditCommonService.getProjects(this);
    this.services.deliverableEditCommonService.getDeliverableTypes(this);
  }

  getLocations(): void {
    this.loading++;
    const params = { active: 'true', orgTypeName: 'LiquidStudio' };
    this.locationsService.getLocations(params).subscribe((response) => {
      if (response && response.result) {
        this.loading--;
        this.locationList = response.result;
      }
    }, (error1) => {
      this.loading--;
      this.commonService.handleError(error1);
    });
    return this.locationList;
  }

  getPartners(): void {
    this.loading++;
    const params = { active: true };
    this.partnerService.getPartners(params).subscribe((response) => {
      if (response && response.result) {
        this.loading--;
        this.options.partners = response.result.filter((partner) => {
           //commented the below code to make partners available globally
          // return partner.parnter_multiple_org.some((org) => org.name == this.orgTypeName);
          return partner.parnter_multiple_org;
        });
      }
      if (this.deliverableForm) {
        this.services.commonService.checkPartnersOption(this, this.deliverableForm);
      }
    }, (error1) => {
      this.loading--;
      this.commonService.handleError(error1);
    });
  }

  getOtherLocations() {
    const input = document.getElementById('other_locations');
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    const primaryLocation = this.deliverableForm.get('primary_location');
    if (primaryLocation.value) {
      this.otherLocationsOptions = this.locationList.filter((o) => { return o._id != primaryLocation.value._id; });
    }
    if (filterValue) {
      return this.otherLocationsOptions.filter((option) => {
        return option['name'] && (option['name'].toLowerCase().includes(filterValue) || Object.keys(option).some((k) => option[k] && option[k].toString().toLowerCase().includes(filterValue)));
      });
    }
    return this.otherLocationsOptions;
  }

  canDeactivate() {
    if (this.deliverableForm && this.deliverableForm.dirty) {
      return Observable.create((observer: Observer<boolean>) => {
        const dialogRef = this.services.commonService.openDirtyDialog();
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.services.commonService.removeSessionData('UNSAVED_DELIVERABLE');
          } else {
            this.location.go(this.currentUrl);
          }
          observer.next(result);
          observer.complete();
        });
      });
    }
    return true;
  }

  get Messages(): any { return Messages; }

  _afterCreateForm() {
    this.imgURL = this.services.deliverableCommonService.defaultDeliverableImage;
    this.services.deliverableEditCommonService.afterCreateForm(this);
    if (this.isNew && this.options.fiscal_year_default_value) {
      this.deliverableForm.get('eac_qrtr').setValue(this.options.fiscal_year_default_value.qtr, { emitEvent: false });
      this.deliverableForm.get('eac_qrtr_year').setValue(this.options.fiscal_year_default_value.year, { emitEvent: false });
    }
    this.deliverableForm.get('partners').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.services.commonService.checkPartnersOption(this, this.deliverableForm);
      });
    this.deliverableForm.get('other_groups').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this._enableControls();
      });

    this.deliverableForm.get('name').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.deliverableName = this.deliverableForm.get('name').value;
      });

    this.deliverableForm.get('short_description').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.shortDesc = this.deliverableForm.get('short_description').value;
      });

    this.deliverableForm.get('long_description').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.longDesc = this.deliverableForm.get('long_description').value;
      });

    this.deliverableForm.get('other_workstreams').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this._enableControls();
      });

    this.deliverableForm.valueChanges.subscribe(() => {
      if (this.isNew) {
        this.services.commonService.setSessionData('UNSAVED_DELIVERABLE', this._getFromData());
      }
    });
  }

  _enableControls() {
    if (this.options['groups'].length || this.orgTypeName == 'Liquid Studio') {
      this.services.deliverableEditCommonService.primaryOptionCheck(this);
      if (this.orgTypeName != 'Liquid Studio') {
        this.services.deliverableEditCommonService.otherOptionCheck(this);
      }
      this.services.deliverableEditCommonService.enableControls(this);
    }
  }

  getRelatedDeliverablesParam() {
    const deliverable = this.deliverableForm.getRawValue();
    const param = {
      deliverableId: deliverable._id ? deliverable._id : null,
      eso_id: deliverable.owner ? deliverable.owner.eso_id : null,
      group: deliverable.primary_group ? deliverable.primary_group._id : null,
      workstream: deliverable.primary_workstream ? deliverable.primary_workstream._id : null,
      location: deliverable.primary_location ? deliverable.primary_location._id : null,
      project: deliverable.project ? deliverable.project._id : null,
      other_workstreams: deliverable.other_workstreams.length ? this.services.commonService.getOnlyIds(deliverable.other_workstreams) : null,
      other_groups: deliverable.other_groups.length ? this.services.commonService.getOnlyIds(deliverable.other_groups) : null,
      other_locations: deliverable.other_locations.length ? this.services.commonService.getOnlyIds(deliverable.other_locations) : null,
      team_members: deliverable.team_members.length ? this.services.commonService.getOnlyIds(deliverable.team_members, 'eso_id') : null,
      orgTypeName: this.orgTypeName
    };
    this.services.deliverableEditCommonService.getRelatedDeliverables(this, param);
  }


  getImagePreview(files: any): void {
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const limit = 5 * 1024 * 1024;
    if (files[0].size > limit) {
      this.maxFileSize = true;
      this.file = null;
      this.imgURL = this.services.deliverableCommonService.defaultDeliverableImage;
      return;
    } else {
      this.maxFileSize = false;
    }
    this.file = files[0];
    this.deliverableForm.markAsDirty();
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imgURL = reader.result;
    };
  }
  replacePhoto() {
    // Your code to handle the photo replacement functionality
    // For example, triggering a click on the file input to open the file picker
    const fileInput = document.getElementById('deliverable_image');
    if (fileInput) {
      fileInput.click();
    }
  }
  
  onChangeOwner(event): void {
    this.services.deliverableEditCommonService.onChangeOwner(this, event);
  }

  onChangeClient(event): void {
    this.services.deliverableEditCommonService.onChangeClient(this, event);
  }

  onChangeTeamMembers(event): void {
    this.options['team_members'] = [];
    this.teamMembersChanged.next(event.target.value);
  }

  onChangeKeyWord(event): void {
    this.options['keywords'] = [];
    this.keywordChanged.next(event.target.value);
  }

  getGroupMaster(group) {
    return this.services.deliverableEditCommonService.getGroupMaster(this, group);
  }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    return this.services.deliverableEditCommonService.getMultipleSelectOptions(this, field, key, onlyTypeHead, withoutFilter);
  }

  getMultipleSelectValues(field: string): any {
    return this.deliverableForm.get(field).value;
  }

  filterSelectPartners(): any {
    const deliverable_type = this.deliverableForm.get('deliverable_type').value?.name;
    const partnerValue = this.deliverableForm.get('partners').value;
    const partnerList = [];
    if (deliverable_type == 'University Relationships') {
      partnerValue.forEach((item) => {
        if (item?.partnerType == 'University') {
          partnerList.push(item);
        }
      });
      this.deliverableForm.get('partners').setValue(partnerList);
    } else if (deliverable_type == 'Vendor Relationships') {
      partnerValue.forEach((item) => {
        if (item?.partnerType == 'Vendor') {
          partnerList.push(item);
        }
      });
      this.deliverableForm.get('partners').setValue(partnerList);
    }
    else {
      this.deliverableForm.get('partners').setValue(partnerValue);
    }
    return this.deliverableForm.get('partners').value;
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    this.services.deliverableEditCommonService.onAddMultipleSelectValue(this, field, event, allowEnter, key);
  }

  onAddSelectValue(field: string, event: MatAutocompleteSelectedEvent): void {
    this.services.deliverableEditCommonService.onAddSelectValue(this, field, event);
  }

  onAddClientValue(field: string, event: MatAutocompleteSelectedEvent): void {
    this.services.deliverableEditCommonService.onAddClientValue(this, field, event);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.deliverableEditCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }

  getQuartsOptions(field: string): any[] {
    return this.services.deliverableEditCommonService.getQuartsOptions(this, field);
  }

  canInitiativeAccess(initiative) {
    return this.services.deliverableEditCommonService.canInitiativeAccess(this, initiative);
  }

  isValueNotFoundInOptions(field: string, key: string): boolean {
    return this.services.deliverableEditCommonService.isValueNotFoundInOptions(this, field, key);
  }

  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }

  onAddUrl(): any {
    this.services.deliverableEditCommonService.onAddUrl(this);
  }

  onRemoveUrl(value: any): void {
    this.services.deliverableEditCommonService.onRemoveUrl(this, value);
  }

  onCancel(): void {
    this.currentUrl = this.location.path();
    this.location.back();
  }

  gotoIncompleteField(): void {
    if (this.validForm) {
      const invalidControl = this.validForm.nativeElement.querySelector('.incomplete');
      if (invalidControl) {
        invalidControl.focus();
      }
    }
  }

  _isValid(): boolean {
    if (this.deliverableForm.valid && !this.maxFileSize) {
      // if (!this.hidePartners && ((this.deliverableForm.get('deliverable_type').value?.name == 'Vendor Relationships' ||
      //   this.deliverableForm.get('deliverable_type').value?.name == 'University Relationships' ||
      //   this.deliverableForm.get('deliverable_type').value?.name == 'Client Pilots / Joint Innovation Projects'
      // ) &&
      //   !this.deliverableForm.get('partners').value?.length)
      // ) {
      //   this.invalidFocus();
      //   return false;
      // }
      return true;
    }
    this.invalidFocus();
    return false;
  }

  _isValidDraft(): boolean {
    const isValidQuarter = this.deliverableForm.get('eac_qrtr').invalid && this.deliverableForm.get('eac_qrtr').errors && this.deliverableForm.get('eac_qrtr').errors.mustLessThenMatch;
    if (this.deliverableForm.get('name').valid && this.deliverableForm.get('owner').valid && !this.maxFileSize && !isValidQuarter) {
      return true;
    }
    this.invalidFocus();
    return false;
  }

  invalidFocus() {
    this.services.deliverableEditCommonService.invalidFocus(this);
  }

  _formReset() {
    this.services.deliverableEditCommonService.formReset(this);
    setTimeout(() => {
      this.services.commonService.removeSessionData('UNSAVED_DELIVERABLE');
    }, 100);
  }

  _getFromData() {
    return this.services.deliverableEditCommonService.getFromData(this);
  }

  onSaveDraft(): void {
    this.submitted = false;
    this.draftSubmitted = true;
    if (this._isValidDraft() && !this.loading) {
      if (this.isNew) {
        this.loading++;
        this.services.deliverableService.saveDraft(this.services.commonService.removeNullObjects(this._getFromData()), this.file)
          .subscribe((response) => {
            this.services.deliverableEditCommonService.afterSave(this, response, true, true);
          });
      } else {
        this.loading++;
        this.services.deliverableService.updateDraft(this.deliverableId, this.services.commonService.removeNullObjects(this._getFromData()), this.file)
          .subscribe((response) => {
            this.services.deliverableEditCommonService.afterSave(this, response, false, true);
          });
      }
    }
  }

  onSave(): void {
    this.submitted = true;
    if (this._isValid() && !this.loading) {
      this.services.deliverableEditCommonService.setDefaultStatus(this);
      this.loading++;
      if (this.isNew) {
        this.services.deliverableService.save(this.services.commonService.removeNullObjects(this._getFromData()), this.file, this.orgTypeName)
          .subscribe((response) => {
            this.services.deliverableEditCommonService.afterSave(this, response, true, false);
          });
      } else {
        this.services.deliverableService.update(this.deliverableId, this.services.commonService.removeNullObjects(this._getFromData()), this.file, this.orgTypeName)
          .subscribe((response) => {
            this.services.deliverableEditCommonService.afterSave(this, response, false, false);
          });
      }
    }
  }
}
