/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { MetricService } from '../metric.service';

@Injectable({
  providedIn: 'root'
})
export class MetricApiService implements MetricService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getMetrics(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/metrics`, this.commonService.httpOptions(params));
  }
  addMetrics(reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/metrics`, reqbody, this.commonService.httpOptions());
  }

  updateMetricsStatus(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/metrics/archiveind`, reqBody, this.commonService.httpOptions(params));
  }
  editMetrics(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/metrics/update/${params}`, reqBody, this.commonService.httpOptions());
  }
}

