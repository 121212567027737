/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GroupLocationLeadService } from '../group-location-lead.service';
import { CommonService } from '../common.service';
@Injectable()
export class GroupLocationLeadMockService implements GroupLocationLeadService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getGroupLocationLeads(params): Observable<any> {
    return this.http.get('/assets/json/demo/groupLocationLead.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  addGroupLocationLead(reqbody): Observable<any> {
    return of(reqbody);
  }
  editGroupLocationLead(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  updateGroupLocationLeadStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}
