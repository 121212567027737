<div class="kpi-content">
    <div class="kpi-section">
        <div id="kpi" class="kpi-wording">KPI</div> 
            <div class="sec-btn-wrap" *ngIf="false">
                <button id="btnExportPpt" mat-stroked-button color="accent" aria-label="button Icon">
                    <div fxLayout="row" class="btn-inner">
                        <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24.6" viewBox="0 0 24 24.6">
                            <path fill="none" d="M0 0h24v24.6H0z" />
                            <path fill="#004dff" d="M8.71 7.71L11 5.41V15a1 1 0 0 0 2 0V5.41l2.29 2.3a1 1 0 1 0 1.42-1.42l-4-4a1.034 1.034 0 0 0-1.42 0l-4 4a1 1 0 0 0 1.42 1.42zM21 14a1 1 0 0 0-1 1v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-4a1 1 0 1 0-2 0v4a3 3 0 0 0 3 3h14a3 3 0 0 0 3-3v-4a1 1 0 0 0-1-1z" transform="translate(0 -.005)" />
                        </svg>
                        <span>Export Report(PPT)</span>
                    </div>
                </button>
            </div>
    </div>
    <div class="kpi-table">
        <div id="loading" *ngIf="loading" class="loading-wrap">
            <span>Loading...</span>
        </div>
        <div id="noResults" *ngIf="!loading && !deliverables" class="loading-wrap">
            <span>No results</span>
        </div>
        <div *ngIf="!loading && deliverables">
            <div class="kpi-table-header">
                <div id="row_labels" class="title">Metrics</div>
                <div id="count_of_delName" class="title total-deliverables">Count</div>
            </div>
            <div class="kpi-table-body" *ngFor="let metric of result.metric;let i=index;">
                <div id="metric_name{{i}}" class="sub-title metric-name">{{metric.name}}</div>
                <div id="metric_count{{i}}" class="sub-title metric-count">{{metric.count}}</div>
                <div class="deliverable-types"  *ngFor="let deliverable of metric.detail;let j=index;">
                    <div id="deliverable_type_name{{j}}" class="deliverable-name">{{deliverable.deliverable_type_name}}</div>
                    <div id="deliverable_type_count{{j}}" class="deliverable-count">{{deliverable.count}}</div>
                </div>
            </div>
            <div class="kpi-table-footer">
                <div id="grand_total" class="title">Total Deliverables</div>
                <div id="grand_total_count" class="title total-deliverables">{{result.grand_total}}</div>
            </div>
        </div>
        
    </div>
</div>
<app-common-echarts #echart [showLoading]="loading"></app-common-echarts>


