<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm" class="add-dialog" role="dialog" aria-labelledby="titleInitiativeName">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('initiativeName').invalid }">
            <p id="titleInitiativeName">Initiative Name</p>
            <input id="initiativeName" type="text" formControlName="initiativeName"  class="form-control" placeholder="Initiative" required aria-label="Initiative Name" maxlength="128"  (keyup)="changeInitiativeValue(showForm.get('initiativeName').value)"/>
            <span id="initiativeNameLength" class="hint point">{{inputInitiativeName ? inputInitiativeName.length : 0}}/128</span> 
          </div>
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('type').invalid }">
            <p id="titleType">Type</p>
            <mat-select id="drpType" formControlName="type" placeholder="Select" aria-label="Type" disableRipple (selectionChange)="selectedTypeValue($event.value)">
                <mat-option id="type_option{{i}}" *ngFor="let option of data.type;let i=index" [value]="option" >{{option}}</mat-option>
            </mat-select>
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && (showForm.get('Users').invalid || selectedId =='' ) }">
            <p id="titleInitiativeLead">Initiative Lead</p>
            <input type="text" id="users" formControlName="Users" placeholder="Search People" class="form-control" [matAutocomplete]="users" value="{{initiativeLeadName}}" (keyup)="onChangeUsers($event)" aria-label="Initiative Lead" aria-describedby="usersMessage"/>
            <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
            <mat-autocomplete id="users_auto" #users="matAutocomplete" (optionSelected)="selectedValue($event)" [displayWith]="getInitiativeLeadName" role="combobox">
                <mat-option id="users_option{{i}}" *ngFor="let option of options.users;let i=index" [value]="option">{{option.display_name}}</mat-option>
            </mat-autocomplete>
            <span id="usersMessage" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
    </div>
</form>
