/* eslint-disable @typescript-eslint/no-explicit-any */
import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { CanDeactivateGuard, AdminGuard } from '@app/helpers';
import { LoginComponent } from '@app/login/login.component';
import { HomeComponent } from '@app/home/home.component';
import { ErrorComponent } from '@app/error/error.component';
import { DraftDeliverablesComponent } from '@app/deliverables/deliverables-drafts.component';
import { LiveDeliverablesComponent } from '@app/deliverables/deliverables-live.component';
import { DeliverableEditComponent } from '@app/deliverables/deliverable-edit.component';
import { DeliverableViewComponent } from '@app/deliverables/deliverable-view.component';
import { SearchComponent } from './search/search.component';
import { GroupsViewComponent } from './admin-dashboard/components/groups-view/groups-view.component';
import { LocationViewComponent } from './admin-dashboard/components/location-view/location-view.component';
import { WorkstreamViewComponent } from './admin-dashboard/components/workstream-view/workstream-view.component';
import { InitiativesViewComponent } from './admin-dashboard/components/initiatives-view/initiatives-view.component';
import { DeliverableTypeViewComponent } from './admin-dashboard/components/deliverable-type-view/deliverable-type-view.component';
import { StatusViewComponent } from './admin-dashboard/components/status-view/status-view.component';
import { StageViewComponent } from './admin-dashboard/components/stage-view/stage-view.component';
import { IndustryViewComponent } from './admin-dashboard/components/industry-view/industry-view.component';
import { MetricsViewComponent } from './admin-dashboard/components/metrics-view/metrics-view.component';
import { GroupLocationViewComponent } from './admin-dashboard/components/group-location-view/group-location-view.component';
import { ReportingComponent } from './reporting/reporting.component';
import { DraftProjectsComponent } from '@app/projects/projects-drafts.component';
import { LiveProjectsComponent } from '@app/projects/projects-live.component';
import { ProjectEditComponent } from '@app/projects/project-edit.component';
import { ProjectsViewComponent } from '@app/projects/project-view.component';
import { DraftStoriesComponent } from '@app/stories/stories-drafts.component';
import { LiveStoriesComponent } from '@app/stories/stories-live.component';
import { StoryEditComponent } from '@app/stories/story-edit.component';
import { StoryViewComponent } from '@app/stories/story-view.component';
import { ShowMessageComponent } from './dmos/show-message/show-message.component';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from '@environments/environment';
import { PartnerViewComponent } from './admin-dashboard/components/partner-view/partner-view.component';

const appRoutes: Routes = [
  {
    path: 'auth',
    component: MsalRedirectComponent
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    canActivate: [MsalGuard],
    component: SearchComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'deliverables',
    redirectTo: 'deliverables/list/live',
    pathMatch: 'full'
  },
  {
    path: 'demo',
    // redirectTo: 'deliverables/list/live',
    // pathMatch: 'full'
    component: ShowMessageComponent
  },
  {
    path: 'deliverables/list/drafts',
    canActivate: [MsalGuard],
    component: DraftDeliverablesComponent
  },
  {
    path: 'deliverables/list/live',
    canActivate: [MsalGuard],
    component: LiveDeliverablesComponent
  },
  {
    path: 'deliverables/triggerError/:statusCode',
    component: LiveDeliverablesComponent
  },
  {
    path: 'deliverables/:deliverable_id',
    component: DeliverableEditComponent,
    canActivate: [MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'deliverables/view/:deliverable_id',
    canActivate: [MsalGuard],
    component: DeliverableViewComponent
  },
  // {
  //   path: 'search',
  //   canActivate: [MsalGuard],
  //   component: SearchComponent
  // },
  {
    path: 'search/view/:deliverable_id',
    canActivate: [MsalGuard],
    component: DeliverableViewComponent
  },
  {
    path: 'search/view/project/:project_id',
    canActivate: [MsalGuard],
    component: ProjectsViewComponent
  },
  {
    path: 'search/view/story/:story_id',
    canActivate: [MsalGuard],
    component: StoryViewComponent
  },

  {

    path: 'Admin',
    canActivate: [MsalGuard, AdminGuard],
    children: [
      {
        path: 'General/admin-initiatives-view',
        component: InitiativesViewComponent
      },
      {
        path: 'General/admin-metrics-view',
        component: MetricsViewComponent
      },
      {
        path: 'General/admin-status-view',
        component: StatusViewComponent
      },
      {
        path: 'General/admin-industries-view',
        component: IndustryViewComponent
      },
      {
        path: 'General/admin-partner-view',
        component: PartnerViewComponent
      },
      {
        path: 'Labs/admin-groups-view',
        component: GroupsViewComponent,
      },
      {
        path: 'Labs/admin-location-view',
        component: LocationViewComponent
      },
      {
        path: 'Labs/admin-workstream-view',
        component: WorkstreamViewComponent
      },
      {
        path: 'Labs/admin-deliverable-type-view',
        component: DeliverableTypeViewComponent
      },
      {
        path: 'Labs/admin-groupLocation-view',
        component: GroupLocationViewComponent
      },
      {
        path: 'Labs/admin-stage-view',
        component: StageViewComponent
      },
      // Liquid studio
      {
        path: 'LiquidStudio/admin-location-view',
        component: LocationViewComponent
      },
      {
        path: 'LiquidStudio/admin-deliverable-type-view',
        component: DeliverableTypeViewComponent
      },
      // Incubation
      {
        path: 'Incubation/admin-location-view',
        component: LocationViewComponent
      },
      {
        path: 'Incubation/admin-groups-view',
        component: GroupsViewComponent,
      },
      {
        path: 'Incubation/admin-deliverable-type-view',
        component: DeliverableTypeViewComponent
      },

      // Innovation
      {
        path: 'Innovation/admin-location-view',
        component: LocationViewComponent
      },
      {
        path: 'Innovation/admin-groups-view',
        component: GroupsViewComponent,
      },
      {
        path: 'Innovation/admin-deliverable-type-view',
        component: DeliverableTypeViewComponent
      },

      //Metaverse
      {
        path: 'Metaverse/admin-location-view',
        component: LocationViewComponent
      },
      {
        path: 'Metaverse/admin-groups-view',
        component: GroupsViewComponent
      },
      {
        path: 'Metaverse/admin-deliverable-type-view',
        component: DeliverableTypeViewComponent
      }
    ],
  },

  {
    path: 'reporting',
    redirectTo: 'reporting/deliverables',
    pathMatch: 'full'
  },
  {
    path: 'reporting/deliverables',
    canActivate: [MsalGuard],
    component: ReportingComponent
  },
  {
    path: 'reporting/view/:deliverable_id',
    canActivate: [MsalGuard],
    component: DeliverableViewComponent
  },
  {
    path: 'reporting/story/:story_id',
    canActivate: [MsalGuard],
    component: StoryViewComponent
  },
  {
    path: 'reporting/kpi',
    canActivate: [MsalGuard],
    component: ReportingComponent
  },
  {
    path: 'reporting/success-stories',
    canActivate: [MsalGuard],
    component: ReportingComponent
  },
  {
    path: 'projects',
    redirectTo: 'projects/list/live',
    pathMatch: 'full'
  },
  {
    path: 'projects/list/drafts',
    canActivate: [MsalGuard],
    component: DraftProjectsComponent
  },
  {
    path: 'projects/list/live',
    canActivate: [MsalGuard],
    component: LiveProjectsComponent
  },
  {
    path: 'projects/:project_id',
    canActivate: [MsalGuard],
    component: ProjectEditComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'projects/view/:project_id',
    canActivate: [MsalGuard],
    component: ProjectsViewComponent
  },
  {
    path: 'stories',
    redirectTo: environment.STORY_LIVE_LINK,
    pathMatch: 'full'
  },
  {
    path: environment.DRAFT_STORY_LINK,
    canActivate: [MsalGuard],
    component: DraftStoriesComponent
  },
  {
    path: environment.STORY_LIVE_LINK,
    canActivate: [MsalGuard],
    component: LiveStoriesComponent
  },
  {
    path: environment.STORY_ID_LINK,
    canActivate: [MsalGuard],
    component: StoryEditComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: environment.STORY_VIEW_LINK,
    canActivate: [MsalGuard],
    component: StoryViewComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: '**',
    component: ErrorComponent
  }
];

export const AppRoutingProviders: any[] = [];
export const Routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes, { useHash: false });
