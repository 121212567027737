<div fxLayout="column" class="list-item-card" tabindex="0" (keydown.enter)="onStoryClick()">
    <div fxLayout="row" fxLayoutGap="20px" class="name-row">
        <a fxFlex fxLayout="row" fxLayoutGap="20px" (click)="onStoryClick()">
            <img [src]="imgURL" alt="" class="list-item-image">
            <div fxFlex fxLayout="column">
                <h2 name="storyTitle" class="white-space-pre">{{story?.title}}</h2>
                <p name="storySubTitle">{{story?.subtitle}}</p>
            </div>
        </a>
        <div fxLayout="row" class="action-row" *ngIf="isFromSearch">
            <div fxLayout="row" fxLayoutGap="20px" class="action-row-inner">
                <div name="searchWorkflowStatus" class="tag">{{story.workflow_status}}</div>
            </div>
        </div>
        <div fxLayout="row" class="action-row" *ngIf="action">
            <div fxLayout="row" fxLayoutGap="20px" class="action-row-inner">
                <div name="searchWorkflowStatus" class="tag">{{story.workflow_status}}</div>
            </div>
        </div>
    </div>
    <mat-divider></mat-divider>
    <a fxLayout="row" fxLayoutGap="10px" class="data-row" (click)="onStoryClick()">
        <div fxLayout="column" fxFlex="15" class="data-group">
            <label name="lblOwner">Owner</label>
            <span name="owner_value">{{story?.owner?story.owner.display_name:'n/a'}}</span>
        </div>
        <div fxLayout="column" fxFlex="15" class="data-group">
            <label name="lblOrganization">organization</label>
            <span name="organization_value">{{story?.organization_type?story.organization_type.name:'n/a'}}</span>
        </div>
        <div fxLayout="column" fxFlex="20" class="data-group" *ngIf="!isLiquidStudio">
            <label name="lblPrimaryGroup">Primary Group</label>
            <span name="primaryGroup_value">{{story?.primary_group?story.primary_group.name:'n/a'}}</span>
        </div>
        <div fxLayout="column" fxFlex="20" class="data-group" *ngIf="isLabs">
            <label name="lblPrimaryGroup">Primary Workstream</label>
            <span name="primaryGroup_value">{{story?.primary_workstream?story.primary_workstream.name:'n/a'}}</span>
        </div>
        <div fxLayout="column" fxFlex="20" class="data-group">
            <label name="lblPrimaryLocation">Primary Location</label>
            <span name="primaryLocation_value">{{story?.primary_location?story.primary_location.name:'n/a'}}</span>
        </div>
        <div fxLayout="column" fxFlex="8" class="data-group">
            <label name="lblLastEdited">Last Edited</label>
            <span name="lastEdited_hasValue" *ngIf="story?.last_updated_date">{{story?.last_updated_date | date
                }}</span>
            <span name="lastEdited_nullvalue" *ngIf="!story?.last_updated_date">n/a</span>
        </div>
    </a>
</div>