<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm"  class="add-dialog" role="dialog" aria-labelledby="titleGroupName">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('groupName').invalid }">
            <p id="titleGroupName">Group Name</p>
            <input type="text" id="url_text" formControlName="groupName" class="form-control" placeholder="Group" aria-label="group name" maxlength="128"  (keyup)="changeGroupValue(showForm.get('groupName').value)"/>
            <span id="groupNameLength" class="hint point">{{inputGroupName ? inputGroupName.length : 0}}/128</span> 
            <span id="url_text_hint" class="hint error" [matTooltip]="Messages.GROUP_ERROR">{{Messages.GROUP_ERROR}}</span>
          </div>
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('groupLocation').invalid }">
            <p id="titleLocation">Location(s)</p>
            <mat-select id="drpLocation" formControlName="groupLocation" class="form-control" panelClass="show-pseudo-checkbox" placeholder="Select" multiple [compareWith]="onCompareObjects" (selectionChange)="selectedLocationsValue($event.value)" aria-label="Locations">
                <mat-option id="location_option{{i}}" *ngFor="let option of options.locations;let i=index" [value]="option" >{{option.name}}</mat-option>
            </mat-select>
        </div>
        <div class="form-group" [ngClass]="{'error': submitted && (showForm.get('groupUsers').invalid || selectedId == '') }">
            <p id="titleGroupLead">Group Lead</p>
            <input type="text" id="users" formControlName="groupUsers" placeholder="Search People" class="form-control" [matAutocomplete]="users" value="{{groupLeadName}}" (keyup)="onChangeUsers($event)" aria-label="Group Lead" aria-describedby="usersMessage"/>
            <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
            <mat-autocomplete id="users_auto" #users="matAutocomplete" (optionSelected)="selectedValue($event)" [displayWith]="getGroupLeadName" role="combobox">
                <mat-option id="users_option{{i}}" *ngFor="let option of options.users;let i=index" [value]="option">{{option.display_name}}</mat-option>
            </mat-autocomplete>
            <span id="usersMessage" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
    </div>
</form>
