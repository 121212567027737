/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { WorkstreamService } from '../workstream.service';
import { map, catchError, delay, retry } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class WorkstreamApiService implements WorkstreamService{
  constructor(private http: HttpClient, private commonService: CommonService) {
  }
  getWorkStreams(params): Observable<any> {
    return this.http.get(`${environment.API_URL}/workstreams`, this.commonService.httpOptions(params));
  }
  addWorkstream(reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/workstreams`, reqbody, this.commonService.httpOptions());
  }

  editWorkstream(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/workstreams/update/${params}`, reqBody, this.commonService.httpOptions())
    .pipe(
      map(this.commonService.extractData),
      map((res: any) => {
        return res;
      }),
      retry(environment.MAX_RETRY_COUNT),
      delay(environment.RETRY_INTERVAL),
      catchError((error: any) => {
        return this.commonService.handleError(error);
      })
    );
  }
  changeWorkstreamStatus(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/workstreams/archiveind`, reqBody, this.commonService.httpOptions(params));
  }
}