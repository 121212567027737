<div fxLayout="column" class="confirm-dialog" role="dialog" aria-labelledby="h_errorTitle" aria-describedby="errorText">
    <div fxLayout="column">
        <h2 id="h_errorTitle" class="dialog-title">{{data.title}}</h2>
        <div fxLayout="column" class="dialog-content">
            <p id="errorText" *ngIf="data.message" [innerHTML]="data.message"></p>
            <ul *ngIf="data.errorList">
                <li id="error{{i}}" *ngFor="let error of data.errorList;let i=index">- {{error}}</li>
            </ul>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button id="btnYes" mat-button type="button" color="primary" (click)="onYes()">{{data.buttonYesCaption}}</button>
            <button id="btnNo" mat-flat-button type="button" *ngIf="data.toShowCancel" color="primary" (click)="onCancel()">{{data.buttonNoCaption}}</button>
        </div>
    </div>
</div>