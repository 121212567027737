/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-empty */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Services } from '@app/services/services';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-selected-deliverables-popup',
  templateUrl: './selected-deliverables-popup.component.html',
  styleUrls: ['./selected-deliverables-popup.component.scss']
})
export class SelectedDeliverablesPopupComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  public deliverablesList;
  public columns: string[] = ['checked', 'asset'];
  selectedDeliverables: Array<any> = [];
  searchText;
  review: any;
  results: Array<any> = [];
  loading: any = 0;
  optionListAll: Array<any> = [];
  optionScrollPage: any = 1;
  optionScrollSize: any = 5;
  pagination: any = {
    page_number: 0,
    page_size: 10000,
    sort: '',
    search: null,
    sort_dir: ''
  };
  filter;
  loadTypeAhead: any = 0;
  scrollToLocation: any = 500;
  constructor(
    public el: ElementRef,
    public dialogRef: MatDialogRef<SelectedDeliverablesPopupComponent>,
    private cd: ChangeDetectorRef,
    public services: Services
  ) { }
  ngOnInit(): void {
    this.selectedDeliverables = this.services.commonService.getSessionData('GET_SELECTED_DELIVERABLES');
  }
  scrollToBottom(event): void {
    try {
      if (this.el.nativeElement.querySelector('#deliverablesTableId').scrollTop + this.scrollToLocation > this.el.nativeElement.querySelector('#deliverablesTableId').scrollHeight) {
        this.optionScrollPage++;
        this.deliverablesList = this.optionListAll.slice(0, this.optionScrollPage * this.optionScrollSize);
        this.deliverablesList = new MatTableDataSource(this.deliverablesList);
      }
    } catch (err) { }
  }
  /**
   * suggestion
   * @param event
   */
  getTypeAhead(event) {
    const typeAheadValue = event.target.value;
    this.searchText = typeAheadValue;
    if (typeAheadValue.length >= 2) {
      const reqArguments = {
        searchText: typeAheadValue
      };
      setTimeout(() => {
        this.loadTypeAhead++;
        this.cd.detectChanges();
      });
      this.services.deliverableService.getTypeAheadDeliverableData(reqArguments).subscribe((typedata) => {
        this.review = typedata.suggestions;
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      });
    }
    else {
      this.review = null;
    }
    if (typeAheadValue.length == 0) {
      this.ngSelectComponent.handleClearClick();
    }
  }
  /**
   * search field
   * @param $event
   */
  onTypeAheadSearchSubmit($event = null) {
    if ($event != undefined) {
      this.searchText = $event.target.value;
    }
    this.pagination.search = this.searchText;
    this.pagination.page_number = 0;
    this._getSearchResult();
  }
  _getSearchResult(): void {
    setTimeout(() => {
      this.loadTypeAhead++;
      this.cd.detectChanges();
    });
    this.deliverablesList = [];
    this.optionListAll = [];
    this.loading++;
    const filter = this.services.deliverableCommonService.getFilterData(this.filter);
    this.services.reportingService.getDeliverables(this.pagination, filter)
      .subscribe((response) => {
        this.loading--;
        if (response && response.deliverables) {
          this.results = response.deliverables;
          this.results.forEach((element) => {
            const data = {};
            if (this.selectedDeliverables?.length > 0) {
              this.selectedDeliverables.forEach((o) => {
                if (element._id === o._id) {
                  data['checked'] = true;
                }
              });
            } else {
              data['checked'] = false;
            }
            data['asset'] = element;
            this.optionListAll.push(data);
          });
          this.optionScrollPage = 1;
          this.deliverablesList = this.optionListAll.slice(0, this.optionScrollPage * this.optionScrollSize);
          this.deliverablesList = new MatTableDataSource(this.deliverablesList);
        }
        setTimeout(() => {
          this.loadTypeAhead--;
          this.cd.detectChanges();
        });
      }, (error1) => {
        this.loading--;
        this.services.commonService.handleError(error1);
      });
  }
  onCancel(): void {
    this.dialogRef.close();
  }
  onFilter($event) {
    this.filter = $event.data;
    if ($event.type != 'init') {
      this.pagination.page_number = 0;
    }
    this._getSearchResult();
  }
  onSave(): void {
    this.services.commonService.setSessionData('GET_SELECTED_DELIVERABLES', this.selectedDeliverables);
    this.dialogRef.close(true);
    this.services.storyCommonService.isNeedToRefresh.next(true);
  }
  getSelectedOption(selected): void {
    if (this.selectedDeliverables && this.selectedDeliverables.length) {
      this.selectedDeliverables.forEach((item) => {
        if (item._id === selected.asset._id && selected.checked === false) {
          const index = this.services.commonService.objectIndexOf(this.selectedDeliverables, selected.asset);
          if (index >= 0) {
            this.selectedDeliverables.splice(index, 1);
          }
        }
      });
      if (selected.checked === true) {
        this.selectedDeliverables.push(selected.asset);
      }
    } else {
      this.selectedDeliverables = [];
      this.deliverablesList.filteredData.forEach((element) => {
        if (element.checked === true) {
          this.selectedDeliverables.push(element.asset);
        }
      });
    }
  }
}
