/* eslint-disable no-self-assign */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { AddStageComponent } from '@app/dialog/add-stage/add-stage.component';
import { Messages } from '@app/models';
import { CommonService } from '@app/services';
import { StageService } from '@app/services/stage.service';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-stage-view',
  templateUrl: './stage-view.component.html',
  // styleUrls: ['./stage-view.component.scss']
})
export class StageViewComponent implements OnInit {
  public stagesList;
  public columns: string[] = ['stage', 'name', 'rule'];
  public headersData = ['Stage', 'Stage Name', 'Deliverables Req\'d.'];


  public strPublish='Publish';
  public loadVal =0;
  public delTypeList=[];
  public totalStatus = [];
  public ruleList=[];
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public orgTypeName = 'General';
  @ViewChild('table') table: MatTable<string[]>;
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private stageService:StageService,
  ) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.getStages();
    this.getBydeliverableType();
  }

  arraySort(a, b) {
    return b.updated_date < a.updated_date ? 1 : -1;
  }

  getStages(): void {
    this.loadVal++;
    const  params = {orgTypeName:  this.orgTypeName};
    this.stageService.getStages(params)
      .subscribe((response) => {
        if (response && response.result) {
          this.stagesList = response.result;
          this.stagesList.forEach((element) => {
            this.ruleList=[];
            element['stage'] = element.stage;
            element['name'] = element.name;
            let rule='';
            let i=0;
            element.projectStage.sort(this.arraySort);
            element.projectStage.forEach((item) => {
              i++;
              const deliverableStatuRule = item.deliverableStatuRule != (null || undefined) && item.deliverableStatuRule.trim() != '';
              const workStatuRule = item.workStatuRule != (null || undefined) && item.workStatuRule.trim() != '';
              if (deliverableStatuRule && workStatuRule) {
                let workflowstatue = item.workStatuRule;
                if(item.workStatuRule == this.strPublish)
                {
                  workflowstatue = 'Published';
                }
                rule = rule.concat(item.typeRule, '(', item.deliverableStatuRule + ',' + workflowstatue, ')');
              }
              else if (deliverableStatuRule) {
                rule = rule.concat(item.typeRule, '(', item.deliverableStatuRule, ')');
              }
              else if (workStatuRule) {
                let workflowstatue = item.workStatuRule;
                if(item.workStatuRule == this.strPublish)
                {
                  workflowstatue = 'Published';
                }
                rule = rule.concat(item.typeRule, '(', workflowstatue, ')');
              }
              if (!deliverableStatuRule && !workStatuRule) {
                rule = rule.concat(item.typeRule);
              }
              if(element.projectStage.length != 1 && i < element.projectStage.length)
              {
                rule=rule.concat(', ');
              }
              element['rule'] = rule;
            });
          });
          Object.assign(this.totalStatus, this.stagesList);
          this.loadVal--;
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  clickElement(element): void {
    const editData = {'value': element, 'deliverbletype': this.delTypeList };
    const dialogRef = this.dialog.open(AddStageComponent, {
      width: '800px',
      maxHeight: '95vh',
      data: editData
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.commonService.openSnackBar('success', Messages.STAGE_EDITED_SUCCESS);
        this.getStages();
      }
    });
  }
  getBydeliverableType():void{
    this.stageService.getBydeliverableType({'orgTypeName': this.orgTypeName}).subscribe((response) => {
      if (response && response.result) {
        this.delTypeList=response.result.map((item) => item.name);
      }
    });
  }
}


