/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService, CommonService } from '@app/services';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import {InitiativesService} from '@app/services/initiatives.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Messages } from '@app/models';
@Component({
  selector: 'app-add-new-initiative',
  templateUrl: './add-new-initiative.component.html',
  // styleUrls: ['./add-new-initiative.component.scss']
})
export class AddNewInitiativeComponent implements OnInit {
  loading= 0;
  showForm: UntypedFormGroup;
  submitted: any = false;
  selectedType: '';
  loadTeam = 0;
  initiativeName='';
  initiativeLeadName: string;
  selectedId: string;
  teamMembersChanged: Subject<string> = new Subject<string>();
  options: any = {
    users: []
  }
  public loadVal =0;
  public inputInitiativeName: any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AddNewInitiativeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private initiativeService: InitiativesService,
    private userService: UserService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    if(this.data.value){
      this.initiativeName = this.data.value.name;
      this.selectedType = this.data.value.initiative_type;
      this.inputInitiativeName = this.initiativeName;
      if (this.data.value.initiative_lead){
        this.selectedId = this.data.value.initiative_lead.eso_id;
        this.initiativeLeadName = this.data.value.initiative_lead.display_name;
      }
    }
    this.showForm = this.formBuilder.group({
      initiativeName: [{ value: this.initiativeName, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
      type: [{ value: this.selectedType, disabled: false}, Validators.required],
      Users: [{ value: this.initiativeLeadName, disabled: false}, Validators.required]
    });
    this.teamMembersChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['users'] = [];
          this._getTeamMembers(filterValue);
        }
      });
  }
  _getTeamMembers(searchText: any): void {
    this.loadTeam++;
    this.userService.getUsers(searchText)
      .subscribe((response) => {
        this.loadTeam--;
        if (response && response.result) {
          this.options.users = response.result;
        }
      });
  }
  onChangeUsers(event): void {
    this.selectedId = '';
    this.initiativeLeadName = '';
    this.options['users'] = [];
    this.teamMembersChanged.next(event.target.value);
  }
  selectedValue(selected): void {
    this.selectedId = selected.option.value.eso_id;
    this.initiativeLeadName = selected.option.value.display_name;
  }
  getInitiativeLeadName(option) {
    const display_name = option.display_name? option.display_name : option
    return display_name;
  }
  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    if (this._isValid()){
      this.loadVal++;
      const orgTypeName= this.data.orgTypeName;
      if(this.data.value){
        const params = this.data.value._id;
        const body = { 'name': this.showForm.get('initiativeName').value.trim(), 'initiative_type': this.selectedType, 'initiative_lead': this.selectedId,'orgTypeName':orgTypeName};
        this.initiativeService.editInitiative(params, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{
       
        const body = {'name': this.showForm.get('initiativeName').value.trim(), 'initiative_type': this.selectedType, 'initiative_lead': this.selectedId,'orgTypeName':orgTypeName};
        this.initiativeService.addInitiative(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }
  selectedTypeValue(value): void {
    this.selectedType = value;
  }

  _isValid(): boolean {
    if (this.showForm.valid && this.selectedId !== '') {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  changeInitiativeValue(value){
    this.inputInitiativeName = value;
  }
  get Messages(): any { return Messages; }
}

