
<form #validForm fxLayout="column" [formGroup]="showForm" class="add-dialog">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" >
            <p id="titleStage">{{stage}}</p>
        </div>
        <div class="form-group" [ngClass]="{'error':showForm.get('stageName').invalid }">
            <p id="titleStatus">Stage Name</p>
            <input id="stage" type="text" formControlName="stageName" class="form-control" placeholder="Deliverable Status" aria-label="Status"  maxlength="50" required (keyup)="changeStatusValue(showForm.get('stageName').value)"/>
            <span id="stageLength" class="hint point">{{inputStageName ? inputStageName.length : 0}}/50</span>  
        </div>
   <div id ="scrolldIV" style="overflow-y:auto;max-height:255px" >
        <div fxLayout="column" class="dialog-form-stagecontent" *ngFor = "let item of projectStageListShow;let i=index">
            <div class="form-group" style="width: 230px;" [ngClass]="{'error':  item.typeRule === ''}">
                <p id="titleDelType{{i}}">Deliverable Type Required</p>
                <mat-select id="drpDelType{{i}}"   class="form-control" placeholder="{{item.typeRule?item.typeRule:'Select Deliverable Type'}}" (selectionChange)="selectedLocationsValue($event,'typeRule',item)">
                    <mat-option id="DelType_option{{i}}" *ngFor="let option of DelType;let i=index" [value]="option" >{{option}}</mat-option>
                </mat-select>
            </div>
            <div class="form-group" style="padding-left: 20px;width: 230px;">
                <p id="titleDelStatus{{i}}" class="padding:10px">Required Deliverable Status</p>
                <mat-select id="drpDelStatus{{i}}" class="form-control"  placeholder="{{item.deliverableStatuRule?item.deliverableStatuRule:'Select Deliverable Status'}}" (selectionChange)="selectedLocationsValue($event,'deliverableStatuRule',item)">
                    <mat-option id="DelStatus_option{{i}}" *ngFor="let option of DelStatus;let i=index" [value]="option">{{option}}</mat-option>
                </mat-select>
            </div>
            <div class="form-group"  style="padding-left: 20px;width: 230px;">
                <p id="titleWorkFStatus{{i}}">Required Workflow Status</p>
                <mat-select id="drpWorkFStatus{{i}}" class="form-control" placeholder="{{item.workStatuRule?item.workStatuRule:'Select Workflow Status'}}"  (selectionChange)="selectedLocationsValue($event,'workStatuRule',item)">
                    <mat-option id="WorkFStatus_option{{i}}" *ngFor="let option of WorkFStatus;let i=index" [value]="option" >{{option}}</mat-option>
                </mat-select>
            </div>
            <div > <a style=" margin-left: 10px; line-height: 88px;" (click)="deleteStage(item)"><i class="fa fa-trash" aria-hidden="true" id='delbtn{{i}}'></i></a></div>
        </div>
    </div>

    <div fxFlex="17" class="button-display">
        <button id="btnAddNew" *ngIf="stage!=='Stage5'" mat-stroked-button color="accent" (click)="addNewData()"><i class="fa fa-plus-circle add-icon"></i>Add Required Deliverable</button>
     
    </div>
    </div>
    
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="save()" cdkFocusInitial>Save</button>
    </div>
</form>