import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/services';
import { Role } from '@app/models';

@Injectable()
export class AdminGuard  {
public roles: string;
constructor(
        private router: Router,
        private commonService: CommonService
) { }

canActivate(): boolean {
  if (this.commonService.hasAnyRole([Role.CONFIGURATION_ADMINISTRATOR]) || this.commonService.hasAnyRole([Role.MANAGER_ADMINISTRATOR])) {
    return true;
  } else {
    this.router.navigate(['/error']);
    return false;
  }
}
}
