/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable id-blacklist */
/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from '@angular/common';
import {
  AfterContentChecked,
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { richTextConfig } from '@app/config/richTextConfig';
import { Messages, OrgType, User, WorkflowStatus } from '@app/models';
import { SelectedDeliverablesPopupComponent } from '@app/related-deliverables-popup/selected-deliverables-popup.component';
import { Services } from '@app/services/services';
import { StoryIconDialogComponent } from '@app/stories/story-icon-dialog.component';
import { StoryImageDialogComponent } from '@app/stories/story-image-dialog.component';
import { Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-story-edit',
  templateUrl: './story-edit.component.html',
  styleUrls: ['./story-edit.component.scss']
})
export class StoryEditComponent
  implements OnInit, AfterContentChecked, AfterViewChecked
{
  dataImages: any = {};
  storyForm: UntypedFormGroup;
  @ViewChild('validForm') validForm: ElementRef;
  loadKeywords: any = 0;
  storyId: any = null;
  story: any = null;
  isNew: any = true;
  isSaved: any = false;
  storyImage: any = {};
  submitted: any = false;
  isHashTagValidated: any = true;
  draftSubmitted: any = false;
  routerWatcher: any;
  file: any = null;
  fileList: any = [];
  verifyIconLength: any = [];
  imageObj: any = {};
  loading: any = 0;
  loadTeam: any = 0;
  loadContact: any = 0;
  loginUser: User = null;
  storyTitle: any = '';
  storySubTitle: any = '';
  storyHashtag: any = '';
  max_length: any = 0;
  storyContext: any = '';
  storySolution: any = '';
  storyImpact: any = '';
  image_url: any = null;
  isExceedFileSize: any = false;
  maxFileSize: any = false;
  loadOwner: any = 0;
  loadGroup: any = 0;
  loadLocation: any = 0;
  locationList: any;
  richTextStyle: any = richTextConfig;
  loadPartner: any = 0;
  isExceedsSolutionText = false;
  isExceedsContextText = false;
  isExceedsImpactText = false;
  otherLocationsOptions: any = [];
  ownerChanged: Subject<string> = new Subject<string>();
  keywordChanged: Subject<string> = new Subject<string>();
  teamMembersChanged: Subject<string> = new Subject<string>();
  contactsChanged: Subject<string> = new Subject<string>();
  orgTypeName: any = '';
  incomplete: any = false;
  orgType: any = {};
  currentUrl = '';
  imgURL: any = {};
  imagelist: any = [];
  groupOrder: any = null;
  partnerOrder: any = null;
  createStoryTitle: any = '';
  editStoryTitle: any = '';
  solutionText: any = '';
  contextText: any = '';
  impactText: any = '';
  deleteStoryList: any = [];
  imageDescription: any = '';
  isShowIconMesage = false;
  isShowPicturesMesage = false;
  showImageSave = true;
  isDescription: any = '';
  isImageSubmistion: any = false;
  hidePartners = false;
  hideForceNestedMarket = false;
  marketsList = [];
  loadMarkets = 0;
  options: any = {
    owner: [],
    groups: [],
    eac_qrtr: [],
    team_members: [],
    contacts: [],
    related_deliverables: [],
    pictures: [],
    icons: [],
    keywords: [],
    partners: [],
    markets: [],
    forces: [],
    nested_themes: []
  };
  displayConfidentialityLevel: any = [
    {
      _id: 1,
      name: 'NOT for use in External Annual Report'
    },
    {
      _id: 2,
      name: 'COULD be used in External Annual Report with Client NAMED'
    },
    {
      _id: 3,
      name: 'COULD be used in External Annual Report with Client UN-NAMED'
    }
  ];
  confidentiality_level: any = [];
  imageStory: any = {};
  imageStoryFiles: any = {};
  dataProcessing: any = false;
  relatedDeliverableChange: any = false;
  iconChange: any = false;
  imageChange: any = false;
  isForList = true;
  relatedDelThumbnailSize: any = {
    width: 80,
    height: 64
  };
  constructor(
    public router: Router,
    public location: Location,
    private route: ActivatedRoute,
    public services: Services,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog,
    public changeDetector: ChangeDetectorRef
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
  ngOnInit(): void {
    this.services.commonService.removeSessionData('STORY_LIVE_PAGINATION');
    this.services.commonService.removeSessionData('STORY_DRAFT_PAGINATION');
    this.services.commonService.removeSessionData('GET_SELECTED_DELIVERABLES');
    this.services.commonService.removeSessionData('GET_SELECTED_STORY_IMAGE');
    this._getMarketsInfo();
    this.services.commonService.parnterNeedToRefresh.subscribe(
      (isNeedToRefresh) => {
        if (isNeedToRefresh) {
          this.hidePartners =
            this.services.commonService.globalFlags['hidePartners'];
        }
      }
    );
    this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
    this.services.commonService.forceRelatedNeedToRefresh.subscribe(
      (isNeedToRefresh) => {
        if (isNeedToRefresh) {
          this.hideForceNestedMarket =
            this.services.commonService.globalFlags['hideForceNestedMarket'];
        }
      }
    );
    this.hideForceNestedMarket =
      this.services.commonService.globalFlags['hideForceNestedMarket'];
    this.services.storyCommonService.isImageNeedToRefresh.subscribe(
      (isImageNeedToRefresh) => {
        if (isImageNeedToRefresh) {
          // const image = this.services.commonService.getSessionData('GET_SELECTED_STORY_IMAGE');
          // this.imagelist.push(image);
          // this.options.pictures = this.imagelist;
          // this.getImage(this.options.pictures);
        }
      }
    );
    this.services.storyCommonService.isNeedToRefresh.subscribe(
      (isNeedToRefresh) => {
        if (isNeedToRefresh) {
          this.options.related_deliverables =
            this.services.commonService.getSessionData(
              'GET_SELECTED_DELIVERABLES'
            );
        }
      }
    );
    this.services.commonService.showHeader.emit(true);
    this.routerWatcher = this.route.params.subscribe((params) => {
      this.storyImage = '';
      this.storyContext = '';
      this.storySolution = '';
      this.isNew = true;
      this.storyId = null;
      this.submitted = false;
      this.isHashTagValidated = true;
      this.story = null;
      this.storyForm = null;
      this.isSaved = false;
      this.file = null;
      this.draftSubmitted = false;
      this.isImageSubmistion = false;
      this.loading++;
      this.services.masterService.getQuarters().subscribe((response) => {
        this.loading--;
        if (response) {
          this.options.eac_qrtr = response.end_quarter;
          this.options.fiscal_year_default_value =
            response.fiscal_year_default_value;
          if (params.story_id !== 'new') {
            this.isNew = false;
            this.storyId = params.story_id;
            this.loading++;
            this.services.storiesService
              .getStory(this.storyId)
              .subscribe((response) => {
                this.loading--;
                if (response && response.story) {
                  this.story = response.story;
                  this.story = { ...response.story, ...response.metadata };
                  this.storyTitle = this.story.title;
                  this.storySubTitle = this.story.subtitle;
                  this.storyHashtag = this.story.hashtag;
                  this.imageDescription = this.story.image_description;
                  // storySolution: get plain text of solution, and calculate text length of solution
                  this.storySolution = this.story.solution;
                  this.confidentiality_level = this.story.confidentiality_level;
                  if (this.storySolution) {
                    this.storySolution = this.getPlainText(this.storySolution);
                    this.isExceedsSolutionText =
                      this.storySolution.length > 750 ? true : false;
                  }
                  this.storyContext = this.story.context;
                  if (this.storyContext) {
                    this.storyContext = this.getPlainText(this.storyContext);
                    this.isExceedsContextText =
                      this.storyContext.length > 750 ? true : false;
                  }
                  this.storyImpact = this.story.impact;
                  if (this.storyImpact) {
                    this.storyImpact = this.getPlainText(this.storyImpact);
                    this.isExceedsImpactText =
                      this.storyImpact.length > 750 ? true : false;
                  }
                  this.orgTypeName = this.story.organization_type?.name;
                  this.options.related_deliverables =
                    this.story.related_deliverables;
                  this.adjustPicturesOrder();
                  this.services.commonService.setSessionData(
                    'GET_SELECTED_DELIVERABLES',
                    this.options.related_deliverables
                  );
                  this.editStoryTitle =
                    'Edit ' + this.orgTypeName + ' SUCCESS STORY';
                  this.getRelatedOrgData();
                  this.services.storyEditCommonService.getForceList(this);
                  this.services.storyEditCommonService.getNestedThemesList(
                    this
                  );
                  this.services.storyEditCommonService.getPartners(this);
                  if (!this.story.canEdit) {
                    this.location.back();
                    this.services.commonService.openSnackBar(
                      'warning',
                      Messages.EDIT_SUCCESS_STORY_ERROR_MESSAGE,
                      5000
                    );
                    return;
                  }
                  if (this.story.workflow_status != WorkflowStatus.DRAFT) {
                    this.isSaved = true;
                  }
                  if (this.orgTypeName == 'Labs') {
                    this.services.storyEditCommonService.createForm(
                      this,
                      this.story
                    );
                  } else if (this.orgTypeName == 'Liquid Studio') {
                    this.services.storyEditCommonService.createLiquidForm(
                      this,
                      this.story
                    );
                  } else if (
                    this.orgTypeName == OrgType.INCUBATION ||
                    this.orgTypeName == OrgType.OPENINNOVATION  || this.orgTypeName == OrgType.METAVERSE
                  ) {
                    this.services.storyEditCommonService.createIncubationForm(
                      this,
                      this.story
                    );
                  } 
                }
              });
          } else {
            this.orgType =
              this.services.commonService.getSessionData('GET_STORY_ORG');
            if (this.orgType) {
              this.orgTypeName = this.orgType.name;
              this.createStoryTitle =
                'NEW ' + this.orgTypeName + ' SUCCESS STORY';
            }
            this.getRelatedOrgData();
            this.services.storyEditCommonService.getForceList(this);
            this.services.storyEditCommonService.getNestedThemesList(this);
            this.services.storyEditCommonService.getPartners(this);
            const unsavedStory =
              this.services.commonService.getSessionData('UNSAVED_STORY');
            if (unsavedStory) {
              setTimeout(() => {
                const dialogRef = this.services.commonService.unSavedDialog(
                  Messages.UNSAVED_STORY_DRAFT_MESSAGE
                );
                dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                    if (this.orgTypeName == 'Labs') {
                      this.services.storyEditCommonService.createForm(
                        this,
                        unsavedStory
                      );
                    } else if (this.orgTypeName == 'Liquid Studio') {
                      this.services.storyEditCommonService.createLiquidForm(
                        this,
                        unsavedStory
                      );
                    } else if (
                      this.orgTypeName == OrgType.INCUBATION ||
                      this.orgTypeName == OrgType.OPENINNOVATION  || this.orgTypeName == OrgType.METAVERSE
                    ) {
                      this.services.storyEditCommonService.createIncubationForm(
                        this,
                        unsavedStory
                      );
                    }
                    if (this.storyForm) {
                      this.storyForm.markAsDirty();
                    }
                  } else {
                    if (this.orgTypeName == 'Labs') {
                      this.services.storyEditCommonService.createForm(this);
                    } else if (this.orgTypeName == 'Liquid Studio') {
                      this.services.storyEditCommonService.createLiquidForm(
                        this
                      );
                    } else if (
                      this.orgTypeName == OrgType.INCUBATION ||
                      this.orgTypeName == OrgType.OPENINNOVATION  || this.orgTypeName == OrgType.METAVERSE
                    ) {
                      this.services.storyEditCommonService.createIncubationForm(
                        this
                      );
                    }
                    this.services.commonService.removeSessionData(
                      'UNSAVED_STORY'
                    );
                  }
                });
              }, 3000);
            } else {
              // eslint-disable-next-line no-lonely-if
              if (this.orgTypeName == 'Labs') {
                this.services.storyEditCommonService.createForm(this);
              } else if (this.orgTypeName == 'Liquid Studio') {
                this.services.storyEditCommonService.createLiquidForm(this);
              } else if (
                this.orgTypeName == OrgType.INCUBATION ||
                this.orgTypeName == OrgType.OPENINNOVATION  || this.orgTypeName == OrgType.METAVERSE
              ) {
                this.services.storyEditCommonService.createIncubationForm(this);
              }
            }
          }
        }
      });
    });

    this.ownerChanged.pipe(debounceTime(500)).subscribe((filterValue) => {
      this.services.storyEditCommonService.ownerChanged(this, filterValue);
    });
    this.teamMembersChanged.pipe(debounceTime(500)).subscribe((filterValue) => {
      if (filterValue.trim().length >= 3) {
        this.options['team_members'] = [];
        this.services.storyEditCommonService.getTeamMembers(this, filterValue);
      }
    });
    this.contactsChanged.pipe(debounceTime(500)).subscribe((filterValue) => {
      if (filterValue.trim().length >= 3) {
        this.options['contacts'] = [];
        this.services.storyEditCommonService.getContacts(this, filterValue);
      }
    });
    this.keywordChanged.pipe(debounceTime(500)).subscribe((filterValue) => {
      if (filterValue.trim().length >= 3) {
        this.options['keywords'] = [];
        this.services.storyEditCommonService.getKeywords(this, filterValue);
      }
    });
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  adjustPicturesOrder() {
    if (this.story.pictures?.length > 0 && this.story.pictures[0].order) {
      this.options.pictures = this.story.pictures.sort(this.compare('order'));
    } else {
      this.options.pictures = this.story.pictures;
    }
    if (this.story.icons?.length > 0 && this.story.icons[0].order) {
      this.options.icons = this.story.icons.sort(this.compare('order'));
    } else {
      this.options.icons = this.story.icons;
    }
  }

  compare(property) {
    return function (a, b) {
      const value1 = a[property];
      const value2 = b[property];
      return value1 - value2;
    };
  }

  getRelatedOrgData() {
    this.services.storyEditCommonService.getGroups(this);
    this.services.storyEditCommonService.getLocations(this);
  }
  getPlainText(text) {
    text = text.replace(/<[^<>]+>/g, '');
    text = text.replace(/&nbsp;/gi, ' ');
    // text = text.replace(/&ensp;/ig, ' ');
    // text = text.replace(/&emsp;/ig, ' ');
    // text = text.replace(/&thinsp;/ig, ' ');
    text = text.replace(/&zwnj;/gi, '');
    // text = text.replace(/&zwj;/ig, ' ');
    return text;
  }
  getOtherLocations() {
    const input = document.getElementById('other_locations');
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    const primaryLocation = this.storyForm.get('primary_location');
    if (primaryLocation.value) {
      this.otherLocationsOptions = this.locationList.filter((o) => {
        return o._id != primaryLocation.value._id;
      });
    }
    if (filterValue) {
      return this.otherLocationsOptions.filter((option) => {
        return (
          option['name'] &&
          (option['name'].toLowerCase().includes(filterValue) ||
            Object.keys(option).some(
              (k) =>
                option[k] &&
                option[k].toString().toLowerCase().includes(filterValue)
            ))
        );
      });
    }
    return this.otherLocationsOptions;
  }
  getImagePreview(files: any): void {
    if (files.length === 0) return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    const limit = 5 * 1024 * 1024;
    if (files[0].size > limit) {
      this.maxFileSize = true;
      this.file = null;
      this.imgURL =
        this.services.deliverableCommonService.defaultDeliverableImage;
      return;
    } else {
      this.maxFileSize = false;
    }
    this.file = files[0];
    // this.catalogueForm.markAsDirty();
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.imgURL = reader.result;
    };
  }
  _afterCreateForm() {
    // this.imgURL = this.services.storyEditCommonService.defaultStoryImage;
    this.imgURL =
      this.services.deliverableCommonService.defaultDeliverableImage;
    this.services.storyEditCommonService.afterCreateForm(this);
    if (this.isNew && this.options.fiscal_year_default_value) {
      this.storyForm
        .get('eac_qrtr')
        .setValue(this.options.fiscal_year_default_value.qtr, {
          emitEvent: false
        });
      this.storyForm
        .get('eac_qrtr_year')
        .setValue(this.options.fiscal_year_default_value.year, {
          emitEvent: false
        });
    }
    this.storyForm
      .get('title')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        this.storyTitle = this.storyForm.get('title').value;
      });
    this.storyForm
      .get('subtitle')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        this.storySubTitle = this.storyForm.get('subtitle').value;
      });
    this.storyForm
      .get('hashtag')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        this.storyHashtag = this.storyForm.get('hashtag').value;
      });
    this.storyForm
      .get('context')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        const text = this.storyForm.get('context').value;
        if (text) {
          this.storyContext = this.getPlainText(text);
          this.isExceedsContextText =
            this.storyContext.length > 750 ? true : false;
        } else {
          this.storyContext = text;
          this.isExceedsContextText = false;
        }
      });
    this.storyForm
      .get('solution')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        const text = this.storyForm.get('solution').value;
        if (text) {
          this.storySolution = this.getPlainText(text);
          this.isExceedsSolutionText =
            this.storySolution.length > 750 ? true : false;
        } else {
          this.storySolution = text;
          this.isExceedsSolutionText = false;
        }
      });
    this.storyForm
      .get('impact')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        const text = this.storyForm.get('impact').value;
        if (text) {
          this.storyImpact = this.getPlainText(text);
          this.isExceedsImpactText =
            this.storyImpact.length > 750 ? true : false;
        } else {
          this.storyImpact = text;
          this.isExceedsImpactText = false;
        }
      });
    // this.storyForm.get('description').valueChanges
    //   .pipe(
    //     debounceTime(200)
    //   ).subscribe(() => {
    //     this.imageDescription = this.storyForm.get('description').value;
    //   });

    this.storyForm
      .get('other_groups')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        this._enableControls();
      });
    this.storyForm
      .get('other_workstreams')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        this._enableControls();
      });
    this.storyForm.valueChanges.subscribe(() => {
      if (this.isNew) {
        this.services.commonService.setSessionData(
          'UNSAVED_STORY',
          this._getFromData()
        );
      }
    });
    this.storyForm
      .get('partners')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        this.services.commonService.checkPartnersOption(this, this.storyForm);
      });
    this.storyForm
      .get('force')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(() => {
        this._enableControls();
      });
  }
  _enableControls() {
    if (this.options['groups'].length || this.orgTypeName == 'Liquid Studio') {
      this.services.storyEditCommonService.primaryOptionCheck(this);
      if (this.orgTypeName != 'Liquid Studio') {
        this.services.storyEditCommonService.otherOptionCheck(this);
      }
      this.services.storyEditCommonService.enableControls(this);
    }
  }

  _getFromData() {
    return this.services.storyEditCommonService.getFromData(this);
  }

  onChangeOwner(event): void {
    this.services.storyEditCommonService.onChangeOwner(this, event);
  }

  onChangeTeamMembers(event): void {
    this.options['team_members'] = [];
    this.teamMembersChanged.next(event.target.value);
  }

  onChangeContacts(event): void {
    this.options['contacts'] = [];
    this.contactsChanged.next(event.target.value);
  }

  onChangeKeyWord(event): void {
    this.options['keywords'] = [];
    this.keywordChanged.next(event.target.value);
  }

  onAddSelectValue(field: string, event: MatAutocompleteSelectedEvent): void {
    this.services.storyEditCommonService.onAddSelectValue(this, field, event);
  }

  getMultipleSelectOptions(
    field: string,
    key = 'name',
    onlyTypeHead = false,
    withoutFilter = false
  ): any[] {
    return this.services.storyEditCommonService.getMultipleSelectOptions(
      this,
      field,
      key,
      onlyTypeHead,
      withoutFilter
    );
  }

  getMultipleSelectValues(field: string): any {
    return this.storyForm.get(field).value;
  }

  getValueOfConfident(field): any {
    this.confidentiality_level = field;
    this.storyForm
      .get('confidentiality_level')
      .setValue(this.confidentiality_level, { emitEvent: false });
  }

  onAddMultipleSelectValue(
    field: string,
    event: MatAutocompleteSelectedEvent,
    allowEnter = false,
    key = '_id'
  ): void {
    this.services.storyEditCommonService.onAddMultipleSelectValue(
      this,
      field,
      event,
      allowEnter,
      key
    );
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.storyEditCommonService.onRemoveMultipleSelectValue(
      this,
      field,
      value,
      key
    );
  }

  get Messages(): any {
    return Messages;
  }

  getQuartsOptions(field: string): any[] {
    return this.services.storyEditCommonService.getQuartsOptions(this, field);
  }

  getGroupMaster(group) {
    return this.services.storyEditCommonService.getGroupMaster(this, group);
  }

  canDeactivate() {
    if (
      (this.storyForm && this.storyForm.dirty) ||
      this.relatedDeliverableChange ||
      this.iconChange ||
      this.imageChange
    ) {
      return Observable.create((observer: Observer<boolean>) => {
        const dialogRef = this.services.commonService.openDirtyDialog();
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.services.commonService.removeSessionData(
              'GET_SELECTED_DELIVERABLES'
            );
            this.services.commonService.removeSessionData('UNSAVED_STORY');
          } else {
            this.location.go(this.currentUrl);
          }
          observer.next(result);
          observer.complete();
        });
      });
    }
    return true;
  }

  _formReset() {
    this.services.storyEditCommonService.formReset(this);
    setTimeout(() => {
      this.services.commonService.removeSessionData('UNSAVED_STORY');
    }, 100);
  }

  onCancel(): void {
    this.currentUrl = this.location.path();
    this.location.back();
  }

  isValueNotFoundInOptions(field: string, key: string): boolean {
    return this.services.storyEditCommonService.isValueNotFoundInOptions(
      this,
      field,
      key
    );
  }

  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }
  checkRichTextFields(): boolean {
    let isExceeds = false;
    if (
      this.isExceedsContextText ||
      this.isExceedsImpactText ||
      this.isExceedsSolutionText
    ) {
      isExceeds = true;
    }
    return isExceeds;
  }
  _isValidDraft(): boolean {
    const isExceedsRichtext = this.checkRichTextFields();
    const isValidQuarter =
      this.storyForm.get('eac_qrtr').invalid &&
      this.storyForm.get('eac_qrtr').errors &&
      this.storyForm.get('eac_qrtr').errors.mustLessThenMatch;
    if (
      this.storyForm.get('title').valid &&
      this.storyForm.get('owner').valid &&
      !isValidQuarter &&
      !isExceedsRichtext
    ) {
      return true;
    }
    this.invalidFocus();
    return false;
  }

  _isValid(): boolean {
    const isExceedsRichtext = this.checkRichTextFields();
    if (
      this.storyForm.valid &&
      this.options.pictures.length &&
      !isExceedsRichtext
    ) {
      return true;
    }
    this.invalidFocus();
    return false;
  }

  invalidFocus() {
    this.services.storyEditCommonService.invalidFocus(this);
  }

  onSaveDraft(): void {
    this.submitted = false;
    this.isImageSubmistion = true;
    this.draftSubmitted = true;
    if (
      this._isValidDraft() &&
      !this.loading &&
      this.overFileSize() &&
      this.isHashTagValidated
    ) {
      this.relatedDeliverableChange = false;
      this.iconChange = false;
      this.imageChange = false;
      if (this.isNew) {
        this.loading++;
        this.services.storiesService
          .saveDraft(
            this.services.commonService.removeNullObjects(this._getFromData()),
            this.fileList
          )
          .subscribe((response) => {
            this.services.storyEditCommonService.afterSave(
              this,
              response,
              true,
              true
            );
          });
      } else {
        this.loading++;
        this.services.storiesService
          .updateDraft(
            this.storyId,
            this.services.commonService.removeNullObjects(this._getFromData()),
            this.fileList,
            this.deleteStoryList
          )
          .subscribe((response) => {
            this.services.storyEditCommonService.afterSave(
              this,
              response,
              false,
              true
            );
          });
      }
    }
  }
  overFileSize(): boolean {
    let fileSize = 0;
    const limit = 4.5 * 1024 * 1024;
    this.verifyIconLength?.forEach((element) => {
      fileSize += element.imgFile.size;
    });
    if (fileSize > limit) {
      this.isExceedFileSize = true;
      this.isShowIconMesage = false;
      this.isShowPicturesMesage = false;
      return false;
    } else {
      this.isExceedFileSize = false;
      return true;
    }
  }
  onSave(): void {
    this.submitted = true;
    this.isImageSubmistion = true;
    if (
      this._isValid() &&
      !this.loading &&
      this.overFileSize() &&
      this.isHashTagValidated
    ) {
      this.loading++;
      this.relatedDeliverableChange = false;
      this.iconChange = false;
      this.imageChange = false;
      if (this.isNew) {
        this.services.storiesService
          .save(
            this.services.commonService.removeNullObjects(this._getFromData()),
            this.fileList,
            this.orgTypeName
          )
          .subscribe((response) => {
            this.services.storyEditCommonService.afterSave(
              this,
              response,
              true,
              false
            );
          });
      } else {
        this.services.storiesService
          .update(
            this.storyId,
            this.services.commonService.removeNullObjects(this._getFromData()),
            this.fileList,
            this.orgTypeName,
            this.deleteStoryList
          )
          .subscribe((response) => {
            this.services.storyEditCommonService.afterSave(
              this,
              response,
              false,
              false
            );
          });
      }
    }
  }
  getSolutionInnerHTMLByEditor(eve): void {
    this.solutionText = eve.event.target.contentDocument.children[0].innerText;
  }
  getContextTextInnerHTMLByEditor(eve): void {
    this.contextText = eve.event.target.contentDocument.children[0].innerText;
  }
  getimpactTextInnerHTMLByEditor(eve): void {
    this.impactText = eve.event.target.contentDocument.children[0].innerText;
  }
  openRelatedDeliverablesPopup(): void {
    this.relatedDeliverableChange = true;
    this.dialog.open(SelectedDeliverablesPopupComponent, {
      width: '1090px',
      height: '520px',
      maxHeight: '95vh',
      data: null
    });
  }
  // openPopup(): void {
  //   // this.storyForm.get('description').setValue('');
  //   // this.imgURL = this.services.deliverableCommonService.defaultDeliverableImage;
  //   // this.relatedsotryChange = true;
  // }

  openPopup(): void {
    this.isImageSubmistion = false;
    this.imageChange = true;
    if (this.options.pictures?.length == 3) {
      this.isShowPicturesMesage = true;
      return;
    }
    if (!this.options.pictures) {
      this.options.pictures = [];
    }
    this.relatedDeliverableChange = true;
    const dialogRef = this.dialog.open(StoryImageDialogComponent, {
      width: '490px',
      height: 'auto',
      maxHeight: '95vh'
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const file = dialogRef.componentInstance.file;
        this.storyImage = {};
        this.storyImage._id = dialogRef.componentInstance.storyImageid;
        this.imageStory._id = this.storyImage._id;
        this.storyImage.image_type = 'new';
        this.storyImage.image_description =
          dialogRef.componentInstance.image_description;

        this.storyImage.image_url = dialogRef.componentInstance.image_url;
        this.options.pictures.push(this.storyImage);
        this.imageStoryFiles = {};
        this.imageStoryFiles._id = dialogRef.componentInstance.storyImageid;
        this.isDescription = this.storyImage.image_description;
        this.imageStoryFiles.imgFile = file;
        this.fileList.push(this.imageStoryFiles);
        this.verifyIconLength.push(this.imageStoryFiles);
      }
    });
  }

  openIconPopup(): void {
    this.isImageSubmistion = false;
    this.iconChange = true;
    if (this.options.icons?.length == 10) {
      this.isShowIconMesage = true;
      return;
    }
    if (!this.options.icons) {
      this.options.icons = [];
    }
    const dialogRef = this.dialog.open(StoryIconDialogComponent, {
      width: '490px',
      height: 'auto',
      maxHeight: '95vh'
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const file = dialogRef.componentInstance.file;
        this.storyImage = {};
        this.storyImage._id = dialogRef.componentInstance.storyImageid;
        this.imageStory._id = this.storyImage._id;
        this.storyImage.image_type = 'new';
        this.storyImage.image_url = dialogRef.componentInstance.image_url;
        this.options.icons.push(this.storyImage);
        this.imageStoryFiles = {};
        this.imageStoryFiles._id = dialogRef.componentInstance.storyImageid;
        this.imageStoryFiles.imgFile = file;
        this.fileList.push(this.imageStoryFiles);
        this.verifyIconLength.push(this.imageStoryFiles);
      }
    });
  }

  // saveStoryImage(): void {
  //   this.relatedsotryChange = false;
  //   if (!this.storyForm.get('description').value.trim() || !this.file) {
  //     return;
  //   }

  //   this.storyImage._id = uuid.v4();
  //   this.imageStory._id = this.storyImage._id;
  //   this.storyImage.image_type = 'new';
  //   this.storyImage.image_description = this.storyForm.get('description').value.trim();
  //   this.storyImage.image_url = this.imgURL;
  //   this.options.pictures.push(this.storyImage);
  //   this.imageStoryFiles._id = this.storyImage._id;
  //   this.imageStoryFiles.imgFile = this.file;
  //   this.fileList.push(this.imageStoryFiles);
  // }

  getImage(picutres) {
    try {
      picutres.forEach((element) => {
        let blob = null;
        const xhr = new XMLHttpRequest();
        xhr.open('Get', element.image_url);
        xhr.setRequestHeader('Accept', 'image/png');
        xhr.onload = () => {
          if (xhr.status === 200) {
            blob = xhr.response;
            const imgFile = new File([blob], element.fileName, {
              type: 'image/png'
            });
            this.imageObj._id = element._id;
            this.imageObj.imgFile = imgFile;
            this.fileList.push(this.imageObj);
            // callback.call(this, imgFile);
          }
        };
        xhr.send();
      });
    } catch (error) {
      console.log(error);
    }
  }

  getDataImage(data: any, type): string {
    if (data && data.image_link) {
      const dataId = data._id;
      if (this.dataImages[dataId] && this.dataImages[dataId] != 'loading') {
        return this.dataImages[dataId];
      } else if (this.dataImages[dataId] != 'loading') {
        this.dataImages[dataId] = 'loading';
        this.services.masterService
          .getImage(dataId, type, this.isForList, this.relatedDelThumbnailSize)
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[dataId] = reader.result;
              };
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }

  getDataStoryImage(data: any, type): string {
    if (data && data.image_url) {
      const dataId = data._id;
      if (this.dataImages[dataId] && this.dataImages[dataId] != 'loading') {
        return this.dataImages[dataId];
      } else if (this.dataImages[dataId] != 'loading') {
        this.dataImages[dataId] = 'loading';
        this.services.masterService
          .getImage(
            data.image_url,
            type,
            this.isForList,
            this.relatedDelThumbnailSize
          )
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.dataImages[dataId] = reader.result;
              };
              this.imageStoryFiles = {};
              this.imageStoryFiles._id = data._id;
              this.imageStoryFiles.imgFile = response;
              this.verifyIconLength.push(this.imageStoryFiles);
            }
          });
      }
    }
    return this.services.deliverableCommonService.defaultDeliverableImage;
  }

  _getMarketsInfo(): void {
    this.loadMarkets++;
    this.services.storiesService.getMarketsInfo().subscribe((response) => {
      this.loadMarkets--;
      if (response && response.result) {
        this.marketsList = response.result.markets.split(',');
        this.options.markets;
      }
    });
  }

  // onRemoveSotryImage(item) { }
  onRemoveDeliverable(item) {
    this.relatedDeliverableChange = true;
    this.options.related_deliverables =
      this.options.related_deliverables.filter((o) => o._id !== item._id);
    this.services.commonService.setSessionData(
      'GET_SELECTED_DELIVERABLES',
      this.options.related_deliverables
    );
  }

  onRemovestory(item) {
    this.imageChange = true;
    this.isShowPicturesMesage = false;
    this.isImageSubmistion = false;
    this.deleteStoryList.push(item);
    // this.relatedDeliverableChange = true;
    this.options.pictures = this.options.pictures.filter(
      (o) => o._id !== item._id
    );
    this.fileList = this.fileList.filter((o) => o._id !== item._id);
    this.verifyIconLength = this.verifyIconLength.filter(
      (o) => o._id !== item._id
    );
    this.overFileSize();
    // this.services.commonService.setSessionData('GET_SELECTED_STORY_IMAGE', this.options.pictures);
  }
  onRemoveIconstory(item) {
    this.iconChange = true;
    this.isImageSubmistion = false;
    this.isShowIconMesage = false;
    this.deleteStoryList.push(item);
    // this.relatedDeliverableChange = true;
    this.options.icons = this.options.icons.filter((o) => o._id !== item._id);
    this.fileList = this.fileList.filter((o) => o._id !== item._id);
    this.verifyIconLength = this.verifyIconLength.filter(
      (o) => o._id !== item._id
    );
    this.overFileSize();
    // this.services.commonService.setSessionData('GET_SELECTED_STORY_IMAGE', this.options.pictures);
  }
  /**
   * check if there is any image is loading
   * @returns true: has image loding
   */
  hasImageLoading() {
    const imageIds = Object.keys(this.dataImages);
    return imageIds.some((imageId) => this.dataImages[imageId] === 'loading');
  }

  onFocusOut(event) {
    if (this.storyHashtag.length > 0) {
      var hashValue = this.storyHashtag;

      var arrLineWiseChars = hashValue.split('\n');

      var maxNumOfCharsPerLine = 50;

      var maxNumOfLines = 2;

      var noOfLinesEntered = arrLineWiseChars.length;

      this.isHashTagValidated = true;

      // Line validation

      if (noOfLinesEntered > maxNumOfLines) {
        this.isHashTagValidated = false;
      }

      if (this.isHashTagValidated) {
        for (var i = 0; i < arrLineWiseChars.length; i++) {
          if (arrLineWiseChars[i].length > maxNumOfCharsPerLine) {
            this.isHashTagValidated = false;
          }
        }
      }
    } else this.isHashTagValidated = true;
  }
}
