/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OrgType } from '@app/models';
@Injectable()

export class ProjectFilterCommonService {
  getGroups(self, active): void {
    self.loading++;
    const subscription = self.services.masterService.getGroups({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['group'] = response.result.filter((o) => o.organizationType.name != OrgType.INCUBATION);
          self.groupOrder = response.order;
        }
      });
    self.cancelSubscriberBasket.push(subscription);
  }

  getProjectStatus(self, active): void {
    self.loading++;
    const subscription = self.services.projectsService.getProjectStatus({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['status'] = response.result;
        }
      });
    self.cancelSubscriberBasket.push(subscription);
  }

  getInitiatives(self, active): void {
    self.loading++;
    const subscription = self.services.masterService.getInitiatives({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['initiative'] = response.result;
        }
      });
    self.cancelSubscriberBasket.push(subscription);
  }

  getMultipleSelectValues(self, field: string): any {
    return self.filterForm.get(field).value;
  }

  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    const input = document.getElementById(field);
    const index = self.services.commonService.objectIndexOf(values, event.option.value, key);
    if (index < 0) {
      values.push(event.option.value);
    }
    input['value'] = '';
    input.blur();
    self.filterForm.get(field).setValue(values);
  }

  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    const index = self.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.filterForm.get(field).setValue(values);
  }

  hasFilter(self) {
    let res = false;
    for (const item in self.filter) {
      if (self.filter[item] && self.filter[item].length) {
        res = true;
      } else if ((item == 'start_qrtr' || item == 'eac_qrtr') && self.filter[item]) {
        res = true;
      }
    }
    return res;
  }
}