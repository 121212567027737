
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddLocationComponent } from '@app/dialog/add-location/add-location.component';
import { Messages } from '@app/models';
import { CommonService } from '@app/services';
import { LocationsService } from '@app/services/location.service';
import { MasterService } from '@app/services/master.service';

@Component({
  selector: 'app-location-view',
  templateUrl: './location-view.component.html',
  // styleUrls: ['./location-view.component.scss']
})
export class LocationViewComponent implements OnInit {
  public locationList;
  public columns: string[] = ['checked', 'name', 'location_lead_name'];
  public headersData = ['', 'Locations', 'Knowledge Managers '];
  public tableOrder = 'asc';
  public selectedOption = 'All';
  public checkedCount = 0;
  public search = '';
  public selectedLocations: string[];
  public locationCount = [];
  public totalLocations = [];
  public placeHolder;
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public editSelectedLocation = false;
  public loadVal = 0;
  locationsIdList: any[];
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    private locationsService: LocationsService,
    public dialog: MatDialog,
    private masterService: MasterService) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.getLocations();
  }
  getLocations(): void {
    this.loadVal++;
    this.locationList = [];
    let params;
    if (this.selectedOption === 'All') {
      params = { active: 'All', orgTypeName: this.orgTypeName };
    } else if (this.selectedOption === 'Enabled') {
      params = { active: true, orgTypeName: this.orgTypeName };
    } else if (this.selectedOption === 'Disabled') {
      params = { active: false, orgTypeName: this.orgTypeName };
    }
    this.locationsService.getLocations(params).subscribe((response) => {
      if (response && response.result) {
        response.order == 1 ? this.tableOrder = 'asc' : this.tableOrder = 'desc';
        this.locationList = response.result;
        this.locationList.forEach((element) => {
          element['checked'] = false;
          element['active'] = !element.archive_ind;
          if (element.location_lead.length > 0) {
            const allDisplayName: any = [];
            for (const i of element.location_lead) {
              if (i.display_name !== undefined && i.display_name !== null) {
                allDisplayName.push(i.display_name);
              }
            }
            allDisplayName.sort();
            element['location_lead_name'] = allDisplayName.join(';  ');
          }
        });
        Object.assign(this.totalLocations, this.locationList);
        this.enabledValue = this.locationList;
        this.locationList = this.locationList.map(({ description, country, ...rest }) => ({ ...rest }));
        this.locationList = new MatTableDataSource(this.locationList);
        this.locationList._filter.next(this.search);
        if (this.search.trim() == '') {
          this.locationCount = [`${this.locationList.filteredData.length} Entries`, ''];
        }
        else {
          this.locationCount = [`${this.locationList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
        }
        this.loadVal--;
      }
    }, (error1) => {
      this.loadVal--;
      this.commonService.handleError(error1);
    });
  }
  getSelectedOption(selected): void {
    this.selectedLocations = [];
    this.checkedCount = 0;
    this.editSelectedLocation = false;
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.locationsIdList = [];
    this.locationList.filteredData.forEach((element) => {
      if (element.checked === true) {
        this.checkedCount++;
        this.selectedLocations.push(element.group_name);
        this.totalLocations.forEach((item) => {
          if (item.name === element.name) {
            this.locationsIdList.push({ '_id': item._id });
          }
        });
      }
    });
    if (this.checkedCount > 0) {
      this.editSelectedLocation = true;
    }
  }
  getEnableDisableStatus(value): void {
    this.selectedOption = value;
    this.getLocations();
  }
  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.locationList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.locationCount = [`${this.locationList.filteredData.length} Entries`, ''];
    }
    else {
      this.locationCount = [`${this.locationList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }
  setStatus(value): void {
    this.loadVal++;
    this.selectedOption = value;
    const params = { disable: !(value === 'Enabled') };
    this.locationsService.updateLocationStatus(params, this.locationsIdList)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.getLocations();
          this.editSelectedLocation = false;
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  addNewLocation(add): void {
    if (add) {
      const editData = { 'orgTypeName': this.orgTypeName };
      const dialogRef = this.dialog.open(AddLocationComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.LOCATION_ADDED_SUCCESS);
          this.getLocations();
        }
      });
    }
  }
  getSelectedOrder(value): void {
    this.loadVal++;
    const params = { collection_name: 'locations', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.LOCATION_ORDER_SAVE_SUCCESS);
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getClickedItem(value): void {
    if (value) {
      const editData = { 'value': value, 'orgTypeName': this.orgTypeName };
      const dialogRef = this.dialog.open(AddLocationComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.LOCATION_EDITED_SUCCCESS);
          this.getLocations();
        }
      });
    }
  }
}
