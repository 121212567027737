<div fxLayout="column">
    <div fxLayout="column" class="filter-wrap" [formGroup]="filterForm" *ngIf="show" [@slideInOut]>
        <label id="lblFilterDeliverablesBy">Filter Deliverables By:</label>
        <div fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="33" class="form-group">
                    <input id="group" type="text" placeholder="Group" class="form-control" [matAutocomplete]="group"
                        aria-label="group" />
                    <mat-autocomplete id="group_auto" #group="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('group', $event)">
                        <mat-option id="group_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('group');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="group_list">
                        <mat-chip id="group_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('group');let i=index"
                            (removed)="onRemoveMultipleSelectValue('group', option)">
                            {{option.name}}
                            <span id="group_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>

                <div fxFlex="33" class="form-group">
                    <input id="workstream" type="text" placeholder="Workstream" class="form-control"
                        [matAutocomplete]="workstream" aria-label="workstream" />
                    <mat-autocomplete id="workstream_auto" #workstream="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('workstream', $event)">
                        <mat-option id="workstream_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('workstream');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="workstream_list">
                        <mat-chip id="workstream_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('workstream');let i=index"
                            (removed)="onRemoveMultipleSelectValue('workstream', option)">
                            {{option.name}}
                            <span id="workstream_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group">
                    <input id="location" type="text" placeholder="Location" class="form-control"
                        [matAutocomplete]="location" aria-label="location" />
                    <mat-autocomplete id="location_auto" #location="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('location', $event)">
                        <mat-option id="location_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('location');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="location_list">
                        <mat-chip id="location_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('location');let i=index"
                            (removed)="onRemoveMultipleSelectValue('location', option)">
                            {{option.name}}
                            <span id="location_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="33" class="form-group">
                    <input id="status" type="text" placeholder="Deliverable Status" class="form-control"
                        [matAutocomplete]="status" aria-label="Deliverable Status" />
                    <mat-autocomplete id="status_auto" #status="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('status', $event)">
                        <mat-option id="status_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('status');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="status_list">
                        <mat-chip id="status_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('status');let i=index"
                            (removed)="onRemoveMultipleSelectValue('status', option)">
                            {{option.name}}
                            <span id="status_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group">
                    <input id="project" type="text" placeholder="Project" class="form-control"
                        [matAutocomplete]="project" aria-label="project" />
                    <mat-autocomplete id="project_auto" #project="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('project', $event)">
                        <mat-option id="project_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('project');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="project_list">
                        <mat-chip id="project_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('project');let i=index"
                            (removed)="onRemoveMultipleSelectValue('project', option)">
                            {{option.name}}
                            <span id="project_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group">
                    <input id="initiative" type="text" placeholder="Initiative" class="form-control"
                        [matAutocomplete]="initiative" aria-label="initiative" />
                    <mat-autocomplete id="initiative_auto" #initiative="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('initiative', $event)">
                        <mat-option id="initiative_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('initiative');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="initiative_list">
                        <mat-chip id="initiative_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('initiative');let i=index"
                            (removed)="onRemoveMultipleSelectValue('initiative', option)">
                            {{option.name}}
                            <span id="initiative_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <div fxFlex="33" class="form-group">
                    <input id="keyword" type="text" placeholder="Keyword" class="form-control"
                        [matAutocomplete]="keyword" (keyup)="onChangeKeyWord($event)" aria-label="keyword" />
                    <mat-progress-bar *ngIf="loadKeywords" color="accent" mode="indeterminate"></mat-progress-bar>
                    <mat-autocomplete id="keyword_auto" #keyword="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('keyword', $event, null)">
                        <mat-option id="keyword_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('keyword', null, true);let i=index"
                            [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="keyword_list">
                        <mat-chip id="keyword_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('keyword');let i=index"
                            (removed)="onRemoveMultipleSelectValue('keyword', option, null)">
                            {{option}}
                            <span id="keyword_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group">
                    <input id="deliverable_type" type="text" placeholder="Deliverable Types" class="form-control"
                        [matAutocomplete]="deliverable_type" aria-label="Deliverable Type">
                    <mat-autocomplete id="deliverable_type_auto" #deliverable_type="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('deliverable_type', $event)">
                        <mat-option id="deliverable_type_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('deliverable_type');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="deliverable_type_list">
                        <mat-chip id="deliverable_type_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('deliverable_type');let i=index"
                            (removed)="onRemoveMultipleSelectValue('deliverable_type', option)">
                            {{option.name}}
                            <span id="deliverable_type_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group" *ngIf="!isDraft">
                    <input id="workflow_status" type="text" placeholder="Workflow Status" class="form-control"
                        [matAutocomplete]="workflow_status" aria-label="workflow_status">
                    <mat-autocomplete id="workflow_status_auto" #workflow_status="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('workflow_status', $event)">
                        <mat-option id="workflow_status_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('workflow_status');let i=index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="workflow_status_list">
                        <mat-chip id="workflow_status_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('workflow_status');let i=index"
                            (removed)="onRemoveMultipleSelectValue('workflow_status', option)">
                            {{option.name}}
                            <span id="workflow_status_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="33" class="form-group" *ngIf="isDraft">
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="10px">
                <button id="applyFilters" mat-flat-button color="primary" (click)="onFilter('applyFilters')">Apply Filters
                </button>
            </div>
        </div>
    </div>
    <div fxLayout="row wrap" fxLayoutGap="13px" class="filter-values" *ngIf="hasFilter()">
        <label id="lblAppliedFilters">Applied Filters:</label>
        <ng-container *ngFor="let item of filter | keyvalue;let i=index">
            <ng-container *ngIf="item.value && item.value.length">
                <div id="option{{i}}{{j}}" class="tag" *ngFor="let option of item.value;let j=index">
                    <span>{{filterLabels[item.key]}}:</span>&nbsp; {{option && option.name ? option.name : option}}
                    <a id="remove{{i}}{{j}}" class="remove_tag" (click)="onRemoveFilter(item.key, option)"><span
                        ><i class="ic-close"></i></span></a>
                </div>
            </ng-container>
        </ng-container>
        <button id="clearAllFilters" mat-button class="clear-btn" matRipple [matRippleUnbounded]="false" [matRippleColor]="'rgba(255, 255, 255, .3)'" 
        [matRippleCentered]="false" (click)="onClearAllFilter()">Clear All Filters</button>
    </div>
</div>