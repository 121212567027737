<div fxFlex fxLayout="column" class="load-setting-dialog" role="dialog" aria-labelledby="h_LoadSettings">
    <div fxLayout="column" class="dialog-header">
        <h2 id="h_LoadSettings" class="dialog-title">Load Saved Report Settings</h2>
    </div>
    <div fxLayout="column" class="dialog-content">
        <p id="loadReportText">Select the report you would like to load. This will update your filters and table view.</p>
        <div class="form-group">
            <label id="lblSelectReport">Select report</label>
            <mat-select id="drpSelectReport" [(ngModel)]="setting" [compareWith]="onCompareObjects" aria-label="Select report">
                <mat-option id="optSelectReport{{i}}" *ngFor="let option of settings;let i=index" [value]="option">{{option.name}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="dialog-actions">
        <button id="delete" mat-button type="button"  color="warn" (click)="onDelete()" class="btn-delete" [disabled]="!setting">Delete</button>
        <div fxFlex></div>
        <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
        <button id="loadReport" mat-flat-button type="button" color="primary" (click)="onLoad()" [disabled]="!setting">Load Report</button>
    </div>
</div>