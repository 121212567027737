import { Component, OnInit } from '@angular/core';
import { CommonService } from '@app/services';

@Component({
  selector: 'app-projects-draft',
  template: '<app-project-list></app-project-list>'
})
export class DraftProjectsComponent implements OnInit {
  constructor(
    public commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(true);
  }
}
