<form fxLayout="column" [formGroup]="requestForm" (ngSubmit)="onSendRequest()" class="project-dialog" role="dialog" aria-labelledby="recipientTitle">
    <h2 id="recipientTitle" class="dialog-title">SELECT RECIPIENT</h2>
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && requestForm.get('recipient').invalid }">
            <mat-select id="drpDisplayName" formControlName="recipient" aria-label="select recipient">
                <mat-option id="optDisplayName{{i}}" *ngFor="let option of reviewers;let i=index" [value]="option">{{option.display_name}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
        <button id="sendRequest" mat-flat-button type="submit" color="primary" cdkFocusInitial>Send Request</button>
    </div>
</form>