/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MetricService } from '@app/services/metric.service';
import { CommonService } from '@app/services';
@Component({
  selector: 'app-metric-popup',
  templateUrl: './metric-popup.component.html',
  // styleUrls: ['./metric-popup.component.scss']
})
export class MetricPopupComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  submitted = false;
  metricName= '';
  public loadVal = 0;
  public inputMetricName:any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<MetricPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private commonService: CommonService,
    private formBuilder: UntypedFormBuilder,
    private metricsService: MetricService
  ) { }

  ngOnInit(): void {
    if (this.data.value){
      this.metricName = this.data.value.name;
      this.inputMetricName = this.metricName;
    }
    this.showForm = this.formBuilder.group({
      metricName: [{ value: this.metricName, disabled: false }, [Validators.required, this.noWhitespaceValidator]]
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    const orgTypeName= this.data.orgTypeName;
    if (this._isValid()){
      this.loadVal++;
      if (this.data.value){
        const body ={ 'name': this.showForm.get('metricName').value.trim(),'orgTypeName':orgTypeName};
        this.metricsService.editMetrics(this.data.value._id, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{
        
        const body ={'name': this.showForm.get('metricName').value.trim(),'orgTypeName':orgTypeName};
        this.metricsService.addMetrics(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }

  _isValid(): boolean {
    if (this.showForm.valid) {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  changeMetricValue(value){
    this.inputMetricName = value;
  }
}
