<form fxLayout="column" [formGroup]="reworkForm" (ngSubmit)="onRework()" class="deliverable-dialog" role="dailog" aria-labelledby="addComments">
    <div fxLayout="column">
        <h2 id="addComments" class="dialog-title">Add notes or comments</h2>
        <div fxLayout="column" class="dialog-content">
            <div class="form-group" [ngClass]="{'error': submitted && reworkForm.get('comments').invalid }" style="position: relative;">
                <label id="lblNotesOrComments">Notes or Comments</label>
                <textarea id="changeComment" formControlName="comments" class="form-control" aria-label="Notes or Comments" (keyup)="changeCommentValue(reworkForm.get('comments').value)"></textarea>
                <span id="commentLength" class="hint">{{inputComments ? inputComments.length : 0}}/3000</span>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
            <button id="sendReassignment" mat-flat-button type="submit" color="primary" cdkFocusInitial>Send Reassignment</button>
        </div>
    </div>

</form>