/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';

export class DeliverableTypeService {
  getDeliverableType(params): Observable<any> {
    return of(params);
  }

  addDeliverableType(reqbody): Observable<any> {
    return of(reqbody);
  }

  editDeliverableType(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  updateDeliverabletypeStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}
