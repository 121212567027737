<div fxFlex fxLayout="column" *ngIf="project" class="view-project" tabindex="0" (keydown.enter)="onBack()">
    <div class="overlay-section">
        <img alt="image preview" id="imagePreview" [src]="getProjectImage(project)" class="project_image_preview" />
        <div fxLayout="row wrap" fxLayoutGap="30px" class="pri-btn-view-wrap">
            <button id="back" mat-flat-button color="primary" (click)="onBack()">BACK</button>
            <div fxFlex></div>
            <button id="edit" mat-flat-button *ngIf="project.canEdit" [routerLink]="['/projects', project._id]"  color="primary">Edit</button>
            <button id="reassign" mat-flat-button *ngIf="services.commonService.hasAnyLeadRole() && project.canApprove" color="primary" (click)="onReassign()">Reassign</button>
            <button id="approve" mat-flat-button *ngIf="services.commonService.hasAnyLeadRole() && project.canApprove" color="primary" (click)="onApprove()">Approve</button>
            <button id="sendReminder" mat-flat-button *ngIf="canSendReminder(project)" color="primary" (click)="onSendReminder()">Send Reminder</button>
            <button id="requestReview" mat-flat-button *ngIf="project.canRequestReview" color="primary" (click)="onReviewRequest()">Request Review</button>
            <button id="advanceStage" mat-flat-button *ngIf="project.canAdvancedStage && project.stage_number < 5" color="primary" (click)="onAdvanceStage()">Advance Stage</button>
            <button id="recommend" mat-flat-button color="primary" (click)="services.projectsCommonService.sendRecommend(project)">Recommend</button>
        </div>
        <div fxFlex fxLayout="column" class="overlay-content">
            <div fxFlex="65" class="introduce">
                <h2 id="name" class="white-space-pre">{{project.name}}</h2>
                <h3 id="short_description">{{project.short_description}}</h3>
            </div>
            <div fxFlex="33" fxLayout="column" class="userList">
                <h4 id="team_members">TEAM MEMBERS</h4>
                <div fxLayout="row" *ngFor="let user of project.team_members;let i=index" class="user-wrap">
                    <img alt="user profile" id="userImage{{i}}" [src]="getProfileImage(user.eso_id)" class="user-profile" />
                    <div fxFlex fxLayout="column" class="user-name-wrap">
                        <div id="userName{{i}}" class="user_name">{{user.display_name}}</div>
                        <div id="jobTitle{{i}}" class="job_title">{{user.job_title }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="stage_wrap" fxLayout="row">
        <div id="stage1" class="stage"
            [ngClass]="{'done': project.stage_number > 1, 'current':  project.stage_number == 1}">
            <div class="circle">
                <span>1</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.416" height="14.103" viewBox="0 0 19.416 14.103">
                    <path d="M0,5.681,6.635,12,18,0" transform="translate(0.69 0.688)" fill="none" stroke="#fff"
                        stroke-miterlimit="10" stroke-width="2" />
                </svg>
            </div>
            <div class="stage-name">{{stageInfoName[0]}}</div>
            <div class="stage-work-flow"
                *ngIf="project.stage_number == 1 && project.workflow_status != WorkflowStatus.SAVED">
                {{project.workflow_status}}</div>
        </div>
        <div class="stage-line"></div>
        <div id="stage2" class="stage"
            [ngClass]="{'done': project.stage_number > 2, 'current':  project.stage_number == 2}">
            <div class="circle">
                <span>2</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.416" height="14.103" viewBox="0 0 19.416 14.103">
                    <path d="M0,5.681,6.635,12,18,0" transform="translate(0.69 0.688)" fill="none" stroke="#fff"
                        stroke-miterlimit="10" stroke-width="2" />
                </svg>
            </div>
            <div class="stage-name">{{stageInfoName[1]}}</div>
            <div class="stage-work-flow"
                *ngIf="project.stage_number == 2 && project.workflow_status != WorkflowStatus.SAVED">
                {{project.workflow_status}}</div>
        </div>
        <div class="stage-line"></div>
        <div id="stage3" class="stage"
            [ngClass]="{'done': project.stage_number > 3, 'current':  project.stage_number == 3}">
            <div class="circle">
                <span>3</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.416" height="14.103" viewBox="0 0 19.416 14.103">
                    <path d="M0,5.681,6.635,12,18,0" transform="translate(0.69 0.688)" fill="none" stroke="#fff"
                        stroke-miterlimit="10" stroke-width="2" />
                </svg>
            </div>
            <div class="stage-name">{{stageInfoName[2]}}</div>
            <div class="stage-work-flow"
                *ngIf="project.stage_number == 3 && project.workflow_status != WorkflowStatus.SAVED">
                {{project.workflow_status}}</div>
        </div>
        <div class="stage-line"></div>
        <div id="stage4" class="stage"
            [ngClass]="{'done': project.stage_number > 4, 'current':  project.stage_number == 4}">
            <div class="circle">
                <span>4</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.416" height="14.103" viewBox="0 0 19.416 14.103">
                    <path d="M0,5.681,6.635,12,18,0" transform="translate(0.69 0.688)" fill="none" stroke="#fff"
                        stroke-miterlimit="10" stroke-width="2" />
                </svg>
            </div>
            <div class="stage-name">{{stageInfoName[3]}}</div>
            <div class="stage-work-flow"
                *ngIf="project.stage_number == 4 && project.workflow_status != WorkflowStatus.SAVED">
                {{project.workflow_status}}</div>
        </div>
        <div class="stage-line"></div>
        <div id="stage5" class="stage"
            [ngClass]="{'done': project.stage_number > 5, 'current':  project.stage_number == 5}">
            <div class="circle">
                <span>5</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="19.416" height="14.103" viewBox="0 0 19.416 14.103">
                    <path d="M0,5.681,6.635,12,18,0" transform="translate(0.69 0.688)" fill="none" stroke="#fff"
                        stroke-miterlimit="10" stroke-width="2" />
                </svg>
            </div>
            <div class="stage-name">{{stageInfoName[4]}}</div>
            <div class="stage-work-flow"
                *ngIf="project.stage_number == 5 && project.workflow_status != WorkflowStatus.SAVED">
                {{project.workflow_status}}</div>
        </div>
    </div>

    <div fxLayout="row" fxLayoutGap="50px" class="project-content-wrap">
        <div fxFlex fxLayout="column" class="project-content">
            <div fxLayout="row" class="workflow_status" *ngIf="project.workflow_status != WorkflowStatus.SAVED">
                <span id="workflowStatus">{{project.workflow_status}}</span>
                <span id="workflowFromUser" *ngIf="project.workflow && project.workflow.from_user">by
                    {{project.workflow.from_user.display_name}} on {{project.workflow.updated | date}}</span>
                <span id="workflowLastUpdatedBy" *ngIf="!project.workflow && project.last_updated_by">by
                    {{project.last_updated_by.display_name}} on {{project.last_updated_date | date}}</span>
            </div>
            <div fxLayout="row" class="workflow_status"
                *ngIf="project.workflow_status == WorkflowStatus.APPROVED && isEdited()">
                <span id="edited">Edited</span>
                <span id="editedBy">by {{project.last_updated_by.display_name}} on {{project.last_updated_date |
                    date}}</span>
            </div>
            <div fxLayout="row" class="workflow_status"
                *ngIf="(project.workflow_status == WorkflowStatus.REVIEW_REQUESTED && project.workflow && project.workflow.assigned_user)">
                <span id="reviewRequested">Review requested</span>
                <span id="reviewRequestedBy">from {{project.workflow.assigned_user.display_name}} on
                    {{project.workflow.updated | date}}</span>
            </div>
            <p id="long_description">{{project.long_description}}</p>
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxFlex="50" fxLayout="column">
                    <h4 id="industries">INDUSTRY</h4>
                    <mat-chip-set id="industries_list">
                        <mat-chip id="industries_chip{{i}}" disableRipple="true" *ngFor="let option of project.industries;let i=index">
                            {{option.name}}
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="50" fxLayout="column">
                    <h4 id="keywords">KEYWORDS</h4>
                    <mat-chip-set id="keywords_list">
                        <mat-chip id="keywords_chip{{i}}" disableRipple="true" *ngFor="let option of project.keywords;let i=index">
                            {{option}}
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="20px">
                <div fxFlex="50" fxLayout="column">
                    <h4 id="initiatives">INITIATIVES FOR AFFILIATION</h4>
                    <mat-chip-set id="initiatives_list">
                        <mat-chip id="initiatives_chip{{i}}" disableRipple="true" *ngFor="let option of project.initiatives;let i=index">
                            {{option.name}}
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="50"></div>
            </div>
        </div>
        <div fxLayout="column" class="project-attributes">
            <h4 id="attributes">PROJECT ATTRIBUTES</h4>
            <div fxLayout="row" class="value-group">
                <div id="org_type" class="label">Organization</div>
                <div id="org_type_value" fxFlex class="value">
                    {{project.organization_type?project.organization_type.name:'n/a'}}</div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="project_lead" class="label">Project Lead</div>
                <div id="project_lead_value" fxFlex class="value">
                    {{project.project_lead?project.project_lead.display_name:'n/a'}}</div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="primary_group" class="label">Primary Group</div>
                <div id="primary_group_value" fxFlex class="value">
                    {{project.primary_group?project.primary_group.name:'n/a'}}</div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="other_groups" class="label">Other Groups</div>
                <div id="other_groups_value" fxFlex class="value"><span
                        *ngFor="let option of project.other_groups; last as isLast;let i=index">{{option.name}}<span
                            *ngIf="!isLast">, </span></span></div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="primary_workstream" class="label">Primary Workstream</div>
                <div id="primary_workstream_value" fxFlex class="value">
                    {{project.primary_workstream?project.primary_workstream.name:'n/a'}}</div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="other_workstreams" class="label">Other Workstreams</div>
                <div id="other_workstreams_value" fxFlex class="value"><span
                        *ngFor="let option of project.other_workstreams; last as isLast;let i=index">{{option.name}}<span
                            *ngIf="!isLast">, </span></span></div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="primary_location" class="label">Primary Location</div>
                <div id="primary_location_value" fxFlex class="value">
                    {{project.primary_location?project.primary_location.name:'n/a'}}</div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="other_locations" class="label">Other Locations</div>
                <div id="other_locations_value" fxFlex class="value"><span
                        *ngFor="let option of project.other_locations; last as isLast;let i=index">{{option.name}}<span
                            *ngIf="!isLast">, </span></span></div>
            </div>
            <div class="project-attributes-space"></div>
            <div fxLayout="row" class="value-group">
                <div id="last_edited" class="label">Last Edited</div>
                <div fxFlex class="value">
                    <span id="hasDate" *ngIf="project.last_updated_date">{{project.last_updated_date | date }}</span>
                    <span id="nullDate" *ngIf="!project.last_updated_date">n/a</span>
                </div>
            </div>
            <div class="project-attributes-space"></div>
            <div fxLayout="row" class="value-group">
                <div id="project_status" class="label">Project Status</div>
                <div id="project_status_value" fxFlex class="value">
                    {{project.project_status?project.project_status.name:''}}</div>
            </div>
            <div fxLayout="row" class="value-group">
                <div id="project_stage" class="label">Project Stage</div>
                <div fxFlex class="value">
                    <span id="hasStage" *ngIf="project.project_stage">{{project.project_stage.name }}</span>
                    <span id="nullStage" *ngIf="!project.project_stage">n/a</span>
                </div>
            </div>
            <div class="project-attributes-space"></div>
            <div fxLayout="row" class="value-group">
                <div id="endQTR" class="label">FiscalYear Quarter</div>
                <div id="endQTR_value" fxFlex class="value">
                    <span *ngIf="project.eac_qrtr">FY{{project.eac_qrtr.year-2000}} Q{{project.eac_qrtr.qtr}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="project-bottom" *ngIf="project.related_deliverables && project.related_deliverables.length">
        <div class="related-deliverables-wrap">
            <table aria-label="Related Deliverables">
                <thead>
                    <tr>
                        <th id="th_related_deliverables">Related Deliverables</th>
                        <th id="th_owner">Owner</th>
                        <th id="th_primary_group">Primary Group</th>
                        <th id="th_primary_location">Primary Location</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let relatedDeliverable of project.related_deliverables;let i=index">
                        <td id="td_related_deliverables{{i}}">
                            <div fxLayout="row" fxLayoutGap="15px">
                                <img id="deliverableImage{{i}}" [src]="getDeliverableImage(relatedDeliverable)" alt=""
                                    class="deliverable-image">
                                <div fxLayout="column">
                                    <h6 id="deliverableName{{i}}">{{relatedDeliverable.name}}</h6>
                                    <p id="deliverableDesc{{i}}">{{relatedDeliverable.short_description}}</p>
                                </div>
                            </div>
                        </td>
                        <td id="td_owner{{i}}">{{relatedDeliverable.owner?relatedDeliverable.owner.display_name:'n/a'}}</td>
                        <td id="td_primary_group{{i}}">
                            {{relatedDeliverable.primary_group?relatedDeliverable.primary_group.name:'n/a'}}</td>
                        <td id="td_primary_location{{i}}">
                            {{relatedDeliverable.primary_location?relatedDeliverable.primary_location.name:'n/a'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>