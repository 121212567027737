/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { of, Observable } from 'rxjs';

export class ProjectsService {
  getProjectStatus(params = null): Observable<any> {
    return of(params);
  }
  
  getProjectStages(params = null): Observable<any> {
    return of(params);
  }
  
  getQuarters(): Observable<any> {
    return of(null);
  }

  getProjectsKeywords(searchText: string): Observable<any> {
    return of(searchText);
  }

  saveDraft(project, file): Observable<any> {
    return of({ project, file });
  }

  save(project, file): Observable<any> {
    return of({ project, file });
  }

  getTypeAheadData(queryParams): Observable<any> {
    return of(queryParams);
  }

  getProjects(parameters: any, formData: any): Observable<any> {
    return of({ parameters, formData });
  }

  getProject(projectId: any): Observable<any> {
    return of(projectId);
  }

  updateDraft(projectId, project, file): Observable<any> {
    return of({ projectId, project, file });
  }

  update(projectId, project, file): Observable<any> {
    return of({ projectId, project, file });
  }

  projectNameVerification(projectName): Observable<any> {
    return of(projectName);
  }

  checkAdvanceStage(projectId): Observable<any> {
    return of({ projectId });
  }

  advanceStage(projectId): Observable<any> {
    return of({ projectId });
  }

  getReviewers(projectId: any): Observable<any> {
    return of(projectId);
  }

  reviewRequest(projectId: any, formData: any): Observable<any> {
    return of({ projectId, formData });
  }

  approveReassign(projectId: any, formData): Observable<any> {
    return of({ projectId, formData });
  }

  approveRework(projectId: any, formData): Observable<any> {
    return of({ projectId, formData });
  }

  approve(projectId: any): Observable<any> {
    return of(projectId);
  }

  sendReminder(projectId: any): Observable<any> {
    return of(projectId);
  }
}

