import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-message',
  templateUrl: './show-message.component.html',
  // styleUrls: ['./show-message.component.scss']
})
export class ShowMessageComponent implements OnInit {
  demoURL: any ='../../../assets/images/survey-banner.png';
  constructor() { }

  ngOnInit(): void {
  }

}
