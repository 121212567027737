<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm" class="add-dialog" *ngIf="showPop" role="dialog" aria-labelledby="h_TIInformation">
  <h2 id="h_TIInformation" class="dialog-title">TI Information</h2> 
  <div fxLayout="column" class="dialog-content">
    <div class="form-group" [ngClass]="{'error': submitted && showForm.get('parnterName').invalid }">
      <p id="titleParnterName">TI Partner Name</p>
      <input id="parnterName" type="text" formControlName="parnterName" class="form-control" placeholder="Partner"
        aria-label="TI parnter Name" maxlength="128" (keyup)="changePartnerNameValue(showForm.get('parnterName').value)" />
      <span id="parnterNameLength" class="hint point">{{inputPartnerName ? inputPartnerName.length :
        0}}/128</span>
    </div>
    <div class="form-group" [ngClass]="{'error': submitted && showForm.get('description').invalid }">
      <p id="description">TI Partner Description</p>
      <textarea id="descriptionText" rows="3" class="form-control short" formControlName="description"
        placeholder="Description" aria-label="TI Partner Description" maxlength="1000"
        (keyup)="changeDescriptionValue(showForm.get('description').value)"></textarea>
      <span id="descriptionLength" class="hint point">{{inputDescription ? inputDescription.length :
        0}}/1000</span>
    </div>
    <div class="form-group" [ngClass]="{'error': submitted && showForm.get('partnerType').invalid }">
      <p id="titlePartner">TI Partner Type</p>
      <mat-select id="drpPartner" formControlName="partnerType" class="form-control" placeholder="Select" aria-label="TI Partner Type"
        (selectionChange)="selectedPartnerTypeValue($event.value)">
        <mat-option id="partner_option{{i}}" *ngFor="let option of PartnerTypes;let i=index"
          [value]="option">{{option}}</mat-option>
      </mat-select>
    </div>
    <div class="form-group" [ngClass]="{'error': submitted && showForm.get('parnterMultipleOrg').invalid }">
      <p id="titleGroup">TI Primary Group</p>
      <mat-select id="drpGroup" formControlName="parnterMultipleOrg" class="form-control" placeholder="Select" aria-label="TI Primary Group"
        [compareWith]="onCompareObjects"
        (selectionChange)="selectedPrimaryGroupValue('parnterMultipleOrg',$event.value)">
        <mat-option id="partnergroup_option{{i}}" *ngFor="let option of this.data.orgTypes;let i=index"
          [value]="option">{{option.name}}</mat-option>
      </mat-select>
    </div>
    <div class="form-group">
      <p id="lblOrgGroup">TI Secondary/ R&D Group</p>
      <input id="parnterSecondaryGroup" type="text" class="form-control" placeholder="Select"
        [matAutocomplete]="parnter_secondary_group" [disabled]="showForm.get('parnterSecondaryGroup').disabled"
        aria-label=" TI parnter_secondary_group" />
      <mat-autocomplete id="group_auto" #parnter_secondary_group="matAutocomplete"
        (optionSelected)="onAddMultipleSelectValue('parnterSecondaryGroup', $event)" (opened)="disableScrollBar()"
        (closed)="enableScrollBar()">
        <mat-option id="group_option{{i}}" *ngFor="let option of filterSecondaryGroupByOrg();let i=index"
          [value]="option">{{option.name}}</mat-option>
      </mat-autocomplete>
      <mat-chip-set id="group_list">
        <mat-chip id="group_chip{{i}}" disableRipple="true" removable="true" selectable="false"
          *ngFor="let option of getMultipleSelectValues('parnterSecondaryGroup');let i=index"
          (removed)="onRemoveMultipleSelectValue('parnterSecondaryGroup', option)">
          {{option.name}}
          <span id="group_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
        </mat-chip>
      </mat-chip-set>
    </div>
    <!-- <div class="form-group">
      <p id="lblPartnerLocation">Location</p>
      <mat-select id="partnerLocation" placeholder="Select" formControlName="partnerLocation"
        (selectionChange)="onSelectionChangeLocation($event)">
        <mat-option [value]="null"></mat-option>
        <mat-option id="optPartnerLocation{{i}}" *ngFor="let option of filterLocationBySecondaryGroup();let i=index"
          [value]="option">
          {{option}}</mat-option>
      </mat-select>
    </div> -->
    <div class="form-group"
      [ngClass]="{'error': submitted && ( showForm.get('partnerLead').invalid && (selectedId =='' || this.inputPartnerLead.value == '') ) }">
      <p id="lblRelationshipOwner">TI Relationship Owner</p>
      <input id="partnerLead" type="text" class="form-control" [matAutocomplete]="partnerLead"
        [disabled]="showForm.get('partnerLead').disabled" (keyup)="onChangeUsers($event)" aria-label="TI Relationship Owner" aria-describedby="team_members_hint"/>
      <mat-progress-bar *ngIf="loadOwner" color="accent" mode="indeterminate"></mat-progress-bar>
      <mat-autocomplete id="team_members_auto" #partnerLead="matAutocomplete" role="combobox"
        (optionSelected)="onAddMultipleSelectValue('partnerLead', $event, 'eso_id')">
        <mat-option id="partnerLead_option{{i}}" *ngFor="let option of options.users;let i=index"
          [value]="option">{{option.display_name}}</mat-option>
      </mat-autocomplete>
      <span id="team_members_hint" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
      <mat-chip-set id="team_members_list">
        <mat-chip id="team_members_chip{{i}}" disableRipple="true" removable="true" selectable="false"
          *ngFor="let option of getMultipleSelectValues('partnerLead');let i=index"
          (removed)="onRemoveMultipleSelectValue('partnerLead', option, 'eso_id')">
          {{option.display_name}}
          <span id="team_members_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
        </mat-chip>
      </mat-chip-set>
    </div>
    <div class="form-group">
      <p id="titlePartnerSecondaryLead">Business Operations Contact</p>
      <input id="partnerSecondaryLead" type="text" formControlName="partnerSecondaryLead" placeholder="Search People"
        class="form-control" [matAutocomplete]="secondaryUsers" value="{{inputSecondaryUser}}"
        (keyup)="onChangeSecondaryUsers($event)" aria-label="Business Operations Contact" aria-describedby="partnerSecondaryLeadMessage"/>
      <mat-progress-bar *ngIf="loadSecondaryOwner" color="accent" mode="indeterminate"></mat-progress-bar>
      <mat-autocomplete id="partnerSecondaryLead_auto" #secondaryUsers="matAutocomplete" role="combobox"
        (optionSelected)="selectedSecondaryValue($event)" [displayWith]="getBusinessOperationsContactLead" >
        <mat-option id="partnerSecondaryLead_option{{i}}" *ngFor="let option of options.secondaryUsers;let i=index"
          [value]="option">{{option.display_name}}</mat-option>
      </mat-autocomplete>
      <span id="partnerSecondaryLeadMessage" class="hint"
        [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
    </div>
    <div class="form-group">
      <p id="enteredIn">Entered In</p>
      <mat-select id="eac_qrtr" formControlName="eac_qrtr" aria-label="Entered In">
        <mat-option [value]="showForm.get('eac_qrtr').value" disabled>FY{{this.showForm.get('eac_qrtr').value.year
          - 2000}} Q{{showForm.get('eac_qrtr').value.qtr}}
        </mat-option>
      </mat-select>
    </div>
    <mat-divider></mat-divider>
    <h2 class="dialog-title" aria-label="Partner Information">Partner Information</h2>
    <div class="form-group">
      <p id="lblCountry">Country</p>
      <mat-select id="country" placeholder="Select" formControlName="country" aria-label="Country"
        (selectionChange)="onSelectionChangeCountry($event)">
        <mat-option [value]="null"></mat-option>
        <mat-option id="optCountry{{i}}" *ngFor="let option of filterCountryOptionList();let i=index" [value]="option">
          {{option}}</mat-option>
      </mat-select>
    </div>
    <div class="form-group">
      <p id="lblMarketUnit">Market Unit</p>
      <mat-select id="marketUnit" placeholder="Select" formControlName="marketUnit" aria-label="Market Unit"
        (selectionChange)="onSelectionChangeMU($event)">
        <mat-option [value]="null"></mat-option>
        <mat-option id="optMarketUnit{{i}}" *ngFor="let option of filterMarketAndMUByCountry();let i=index"
          [value]="option">
          {{option}}</mat-option>
      </mat-select>
    </div>
    <div class="form-group">
      <p id="lblMarket">Market</p>
      <input id="market" type="text" class="form-control" formControlName="market" placeholder="Market"
        aria-label="market" />
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
    <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
    <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()"
      cdkFocusInitial>Save</button>
  </div>
</form>