<section fxFlex fxLayout="column" class="single-page">   
    <div fxLayout="column" class="single-page-box-wrap">
        <div fxLayout="row" class="single-page-header">
            <h1 id="h_login">Login</h1>
        </div>
        <div fxLayout="column" class="single-page-content"></div>
        <div fxLayout="column" class="single-page-bottom">
            <form fxLayout="column" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group" [ngClass]="{'error': submitted && loginForm.get('username').invalid }">
                    <label id="lblUser">User</label>
                    <mat-select id="drpDisplayName" formControlName="username">
                        <mat-option id="optDisplayName{{i}}" *ngFor="let option of users;let i=index" [value]="option">{{option.display_name}} ({{option.labs_role[0]}})</mat-option>
                    </mat-select>
                </div>
                <button id="login" mat-flat-button color="primary" type="submit">Log in</button>
            </form>
        </div>
    </div>
</section>