/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { Injectable } from '@angular/core';
import { OrganizationTypeService } from '../organization-type.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationTypeAPIService implements OrganizationTypeService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getOrganizationTypes(params = null): Observable<any> {
    this.commonService.log('GET:', '/organizationType', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/organizationType`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}