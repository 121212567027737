<mat-progress-bar *ngIf="loadVal" color="accent" mode="indeterminate"></mat-progress-bar>
<form #validForm fxLayout="column" [formGroup]="showForm" class="add-dialog" role="dialog" aria-labelledby="titleMetricName">
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && showForm.get('metricName').invalid }">
            <p id="titleMetricName">Metric Name</p>
            <input id="metricName" type="text" formControlName="metricName" class="form-control" placeholder="Metric Name" aria-label="Metric name" required  maxlength="128"  (keyup)="changeMetricValue(showForm.get('metricName').value)"/>
            <span id="metricNameLength" class="hint point">{{inputMetricName ? inputMetricName.length : 0}}/128</span>  
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnSave" mat-flat-button type="submit" color="primary" (click)="addNewData()" cdkFocusInitial>Save</button>
    </div>
</form>