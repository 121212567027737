<div fxFlex fxLayout="column" *ngIf="story" class="view-story" tabindex="0" (keydown.enter)="onBack()">
  <div fxLayout="row wrap" fxLayoutGap="30px" class="pri-btn-view-wrap">
    <button id="back" mat-button color="primary" (click)="onBack()">BACK</button>
    <div fxFlex></div>
    <button id="downloadppt" mat-stroked-button [disabled]="hasImageLoading()" color="primary"
      [matMenuTriggerFor]="setingMenu">Download .ppt Slide</button>
    <mat-menu #setingMenu="matMenu" class="live-menu sort-menu">
      <button id="btn_withconfidential" mat-menu-item (click)="downloadPPT()">
        Download with confidential level
      </button>
      <button id="btn_withoutconfidential" mat-menu-item (click)="downloadPPT(true)">
        Download without confidential level
      </button>
    </mat-menu>
    <button id="edit" *ngIf="story.canEdit" [routerLink]="['/stories', story._id]" mat-button
      color="primary">Edit</button>
    <button id="viewComments" *ngIf="comments.length" mat-stroked-button color="primary" (click)="onViewComments()">View
      Comments</button>
    <button id="rework" *ngIf="story.canPublish" mat-stroked-button color="primary"
      (click)="onRework()">Reassign</button>
    <button id="requestPublish" *ngIf="story.canRequestPublish" mat-stroked-button color="primary"
      (click)="onRequestPublish()">Request Publish</button>
    <button id="approvePublish" *ngIf="story.canPublish" mat-stroked-button color="primary"
      (click)="onApprovePublish()">Approve Publish</button>
    <button id="recommend" mat-stroked-button color="primary"
      (click)="services.storyCommonService.sendRecommend(story)">Recommend</button>
  </div>
  <div fxLayout="column" class="workflow_status_wrap">
    <div fxLayout="row" class="workflow_status" *ngIf="story.workflow_status == WorkflowStatus.PUBLISH_APPROVED">
      <span id="workflowStatus">Publish Approved</span>
      <span id="workflowFromUser" *ngIf="story.workflow && story.workflow.from_user">by
        {{story.publish_approve_workflow.from_user.display_name}} on {{story.publish_approve_workflow.updated |
        date}}</span>
    </div>
    <div fxLayout="row" class="workflow_status" *ngIf="story.workflow_status == WorkflowStatus.SAVED">
      <span id="workflowStatus">Saved</span>
      <span id="workflowLastUpdatedBy" *ngIf="story.last_updated_by">by
        {{story.last_updated_by.display_name}} on {{story.last_updated_date | date}}</span>
    </div>
    <div fxLayout="row" class="workflow_status"
      *ngIf="story.workflow_status == WorkflowStatus.PUBLISH_APPROVED && isEdited()">
      <span id="edited">Edited</span>
      <span id="editedBy">by {{story.last_updated_by.display_name}} on {{story.last_updated_date |
        date}}</span>
    </div>
    <div fxLayout="row" class="publish_requested_status"
      *ngIf="story.workflow_status == WorkflowStatus.PUBLISH_REQUESTED">
      <span id="publishApprover" class="b_field">Publish Approver</span>
      <span id="publishApproverUser" *ngIf="story.primary_location">
        {{publishApprovers}} on {{story.workflow.updated | date}}</span>
    </div>
    <div fxLayout="row" class="publish_requested_status"
      *ngIf="story.workflow_status == WorkflowStatus.PUBLISH_REQUESTED">
      <span id="publishRequester" class="b_field">Publish Requester</span>
      <span id="publishRequestedUser" *ngIf="story.workflow && story.workflow.from_user">
        {{story.workflow.from_user.display_name}} on {{story.workflow.updated | date}}</span>
    </div>
  </div>
  <div class="black-box">
    <div *ngIf="!hideForceNestedMarket" class="black_line"
      [ngClass]="{'black_line_group':story.organization_type.name != 'Liquid Studio'}">
      <div class="org_line">
        <div class="black_part">&nbsp;</div>
        <div class="font_color">
          <div class="un_select_org"></div>
          <div class="un_select_org"></div>
          <div class="un_select_org" >
            <span *ngIf="story.organization_type.name == 'Labs'"></span>
          </div>
          <div class="un_select_org">
            <span *ngIf="story.organization_type.name == 'Incubation'"></span>
          </div>
          <div class="un_select_org">
            <span *ngIf="story.organization_type.name == 'Liquid Studio'"></span>
          </div>
        </div>
      </div>
    </div>
    <div fxLayoutGap="8px" style="max-width: 71%;display: flex;flex-wrap: wrap;"
      *ngIf=" !isForce && !hideForceNestedMarket">
      <div *ngIf=" !hideForceNestedMarket" class="combinationtitle">
        {{story.force?story.force.name:''}}</div>
      <!-- <div *ngIf="!hideForceNestedMarket && !isNested" class="vertical-bar-purple"></div>
      <div *ngIf="!hideForceNestedMarket && !isNested" class="combinationtitle">
        {{story.nested_themes?.name}}</div> -->
      <div *ngIf="story.organization_type.name&&story.force " class="vertical-bar-black"></div>
      <div class="combinationorg">{{story.organization_type.name}}</div>
      <div *ngIf="story.markets && story.organization_type " class="vertical-bar-black"></div>
      <div *ngIf="story.markets " class="combinationorg">{{story.markets}}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="8px" *ngIf="!hideForceNestedMarket && isForce ">
      <div class="combinationorg">{{story.organization_type.name}}</div>
      <div *ngIf="story.markets&&story.organization_type " class="vertical-bar-black"></div>
      <div class="combinationorg">{{story.markets}}</div>
    </div>
    <div fxLayout="row" fxLayoutGap="8px" *ngIf="hideForceNestedMarket">
      <div class="combinationorg">{{story.organization_type?story.organization_type.name:''}}</div>
      <div *ngIf="story.primary_group&&story.organization_type" class="vertical-bar-black"></div>
      <div *ngIf="story.primary_group" class="combinationtitle">{{story.primary_group.name}}</div>
      <div *ngIf="story.primary_location&&(story.organization_type||story.primary_group)" class="vertical-bar-purple"></div>
      <div *ngIf="story.primary_location" class="combinationtitle">{{story.primary_location.name}}</div>
    </div>
    <h2 id="storyName" [ngClass]="{'white-space-pre': true,'h30':!isExceedsTitle,'h38':isExceedsTitle}">{{story.title}}
    </h2>
    <h3 id="storyShortDesc" [ngClass]="{'h18':!isExceedsSubTitle,'h20':isExceedsSubTitle}">{{story.subtitle}}</h3>
    <div fxLayout="row" class="value-group story-type">
      <div id="storyType" class="label">
        {{story.story_type?story.story_type.name:''}} {{story.idf_number? " - " +
        story.idf_number:''}}
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="value-group">
      <div fxLayout="column" class="text-context">
        <div fxLayout="row">
          <div fxLayout="column" class="context-col">
            <div fxLayout="row">
              <div id="storycontext" class="title">Context:</div>
            </div>
            <div id="storyStatus_value" fxFlex *ngIf="!isContextHtml">
              <p id="deliverableContext" fxFlex [ngClass]="{'value14':!isExceedsContext,'value15':isExceedsContext}"
                [innerHTML]="story.context | bypassSecurityTrustHtml"></p>
            </div>
            <div id="storyStatus_value" fxFlex class="value" *ngIf="isContextHtml">
              <p id="deliverableContext" fxFlex [innerHTML]="story.context | bypassSecurityTrustHtml"></p>
            </div>
          </div>
          <div fxLayout="column" class="context-col">
            <div fxLayout="row">
              <div id="storysolution" class="title">Solution:</div>
            </div>
            <div id="storyStatus_value" fxFlex *ngIf="!isSolutionHtml">
              <p id="deliverableSloution" fxFlex [ngClass]="{'value14':!isExceedsSolution,'value15':isExceedsSolution}"
                [innerHTML]="story.solution | bypassSecurityTrustHtml"></p>
            </div>
            <div id="storyStatus_value" fxFlex class="value" *ngIf="isSolutionHtml">
              <p id="deliverableSloution" fxFlex [innerHTML]="story.solution | bypassSecurityTrustHtml"></p>
            </div>
          </div>
          <div fxLayout="column" class="context-col">
            <div fxLayout="row">
              <div id="storyimpact" class="title">Impact:</div>
            </div>
            <div id="storyStatus_value" fxFlex *ngIf="!isImpactHtml">
              <p id="deliverableImpact" fxFlex [ngClass]="{'value14':!isExceedsImpact,'value15':isExceedsImpact}"
                [innerHTML]="story.impact | bypassSecurityTrustHtml"></p>
            </div>
            <div id="storyStatus_value" fxFlex class="value" *ngIf="isImpactHtml">
              <p id="deliverableImpact" fxFlex [innerHTML]="story.impact | bypassSecurityTrustHtml"></p>
            </div>
          </div>
        </div>
      </div>
      <div fxFlex="25" fxLayout="column" class="text-right ">
        <div id="imagePreview" *ngFor="let item of options.pictures;let i=index" class="story_image_preview">
          <div id="storycontext" class="picture-title">
            {{item.image_description}}</div>
          <div class="story_image_preview">
            <div [style.background-image]="'url(' + getDataStoryImage(item, 'story')  + ')'"
              class="story_image_preview_inner">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="value-bottom">
      <div fxLayout="column" class="value-group">
        <div fxLayout="row">
          <hr class="text-line">
        </div>
        <div fxLayout="row" class="bottom">
          <div fxLayout="row" id="iconArea">
            <div *ngFor="let item of iconsArr;let i=index">
              <img id="deliconsImage{{i}}" alt="" [src]="item.data" [width]="item.width" [height]="item.height"
                class="text-icon">
            </div>
          </div>
          <div fxLayout="column">
            <div>
              <div id="storycontext" class="title-contact">Contacts:</div>
            </div>
            <div>
              <div *ngFor="let user of story.contacts;let i=index">
                <div fxFlex fxLayout="row" class="user-name-wrap" fxLayoutAlign="end">
                  <a href="mailto:{{user.email_id}}?subject=Email Subject&body=Click"
                    class="title title-email">{{user.email_id}}<br /></a>
                </div>
              </div>
            </div>
            <div fxFlex fxLayout="row" class="user-name-wrap"  fxLayoutAlign="end">
              <div class="title title-email hashtag">{{story.hashtag}}</div>
            </div>
          </div>
        </div>
      </div>
      <footer class="text-footer">
        <img src="assets/images/footer.png" alt="" class="text-icon" />
        <p class="text-copy">Copyright &#64;
          2023 Accenture. All rights reserved.</p>
      </footer>
    </div>

  </div>
  <div fxLayout="column" class="owner_wrap">
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="34" fxLayout="column">
        <h4 id="storyAttributes">STORY ATTRIBUTES</h4>
        <div fxLayout="row" class="value-group">
          <div id="t_org" class="label">Organization</div>
          <div id="org_value" fxFlex class="value">
            {{story.organization_type?story.organization_type.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_owner" class="label">Owner</div>
          <div id="owner_value" fxFlex class="value">
            {{story.owner?story.owner.display_name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="!isLiquidStudio">
          <div id="t_primaryGroup" class="label">Primary Group</div>
          <div id="primaryGroup_value" fxFlex class="value">
            {{story.primary_group?story.primary_group.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="!isLiquidStudio">
          <div id="t_otherGroups" class="label">Other Groups</div>
          <div id="otherGroups_value" fxFlex class="value"><span
              *ngFor="let option of story.other_groups; last as isLast;let i=index">{{option.name}}<span
                *ngIf="!isLast">,
              </span></span></div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="isLabs">
          <div id="t_workstream" class="label">Primary Workstream</div>
          <div id="workstream_value" fxFlex class="value">
            {{story.primary_workstream?story.primary_workstream.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group" *ngIf="isLabs">
          <div id="t_otherWorkstreams" class="label">Other Workstreams</div>
          <div id="otherWorkstreams_value" fxFlex class="value"><span
              *ngFor="let option of story.other_workstreams; last as isLast;let i=index">{{option.name}}<span
                *ngIf="!isLast">, </span></span></div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_primaryLocation" class="label">Primary Location</div>
          <div id="primaryLocation_value" fxFlex class="value">
            {{story.primary_location?story.primary_location.name:'n/a'}}</div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_otherLocations" class="label">Other Locations</div>
          <div id="otherLocations_value" fxFlex class="value"><span
              *ngFor="let option of story.other_locations; last as isLast;let i=index">{{option.name}}<span
                *ngIf="!isLast">, </span></span></div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_lastPublished" class="label">Last Published</div>
          <div fxFlex class="value">
            <span id="lastPublished_hasValue"
              *ngIf="story.last_showcase_published_date">{{story.last_showcase_published_date | date
              }}</span>
            <span id="lastPublished_nullValue" *ngIf="!story.last_showcase_published_date">n/a</span>
          </div>
        </div>
        <div fxLayout="row" class="value-group">
          <div id="t_lastEdited" class="label">Last Edited</div>
          <div fxFlex class="value">
            <span id="lastEdited_hasValue" *ngIf="story.last_updated_date">{{story.last_updated_date | date
              }}</span>
            <span id="lastEdited_nullValue" *ngIf="!story.last_updated_date">n/a</span>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxLayout="column">
        <h4 id="teamMembers">ADDITIONAL TEAM MEMBERS</h4>
        <div fxLayout="row" *ngFor="let user of story.team_members;let i=index" class="user-wrap">
          <img alt="user profile" id="userImage{{i}}" [src]="getProfileImage(user.eso_id)" class="user-profile" />
          <div fxFlex fxLayout="column" class="user-name-wrap">
            <div id="userDisplayName{{i}}">{{user.display_name}}</div>
            <div id="userJobTitle{{i}}">{{user.job_title }}</div>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxLayout="column">
        <h4 id="contacts">CONTACTS</h4>
        <div fxLayout="row" *ngFor="let user of story.contacts;let i=index" class="user-wrap">
          <img alt="user profile" id="userImage{{i}}" [src]="getProfileImage(user.eso_id)" class="user-profile" />
          <div fxFlex fxLayout="column" class="user-name-wrap">
            <div id="userDisplayName{{i}}">{{user.display_name}}</div>
            <div id="userJobTitle{{i}}">{{user.job_title }}</div>
          </div>
        </div>
        <h4 id="keywords">KEYWORDS</h4>
        <mat-chip-set id="keywords_list">
          <mat-chip id="keywords_chip{{i}}" disableRipple="true" *ngFor="let option of story.keywords;let i=index">
            {{option}}
          </mat-chip>
        </mat-chip-set>
        <h4 id="partner" *ngIf="!hidePartners">PARTNERS</h4>
        <mat-chip-set id="partner_list" *ngIf="!hidePartners">
          <mat-chip id="partner_chip{{i}}" disableRipple="true" *ngFor="let option of story.partners;let i=index">
            {{option.name}}
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="67" fxLayout="column">
        <div fxFlex fxLayout="row" class="value-group" *ngIf="story.asset_link">
          <div id="publishedLink" class="label published-link">Published Link</div>
          <div id="storyAssetLink" fxFlex class="value">
            <a [href]="services.commonService.addHttp(story.asset_link)" target="_blank" class="link">{{linkName}}</a>
          </div>
        </div>
      </div>
      <div fxFlex="33" fxLayout="column">
      </div>
    </div>
  </div>
  <div fxLayout="row" fxLayoutGap="30px" class="project_wrap">
    <div fxFlex="33" fxLayout="column">

      <div fxLayout="row" class="value-group">
        <div id="endQTR" class="label">FiscalYear Quarter</div>
        <div id="endQTR_value" fxFlex class="value">
          <span *ngIf="story.eac_qrtr">FY{{story.eac_qrtr.year-2000}}
            Q{{story.eac_qrtr.qtr}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="related-deliverables-wrap" *ngIf="story.related_deliverables && story.related_deliverables.length">
    <table aria-label="RELATED DELIVERABLES">
      <thead>
        <tr>
          <th id="th_relatedDeliverables">Related Deliverables</th>
          <th id="th_owner">Owner</th>
          <th id="th_primaryGroup">Primary Group</th>
          <th id="th_primaryLocation">Primary Location</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let relatedDeliverable of story.related_deliverables;let i=index">
          <td id="td_relatedDeliverables{{i}}">
            <div fxLayout="row" fxLayoutGap="15px">
              <img id="deliverableImage{{i}}" [src]="getDeliverableImage(relatedDeliverable)" alt=""
                class="deliverable-image">
              <div fxLayout="column">
                <h6 id="deliverableName{{i}}">{{relatedDeliverable.name}}</h6>
                <p id="deliverableShortDesc{{i}}">{{relatedDeliverable.short_description}}</p>
              </div>
            </div>
          </td>
          <td id="td_displayName{{i}}">{{relatedDeliverable.owner?relatedDeliverable.owner.display_name:'n/a'}}</td>
          <td id="td_primaryGroup{{i}}">
            {{relatedDeliverable.primary_group?relatedDeliverable.primary_group.name:'n/a'}}</td>
          <td id="td_primaryLocation{{i}}">
            {{relatedDeliverable.primary_location?relatedDeliverable.primary_location.name:'n/a'}}</td>
        </tr>
      </tbody>
    </table>

  </div>
</div>