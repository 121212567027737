/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, ViewChild, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { LocationsService } from '@app/services/location.service';
import { Services } from '@app/services/services';
import { User, Messages } from '@app/models';
import { OrgType } from '@app/models/organizationType';
import { PartnerService } from '@app/services/partner.service';
import { CommonService } from '@app/services';
@Component({
  selector: 'app-quick-edit',
  templateUrl: './quick-edit.component.html',
  styleUrls: ['./quick-edit.component.scss']
})
export class QuickEditComponent implements OnInit {
  loading: any = 0;
  loadOwner: any = 0;
  isNew: any = true;
  deliverableForm: UntypedFormGroup;
  submitted: any = false;
  deliverableId: any = null;
  deliverable: any = null;
  ownerChanged: Subject<string> = new Subject<string>();
  groupOrder: any = null;
  options: any = {
    deliverable_type: [],
    initiatives: [],
    deliverable_status: [],
    eac_qrtr: [],
    owner: [],
    groups: [],
    partners: []
  }
  loginUser: User = null;
  locationList: any;
  showSaveBtn: any = true;
  deliverableName: any = '';
  shortDesc: any = '';
  addNewTitle: any = '';
  editTitle: any = '';
  orgTypeName: any = '';
  orgType: any = {};
  currentDeliverableStatus: any = '';
  previousDeliverableStatus: any = '';
  currentDeliverableType: any = '';
  previousDeliverableType: any = '';
  isLiquidStudio: any = false;
  hidePartners;
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<QuickEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public locationsService: LocationsService,
    public services: Services,
    private commonService: CommonService,
    private partnerService: PartnerService
  ) {
    this.loginUser = this.services.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.services.deliverableEditCommonService.getInitiatives(this);
    this.services.deliverableEditCommonService.getQuarters(this);
    this.services.deliverableEditCommonService.getDeliverableStatus(this);
    this.services.commonService.showHeader.emit(true);
    this.isNew = true;
    this.deliverableId = null;
    this.submitted = false;
    this.deliverable = null;
    this.deliverableForm = null;
    this.loading++;
    this.services.masterService.getQuarters()
      .subscribe((response) => {
        this.loading--;
        if (response) {
          this.options.eac_qrtr = response.end_quarter;
          this.options.fiscal_year_default_value = response.fiscal_year_default_value;
          if (this.data) {
            this.isNew = false;
            this.deliverableId = this.data._id;
            this.loading++;
            this.services.deliverableService.getDeliverable(this.deliverableId)
              .subscribe((response) => {
                this.loading--;
                if (response && response.result) {
                  this.deliverable = response.result;
                  this.deliverable = { ...response.result, ...response.metadata };
                  this.currentDeliverableType = this.deliverable.deliverable_type.name;
                  this.currentDeliverableStatus = this.deliverable.deliverable_status;
                  this.orgTypeName = this.deliverable.organization_type.name;
                  this.getLocations(this.orgTypeName);
                  this.services.deliverableEditCommonService.getDeliverableTypes(this);
                  this.editTitle = 'Edit ' + this.orgTypeName;
                  this.services.deliverableEditCommonService.getGroups(this);
                  this.deliverableName = this.deliverable.name;
                  this.shortDesc = this.deliverable.short_description;
                  this.getPartners();
                  if (!this.deliverable.canEdit && (!this.deliverable.canEditInitiative || this.orgTypeName == OrgType.LIQUID)) {
                    this.services.commonService.openSnackBar('warning', Messages.EDIT_ERROR_MESSAGE, 5000);
                  }
                  if (this.orgTypeName == OrgType.LABS) {
                    this.services.deliverableEditCommonService.createForm(this, this.deliverable);
                  } else if (this.orgTypeName == OrgType.LIQUID) {
                    this.services.deliverableEditCommonService.createLiquidForm(this, this.deliverable);
                    this.isLiquidStudio = true;
                  } else if (this.orgTypeName == OrgType.INCUBATION  || this.orgTypeName == OrgType.OPENINNOVATION) {
                    this.services.deliverableEditCommonService.createIncubationForm(this, this.deliverable);
                  }else if (this.orgTypeName == OrgType.METAVERSE) {
                    this.services.deliverableEditCommonService.createMetaverseForm(this, this.deliverable);
                  }
                }
              });
          } else {
            this.orgType = this.services.commonService.getSessionData('GET_QUICK_ORG');
            this.orgTypeName = this.orgType.name;
            this.getLocations(this.orgTypeName);
            this.services.deliverableEditCommonService.getDeliverableTypes(this);
            this.addNewTitle = 'Add ' + this.orgTypeName;
            this.services.deliverableEditCommonService.getGroups(this);
            this.services.commonService.removeSessionData('deliverable-list-filter');
            this.getPartners();
            if (this.orgTypeName == OrgType.LABS) {
              this.services.deliverableEditCommonService.createForm(this, this.deliverable);
            } else if (this.orgTypeName == OrgType.LIQUID) {
              this.services.deliverableEditCommonService.createLiquidForm(this, this.deliverable);
              this.isLiquidStudio = true;
            } else if (this.orgTypeName == OrgType.INCUBATION  || this.orgTypeName == OrgType.OPENINNOVATION) {
              this.services.deliverableEditCommonService.createIncubationForm(this, this.deliverable);
            } else if (this.orgTypeName == OrgType.METAVERSE) {
              this.services.deliverableEditCommonService.createMetaverseForm(this, this.deliverable);
            }
          }
        }
      });

    this.services.commonService.parnterNeedToRefresh.subscribe((isNeedToRefresh) => {
      if (isNeedToRefresh) {
        this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
      }
    });
    this.hidePartners = this.services.commonService.globalFlags['hidePartners'];
    this.ownerChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        this.services.deliverableEditCommonService.ownerChanged(this, filterValue);
      });
  }

  getPartners(): void {
    this.loading++;
    const params = { active: true };
    this.partnerService.getPartners(params).subscribe((response) => {
      if (response && response.result) {
        this.loading--;
        this.options.partners = response.result;
      }
      if (this.deliverableForm) {
        this.services.commonService.checkPartnersOption(this, this.deliverableForm);
      }
    }, (error1) => {
      this.loading--;
      this.commonService.handleError(error1);
    });
  }

  getLocations(field): void {
    this.loading++;
    const params = { active: 'true', orgTypeName: field };
    this.locationsService.getLocations(params).subscribe((response) => {
      if (response && response.result) {
        this.loading--;
        this.locationList = response.result;
      }
    });
    return this.locationList;
  }


  get Messages(): any { return Messages; }

  _afterCreateForm() {
    this.services.deliverableEditCommonService.afterCreateForm(this);
    if (this.isNew && this.options.fiscal_year_default_value) {
      this.deliverableForm.get('eac_qrtr').setValue(this.options.fiscal_year_default_value.qtr, { emitEvent: false });
      this.deliverableForm.get('eac_qrtr_year').setValue(this.options.fiscal_year_default_value.year, { emitEvent: false });
    }
    this.deliverableForm.get('name').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.deliverableName = this.deliverableForm.get('name').value;
      });

    this.deliverableForm.get('short_description').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.shortDesc = this.deliverableForm.get('short_description').value;
      });
    this.deliverableForm.get('partners').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        this.services.commonService.checkPartnersOption(this, this.deliverableForm);
      });
  }

  filterSelectPartners(): any {
    const deliverable_type = this.deliverableForm.get('deliverable_type').value?.name;
    const partnerValue = this.deliverableForm.get('partners').value;
    const partnerList = [];
    if (deliverable_type == 'University Relationships') {
      partnerValue.forEach((item) => {
        if (item?.partnerType == 'University') {
          partnerList.push(item);
        }
      });
      this.deliverableForm.get('partners').setValue(partnerList);
    } else if (deliverable_type == 'Vendor Relationships') {
      partnerValue.forEach((item) => {
        if (item?.partnerType == 'Vendor') {
          partnerList.push(item);
        }
      });
      this.deliverableForm.get('partners').setValue(partnerList);
    }
    else {
      this.deliverableForm.get('partners').setValue(partnerValue);
    }
    return this.deliverableForm.get('partners').value;
  }

  _enableControls() {
    if (this.options['groups'].length || this.orgTypeName == 'Liquid Studio') {
      this.services.deliverableEditCommonService.primaryOptionCheck(this);
      this.services.deliverableEditCommonService.enableControls(this);
    }
  }


  onChangeOwner(event): void {
    this.services.deliverableEditCommonService.onChangeOwner(this, event);
  }

  getGroupMaster(group) {
    return this.services.deliverableEditCommonService.getGroupMaster(this, group);
  }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    return this.services.deliverableEditCommonService.getMultipleSelectOptions(this, field, key, onlyTypeHead, withoutFilter);
  }

  getMultipleSelectValues(field: string): any {
    return this.deliverableForm.get(field).value;
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    this.services.deliverableEditCommonService.onAddMultipleSelectValue(this, field, event, allowEnter, key);
  }

  onAddSelectValue(field: string, event: MatAutocompleteSelectedEvent): void {
    this.services.deliverableEditCommonService.onAddSelectValue(this, field, event);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.deliverableEditCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }

  getQuartsOptions(field: string): any[] {
    return this.services.deliverableEditCommonService.getQuartsOptions(this, field);
  }

  canInitiativeAccess(initiative) {
    return this.services.deliverableEditCommonService.canInitiativeAccess(this, initiative);
  }

  isValueNotFoundInOptions(field: string, key: string): boolean {
    return this.services.deliverableEditCommonService.isValueNotFoundInOptions(this, field, key);
  }

  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }

  onAddUrl(): any {
    this.services.deliverableEditCommonService.onAddUrl(this);
  }

  onRemoveUrl(value: any): void {
    this.services.deliverableEditCommonService.onRemoveUrl(this, value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  _isValid(): boolean {
    if (this.deliverableForm.valid) {
      // if (!this.hidePartners && ((this.deliverableForm.get('deliverable_type').value?.name == 'Vendor Relationships' ||
      // this.deliverableForm.get('deliverable_type').value?.name == 'University Relationships' ||
      // this.deliverableForm.get('deliverable_type').value?.name == 'Client Pilots / Joint Innovation Projects'
      // ) &&
      // !this.deliverableForm.get('partners').value?.length)
      // ) {
      //   this.invalidFocus();
      //   return false;
      // }
      return true;
    }
    this.invalidFocus();
    return false;
  }


  invalidFocus() {
    this.services.deliverableEditCommonService.invalidFocus(this);
  }

  _formReset() {
    this.services.deliverableEditCommonService.formReset(this);
  }

  _getFromData() {
    return this.services.deliverableEditCommonService.getFromData(this);
  }

  onSave(): void {
    this.submitted = true;
    if (this._isValid() && !this.loading) {
      this.services.deliverableEditCommonService.setDefaultStatus(this);
      this.loading++;
      if (this.isNew) {
        this.services.deliverableService.save(this.services.commonService.removeNullObjects(this._getFromData()), null, this.orgTypeName)
          .subscribe((response) => {
            this.services.deliverableEditCommonService.afterSave(this, response, true, false, true);
            this.services.deliverableCommonService.isNeedToRefresh.next(true);
          });
      } else {
        this.services.deliverableService.update(this.deliverableId, this.services.commonService.removeNullObjects(this._getFromData()), null, this.orgTypeName)
          .subscribe((response) => {
            this.services.deliverableEditCommonService.afterSave(this, response, false, false, true);
            this.services.deliverableCommonService.isNeedToRefresh.next(true);
          });
      }
    }
  }
}
