<div fxFlex fxLayout="column" class="edit-story" *ngIf="storyForm && dataProcessing">
  <h2 id="isNew">{{isNew ? createStoryTitle : editStoryTitle}}</h2>
  <p id="indicatesRequiredField"><span>*</span> indicates required field</p>
  <form #validForm fxLayout="column" [formGroup]="storyForm">
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50">
        <div class="form-group" [ngClass]="{'error': (draftSubmitted || submitted) && storyForm.get('title').invalid }">
          <label id="lblTitle">Title<span>*</span></label>
          <input id="title" type="text" class="form-control" formControlName="title" aria-label="Title"
            maxlength="80" />
          <p id="titleLength" class="hintMessage">{{storyTitle ? storyTitle.length :0}}/80</p>

        </div>
      </div>
      <div fxFlex="50">
        <div class="form-group" [ngClass]="{'error': submitted && storyForm.get('subtitle').invalid }">
          <label id="lblsubTitle">Subtitle<span>*</span></label>
          <input id="subTitle" type="text" class="form-control" formControlName="subtitle" aria-label="Subtitle"
            maxlength="250" />
          <p id="subTitleLength" class="hintMessage">{{storySubTitle ? storySubTitle.length :0}}/250</p>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50" class="form-group"
        [ngClass]="{'error': (draftSubmitted || submitted) && storyForm.get('owner').invalid }">
        <label id="lblOwner" [matTooltip]="Messages.OWNER">Owner<span>* </span>
          <span class="info-icon"></span></label>
        <input id="owner" type="text" class="form-control" [matAutocomplete]="owner"
          [disabled]="storyForm.get('owner').disabled"
          [value]="storyForm.get('owner').value?storyForm.get('owner').value.display_name:''"
          (keyup)="onChangeOwner($event)" aria-label="owner" aria-describedby="owner_text_hint" />
        <mat-progress-bar *ngIf="loadOwner" color="accent" mode="indeterminate"></mat-progress-bar>
        <mat-autocomplete id="owner_auto" #owner="matAutocomplete" role="combobox" (optionSelected)="onAddSelectValue('owner', $event)"> 
          <mat-option id="owner_option{{i}}"
            *ngFor="let option of getMultipleSelectOptions('owner','email_id', true);let i=index" [value]="option">
            {{option.display_name}}</mat-option>
        </mat-autocomplete>
        <span id="owner_text_hint" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
      </div>
      <div fxFlex="50" class="form-group">
        <label id="lblTeamMembers" [matTooltip]="Messages.ADDITIONAL_TEAM_MEMBERS">ADDITIONAL TEAM MEMBERS
          <span class="info-icon"></span>
        </label>
        <input id="team_members" type="text" class="form-control" [matAutocomplete]="team_members"
          [disabled]="storyForm.get('team_members').disabled" (keyup)="onChangeTeamMembers($event)"
          aria-label=" Additional teamMembers" aria-describedby="team_members_hint" />
        <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
        <mat-autocomplete id="team_members_auto" #team_members="matAutocomplete" role="combobox"
          (optionSelected)="onAddMultipleSelectValue('team_members', $event, false, 'eso_id')">
          <mat-option id="team_members_option{{i}}"
            *ngFor="let option of getMultipleSelectOptions('team_members','email_id', true);let i=index"
            [value]="option">{{option.display_name}}</mat-option>
        </mat-autocomplete>
        <span id="team_members_hint" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
        <mat-chip-set id="team_members_list">
          <mat-chip id="team_members_chip{{i}}" disableRipple="true" removable="true" selectable="false"
            *ngFor="let option of getMultipleSelectValues('team_members');let i=index"
            (removed)="onRemoveMultipleSelectValue('team_members', option, 'eso_id')">
            {{option.display_name}}
            <span id="team_members_delete{{i}}" matChipRemove *ngIf="!storyForm.get('team_members').disabled">
              <i class="ic-close"></i></span>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxFlex="50" class="form-group"
        [ngClass]="{'error': submitted && storyForm.get('confidentiality_level').invalid }">
        <label id="lbConfidentiality">CONFIDENTIALITY LEVEL<span>*</span></label>
        <mat-select id="drpConfidentiality" class="ng-invalid" [value]="confidentiality_level" aria-label="confidentiality Level">
          <mat-option (click)="getValueOfConfident('')" [value]="null"></mat-option>
          <mat-option id="optConfidentiality{{i}}" *ngFor="let option of displayConfidentialityLevel;let i=index"
            (click)="getValueOfConfident(option.name)" [value]="option.name">
            {{option.name}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="50" class="form-group" [ngClass]="{'error': submitted && !storyForm.get('keywords').value?.length }">
        <label id="lblKeywords">Keywords<span>*</span></label>
        <input id="keywords" type="text" class="form-control" [matAutocomplete]="keywords"
          [disabled]="storyForm.get('keywords').disabled" (keyup)="onChangeKeyWord($event)"
          (keyup.enter)="onAddMultipleSelectValue('keywords', $event, true)"
          [ngClass]="{'incomplete': incomplete && !storyForm.get('keywords').value.length}" aria-label="keywords"
          maxlength="300" aria-describedby="keywords_text_hint"/>
        <mat-progress-bar *ngIf="loadKeywords" color="accent" mode="indeterminate"></mat-progress-bar>
        <mat-autocomplete id="keywords_auto" #keywords="matAutocomplete" role="combobox"
          (optionSelected)="onAddMultipleSelectValue('keywords', $event, true)">
          <mat-option id="keywords_option{{i}}"
            *ngFor="let option of getMultipleSelectOptions('keywords', null, true);let i=index" [value]="option">
            {{option}}</mat-option>
        </mat-autocomplete>
        <span id="keywords_text_hint" *ngIf="!getMultipleSelectValues('keywords')?.length" class="hint"
          [matTooltip]="Messages.KEYWORD_HINT">{{Messages.KEYWORD_HINT}}</span>
        <mat-chip-set id="keywords_list">
          <mat-chip id="keywords_chip{{i}}" disableRipple="true" removable="true" selectable="false"
            *ngFor="let option of getMultipleSelectValues('keywords');let i=index"
            (removed)="onRemoveMultipleSelectValue('keywords', option, null)">
            {{option}}
            <span id="keywords_delete{{i}}" matChipRemove *ngIf="!storyForm.get('keywords').disabled">
              <i class="ic-close"></i></span>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName !== 'Liquid Studio'">
      <div fxFlex="50" class="form-group" [ngClass]="{'error': submitted && storyForm.get('primary_group').invalid }">
        <label id="lblGroup">Primary Group<span>*</span></label>
        <mat-select id="drpGroup" formControlName="primary_group" [compareWith]="onCompareObjects" aria-label="PrimaryGroup">
          <mat-option [value]="null"></mat-option>
          <mat-option id="optGroup{{i}}" *ngFor="let option of getMultipleSelectOptions('primary_group');let i=index"
            [value]="option">
            {{option.name}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="50" class="form-group">
        <label id="lblOtherGroups">Other Groups</label>
        <input id="other_groups" type="text" class="form-control" [matAutocomplete]="other_groups"
          [disabled]="storyForm.get('other_groups').disabled" aria-label="otherGroups" />
        <mat-autocomplete id="other_groups_auto" #other_groups="matAutocomplete"
          (optionSelected)="onAddMultipleSelectValue('other_groups', $event)">
          <mat-option id="other_groups_option{{i}}"
            *ngFor="let option of getMultipleSelectOptions('other_groups');let i=index" [value]="option">
            {{option.name}}</mat-option>
        </mat-autocomplete>
        <mat-chip-set id="other_groups_list">
          <mat-chip id="other_groups_chip{{i}}" disableRipple="true" removable="true" selectable="false"
            *ngFor="let option of getMultipleSelectValues('other_groups');let i=index"
            (removed)="onRemoveMultipleSelectValue('other_groups', option)">
            {{option.name}}
            <span id="other_groups_delete{{i}}" matChipRemove *ngIf="!storyForm.get('other_groups').disabled">
              <i class="ic-close"></i></span>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <mat-divider *ngIf="orgTypeName !== 'Liquid Studio'"></mat-divider>
    <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName == 'Labs'">
      <div fxFlex="50" class="form-group"
        [ngClass]="{'error': submitted && storyForm.get('primary_workstream').invalid }">
        <label id="lblPrimaryWorkstream">Primary Workstream<span>*</span></label>
        <mat-select id="drpPrimaryWorkstream" formControlName="primary_workstream" [compareWith]="onCompareObjects" aria-label="Primaryworkstream">
          <mat-option [value]="null"></mat-option>
          <mat-option id="optPrimaryWorkstream{{i}}"
            *ngFor="let option of getMultipleSelectOptions('primary_workstream'); let i=index" [value]="option">
            {{option.name}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="50" class="form-group">
        <label id="lblOtherWorkstream">Other Workstreams</label>
        <input id="other_workstreams" type="text" class="form-control" [matAutocomplete]="other_workstreams"
          [disabled]="storyForm.get('other_workstreams').disabled" aria-label="otherWorkstreams" />
        <mat-autocomplete id="other_workstreams_auto" #other_workstreams="matAutocomplete"
          (optionSelected)="onAddMultipleSelectValue('other_workstreams', $event)">
          <mat-option id="other_workstreams_option{{i}}"
            *ngFor="let option of getMultipleSelectOptions('other_workstreams');let i=index" [value]="option">
            {{option.name}}</mat-option>
        </mat-autocomplete>
        <mat-chip-set id="other_workstreams_list">
          <mat-chip id="other_workstreams_chip{{i}}" disableRipple="true" removable="true" selectable="false"
            *ngFor="let option of getMultipleSelectValues('other_workstreams');let i=index"
            (removed)="onRemoveMultipleSelectValue('other_workstreams', option)">
            {{option.name}}
            <span id="other_workstreams_delete{{i}}" matChipRemove *ngIf="!storyForm.get('other_workstreams').disabled">
              <i class="ic-close"></i></span>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <mat-divider *ngIf="orgTypeName == 'Labs'"></mat-divider>
    <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName !== 'Liquid Studio'">
      <div fxFlex="50" class="form-group"
        [ngClass]="{'error': submitted && storyForm.get('primary_location').invalid }">
        <label id="lblPrimaryLocation">Primary Location<span>*</span></label>
        <mat-select id="drpPrimaryLocation" formControlName="primary_location" [compareWith]="onCompareObjects" aria-label="PrimaryLocation">
          <mat-option [value]="null"></mat-option>
          <mat-option id="optPrimaryLocation{{i}}"
            *ngFor="let option of getMultipleSelectOptions('primary_location');let i=index" [value]="option">
            {{option.name}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="50" class="form-group">
        <label id="lblOtherLocations">Other Locations</label>
        <input id="other_locations" type="text" class="form-control" [matAutocomplete]="other_locations"
          [disabled]="storyForm.get('other_locations').disabled" aria-label="otherLocations" />
        <mat-autocomplete id="other_locations_auto" #other_locations="matAutocomplete"
          (optionSelected)="onAddMultipleSelectValue('other_locations', $event)">
          <mat-option id="other_locations_option{{i}}"
            *ngFor="let option of getMultipleSelectOptions('other_locations');let i=index" [value]="option">
            {{option.name}}</mat-option>
        </mat-autocomplete>
        <mat-chip-set id="other_locations_list">
          <mat-chip id="other_locations_chip{{i}}" disableRipple="true" removable="true" selectable="false"
            *ngFor="let option of getMultipleSelectValues('other_locations');let i=index"
            (removed)="onRemoveMultipleSelectValue('other_locations', option)">
            {{option.name}}
            <span id="other_locations_delete{{i}}" matChipRemove *ngIf="!storyForm.get('other_locations').disabled">
              <i class="ic-close"></i></span>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <!-- Liquid Studio -->
    <div fxLayout="row" fxLayoutGap="30px" *ngIf="orgTypeName == 'Liquid Studio'">
      <div fxFlex="50" class="form-group"
        [ngClass]="{'error': submitted && storyForm.get('primary_location').invalid }">
        <label id="lblPrimaryLocation">Primary Location<span>*</span></label>
        <mat-select id="drpPrimaryLocation" formControlName="primary_location" [compareWith]="onCompareObjects" aria-label="PrimaryLocation">
          <mat-option [value]="null"></mat-option>
          <mat-option id="optPrimaryLocation{{i}}" *ngFor="let option of locationList;let i=index" [value]="option">
            {{option.name}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="50" class="form-group">
        <label id="lblOtherLocations">Other Locations</label>
        <input id="other_locations" type="text" class="form-control" [matAutocomplete]="other_locations"
          [disabled]="storyForm.get('primary_location').invalid" aria-label="otherLocations" />
        <mat-autocomplete id="other_locations_auto" #other_locations="matAutocomplete"
          (optionSelected)="onAddMultipleSelectValue('other_locations', $event)">
          <mat-option id="other_locations_option{{i}}" *ngFor="let option of getOtherLocations();let i=index"
            [value]="option">{{option.name}}</mat-option>
        </mat-autocomplete>
        <mat-chip-set id="other_locations_list">
          <mat-chip id="other_locations_chip{{i}}" disableRipple="true" removable="true" selectable="false"
            *ngFor="let option of getMultipleSelectValues('other_locations');let i=index"
            (removed)="onRemoveMultipleSelectValue('other_locations', option)">
            {{option.name}}
            <span id="other_locations_delete{{i}}" matChipRemove *ngIf="!storyForm.get('other_locations').disabled">
              <i class="ic-close"></i></span>
          </mat-chip>
        </mat-chip-set>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutGap="30px">
      <div fxLayout="row" fxFlex="50" fxLayoutGap="30px">
        <div fxFlex="50" class="form-group" [ngClass]="{'error': submitted && storyForm.get('eac_qrtr').invalid }">
          <label id="lblEndQTR">Quarter<span>*</span></label>
          <mat-select id="drpEndQrtr" formControlName="eac_qrtr" aria-label="Quarter">
            <mat-option [value]="null"></mat-option>
            <mat-option [value]="storyForm.get('eac_qrtr').value" disabled
              *ngIf="isValueNotFoundInOptions('eac_qrtr', 'qtr')">
              Q{{storyForm.get('eac_qrtr').value}}
            </mat-option>
            <mat-option id="optEndqtr{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr');let i=index"
              [value]="option.qtr">Q{{option.qtr}}</mat-option>
          </mat-select>
        </div>
        <div fxFlex="50" class="form-group" [ngClass]="{'error': submitted && storyForm.get('eac_qrtr_year').invalid }">
          <label id="lblEndYear">Fiscal Year<span>*</span></label>
          <mat-select id="drpEndQrtrYear" formControlName="eac_qrtr_year" aria-label="FiscalYear">
            <mat-option [value]="storyForm.get('eac_qrtr_year').value" disabled
              *ngIf="isValueNotFoundInOptions('eac_qrtr_year', 'year')">
              FY{{storyForm.get('eac_qrtr_year').value
              - 2000}}</mat-option>
            <mat-option id="optEndYear{{i}}" *ngFor="let option of getQuartsOptions('eac_qrtr_year'); let i=index"
              [value]="option.year">
              FY{{option.year-2000}}</mat-option>
          </mat-select>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50">
        <div class="form-group" [ngClass]="{'error': submitted && storyForm.get('contacts').invalid }">
          <label id="lblContacts" [matTooltip]="Messages.CONTACT">Contacts<span>*</span>
            <span class="info-icon"></span></label>
          <input id="contacts" type="text" class="form-control" [matAutocomplete]="contacts"
            [disabled]="storyForm.get('contacts').disabled" (keyup)="onChangeContacts($event)" aria-label="contacts" aria-describedby="contacts_hint"/>
          <mat-progress-bar *ngIf="loadContact" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="contacts_auto" #contacts="matAutocomplete" role="combobox"
            (optionSelected)="onAddMultipleSelectValue('contacts', $event, false, 'eso_id')">
            <mat-option id="contacts_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('contacts','email_id', true);let i=index" [value]="option">
              {{option.display_name}}</mat-option>
          </mat-autocomplete>
          <span id="contacts_hint" class="hint" [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
          <mat-chip-set id="contacts_list">
            <mat-chip id="contacts_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('contacts');let i=index"
              (removed)="onRemoveMultipleSelectValue('contacts', option, 'eso_id')">
              {{option.display_name}}
              <span id="contacts_delete{{i}}" matChipRemove><i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div fxLayout="row" fxLayoutGap="30px" *ngIf="!hideForceNestedMarket">
      <div fxFlex="50" class="form-group" [ngClass]="{'error': submitted && storyForm.get('force').invalid }">
        <label id="lblForceName">Force Name<span>*</span></label>
        <mat-select id="drpForceName" formControlName="force" [compareWith]="onCompareObjects" aria-label="ForceName">
          <mat-option [value]="null"></mat-option>
          <mat-option id="optForceName{{i}}" *ngFor="let option of getMultipleSelectOptions('forces');let i=index"
            [value]="option">
            {{option.name}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="50" class="form-group" [ngClass]="{'error': submitted && storyForm.get('nested_themes').invalid }">
        <label id="lblNestedThemesName">Nested themes<span>*</span></label>
        <mat-select id="drpNestedThemesName" formControlName="nested_themes" [compareWith]="onCompareObjects" aria-label="NestedThemes">
          <mat-option [value]="null"></mat-option>
          <mat-option id="optNestedThemesName{{i}}"
            *ngFor="let option of getMultipleSelectOptions('nested_themes');let i=index" [value]="option">
            {{option.name}}</mat-option>
        </mat-select>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" *ngIf="!hidePartners || !hideForceNestedMarket">
      <div *ngIf="!hideForceNestedMarket" fxFlex="50" class="form-group"
        [ngClass]="{'error': submitted && storyForm.get('markets').invalid }">
        <label id="labMarkets">MARKET<span>*</span></label>
        <mat-select id="drpMarkets" formControlName="markets" aria-label="Market">
          <mat-option [value]="null"></mat-option>
          <mat-option id="markets_opt{{i}}" *ngFor="let option of getMultipleSelectOptions('markets');let i=index"
            [value]="option">
            {{option}}</mat-option>
        </mat-select>
      </div>
      <div fxFlex="50" class="form-group" *ngIf="!hidePartners">
        <label id="lblPartner">PARTNERS</label>
        <input id="partners" type="text" class="form-control" [matAutocomplete]="partners"
          [disabled]="storyForm.get('partners').disabled" aria-label="partners" />
        <mat-autocomplete id="partners_auto" #partners="matAutocomplete"
          (optionSelected)="onAddMultipleSelectValue('partners', $event)">
          <mat-option id="partners_option{{i}}" *ngFor="let option of getMultipleSelectOptions('partners');let i=index"
            [value]="option">{{option.name}}
          </mat-option>
        </mat-autocomplete>
        <mat-chip-set id="partners_list">
          <mat-chip id="partners_chip{{i}}" disableRipple="true" removable="true" selectable="true"
            *ngFor="let option of getMultipleSelectValues('partners');let i=index"
            (removed)="onRemoveMultipleSelectValue('partners', option)">
            {{option.name}}
            <span id="partners_delete{{i}}" matChipRemove *ngIf="!storyForm.get('partners').disabled"><i
                class="ic-close"></i></span>
          </mat-chip>
        </mat-chip-set>
      </div>
      <div fxFlex="50" class="form-group" *ngIf="hidePartners || hideForceNestedMarket"></div>
    </div>
    <!-- <mat-divider></mat-divider> -->
    <div fxLayout="row" fxLayoutGap="29px">
      <div fxLayout="column" fxFlex="50">
        <div class="form-group "
          [ngClass]="{'error': submitted && storyForm.get('context').invalid ,'context-error':storyContext?.length>750 }">
          <label for="context_tinymce" id="lblContext">Context<span>*</span></label>
          <editor id="context_tinymce" style="width: 100%;" (onChange)="getContextTextInnerHTMLByEditor($event)"
            formControlName="context" [init]="richTextStyle" role="textbox" aria-multiline="true" aria-label="Context">
          </editor>
          <span id="owner_text_hint" class="hint" *ngIf="storyContext?.length>750"
            [ngClass]="{'context-hint-error': storyContext?.length>750 }"
            [matTooltip]="Messages.RICH_TEXT_HINT">{{Messages.RICH_TEXT_HINT}}</span>
          <span id="solutionLength" class="hintMessage"
            [ngClass]="{'richTextHint': storyContext?.length>750 }">{{storyContext ? storyContext?.length
            :0}}/750</span>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50">
        <div class="form-group"
          [ngClass]="{'error': submitted && storyForm.get('solution').invalid,'context-error':storySolution?.length>750 }">
          <label  id="lblSolution">Solution<span>*</span></label>
          <editor id="solution_tinymce" style="width: 100%;" (onChange)="getSolutionInnerHTMLByEditor($event)"
            formControlName="solution" [init]="richTextStyle" >
          </editor>
          <span id="owner_text_hint" class="hint" *ngIf="storySolution?.length>750"
            [ngClass]="{'context-hint-error': storySolution?.length>750 }"
            [matTooltip]="Messages.RICH_TEXT_HINT">{{Messages.RICH_TEXT_HINT}}</span>
          <span id="solutionLength" class="hintMessage"
            [ngClass]="{'richTextHint': storySolution?.length>750 }">{{storySolution ? storySolution?.length
            :0}}/750</span>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="29px">
      <div fxLayout="column" fxFlex="50">
        <div class="form-group"
          [ngClass]="{'error': submitted && storyForm.get('impact').invalid ,'context-error':storyImpact?.length>750 }">
          <label id="lblImpact">Impact<span>*</span></label>
          <editor id="impact_tinymce" style="width: 100%;" (onChange)="getimpactTextInnerHTMLByEditor($event)"
            formControlName="impact" [init]="richTextStyle" >
          </editor>
          <span id="owner_text_hint" class="hint" *ngIf="storyImpact?.length>750" 
            [ngClass]="{'context-hint-error': storyImpact?.length>750 }"
            [matTooltip]="Messages.RICH_TEXT_HINT">{{Messages.RICH_TEXT_HINT}}</span>
          <span id="solutionLength" class="hintMessage"
            [ngClass]="{'richTextHint': storyImpact?.length>750 }">{{storyImpact ? storyImpact?.length
            :0}}/750</span>
        </div>
      </div>
      <div fxLayout="column" fxFlex="50">
      </div>
    </div>
    <div fxFlex="50">
      <div class="form-group" [ngClass]="{'error': !isHashTagValidated || storyForm.get('hashtag').invalid}">
        <label id="lblHashtag">Hashtag</label>
        <textarea id="hashtag" spellcheck="false" rows="2" cols="60" styletype="text" 
        (focusout)="onFocusOut($event)" pattern="[A-Za-z0-9@#_\.\\\,\&\-\|\s]+" class="form-control hashtag" formControlName="hashtag" aria-label="Hashtag" aria-describedby="hashtag_hint" maxlength=101></textarea>
        <span id="hashtag_hint" class="hint">Max allowed lines:2 & Max allowed character/line:50.</span>
        <p id="hashtagLength" class="hintMessage">{{storyHashtag ? storyHashtag.length :0}}/101</p>
      </div>
    </div>
    <div fxLayout="column" class="form-group">
      <label fxLayout="row" id="lblLongRelatedDel" [matTooltip]="Messages.PICTURES_FORM_MESSAGE">CLIENT LOGOS
        <span class="info-icon"></span>
        <span id="owner_text_hint" class="hint-picture" *ngIf="isShowIconMesage"
          [ngClass]="{'context-hint-error': isShowIconMesage }">{{Messages.LOGO_TEXT_HINT}}</span>
        <span id="icon_text_hint" class="hint-picture"
          [ngClass]="{'context-hint-error': !isShowIconMesage&&isExceedFileSize }"
          *ngIf="!isShowIconMesage&&isExceedFileSize&&options.icons?.length>0&&isImageSubmistion">{{Messages.FILE_TOTAL_MESSAGE}}</span>
      </label>

      <div class="related-deliverable-container">
        <ng-container>
          <div fxLayout="row" class="show-card">
            <div fxLayout="row" class="sub-show-card client-logos-wrap">
              <div *ngFor="let item of options.icons;let i=index">
                <img id="deliconsImage{{i}}" *ngIf="item.image_type=='new'" [src]="item.image_url" alt=""
                  class="deliverable-image">
                <img id="deliconsImage{{i}}" *ngIf="item.image_type!=='new'" [src]="getDataStoryImage(item,'story')"
                  alt="" class="deliverable-image">
                <label id="btnRemove{{i}}" [ngClass]="{'disabled': this.dataImages[item._id] === 'loading'}"
                  for="deliconsImage{{i}}" class="removeIconButton" aria-label="remove deliverable"
                  (click)="onRemoveIconstory(item)">
                </label>
              </div>
            </div>
          </div>
          <div fxLayout="column">
            <div id="lblAddRelatedDels" class="story-label-btn" (click)="openIconPopup()" tabindex="0" (keydown.enter)="openIconPopup()">
              <div class="next" aria-disabled="true"></div>ADD LOGOS
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div fxLayout="column" class="form-group" [ngClass]="{'error': submitted && !options.pictures?.length }">
      <label fxLayout="row" id="lblLongRelatedDel" [matTooltip]="Messages.PICTURES_FORM_MESSAGE">PICTURES<span>*</span>
        <span class="info-icon"></span>
        <span id="owner_text_hint" class="hint-picture" *ngIf="isShowPicturesMesage"
          [ngClass]="{'context-hint-error': isShowPicturesMesage }">{{Messages.IMAGE_TEXT_HINT}}</span>
        <span id="picture_text_hint" class="hint-picture"
          [ngClass]="{'context-hint-error':  !isShowPicturesMesage&&isExceedFileSize }" role="alert" [attr.aria-invalid]="Messages.FILE_TOTAL_MESSAGE  ? 'true' : 'false'"
          *ngIf="!isShowPicturesMesage&&isExceedFileSize&&options.pictures?.length>0&&isImageSubmistion">{{Messages.FILE_TOTAL_MESSAGE}}</span>
      </label>
      <div class="related-deliverable-container">
        <ng-container *ngFor="let item of options.pictures;let i=index">
          <div fxLayout="row" class="show-card">
            <div id="btnRemove{{i}}" class="removeAssetButton" aria-label="remove deliverable"
              (click)="onRemovestory(item)" [ngClass]="{'disabled': this.dataImages[item._id] === 'loading' }">
            </div>
            <div fxLayout="row" class="sub-show-card">
              <img id="delImage{{i}}" *ngIf="item.image_type=='new'" [src]="item.image_url" alt=""
                class="deliverable-image">
              <img id="delImage{{i}}" *ngIf="item.image_type!=='new'" [src]="getDataStoryImage(item,'story')" alt=""
                class="deliverable-image">
              <div id="delName{{i}}" class="deliverable-name">{{item.image_description}}</div>
            </div>
          </div>

        </ng-container>

        <div id="lblAddRelatedDels" class="story-label-btn" (click)="openPopup()" tabindex="0" (keydown.enter)="openPopup()">
          <div class="next" aria-disabled="true"></div>ADD PICTURES
        </div>
      </div>
    </div>
    <div fxLayout="column" class="form-group">
      <label id="lblLongRelatedDel"> RELATED DELIVERABLES </label>
      <div class="related-deliverable-container">
        <ng-container *ngFor="let item of options.related_deliverables;let i=index">
          <div fxLayout="row" class="show-card">
            <div id="btnRemove{{i}}" class="removeAssetButton" (click)="onRemoveDeliverable(item)"
              aria-label="remove deliverable">
            </div>
            <div fxLayout="row" class="sub-show-card">
              <img id="delImage{{i}}" [src]="getDataImage(item, 'deliverable')" alt="" class="deliverable-image">
              <div id="delName{{i}}" class="deliverable-name">{{item.name}}</div>
            </div>
          </div>
        </ng-container>
        <div id="lblAddRelatedDels" class="story-label-btn" (click)="openRelatedDeliverablesPopup()" tabindex="0" (keydown.enter)="openRelatedDeliverablesPopup()">
          <div class="next"></div>SELECT DELIVERABLES
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="pri-btn-edit-wrap">
      <button id="cancel" type="button" mat-button color="primary" (click)="onCancel()">Cancel</button>
      <button id="saveDraft" *ngIf="!isSaved" type="button" mat-stroked-button color="primary" (click)="onSaveDraft()"
        [disabled]="hasImageLoading()">Save Draft</button>
      <button id="goLive" *ngIf="!isSaved" type="button" mat-flat-button color="primary" (click)="onSave()"
        [disabled]="hasImageLoading()">Go Live</button>
      <button id="update" *ngIf="isSaved" type="button" mat-flat-button color="primary" (click)="onSave()"
        [disabled]="hasImageLoading()">Update</button>
    </div>
  </form>
</div>