/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { CommonService, UserService } from '@app/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GroupService } from '@app/services/group.service';
import { Messages } from '@app/models';
@Component({
  selector: 'app-add-new-groups',
  templateUrl: './add-new-groups.component.html',
  // styleUrls: ['./add-new-groups.component.scss']
})

export class AddNewGroupsComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  submitted = false;
  nameGroup: string;
  teamMembersChanged: Subject<string> = new Subject<string>();
  options: any = {
    users: [],
    locations: [],
    selectedLocations: []
  }
  loadTeam = 0;
  groupLeadName: string;
  selectedId: string;
  groupLocation: string;
  public loadVal = 0;
  public inputGroupName: any = '';
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<AddNewGroupsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private groupService: GroupService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.options.locations = this.data.locations;
    if(this.data.value){
      this.nameGroup = this.data.value.name;
      this.inputGroupName = this.nameGroup;
      this.options.selectedLocations = [];
      this.data.value.locations.forEach((element) => {
        const result = this.commonService.getSelectedValue(element._id, this.data.locations);
        if(result){
          this.options.selectedLocations.push(element);
        }
      });
      if(this.data.value.group_lead){
        this.selectedId = this.data.value.group_lead.eso_id;
        this.groupLeadName = this.data.value.group_lead.display_name;
      }
    }
    this.showForm = this.formBuilder.group({
      groupName: [{ value: this.nameGroup, disabled: false }, [Validators.required, this.noWhitespaceValidator]],
      groupLocation: [{ value: this.options.selectedLocations, disabled: false}, Validators.required],
      groupUsers: [{ value: this.groupLeadName, disabled: false}, Validators.required]
    });
    this.teamMembersChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['users'] = [];
          this._getTeamMembers(filterValue);
        }
      });
  }

  _getTeamMembers(searchText: any): void {
    this.loadTeam++;
    this.userService.getUsers(searchText)
      .subscribe((response) => {
        this.loadTeam--;
        if (response && response.result) {
          this.options.users = response.result;
        }
      });
  }

  onChangeUsers(event): void {
    this.selectedId = '';
    this.groupLeadName = '';
    this.options['users'] = [];
    this.teamMembersChanged.next(event.target.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    if (this._isValid()){
      this.loadVal++;
      if(this.data.value){
        const params = this.data.value._id;
        const locationsIdArray = this.options.selectedLocations.map(({_id, ...rest}) => ({_id}));
        const body = { 'name': this.showForm.get('groupName').value.trim(), 'locations': locationsIdArray, 'group_lead': this.selectedId, 'orgTypeName': this.data.orgTypeName};
        this.groupService.editGroup(params, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{
        const locationsIdArray = this.options.selectedLocations.map(({_id, ...rest}) => ({_id}));
        const body = {'name': this.showForm.get('groupName').value.trim(), 'locations': locationsIdArray, 'group_lead': this.selectedId, 'orgTypeName': this.data.orgTypeName};
        this.groupService.addGroup(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }
  get Messages(): any { return Messages; }
  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }

  selectedValue(selected): void {
    this.selectedId = selected.option.value.eso_id;
    this.groupLeadName = selected.option.value.display_name;
  }
  getGroupLeadName(option) {
    const display_name = option.display_name? option.display_name : option
    return display_name;
  }
  selectedLocationsValue(value): void {
    this.options.selectedLocations = [];
    value.forEach((element) => {
      this.options.selectedLocations.push({'_id': element._id});
    });
  }

  _isValid(): boolean {
    if (this.showForm.valid && this.selectedId !== '') {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  changeGroupValue(value){
    this.inputGroupName = value;
  }
  // get Messages(): any { return Messages; }
}
