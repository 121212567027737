/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime } from 'rxjs/operators';
import { Messages, OrgType } from '@app/models';
import { LocationsService } from '@app/services/location.service';

@Injectable()

export class StoryEditCommonService {
  defaultStoryImage: any = 'assets/images/story.png';
  constructor(
    private formBuilder: UntypedFormBuilder,
    private locationsService: LocationsService,
  ) {

  }

  getStoryImage(self, imageId): void {
    self.services.masterService.getImage(imageId, 'story')
      .subscribe((response) => {
        if (response && response.size > 0) {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onload = () => {
            self.imgURL = reader.result;
          };
        }
      });
  }
  getPartners(self): void {
    self.loading++;
    self.services.masterService.getPartners()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          response.result.forEach(element => {
            element.parnter_multiple_org.forEach(item => {
              //commented the below code to make partners available globally
              //if (item.name == self.orgTypeName) {
              self.options.partners.push(element);
              //}
            })
          });
        }
        if (self.storyForm) {
          self.services.commonService.checkPartnersOption(self, self.storyForm);
        }
      });
  }

  createForm(self, story = null): void {
    self.storyForm = this.formBuilder.group({
      _id: [story ? story._id : null],
      title: [story ? story.title : null, [Validators.required, this.noWhitespaceValidator]],
      subtitle: [story ? story.subtitle : null, this.noWhitespaceValidator],
      hashtag: [story ? story.hashtag : null],
      context: [story ? story.context : null, this.noWhitespaceValidator],
      solution: [story ? story.solution : null, this.noWhitespaceValidator],
      impact: [story ? story.impact : null, this.noWhitespaceValidator],
      // description: [story ? story.description : null],
      team_members: [{ value: story ? story.team_members : [], disabled: false }],
      contacts: [{ value: story ? story.contacts : [], disabled: false }, Validators.required],
      owner: [story ? story.owner : self.loginUser, Validators.required],
      eac_qrtr: [story && story.eac_qrtr ? story.eac_qrtr.qtr : null, Validators.required],
      eac_qrtr_year: [{ value: story && story.eac_qrtr ? story.eac_qrtr.year : null, disabled: false }, Validators.required],
      primary_group: [{ value: story ? story.primary_group : null, disabled: false }, Validators.required],
      other_groups: [{ value: story ? story.other_groups : [], disabled: true }],
      primary_workstream: [{ value: story ? story.primary_workstream : null, disabled: false }, Validators.required],
      other_workstreams: [{ value: story ? story.other_workstreams : [], disabled: true }],
      primary_location: [{ value: story ? story.primary_location : null, disabled: false }, Validators.required],
      other_locations: [{ value: story ? story.other_locations : [], disabled: true }],
      keywords: [{ value: story && story.keywords ? story.keywords : [], disabled: false }, Validators.required],
      partners: [{ value: story && story.partners ? story.partners : [], disabled: false }],
      image: null,
      confidentiality_level: [{ value: story && story.confidentiality_level ? story.confidentiality_level : null, disabled: false }, Validators.required],
      force: [{ value: story && story.force ? story.force : null, disabled: false }, Validators.required],
      nested_themes: [{ value: story && story.nested_themes ? story.nested_themes : null, disabled: true }, Validators.required],
      markets: [{ value: story && story.markets ? story.markets : null, disabled: false }, Validators.required]
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }

  createLiquidForm(self, story = null): void {
    self.storyForm = this.formBuilder.group({
      _id: [story ? story._id : null],
      title: [story ? story.title : null, [Validators.required, this.noWhitespaceValidator]],
      subtitle: [story ? story.subtitle : null, this.noWhitespaceValidator],
      hashtag: [story ? story.hashtag : null],
      context: [story ? story.context : null, this.noWhitespaceValidator],
      solution: [story ? story.solution : null, this.noWhitespaceValidator],
      impact: [story ? story.impact : null, this.noWhitespaceValidator],
      // description: [story ? story.description : null],
      team_members: [{ value: story ? story.team_members : [], disabled: false }],
      contacts: [{ value: story ? story.contacts : [], disabled: false }, Validators.required],
      owner: [story ? story.owner : self.loginUser, Validators.required],
      eac_qrtr: [story && story.eac_qrtr ? story.eac_qrtr.qtr : null, Validators.required],
      eac_qrtr_year: [{ value: story && story.eac_qrtr ? story.eac_qrtr.year : null, disabled: false }, Validators.required],
      primary_group: [{ value: story ? story.primary_group : null, disabled: true }],
      other_groups: [{ value: story ? story.other_groups : [], disabled: true }],
      primary_workstream: [{ value: story ? story.primary_workstream : null, disabled: true }],
      other_workstreams: [{ value: story ? story.other_workstreams : [], disabled: true }],
      primary_location: [{ value: story ? story.primary_location : null, disabled: false }, Validators.required],
      other_locations: [{ value: story ? story.other_locations : [], disabled: false }],
      keywords: [{ value: story && story.keywords ? story.keywords : [], disabled: false }, Validators.required],
      partners: [{ value: story && story.partners ? story.partners : [], disabled: false }],
      confidentiality_level: [{ value: story && story.confidentiality_level ? story.confidentiality_level : null, disabled: false }, Validators.required],
      force: [{ value: story && story.force ? story.force : null, disabled: false }, Validators.required],
      nested_themes: [{ value: story && story.nested_themes ? story.nested_themes : null, disabled: true }, Validators.required],
      markets: [{ value: story && story.markets ? story.markets : null, disabled: false }, Validators.required]
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }
  getKeywords(self, searchText: any): void {
    self.loadKeywords++;
    self.services.storiesService.getStoryKeywords(searchText)
      .subscribe((response) => {
        self.loadKeywords--;
        if (response && response.result) {
          self.options.keywords = response.result;
        }
      });
  }
  createIncubationForm(self, story = null): void {
    self.storyForm = this.formBuilder.group({
      _id: [story ? story._id : null],
      title: [story ? story.title : null, [Validators.required, this.noWhitespaceValidator]],
      subtitle: [story ? story.subtitle : null, this.noWhitespaceValidator],
      hashtag: [story ? story.hashtag : null],
      context: [story ? story.context : null, this.noWhitespaceValidator],
      solution: [story ? story.solution : null, this.noWhitespaceValidator],
      impact: [story ? story.impact : null, this.noWhitespaceValidator],
      // description: [story ? story.description : null],
      team_members: [{ value: story ? story.team_members : [], disabled: false }],
      contacts: [{ value: story ? story.contacts : [], disabled: false }, Validators.required],
      owner: [story ? story.owner : self.loginUser, Validators.required],
      eac_qrtr: [story && story.eac_qrtr ? story.eac_qrtr.qtr : null, Validators.required],
      eac_qrtr_year: [{ value: story && story.eac_qrtr ? story.eac_qrtr.year : null, disabled: false }, Validators.required],
      primary_group: [{ value: story ? story.primary_group : null, disabled: false }, Validators.required],
      other_groups: [{ value: story ? story.other_groups : [], disabled: true }],
      primary_workstream: [{ value: story ? story.primary_workstream : null, disabled: true }],
      other_workstreams: [{ value: story ? story.other_workstreams : [], disabled: true }],
      primary_location: [{ value: story ? story.primary_location : null, disabled: true }, Validators.required],
      other_locations: [{ value: story ? story.other_locations : [], disabled: true }],
      keywords: [{ value: story && story.keywords ? story.keywords : [], disabled: false }, Validators.required],
      partners: [{ value: story && story.partners ? story.partners : [], disabled: false }],
      confidentiality_level: [{ value: story && story.confidentiality_level ? story.confidentiality_level : null, disabled: false }, Validators.required],
      force: [{ value: story && story.force ? story.force : null, disabled: false }, Validators.required],
      nested_themes: [{ value: story && story.nested_themes ? story.nested_themes : null, disabled: true }, Validators.required],
      markets: [{ value: story && story.markets ? story.markets : null, disabled: false }, Validators.required]
    }, {
      validator: this.extraValidator()
    });
    self._afterCreateForm();
  }

  getMultipleSelectOptions(self, field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    const input = document.getElementById(field);
    let filterValue = null;
    if (input) {
      filterValue = input['value'].toLowerCase();
    }
    if (onlyTypeHead && input && input['value'].trim() == '') {
      filterValue = null;
    }
    let options = [];
    if (field == 'owner') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      const teamMembers = self.storyForm.get('team_members').value;
      self.options['owner'].forEach((user) => {
        const teamMembersIndex = self.services.commonService.objectIndexOf(teamMembers, user, 'eso_id');
        if (teamMembersIndex < 0) {
          options.push(user);
        }
      });
    } else if (field == 'team_members') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      const owner = self.storyForm.get('owner').value;
      if (owner) {
        self.options['team_members'].forEach((item) => {
          if (item.eso_id !== owner?.eso_id) {
            options.push(item);
          }
        });
      } else {
        options = self.options['team_members'];
      }
    } else if (field == 'partners') {
      const partners = self.storyForm.get('partners').value;
      if (partners) {
        self.options['partners'].forEach((item) => {
          if (item._id !== partners._id) {
            options.push(item);
          }
        });
      }
    } else if (field == 'markets') {
      options = self.marketsList;
    } else if (field == 'primary_group') {
      options = self.options['groups'];
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.order));
      }
    } else if (field == 'other_groups') {
      const group = self.storyForm.get('primary_group').value;
      if (group) {
        self.options['groups'].forEach((item) => {
          if (item._id !== group._id) {
            options.push(item);
          }
        });
      }
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.order));
      }
    } else if (field == 'primary_workstream') {
      const group = self.storyForm.get('primary_group').value;
      if (group) {
        const groupMaster = self.getGroupMaster(group);
        options = groupMaster.workstreams;
      }
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.workstreamOrder));
      }
    } else if (field == 'other_workstreams') {
      const group = self.storyForm.get('primary_group').value;
      const primary_workstream = self.storyForm.get('primary_workstream').value;
      if (group && primary_workstream) {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.workstreams.forEach((workstream) => {
          const index = self.services.commonService.objectIndexOf(options, workstream);
          if (index < 0 && primary_workstream._id != workstream._id) {
            options.push(workstream);
          }
        });
      }
      const otherGroups = self.storyForm.get('other_groups').value;
      otherGroups.forEach((group) => {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.workstreams.forEach((workstream) => {
          if (!primary_workstream || primary_workstream && primary_workstream._id != workstream._id) {
            const index = self.services.commonService.objectIndexOf(options, workstream);
            if (index < 0) {
              options.push(workstream);
            }
          }
        });
      });
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.workstreamOrder));
      }
    } else if (field == 'primary_location') {
      const group = self.storyForm.get('primary_group').value;
      if (group) {
        const groupMaster = self.getGroupMaster(group);
        options = groupMaster.locations;
      } else if (!group && self.orgTypeName == OrgType.LIQUID) {
        options = self.locationList;
      }
      if (self.groupOrder && options) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.locationOrder));
      }
    } else if (field == 'other_locations') {
      const group = self.storyForm.get('primary_group').value;
      const primary_location = self.storyForm.get('primary_location').value;
      if (group && primary_location) {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.locations.forEach((location) => {
          const index = self.services.commonService.objectIndexOf(options, location);
          if (index < 0 && primary_location._id != location._id) {
            options.push(location);
          }
        });
      }
      const otherGroups = self.storyForm.get('other_groups').value;
      otherGroups.forEach((group) => {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.locations.forEach((location) => {
          if (!primary_location || primary_location && primary_location._id != location._id) {
            const index = self.services.commonService.objectIndexOf(options, location);
            if (index < 0) {
              options.push(location);
            }
          }
        });
      });
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.locationOrder));
      }
    } else if (field == 'keywords') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      options = self.options[field];
    } else if (field == 'nested_themes') {
      const force = self.storyForm.get('force').value;
      if (force) {
        options = self.options[field].filter((item) => {
          return item?.force._id == force?._id;
        });
      }
    } else {
      options = self.options[field];
    }
    if (withoutFilter) {
      return options;
    }
    if (filterValue) {
      if (key != null) {
        if (field == 'partners') {
          return options.filter((option) => {
            return option[key] && option[key].toLowerCase().includes(filterValue);
          });
        } else {
          return options.filter((option) => {
            return option[key] && (option[key].toLowerCase().includes(filterValue) || Object.keys(option).some((k) => option[k] && option[k].toString().toLowerCase().includes(filterValue)));
          }
          );
        }
      }
      return options.filter((option) => {
        return option && option.toLowerCase().includes(filterValue);
      });
    } else if (onlyTypeHead) {
      return [];
    }
    return options;
  }

  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    const values = self.storyForm.get(field).value;
    const input = document.getElementById(field);
    if (allowEnter) {
      const value = input['value'];
      if (value.trim() != '') {
        const index = self.services.commonService.objectIndexOf(values, value, null);
        if (index < 0) {
          values.push(value);
        }
      }
    } else {
      const index = self.services.commonService.objectIndexOf(values, event.option.value, key);
      if (index < 0) {
        values.push(event.option.value);
      }
    }
    input['value'] = '';
    input.blur();
    self.storyForm.get(field).setValue(values);
    self.storyForm.get(field).markAsDirty();
    if (field == 'contacts') {
      const values = self.storyForm.get(field).value;
      if (values && values.length > 2) {
        self.storyForm.get('contacts').disable({ emitEvent: false });
      }
    }
  }

  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    const values = self.storyForm.get(field).value;
    const index = self.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.storyForm.get(field).setValue(values);
    self.storyForm.get(field).markAsDirty();
    if (field == 'contacts') {
      const values = self.storyForm.get(field).value;
      if (values && values.length < 3) {
        self.storyForm.get('contacts').enable({ emitEvent: false });
      }
    }
  }

  afterCreateForm(self) {
    if (self.options.groups && self.options.groups.length) {
      self._enableControls();
    }

    self.storyForm.get('owner').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe((owner) => {
        if (!owner || owner.eso_id !== self.loginUser.eso_id) {
          const teamMembers = self.getMultipleSelectValues('team_members');
          const index = self.services.commonService.objectIndexOf(teamMembers, self.loginUser, 'eso_id');
          if (index < 0) {
            teamMembers.push(self.loginUser);
          }
          self.storyForm.get('team_members').setValue(teamMembers);
        }
      });
    self.storyForm.get('primary_group').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.storyForm.get('primary_workstream').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.storyForm.get('primary_location').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.storyForm.get('eac_qrtr').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        const eacQrtrYears = self.getQuartsOptions('eac_qrtr_year');
        const endYear = self.storyForm.get('eac_qrtr_year').value;
        if (eacQrtrYears && eacQrtrYears.length && !endYear) {
          self.storyForm.get('eac_qrtr_year').setValue(eacQrtrYears[1].year);
        } else if (eacQrtrYears && eacQrtrYears.length && endYear) {
          self.storyForm.get('eac_qrtr_year').setValue(endYear);
        } else {
          self.storyForm.get('eac_qrtr_year').setValue(null);
        }
        self._enableControls();
      });
  }

  enableControls(self) {
    // Enable and disable
    const story = self.storyForm.getRawValue();
    if (story.primary_group) {
      self.storyForm.get('primary_workstream').enable({ emitEvent: false });
      self.storyForm.get('primary_location').enable({ emitEvent: false });
      self.storyForm.get('other_groups').enable({ emitEvent: false });
    } else {
      self.storyForm.get('primary_workstream').disable({ emitEvent: false });
      self.storyForm.get('primary_location').disable({ emitEvent: false });
      self.storyForm.get('other_groups').disable({ emitEvent: false });
    }

    if (self.orgTypeName == OrgType.LIQUID) {
      self.storyForm.get('primary_location').enable({ emitEvent: false });
    }

    if (story.contacts.length > 2) {
      self.storyForm.get('contacts').disable({ emitEvent: false });
    }

    if (story.eac_qrtr) {
      self.storyForm.get('eac_qrtr_year').enable({ emitEvent: false });
    } else {
      self.storyForm.get('eac_qrtr_year').disable({ emitEvent: false });
    }
    if (story.force) {
      self.storyForm.get('nested_themes').enable({ emitEvent: false });
      if (self.options['nested_themes'].length) {
        const nestedThemesOptions = self.getMultipleSelectOptions('nested_themes', 'name', false, true);
        const nestedThemesIndex = self.services.commonService.objectIndexOf(nestedThemesOptions, story.nested_themes);
        if (nestedThemesIndex < 0) {
          self.storyForm.get('nested_themes').setValue(null, { emitEvent: false });
        }
      }
    } else {
      self.storyForm.get('nested_themes').disable({ emitEvent: false });
      self.storyForm.get('nested_themes').setValue(null, { emitEvent: false });
    }
    self.dataProcessing = true;
  }

  primaryOptionCheck(self) {
    const story = self.storyForm.getRawValue();

    const primaryWorkstreamOptions = self.getMultipleSelectOptions('primary_workstream', 'name', false, true);
    const workstreamIndex = self.services.commonService.objectIndexOf(primaryWorkstreamOptions, story.primary_workstream);
    if (workstreamIndex < 0) {
      self.storyForm.get('primary_workstream').setValue(null, { emitEvent: false });
    }
    if (primaryWorkstreamOptions.length == 1) {
      self.storyForm.get('primary_workstream').setValue(primaryWorkstreamOptions[0], { emitEvent: false });
    }

    const primaryLocationOptions = self.getMultipleSelectOptions('primary_location', 'name', false, true);
    let locationIndex;
    if (primaryLocationOptions !== undefined && primaryLocationOptions.length !== 0) {
      locationIndex = self.services.commonService.objectIndexOf(primaryLocationOptions, story.primary_location);
    }
    if (locationIndex < 0) {
      self.storyForm.get('primary_location').setValue(null, { emitEvent: false });
    }
    if (primaryLocationOptions !== undefined && primaryLocationOptions.length == 1) {
      self.storyForm.get('primary_location').setValue(primaryLocationOptions[0], { emitEvent: false });
    }
    if (self.orgTypeName == OrgType.LIQUID) {
      if (locationIndex >= 0) {
        const removedPrimaryLocation = primaryLocationOptions[locationIndex];
        let otherLocations = self.storyForm.get('other_locations').value;
        if (otherLocations.length > 0) {
          otherLocations = otherLocations.filter((o) => { return o._id != removedPrimaryLocation._id; });
        }
        self.storyForm.get('other_locations').setValue(otherLocations, { emitEvent: false });
      } else {
        self.storyForm.get('other_locations').setValue([], { emitEvent: false });
      }
    }
  }

  otherOptionCheck(self) {
    let story = self.storyForm.getRawValue();
    const otherGroupsOptions = self.getMultipleSelectOptions('other_groups', 'name', false, true);
    const removeGroups = [];
    story.other_groups.forEach((group) => {
      const groupIndex = self.services.commonService.objectIndexOf(otherGroupsOptions, group);
      if (groupIndex < 0) {
        removeGroups.push(group);
      }
    });
    removeGroups.forEach((group) => {
      const groupIndex = self.services.commonService.objectIndexOf(story.other_groups, group);
      if (groupIndex >= 0) {
        story.other_groups.splice(groupIndex, 1);
      }
    });
    self.storyForm.get('other_groups').setValue(story.other_groups, { emitEvent: false });

    const otherWorkstreamsOptions = self.getMultipleSelectOptions('other_workstreams', 'name', false, true);
    const removeWorkstreams = [];
    story.other_workstreams.forEach((workstream) => {
      const workstreamIndex = self.services.commonService.objectIndexOf(otherWorkstreamsOptions, workstream);
      if (workstreamIndex < 0) {
        removeWorkstreams.push(workstream);
      }
    });
    removeWorkstreams.forEach((workstream) => {
      const workstreamIndex = self.services.commonService.objectIndexOf(story.other_workstreams, workstream);
      if (workstreamIndex >= 0) {
        story.other_workstreams.splice(workstreamIndex, 1);
      }
    });
    self.storyForm.get('other_workstreams').setValue(story.other_workstreams, { emitEvent: false });


    const otherLocationsOptions = self.getMultipleSelectOptions('other_locations', 'name', false, true);
    const removeLocations = [];
    story.other_locations.forEach((location) => {
      const locationIndex = self.services.commonService.objectIndexOf(otherLocationsOptions, location);
      if (locationIndex < 0) {
        removeLocations.push(location);
      }
    });
    removeLocations.forEach((location) => {
      const locationIndex = self.services.commonService.objectIndexOf(story.other_locations, location);
      if (locationIndex >= 0) {
        story.other_locations.splice(locationIndex, 1);
      }
    });
    self.storyForm.get('other_locations').setValue(story.other_locations, { emitEvent: false });

    // Enable and disable
    story = self.storyForm.getRawValue();
    if ((story.other_groups.length || story.primary_workstream) && otherWorkstreamsOptions.length) {
      self.storyForm.get('other_workstreams').enable({ emitEvent: false });
    } else {
      self.storyForm.get('other_workstreams').disable({ emitEvent: false });
    }

    if ((story.other_groups.length || story.primary_location) && otherLocationsOptions.length) {
      self.storyForm.get('other_locations').enable({ emitEvent: false });
    } else {
      self.storyForm.get('other_locations').disable({ emitEvent: false });
    }
  }

  getFromData(self) {
    const formData = self.storyForm.getRawValue();
    if (formData.title) {
      formData.title = formData.title.trim();
    }
    if (formData.subtitle) {
      formData.subtitle = formData.subtitle.trim();
    }
    if (formData.hashtag) {
      formData.hashtag = formData.hashtag.trim();
    }
    if (formData.context) {
      formData.context = formData.context.trim();
      formData.pure_context = self.contextText ? self.contextText.trim() : self.storyContext.trim();
    }
    if (formData.solution) {
      formData.solution = formData.solution.trim();
      formData.pure_solution = self.solutionText ? self.solutionText.trim() : self.storySolution.trim();
    }
    if (formData.impact) {
      formData.impact = formData.impact.trim();
      formData.pure_impact = self.impactText ? self.impactText.trim() : self.storyImpact.trim();
    }
    // if (formData.description) {
    //   formData.description = formData.description.trim();
    // }
    if (formData.eac_qrtr) {
      formData.eac_qrtr = {
        qtr: formData.eac_qrtr,
        year: formData.eac_qrtr_year
      };
    }
    if (!formData.primary_group && self.orgTypeName !== OrgType.LIQUID) {
      formData.primary_group = null;
      formData.primary_location = null;
      formData.primary_workstream = null;
    }
    formData.organization_type = self.orgType;
    if (self.options.related_deliverables && self.options.related_deliverables.length > 0) {
      formData.related_deliverables = self.options.related_deliverables.map((o) => o._id);
    }
    if (self.options.pictures && self.options.pictures.length > 0) {
      formData.pictures = self.options.pictures;
    }
    if (self.options.icons && self.options.icons.length > 0) {
      formData.icons = self.options.icons;
    }
    // if (self.options.keywords && self.options.keywords.length > 0) {
    //   formData.keywords = self.options.keywords;
    // }

    if (self.displayConfidentialityLevel) {
      formData.confidentiality_level = self.confidentiality_level;
    }
    return formData;
  }

  getGroupMaster(self, group) {
    const index = self.services.commonService.objectIndexOf(self.options['groups'], group);
    if (index >= 0) {
      return self.options['groups'][index];
    }
    return { workstreams: [], locations: [] };
  }

  getQuartsOptions(self, field: string): any[] {
    const options = [];
    if (field == 'eac_qrtr') {
      self.options['eac_qrtr'].forEach((qrtr) => {
        const index = self.services.commonService.objectIndexOf(options, qrtr, 'qtr');
        if (index < 0) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a.qtr - b.qtr; });
    } else if (field == 'eac_qrtr_year') {
      const eacQrtr = self.storyForm.get('eac_qrtr').value;
      self.options['eac_qrtr'].forEach((qrtr) => {
        const index = self.services.commonService.objectIndexOf(options, qrtr, 'year');
        if (index < 0 && qrtr.qtr == eacQrtr) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a.year - b.year; });
    }
    return options;
  }

  onAddSelectValue(self, field: string, event: MatAutocompleteSelectedEvent): void {
    const input = document.getElementById(field);
    input['value'] = event.option.viewValue;
    input.blur();
    self.storyForm.get(field).setValue(event.option.value);
    self.storyForm.get(field).markAsDirty();
  }

  onChangeOwner(self, event): void {
    self.options['owner'] = [];
    if (event.target.value == '') {
      self.storyForm.get('owner').setValue(null);
    }
    self.ownerChanged.next(event.target.value);
  }

  afterSave(self, response, isNew, isDraft, isQuick = null) {
    self.loading--;
    if (response) {
      self.services.commonService.openSnackBar('success', isNew ? Messages.STORY_SAVE_SUCCESS : Messages.STORY_UPDATE_SUCCESS);
      self._formReset();
      if (!isQuick)
        if (isDraft)
          self.router.navigate(['stories/list/drafts']);
        else
          self.router.navigate(['/stories/list/live']);
      else
        self.dialogRef.close(true);
    }
  }

  getGroups(self): void {
    self.loadGroup++;
    const params = { orgTypeName: self.orgTypeName };
    self.services.masterService.getGroups(params)
      .subscribe((response) => {
        self.loadGroup--;
        if (response && response.result) {
          self.options.groups = response.result;
          self.groupOrder = response.order;
        }
        if (self.storyForm) {
          self._enableControls();
        }
      });
  }

  getLocations(self): void {
    self.loadLocation++;
    const params = { active: 'true', orgTypeName: 'LiquidStudio' };
    this.locationsService.getLocations(params).subscribe((response) => {
      if (response && response.result) {
        self.loadLocation--;
        self.locationList = response.result;
      }
    }, (error1) => {
      self.loadLocation--;
      self.commonService.handleError(error1);
    });
    return self.locationList;
  }

  ownerChanged(self, filterValue) {
    if (filterValue.trim().length >= 3) {
      self.options['owner'] = [];
      this.getOwner(self, filterValue);
    }
  }

  getOwner(self, searchText: any): void {
    self.loadOwner++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadOwner--;
        if (response && response.result) {
          self.options.owner = response.result;
        }
      });
  }

  getTeamMembers(self, searchText: any): void {
    self.loadTeam++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadTeam--;
        if (response && response.result) {
          self.options.team_members = response.result;
        }
      });
  }

  getContacts(self, searchText: any): void {
    self.loadContact++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadContact--;
        if (response && response.result) {
          self.options.contacts = response.result;
        }
      });
  }

  formReset(self) {
    const defaultstory = {
      owner: self.loginUser,
      team_members: [],
      other_groups: [],
      other_workstreams: [],
      other_locations: [],
      contacts: [],
      keywords: [],
    };
    self.storyForm.reset(defaultstory, { emitEvent: false });
  }

  invalidFocus(self) {
    setTimeout(() => {
      const invalidMatControl = self.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = self.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }

  isValueNotFoundInOptions(self, field: string, key: string): boolean {
    let result = true;
    const value = self.storyForm.get(field).value;
    if (!value) {
      return false;
    }
    const options = self.getQuartsOptions(field);
    options.forEach((opt) => {
      if (opt[key] == value) {
        result = false;
      }
    });
    return result;
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  extraValidator() {
    return (formGroup: UntypedFormGroup) => {
      const endQrtrCtrl = formGroup.controls['eac_qrtr'];
      const endQrtrYearCtrl = formGroup.controls['eac_qrtr_year'];

      if (!endQrtrCtrl.value || !endQrtrYearCtrl.value) {
        endQrtrCtrl.setErrors({ required: true });
        endQrtrYearCtrl.setErrors({ required: true });
      } else {
        endQrtrCtrl.setErrors(null);
        endQrtrYearCtrl.setErrors(null);
      }
    };
  }
  getForceList(self): void {
    self.loading++;
    self.services.masterService.getForceList()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.forces = response.result;
        }
      });
  }
  getNestedThemesList(self): void {
    self.loading++;
    self.services.masterService.getNestedThemesList()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.nested_themes = response.result;
        }
        if (self.storyForm) {
          self._enableControls();
        }
      });
  }
}