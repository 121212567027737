/* eslint-disable complexity */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Messages, WorkflowStatus } from '@app/models';
import { Subject } from 'rxjs';
import { AuthenticationService } from './api/authentication.service';
@Injectable()

export class StoryCommonService {
  isNeedToRefresh: Subject<boolean> = new Subject<boolean>();
  isImageNeedToRefresh: Subject<boolean> = new Subject<boolean>();
  defaultStoryImage: any = 'assets/images/TI-Banner.png';
  defaultProfileImage: any = 'assets/images/avatar.png';
  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog,
  ) {

  }

  openDialog(self, component, data, width, message, emit = null, isReturn = false) {
    const dialogRef = this.dialog.open(component, {
      width: width,
      maxHeight: '95vh',
      data: data
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        self.services.commonService.openSnackBar('success', message);
        if (emit) {
          self.update.emit({ type: emit });
        } else {
          self.router.navigate(['/stories/list/live']);
        }
      }
    });
  }

  sendRecommend(story) {
    const storyLink = window.location.origin + '/search/view/story/' + story._id;
    const body = `${Messages.RECOMMEND_MAIL_BODY_1} ${storyLink} ${Messages.RECOMMEND_MAIL_BODY_2}`;
    const uri = `mailto:?subject=${encodeURIComponent(Messages.RECOMMEND_STORY_MAIL_SUBJECT)}&body=${encodeURIComponent(body)}`;
    window.open(uri);
  }

  getProfileImage(self, userId: any): string {
    if (userId) {
      if (self.profileImages[userId] && self.profileImages[userId] != 'loading') {
        return self.profileImages[userId];
      } else if (self.profileImages[userId] != 'loading') {
        self.profileImages[userId] = 'loading';
        self.services.masterService.getImage(userId, 'profile')
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                self.profileImages[userId] = reader.result;
              };
            }
          });
      }
    }
    return self.services.storyCommonService.defaultProfileImage;
  }
  getStoryFilterData(filterObj) {
    const filter = {};
    for (const item in filterObj) {
      if (filterObj && filterObj[item] && filterObj[item].length) {
        filterObj[item].forEach((value) => {
          if (item == 'group') {
            if (!filter['primary_group']) {
              filter['primary_group'] = [];
            }
            filter['primary_group'].push(value.name);
          } else if (item == 'workstream') {
            if (!filter['primary_workstream']) {
              filter['primary_workstream'] = [];
            }
            filter['primary_workstream'].push(value.name);
          } else if (item == 'location') {
            if (!filter['primary_location']) {
              filter['primary_location'] = [];
            }
            filter['primary_location'].push(value.name);
          } else if (item == 'organization') {
            if (!filter['organization_type']) {
              filter['organization_type'] = [];
            }
            filter['organization_type'].push(value.name);
          } else if (item == 'eac_qrtr_qtr') {
            if (!filter['eac_qrtr_qtr']) {
              filter['eac_qrtr_qtr'] = [];
            }
            filter['eac_qrtr_qtr'].push(value);
          }
          else if (item == 'eac_qrtr_year') {
            if (!filter['eac_qrtr_year']) {
              filter['eac_qrtr_year'] = [];
            }
            filter['eac_qrtr_year'].push(value);
          } else if (item == 'workflow_status') {
            if (!filter['workflow_status']) {
              filter['workflow_status'] = [];
            }
            filter['workflow_status'].push(value.name);
          } else if (item == 'force') {
            if (!filter['force']) {
              filter['force'] = [];
            }
            filter['force'].push(value.name);
          } else if (item == 'nested_themes') {
            if (!filter['nested_themes']) {
              filter['nested_themes'] = [];
            }
            filter['nested_themes'].push(value.name);
          } else if (item == 'market') {
            if (!filter['markets']) {
              filter['markets'] = [];
            }
            filter['markets'].push(value);
          }
        });
      }
    }
    return filter;
  }
  getWorkflowStatus(self): void {
    self.options.workflow_status = [{
      _id: WorkflowStatus.SAVED,
      name: WorkflowStatus.SAVED
    }, {
      _id: WorkflowStatus.PUBLISH_REQUESTED,
      name: WorkflowStatus.PUBLISH_REQUESTED
    }, {
      _id: WorkflowStatus.PUBLISH_APPROVED,
      name: WorkflowStatus.PUBLISH_APPROVED
    }];
  }
}