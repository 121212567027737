/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PartnerPopupComponent } from '@app/dialog/partner-popup/partner-popup.component';
import { Messages } from '@app/models';
import { CommonService, MasterService } from '@app/services';
import { OrganizationTypeService } from '@app/services/organization-type.service';
import { PartnerService } from '@app/services/partner.service';
import { Services } from '@app/services/services';

@Component({
  selector: 'app-partners-view',
  templateUrl: './partner-view.component.html',
  // styleUrls: ['./partner-view.component.scss']
  })
export class PartnerViewComponent implements OnInit {
  public partnersList;
  public columns: string[] = ['checked', 'name', 'partnerType', 'parnter_multiple_org_name', 'partner_lead_name', 'marketUnit'];
  public headersData = ['', 'TI Partner Name', 'TI Partner Type', 'TI Primary Groups', 'TI Relationship Owner', 'Market Unit'];
  selectedOption = 'All';
  public tableOrder = 'asc';
  totalFunctions = [];
  public search = '';
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  placeHolder: { status: any; action: any; };
  partnerCount = [];
  checkedCount: number;
  selectedpartners: any[];
  editSelectedPartner: boolean;
  partnreIdList: any[];
  public loadVal = 0;
  public enabledValue;
  public orgTypeName = 'General';
  orgTypes: Array<any> = [];
  PartnerTypes: Array<any> = [];
  secondaryGroups: Array<any> = [];
  lqLocations: Array<any> = [];
  eac_qrtr = '';
  constructor(private commonService: CommonService,
    private masterService: MasterService,
    public services: Services,
    public dialog: MatDialog,
    private organizationTypeService: OrganizationTypeService,
    private partnerService: PartnerService) { }


  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this._getOrganizationTypes();
    this._getParnterTypes();
    this._getGroups();
    this._getParnterQuarters();
    this.getPartners();
    this.getLQLocations();
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
  }

  getPartners(): void {
    this.loadVal++;
    let params;
    if (this.selectedOption === 'All') {
      params = { active: 'All', orgTypeName: this.orgTypeName };
    } else if (this.selectedOption === 'Enabled') {
      params = { active: true, orgTypeName: this.orgTypeName };
    } else if (this.selectedOption === 'Disabled') {
      params = { active: false, orgTypeName: this.orgTypeName };
    }
    this.partnerService.getPartners(params)
      .subscribe((response) => {
        if (response && response.result) {
          response.order == 1 ? this.tableOrder = 'asc' : this.tableOrder = 'desc';
          this.partnersList = response.result;
          this.partnersList.forEach((element) => {
            element['checked'] = false;
            element['active'] = !element.archive_ind;
            if (element.partner_common_org) {
              element['partner_common_org_name'] = element.partner_common_org.name;
            }
            if (element.parnter_multiple_org.length > 0) {
              const parnterMultipleOrg = element.parnter_multiple_org.map((item) => item.name).join(',');
              element['parnter_multiple_org_name'] = parnterMultipleOrg;
            }
            if (element.partner_lead) {
              element['partner_lead_name'] = element.partner_lead.map((item)=>item.display_name).join(',');
            }
          });
          Object.assign(this.totalFunctions, this.partnersList);
          this.enabledValue = this.partnersList;
          this.partnersList = this.partnersList.map(({ description, ...rest }) => ({ ...rest }));
          this.partnersList = new MatTableDataSource(this.partnersList);
          this.partnersList._filter.next(this.search);
          if (this.search.trim() == '') {
            this.partnerCount = [`${this.partnersList.filteredData.length} Entries`, ''];
          }
          else {
            this.partnerCount = [`${this.partnersList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
          }
          this.loadVal--;
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getSelectedOption(selected): void {
    this.selectedpartners = [];
    this.checkedCount = 0;
    this.editSelectedPartner = false;
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.partnreIdList = [];
    this.partnersList.filteredData.forEach((element) => {
      if (element.checked === true) {
        this.checkedCount++;
        this.selectedpartners.push(element.group_name);
        this.totalFunctions.forEach((item) => {
          if (item.name === element.name) {
            this.partnreIdList.push({ '_id': item._id });
          }
        });
      }
    });
    if (this.checkedCount > 0) {
      this.editSelectedPartner = true;
    }
  }
  addNewPartner(add): void {
    if (add) {
      const editData = { 'PartnerTypes': this.PartnerTypes, 'orgTypes': this.orgTypes, 'orgTypeName': this.orgTypeName, 'secondaryGroups': this.secondaryGroups, 'parnterLQLocation': this.lqLocations, 'eac_qrtr': this.eac_qrtr };
      const dialogRef = this.dialog.open(PartnerPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.PARTNER_ADDED_SUCCESS);
          this.getPartners();
        }
      });
    }
  }
  setStatus(value): void {
    this.loadVal++;
    this.selectedOption = value;
    const params = { disable: !(value === 'Enabled') };
    this.partnerService.changePartenrsStatus(params, this.partnreIdList)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.getPartners();
          this.editSelectedPartner = false;
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getEnableDisableStatus(value): void {
    this.selectedOption = value;
    this.getPartners();
  }
  getSelectedOrder(value): void {
    this.loadVal++;
    const params = { collection_name: 'industries', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.PARTNER_ORDER_SAVE_SUCCESS);
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.partnersList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.partnerCount = [`${this.partnersList.filteredData.length} Entries`, ''];
    }
    else {
      this.partnerCount = [`${this.partnersList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }
  getClickedItem(value): void {
    if (value) {
      const editData = { 'PartnerTypes': this.PartnerTypes, 'orgTypes': this.orgTypes, 'value': value, 'orgTypeName': this.orgTypeName, 'secondaryGroups': this.secondaryGroups, 'parnterLQLocation': this.lqLocations, 'eac_qrtr': this.eac_qrtr };
      const dialogRef = this.dialog.open(PartnerPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.PARTNER_EDITED_SUCCESS);
          this.getPartners();
        }
      });
    }
  }

  _getOrganizationTypes(): void {
    this.loadVal++;
    this.organizationTypeService.getOrganizationTypes()
      .subscribe((response) => {
        this.loadVal--;
        if (response && response.result) {
          this.orgTypes = response.result.filter((o) => o.name != 'General');
        }
      });
  }

  _getParnterTypes(): void {
    this.loadVal++;
    this.partnerService.getParnterTypes()
      .subscribe((response) => {
        this.loadVal--;
        if (response && response.result) {
          this.PartnerTypes = response.result;
        }
      });
  }

  _getGroups(): void {
    this.loadVal++;
    this.services.masterService.getGroups()
      .subscribe((response) => {
        this.loadVal--;
        if (response && response.result) {
          this.secondaryGroups = response.result;
        }
      });
  }

  getLQLocations(): void {
    this.loadVal++;
    const params = { active: 'true', orgTypeName: 'LiquidStudio' };
    this.services.masterService.getLocations(params).subscribe((response) => {
      if (response && response.result) {
        this.loadVal--;
        this.lqLocations = response.result;
      }
    }, (error1) => {
      this.loadVal--;
      this.services.commonService.handleError(error1);
    });
  }
  _getParnterQuarters(): void {
    this.loadVal++;
    this.services.masterService.getParnterQuarters()
      .subscribe((response) => {
        this.loadVal--;
        this.eac_qrtr = response.parnter_year_default_value;
      });
  }
}
