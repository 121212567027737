/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime } from 'rxjs/operators';
import { Messages } from '@app/models';

@Injectable()

export class ProjectsEditCommonService {
  constructor(
    private formBuilder: UntypedFormBuilder,
  ) {

  }

  getProjectImage(self, projectId): void {
    self.services.masterService.getImage(projectId, 'project')
      .subscribe((response) => {
        if (response && response.size > 0) {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onload = () => {
            self.imgURL = reader.result;
          };
        }
      });
  }

  getInitiatives(self): void {
    self.loading++;
    self.services.masterService.getInitiatives()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.initiatives = response.result;
        }
      });
  }

  getQuarters(self): void {
    self.loading++;
    self.services.projectsService.getQuarters()
      .subscribe((response) => {
        self.loading--;
        if (response) {
          self.options.eac_qrtr = response.end_quarter;
          self.options.end_quarter_default_value = response.end_quarter_default_value;
          if (self.isNew && self.projectForm && self.options.end_quarter_default_value) {
            self.projectForm.get('eac_qrtr').setValue(self.options.end_quarter_default_value.qtr, { emitEvent: false });
            self.projectForm.get('eac_qrtr_year').setValue(self.options.end_quarter_default_value.year, { emitEvent: false });
          }
        }
      });
  }

  getProjectStatus(self): void {
    self.loading++;
    self.services.projectsService.getProjectStatus()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.project_status = response.result;
        }
      });
  }

  getProjectLead(self, searchText: any): void {
    self.loadProjectLead++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadProjectLead--;
        if (response && response.result) {
          self.options.project_lead = response.result;
        }
      });
  }

  getGroups(self): void {
    self.loading++;
    const params = { orgTypeName: self.orgTypeName };
    self.services.masterService.getGroups(params)
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.groups = response.result;
          self.groupOrder = response.order;
        }
        if (self.projectForm) {
          self._enableControls();
        }
      });
  }

  getTeamMembers(self, searchText: any): void {
    self.loadTeam++;
    self.services.userService.getUsers(searchText)
      .subscribe((response) => {
        self.loadTeam--;
        if (response && response.result) {
          self.options.team_members = response.result;
        }
      });
  }

  getKeywords(self, searchText: any): void {
    self.loadKeywords++;
    self.services.projectsService.getProjectsKeywords(searchText)
      .subscribe((response) => {
        self.loadKeywords--;
        if (response && response.result) {
          self.options.keywords = response.result;
        }
      });
  }

  getIndustries(self): void {
    self.loading++;
    self.services.masterService.getIndustries()
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options.industries = response.result;
        }
      });
  }

  projectLeadChanged(self, filterValue) {
    if (filterValue.trim().length >= 3) {
      self.options['project_lead'] = [];
      this.getProjectLead(self, filterValue);
    }
  }

  createForm(self, project = null): void {
    self.projectForm = this.formBuilder.group({
      _id: [project ? project._id : null],
      name: [project ? project.name : null, [Validators.required, this.noWhitespaceValidator]],
      short_description: [project ? project.short_description : null, [Validators.required, this.noWhitespaceValidator]],
      long_description: [project ? project.long_description : null, [Validators.required, this.noWhitespaceValidator]],

      initiatives: [project ? project.initiatives : []],
      project_status: [project ? project.project_status : null, Validators.required],
      eac_qrtr: [project && project.eac_qrtr ? project.eac_qrtr.qtr : null],
      eac_qrtr_year: [{ value: project && project.eac_qrtr ? project.eac_qrtr.year : null, disabled: true }],

      project_lead: [project ? project.project_lead : self.loginUser, Validators.required],
      team_members: [{ value: project ? project.team_members : [], disabled: false }],
      primary_group: [{ value: project ? project.primary_group : null, disabled: false }, Validators.required],
      other_groups: [{ value: project ? project.other_groups : [], disabled: true }],
      primary_workstream: [{ value: project ? project.primary_workstream : null, disabled: true }, Validators.required],
      other_workstreams: [{ value: project ? project.other_workstreams : [], disabled: true }],
      primary_location: [{ value: project ? project.primary_location : null, disabled: true }, Validators.required],
      other_locations: [{ value: project ? project.other_locations : [], disabled: true }],
      keywords: [project ? project.keywords : []],
      industries: [project ? project.industries : []],
      image: null
    }, {});
    self._afterCreateForm();
  }


  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }

  onBlurProjectName(self) {
    const nameCtrl = self.projectForm.get('name');
    if (nameCtrl.value && nameCtrl.dirty && !nameCtrl.invalid && (self.isNew || !self.isNew && self.project.name !== nameCtrl.value)) {
      self.loading++;
      self.services.projectsService.projectNameVerification(nameCtrl.value)
        .subscribe((response) => {
          self.loading--;
          if (response && response.result) {
            self.hasValidProjectName = false;
            nameCtrl.setErrors({ ...nameCtrl.errors, usernameShouldBeUnique: true });
          } else {
            self.hasValidProjectName = true;
            nameCtrl.setErrors(null);
          }
        });
    }
  }

  afterCreateForm(self) {
    if (self.options.groups && self.options.groups.length) {
      self._enableControls();
    }

    self.projectForm.get('project_lead').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe((projectLead) => {
        if (!projectLead || projectLead.eso_id !== self.loginUser.eso_id) {
          const teamMembers = self.getMultipleSelectValues('team_members');
          const index = self.services.commonService.objectIndexOf(teamMembers, self.loginUser, 'eso_id');
          if (index < 0) {
            teamMembers.push(self.loginUser);
          }
          self.projectForm.get('team_members').setValue(teamMembers);
        }
      });

    self.projectForm.get('primary_group').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.projectForm.get('primary_workstream').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.projectForm.get('primary_location').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });

    self.projectForm.get('eac_qrtr').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        const eacQrtrYears = self.getQuartsOptions('eac_qrtr_year');
        const endYear = self.projectForm.get('eac_qrtr_year').value;
        if (eacQrtrYears && eacQrtrYears.length && !endYear) {
          self.projectForm.get('eac_qrtr_year').setValue(eacQrtrYears[0].year);
        } else if (eacQrtrYears && eacQrtrYears.length && endYear) {
          self.projectForm.get('eac_qrtr_year').setValue(endYear);
        } else {
          self.projectForm.get('eac_qrtr_year').setValue(null);
        }
        self._enableControls();
      });

    self.projectForm.get('project_status').valueChanges
      .pipe(
        debounceTime(200)
      ).subscribe(() => {
        self._enableControls();
      });
  }

  primaryOptionCheck(self) {
    const project = self.projectForm.getRawValue();

    const primaryWorkstreamOptions = self.getMultipleSelectOptions('primary_workstream', 'name', false, true);
    const workstreamIndex = self.services.commonService.objectIndexOf(primaryWorkstreamOptions, project.primary_workstream);
    if (workstreamIndex < 0) {
      self.projectForm.get('primary_workstream').setValue(null, { emitEvent: false });
    }
    if (primaryWorkstreamOptions.length == 1) {
      self.projectForm.get('primary_workstream').setValue(primaryWorkstreamOptions[0], { emitEvent: false });
    }

    const primaryLocationOptions = self.getMultipleSelectOptions('primary_location', 'name', false, true);
    const locationIndex = self.services.commonService.objectIndexOf(primaryLocationOptions, project.primary_location);
    if (locationIndex < 0) {
      self.projectForm.get('primary_location').setValue(null, { emitEvent: false });
    }
    if (primaryLocationOptions.length == 1) {
      self.projectForm.get('primary_location').setValue(primaryLocationOptions[0], { emitEvent: false });
    }
  }

  otherOptionCheck(self) {
    let project = self.projectForm.getRawValue();
    const otherGroupsOptions = self.getMultipleSelectOptions('other_groups', 'name', false, true);
    const removeGroups = [];
    project.other_groups.forEach((group) => {
      const groupIndex = self.services.commonService.objectIndexOf(otherGroupsOptions, group);
      if (groupIndex < 0) {
        removeGroups.push(group);
      }
    });
    removeGroups.forEach((group) => {
      const groupIndex = self.services.commonService.objectIndexOf(project.other_groups, group);
      if (groupIndex >= 0) {
        project.other_groups.splice(groupIndex, 1);
      }
    });
    self.projectForm.get('other_groups').setValue(project.other_groups, { emitEvent: false });

    const otherWorkstreamsOptions = self.getMultipleSelectOptions('other_workstreams', 'name', false, true);
    const removeWorkstreams = [];
    project.other_workstreams.forEach((workstream) => {
      const workstreamIndex = self.services.commonService.objectIndexOf(otherWorkstreamsOptions, workstream);
      if (workstreamIndex < 0) {
        removeWorkstreams.push(workstream);
      }
    });
    removeWorkstreams.forEach((workstream) => {
      const workstreamIndex = self.services.commonService.objectIndexOf(project.other_workstreams, workstream);
      if (workstreamIndex >= 0) {
        project.other_workstreams.splice(workstreamIndex, 1);
      }
    });
    self.projectForm.get('other_workstreams').setValue(project.other_workstreams, { emitEvent: false });


    const otherLocationsOptions = self.getMultipleSelectOptions('other_locations', 'name', false, true);
    const removeLocations = [];
    project.other_locations.forEach((location) => {
      const locationIndex = self.services.commonService.objectIndexOf(otherLocationsOptions, location);
      if (locationIndex < 0) {
        removeLocations.push(location);
      }
    });
    removeLocations.forEach((location) => {
      const locationIndex = self.services.commonService.objectIndexOf(project.other_locations, location);
      if (locationIndex >= 0) {
        project.other_locations.splice(locationIndex, 1);
      }
    });
    self.projectForm.get('other_locations').setValue(project.other_locations, { emitEvent: false });

    // Enable and disable
    project = self.projectForm.getRawValue();
    if ((project.other_groups.length || project.primary_workstream) && otherWorkstreamsOptions.length) {
      self.projectForm.get('other_workstreams').enable({ emitEvent: false });
    } else {
      self.projectForm.get('other_workstreams').disable({ emitEvent: false });
    }

    if ((project.other_groups.length || project.primary_location) && otherLocationsOptions.length) {
      self.projectForm.get('other_locations').enable({ emitEvent: false });
    } else {
      self.projectForm.get('other_locations').disable({ emitEvent: false });
    }
  }

  enableControls(self) {
    // Enable and disable
    const project = self.projectForm.getRawValue();
    if (!self.isNew && !self.project.canEdit && !self.project.canEditInitiative) {
      self.showSaveBtn = false;
      self.projectForm.disable({ emitEvent: false });
    } else if (!self.isNew && !self.project.canEdit && self.project.canEditInitiative) {
      self.showSaveBtn = true;
      self.projectForm.disable({ emitEvent: false });
      self.projectForm.get('initiatives').enable({ emitEvent: false });
    } else {
      self.showSaveBtn = true;
      self.projectForm.enable({ emitEvent: false });

      if (project.primary_group) {
        self.projectForm.get('primary_workstream').enable({ emitEvent: false });
        self.projectForm.get('primary_location').enable({ emitEvent: false });
        self.projectForm.get('other_groups').enable({ emitEvent: false });
      } else {
        self.projectForm.get('primary_workstream').disable({ emitEvent: false });
        self.projectForm.get('primary_location').disable({ emitEvent: false });
        self.projectForm.get('other_groups').disable({ emitEvent: false });
      }

      if (project.eac_qrtr) {
        self.projectForm.get('eac_qrtr_year').enable({ emitEvent: false });
      } else {
        self.projectForm.get('eac_qrtr_year').disable({ emitEvent: false });
      }
    }
  }

  onChangeProjectLead(self, event): void {
    self.options['project_lead'] = [];
    if (event.target.value == '') {
      self.projectForm.get('project_lead').setValue(null);
    }
    self.projectLeadChanged.next(event.target.value);
  }

  getGroupMaster(self, group) {
    const index = self.services.commonService.objectIndexOf(self.options['groups'], group);
    if (index >= 0) {
      return self.options['groups'][index];
    }
    return { workstreams: [], locations: [] };
  }

  getMultipleSelectOptions(self, field: string, key = 'name', onlyTypeHead = false, withoutFilter = false): any[] {
    const input = document.getElementById(field);
    let filterValue = null;
    if (input && input['value']) {
      filterValue = input['value'].toLowerCase();
    }
    if (onlyTypeHead && input && input['value'].trim() == '') {
      filterValue = null;
    }
    let options = [];
    if (field == 'project_lead') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      const teamMembers = self.projectForm.get('team_members').value;
      self.options['project_lead'].forEach((user) => {
        const index = self.services.commonService.objectIndexOf(teamMembers, user, 'eso_id');
        if (index < 0) {
          options.push(user);
        }
      });
    } else if (field == 'team_members') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      const projectLead = self.projectForm.get('project_lead').value;
      if (projectLead) {
        self.options['team_members'].forEach((item) => {
          if (item.eso_id !== projectLead.eso_id) {
            options.push(item);
          }
        });
      } else {
        options = self.options['team_members'];
      }
    } else if (field == 'primary_group') {
      options = self.options['groups'];
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.order));
      }
    } else if (field == 'other_groups') {
      const group = self.projectForm.get('primary_group').value;
      if (group) {
        self.options['groups'].forEach((item) => {
          if (item._id !== group._id) {
            options.push(item);
          }
        });
      }
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.order));
      }
    } else if (field == 'primary_workstream') {
      const group = self.projectForm.get('primary_group').value;
      if (group) {
        const groupMaster = self.getGroupMaster(group);
        options = groupMaster.workstreams;
      }
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.workstreamOrder));
      }
    } else if (field == 'other_workstreams') {
      const group = self.projectForm.get('primary_group').value;
      const primary_workstream = self.projectForm.get('primary_workstream').value;
      if (group && primary_workstream) {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.workstreams.forEach((workstream) => {
          const index = self.services.commonService.objectIndexOf(options, workstream);
          if (index < 0 && primary_workstream._id != workstream._id) {
            options.push(workstream);
          }
        });
      }
      const otherGroups = self.projectForm.get('other_groups').value;
      otherGroups.forEach((group) => {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.workstreams.forEach((workstream) => {
          if (!primary_workstream || primary_workstream && primary_workstream._id != workstream._id) {
            const index = self.services.commonService.objectIndexOf(options, workstream);
            if (index < 0) {
              options.push(workstream);
            }
          }
        });
      });
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.workstreamOrder));
      }
    } else if (field == 'primary_location') {
      const group = self.projectForm.get('primary_group').value;
      if (group) {
        const groupMaster = self.getGroupMaster(group);
        options = groupMaster.locations;
      }
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.locationOrder));
      }
    } else if (field == 'other_locations') {
      const group = self.projectForm.get('primary_group').value;
      const primary_location = self.projectForm.get('primary_location').value;
      if (group && primary_location) {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.locations.forEach((location) => {
          const index = self.services.commonService.objectIndexOf(options, location);
          if (index < 0 && primary_location._id != location._id) {
            options.push(location);
          }
        });
      }
      const otherGroups = self.projectForm.get('other_groups').value;
      otherGroups.forEach((group) => {
        const groupMaster = self.getGroupMaster(group);
        groupMaster.locations.forEach((location) => {
          if (!primary_location || primary_location && primary_location._id != location._id) {
            const index = self.services.commonService.objectIndexOf(options, location);
            if (index < 0) {
              options.push(location);
            }
          }
        });
      });
      if (self.groupOrder) {
        options.sort(self.services.commonService.compareValues(self.groupOrder.internalOrder.locationOrder));
      }
    } else if (field == 'keywords') {
      if (filterValue) {
        filterValue = filterValue.trim();
      }
      options = self.options[field];
    } else if (field == 'initiatives') {
      if (!self.isNew && !self.project.canEdit && self.project.canEditInitiative) {
        options = self.options[field].filter((i) => i.initiative_lead && i.initiative_lead.eso_id == self.loginUser.eso_id);
      } else {
        options = self.options[field];
      }
    } else {
      options = self.options[field];
    }
    if (withoutFilter) {
      return options;
    }
    if (filterValue) {
      if (key != null) {
        return options.filter((option) => {
          return option[key] && (option[key].toLowerCase().includes(filterValue) || Object.keys(option).some((k) => option[k] && option[k].toString().toLowerCase().includes(filterValue)));
        }
        );
      }
      return options.filter((option) => {
        return option && option.toLowerCase().includes(filterValue);
      });
    } else if (onlyTypeHead) {
      return [];
    }
    return options;
  }

  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, allowEnter = false, key = '_id'): void {
    const values = self.projectForm.get(field).value;
    const input = document.getElementById(field);
    if (allowEnter) {
      const value = input['value'];
      if (value.trim() != '') {
        const index = self.services.commonService.objectIndexOf(values, value, null);
        if (index < 0) {
          values.push(value);
        }
      }
    } else {
      const index = self.services.commonService.objectIndexOf(values, event.option.value, key);
      if (index < 0) {
        values.push(event.option.value);
      }
    }
    input['value'] = '';
    input.blur();
    self.projectForm.get(field).setValue(values);
    self.projectForm.get(field).markAsDirty();
  }

  onAddSelectValue(self, field: string, event: MatAutocompleteSelectedEvent): void {
    const input = document.getElementById(field);
    input['value'] = event.option.viewValue;
    input.blur();
    self.projectForm.get(field).setValue(event.option.value);
    self.projectForm.get(field).markAsDirty();
  }

  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    const values = self.projectForm.get(field).value;
    const index = self.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    self.projectForm.get(field).setValue(values);
    self.projectForm.get(field).markAsDirty();
  }

  getQuartsOptions(self, field: string): any[] {
    const options = [];
    if (field == 'eac_qrtr') {
      self.options['eac_qrtr'].forEach((qrtr) => {
        const index = self.services.commonService.objectIndexOf(options, qrtr, 'qtr');
        if (index < 0) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a.qtr - b.qtr; });
    } else if (field == 'eac_qrtr_year') {
      const eacQrtr = self.projectForm.get('eac_qrtr').value;
      self.options['eac_qrtr'].forEach((qrtr) => {
        const index = self.services.commonService.objectIndexOf(options, qrtr, 'year');
        if (index < 0 && qrtr.qtr == eacQrtr) {
          options.push(qrtr);
        }
      });
      options.sort((a, b) => { return a.year - b.year; });
    }
    return options;
  }

  isValueNotFoundInOptions(self, field: string, key: string): boolean {
    let result = true;
    const value = self.projectForm.get(field).value;
    if (!value) {
      return false;
    }
    const options = self.getQuartsOptions(field);
    options.forEach((opt) => {
      if (opt[key] == value) {
        result = false;
      }
    });
    return result;
  }

  invalidFocus(self) {
    setTimeout(() => {
      const invalidMatControl = self.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = self.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }

  formReset(self) {
    const defaultProject = {
      project_lead: self.loginUser,
      initiatives: [],
      team_members: [],
      other_groups: [],
      other_workstreams: [],
      other_locations: [],
      keywords: [],
      industries: [],
    };
    self.projectForm.reset(defaultProject, { emitEvent: false });
  }

  getFromData(self) {
    const formData = self.projectForm.getRawValue();
    if (formData.name) {
      formData.name = formData.name.trim();
    }
    if (formData.short_description) {
      formData.short_description = formData.short_description.trim();
    }
    if (formData.long_description) {
      formData.long_description = formData.long_description.trim();
    }
    if (formData.eac_qrtr) {
      formData.eac_qrtr = {
        qtr: formData.eac_qrtr,
        year: formData.eac_qrtr_year
      };
    }
    formData.organization_type = self.orgType;
    return formData;
  }

  setDefaultStatus(self) {
    if (!self.projectForm.get('project_status').value) {
      for (const option of self.options.project_status) {
        if (option.name == 'In Progress') {
          self.projectForm.get('project_status').setValue(option);
          break;
        }
      }
    }
  }

  afterSave(self, response, isNew, isDraft, isQuick = null) {
    self.loading--;
    if (response) {
      self.services.commonService.openSnackBar('success', isNew ? Messages.PROJECT_SAVE_SUCCESS : Messages.PROJECT_UPDATE_SUCCESS);
      self._formReset();
      if (!isQuick)
        if (isDraft)
          self.router.navigate(['projects/list/drafts']);
        else
          self.router.navigate(['/projects/list/live']);
      else
        self.dialogRef.close(true);
    }
  }
}