/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@app/services';
import { environment } from '@environments/environment';
@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnInit {
  constructor(
    public commonService: CommonService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.commonService.showHeader.emit(false);
  }

  onLogin() {
    if (environment.demo) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
