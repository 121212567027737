<form fxLayout="column" [formGroup]="orgTypeFrom" class="org-type-dialog" aria-labelledby="OrgTypeTitle">
    <h2 id="OrgTypeTitle" class="dialog-title">SELECT ORGANIZATION</h2>
    <div fxLayout="column" class="dialog-content">
        <div class="form-group" [ngClass]="{'error': submitted && orgTypeFrom.get('OrgType').invalid }">
            <label id="lblOrgType">Organization</label>
            <mat-select id="drpOrgTypeName" formControlName="OrgType" aria-label="Organization">
                <mat-option id="drpOrgTypeName{{i}}" *ngFor="let option of orgTypes;let i=index" [value]="option">{{option.name}}</mat-option>
            </mat-select>
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="cancelBtn" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
        <button id="okBtn" mat-flat-button type="submit" color="primary" (click)="onSaveOrgType()">OK</button>
    </div>
</form>