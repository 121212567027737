/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'app-dropdown-enable',
  templateUrl: './dropdown-enable.component.html',
  styleUrls: ['./dropdown-enable.component.scss']
})
export class DropdownEnableComponent implements OnChanges {
  @Input()
  public setActions = false;
  @Output()
  public showActivityStatus: EventEmitter<string> = new EventEmitter();
  @Output()
  public searchValue: EventEmitter<string> = new EventEmitter();
  @Output()
  public enableAdd: EventEmitter<boolean> = new EventEmitter();
  @Input()
  public selectedCount: number;
  @Output()
  public actionSelected: EventEmitter<string> = new EventEmitter();
  @Output()
  public statusSelected: EventEmitter<string> = new EventEmitter();
  @Input()
  public setPlaceholder;
  @Input()
  public adminEnable? = false;

  public selectedOption = 'All';
  public search = '';
  public actionPlaceholder: string;
  public statusPlaceholder: string;

  ngOnChanges(): void {
    this.actionPlaceholder = this.setPlaceholder.action;
    this.statusPlaceholder = this.setPlaceholder.status;
  }

  showStatus(value): void {
    this.selectedOption = value;
    this.showActivityStatus.emit(this.selectedOption);
  }

  setSearchInput(): void {
    this.searchValue.emit(this.search);
  }

  addNew(): void {
    this.enableAdd.emit(true);
  }

  setStatus(value):void {
    this.selectedOption = value;
    this.statusSelected.emit(value);
  }

  performAction(selectedAction):void {
    this.actionSelected.emit(selectedAction);
  }
}

