<div fxFlex fxLayout="column" class="load-setting-dialog">
    <div fxLayout="column" class="dialog-header">
        <h2 id="h_deleteSettings" class="dialog-title">Delete Saved Report Settings</h2>
    </div>
    <div fxLayout="column" class="dialog-content">
        <p id="deleteReportText">Are you sure you want to delete <b id="deleteName">{{data.name}}</b>?</p>
    </div>
    <div fxLayout="row" fxLayoutGap="10px" class="dialog-actions">
        <div fxFlex></div>
        <button id="cancel" mat-button type="button" color="primary" (click)="onCancel()">cancel</button>
        <button id="deleteReport" mat-flat-button type="button" color="primary" (click)="onDelete()">Delete Report</button>
    </div>
</div>