/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { CommonService } from '../common.service';
import { StoriesService } from '../stories.service';

@Injectable()
export class StoryMockService implements StoriesService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService
  ) {
  }

  getStory(storyId: any): Observable<any> {
    this.commonService.log('GET:', `/stories/${storyId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/stories.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return { result: res.find((x) => x._id === parseInt(storyId, 10)) };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getStories(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/stories/searchMySuccessStoryList', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.get('/assets/json/demo/stories.json', this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          const result = res.filter((x) => parameters.workflow_status.includes(x.workflow_status));
          return {
            stories: result.slice(parameters.page_number * parameters.page_size, parameters.page_number * parameters.page_size + parameters.page_size),
            total: result.length
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getIconShapeInfo(): Observable<any> {
    this.commonService.log('GET:', '/stories/getIconShapeInfo', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`/assets/json/demo/stories.json`, this.commonService.httpOptions());
  }

  getMarketsInfo(): Observable<any> {
    this.commonService.log('GET:', '/stories/getMarketsInfo', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`/assets/json/demo/stories.json`, this.commonService.httpOptions());
  }

  saveDraft(story, file): Observable<any> {
    this.commonService.log('POST:', '/stories/draft', 'BODY:', story, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ story, file });
  }

  updateDraft(storyId, story, file, deleteStoryList): Observable<any> {
    this.commonService.log('PUT:', `/stories/draft/${storyId}`, 'BODY:', story, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ storyId, story, file });
  }

  save(story, file, orgTypeName = null): Observable<any> {
    this.commonService.log('POST:', '/stories', 'BODY:', story, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ story, file, orgTypeName });
  }

  update(storyId, story, file): Observable<any> {
    this.commonService.log('POST:', `/stories/${storyId}`, 'BODY:', story, 'OPTIONS:', this.commonService.httpOptions(null, null));
    return of({ storyId, story, file });
  }

  export(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/stories/reporting/export', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return of({});
  }
  exportPPT(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/stories/reporting/export', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return of({});
  }

  publishRequest(storyId: any): Observable<any> {
    this.commonService.log('POST:', `/stories/publish/request/${storyId}`, 'OPTIONS:', this.commonService.httpOptions());
    return of({ storyId: storyId });
  }

  publishApprove(storyId: any): Observable<any> {
    this.commonService.log('POST:', `/stories/publish/approve/${storyId}`, 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return of({ storyId });
  }

  publishRework(storyId: any, formData): Observable<any> {
    this.commonService.log('POST:', `/stories/publish/rework/${storyId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return of({ storyId, formData });
  }

  getStoryKeywords(searchText: string): Observable<any> {
    const param = { search: searchText.toLowerCase().trim() };
    return of({ searchText });
  }
}

