/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddIndustryComponent } from '@app/dialog/add-industry/add-industry.component';
import { Messages } from '@app/models';
import { CommonService, MasterService } from '@app/services';
import { IndustryService } from '@app/services/industry.service';

@Component({
  selector: 'app-industry-view',
  templateUrl: './industry-view.component.html',
  // styleUrls: ['./industry-view.component.scss']
})
export class IndustryViewComponent implements OnInit {
  public industriesList;
  public columns: string[] = ['checked', 'name'];
  public headersData = ['', 'Industry'];
  selectedOption = 'All';
  public tableOrder = 'asc';
  totalFunctions = [];
  public search = '';
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  placeHolder: { status: any; action: any; };
  industryCount = [];
  checkedCount: number;
  selectedindustries: any[];
  editSelectedIndustries: boolean;
  industryIdList: any[];
  public loadVal =0;
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    private masterService: MasterService,
    public dialog: MatDialog,
    private industryService: IndustryService) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.getIndustries();
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
  }

  getIndustries(): void {
    this.loadVal++;
    let params;
    if(this.selectedOption === 'All') {
      params = {active: 'All', orgTypeName: this.orgTypeName};
    } else if (this.selectedOption === 'Enabled'){
      params = {active: true, orgTypeName: this.orgTypeName};
    } else if (this.selectedOption === 'Disabled') {
      params = {active: false, orgTypeName: this.orgTypeName};
    }
    this.industryService.getIndustries(params)
      .subscribe((response) => {
        if (response && response.result) {
          response.order==1?this.tableOrder ='asc':this.tableOrder ='desc';
          this.industriesList = response.result;
          this.industriesList.forEach((element) => {
            element['checked'] = false;
            element['active'] = !element.archive_ind;
          });
          Object.assign(this.totalFunctions, this.industriesList);
          this.enabledValue = this.industriesList;
          this.industriesList = this.industriesList.map(({description, ...rest}) => ({...rest}));
          this.industriesList = new MatTableDataSource(this.industriesList);
          this.industriesList._filter.next(this.search);
          if (this.search.trim() == '') {
            this.industryCount = [`${this.industriesList.filteredData.length} Entries`, ''];
          }
          else {
            this.industryCount = [`${this.industriesList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
          }
          this.loadVal--;
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getSelectedOption(selected):void {
    this.selectedindustries = [];
    this.checkedCount = 0;
    this.editSelectedIndustries = false;
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.industryIdList = [];
    this.industriesList.filteredData.forEach((element)=> {
      if(element.checked === true) {
        this.checkedCount++;
        this.selectedindustries.push(element.group_name);
        this.totalFunctions.forEach((item) => {
          if(item.name === element.name) {
            this.industryIdList.push({'_id': item._id});
          }
        });
      }
    });
    if(this.checkedCount > 0) {
      this.editSelectedIndustries = true;
    }
  }
  addNewIndustry(add): void {
    if(add) {
      const editData = {'orgTypeName': this.orgTypeName};
      const dialogRef = this.dialog.open(AddIndustryComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.INDUSTRY_ADDED_SUCCESS);
          this.getIndustries();
        }
      });
    }
  }
  setStatus(value): void {
    this.loadVal++;
    this.selectedOption = value;
    const params = {disable: !(value ==='Enabled')};
    this.industryService.changeIndustriesStatus(params, this.industryIdList)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.getIndustries();
          this.editSelectedIndustries = false;
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getEnableDisableStatus(value) :void{
    this.selectedOption = value;
    this.getIndustries();
  }
  getSelectedOrder(value):void{
    this.loadVal++;
    const params = { collection_name: 'industries', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.INDUSTRY_ORDER_SAVE_SUCCESS);
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.industriesList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.industryCount = [`${this.industriesList.filteredData.length} Entries`, ''];
    }
    else {
      this.industryCount = [`${this.industriesList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }
  getClickedItem(value):void{
    if(value) {
      const editData = {'value': value,'orgTypeName': this.orgTypeName};
      const dialogRef = this.dialog.open(AddIndustryComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.INDUSTRY_EDITED_SUCCCESS);
          this.getIndustries();
        }
      });
    }
  }
}
