/* eslint-disable complexity */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { Messages, WorkflowStatus } from '@app/models';
import { Subject } from 'rxjs';
import { AuthenticationService } from './api/authentication.service';

@Injectable()

export class DeliverableCommonService {
  defaultDeliverableImage: any = 'assets/images/TI-Banner.png';
  defaultProfileImage: any = 'assets/images/avatar.png';
  isNeedToRefresh: Subject<boolean> = new Subject<boolean>();

  constructor(
    private authService: AuthenticationService,
    public dialog: MatDialog,
  ) {

  }

  isDeliverablePublishable(deliverable: any) {
    if (deliverable && deliverable.deliverable_type && deliverable.deliverable_type.isPublish) {
      return true;
    }
    return false;
  }

  getWorkflowStatus(self): void {
    self.options.workflow_status = [{
      _id: WorkflowStatus.SAVED,
      name: WorkflowStatus.SAVED
    }, {
      _id: WorkflowStatus.REVIEW_REQUESTED,
      name: WorkflowStatus.REVIEW_REQUESTED
    }, {
      _id: WorkflowStatus.APPROVED,
      name: WorkflowStatus.APPROVED
    }, {
      _id: WorkflowStatus.PUBLISH_REQUESTED,
      name: WorkflowStatus.PUBLISH_REQUESTED
    }, {
      _id: WorkflowStatus.PUBLISH_APPROVED,
      name: WorkflowStatus.PUBLISH_APPROVED
    }];
  }

  getFilterData(filterObj) {
    const filter = {};
    for (const item in filterObj) {
      if (filterObj && filterObj[item] && filterObj[item].length) {
        filterObj[item].forEach((value) => {
          if (item == 'type') {
            if (!filter['type']) {
              filter['type'] = [];
            }
            filter['type'].push(value.name);
          } else if (item == 'group') {
            if (!filter['primary_group']) {
              filter['primary_group'] = [];
            }
            filter['primary_group'].push(value.name);
          } else if (item == 'workstream') {
            if (!filter['primary_workstream']) {
              filter['primary_workstream'] = [];
            }
            filter['primary_workstream'].push(value.name);
          } else if (item == 'location') {
            if (!filter['primary_location']) {
              filter['primary_location'] = [];
            }
            filter['primary_location'].push(value.name);
          } else if (item == 'status') {
            if (!filter['deliverable_status']) {
              filter['deliverable_status'] = [];
            }
            filter['deliverable_status'].push(value.name);
          } else if (item == 'project') {
            if (!filter['project']) {
              filter['project'] = [];
            }
            filter['project'].push(value.name);
          } else if (item == 'initiative') {
            if (!filter['initiatives']) {
              filter['initiatives'] = [];
            }
            filter['initiatives'].push(value.name);
          } else if (item == 'keyword') {
            if (!filter['keywords']) {
              filter['keywords'] = [];
            }
            filter['keywords'].push(value);
          } else if (item == 'deliverable_type') {
            if (!filter['deliverable_type']) {
              filter['deliverable_type'] = [];
            }
            filter['deliverable_type'].push(value.name);
          } else if (item == 'workflow_status') {
            if (!filter['workflow_status']) {
              filter['workflow_status'] = [];
            }
            filter['workflow_status'].push(value.name);
          } else if (item == 'organization') {
            if (!filter['organization_type']) {
              filter['organization_type'] = [];
            }
            filter['organization_type'].push(value.name);
          } else if (item == 'industries') {
            if (!filter['industries']) {
              filter['industries'] = [];
            }
            filter['industries'].push(value.name);
          }
          else if (item == 'eac_qrtr_qtr') {
            if (!filter['eac_qrtr_qtr']) {
              filter['eac_qrtr_qtr'] = [];
            }
            filter['eac_qrtr_qtr'].push(value);
          }
          else if (item == 'eac_qrtr_year') {
            if (!filter['eac_qrtr_year']) {
              filter['eac_qrtr_year'] = [];
            }
            filter['eac_qrtr_year'].push(value);
          } else if (item == 'client') {
            if (!filter['client']) {
              filter['client'] = [];
            }
            filter['client'].push(value);
          } else if (item == 'force') {
            if (!filter['force']) {
              filter['force'] = [];
            }
            filter['force'].push(value.name);
          } else if (item == 'nested_themes') {
            if (!filter['nested_themes']) {
              filter['nested_themes'] = [];
            }
            filter['nested_themes'].push(value.name);
          } else if (item == 'market') {
            if (!filter['markets']) {
              filter['markets'] = [];
            }
            filter['markets'].push(value);
          }
        });
      }
    }
    return filter;
  }

  isAssigned(deliverable: any) {
    const loginUser = this.authService.currentUserValue;
    if (loginUser && deliverable && deliverable.workflow && deliverable.workflow.assigned_user) {
      if (deliverable.workflow.assigned_user == loginUser._id) {
        return true;
      } else if (deliverable.workflow.assigned_user.eso_id) {
        return deliverable.workflow.assigned_user.eso_id == loginUser.eso_id;
      }
    }
    return false;
  }

  isPrimaryLocationLead(deliverable: any) {
    const loginUser = this.authService.currentUserValue;
    if (loginUser && deliverable && deliverable.primary_location) {
      const loactionLeadList = deliverable.primary_location.location_lead.map((o) => o._id);
      if (loactionLeadList.length > 0 && loactionLeadList.includes(loginUser._id)) {
        return true;
      }
    } else if (loginUser && deliverable && deliverable.primary_group && deliverable.primary_group.group_lead) {
      if (deliverable.primary_group.group_lead._id == loginUser._id) {
        return true;
      }
    }
    return false;
  }

  sendRecommend(deliverable) {
    const deliverableLink = window.location.origin + '/search/view/' + deliverable._id;
    const body = `${Messages.RECOMMEND_MAIL_BODY_1} ${deliverableLink} ${Messages.RECOMMEND_MAIL_BODY_2}`;
    const uri = `mailto:?subject=${encodeURIComponent(Messages.RECOMMEND_MAIL_SUBJECT)}&body=${encodeURIComponent(body)}`;
    window.open(uri);
  }

  sendOwnerEmail(deliverable) {
    const deliverableLink = window.location.origin + '/search/view/' + deliverable._id;
    const body = `${Messages.OWNER_MAIL_BODY_1} ${deliverableLink} ${Messages.OWNER_MAIL_BODY_2}`;
    const uri = `mailto:${deliverable.owner.email_id}?subject=${encodeURIComponent(Messages.OWNER_MAIL_SUBJECT)}&body=${encodeURIComponent(body)}`;
    window.open(uri);
  }

  sendGroupLeadEmail(deliverable) {
    const deliverableLink = window.location.origin + '/search/view/' + deliverable._id;
    const body = `${Messages.GROUP_LEAD_MAIL_BODY_1} ${deliverableLink} ${Messages.GROUP_LEAD_MAIL_BODY_2}`;
    const uri = `mailto:${deliverable.primary_group.group_lead.email_id}?subject=${encodeURIComponent(Messages.GROUP_LEAD_MAIL_SUBJECT)}&body=${encodeURIComponent(body)}`;
    window.open(uri);
  }

  getProfileImage(self, userId: any): string {
    if (userId) {
      if (self.profileImages[userId] && self.profileImages[userId] != 'loading') {
        return self.profileImages[userId];
      } else if (self.profileImages[userId] != 'loading') {
        self.profileImages[userId] = 'loading';
        self.services.masterService.getImage(userId, 'profile')
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                self.profileImages[userId] = reader.result;
              };
            }
          });
      }
    }
    return self.services.deliverableCommonService.defaultProfileImage;
  }
  openDialog(self, component, data, width, message, emit = null) {
    const dialogRef = this.dialog.open(component, {
      width: width,
      maxHeight: '95vh',
      data: data
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        self.services.commonService.openSnackBar('success', message);
        if (emit) {
          self.update.emit({ type: 'review_request' });
        } else {
          self.router.navigate(['/deliverables/list/live']);
        }
      }
    });
  }

  canEditDeliverable(deliverable: any) {
    const loginUser = this.authService.currentUserValue;
    if (loginUser && deliverable && deliverable.owner && deliverable.owner.eso_id) {
      if (deliverable.owner.eso_id == loginUser.eso_id) {
        return true;
      }
    }
    if (loginUser && deliverable && deliverable.team_members) {
      for (const user of deliverable.team_members) {
        if (user.eso_id === loginUser.eso_id) {
          return true;
        }
      }
    }
    if (loginUser && deliverable && deliverable.primary_group && deliverable.primary_group.group_lead) {
      if (deliverable.primary_group.group_lead.eso_id == loginUser.eso_id) {
        return true;
      }
    }
    if (loginUser && deliverable && deliverable.other_groups) {
      for (const group of deliverable.other_groups) {
        if (group.group_lead && group.group_lead.eso_id == loginUser.eso_id) {
          return true;
        }
      }
    }
    if (loginUser && deliverable && deliverable.primary_location && deliverable.primary_location.location_lead) {
      const location_lead = deliverable.primary_location.location_lead.filter((o) => o.eso_id === loginUser.eso_id);
      if (location_lead.length > 0) {
        return true;
      }
    }
    if (loginUser && deliverable && deliverable.other_locations) {
      for (const location of deliverable.other_locations) {
        const location_lead = location.location_lead.filter((o) => o.eso_id === loginUser.eso_id);
        if (location_lead.length > 0) {
          return true;
        }
      }
    }
    return false;
  }
}