/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { ReportingService } from '../reporting.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportingAPIService implements ReportingService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/list/reporting', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/quarter/list/reporting`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getStoryQuarters(): Observable<any> {
    this.commonService.log('GET:', '/quarter/report/story', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/quarter/report/story`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverableTypeByMetrics(params = null): Observable<any> {
    this.commonService.log('GET:', '/deliverabletypes/groupbymetrices', 'OPTIONS:', this.commonService.httpOptions(params));
    return this.http.get(`${environment.API_URL}/deliverabletypes/groupbymetrices`, this.commonService.httpOptions(params))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getDeliverables(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/search', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/search`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  /**
   * get publications data
   * @param parameters parameters
   * @param formData form data
   */
  getPublicationsList(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/publications', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/publications`, { filter: formData }, { ...this.commonService.httpOptions(parameters, null), responseType: 'blob' as 'json', observe: 'response' })
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  export(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/deliverables/reporting/export', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/deliverables/reporting/export`, { filter: formData }, { ...this.commonService.httpOptions(parameters, null), responseType: 'blob' as 'json', observe: 'response' })
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getSettings(): Observable<any> {
    this.commonService.log('GET:', '/reports/settings', 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/reports/settings`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getSetting(settingId: any): Observable<any> {
    this.commonService.log('GET:', `/reports/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get(`${environment.API_URL}/reports/settings/${settingId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  deleteSetting(settingId: any): Observable<any> {
    this.commonService.log('DELETE:', `/reports/settings/${settingId}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.delete(`${environment.API_URL}/reports/settings/${settingId}`, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  saveSetting(formData: any): Observable<any> {
    this.commonService.log('POST:', '/reports/settings', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/reports/settings`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  updateSetting(settingId: any, formData: any): Observable<any> {
    this.commonService.log('POST:', `/reports/settings/${settingId}`, 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/reports/settings/${settingId}`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  downloadPdf(parameters: any, htmlText: any): Observable<any> {
    this.commonService.log('POST:', '/deliverables/reporting/downloadPdf', 'BODY:', { filter: htmlText }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/deliverables/reporting/downloadPdf`,
      { filter: htmlText },
      { ...this.commonService.httpOptions(parameters, null), responseType: 'blob', observe: 'response' })
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}