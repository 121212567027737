/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { map, catchError, retry, delay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { KPIService } from '../kpi.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KPIAPIService implements KPIService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  getDeliverables(formData: any): Observable<any> {
    this.commonService.log('POST:', '/search/kpi', 'BODY:', formData, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.post(`${environment.API_URL}/search/kpi`, formData, this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
  getStory(parameters: any, formData: any): Observable<any> {
    this.commonService.log('POST:', '/search/stories', 'BODY:', { filter: formData }, 'OPTIONS:', this.commonService.httpOptions(parameters));
    return this.http.post(`${environment.API_URL}/search/stories`, { filter: formData }, this.commonService.httpOptions(parameters))
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return res;
        }),
        retry(environment.MAX_RETRY_COUNT),
        delay(environment.RETRY_INTERVAL),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }
}