/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-lines */
/* eslint-disable complexity */
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Animations } from '@app/app-animations';
import { OrgType } from '@app/models';
import { OrganizationTypeService } from '@app/services/organization-type.service';
import { Services } from '@app/services/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
  selector: 'app-related-deliverables-filter',
  outputs: ['onChange'],
  templateUrl: './related-deliverables-filter.component.html',
  styleUrls: ['./related-deliverables-filter.component.scss'],
  animations: [
    Animations.slideInOut
  ]
})
export class RelatedDeliverablesFilterComponent implements OnInit {
  allDeliverables: any;
  loading: any = 0;
  loadKeywords: any = 0;
  keywordChanged: Subject<string> = new Subject<string>();
  filterForm: UntypedFormGroup;
  options: any = {
    group: [],
    location: [],
    keyword: [],
    organization: [],
    industries: []
  };
  relatedDeliveralesFilter: any = {
    group: [],
    location: [],
    keyword: [],
    organization: [],
    industries: []
  };
  @Input('sectionKey') filterSectionKey: string;
  @Input('filterOptionActive') filterOptionActive: any = null;
  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public formBuilder: UntypedFormBuilder,
    public services: Services,
    private cd: ChangeDetectorRef,
    public organizationTypeService: OrganizationTypeService
  ) { }

  ngAfterViewChecked(): void {
    this.cd.detectChanges();
  }
  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      group: [[]],
      location: [[]],
      keyword: [[]],
      organization: [[]],
      industries: [[]]
    });
    this._getOrganization();
    this.services.deliverableFilterCommonService.getGroups(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getLocationsOfLq(this, this.filterOptionActive);
    this.services.deliverableFilterCommonService.getIndustries(this, this.filterOptionActive);
    this.keywordChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.length >= 3) {
          this.options['keyword'] = [];
          this._getKeywords(filterValue);
        }
      });
    this.onFilter('init');
  }

  _getOrganization(): void {
    this.loading++;
    this.organizationTypeService.getOrganizationTypes()
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.options['organization'] = response.result.filter((o) => o.name != OrgType.GENERAL);
        }
      });
  }
  _getKeywords(searchText: any): void {
    this.loadKeywords++;
    this.services.deliverableService.getDeliverablesKeywords(searchText)
      .subscribe((response) => {
        this.loadKeywords--;
        if (response && response.result) {
          this.options['keyword'] = response.result;
        }
      });
  }
  onChangeKeyWord(event): void {
    this.options['keyword'] = [];
    this.keywordChanged.next(event.target.value);
  }

  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false): any[] {
    return this.services.commonService.getMultipleSelectOptions(this, field, null, key, onlyTypeHead, false, false, true);
  }
  getMultipleSelectValues(field: string): any {
    this.filterForm.get('group').setValue(this.relatedDeliveralesFilter['group']);
    this.filterForm.get('organization').setValue(this.relatedDeliveralesFilter['organization']);
    this.filterForm.get('location').setValue(this.relatedDeliveralesFilter['location']);
    this.filterForm.get('keyword').setValue(this.relatedDeliveralesFilter['keyword']);
    this.filterForm.get('industries').setValue(this.relatedDeliveralesFilter['industries']);
    return this.filterForm.get(field).value;
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    this.services.deliverableFilterCommonService.onAddMultipleSelectValue(this, field, event, null, key);
  }

  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.deliverableFilterCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }
  onFilter(type) {
    const values = this.filterForm.getRawValue();
    for (const item in values) {
      if (values[item] && values[item].length) {
        values[item].forEach((value) => {
          let index = -1;
          if (item == 'keyword') {
            index = this.services.commonService.objectIndexOf(this.relatedDeliveralesFilter[item], value, null);
          } else {
            index = this.services.commonService.objectIndexOf(this.relatedDeliveralesFilter[item], value);
          }
          if (index < 0) {
            this.relatedDeliveralesFilter[item].push(value);
          }
        });
      }
    }
    this.filterForm.reset();
    this.services.commonService.setSessionData(this.filterSectionKey, this.relatedDeliveralesFilter);
    this.onChange.emit({ type: type, data: this.relatedDeliveralesFilter });
  }

  onRemoveFilter(field, option) {
    const index = this.services.commonService.objectIndexOf(this.relatedDeliveralesFilter[field], option, null);
    if (index >= 0) {
      this.relatedDeliveralesFilter[field].splice(index, 1);
    }
    this.onFilter('removeFilter');
  }
  onClearAllFilter() {
    this.filterForm.reset();
    this.relatedDeliveralesFilter = {
      group: [],
      location: [],
      keyword: [],
      organization: [],
      industries: []
    };
    this.onFilter('clearAll');
  }

  hasFilter() {
    return this.services.deliverableFilterCommonService.hasFilter(this);
  }
}
