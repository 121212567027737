/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError, delay, retry } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { CommonService } from '../common.service';
import { IndustryService } from '@app/services/industry.service';

@Injectable({
  providedIn: 'root'
})
export class IndustryApiService implements IndustryService{
  constructor(private http: HttpClient, private commonService: CommonService) { }
  getIndustries(params: any): Observable<any> {
    return this.http.get(`${environment.API_URL}/industries`, this.commonService.httpOptions(params));
  }
  addIndustry(reqbody): Observable<any> {
    return this.http.post(`${environment.API_URL}/industries`, reqbody, this.commonService.httpOptions());
  }
  editIndustries(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/industries/update/${params}`, reqBody, this.commonService.httpOptions())
    .pipe(
      map(this.commonService.extractData),
      map((res: any) => {
        return res;
      }),
      retry(environment.MAX_RETRY_COUNT),
      delay(environment.RETRY_INTERVAL),
      catchError((error: any) => {
        return this.commonService.handleError(error);
      })
    );
  }
  changeIndustriesStatus(params, reqBody): Observable<any> {
    return this.http.post(`${environment.API_URL}/industries/archiveind`, reqBody, this.commonService.httpOptions(params));
  }
}
