import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  MatRippleModule,
  MAT_RIPPLE_GLOBAL_OPTIONS
} from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingProviders, Routing } from '@app/app-routing';
import { AppComponent } from '@app/app.component';
import { DeliverableFilterComponent } from '@app/components/deliverable-filter/deliverable-filter.component';
import { DeliverableListViewComponent } from '@app/components/deliverable-list-view/deliverable-list-view.component';
import { DeliverableListComponent } from '@app/components/deliverable-list/deliverable-list.component';
import { ProjectFilterComponent } from '@app/components/project-filter/project-filter.component';
import { ProjectListViewComponent } from '@app/components/project-list-view/project-list-view.component';
import { ProjectListComponent } from '@app/components/project-list/project-list.component';
import { ReportFilterComponent } from '@app/components/report-filter/report-filter.component';
import { SearchFilterComponent } from '@app/components/search-filter/search-filter.component';
import { StoryListViewComponent } from '@app/components/story-list-view/story-list-view.component';
import { StoryListComponent } from '@app/components/story-list/story-list.component';
import {
  ApproveDialog,
  DeliverableCommentsDialog,
  PublishDialog,
  PublishRequestDialog,
  ReassignDialog,
  ReviewRequestDialog,
  ReworkDialog
} from '@app/deliverables/deliverable-dialog.components';
import { DeliverableEditComponent } from '@app/deliverables/deliverable-edit.component';
import { DeliverableViewComponent } from '@app/deliverables/deliverable-view.component';
import { DraftDeliverablesComponent } from '@app/deliverables/deliverables-drafts.component';
import { LiveDeliverablesComponent } from '@app/deliverables/deliverables-live.component';
import { ConfirmDialog } from '@app/dialog/confirm-dialog';
import { ErrorComponent } from '@app/error/error.component';
import { HeaderComponent } from '@app/header/header.component';
import {
  AdminGuard,
  AuthGuard,
  CanDeactivateGuard,
  SuperAdminGuard
} from '@app/helpers';
import { HomeComponent } from '@app/home/home.component';
import { LoginComponent } from '@app/login/login.component';
import {
  AdvanceStageDialog,
  ProjectReviewRequestDialog
} from '@app/projects/project-dialog.components';
import { ProjectEditComponent } from '@app/projects/project-edit.component';
import { ProjectsViewComponent } from '@app/projects/project-view.component';
import { DraftProjectsComponent } from '@app/projects/projects-drafts.component';
import { LiveProjectsComponent } from '@app/projects/projects-live.component';
import { KPIComponent } from '@app/reporting/kpi/kpi.component';
import { QuickEditComponent } from '@app/reporting/quick-edit.component';
import {
  DeleteSettingComponent,
  LoadSettingComponent,
  SaveSettingComponent,
  UpdateReportBookSettingComponent
} from '@app/reporting/reporting-setting.component';
import { ReportingComponent } from '@app/reporting/reporting.component';
import { SuccessStoriesComponent } from '@app/reporting/success-stories/success-stories.component';
import {
  AdminFactory,
  AmTestFactory,
  AuthenticationService,
  CommonService,
  DeliverableCommonService,
  DeliverableEditCommonService,
  DeliverableFactory,
  DeliverableFilterCommonService,
  DeliverableService,
  DeliverableTypeFactory,
  GroupFactory,
  GroupLocationLeadFactory,
  IndustryFactory,
  InitiativeFactory,
  KPIFactory,
  KPIService,
  LocationFactory,
  MasterFactory,
  MasterService,
  MetricFactory,
  MiddlewareService,
  OrganizationTypeFactory,
  PartnerFactory,
  ProjectFilterCommonService,
  ProjectsCommonService,
  ProjectsEditCommonService,
  ProjectsFactory,
  ProjectsService,
  ReportingFactory,
  ReportingService,
  RouterExtService,
  StageFactory,
  StatusFactory,
  StoriesFactory,
  StoriesService,
  StoryCommonService,
  StoryEditCommonService,
  StoryFilterCommonService,
  UserFactory,
  UserService,
  WorkstreamFactory
} from '@app/services';
import { Services } from '@app/services/services';
import { SideBarComponent } from '@app/side-bar/side-bar.component';
import { DraftStoriesComponent } from '@app/stories/stories-drafts.component';
import { LiveStoriesComponent } from '@app/stories/stories-live.component';
import { StoryEditComponent } from '@app/stories/story-edit.component';
import { StoryViewComponent } from '@app/stories/story-view.component';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { environment } from '@environments/environment';
import { NgSelectModule } from '@ng-select/ng-select';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { SliderModule } from 'primeng/slider';
import { MaterialModule } from '../material.module';
import { DeliverableTypeViewComponent } from './admin-dashboard/components/deliverable-type-view/deliverable-type-view.component';
import { GroupLocationViewComponent } from './admin-dashboard/components/group-location-view/group-location-view.component';
import { GroupsViewComponent } from './admin-dashboard/components/groups-view/groups-view.component';
import { IndustryViewComponent } from './admin-dashboard/components/industry-view/industry-view.component';
import { InitiativesViewComponent } from './admin-dashboard/components/initiatives-view/initiatives-view.component';
import { LocationViewComponent } from './admin-dashboard/components/location-view/location-view.component';
import { MetricsViewComponent } from './admin-dashboard/components/metrics-view/metrics-view.component';
import { PartnerViewComponent } from './admin-dashboard/components/partner-view/partner-view.component';
import { StageViewComponent } from './admin-dashboard/components/stage-view/stage-view.component';
import { StatusViewComponent } from './admin-dashboard/components/status-view/status-view.component';
import { WorkstreamViewComponent } from './admin-dashboard/components/workstream-view/workstream-view.component';
import { MySuceessStoryFilterComponent } from './components/my-success-stories-filter/my-success-stories-filter.component';
import { StoryFilterComponent } from './components/story-filter/story-filter.component';
import { globalRippleConfig } from './config/rippleConfig';
import { DialogProgressComponent } from './dialog-progress/dialog-progress.component';
import { AddIndustryComponent } from './dialog/add-industry/add-industry.component';
import { AddLocationComponent } from './dialog/add-location/add-location.component';
import { AddNewGroupsComponent } from './dialog/add-new-groups/add-new-groups.component';
import { AddNewInitiativeComponent } from './dialog/add-new-initiative/add-new-initiative/add-new-initiative.component';
import { AddNewWorkstreamComponent } from './dialog/add-new-workstream/add-new-workstream/add-new-workstream.component';
import { AddStageComponent } from './dialog/add-stage/add-stage.component';
import {
  DeleteBookSettingComponent,
  LoadBookSettingComponent,
  SaveBookMarkSettingComponent,
  UpdateBookSettingComponent
} from './dialog/book-mark-setting/book-mark-setting.component';
import { DeleteFunctionalityDialogComponent } from './dialog/delete-functionality/delete-functionality-dialog/delete-functionality-dialog.component';
import { DeliverableTypePopupComponent } from './dialog/deliverable-type-popup/deliverable-type-popup.component';
import { DisableGroupPopupComponent } from './dialog/disable-group-popup/disable-group-popup.component';
import { GroupLocationLeadPopupComponent } from './dialog/group-location-lead-popup/group-location-lead-popup.component';
import { MetricPopupComponent } from './dialog/metric-popup/metric-popup.component';
import { OrganizationTypePopupComponent } from './dialog/organization-type-popup/organization-type-popup.component';
import { PartnerPopupComponent } from './dialog/partner-popup/partner-popup.component';
import { StatusPopupComponent } from './dialog/status-popup/status-popup.component';
import { ShowMessageComponent } from './dmos/show-message/show-message.component';
import { BypassSecurityTrustHtmlPipe } from './pipe/bypass-security-trust-html.pipe';
import { ProgressSpinnerComponent } from './progress-spinner/progress-spinner.component';
import {
  ProjectApproveDialog,
  ProjectReassignDialog
} from './projects/project-reassign-dialog.components';
import { RelatedDeliverablesFilterComponent } from './related-deliverables-popup/related-deliverables-filter/related-deliverables-filter.component';
import { RelatedDeliverablesListComponent } from './related-deliverables-popup/related-deliverables-list/related-deliverables-list.component';
import { RelatedDeliverablesTableComponent } from './related-deliverables-popup/related-deliverables-table/related-deliverables-table.component';
import { SelectedDeliverablesPopupComponent } from './related-deliverables-popup/selected-deliverables-popup.component';
import { CommonEchartsComponent } from './reporting/common-echarts/common-echarts.component';
import { SearchComponent } from './search/search.component';
import { AdminService } from './services/admin.service';
import { AmTestService } from './services/amtest.service';
import { DeliverableTypeService } from './services/deliverable-type.service';
import { GroupLocationLeadService } from './services/group-location-lead.service';
import { GroupService } from './services/group.service';
import { IndustryService } from './services/industry.service';
import { InitiativesService } from './services/initiatives.service';
import { LocationsService } from './services/location.service';
import { MetricService } from './services/metric.service';
import { OrganizationTypeService } from './services/organization-type.service';
import { PartnerService } from './services/partner.service';
import { StageService } from './services/stage.service';
import { StatusService } from './services/status.service';
import { WorkstreamService } from './services/workstream.service';
import { ActionDropdownComponent } from './shared/components/action-dropdown/action-dropdown.component';
import { CropperComponent } from './shared/components/cropper/cropper.component';
import { DataTableComponent } from './shared/components/data-table/data-table.component';
import { DropdownEnableComponent } from './shared/components/dropdown-enable/dropdown-enable.component';
import {
  ReworkStoryDialog,
  StoryCommentsDialog,
  StoryPublishDialog,
  StoryPublishRequestDialog
} from './stories/dialog/story-dialog.components';
import { StoryIconDialogComponent } from './stories/story-icon-dialog.component';
import { StoryImageDialogComponent } from './stories/story-image-dialog.component';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('MSAL Angular: ', message);
}

@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        SideBarComponent,
        LoginComponent,
        HomeComponent,
        ErrorComponent,
        DraftDeliverablesComponent,
        LiveDeliverablesComponent,
        DeliverableEditComponent,
        DeliverableViewComponent,
        ReviewRequestDialog,
        ApproveDialog,
        ReassignDialog,
        PublishDialog,
        ReworkDialog,
        PublishRequestDialog,
        DeliverableCommentsDialog,
        DeliverableFilterComponent,
        SearchFilterComponent,
        DeliverableListViewComponent,
        SearchComponent,
        DeliverableListComponent,
        ConfirmDialog,
        GroupsViewComponent,
        LocationViewComponent,
        WorkstreamViewComponent,
        InitiativesViewComponent,
        DeliverableTypeViewComponent,
        StatusViewComponent,
        IndustryViewComponent,
        AddIndustryComponent,
        MetricsViewComponent,
        PartnerViewComponent,
        DataTableComponent,
        DeleteFunctionalityDialogComponent,
        ActionDropdownComponent,
        ReportingComponent,
        ReportFilterComponent,
        QuickEditComponent,
        LoadSettingComponent,
        DeleteSettingComponent,
        SaveSettingComponent,
        AddNewGroupsComponent,
        DropdownEnableComponent,
        AddNewWorkstreamComponent,
        AddNewInitiativeComponent,
        MetricPopupComponent,
        StatusPopupComponent,
        AddLocationComponent,
        DeliverableTypePopupComponent,
        DisableGroupPopupComponent,
        GroupLocationViewComponent,
        GroupLocationLeadPopupComponent,
        KPIComponent,
        SuccessStoriesComponent,
        DraftProjectsComponent,
        LiveProjectsComponent,
        ProjectEditComponent,
        ProjectListComponent,
        ProjectsViewComponent,
        ProjectFilterComponent,
        ProjectListViewComponent,
        AdvanceStageDialog,
        ProjectReviewRequestDialog,
        CropperComponent,
        StageViewComponent,
        AddStageComponent,
        CommonEchartsComponent,
        ProjectReassignDialog,
        ProjectApproveDialog,
        OrganizationTypePopupComponent,
        ShowMessageComponent,
        SaveBookMarkSettingComponent,
        LoadBookSettingComponent,
        DeleteBookSettingComponent,
        UpdateBookSettingComponent,
        UpdateReportBookSettingComponent,
        StoryEditComponent,
        StoryListComponent,
        StoryListViewComponent,
        DraftStoriesComponent,
        LiveStoriesComponent,
        StoryViewComponent,
        BypassSecurityTrustHtmlPipe,
        SelectedDeliverablesPopupComponent,
        StoryImageDialogComponent,
        StoryIconDialogComponent,
        RelatedDeliverablesFilterComponent,
        RelatedDeliverablesListComponent,
        RelatedDeliverablesTableComponent,
        StoryFilterComponent,
        MySuceessStoryFilterComponent,
        StoryPublishRequestDialog,
        StoryPublishDialog,
        ReworkStoryDialog,
        StoryCommentsDialog,
        PartnerPopupComponent,
        ProgressSpinnerComponent,
        DialogProgressComponent
    ],
    bootstrap: [AppComponent, MsalRedirectComponent], imports: [BrowserModule,
        EditorModule,
        BrowserAnimationsModule,
        Routing,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        NgSelectModule,
        SliderModule,
        MatButtonModule,
        MatSidenavModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        MatTabsModule,
        MatSelectModule,
        MatMenuModule,
        MatDividerModule,
        MatChipsModule,
        MatDialogModule,
        MatToolbarModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatTooltipModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        MatProgressSpinnerModule,
        DragDropModule,
        MaterialModule,
        MsalModule.forRoot(new PublicClientApplication({
            auth: {
                clientId: environment.clientId,
                authority: environment.authority,
                redirectUri: environment.redirectUri
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
                storeAuthStateInCookie: isIE // set to true for IE 11
            },
            system: {
                loggerOptions: {
                    loggerCallback: loggerCallback,
                    piiLoggingEnabled: false
                }
            }
        }), {
            interactionType: InteractionType.Redirect,
            authRequest: {
                scopes: ['user.read', 'openid', 'profile']
            }
        }, {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: new Map([
                ['https://graph.microsoft.com', ['user.read.all']]
            ])
        }),
        NgxEchartsModule.forRoot({
            echarts: () => import('echarts')
        })], providers: [
        AppRoutingProviders,
        MiddlewareService,
        CommonService,
        AuthenticationService,
        DeliverableCommonService,
        DeliverableEditCommonService,
        DeliverableFilterCommonService,
        StoryFilterCommonService,
        ProjectsEditCommonService,
        ProjectsCommonService,
        ProjectFilterCommonService,
        StoryCommonService,
        StoryEditCommonService,
        StoriesService,
        RouterExtService,
        AuthGuard,
        AdminGuard,
        SuperAdminGuard,
        CanDeactivateGuard,
        Services,
        {
            provide: UserService,
            useFactory: UserFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: MasterService,
            useFactory: MasterFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: DeliverableService,
            useFactory: DeliverableFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: ProjectsService,
            useFactory: ProjectsFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: StoriesService,
            useFactory: StoriesFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: ReportingService,
            useFactory: ReportingFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: KPIService,
            useFactory: KPIFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: AdminService,
            useFactory: AdminFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: GroupService,
            useFactory: GroupFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: WorkstreamService,
            useFactory: WorkstreamFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: InitiativesService,
            useFactory: InitiativeFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: PartnerService,
            useFactory: PartnerFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: MetricService,
            useFactory: MetricFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: StageService,
            useFactory: StageFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: LocationsService,
            useFactory: LocationFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: IndustryService,
            useFactory: IndustryFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: StatusService,
            useFactory: StatusFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: DeliverableTypeService,
            useFactory: DeliverableTypeFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: GroupLocationLeadService,
            useFactory: GroupLocationLeadFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: AmTestService,
            useFactory: AmTestFactory,
            deps: [HttpClient, CommonService]
        },
        {
            provide: OrganizationTypeService,
            useFactory: OrganizationTypeFactory,
            deps: [HttpClient, CommonService]
        },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
        MsalGuard,
        MsalBroadcastService,
        MsalService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
