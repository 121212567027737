<div fxFlex fxLayout="column" class="edit-project" *ngIf="projectForm">
    <h2 id="isNew">{{isNew? createTitle : editeTitle}} </h2>
    <p id="indicatesRequiredField"><span>*</span> indicates required field</p>
    <form #validForm fxLayout="column" [formGroup]="projectForm">
        <div fxLayout="row">
            <div fxFlex>
                <div class="form-group"
                    [ngClass]="{'error': ((draftSubmitted || submitted) && projectForm.get('name').invalid) || ( projectForm.get('name').invalid && projectForm.get('name').errors && projectForm.get('name').errors.usernameShouldBeUnique)}">
                    <label id="lbl_project_name">Project Name<span>*</span></label>
                    <input type="text" id="project_name" class="form-control" formControlName="name"
                        (blur)="onBlurProjectName()" aria-label="Project Name" maxlength="128" />
                    <p id="projectNameLength" class="hintMessage">{{projectName ? projectName.length :0}}/128</p>
                    <span id="validName"
                        *ngIf="projectForm.get('name').invalid && projectForm.get('name').errors && projectForm.get('name').errors.usernameShouldBeUnique"
                        class="hint error" [matTooltip]="Messages.PROJECT_NAME_UNIQUE_ERROR"
                        style="display: block;">{{Messages.PROJECT_NAME_UNIQUE_ERROR}}</span>
                </div>
            </div>
        </div>
        <div fxLayout="row">
            <div fxFlex>
                <div class="form-group"
                    [ngClass]="{'error': submitted && projectForm.get('short_description').invalid }">
                    <label id="lbl_short_description">Short Description<span>*</span></label>
                    <textarea rows="3" id="short_description" class="form-control short"
                        formControlName="short_description" maxlength="150" aria-label="shortDescription"></textarea>
                    <p id="shortDescLength" class="hintMessage">{{shortDesc ? shortDesc.length :0}}/150</p>
                </div>
            </div>
        </div>
        <div fxLayout="row" fxLayoutGap="30px">
            <div fxFlex="50" fxLayout="column">
                <div class="form-group" [ngClass]="{'error': submitted && maxFileSize}">
                    <label id="lbl_project_image">Project Image</label>
                    <input type="file" #file id="project_image" class="form-control"
                        (change)="getImagePreview(file.files)" [ngClass]="{'ng-invalid': submitted && maxFileSize}" />
                    <div class="image_preview_project" >
                    
                        <label  id="replace_photo" (click)="file.click()" tabindex="0" (keydown.enter)="file.click()" id="lbl_replace_photo" for="project_image" class="label-btn"
                            *ngIf="!projectForm.get('image').disabled"><span class="icon ic-file"></span>Replace
                            Photo</label>
                            <app-cropper #cropper [currentFile]="currentFile" [projectImgURL]="imgURL"></app-cropper>
                    </div>
                    <span id="validImage" *ngIf="maxFileSize" class="hint error"
                        [matTooltip]="Messages.FILE_LIMIT_MESSAGE"
                        style="display: block;top: 35px;margin-bottom: 10px;">{{Messages.FILE_LIMIT_MESSAGE}}</span>
                </div>
            </div>
            <div fxFlex="50" fxLayout="column">
                <div class="form-group"
                    [ngClass]="{'error': submitted && projectForm.get('long_description').invalid }">
                    <label id="lbl_long_description">Long Description <span>*</span></label>
                    <textarea id="long_description" class="form-control" formControlName="long_description"
                        aria-label="Long Description" maxlength="5000"></textarea>
                    <p id="longDescLength" class="hintMessage">{{long_description ? long_description.length :0}}/5000
                    </p>
                </div>
            </div>
        </div>
        <div fxLayout="column" class="owner_wrap">
            <div fxLayout="row" fxLayoutGap="30px">
                <div fxFlex="50" class="form-group"
                    [ngClass]="{'error': (draftSubmitted || submitted) && projectForm.get('project_lead').invalid }">
                    <label id="lbl_project_lead">Project Lead<span>*</span></label>
                    <input type="text" id="project_lead" class="form-control" [matAutocomplete]="project_lead"
                        [disabled]="projectForm.get('project_lead').disabled"
                        [value]="projectForm.get('project_lead').value?projectForm.get('project_lead').value.display_name:''"
                        (keyup)="onChangeProjectLead($event)" aria-label="project_lead" aria-describedby="project_lead_hint"/>
                    <mat-progress-bar *ngIf="loadProjectLead" color="accent" mode="indeterminate"></mat-progress-bar>
                    <mat-autocomplete id="project_lead_auto" #project_lead="matAutocomplete" role="combobox"
                        (optionSelected)="onAddSelectValue('project_lead', $event)">
                        <mat-option id="project_lead_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('project_lead','email_id', true); let i = index"
                            [value]="option">{{option.display_name}}</mat-option>
                    </mat-autocomplete>
                    <span id="project_lead_hint" class="hint"
                        [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
                </div>
                <div fxFlex="50" class="form-group">
                    <label id="lbl_team_members">TEAM MEMBERS</label>
                    <input type="text" id="team_members" class="form-control" [matAutocomplete]="team_members"
                        [disabled]="projectForm.get('team_members').disabled" (keyup)="onChangeTeamMembers($event)"
                        aria-label="teamMembers" aria-describedby="team_members_hint" />
                    <mat-progress-bar *ngIf="loadTeam" color="accent" mode="indeterminate"></mat-progress-bar>
                    <mat-autocomplete id="team_members_auto" #team_members="matAutocomplete" role="combobox"
                        (optionSelected)="onAddMultipleSelectValue('team_members', $event, false, 'eso_id')">
                        <mat-option id="team_members_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('team_members','email_id', true); let i = index"
                            [value]="option">{{option.display_name}}</mat-option>
                    </mat-autocomplete>
                    <span id="team_members_hint" class="hint"
                        [matTooltip]="Messages.OWNER_HINT">{{Messages.OWNER_HINT}}</span>
                    <mat-chip-set id="team_members_list">
                        <mat-chip id="team_members_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('team_members'); let i = index"
                            (removed)="onRemoveMultipleSelectValue('team_members', option, 'eso_id')">
                            {{option.display_name}}
                            <span id="team_members_delete{{i}}" matChipRemove *ngIf="!projectForm.get('team_members').disabled">
                                <i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutGap="30px">
                <div fxFlex="50" class="form-group"
                    [ngClass]="{'error': submitted && projectForm.get('primary_group').invalid }">
                    <label id="lbl_primary_group">Primary Group<span>*</span></label>
                    <mat-select id="primary_group" formControlName="primary_group" [compareWith]="onCompareObjects" aria-label="PrimaryGroup">
                        <mat-option [value]="null"></mat-option>
                        <mat-option id="primary_group_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('primary_group'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-select>
                </div>
                <div fxFlex="50" class="form-group">
                    <label id="lbl_other_groups">Other Groups</label>
                    <input type="text" id="other_groups" class="form-control" [matAutocomplete]="other_groups"
                        [disabled]="projectForm.get('other_groups').disabled" aria-label="otherGroups" />
                    <mat-autocomplete id="other_groups_auto" #other_groups="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('other_groups', $event)">
                        <mat-option id="other_groups_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('other_groups'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="other_groups_list">
                        <mat-chip id="other_groups_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('other_groups'); let i = index"
                            (removed)="onRemoveMultipleSelectValue('other_groups', option)">
                            {{option.name}}
                            <span id="other_groups_delete{{i}}" matChipRemove *ngIf="!projectForm.get('other_groups').disabled">
                                <i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutGap="30px">
                <div fxFlex="50" class="form-group"
                    [ngClass]="{'error': submitted && projectForm.get('primary_workstream').invalid }">
                    <label id="lbl_primary_workstream">Primary Workstream<span>*</span></label>
                    <mat-select id="primary_workstream" formControlName="primary_workstream"
                        [compareWith]="onCompareObjects" aria-label="Primaryworkstream">
                        <mat-option [value]="null"></mat-option>
                        <mat-option id="primary_workstream_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('primary_workstream'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-select>
                </div>
                <div fxFlex="50" class="form-group">
                    <label id="lbl_other_workstreams">Other Workstreams</label>
                    <input type="text" id="other_workstreams" class="form-control" [matAutocomplete]="other_workstreams"
                        [disabled]="projectForm.get('other_workstreams').disabled" aria-label="otherWorkstreams" />
                    <mat-autocomplete id="other_workstreams_auto" #other_workstreams="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('other_workstreams', $event)">
                        <mat-option id="other_workstreams_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('other_workstreams'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="other_workstreams_list">
                        <mat-chip id="other_workstreams_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('other_workstreams'); let i = index"
                            (removed)="onRemoveMultipleSelectValue('other_workstreams', option)">
                            {{option.name}}
                            <span id="other_workstreams_delete{{i}}" matChipRemove *ngIf="!projectForm.get('other_workstreams').disabled">
                                <i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutGap="30px">
                <div fxFlex="50" class="form-group"
                    [ngClass]="{'error': submitted && projectForm.get('primary_location').invalid }">
                    <label id="lbl_primary_location">Primary Location<span>*</span></label>
                    <mat-select id="primary_location" formControlName="primary_location"
                        [compareWith]="onCompareObjects" aria-label="PrimaryLocation">
                        <mat-option [value]="null"></mat-option>
                        <mat-option id="primary_location_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('primary_location'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-select>
                </div>
                <div fxFlex="50" class="form-group">
                    <label id="lbl_other_locations">Other Locations</label>
                    <input type="text" id="other_locations" class="form-control" [matAutocomplete]="other_locations"
                        [disabled]="projectForm.get('other_locations').disabled" aria-label="otherLocations" />
                    <mat-autocomplete id="other_locations_auto" #other_locations="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('other_locations', $event)">
                        <mat-option id="other_locations_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('other_locations'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="other_locations_list">
                        <mat-chip id="other_locations_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('other_locations'); let i = index"
                            (removed)="onRemoveMultipleSelectValue('other_locations', option)">
                            {{option.name}}
                            <span id="other_locations_delete{{i}}" matChipRemove *ngIf="!projectForm.get('other_locations').disabled">
                                <i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <mat-divider></mat-divider>
            <div fxLayout="row" fxLayoutGap="30px">
                <div fxFlex="50" class="form-group"
                    [ngClass]="{'error': submitted && projectForm.get('project_status').invalid }">
                    <label id="lbl_project_status">Project Status<span>*</span></label>
                    <mat-select id="project_status" formControlName="project_status" [compareWith]="onCompareObjects" aria-label="ProjectStatus">
                        <mat-option [value]="null"></mat-option>
                        <mat-option id="status_option{{i}}" *ngFor="let option of options.project_status; let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-select>
                </div>
                <div fxFlex="50" fxLayout="row" fxLayoutGap="30px">
                    <div fxFlex="50" class="form-group"
                        [ngClass]="{'error': submitted && projectForm.get('eac_qrtr').invalid }">
                        <label id="lbl_proEndQrtr">QUARTER</label>
                        <mat-select formControlName="eac_qrtr" id="proEndQrtr" aria-label="Quarter">
                            <mat-option [value]="null"></mat-option>
                            <mat-option id="end_qtr_option1" [value]="projectForm.get('eac_qrtr').value" disabled
                                *ngIf="isValueNotFoundInOptions('eac_qrtr', 'qtr')">
                                Q{{projectForm.get('eac_qrtr').value}}</mat-option>
                            <mat-option id="end_qtr_option2{{i}}"
                                *ngFor="let option of getQuartsOptions('eac_qrtr'); let i = index" [value]="option.qtr">
                                Q{{option.qtr}}</mat-option>
                        </mat-select>
                    </div>
                    <div fxFlex="50" class="form-group"
                        [ngClass]="{'error': submitted && projectForm.get('eac_qrtr_year').invalid }">
                        <label id="lbl_proEndQrtrYear">FISCAL YEAR</label>
                        <mat-select formControlName="eac_qrtr_year" id="proEndQrtrYear" aria-label="FiscalYear">
                            <mat-option id="end_year_option1" [value]="projectForm.get('eac_qrtr_year').value" disabled
                                *ngIf="isValueNotFoundInOptions('eac_qrtr_year', 'year')">
                                FY{{projectForm.get('eac_qrtr_year').value-2000}}</mat-option>
                            <mat-option id="end_year_option2{{i}}"
                                *ngFor="let option of getQuartsOptions('eac_qrtr_year'); let i = index"
                                [value]="option.year">FY{{option.year-2000}}</mat-option>
                        </mat-select>
                    </div>
                </div>
            </div>
        </div>
        <div fxLayout="column" class="project_wrap">
            <div fxLayout="row" fxLayoutGap="30px">
                <div fxFlex="50" class="form-group">
                    <label id="lbl_industries">INDUSTRY</label>
                    <input type="text" id="industries" class="form-control" [matAutocomplete]="industries"
                        [disabled]="projectForm.get('industries').disabled" aria-label="indusries" />
                    <mat-autocomplete id="industries_auto" #industries="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('industries', $event)">
                        <mat-option id="industries_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('industries'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="industries_list">
                        <mat-chip id="industries_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('industries'); let i = index"
                            (removed)="onRemoveMultipleSelectValue('industries', option)">
                            {{option.name}}
                            <span id="industries_delete{{i}}" matChipRemove *ngIf="!projectForm.get('industries').disabled">
                                <i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="50" class="form-group">
                    <label id="lbl_keywords">Keywords</label>
                    <input type="text" id="keywords" class="form-control" [matAutocomplete]="keywords"
                        [disabled]="projectForm.get('keywords').disabled" (keyup)="onChangeKeyWord($event)"
                        (keyup.enter)="onAddMultipleSelectValue('keywords', $event, true)" aria-label="keywords"
                        maxlength="300" aria-describedby="keywords_hint"/>
                    <mat-progress-bar *ngIf="loadKeywords" color="accent" mode="indeterminate"></mat-progress-bar>
                    <mat-autocomplete id="keywords_auto" #keywords="matAutocomplete" role="combobox"
                        (optionSelected)="onAddMultipleSelectValue('keywords', $event, true)">
                        <mat-option id="keywords_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('keywords', null, true); let i = index"
                            [value]="option">{{option}}</mat-option>
                    </mat-autocomplete>
                    <span id="keywords_hint" *ngIf="!getMultipleSelectValues('keywords').length" class="hint"
                        [matTooltip]="Messages.KEYWORD_HINT">{{Messages.KEYWORD_HINT}}</span>
                    <mat-chip-set id="keywords_list">
                        <mat-chip id="keywords_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('keywords'); let i = index"
                            (removed)="onRemoveMultipleSelectValue('keywords', option, null)">
                            {{option}}
                            <span id="keywords_delete{{i}}" matChipRemove *ngIf="!projectForm.get('keywords').disabled">
                                <i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="30px">
                <div fxFlex="50" class="form-group">
                    <label id="lbl_initiatives">Initiatives For Affiliation</label>
                    <input type="text" id="initiatives" class="form-control" [matAutocomplete]="initiatives"
                        [disabled]="projectForm.get('initiatives').disabled" aria-label="initiatives" />
                    <mat-autocomplete id="initiatives_auto" #initiatives="matAutocomplete"
                        (optionSelected)="onAddMultipleSelectValue('initiatives', $event)">
                        <mat-option id="initiatives_option{{i}}"
                            *ngFor="let option of getMultipleSelectOptions('initiatives'); let i = index"
                            [value]="option">{{option.name}}</mat-option>
                    </mat-autocomplete>
                    <mat-chip-set id="initiatives_list">
                        <mat-chip id="initiatives_chip{{i}}" disableRipple="true" removable="true" selectable="false"
                            *ngFor="let option of getMultipleSelectValues('initiatives'); let i = index"
                            (removed)="onRemoveMultipleSelectValue('initiatives', option)">
                            {{option.name}}
                            <span id="initiatives_delete{{i}}" matChipRemove *ngIf="!projectForm.get('initiatives').disabled">
                                <i class="ic-close"></i></span>
                        </mat-chip>
                    </mat-chip-set>
                </div>
                <div fxFlex="50"></div>
            </div>

        </div>

        <div fxLayout="row" fxLayoutGap="30px" class="pri-btn-edit-wrap">
            <button id="cancel" type="button" mat-button color="primary" (click)="onCancel()">Cancel</button>
            <button id="saveDraft" *ngIf="!isSaved" type="button" mat-stroked-button color="primary" (click)="onSaveDraft()">Save Draft</button>
            <button id="goLive" *ngIf="!isSaved" type="button" mat-flat-button color="primary" (click)="onSave()">Go Live</button>
            <button id="update" *ngIf="isSaved" type="button" mat-flat-button color="primary" (click)="onSave()">Update</button>
        </div>
    </form>
</div>