/* eslint-disable max-lines */
/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { OrgType } from '@app/models/organizationType';

@Injectable()

export class DeliverableFilterCommonService {
  getLocationsOfLq(self, active): void {
    self.loading++;
    self.services.masterService.getLocations({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['location'] = response.result.filter((o) => o.organizationType.name === OrgType.LIQUID);
        }
      });
  }
  getGroups(self, active): void {
    self.loading++;
    self.services.masterService.getGroups({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['group'] = response.result;
          self.groupOrder = response.order;
        }
      });
  }

  getDeliverableStatus(self, active): void {
    self.loading++;
    self.services.deliverableService.getDeliverableStatus({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['status'] = response.result;
        }
      });
  }
  getIndustries(self, active): void {
    self.loading++;
    self.services.masterService.getIndustries({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['industries'] = response.result;
        }
      });
  }
  getInitiatives(self, active): void {
    self.loading++;
    self.services.masterService.getInitiatives({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['initiative'] = response.result;
        }
      });
  }

  getDeliverableTypes(self, active): void {
    self.loading++;
    self.services.masterService.getDeliverableTypes({ active: active })
      .subscribe((response) => {
        self.loading--;
        if (response && response.result) {
          self.options['deliverable_type'] = response.result;
        }
      });
  }

  getMultipleSelectValues(self, field: string, metric = null): any {
    if (field == 'deliverable_type' && metric) {
      const values = self.filterForm.get(field).value;
      const result = [];
      metric.deliverable_types.forEach((deliverableType) => {
        const index = self.services.commonService.objectIndexOf(values ? values : [], deliverableType);
        if (index >= 0) {
          result.push(deliverableType);
        }
      });
      return result;
    } else if (field == 'type') {
      const typeArr = self.filterForm.get('type').value;
      if (typeArr && typeArr.length == 1) {
        typeArr.forEach((element) => {
          if (element.name === 'Project' || element.name === 'Success Story') {
            self.filterForm.get('deliverable_type').value = [];
            self.isShowDeliverableType = true;
          } else {
            self.isShowDeliverableType = false;
          }
        });
      }
    }
    return self.filterForm.get(field).value;
  }

  onAddMultipleSelectValue(self, field: string, event: MatAutocompleteSelectedEvent, metric = null, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
    }
    let input = document.getElementById(field);
    if (metric) {
      input = document.getElementById(field + '_' + metric._id);
    }
    const index = self.services.commonService.objectIndexOf(values, event.option.value, key);
    if (index < 0) {
      values.push(event.option.value);
      if (field === 'deliverable_type') {
        self.addAllOfDeliverableTypes.push(event.option.value);
      }
    }
    input['value'] = '';
    input.blur();
    self.filterForm.get(field).setValue(values);
  }

  onRemoveMultipleSelectValue(self, field: string, value: any, key = '_id'): void {
    let values = self.filterForm.get(field).value;
    if (!values) {
      values = [];
      self.addAllOfDeliverableTypes = [];
    }
    const index = self.services.commonService.objectIndexOf(values, value, key);
    if (index >= 0) {
      values.splice(index, 1);
    }
    if (values.length > 0 && field === 'deliverable_type') {
      const allList = values.map((o) => self.addAllOfDeliverableTypes.filter((i) => i.name === o.name && i._id != value._id));
      self.addAllOfDeliverableTypes = allList.reduce((a, b) => { return a.concat(b); });
    } else if (field === 'deliverable_type') {
      self.addAllOfDeliverableTypes = [];
    }
    self.filterForm.get(field).setValue(values);
    if (self.filterSectionKey === 'select-deliverables-filter') {
      self.relatedDeliveralesFilter[field] = values;
    }
  }

  hasFilter(self) {
    let res = false;
    for (const item in self.filter) {
      if (self.filter[item] && self.filter[item].length) {
        res = true;
      } else if (item == 'eac_qrtr' && self.filter[item]) {
        res = true;
      }
    }
    return res;
  }
}