/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */

import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Services } from '@app/services/services';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { User, Messages, WorkflowStatus } from '@app/models';
import { debounceTime } from 'rxjs/operators';
import * as uuid from 'uuid';
@Component({
  selector: 'app-story-image-dialog',
  templateUrl: './story-image-dialog.component.html',
  styleUrls: ['./story-image-dialog.component.scss']
})
export class StoryImageDialogComponent implements OnInit {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  public deliverablesList;

  public columns: string[] = ['checked', 'asset'];
  selectedStory: Array<any> = [];
  results: Array<any> = [];
  storyImage: any = {};
  loading: any = 0;
  imageForm: UntypedFormGroup;
  image_url: any = null;
  maxFileSize: any = false;
  file: any = null;
  isFile = false;
  storyImageid: any = '';
  isPop = false;
  image_type: any = 'new';
  imgFile: any = null;
  image_description: any = '';
  constructor(
    public el: ElementRef,
    public dialogRef: MatDialogRef<StoryImageDialogComponent>,
    private cd: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    public services: Services
  ) { }
  ngOnInit(): void {
    // this.selectedStory = this.services.commonService.getSessionData('GET_SELECTED_STORY_IMAGE');
    // this.imageForm = this.formBuilder.group({
    //   image_description: [{ value: this.image_description, disabled: false }],
    //   image: null
    //   // locationLead: [{ value: this.input, disabled: false }, Validators.required]
    // });
    this.createForm(this);
    this.image_url = this.services.deliverableCommonService.defaultDeliverableImage;
  }

  onCancel(): void {
    this.dialogRef.close();
  }
  getImagePreview(files: any): void {
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    const fileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    if (!fileTypes.includes(mimeType)) {
      this.isPop = true;
      this.maxFileSize = false;
      return;
    }
    this.isPop = false;
    const limit = 5 * 1024 * 1024;
    if (files[0].size > limit) {
      this.maxFileSize = true;
      this.file = null;
      return;
    } else {
      this.maxFileSize = false;
    }
    this.file = files[0];
    this.isFile = false;
    this.imageForm.markAsDirty();
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.image_url = reader.result;
    };
    this.imgFile = this.file;
  }
  get Messages(): any { return Messages; }
  onSave(): void {
    if (!this.file) {
      this.isFile = true;
      return;
    }

    this.storyImageid = uuid.v4();
    this.dialogRef.close(this.file);
    this.services.storyCommonService.isImageNeedToRefresh.next(true);
  }
  changeLocationValue(value) {
    this.image_description = value;
  }
  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
  }
  replacePhoto() {
    // Your code to handle the photo replacement functionality
    // For example, triggering a click on the file input to open the file picker
    const fileInput = document.getElementById('deliverable_image');
    if (fileInput) {
      fileInput.click();
    }
  }

  // _afterCreateForm() {
  //   this.imageForm.get('image_description').valueChanges
  //     .pipe(
  //       debounceTime(200)
  //     ).subscribe(() => {

  //     });
  // }

  createForm(self): void {
    this.imageForm = this.formBuilder.group({
      image_description: [{ value: this.image_description, disabled: false }],
      image: null
      // locationLead: [{ value: this.input, disabled: false }, Validators.required]
    });
    // self._afterCreateForm();
  }
}
