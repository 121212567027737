/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AddNewWorkstreamComponent } from '@app/dialog/add-new-workstream/add-new-workstream/add-new-workstream.component';
import { DisableGroupPopupComponent } from '@app/dialog/disable-group-popup/disable-group-popup.component';
import { Messages } from '@app/models';
import { CommonService } from '@app/services';
import { MasterService } from '@app/services/master.service';
import { WorkstreamService } from '@app/services/workstream.service';
import 'rxjs/add/observable/of';

@Component({
  selector: 'app-workstream-view',
  templateUrl: './workstream-view.component.html',
  // styleUrls: ['./workstream-view.component.scss']
})
export class WorkstreamViewComponent implements OnInit {
  public workStreamList;
  public columns: string[] = ['checked', 'name', 'workstream_group', 'workstream_lead_name'];
  public headersData = ['', 'Workstream', 'Group', 'Workstream Lead'];
  public selectedOption = 'All';
  public checkedCount = 0;
  public editSelectedAdmin = false;
  public selectedWorkstreams: string[];
  public search = '';
  public checkedAdmin: string;
  public placeHolder;
  public idList = [];
  public workstreamCount = [];
  public totalWorkstreams = [];
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public tableOrder = 'asc';
  public groups = [];
  public loadVal = 0;
  public enabledValue;
  public orgTypeName = 'General';
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private workstreamService: WorkstreamService,
    private masterService: MasterService
  ) { }
  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.getWorkstreams();
    this._getGroups();
  }

  getWorkstreams(): void {
    this.loadVal++;
    this.workStreamList = [];
    let params = {};
    if (this.selectedOption === 'All') {
      params = { active: 'All' };
    } else if (this.selectedOption === 'Enabled') {
      params = { active: true };
    } else if (this.selectedOption === 'Disabled') {
      params = { active: false };
    }
    params['orgTypeName'] = this.orgTypeName;
    this.workstreamService.getWorkStreams(params).subscribe((response) => {
      if (response && response.result) {
        response.order == 1 ? this.tableOrder = 'asc' : this.tableOrder = 'desc';
        this.workStreamList = response.result;
        this.workStreamList.forEach((element) => {
          element['checked'] = false;
          element['active'] = !element.archive_ind;
          let temp_groups = '';
          element.groups.forEach((group) => {
            temp_groups += group.name + ',';
          });
          if (element.workstream_lead) {
            element['workstream_lead_name'] = element.workstream_lead.display_name;
          }
          element['workstream_group'] = temp_groups.substring(0, temp_groups.length - 1);
        });
        Object.assign(this.totalWorkstreams, this.workStreamList);
        this.enabledValue = this.workStreamList;
        this.workStreamList = new MatTableDataSource(this.workStreamList);
        this.workStreamList._filter.next(this.search);
        if (this.search.trim() == '') {
          this.workstreamCount = [`${this.workStreamList.filteredData.length} Entries`, ''];
        }
        else {
          this.workstreamCount = [`${this.workStreamList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
        }
        this.loadVal--;
      }
    }, (error1) => {
      this.loadVal--;
      this.commonService.handleError(error1);
    });
  }

  getSelectedOption(selected): void {
    this.selectedWorkstreams = [];
    this.checkedCount = 0;
    this.editSelectedAdmin = false;
    this.checkedAdmin = selected.group_name;
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.idList = [];
    this.workStreamList.filteredData.forEach((element) => {
      if (element.checked === true) {
        this.checkedCount++;
        this.selectedWorkstreams.push(element.group_name);
        this.totalWorkstreams.forEach((item) => {
          if (item.name === element.name) {
            this.idList.push({ '_id': item._id });
          }
        });
      }
    });
    if (this.checkedCount > 0) {
      this.editSelectedAdmin = true;
    }
  }
  resetSelected(): void {
    this.selectedWorkstreams = [];
    this.checkedCount = 0;
    this.editSelectedAdmin = false;
    this.placeHolder = { status: this.statusPlaceholder, action: this.actionPlaceholder };
    this.idList = [];
    this.workStreamList.filteredData.forEach((element) => {
      element['checked'] = false;
    });
  }
  getEnableDisableStatus(value): void {
    this.selectedOption = value;
    this.getWorkstreams();
  }

  getSearchInputValue(searchInput): void {
    this.search = searchInput;
    this.workStreamList._filter.next(this.search);
    if (this.search.trim() == '') {
      this.workstreamCount = [`${this.workStreamList.filteredData.length} Entries`, ''];
    }
    else {
      this.workstreamCount = [`${this.workStreamList.filteredData.length} of ${this.enabledValue.length} Entries`, ''];
    }
  }

  setStatus(value): void {
    this.selectedOption = value;
    if (value === 'Enabled') {
      this.loadVal++;
      const params = { disable: !(value === 'Enabled') };
      this.workstreamService.changeWorkstreamStatus(params, this.idList)
        .subscribe((response) => {
          if (response) {
            this.loadVal--;
            this.getWorkstreams();
            this.editSelectedAdmin = false;
          }
        }, (error1) => {
          this.loadVal--;
          this.commonService.handleError(error1);
        });
    }
    else {
      const editData = { 'group_id': this.idList, 'type': 'workstream', 'popup_content': Messages.WORKSTREAMS_POPUP_CONTENT };
      const dialogRef = this.dialog.open(DisableGroupPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.loadVal++;
          const params = { disable: !(value === 'Enabled') };
          this.workstreamService.changeWorkstreamStatus(params, this.idList)
            .subscribe((response) => {
              if (response) {
                this.loadVal--;
                this.getWorkstreams();
                this.editSelectedAdmin = false;
              }
            }, (error1) => {
              this.loadVal--;
              this.commonService.handleError(error1);
            });
        }
        else {
          this.resetSelected();
        }
      });
    }
  }


  addNewWorkstream(add): void {
    if (add) {
      const editData = { 'groups': this.groups, 'orgTypeName': this.orgTypeName };
      const dialogRef = this.dialog.open(AddNewWorkstreamComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.WORKSTREAM_ADDED_SUCCESS);
          this.getWorkstreams();
        }
      });
    }
  }
  getSelectedOrder(value): void {
    this.loadVal++;
    const params = { collection_name: 'workstreams', order: value, field_name: 'name' };
    this.masterService.saveOrder(params)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.WORKSTREAM_ORDER_SAVE_SUCCESS);
        }
      }, (error1) => {
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }
  getClickedItem(value): void {
    if (value) {
      const editData = { 'groups': this.groups, 'value': value ,orgTypeName: this.orgTypeName};
      const dialogRef = this.dialog.open(AddNewWorkstreamComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.WORKSTREAM_EDITED_SUCCESS);
          this.getWorkstreams();
        }
      });
    }
  }
  _getGroups(): void {
    this.masterService.getGroups({ 'orgTypeName': this.orgTypeName })
      .subscribe((response) => {
        if (response && response.result) {
          this.groups = response.result;
        }
      });
  }
}
