<div fxFlex fxLayout="column" class="main-page">
  <div fxLayout="column" class="toolbar-header-row">
    <div fxLayout="row" class="toolbar-header-nav">
      <ul class="nav">
        <li routerLinkActive="active">
          <a id="drafts" routerLink="/deliverables/list/drafts">Drafts<span
              *ngIf="draftCount">&nbsp;({{draftCount}})</span></a>
        </li>
        <li routerLinkActive="active">
          <a id="live" routerLink="/deliverables/list/live">Live<span *ngIf="liveCount">
              &nbsp;({{liveCount}})</span></a>
        </li>
      </ul>
      <div fxFlex></div>

      <div fxLayout="row" fxLayoutGap="10px">
        <button id="btn_settingMenu" mat-button color="primary" class="setting-btn" [matMenuTriggerFor]="setingMenu">
          <div class="btn-inner">
            <span>
              <span id="s_filtersetting" class="setting-value">Bookmark</span>
            </span>
            <span class="icon ic-dropdown"></span>
          </div>
        </button>
        <mat-menu #setingMenu="matMenu" class="live-menu sort-menu">
          <button id="btn_savesetting" mat-menu-item (click)="onSaveSetting()">
            Save Filter Settings
          </button>
          <button id="btn_loadsetting" mat-menu-item (click)="onLoadSetting()">
            Load Filter Settings
          </button>
        </mat-menu>
        <form fxLayout="row" class="search_wrap" (ngSubmit)="onSearchSubmit()">
          <input id="searchDeliverables" placeholder="Search deliverables…" name="search" [(ngModel)]="search"
            aria-label="searchDeliverables" />
          <button id="searchDeliverablesButton1" type="submit" *ngIf="!pagination.search || pagination.search != search"
            aria-label="search">
            <span class="icon ic-search"></span>
          </button>
          <button id="searchDeliverablesButton2" *ngIf="pagination.search && pagination.search == search"
            (click)="search=null;onSearchSubmit()" aria-label="search pagination">
            <span class="icon ic-close"></span>
          </button>
        </form>
        <button id="sort" mat-button color="primary" class="sort-btn" [matMenuTriggerFor]="sortMenu">
          <div class="btn-inner">
            <span>
              <span id="s_sort">Sort : </span>
              <span id="s_workflowStatus" class="sort-value" *ngIf="pagination.sort == 'workflow_status'">Workflow
                Status </span>
              <span id="s_mostRecent" class="sort-value" *ngIf="pagination.sort == 'last_updated_date'">Most
                Recent</span>
              <span id="s_alphabetical" class="sort-value" *ngIf="pagination.sort == 'name'">Alphabetical</span>
              <span id="s_sort_dir">({{pagination.sort_dir}})</span>
            </span>
            <span id="sortIcon" class="icon ic-dropdown"></span>
          </div>
        </button>
        <mat-menu #sortMenu="matMenu" class="live-menu sort-menu">
          <button id="workflowStatus" mat-menu-item (click)="onSort('workflow_status')">
            Workflow Status
            <span id="workflowStatusIcon" class="icon ic-dropdown" *ngIf="pagination.sort == 'workflow_status'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="mostRecent" mat-menu-item (click)="onSort('last_updated_date')">
            Most Recent
            <span id="mostRecentIcon" class="icon ic-dropdown" *ngIf="pagination.sort == 'last_updated_date'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
          <button id="alphabetical" mat-menu-item (click)="onSort('name')">
            Alphabetical
            <span id="alphabeticalIcon" class="icon ic-dropdown" *ngIf="pagination.sort == 'name'">
              <span
                [ngClass]="{'ic-sort_up' : pagination.sort_dir === 'asc', 'ic-sort_down' : pagination.sort_dir !== 'asc' }"></span>
            </span>
          </button>
        </mat-menu>
        <button id="filter" mat-button class="filter-btn" color="primary" (click)="onOpenFilter()" [ngClass]="{'open': showFilter}">
          <span class="icon ic-filter"></span> Filter
        </button>
      </div>
    </div>
    <div fxLayout="row" *ngIf="setting" class="show-save-setting">
      <label id="delAppliedFilters"> Showing Bookmark:</label>
      <div fxLayout="column" class="show-name">
        {{setting.name}}
      </div>
    </div>
    <app-deliverable-filter #delFilter sectionKey="deliverable-list-filter" [isDraft]="!isLive" [show]="showFilter"
      (onChange)="onFilter($event)"></app-deliverable-filter>

  </div>
  <div fxFlex fxLayout="column" class="list-row" id="deliverable-list-row">
    <div id="loading" fxFlex fxLayout="row" *ngIf="loading" class="loading-wrap">
      <span>Loading...</span>
    </div>
    <div id="noResults" role="alert" aria-live="assertive" fxFlex fxLayout="row" *ngIf="!loading && !deliverables.length" class="loading-wrap">
      <span>No results</span>
    </div>
    <div fxLayout="column" fxLayoutGap="20px" *ngIf="!loading" class="list-items">
      <ng-container *ngFor="let deliverable of deliverables;let i=index">
        <app-deliverable-list-view id="deliverableComp{{i}}" [deliverable]="deliverable" [action]="action"
          (onClick)="onDeliverableClick($event)" (update)="onUpdate()"></app-deliverable-list-view>
      </ng-container>
    </div>
    <mat-paginator [length]="totalRecords" [pageSize]="pagination.page_size" [pageIndex]="pagination.page_number"
      [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
  </div>
</div>