/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */

import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Services } from '@app/services/services';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { User, Messages, WorkflowStatus } from '@app/models';
import { debounceTime } from 'rxjs/operators';
import * as uuid from 'uuid';
@Component({
    selector: 'app-icon-icon-dialog',
    templateUrl: './story-icon-dialog.component.html',
    styleUrls: ['./story-image-dialog.component.scss']
})
export class StoryIconDialogComponent implements OnInit {
    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
    @ViewChild('validForm') validForm: ElementRef;
    public deliverablesList;

    public columns: string[] = ['checked', 'asset'];
    selectedStory: Array<any> = [];
    results: Array<any> = [];
    storyImage: any = {};
    loading: any = 0;
    iconForm: UntypedFormGroup;
    image_url: any = null;
    maxFileSize: any = false;
    file: any = null;
    isFile = false;
    isPop = false;
    storyImageid: any = '';
    isDescription = false;
    image_type: any = 'new';
    imgFile: any = null;

    constructor(
        public el: ElementRef,
        public dialogRef: MatDialogRef<StoryIconDialogComponent>,
        private cd: ChangeDetectorRef,
        private formBuilder: UntypedFormBuilder,
        public services: Services
    ) { }
    ngOnInit(): void {
        // this.selectedStory = this.services.commonService.getSessionData('GET_SELECTED_STORY_IMAGE');
        // this.iconForm = this.formBuilder.group({
        //   image_description: [{ value: this.image_description, disabled: false }],
        //   image: null
        //   // locationLead: [{ value: this.input, disabled: false }, Validators.required]
        // });
        this.createLiquidForm(this);
        this.image_url = this.services.deliverableCommonService.defaultDeliverableImage;
    }

    onCancel(): void {
        this.dialogRef.close();
    }
    getImagePreview(files: any): void {
        if (files.length === 0)
            return;

        const mimeType = files[0].type;
        const fileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
        if (!fileTypes.includes(mimeType)) {
            this.isPop = true;
            this.maxFileSize = false;
            return;
        }
        this.isPop = false;
        const limit = 5 * 1024 * 1024;
        if (files[0].size > limit) {
            this.maxFileSize = true;
            return;
        } else {
            this.maxFileSize = false;
        }
        this.file = files[0];
        this.isFile = false;
        // this.catalogueForm.markAsDirty();
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.image_url = reader.result;
        };
        this.imgFile = this.file;
    }
    get Messages(): any { return Messages; }
    onSave(): void {
        if (!this.file) {
            this.isFile = true;
            // return;
        }
        this.storyImageid = uuid.v4();
        this.dialogRef.close(this.file);
        this.services.storyCommonService.isImageNeedToRefresh.next(true);
    }

    public noWhitespaceValidator(control: UntypedFormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    createLiquidForm(self): void {
        this.iconForm = this.formBuilder.group({
            // image_description: [{ value: this.image_description, disabled: false }],
            image: null
            // locationLead: [{ value: this.input, disabled: false }, Validators.required]
        });
        //  self._afterCreateForm();
    }
    replacePhoto() {
        // Your code to handle the photo replacement functionality
        // For example, triggering a click on the file input to open the file picker
        const fileInput = document.getElementById('deliverable_image');
        if (fileInput) {
          fileInput.click();
        }
      }
}
