export enum Messages {
    API_ERROR_CODE = 500,
    HTTP_STATUS_CODE_200 = 200,
    HTTP_STATUS_CODE_401 = 401,
    HTTP_STATUS_CODE_403 = 403,
    HTTP_STATUS_CODE_404 = 404,
    GROUP_POPUP_CONTENT = '<p>Disabling this Group will lock all Deliverables associated with the group.  As a result, these Deliverables will no longer be editable.  For any items that are not complete and will be realigned with a new group, please edit and change the Group / Workstream / Project associations prior to making the Group inactive.</p><p>The impacted Deliverables will be listed in the attached CSV for your assessment.  Workstreams and Projects associated with a Group are also listed and should be reviewed and if necessary, also be realigned to a new Group.</p><p>Once you have confirmed that all Deliverables and other objects needing realignment have been addressed, you may click confirm to continue.</p><p><b>Note- </b>Once confirmed, if you later identify an issue, a group may be switched back to “active” status to allow for remediation.</p>',
    INITIATIVES_POPUP_CONTENT = '<p>Disabling this Initiative will mean no additional deliverables may be tagged to the Initiative.   Any deliverables that are not complete will need to be removed from the Initiative being disabled.  If appropriate, deliverables may be aligned with a new Initiative, although it is not required.  Please review the Initiative alignment for any deliverables still in progress and make the appropriate updates.</p><p>The impacted Deliverables will be listed in the attached CSV for your assessment.  Projects associated with an Initiative are also listed and should be reviewed and the initiative alignment removed or changed to eliminate the Initiative being disabled.</p><p>Once you have confirmed that all Deliverables and other objects needing realignment have been addressed, you may click confirm to continue.</p><p><b>Note- </b>Once confirmed, if you later identify an issue, an Initiative may be switched back to “active” status to allow for remediation.</p>',
    WORKSTREAMS_POPUP_CONTENT = '<p>Disabling this Workstream will lock all Deliverables associated with the workstream.  As a result, these Deliverables will no longer be editable.  For any items that are not complete and will be realigned with a new workstream, please edit and change the Group / Workstream / Project associations prior to making the Workstream inactive.</p><p>The impacted Deliverables will be listed in the attached CSV for your assessment.  Projects associated with a Workstream are also listed and should be reviewed and if necessary, also be realigned to a new Workstream .</p><p>Once you have confirmed that all Deliverables and other objects needing realignment have been addressed, you may click confirm to continue.</p><p><b>Note- </b>Once confirmed, if you later identify an issue, a workstream may be switched back to “active” status to allow for remediation.</p>',
    CATEGORIES_POPUP_CONTENT = '<p>Disabling this Category will lock all Deliverables associated with the category.  As a result, these Deliverables will no longer be editable.  For any items that are not complete and will be realigned with a new category, please edit and change the Group / Category / Project associations prior to making the Category inactive.</p><p>The impacted Deliverables will be listed in the attached CSV for your assessment.  Projects associated with a Category are also listed and should be reviewed and if necessary, also be realigned to a new category .</p><p>Once you have confirmed that all Deliverables and other objects needing realignment have been addressed, you may click confirm to continue.</p><p><b>Note- </b>Once confirmed, if you later identify an issue, a category may be switched back to “active” status to allow for remediation.</p>',
    REVIEW_REQUESTED_SUCCESS = 'Your deliverable has been sent for review',
    PUBLISH_REQUESTED_SUCCESS = 'Your deliverable has been sent for publishing',
    STORY_PUBLISH_REQUESTED_SUCCESS = 'Your success story has been sent for publishing',
    REASSIGN_SUCCESS = 'Deliverable reassigned',
    APPROVE_SUCCESS = 'Deliverable approved',
    PUBLISH_SUCCESS = 'Deliverable published',
    STORY_PUBLISH_SUCCESS = 'Success Story published',
    SAVE_SUCCESS = 'Deliverable saved successfully',
    UPDATE_SUCCESS = 'Deliverable updated successfully',
    PROJECT_SAVE_SUCCESS = 'Project saved successfully',
    PROJECT_UPDATE_SUCCESS = 'Project updated successfully',
    STORY_SAVE_SUCCESS = 'Story saved successfully',
    STORY_UPDATE_SUCCESS = 'Story updated successfully',
    STORY_REASSIGN_SUCCESS = 'Success story reassigned',
    DISCARD_MESSAGE = 'You have not saved your current work. Do you want to discard and proceed?',
    REPORTING_SETTING_SAVE_SUCCESS = 'Setting saved successfully',
    SETTINGS_SAVE_SUCCESS = 'Settings saved successfully',
    KEYWORD_HINT = 'Search for an existing keyword or type in a new keyword and press enter',
    OWNER_HINT = 'Use Accenture Enterprise Id for name selection',
    OWNER = 'The person identified here will see this entry in their"My Success Stories" section. This is usually the person who creates the success story although the owner can be changed if needed. The Owner is not displayed on the Success Story export.ppt slide',
    CONTACT = 'These names are used to populate the "Contacts" section of the Success Story export .ppt slide.Maximum of 3 EIDs',
    ADDITIONAL_TEAM_MEMBERS = 'These are usually members involved with the creation of the Success Story. People included here will see this entry in their My Success Story section. These names are not displayed on the Success Story export.ppt slide',
    RICH_TEXT_HINT = 'The value exceeds maximum 750',
    IMAGE_TEXT_HINT = 'The image exceeds maximum 3',
    LOGO_TEXT_HINT = 'The image exceeds maximum 10',
    ORGANIZATION_HINT = 'Please select an organization to load group or location',
    URL_ERROR = 'Please enter a valid URL in the following format http(s)://www.url.xxx',
    GROUP_ERROR = 'The value exceeds maximum 128',
    END_QTR_ERROR = 'END QTR cannot be earlier than START QTR',
    REPORTING_QTR_ERROR = 'To date cannot be earlier than From date',
    FILE_LIMIT_MESSAGE = 'File exceeds the maximum allowed size of 5MB',
    FILE_TOTAL_MESSAGE = 'The total size of logos and pictures should be less than 5M.',
    PICTURES_FORM_MESSAGE = 'Only .png, .jpg and .jpeg format allowed!',

    EDIT_ERROR_MESSAGE = 'You cannot edit this deliverable',
    EDIT_SUCCESS_STORY_ERROR_MESSAGE = 'You cannot edit this success story',
    EDIT_PROJECT_ERROR_MESSAGE = 'You cannot edit this project',
    REMINDER_SUCCESS = 'Reminder sent successfully',
    PROJECT_NAME_UNIQUE_ERROR = 'This name already exist',
    UNSAVED_DRAFT_MESSAGE = 'You have unsaved deliverable data in draft. Do you want to recover this?',
    UNSAVED_PROJECT_DRAFT_MESSAGE = 'You have unsaved project data in draft. Do you want to recover this?',
    UNSAVED_STORY_DRAFT_MESSAGE = 'You have unsaved story data in draft. Do you want to recover this?',
    HELP_REF = 'https://in.accenture.com/innovation/scaleinnovation/',
    CONTACT_US_MAIL_TO = 'si.support@accenture.com',
    CONTACT_US_MAIL_SUBJECT = 'Accenture SI Support',
    CONTACT_US_MAIL_BODY = '',

    RECOMMEND_MAIL_SUBJECT = 'A ScaleInnovation Deliverable is being recommended for you to review',
    RECOMMEND_MAIL_BODY_1 = 'Go through this link',
    RECOMMEND_MAIL_BODY_2 = 'for further details',
    RECOMMEND_PROJECT_MAIL_SUBJECT = 'A ScaleInnovation Project is being recommended for you to review',
    RECOMMEND_STORY_MAIL_SUBJECT = 'A ScaleInnovation Success Story is being recommended for you to review',

    OWNER_MAIL_SUBJECT = 'Deliverable owner',
    OWNER_MAIL_BODY_1 = 'Go through this link',
    OWNER_MAIL_BODY_2 = 'for further details',

    GROUP_LEAD_MAIL_SUBJECT = 'Deliverable group lead',
    GROUP_LEAD_MAIL_BODY_1 = 'Go through this link',
    GROUP_LEAD_MAIL_BODY_2 = 'for further details',

    ADMIN_ADDED_SUCCESS = 'Admin added successfully',
    ADMIN_DELETED_SUCCESS = 'Admin deleted successfully',

    GROUP_ADDED_SUCCESS = 'Group added successfully',
    GROUP_EDITED_SUCCESS = 'Group edited successfully',
    WORKSTREAM_ADDED_SUCCESS = 'Workstream added successfully',
    WORKSTREAM_EDITED_SUCCESS = 'Workstream edited successfully',

    CATEGORYADDED_SUCCESS = 'Categroy added successfully',
    CATEGORYADDED_EDITED_SUCCESS = 'Categroy edited successfully',

    INITIATIVE_ADDED_SUCCESS = 'Initiative added successfully ',
    LOCATION_ADDED_SUCCESS = 'Location added successfully ',
    FUNCTION_ADDED_SUCCESS = 'Function added successfully ',
    INDUSTRY_ADDED_SUCCESS = 'Industry added successfully ',
    GROUP_LOCATION_ADDED_SUCCESS = 'Group Location added successfully',
    DELIVERABLE_TYPE_ADDED_SUCCESS = 'Deliverable Type added successfully',
    INITIATIVE_EDITED_SUCCCESS = 'Initiative edited successfully',
    FUNCTION_EDITED_SUCCCESS = 'Function edited successfully',
    INDUSTRY_EDITED_SUCCCESS = 'Industry edited successfully',
    LOCATION_EDITED_SUCCCESS = 'Location edited successfully',
    DELIVERABLE_TYPE_EDITED_SUCCCESS = 'Deliverable Type edited successfully',
    GROUP_LOCATION_EDITED_SUCCESS = 'Group Location edited successfully',
    GROUP_ORDER_SAVE_SUCCESS = 'Group order saved successfully',
    GROUP_LOCATION_LEAD_ORDER_SAVE_SUCCESS = 'Group location lead order saved successfully',
    INITIATIVE_ORDER_SAVE_SUCCESS = 'Initiative order saved successfully',
    WORKSTREAM_ORDER_SAVE_SUCCESS = 'Workstream order saved successfully',
    CATEGORY_ORDER_SAVE_SUCCESS = 'Category order saved successfully',
    PARTNER_ORDER_SAVE_SUCCESS = 'Partner order saved successfully',
    PARTNER_ADDED_SUCCESS = 'Partner added successfully',
    PARTNER_EDITED_SUCCESS = 'Partner edited successfully',
    METRIC_ORDER_SAVE_SUCCESS = 'Metric order saved successfully',
    METRIC_ADDED_SUCCESS = 'Metric added successfully',
    METRIC_EDITED_SUCCESS = 'Metric edited successfully',
    PROJECT_ORDER_SAVE_SUCCESS = 'Project order saved successfully',
    PROJECT_ADDED_SUCCESS = 'Project added successfully',
    PROJECT_EDITED_SUCCESS = 'Project edited successfully',
    FUNCTION_ORDER_SAVE_SUCCESS = 'Function order saved successfully',
    INDUSTRY_ORDER_SAVE_SUCCESS = 'Industry order saved successfully',
    STATUS_ORDER_SAVE_SUCCESS = 'Status order saved successfully',
    STATUS_ADDED_SUCCESS = 'Status added successfully',
    STATUS_EDITED_SUCCESS = 'Status edited successfully',
    STATUS_DELETED_SUCCESS = 'Status deleted successfully',
    LOCATION_ORDER_SAVE_SUCCESS = 'Location order saved successfully',
    DELIVERABLE_TYPE_ORDER_SAVE_SUCCESS = 'Deliverable Type order saved successfully',
    STATUS_NOT_EDITABLE = 'In-Progress status is not editable',

    DESCRIPTION_LENGTH_LIMIT_MESSAGE = 'The value exceeds maximum 4096',
    ADVANCE_STAGE_SUCCESS = 'Advanced Stage Successfully',
    STAGE_EDITED_SUCCESS = 'Stage edited successfully',

    PROJECT_REVIEW_REQUESTED_SUCCESS = 'Your project has been sent for review',
    PROJECT_REASSIGN_SUCCESS = 'Project reassigned',
    PROJECT_APPROVE_SUCCESS = 'Project approved',

    SESSION_EXPIRED_DATA_MESSAGE = 'Your session has expired. Please wait, we are logging you back in.',
    UNEXPECTED_ERROR_DATA_MESSAGE = 'Unexpected error occurred. Please refresh your page or relogin our system, and contact si.support@accenture.com if the error persists.',
    BAD_NETWORK_ERROR_MESSAGE = 'The server encountered a temporary error and could not complete your request. Please refresh your page or relogin our system, and contact si.support@accenture.com if the error persists.',

    CLIENT_HINT = 'Search by client name or SAP account ID, minimum 3 characters',
    PARTNER_HINT = 'Required for vendor and university relationships'

}
