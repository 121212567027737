/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { of, Observable } from 'rxjs';
export class GroupLocationLeadService {
  getGroupLocationLeads(params): Observable<any> {
    return of(params);
  }

  addGroupLocationLead(reqbody): Observable<any> {
    return of(reqbody);
  }
  editGroupLocationLead(params, reqBody): Observable<any> {
    return of(reqBody);
  }
  updateGroupLocationLeadStatus(params, reqBody): Observable<any> {
    return of(reqBody);
  }
}