/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { Role } from '@app/models';

import { AuthenticationService, CommonService, DeliverableCommonService, MasterService, ProjectsCommonService, StoryCommonService } from '@app/services';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationTypePopupComponent } from '@app/dialog/organization-type-popup/organization-type-popup.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  route: string;
  profileImages: any = {};
  constructor(
    private location: Location,
    private router: Router,
    public dialog: MatDialog,
    private authService: AuthenticationService,
    private deliverableCommonService: DeliverableCommonService,
    private projectsCommonService: ProjectsCommonService,
    private storyCommonService: StoryCommonService,
    private masterService: MasterService,
    public commonService: CommonService
  ) {
    this.router.events.subscribe(() => {
      if (this.location.path() !== '') {
        this.route = location.path();
      } else {
        this.route = '';
      }
    });
  }
  get Role(): any { return Role; }

  createDeliverable(): void {
    this.deliverableCommonService.openDialog(this, OrganizationTypePopupComponent, 'deliverable', '538px', '', 'organization_type');
  }

  createProject(): void {
    this.projectsCommonService.openDialog(this, OrganizationTypePopupComponent, 'project', '538px', '', 'organization_type');
  }

  createStory(): void {
    this.storyCommonService.openDialog(this, OrganizationTypePopupComponent, 'story', '538px', '', 'organization_type');
  }

  redirectTo(uri: string): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate([uri]));
  }

  isChildOf(path: string): boolean {
    if (!this.route) {
      if (this.location.path() !== '') {
        this.route = this.location.path();
      } else {
        this.route = '';
      }
    }
    const pattern = new RegExp(`^\/(${path})`);
    return pattern.test(this.route);
  }

  getCurrentUserProfileImage() {
    const loginUser = this.authService.currentUserValue;
    return this._getProfileImage(loginUser ? loginUser.eso_id : null);
  }

  _getProfileImage(userId: any): string {
    if (userId) {
      if (this.profileImages[userId] && this.profileImages[userId] != 'loading') {
        return this.profileImages[userId];
      } else if (this.profileImages[userId] != 'loading') {
        this.profileImages[userId] = 'loading';
        this.masterService.getImage(userId, 'profile')
          .subscribe((response) => {
            if (response && response.size > 0) {
              const reader = new FileReader();
              reader.readAsDataURL(response);
              reader.onload = () => {
                this.profileImages[userId] = reader.result;
              };
            }
          });
      }
    }
    return this.deliverableCommonService.defaultProfileImage;
  }
}
