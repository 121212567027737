/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectsService } from '@app/services';

@Component({
  selector: 'app-project-advance-stage-dialog',
  templateUrl: 'project-advance-stage-dialog.html',
  // styleUrls: ['./project-dialog.components.scss']
})
export class AdvanceStageDialog implements OnInit {
  loading: any = 0;
  project: any = null;
   advanceStage: any = null
  submitted: any = false;
  constructor(
    public dialogRef: MatDialogRef<AdvanceStageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public projectsService: ProjectsService
  ) { }

  ngOnInit(): void {
   this.project=this.data.project;
   this.advanceStage=this.data.advanceStage;
  }


  onCancel(): void {
    this.dialogRef.close();
  }

  onAdvanceStage(): void {
    this.submitted = true;
    if (!this.loading) {
      this.loading++;
      this.projectsService.advanceStage(this.project._id)
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.dialogRef.close(true);
          }
        });
    }
  }
}

@Component({
  selector: 'app-project-review-request-dialog',
  templateUrl: 'project-review-request-dialog.html',
  // styleUrls: ['./project-dialog.components.scss']
})
export class ProjectReviewRequestDialog implements OnInit {
  loading: any = 0;
  reviewers: Array<any> = [];
  requestForm: UntypedFormGroup;
  submitted: any = false;
  constructor(
    public dialogRef: MatDialogRef<ProjectReviewRequestDialog>,
    @Inject(MAT_DIALOG_DATA) public project: any,
    public projectService: ProjectsService,
    private formBuilder: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this._getReviewers();
    this.requestForm = this.formBuilder.group({
      recipient: [null, Validators.required],
    });
  }

  _getReviewers(): void {
    this.loading++;
    this.projectService.getReviewers(this.project._id)
      .subscribe((response) => {
        this.loading--;
        if (response && response.result) {
          this.reviewers = response.result;
        }
      });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSendRequest(): void {
    this.submitted = true;
    if (this.requestForm.valid && !this.loading) {
      this.loading++;
      const requestFormData = this.requestForm.getRawValue();
      this.projectService.reviewRequest(this.project._id, requestFormData.recipient)
        .subscribe((response) => {
          this.loading--;
          if (response) {
            this.dialogRef.close(true);
          }
        });
    }
  }
}
