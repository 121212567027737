/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

import { CommonService } from '../common.service';
import { UserService } from '../user.service';

@Injectable()
export class UserMockService implements UserService {
  constructor(private http: HttpClient, private commonService: CommonService) {
  }

  signUp(user = null): Observable<any> {
    this.commonService.log('GET:', '/users/signup', 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return of({ result: user });
  }

  getUsers(searchText: string): Observable<any> {
    searchText = searchText.toLowerCase().trim();
    this.commonService.log('GET:', `/users/search/${searchText}`, 'OPTIONS:', this.commonService.httpOptions());
    return this.http.get('/assets/json/demo/users.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          return {
            result: res.filter((option) => {
              return option['email_id'] && option['email_id'].toLowerCase().includes(searchText);
            })
          };
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getLoginUsers(): Observable<any> {
    return this.http.get('/assets/json/demo/users.json', this.commonService.httpOptions())
      .pipe(
        map(this.commonService.extractData),
        map((res: any) => {
          res = { result: res };
          return res;
        }),
        catchError((error: any) => {
          return this.commonService.handleError(error);
        })
      );
  }

  getUsersByRole(role): Observable<any> {
    return of(role);
  }

  addAdmin(esoId, role): Observable<any> {
    return of(esoId, role);
  }

  getGlobalFlags(): Observable<any> {
    this.commonService.log('GET:', '/users/getGlobalFlags', 'BODY:', {}, 'OPTIONS:', this.commonService.httpOptions());
    return of(null);
  }
}

