<div fxLayout="column">
  <div fxLayout="column" class="filter-wrap" [formGroup]="filterForm" [@slideInOut]>
    <div fxLayout="column" fxLayoutGap="10px">
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" aria-label="organization" id="organization" placeholder="Organization" class="form-control"
            [matAutocomplete]="organization" />
          <mat-autocomplete id="organization_auto" #organization="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('organization', $event)">
            <mat-option id="organization_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('organization');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="organization_list">
            <mat-chip id="organization_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('organization');let i=index"
              (removed)="onRemoveMultipleSelectValue('organization', option)">
              {{option.name}}
              <span id="organization_delete{{i}}" matChipRemove (click)="onRemoveFilter('organization', option)">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" id="group" placeholder="Group" class="form-control" [matAutocomplete]="group"
            aria-label="group" />
          <mat-autocomplete id="group_auto" #group="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('group', $event)">
            <mat-option id="group_option{{i}}" *ngFor="let option of getMultipleSelectOptions('group');let i=index"
              [value]="option">{{option.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-chip-set id="group_list">
            <mat-chip id="group_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('group');let i=index"
              (removed)="onRemoveMultipleSelectValue('group', option)">
              {{option.name}}
              <span id="group_delete{{i}}" matChipRemove (click)="onRemoveFilter('group', option)">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" id="location" placeholder="Location" class="form-control" [matAutocomplete]="location"
            aria-label="location" />
          <mat-autocomplete id="location_auto" #location="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('location', $event)">
            <mat-option id="location_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('location');let i=index" [value]="option">{{option.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-chip-set id="location_list">
            <mat-chip id="location_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('location');let i=index"
              (removed)="onRemoveMultipleSelectValue('location', option)">
              {{option.name}}
              <span id="location_delete{{i}}" matChipRemove (click)="onRemoveFilter('location', option)">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>

      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input id="keyword" type="text" placeholder="Keyword" class="form-control" [matAutocomplete]="keyword"
            (keyup)="onChangeKeyWord($event)" aria-label="keyword" />
          <mat-progress-bar *ngIf="loadKeywords" color="accent" mode="indeterminate"></mat-progress-bar>
          <mat-autocomplete id="keyword_auto" #keyword="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('keyword', $event, null)">
            <mat-option id="keyword_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('keyword', null, true);let i=index" [value]="option">
              {{option}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="keyword_list">
            <mat-chip id="keyword_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('keyword');let i=index"
              (removed)="onRemoveMultipleSelectValue('keyword', option, null)">
              {{option}}
              <span id="keyword_delete{{i}}" matChipRemove (click)="onRemoveFilter('keyword', option)">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" class="filter-set">
        <div class="form-group">
          <input type="text" aria-label="industry" id="industries" placeholder="Industry" class="form-control"
            [matAutocomplete]="industries" />
          <mat-autocomplete id="industry_auto" #industries="matAutocomplete"
            (optionSelected)="onAddMultipleSelectValue('industries', $event)">
            <mat-option id="industry_option{{i}}"
              *ngFor="let option of getMultipleSelectOptions('industries');let i=index" [value]="option">
              {{option.name}}</mat-option>
          </mat-autocomplete>
          <mat-chip-set id="industry_list">
            <mat-chip id="industry_chip{{i}}" disableRipple="true" removable="true" selectable="false"
              *ngFor="let option of getMultipleSelectValues('industries');let i=index"
              (removed)="onRemoveMultipleSelectValue('industries', option)">
              {{option.name}}
              <span id="industry_delete{{i}}" matChipRemove (click)="onRemoveFilter('industries', option)">
                <i class="ic-close"></i></span>
            </mat-chip>
          </mat-chip-set>
        </div>
      </div>
    </div>
  </div>
  <div fxLayout="row" class="related-deliverables-btn-wrap">
    <div fxLayout="row" fxLayoutGap="40px" class="filter-set">
      <div fxLayout="row" class="form-group">
        <button id="clearAllFilters" type="button" mat-stroked-button class="clear-btn" matRipple [matRippleUnbounded]="false" [matRippleColor]="'rgba(255,255,255,.3)'" 
        [matRippleCentered]="false" (click)="onClearAllFilter()">
          <span class="ic-close"></span>Clear All Filters</button>
        <button id="applyFilters" type="button" mat-flat-button class="apply-filter" color="primary" (click)="onFilter('applyFilters')">Apply Filters</button>
      </div>
    </div>
  </div>
</div>