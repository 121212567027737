/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable id-blacklist */
import { Component, OnInit, Input, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { Messages, OrgType } from '@app/models';
import { Animations } from '@app/app-animations';
import { Services } from '@app/services/services';
import { OrganizationTypeService } from '@app/services/organization-type.service';

@Component({
  selector: 'app-my-success-stories-filter',
  outputs: ['onChange'],
  templateUrl: 'my-success-stories-filter.component.html',
  // styleUrls: ['./my-success-stories-filter.component.scss'],
  animations: [
  Animations.slideInOut
  ]})
export class MySuceessStoryFilterComponent implements OnInit {
  loading: any = 0;
  filterForm: UntypedFormGroup;
  groupOrder: any = null;
  submitted: any = false;
  filterLabels: any = {
    group: 'Group',
    workstream: 'Workstream',
    location: 'Location',
    workflow_status: 'Workflow Status',
    force: 'Force Name',
    nested_themes: 'Nested Themes',
    market: 'Market'
  }
  options: any = {
    group: [],
    location: [],
    workflow_status: [],
    force: [],
    nested_themes: [],
    market: []
  };
  filter: any = {
    group: [],
    workstream: [],
    location: [],
    workflow_status: [],
    force: [],
    nested_themes: [],
    market: []
  };
  @Input('show') show: boolean;
  @Input('sectionKey') filterSectionKey: string;
  @Input('isDraft') isDraft: boolean;
  @Input('filterOptionActive') filterOptionActive: any = null;
  onChange: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    public organizationTypeService: OrganizationTypeService,
    public services: Services
  ) {

  }

  get Messages(): any { return Messages; }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      group: [[]],
      workstream: [[]],
      location: [[]],
      workflow_status: [[]],
      force: [[]],
      nested_themes: [[]],
      market: [[]]
    }

    );
    this.services.storyFilterCommonService.getGroups(this, this.filterOptionActive);
    this.services.storyFilterCommonService.getLocationsOfLq(this, this.filterOptionActive);
    this.services.storyCommonService.getWorkflowStatus(this);
    this.services.storyFilterCommonService.getForceList(this, this.filterOptionActive);
    this.services.storyFilterCommonService.getNestedThemesList(this, this.filterOptionActive);
    this.services.storyFilterCommonService.getMarketsInfo(this);
    if (this.services.commonService.getSessionData(this.filterSectionKey)) {
      this.filter = this.services.commonService.getSessionData(this.filterSectionKey);
      if (this.isDraft) {
        this.filter.workflow_status = [];
      }
    }
    this.onFilter('init');
  }


  getMultipleSelectOptions(field: string, key = 'name', onlyTypeHead = false): any[] {
    return this.services.storyFilterCommonService.getMultipleSelectOptions(this, field, key, onlyTypeHead);
  }
  getMultipleSelectValues(field: string): any {
    return this.services.storyFilterCommonService.getMultipleSelectValues(this, field);
  }

  onAddMultipleSelectValue(field: string, event: MatAutocompleteSelectedEvent, key = '_id'): void {
    this.services.storyFilterCommonService.onAddMultipleSelectValue(this, field, event, null, key);
  }
  onRemoveMultipleSelectValue(field: string, value: any, key = '_id'): void {
    this.services.storyFilterCommonService.onRemoveMultipleSelectValue(this, field, value, key);
  }


  onFilter(type) {
    this.filter = this.getFilter();
    this.filterForm.reset();
    this.services.commonService.setSessionData(this.filterSectionKey, this.filter);
    this.onChange.emit({ type: type, data: this.filter });
  }

  getFilter() {
    const values = this.filterForm.getRawValue();
    for (const item in values) {
      if (values[item] && values[item].length) {
        values[item].forEach((value) => {
          let index = -1;
          if ( item == 'market') {
            index = this.services.commonService.objectIndexOf(this.filter[item], value, null);
          }
          else{
            index = this.services.commonService.objectIndexOf(this.filter[item], value);
          }

          if (index < 0) {
            this.filter[item].push(value);
          }
        });
      }
    }
    this.filter.group = this.filter.group.map(({ _id, name }) => ({ _id, name }));
    this.filter.location = this.filter.location.map(({ _id, name }) => ({ _id, name }));
    this.filter.workstream = this.filter.workstream.map(({ _id, name }) => ({ _id, name }));
    this.filter.workflow_status = this.filter.workflow_status.map(({ _id, name }) => ({ _id, name }));
    return this.filter;
  }

  onRemoveFilter(field, option) {
    if (option) {
      let index = -1;
      if (field === 'eac_qrtr_year' || field === 'eac_qrtr_qtr' || field == 'market') {
        index = this.services.commonService.objectIndexOf(this.filter[field], option, null);
      } else {
        index = this.services.commonService.objectIndexOf(this.filter[field], option);
      }
      if (index >= 0) {
        this.filter[field].splice(index, 1);
      }
    } else {
      this.filter[field] = null;
    }
    this.onFilter('removeFilter');
  }

  onRemoveFilterObjects(fields) {
    fields.forEach((field) => {
      this.filter[field] = null;
    });
    this.onFilter('removeFilter');
  }

  onClearAllFilter() {
    this.filterForm.reset();
    this.filter = {
      group: [],
      workstream: [],
      location: [],
      workflow_status: [],
      force: [],
      nested_themes: [],
      market: []
    };
    this.onFilter('clearAll');
  }

  hasFilter() {
    let res = false;
    for (const item in this.filter) {
      if (this.filter[item] && this.filter[item].length > 0) {
        res = true;
      }
    }
    return res;
  }

  loadFilter(filter) {
    this.filter = filter;
    this.onFilter('loadSetting');
  }
}
