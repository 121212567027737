/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable } from '@angular/material/table';
import { DeleteFunctionalityDialogComponent } from '@app/dialog/delete-functionality/delete-functionality-dialog/delete-functionality-dialog.component';
import { StatusPopupComponent } from '@app/dialog/status-popup/status-popup.component';
import { Messages } from '@app/models';
import { CommonService, DeliverableService } from '@app/services';
import { StatusService } from '@app/services/status.service';
import 'rxjs/add/observable/of';
@Component({
  selector: 'app-status-view',
  templateUrl: './status-view.component.html',
  // styleUrls: ['./status-view.component.scss']
})
export class StatusViewComponent implements OnInit {
  public statusList;
  public columns: string[] = ['checked', 'name', 'completion'];
  public headersData = ['', 'Deliverable Status', 'Completion'];
  public selectedOption = 'All';
  public checkedCount = 0;
  public editSelectedStatus = false;
  public selectedProjects: string[];
  public placeHolder;
  public statusIdList = [];
  public statusCount =[]
  public loadVal =0;
  public totalStatus = [];
  public actionPlaceholder = 'Actions';
  public statusPlaceholder = 'Set Status';
  public orgTypeName = 'General';
  @ViewChild('table') table: MatTable<string[]>;
  constructor(private commonService: CommonService,
    public dialog: MatDialog,
    private statusService: StatusService,
    private deliverableService: DeliverableService
  ) { }

  ngOnInit(): void {
    const hrefArray = window.location.href.toString().split('/');
    this.orgTypeName = hrefArray[4];
    this.commonService.showHeader.emit(true);
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.getStatus();
  }
  getStatus(): void {
    this.loadVal++;
    const  params = {orgTypeName:  this.orgTypeName};
    this.deliverableService.getDeliverableStatus(params)
      .subscribe((response) => {
        if (response && response.result) {
          this.statusList = response.result;
          this.statusList.forEach((element) => {
            element['checked'] = false;
            element['disabled'] = element.name=='In Progress';
            element['isComplete']?element['completion']='Completed':element['completion']='Not Complete';
          });
          Object.assign(this.totalStatus, this.statusList);
          this.loadVal--;
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  selectAll(event): void {
    this.statusList.forEach((element) => {
      if(element.name.toLowerCase() !='in progress'){
        element.checked = event.checked;
      }
    });
    this.selectOne(null);
  }
  selectOne(item): void {
    this.selectedProjects = [];
    this.checkedCount = 0;
    this.editSelectedStatus = false;
    // this.checkedAdmin = selected.name;
    this.placeHolder = {status: this.statusPlaceholder, action: this.actionPlaceholder};
    this.actionPlaceholder = 'Actions';
    this.statusIdList = [];
    this.statusList.forEach((element)=> {
      if(element.checked === true) {
        this.checkedCount++;
        this.selectedProjects.push(element.name);
        this.totalStatus.forEach((item) => {
          if(item.name === element.name) {
            this.statusIdList.push({'_id': item._id});
          }
        });
      }
    });
    if(this.checkedCount > 0) {
      this.editSelectedStatus = true;
    }
  }

  dropTable(event: CdkDragDrop<string[]>) :void{
    this.loadVal++;
    const prevIndex = this.statusList.findIndex((d) => d === event.item.data);
    moveItemInArray(this.statusList, prevIndex, event.currentIndex);
    this.table.renderRows();
    const orderBody =[];
    this.statusList.forEach((element, index) => {
      orderBody.push({'_id': element._id, 'order': index+1});
    });
    const reqBody = {'statusOrder': orderBody};
    this.statusService.saveStatus(reqBody)
      .subscribe((response) => {
        if (response) {
          this.loadVal--;
          this.commonService.openSnackBar('success', Messages.STATUS_ORDER_SAVE_SUCCESS);
        }
      }, (error1)=>{
        this.loadVal--;
        this.commonService.handleError(error1);
      });
  }

  addNewAdmin(): void {
    const editData = {'orgTypeName':this.orgTypeName};
    const dialogRef = this.dialog.open(StatusPopupComponent, {
      width: '538px',
      maxHeight: '95vh',
      data: editData
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.commonService.openSnackBar('success', Messages.STATUS_ADDED_SUCCESS);
        this.getStatus();
      }
    });
  }
  clickElement(element): void {
    if(element.name.toLowerCase() !='in progress') {
      const editData = {'value': element};
      const dialogRef = this.dialog.open(StatusPopupComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: editData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.STATUS_EDITED_SUCCESS);
          this.getStatus();
        }
      });
    }
    else{
      this.commonService.openSnackBar('warning', Messages.STATUS_NOT_EDITABLE);
    }
  }
  performAction(selectedAction){
    if(selectedAction === 'Delete') {
      const selectedData = { count: this.checkedCount, checkedData: this.selectedProjects, statusIdList: this.statusIdList, status: true};
      const dialogRef = this.dialog.open(DeleteFunctionalityDialogComponent, {
        width: '538px',
        maxHeight: '95vh',
        data: selectedData
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.commonService.openSnackBar('success', Messages.STATUS_DELETED_SUCCESS);
          this.getStatus();
          this.editSelectedStatus = false;
        }
      });
    }
  }
}

