import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';

import { AuthenticationService } from '@app/services';

@Injectable()
export class AuthGuard  {
  constructor(
        private router: Router,
        private authenticationService: AuthenticationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot):boolean {
    const currentUser = this.authenticationService.currentUserValue;
    if (currentUser) {
      let rolesAuth = false;
      if (route.data.roles) {
        if (route.data.roles.indexOf('*') !== -1) {
          rolesAuth = true;
        } else {
          if (currentUser.roles) {
            for (const role of route.data.roles) {
              for (const currentUserRole of currentUser.roles) {
                if (currentUserRole.role === role) {
                  rolesAuth = true;
                  break;
                }
              }
            }
          }
          if (!rolesAuth) {
            this.router.navigate(['/login']);
          }
        }
      } else {
        rolesAuth = true;
      }

      return rolesAuth;
    }
    this.router.navigate(['/']);
    return false;
  }
}
