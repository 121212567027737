/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable id-blacklist */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit, Inject, ViewChild, ElementRef} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { CommonService, UserService } from '@app/services';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GroupLocationLeadService } from '@app/services/group-location-lead.service';
import { Messages } from '@app/models';

@Component({
  selector: 'app-group-location-lead-popup',
  templateUrl: './group-location-lead-popup.component.html',
  // styleUrls: ['./group-location-lead-popup.component.scss']
})
export class GroupLocationLeadPopupComponent implements OnInit {
  loading = 0;
  showForm: UntypedFormGroup;
  submitted = false;
  teamMembersChanged: Subject<string> = new Subject<string>();
  selectedGroup: any;
  groups = [];
  selectedLocation: any = null;
  options: any = {
    users: [],
    groups: [],
    locations: [],
  }
  loadTeam = 0;
  groupLocationLeadName: string;
  selectedId: string;
  public loadVal = 0;
  @ViewChild('validForm') validForm: ElementRef;
  constructor(
    public dialogRef: MatDialogRef<GroupLocationLeadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private groupLocationLeadService: GroupLocationLeadService,
    private commonService: CommonService
  ) { }

  ngOnInit(): void {
    this.options.groups = this.data.groups;
    if(this.data.value){
      if(this.data.value.group){
        this.selectedGroup = this.data.value.group;
        this.selectedGroupValue(this.data.value.group, true);
      }
      this.selectedLocation = this.commonService.getSelectedValue(this.data.value.location._id, this.options.locations);
      if(this.data.value.group_location_lead){
        this.selectedId = this.data.value.group_location_lead.eso_id;
        this.groupLocationLeadName = this.data.value.group_location_lead.display_name;
      }
    }
    this.groups = this.options.groups.map(({_id, name, archive_ind, ...rest}) => ({_id, name, archive_ind}));
    this.showForm = this.formBuilder.group({
      group: [{ value: this.selectedGroup, disabled: false }, Validators.required],
      groupLocation: [{ value: this.selectedLocation, disabled: !this.data.value}, Validators.required],
      groupUsers: [{ value: this.groupLocationLeadName, disabled: false}, Validators.required]
    });
    this.teamMembersChanged.pipe(debounceTime(500))
      .subscribe((filterValue) => {
        if (filterValue.trim().length >= 3) {
          this.options['users'] = [];
          this._getTeamMembers(filterValue);
        }
      });
  }

  _getTeamMembers(searchText: any): void {
    this.loadTeam++;
    this.userService.getUsers(searchText)
      .subscribe((response) => {
        this.loadTeam--;
        if (response && response.result) {
          this.options.users = response.result;
        }
      });
  }

  onChangeUsers(event): void {
    this.selectedId = '';
    this.groupLocationLeadName = '';
    this.options['users'] = [];
    this.teamMembersChanged.next(event.target.value);
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addNewData(): void {
    this.submitted = true;
    if (this._isValid()){
      this.loadVal++;
      if(this.data.value){
        const params = this.data.value._id;
        const groupIdObj = {'_id': this.selectedGroup._id};
        const locationIdObj = {'_id': this.selectedLocation};
        // const groupLocationsIdArray = this.options.selectedLocations.map(({_id, ...rest}) => ({_id}));
        const body = { 'group': groupIdObj, 'location': locationIdObj, 'group_location_lead': this.selectedId};
        this.groupLocationLeadService.editGroupLocationLead(params, body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
      else{
        // const locationsIdArray = this.options.selectedLocations.map(({_id, ...rest}) => ({_id}));
        const groupIdObj = {'_id': this.selectedGroup._id};
        const locationIdObj = {'_id': this.selectedLocation};
        const body = { 'group': groupIdObj, 'location': locationIdObj, 'group_location_lead': this.selectedId, 'orgTypeName': this.data.orgTypeName};
        this.groupLocationLeadService.addGroupLocationLead(body)
          .subscribe((response) => {
            if (response) {
              this.loadVal--;
              this.dialogRef.close(true);
            }
          }, (error1)=>{
            this.loadVal--;
            this.commonService.handleError(error1);
          });
      }
    }
  }
  onCompareObjects(obj1: any, obj2: any): boolean {
    if (!obj1 || !obj2) {
      return false;
    }
    return obj1._id === obj2._id;
  }

  selectedValue(selected): void {
    this.selectedId = selected.option.value.eso_id;
    this.groupLocationLeadName = selected.option.value.display_name;
  }
  getGroupLocationLeadName(option) {
    const display_name = option.display_name? option.display_name : option
    return display_name;
  }
  selectedGroupValue(value, isPreFilled=false): void{
    let group;
    if(!isPreFilled){
      group = this.showForm.get('group').value;
    }
    else{
      group = value;
    }
    if (group) {
      const groupMaster = this.getGroupMaster(group);
      this.options.locations = groupMaster.locations;
      this.selectedGroup = value;
      if(!isPreFilled){
        this.showForm.get('groupLocation').enable({ emitEvent: false });
        this.showForm.controls['groupLocation'].setValue(null);
        this.selectedLocation = null;
      }
    }
  }
  selectedLocationsValue(value): void {
    this.selectedLocation = value;
  }
  getGroupMaster(group) {
    const index = this.commonService.objectIndexOf(this.options['groups'], group);
    if (index >= 0) {
      return this.options['groups'][index];
    }
    return { workstreams: [], locations: [] };
  }
  _isValid(): boolean {
    if (this.showForm.valid && this.selectedId !== '') {
      return true;
    }
    this.invalidFocus();
    return false;
  }
  invalidFocus() {
    setTimeout(() => {
      const invalidMatControl = this.validForm.nativeElement.querySelector('.error .ng-invalid');
      if (invalidMatControl) {
        invalidMatControl.focus();
        return;
      }
      const invalidControl = this.validForm.nativeElement.querySelector('.error .form-control');
      if (invalidControl) {
        invalidControl.focus();
      }
    }, 0);
  }
  get Messages(): any { return Messages; }
}

