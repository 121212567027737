<div fxLayout="column" class="progress-dialog">
    <div fxLayout="column">
        <h2 id="dialog_progres_title" class="dialog-title">Processing the download, please wait.</h2>
        <div fxLayout="column" class="dialog-content">
            <mat-dialog-content>
                <app-progress-spinner></app-progress-spinner>
            </mat-dialog-content>
        </div>
        <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
            <button mat-button type="button" color="primary" [mat-dialog-close]="false" style="height: 36px">Cancel</button>
        </div>
    </div>
</div>
