<form fxLayout="column" class="project-dialog" role="dialog" aria-labelledby="h_stageNum" aria-describedby="advanceText1 advanceText2 advanceText3">
    <label id="h_advanceStage" class="step-title">Advance Stage</label>
    <h2 id="h_stageNum" class="dialog-title" >ADVANCE TO STAGE  {{project.stage_number + 1}}?</h2>
    <div fxHide [fxShow]="advanceStage && advanceStage.isMet" fxLayout="column" class="dialog-content">
        <p id="advanceText1">
            All requirements for this project have been met to complete Stage {{project.stage_number}}. <br />
            Approving Stage {{project.stage_number}} will move the project to Stage {{project.stage_number + 1}}.
        </p>
    </div>
    <div [fxShow]="advanceStage && !advanceStage.isMet " fxLayout="column" class="dialog-content" aria-live="polite">
        <p id="advanceText2">
            Project should meet below requirements
        </p>
        <ul *ngIf="advanceStage" aria-labelledby="advanceText2">
            <li *ngFor="let option of advanceStage.checkResult;let i=index" id="option{{i}}" role="option" tabindex="0" [attr.aria-label]="option">
                {{option}}
                </li>
        </ul>
        <p id="advanceText3">
            One or more of above requirements is not met. <br />
            Do you still want to proceed to next stage ?
        </p>
    </div>
    <div fxLayout="row" fxLayoutGap="30px" class="dialog-actions">
        <button id="btnCancel" mat-button type="button" color="primary" (click)="onCancel()">Cancel</button>
        <button id="btnAdvance" mat-flat-button type="button" color="primary" (click)="onAdvanceStage()" cdkFocusInitial>Advance to Stage {{project.stage_number + 1}}</button>
    </div>
</form>